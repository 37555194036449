import React from 'react';
import _ from 'lodash';
import EmptySection from './EmptySection';
import {Permission} from 'src/stores/domainObjects/Permission';
import {observer} from 'mobx-react-lite';
import {formatDateFromNow, formatFullDate} from 'src/lib';
import SimplifiedHeaderServiceUsage from './SimplifiedHeaderServiceUsage';
import {Table} from '@trustle/component-library';

type Props = {
  permission: Permission;
};

const PermissionEvents = observer((props: Props) => {
  const {permission} = props;
  const serviceUsage: any = _.first(permission.serviceUsage);
  const system = permission.rootResource;
  const {retrieveUsageDataEnabled} = system;

  if (!serviceUsage || !retrieveUsageDataEnabled) {
    return <EmptySection system={system} type="resource" />;
  }

  const percentage = _.round(permission.usageData?.usage ?? 0);

  return (
    <div className="tr-my-2">
      <SimplifiedHeaderServiceUsage system={system} percentage={percentage} />
      <Table
        data={serviceUsage.account}
        tableKey={'permissionevents'}
        columns={[
          {
            dataField: 'account',
            text: 'System Account',
            headerStyle: {textAlign: 'center'},
            formatter: (cell: any) => {
              const link = `/resource/manage/${system.id}/accounts?Account+Name=${cell}&open=details`;
              return (
                <a href={link} className="text-underline" rel="noopener noreferrer">
                  {cell}
                </a>
              );
            },
          },
          {
            dataField: 'eventDate',
            text: 'Last Accessed',
            headerStyle: {textAlign: 'center'},
            formatter: (cell: any) => {
              return `${formatDateFromNow(cell)} (${formatFullDate(cell)})`;
            },
          },
        ]}
        striped={false}
        wrapperClasses="rounded-xl border border-black rounded"
        bordered={false}
      />
    </div>
  );
});

export default PermissionEvents;
