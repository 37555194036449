import {Button, Modal} from '@trustle/component-library';
import React from 'react';
import {formatUserName} from '../../../../../../../../lib';
import {useRootStore} from '../../../../../../../../lib/hooks';
import axios from 'axios';
import _ from 'lodash';
import {UserActionModalProps} from '../types';

type Props = UserActionModalProps & {
  reSend: boolean;
};

export function UserSendInviteModal({user, handleCloseFn, reSend, onConfirm}: Props) {
  const {toast} = useRootStore();
  const {id} = user;

  const handleConfirm = async () => {
    try {
      await axios.post(`/api/users/admin/${id}/resend-invite`);
      toast.success(reSend ? 'Invite re-sent successfully' : 'Invite sent successfully');
    } catch (error) {
      const message = _.get(error, 'response.data.error.message');
      toast.error(`Invite couldn't be sent. ${message ?? ''}`);
    } finally {
      onConfirm?.();
      handleCloseFn();
    }
  };

  return (
    <Modal onClose={handleCloseFn} title={'Confirm action'}>
      <div>
        {reSend
          ? `Please, confirm that you want to re-send an email invite to: `
          : `Please, confirm that you want to send an email invite to: `}
        <strong>{formatUserName(user)}</strong>
        <div className="tr-flex tr-justify-end tr-mt-4">
          <Button onClick={() => handleCloseFn()} variant="secondary">
            Cancel
          </Button>
          <Button onClick={() => handleConfirm()}>Confirm</Button>
        </div>
      </div>
    </Modal>
  );
}
