import _ from 'lodash';
import React from 'react';
import {useQueryStrings, useRootStore} from 'src/lib/hooks';
import {observer} from 'mobx-react';
import {UserStatus} from 'src/stores/usersStore';
import StatusIndicator, {translateStatus} from 'src/components/StatusIndicator';
import {User} from 'src/stores/domainObjects/User';

const FilterButton = observer(
  ({filter, reset = false, users}: {filter: UserStatus; users?: User[]; reset?: boolean}) => {
    const [filters, setFilters] = useQueryStrings({});
    users = users ?? useRootStore().usersStore.users;
    const values = filters.Status?.split(',') ?? [];
    const isFilterOn = values.includes(filter);
    const selected = values.includes(filter);

    return (
      <>
        <div
          className="hover:tr-bg-[#C2E5FF33] hover:tr-rounded-lg"
          role="button"
          onClick={() => {
            if (!reset) {
              const newValue = isFilterOn ? _.without(values, filter) : [...values, filter];
              setFilters({...filters, Status: newValue});
            } else {
              setFilters({});
            }
          }}
        >
          {!reset ? (
            <div
              className={`tr-flex tr-px-2 tr-py-1 rounded-lg tr-w-[130px] tr-text-sm ${
                selected ? 'tr-bg-[#E9F4FD]' : ''
              } `}
              data-testid={`${filter} count`}
            >
              <StatusIndicator status={translateStatus(filter)} messages={[_.capitalize(filter)]} />
              <div className="tr-flex tr-px-3">{users.filter((u) => u.status === filter).length}</div>
              <div className="tr-flex justify-content-center status-tag">{_.upperFirst(filter)}</div>
            </div>
          ) : (
            <div className={`${_.isEmpty(filters) ? 'tr-bg-[#E9F4FD] rounded-2xl' : ''}`}>
              <div className="tr-px-4 tr-font-bold tr-py-2">
                <div className="tr-flex tr-justify-between tr-text-sm md:tr-text-xl lg:tr-text-3xl xl:tr-text-6xl ">
                  {users.length}
                </div>
                <div className="tr-text-sm">Users</div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
);

export default FilterButton;
