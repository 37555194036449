import React from 'react';
import {ConnectionServiceE} from 'src/types';
import {ComponentStateT, RouteStepT} from '../types';
import {Loading} from '@trustle/component-library';
import M365Setup from './M365Setup';

const M365HeaderDescription = `Sync M365 with your Trustle organization. 
This process will guide you through authorizing Trustle to access your M365 subscription.`;

const steps: RouteStepT[] = [
  {
    name: 'Authenticate',
    path: `/connect/${ConnectionServiceE.M365}/:connectionId/authenticate`,
    component: (params: ComponentStateT) => {
      return (
        <>
          {params.connectionInfo ? (
            <M365Setup connectionInfo={params.connectionInfo} onComplete={params.nextStep} />
          ) : (
            <Loading />
          )}
        </>
      );
    },
  },
];

export const meta = {
  steps: steps,
  key: ConnectionServiceE.M365,
  description: M365HeaderDescription,
  title: 'Add a New M365 System',
};
