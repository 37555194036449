import Fuse from 'fuse.js';
import _ from 'lodash';
/**
 * Performs a fuzzy search on a list of items based on a search query and specified fields.
 *
 * @param {string} query - The search term to look for in the list.
 * @param {string[]} fields - The fields within each item to search through.
 * @param {T[]} list - The list of items to search within.
 * @returns {T[]} - A list of items that match the query with fuzzy search.
 */
function fuseSearch<T>(query: string, fields: string[], list: T[]): T[] {
  if (!query) {
    return list;
  }

  const options = {
    keys: fields,
    threshold: 0.3,
  };

  const fuse = new Fuse(list, options);

  return _.map(fuse.search(query), (elem) => {
    return elem.item;
  });
}

export {fuseSearch};
