import React from 'react';
import _ from 'lodash';
import {logger} from 'src/lib';

type ShowAccessDatesProps = {
  elements: any[];
  label: string;
  onClick?: (ad: any[]) => void;
  maxItems?: number;
};

/**
 * It slice a string array to display as: a separated list of string with ','.
 * The maxItems determine when add the '+ [maxItems] more' text
 * maxItems default value is 2
 *
 * @example
 * ```
 * <SliceStringList
 *     elements=[{label: 'foo', id: 4},{label: 'bar', id: 2},{label: 'zzz', id: 1}]
 * />
 *
 * The above example produces: "foo,bar + 1 more"
 * ```
 */
function SliceStringList(props: ShowAccessDatesProps) {
  const {elements, onClick = () => {}, maxItems = 2, label} = props;

  if (_.isEmpty(elements)) {
    return <></>;
  }

  const addMore = _.size(elements) - maxItems;
  const moreText = addMore > 0 ? `+ ${addMore} more` : '';
  const taken = _.take(elements, maxItems);
  if (_.some(elements, (e) => !e.hasOwnProperty(label))) {
    logger.warn(`[SliceStringList] - Some elements does not contains the label specified`, {label: label});
  }

  const displayAccounts = [
    `${_.join(
      _.compact(
        _.map(taken, (e: any) => {
          return e[label];
        })
      ),
      ', '
    )}`,
  ];
  displayAccounts.push(moreText);
  return (
    <div
      data-testid="list"
      onClick={() => {
        onClick(elements);
      }}
      className="text-underline cursor"
    >
      {_.join(_.compact(displayAccounts), ' ')}
    </div>
  );
}

export default SliceStringList;
