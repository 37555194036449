import React from 'react';
import {useRootStore} from 'src/lib/hooks';

function Logout() {
  useRootStore().authInfo.logout();

  return <div>Please wait while we log you out.</div>;
}

export default Logout;
