import React from 'react';

import {Icon} from '@trustle/component-library';
import {observer} from 'mobx-react';
import {useRootStore} from 'src/lib/hooks';

const ExpandableObserver = observer(({rowKey, expanded}: {rowKey: string; expanded: boolean}) => {
  const {org} = useRootStore();

  return (
    <>
      {org.accountsDictionary[rowKey] ? (
        <div className="cursor tr-w-[2.5rem]" data-testid="chevronexpand">
          <Icon type={expanded ? 'chevronUp' : 'chevronDown'} title="Expand" size="sm" />
        </div>
      ) : (
        <></>
      )}
    </>
  );
});

export default ExpandableObserver;
