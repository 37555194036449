import React, {useState} from 'react';
import {Button, Tab} from '@trustle/component-library';
import {USAGE_COLOR} from '../internal/licenses-usage-chart/utils';
import {SitesUsageHeatmap} from './SitesUsageHeatmap';
import {DemoRemoveUsers} from './DemoRemoveUsers';
import {SitesTable} from './SitesTable';

export function M365SitesUsage() {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [showSitesPanel, setShowSitesPanel] = useState(true);

  const [selection, setSelection] = useState<{ykey: string; xkey: string; yval: string; xval: string} | undefined>();

  return (
    <div className="tr-flex tr-gap-8 tr-p-4 tr-flex-col">
      <div className="tr-flex">
        <div className="tr-flex tr-flex-col tr-w-full tr-gap-2">
          <div className="tr-flex tr-flex-col">
            <div className="tr-flex tr-items-center tr-gap-2">
              <div className="tr-ml-auto tr-flex tr-gap-4">
                {!showSitesPanel && (
                  <Button
                    variant="secondary"
                    className="tr-m-0 tr-px-3 tr-py-2.5 tr-whitespace-nowrap tr-bg-trustle-bglighter tr-text-trustle-primary"
                    onClick={() => setShowSitesPanel(true)}
                  >
                    Show Sites
                  </Button>
                )}
              </div>
            </div>
            <div className="tr-flex tr-px-4 tr-mt-8">
              <div className="tr-flex tr-flex-col tr-w-full tr-gap-2 tr-max-w-[50%] tr-px-3">
                {renderMetricLabel(`50%`, 'Total Sharepoint Usage')}
                <div className="tr-w-full tr-bg-gray-200 tr-rounded-full tr-h-2.5 trbg-gray-700 tr-relative">
                  <div
                    className="tr-h-2.5 tr-rounded-full"
                    style={{width: `${50}%`, backgroundColor: USAGE_COLOR}}
                  ></div>
                </div>
              </div>
              <div className="tr-flex tr-w-full tr-justify-between tr-gap-8 tr-border-gray-200 tr-border-[0px] tr-border-l-[2px] tr-border-solid tr-px-4">
                {renderMetricLabel('125,463', 'Total Hours of Access', '(24hrs period)')}
                {renderMetricLabel('75,780', 'Total Hours of Usage', '(24hrs period)')}
                {renderMetricLabel('42,320', 'Unused Hours')}
              </div>
            </div>
          </div>
          <div className="tr-p-2">
            <Tab.Group selectedIndex={selectedIndex} onChange={(i: number) => setSelectedIndex(i)}>
              <Tab.List variant="line">
                <Tab key={'HEATMAP'}>Heatmap</Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel key={'HEATMAP'}>
                  <SitesUsageHeatmap setSelection={setSelection} showSitesPanel={showSitesPanel} />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
        {showSitesPanel && (
          <div className="tr-max-w-[350px] tr-border-gray-200 tr-border-[0px] tr-border-l-[1px] tr-border-solid tr-px-4">
            <SitesTable setShowLicensesPanel={setShowSitesPanel} />
          </div>
        )}
      </div>
      <DemoRemoveUsers selection={selection} setSelection={setSelection} isSites />
    </div>
  );
}

function renderMetricLabel(value: string | number, label: string, subLabel?: string) {
  return (
    <div className="tr-flex tr-flex-col">
      <label className="tr-mb-0 tr-text-2xl tr-font-bold">{value}</label>
      <label className="tr-mb-0 tr-text-xs tr-font-bold">{label}</label>
      {subLabel && <label className="tr-mb-0 tr-text-xs tr-text-gray-400">{subLabel}</label>}
    </div>
  );
}
