import {observer} from 'mobx-react';
import {Permission} from 'src/stores/domainObjects/Permission';

import React from 'react';
import {Icon} from '@trustle/component-library';
import _ from 'lodash';

export const PermissionName = observer(({permission, accessMode}: {permission: Permission; accessMode: boolean}) => {
  const permLabel = _.truncate(permission.label, {length: 38});

  return (
    <div className="tr-flex tr-pl-1">
      <p className={`body5-normal tr-m-0`} data-testid={`accordion-title-${permission.label}`} title={permission.label}>
        {permission.rootResource.userIsOwner && accessMode ? (
          <a
            href={`/resource/manage/${permission.rootResource.id}/resources?Opened=${permission.ancestorIds.join(',')}`}
            data-testid="link-permission"
          >
            {permLabel}
          </a>
        ) : (
          <b>{permLabel}</b>
        )}
      </p>
      {permission.description && (
        <Icon
          type="moreInfo"
          size="sm"
          className="tr-inline-table tr-text-trustle-link"
          title={permission.description}
          data-testid={`${permission.label}-moreInfoIcon`}
        />
      )}
    </div>
  );
});
