import React from 'react';
import {TextInput} from '@trustle/component-library';
import _ from 'lodash';
import {UserResponse} from '../../../../../hooks/useUsers';
import {LoginMethodsE} from '../../../../../../../../../types';
import {formatDateFromNow} from '../../../../../../../../../lib';

type Props = {
  user: UserResponse;
};

export function UserLoginInfo({user}: Props) {
  const {loginMethod, lastLogin} = user;

  const loginMethodValue =
    loginMethod === LoginMethodsE.GOOGLE
      ? 'Google OAuth'
      : loginMethod === LoginMethodsE.PASSWORD
      ? 'Email / Password'
      : _.startCase(loginMethod);

  const lastLoginValue = lastLogin ? formatDateFromNow(lastLogin) : '';

  return (
    <div className="tr-flex tr-flex-col tr-gap-4">
      <h3>Login Info</h3>
      <div className="tr-flex tr-flex-col">
        <div className="tr-flex tr-gap-4">
          <TextInput label="Method" value={loginMethodValue} disabled />
          <TextInput label="Last Login" value={lastLoginValue} disabled />
        </div>
      </div>
    </div>
  );
}
