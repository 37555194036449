import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import {
  MinUserT,
  AccessRequestCommentT,
  DenormGenericAccessRequestChangeT,
  AccessRequestChangeType,
  AccessRecordT,
} from 'src/types';
import {formatUserName, fixPlurality, requestChangeLabel} from 'src/lib';
import {Link} from 'react-router-dom';
import {Icon} from '@trustle/component-library';

type HistoryTabPropsT = {
  accessRequest: {
    id: string;
    history?: DenormGenericAccessRequestChangeT[];
    comments?: AccessRequestCommentT[];
    forAccess?: AccessRecordT | null;
  };
};

function renderAccessRequestHistoryItem(item: DenormGenericAccessRequestChangeT) {
  const {id, forUser, created, type, originalAssignees, forAccess} = item;
  const userName = formatUserName(forUser) ?? '[Undefined User]';

  const content = (() => {
    switch (item.type) {
      case AccessRequestChangeType.COMMENTED:
        return <div className="body6">"{item.params.message}"</div>;

      case AccessRequestChangeType.REASSIGNED: {
        const {assignees} = item;
        const assigneeDisplay = _.isEmpty(assignees)
          ? 'Organization Admins'
          : _.map(assignees, (assignee: MinUserT) => formatUserName(assignee)).join(', ');
        return (
          <div>
            <div className="body5">Assigned to {assigneeDisplay}</div>
            {item.params.comment && <div className="body6">"{item.params.comment}"</div>}
          </div>
        );
      }

      default: {
        const action = _.endsWith(item.type, 'Approved')
          ? 'approved'
          : _.endsWith(item.type, 'Rejected')
          ? 'rejected'
          : _.endsWith(item.type, 'Canceled')
          ? 'canceled'
          : _.endsWith(item.type, 'Duration')
          ? 'updated'
          : _.endsWith(item.type, 'provisioned')
          ? item.type
          : undefined;

        const title = _.isUndefined(action) ? _.startCase(item.type) : `Request has been ${action}.`;
        const {comment, accessDurationUnit, accessDurationValue} = item.params;

        return (
          <div>
            <div key={item.id} className="body5">
              {title}
            </div>
            {comment && <div className="body6">"{comment}"</div>}
            {accessDurationValue && (
              <div className="body6">
                New duration: {`${accessDurationValue} ${fixPlurality(accessDurationValue, accessDurationUnit)}`}
              </div>
            )}
          </div>
        );
      }
    }
  })();

  const icon = (() => {
    switch (type) {
      case AccessRequestChangeType.COMPLETED:
      case AccessRequestChangeType.MANAGER_APPROVED:
      case AccessRequestChangeType.DESIGNATED_APPROVED:
      case AccessRequestChangeType.OWNER_APPROVED:
      case AccessRequestChangeType.PROVISIONED:
        return <Icon type="enabled" />;

      case AccessRequestChangeType.MANAGER_REJECTED:
      case AccessRequestChangeType.DESIGNATED_REJECTED:
      case AccessRequestChangeType.OWNER_REJECTED:
        return (
          <div className="tr-text-error">
            <Icon type="warning" />
          </div>
        );
    }
  })();

  const createdDate = moment(created).format('M/D/Y hh:mma');
  const permission = !_.isNil(forAccess)
    ? {
        label: forAccess.permissionLabel,
        duration: {
          durationUnit: forAccess.accessDurationUnit ?? forAccess.durationUnit,
          durationValue: forAccess.accessDurationValue ?? forAccess.durationValue,
        },
      }
    : undefined;

  return (
    <div key={`history_tab_item-${id}`} className={`border-bottom history_tab_item-${id} ar-history-item`}>
      <div key={id} className="tr-flex">
        <div className="tr-flex tr-flex-col tr-w-1/3">
          <div className="body4">{requestChangeLabel(type)}</div>
          {permission?.label && <div className="body6 font-weight-bold">{`Permission: ${permission.label}`}</div>}
          {permission?.duration && (
            <div className="body6">{`Duration: ${permission.duration.durationValue} ${permission.duration.durationUnit}`}</div>
          )}
          <div className="body6">
            {userName && (
              <>
                {'by: '}
                <Link key={forUser.id} to={`/users/${forUser.id}`}>
                  {userName}
                </Link>
              </>
            )}
          </div>
          <div className="body6">
            {originalAssignees && (
              <>
                {'on behalf of: '}
                {originalAssignees.map((assignee: MinUserT) => {
                  return (
                    <>
                      <Link key={assignee.id} to={`/users/${assignee.id}`}>
                        {formatUserName(assignee)}
                      </Link>{' '}
                    </>
                  );
                })}
              </>
            )}
          </div>
          <div className="body6 mt-3 text-secondary">{createdDate}</div>
        </div>
        <div className="tr-flex tr-flex-col tr-w-1/2">{content}</div>
        <div className="tr-flex tr-flex-col tr-w-1/6">{icon}</div>
      </div>
    </div>
  );
}

function HistoryTab(props: HistoryTabPropsT) {
  const {accessRequest} = props;
  const history: DenormGenericAccessRequestChangeT[] = _.map(accessRequest.history || [], (item: any) => {
    return {...item, forAccess: accessRequest.forAccess};
  });

  const mergedHistory = history.concat(
    _.map(accessRequest.comments, (comment: AccessRequestCommentT): DenormGenericAccessRequestChangeT => {
      return {
        created: comment.createdAt,
        type: AccessRequestChangeType.COMMENTED,
        params: {message: comment.message},
        ..._.pick(comment, ['forUser', 'uid', 'id']),
      };
    })
  );
  if (_.isEmpty(mergedHistory)) {
    return <p></p>;
  }

  return (
    <div className="py-2 history-tab-component" key={`history_tab-${accessRequest.id}`}>
      {_(mergedHistory).orderBy(['created'], ['desc']).map(renderAccessRequestHistoryItem).value()}
    </div>
  );
}

export {HistoryTab, renderAccessRequestHistoryItem};
