import {ItemIconTypesEnum} from '@trustle/component-library/dist/types';
import {Icon, Tooltip} from '@trustle/component-library';
import React from 'react';

export function IconTooltip(props: {description: string; type: ItemIconTypesEnum; className: string}) {
  const {description, type, className} = props;
  return (
    <Tooltip size="lg" content={<div>{description}</div>} className="tr-font-normal">
      <div className={className}>
        <Icon type={type} className={`cursor ${className}`} />
      </div>
    </Tooltip>
  );
}
