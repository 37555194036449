import {Confirmation} from '@trustle/component-library';
import axios from 'axios';
import React from 'react';
import {Resource} from 'src/stores/domainObjects/Resource';

type PropsT = {
  resource: Resource;
  closeModal: (resourceWasUnarchived: boolean) => void;
};

function UnarchiveResourceModal(props: PropsT) {
  const {resource, closeModal} = props;

  async function runAction(e: any) {
    e.preventDefault();
    await axios.post(`/api/resources/${resource.id}/unarchive`);
    closeModal(true);
  }

  return (
    <Confirmation onConfirm={runAction} onClose={() => closeModal(false)} title={'Confirm Unarchive'}>
      <div>Please confirm that you would like to unarchive {resource.name}</div>
    </Confirmation>
  );
}

export default UnarchiveResourceModal;
