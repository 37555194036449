import _ from 'lodash';
import axios from 'axios';
import React from 'react';
import {useState, useEffect} from 'react';
import {SubmitButton} from 'src/components/design';
import {OrgConfigurationT} from 'src/types';
import {Loading, Table} from '@trustle/component-library';
import AdminNotificationsConfigurationForm from './configNotifications/AdminNotificationsConfigurationForm';
import {useFeatureFlags, useQueryParams} from 'src/lib';
import {useToasts} from 'react-toast-notifications';
import SlackNotificationPostInstall from './SlackNotificationPostInstall';
import {Confirmation} from '@trustle/component-library';

function AdminNotifcations() {
  const featureFlagViewer = useFeatureFlags();
  const [slackPostInstallError] = useQueryParams(['slack_post_install_error']);
  const showAdminHiddenFeatures = featureFlagViewer.isEnabled('show_admin_hidden_features');
  const [orgConfiguration, setOrgConfiguration] = useState<OrgConfigurationT>();
  const [slackConfiguration, setSlackConfiguration] = useState<any>();
  const [isLoading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccessMsg, setSubmitSuccessMsg] = useState('');
  const [configUpdates, setConfigUpdates] = useState<Partial<OrgConfigurationT>>({});
  const [showConfirmModalDisableSlack, setShowConfirmModalDisableSlack] = useState(false);

  const fetchPolicies = async () => {
    const result = await axios.get('/api/orgs/admin/configuration');
    setOrgConfiguration(result.data.orgConfiguration);
    setSlackConfiguration(result.data.slackConfiguration);
    setLoading(false);
  };
  const {addToast} = useToasts();
  useEffect(() => {
    void fetchPolicies();
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  function setAttr(e: any) {
    const {name, value} = e.target;
    setConfigUpdates({...configUpdates, [name]: value} as any);
    setOrgConfiguration({...orgConfiguration, [name]: value} as any);
  }
  async function applyChanges(e: any) {
    e.preventDefault();
    setIsSubmitting(true);
    await axios.post('/api/orgs/admin/configuration', configUpdates);
    setIsSubmitting(false);
    setSubmitSuccessMsg('Configuration changes saved.');
    setConfigUpdates({});
  }

  async function handleUnsubscribeSlack() {
    setLoading(true);
    await axios.post('/api/orgs/admin/unsubscribe-slack');
    await fetchPolicies();
    addToast('Slack notifications have been disabled', {
      appearance: 'success',
      autoDismiss: true,
    });
  }

  const checkboxCss = 'tr-w-4 tr-h-4 tr-rounded-[1px] tr-border-2 tr-border-neutral-700 tr-cursor-pointer';
  const notificationSettingsTable = (
    <div className="tr-mb-4 tr-flex tr-flex-col">
      <h2 className="tr-mb-4"> Notifications </h2>
      <Table
        tableKey="adminNotifications"
        data={[
          {
            event: 'Workflow change',
            days: '-',
            security: <input type="checkbox" className={checkboxCss} />,
            owner: <input type="checkbox" className={checkboxCss} />,
          },
          {
            event: 'Resources or permissions added',
            days: '-',
            security: <input type="checkbox" className={checkboxCss} />,
            owner: <input type="checkbox" className={checkboxCss} />,
          },
          {
            event: 'Current permission import is past-due by',
            days: <input type="number" max="999" min="0" />,
            security: <input type="checkbox" className={checkboxCss} />,
            owner: <input type="checkbox" className={checkboxCss} />,
          },
          {
            event: 'New users do not set their manager',
            days: '-',
            security: <input type="checkbox" className={checkboxCss} />,
            owner: <input type="checkbox" className={checkboxCss} />,
          },
          {
            event: 'Deprovision tasks are not completed',
            days: <input type="number" max="999" min="0" />,
            security: <input type="checkbox" className={checkboxCss} />,
            owner: <input type="checkbox" className={checkboxCss} />,
          },
        ]}
        striped={true}
        bordered={false}
        columns={[
          {
            dataField: 'event',
            text: 'Event',
          },
          {
            dataField: 'days',
            text: 'Threshold (Days)',
          },
          {
            dataField: 'security',
            text: 'Security',
          },
          {
            dataField: 'owner',
            text: 'Owner',
          },
        ]}
      />
    </div>
  );

  const policyEnforcementSettings = (
    <div className="tr-mb-2 tr-flex tr-flex-col">
      <form onSubmit={applyChanges}>
        <h2> Settings</h2>
        <div className="tr-flex flex-wrap flex-column align-content-start tr-mb-4">
          <div className="tr-flex tr-flex-row tr-justify-between">
            <label>Require resource owner import at least once every</label>
            <div className="tr-pl-4">
              <input
                type="number"
                max="999"
                min="0"
                name="resourceImportFrequency"
                value={orgConfiguration!.resourceImportFrequency}
                onChange={setAttr}
              />{' '}
              Days
            </div>
          </div>
          <div className="tr-flex tr-flex-row tr-justify-between">
            <label>Require Trustle owners to import regular employees at least once every</label>
            <div className="tr-pl-4">
              <input
                type="number"
                max="999"
                min="0"
                name="employeeImportFrequency"
                value={orgConfiguration!.employeeImportFrequency}
                onChange={setAttr}
              />{' '}
              Days
            </div>
          </div>
          <div className="tr-flex tr-flex-row tr-justify-between">
            <label>Require managers to certify temp employees access every</label>
            <div className="tr-pl-4">
              <input
                type="number"
                max="999"
                min="0"
                name="vendorEmployeeReviewFrequency"
                value={orgConfiguration!.vendorEmployeeReviewFrequency}
                onChange={setAttr}
              />{' '}
              Days
            </div>
          </div>
          <div className="tr-flex tr-flex-row tr-justify-between">
            <label>Require account owners to certify non-human accounts every</label>
            <div className="tr-pl-4">
              <input
                type="number"
                max="999"
                min="0"
                name="serviceAccountReviewFrequency"
                value={orgConfiguration!.serviceAccountReviewFrequency}
                onChange={setAttr}
              />{' '}
              Days
            </div>
          </div>
        </div>
        <div>
          {submitSuccessMsg && <div className="alert alert-success">{submitSuccessMsg}</div>}
          <SubmitButton
            label="Save Changes"
            inSubmit={isSubmitting}
            disabled={_.isEmpty(configUpdates) || isSubmitting}
          />
        </div>
      </form>
    </div>
  );

  const notificationIntegrations = (() => {
    const content = (() => {
      if (!slackConfiguration) {
        return (
          <>
            <p>
              {' '}
              Install the Trustle Slack application to your workspace to receive all access management requests via
              Slack
            </p>
            <a className="btn btn-primary" href="/api/connect/slack/oauth/start?query_type=slack-notifications">
              Enable Slack
            </a>
          </>
        );
      }

      return (
        <>
          <p>
            Trustle is authenticated and configured to send notifications to Slack Workspace{' '}
            <b>{slackConfiguration.workspaceName}</b>
          </p>
          <div
            className="btn btn-primary"
            onClick={() => {
              setShowConfirmModalDisableSlack(true);
            }}
          >
            Disable Slack
          </div>
          {showConfirmModalDisableSlack && (
            <Confirmation
              onConfirm={handleUnsubscribeSlack}
              onClose={() => setShowConfirmModalDisableSlack(false)}
              title={'Confirm Disable slack notifications'}
            >
              <div>Please confirm that you want to disable Slack notifications </div>
            </Confirmation>
          )}
        </>
      );
    })();

    return (
      <div className="tr-mb-4">
        <h2 className="tr-mb-4">Integrations</h2>
        <div className="tr-mb-4">
          <SlackNotificationPostInstall slackPostInstallError={slackPostInstallError} />
        </div>
        {content}
      </div>
    );
  })();

  return (
    <>
      {notificationIntegrations}
      <hr />
      <AdminNotificationsConfigurationForm orgConfiguration={orgConfiguration!} handleFetchPolicies={fetchPolicies} />
      <hr />
      {showAdminHiddenFeatures && notificationSettingsTable}
      {showAdminHiddenFeatures && policyEnforcementSettings}
    </>
  );
}

export default AdminNotifcations;
