import React, {useState} from 'react';
import {Resource} from 'src/stores/domainObjects/Resource';
import NewResourceAccountModal from './NewResourceAccountModal';
import {Button} from '@trustle/component-library';

type AddAccountButtonPropsT = {
  resource: Resource;
  refresh: () => void;
};

const AddAccountButton = ({resource, refresh}: AddAccountButtonPropsT) => {
  const [showNewResourceAccountModal, setShowNewResourceAccountModal] = useState(false);

  return (
    <>
      <Button onClick={() => setShowNewResourceAccountModal(true)}>{'Add Account'}</Button>
      {showNewResourceAccountModal && (
        <NewResourceAccountModal
          resource={resource}
          defaultData={{}}
          closeModal={() => {
            refresh();
            setShowNewResourceAccountModal(false);
          }}
        />
      )}
    </>
  );
};

export default AddAccountButton;
