import React from 'react';
import {Badge, Icon} from '@trustle/component-library';
import _ from 'lodash';
import {BadgeVariant} from 'src/views/Resource/Account/TrustleBadge';

type RiskBadgeProps = {riskScore: any};

// copied from `frontend/src/views/Resource/Account/RiskBadge.tsx`
function RiskBadge({riskScore}: RiskBadgeProps) {
  if (_.isNil(riskScore)) {
    return (
      <Icon title="Not Available" type="notAvailable" className="tr-mx-auto" size="sm" data-testid="notAvailableIcon" />
    );
  }
  const level = riskScore > 7 ? 'High' : riskScore > 3 ? 'Medium' : 'Low';
  const tag = `${riskScore}/10 - ${level}`;

  const color =
    level === 'High' ? BadgeVariant.DANGER : level === 'Medium' ? BadgeVariant.WARNING : BadgeVariant.PRIMARY;

  return (
    <div className="tr-items-center tr-flex">
      <Badge className="text-white cursor" pill variant={color}>
        {tag}
      </Badge>
    </div>
  );
}

type RiskProps = {account: any};

// copied from `frontend/src/components/design/tables/SharedColumnDefinitions/AccountColumns.tsx`
export function Risk({account}: RiskProps) {
  return <RiskBadge riskScore={account.riskScore} />;
}
