import {Field, useField} from 'formik';
import React from 'react';
import {Checkbox, Select, TextInput, Tooltip} from '@trustle/component-library';
import {FieldMappingOption, FieldMappingType} from '../helpers';

type Props = {
  fieldName: string;
  fieldLabel: string;
  supportRegex?: boolean;
  options: FieldMappingOption[];
  serviceFieldMappings: FieldMappingType;
  forceMandatory?: boolean;
  disableMandatory?: boolean;
};

export function AttributeMapField(props: Props) {
  const {
    fieldName,
    fieldLabel,
    options,
    supportRegex = false,
    serviceFieldMappings,
    forceMandatory = false,
    disableMandatory = false,
  } = props;
  const [field, , helpers] = useField(fieldName);
  const fieldValue = field.value || serviceFieldMappings[fieldName];
  return (
    <div className={`items-center pb-7 tr-gap-4 tr-grid ${supportRegex && 'tr-grid-cols-3'}`} style={{ gridTemplateColumns: supportRegex ? '':'20px 42% 42%'}} key={fieldName}>
      {!disableMandatory && (
        <Tooltip
          interactive
          size={'md'}
          content={
            <div className="tr-py-1 tr-px-2">
              <p>
                If checked, if this attribute value is missing on the remote side, a validation error will occur at sync-time and the user will not be synced.
              </p>
            </div>
          }
        >
          <Checkbox
            checked={forceMandatory || fieldValue.mandatory}
            onChange={(e: any) => {
              helpers.setValue({ ...fieldValue, mandatory: e.target.checked });
            }}
            disabled={forceMandatory || disableMandatory}
            className="tr-flex tr-flex-none tr-flex-row tr-items-center"
          />
        </Tooltip>
      )}
      <Field component={TextInput} value={fieldLabel} disabled className="pb-0" />
      <Field component={Select} name={`${fieldName}.name`} options={options} />
      {supportRegex && <Field className="pb-0" component={TextInput} name={`${fieldName}.regex`} options={options} />}
    </div>
  );
}
