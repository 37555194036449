import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {ConnectorContextWrapper} from 'src/connectors/ConnectorContext';
import {Switch, Route, useHistory, generatePath, matchPath, useParams} from 'react-router-dom';
import {useFeatureFlags, useQueryParams} from 'src/lib';
import './ConnectorRender.scss';
import FlowProgressHeader from './components/FlowProgressHeader';
import * as oktaMeta from './okta/OktaFlowMetadata';
import * as pagerDuty from './pagerduty/PagerDutyFlowMetadata';
import * as awsMeta from './aws/AWSFlowMetadata';
import * as azureADMeta from './azuread/AzureADFlowMetadata';
import * as github from './github/GithubFlowMetadata';
import * as gapps from './gapps/GappsFlowMetadata';
import * as tableau from './tableau/FlowMetadataTableau';
import * as m365 from './m365/M365FlowMetadata';
//IN THE FUTURE THIS WILL BE GOTTEN WITH A SERVICE.
import buildIntegrationMetaSteps from './manifest/ManifestMetadata';

import {meta as strongdmMeta} from './strongdm/StrongDMFlowMetadata';
import {ConnectionInfoT, ConnectionServiceE, FacetIdEnum, ProvisionMethodE} from '../types';
import {ComponentStateT, ConnectorMeta, RouteStepT} from './types';
import axios from 'axios';
import SetupNameStep from './common/SetupNameStep';
import {useRootStore} from 'src/lib/hooks';
import manifests from './manifests';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';

export default function ConnectorFlow() {
  const {connectorType} = useParams() as {connectorType: ConnectionServiceE};
  const history = useHistory();
  const featureFlagViewer = useFeatureFlags();
  const [querySystemId, orgName] = useQueryParams(['systemId', 'orgName']);
  const [systemId, setSystemId] = useState(querySystemId);

  const metadatas = buildIntegrationMetaSteps();

  function getMeta(connectorType: ConnectionServiceE): ConnectorMeta {
    const connectors: _.Dictionary<ConnectorMeta> = {
      okta: oktaMeta.meta,
      github: github.meta,
      aws: awsMeta.meta,
      gapps: gapps.meta,
      azure_ad: azureADMeta.meta,
      m365: m365.meta,
      slack: {},
      generic: {},
      pagerduty: pagerDuty.meta,
      strongdm: strongdmMeta,
      tableau: tableau.meta,
    };
    if (featureFlagViewer.isEnabled('manifest_flow')) {
      for (const meta of metadatas) {
        if (!manifests[meta.key]?.auth) {
          continue;
        }
        connectors[meta.key] = meta;
      }
    }

    return connectors[connectorType];
  }

  const {steps = [], description, title, image, key} = getMeta(connectorType) || {};
  const {org, newResourceStore} = useRootStore();

  if (org) {
    const isFreeTrial = org.facetId === FacetIdEnum.FREE_AWS;
    const isAWS = connectorType === ConnectionServiceE.AWS;
    const disabled = (isFreeTrial && !isAWS) || (isFreeTrial && isAWS && _.size(newResourceStore.systems) > 0);

    if (disabled || _.isEmpty(steps)) {
      history.replace('/resources/create');
    }
  }

  const nameStep: RouteStepT = {
    name: 'Name',
    path: `/connect/${connectorType}`,
    component: (params: ComponentStateT) => {
      return <SetupNameStep nextStep={params.nextStep} connectorType={connectorType} />;
    },
  };

  //Here we add default steps
  const totalSteps = [nameStep, ...steps];

  const stepLabels = _.map(totalSteps, 'name');
  const stepMatch = _.find(totalSteps, (step: RouteStepT) => {
    return !!matchPath(location.pathname, {path: step.path, exact: true});
  });

  const routeMatch = stepMatch ? matchPath(location.pathname, {path: stepMatch.path, exact: true}) : null;

  const [connectionId, setConnectionId] = useState<string | undefined>(_.get(routeMatch, 'params.connectionId'));

  const [connectionInfo, setConnectionInfo] = useState<ConnectionInfoT>();

  async function loadConnection() {
    if (connectionId) {
      const connectionResponse = await axios.get(`/api/connect/${connectionId}`);
      setConnectionInfo(connectionResponse.data);
    }
  }

  useEffect(() => {
    setConnectionId(_.get(routeMatch, 'params.connectionId'));
  }, [_.get(routeMatch, 'params.connectionId')]);

  useEffect(() => {
    void loadConnection();
  }, [connectionId]);

  async function nextStep(
    currentStepIdx: number,
    {resourceId, newConnectionId, path}: {resourceId?: string; newConnectionId?: string; path?: string} = {}
  ) {
    if (resourceId) {
      setSystemId(resourceId);
    }

    if (newConnectionId) {
      setConnectionId(newConnectionId);
    }

    if (currentStepIdx + 1 === totalSteps.length) {
      //Automatically finalize flow. Before final redirection.

      const {data} = await axios.post(`/api/connect/${connectionId}/setup/complete`, {
        provisionMethod: ProvisionMethodE.manual,
        pollDurationUnit: 'hour',
        pollDurationValue: 24,
      });

      history.push(`/resource/manage/${data.systemId}?onboard=true`);
      return;
    }

    if (path) {
      history.push(path);
      return;
    }

    if (currentStepIdx + 1 > totalSteps.length) {
      // The resource should be imported and mapped by this time. We should redirect to the new resource.
      throw new Error(`Attempted to nextStep too many times (${currentStepIdx + 1})`);
    }

    const nextStep = totalSteps[currentStepIdx + 1];
    if (!nextStep || !nextStep.path) {
      throw new Error(`Step misconfigured ${currentStepIdx}`);
    } else if (nextStep) {
      history.push(generatePath(nextStep.path, {connectionId: newConnectionId || connectionId}));
    }
  }

  const routes = _.map(totalSteps, (step: RouteStepT, idx: number) => {
    return (
      <Route key={step.name} path={step.path} exact={true}>
        <FlowProgressHeader
          steps={stepLabels}
          systemKey={key as SystemIconsTypesEnum}
          description={description}
          image={image}
          title={title!}
          currentStepIdx={idx}
        />
        <div className="tr-pb-5 tr-pt-5 tr-mx-96">
          <div className="tr-justify-center tr-items-center">
            {step.component({connectionId, connectionInfo, orgName, systemId, nextStep: _.partial(nextStep, idx)})}
          </div>
        </div>
      </Route>
    );
  });

  return (
    <ConnectorContextWrapper>
      {!title && (
        <div className="container tr-pb-5 tr-pt-5">
          <div className="tr-flex tr-justify-center tr-items-center">Connector not found</div>
        </div>
      )}
      <Switch>{routes}</Switch>
    </ConnectorContextWrapper>
  );
}
