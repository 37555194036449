import {Icon, Tooltip} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {formatUserName, formatUserNameAndEmail} from 'src/lib';
import {Account} from 'src/stores/domainObjects/Account';
import ShowAuthorizedAccount from './ShowAuthorizedAccount';

type PropsT = {
  account: Account | undefined;
  onClickNotes?: (props: any) => void;
  shortVersion?: boolean;
};

const UserAccountColumnContent = observer((props: PropsT) => {
  const {account, shortVersion} = props;

  if (_.isNil(account)) {
    return <></>;
  }

  function getUserAccountPopup(): ReactNode {
    return (
      <div className="pl-2">
        <div className="tr-text-sm">
          <b>User: </b> {formatUserNameAndEmail(account?.forUser)}
        </div>
        <div className="tr-text-sm">
          <b>Account: </b>
          {account?.account}
          {account && (
            <span className="inline-flex-align ml-1">
              <ShowAuthorizedAccount account={account} />
            </span>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="my-1 tr-flex tr-flex-row justify-content-start tr-items-center">
      {!shortVersion && <Icon type="access" size="sm" />}
      <div className={`tr-flex tr-items-center ${!shortVersion ? 'flex-column' : 'flew-row'}`}>
        {!_.isNil(account.forUser) && (
          <Tooltip content={getUserAccountPopup()} position="bottom" size="lg">
            <Link to={`/users/${account.forUser.id}`}>
              <p className="tr-truncate tr-m-0">{formatUserName(account.forUser)} </p>
            </Link>
          </Tooltip>
        )}
        {!_.isNil(account) && (
          <div className="tr-flex tr-flex-row inline-flex-align">
            {!shortVersion && <div className="grayed-out text-break">{account.account ?? ''}</div>}
          </div>
        )}
      </div>
    </div>
  );
});
export default UserAccountColumnContent;
