import _ from 'lodash';
import React from 'react';
import './FlowProgressBar.scss';

type PropsT = {
  steps: string[];
  currentStepIdx: number;
};

export function FlowProgressBar(props: PropsT) {
  const steps = _.flatMap(props.steps, (stepLabel: string, idx: number) => {
    const completedClass = idx === props.currentStepIdx ? 'active' : '';
    const components = [
      <div key={`step-${idx}`} className={'m-3 body1 cursor'}>
        <span title={stepLabel} className={`step-icon ${completedClass}`}>
          {idx + 1}
        </span>
      </div>,
    ];

    return components;
  });

  return <div className="connector-flow-progress-bar m-3 tr-flex flex-align-center">{steps}</div>;
}
