import React from 'react';
import {observer} from 'mobx-react';
import {Icon, SensitivityIcon} from '@trustle/component-library';
import '../PermissionBranch.scss';
import _ from 'lodash';

import {TreeTabPanelT} from '../TreeTabPanel';
import {Resource} from 'src/stores/domainObjects/Resource';
import LockedOrProvision from '../LockedOrProvision';
import ResourceDropdown from '../../Setup/ResourceDropdown';
import {Button} from '@trustle/component-library';

type TabResourceBranchPropsT = {
  resource: Resource;
  filters?: any;
  setFilters: (value: any) => void;
} & TreeTabPanelT;

const TabSubHeader = observer((props: TabResourceBranchPropsT) => {
  const {filters, canEditResource, resource, setFilters, allowExpand = true} = props;

  const lockedIcon = (resource: Resource) => {
    return <LockedOrProvision target={resource} />;
  };

  if (!resource) {
    return <></>;
  }

  return (
    <div className="tr-flex tr-justify-between">
      <div className="tr-flex tr-items-center tr-justify-center tr-pl-3">
        <span className="tr-text-black tr-text-opacity-90 tr-text-xl tr-font-bold tr-pr-2">{resource.name}</span>
        <Icon type="moreInfo" size="sm" title={resource.description} className="tr-text-trustle-link tr-pr-1" />
        {canEditResource && (
          <>
            <div className="actions tr-text-black tr-flex tr-items-center">
              {lockedIcon(resource)}
              <div className="tr-flex tr-px-2 tr-border-0 tr-border-l-[1px] tr-border-solid tr-border-trustle-selected">
                <SensitivityIcon
                  size="sm"
                  level={resource.sensitivity?.level ?? ''}
                  title="Sensitivity set for this specific resource"
                />
              </div>
            </div>
          </>
        )}
        <div>
          <ResourceDropdown resource={resource} refreshResourceList={() => {}} showIconDetails={false} />
        </div>
      </div>
      {allowExpand && (
        <div>
          <Button
            variant="ternary"
            onClick={() => {
              if (!_.isUndefined(filters.Expand)) {
                const tempFilters = {...filters};
                delete tempFilters.Expand;
                setFilters(tempFilters);
              } else {
                setFilters({...filters, Expand: 'All'});
              }
            }}
            className="tr-flex"
          >
            {!filters.Expand ? 'Expand All' : 'Close Expanded'}
          </Button>
        </div>
      )}
    </div>
  );
});

export default TabSubHeader;
