import React, {useState} from 'react';
import {USER_TYPES} from '../../../views/v2/admin/admin-users-panel/constants';
import {
  Button,
  Checkbox,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Icon,
  IconButton,
  Table,
  Tooltip,
  UserInfo,
} from '@trustle/component-library';
import _ from 'lodash';
import {USERS_MOCK} from './usersMock';
import {IfCondition} from '../../../views/v2/common/IfCondition';
import {Link} from 'react-router-dom';
import {formatDateFromNow, formatFullDate, formatUserName} from '../../../lib';

type Props = {
  selection?: {
    ykey: string;
    xkey: string;
    yval: string;
    xval: string;
  };
  setSelection: (selection: {ykey: string; xkey: string; yval: string; xval: string} | undefined) => void;
  isSites?: boolean;
};

export function DemoRemoveUsers({selection, setSelection, isSites = false}: Props) {
  const [selectedUsers, setSelectedUsers] = useState<any[]>([]);
  const {ykey, xkey, yval, xval} = selection || {};

  function getUsageColorClass(usage: number) {
    if (usage >= 0 && usage <= 25) {
      return 'tr-text-red-500'; // Red for low usage
    } else if (usage > 25 && usage <= 50) {
      return 'tr-text-orange-500'; // Orange for moderate usage
    } else if (usage > 50 && usage <= 75) {
      return 'tr-text-trustle-navy'; // Blue for good usage
    } else if (usage > 75 && usage <= 100) {
      return 'tr-text-green-500'; // Green for high usage
    }
    return 'tr-text-gray-500'; // Default color for out-of-range values
  }

  const USER_DEMO_COLUMNS = [
    {
      dataField: 'id',
      text: '',
      hidden: true,
    },
    {
      dataField: 'flagged',
      headerStyle: {width: '40px', paddingLeft: '8px', paddingRight: '8px', textAlign: 'center'},
      style: {width: '40px', paddingLeft: '8px', paddingRight: '8px', textAlign: 'center'},
      formatter: (_: any, user: any) => {
        if (user.flagged) {
          return <Icon type="flagOn" title="User is flagged" size="sm" />;
        } else {
          return <Icon type="flagOff" title="User is not flagged" size="sm" />;
        }
      },
    },
    {
      text: 'Name',
      headerStyle: {width: '200px'},
      style: {width: '200px'},
      formatter: (_: any, user: any) => (
        <div id="userNameCol" data-testid={`name${user.email}`}>
          <UserInfo type={user.type} isOrgOwner={user.is_org_owner} className="tr-text-trustle-link">
            <Link className="" to={``}>
              {formatUserName(user)}
            </Link>
          </UserInfo>
        </div>
      ),
    },
    {
      dataField: 'licenses',
      text: isSites ? 'Sites' : 'Licenses',
      formatter: (_: any, user: any) => {
        const {licenses} = user;
        return (
          <Link className="" to={``}>
            {licenses}
          </Link>
        );
      },
    },
    {
      dataField: 'assigned_on',
      text: 'Assigned On',
      formatter: (_: any, user: any) => {
        const {assigned_on} = user;
        return !assigned_on ? (
          <Icon type="emptyData" title="Never" size="sm" />
        ) : (
          <Tooltip position="top" content={formatFullDate(assigned_on)}>
            {formatDateFromNow(assigned_on)}
          </Tooltip>
        );
      },
    },
    {
      dataField: 'last_used',
      text: 'Last Used',
      formatter: (_: any, user: any) => {
        const {last_used} = user;
        return !last_used ? (
          <Icon type="emptyData" title="Never" size="sm" />
        ) : (
          <Tooltip position="top" content={formatFullDate(last_used)}>
            {formatDateFromNow(last_used)}
          </Tooltip>
        );
      },
    },
    {
      dataField: 'usage',
      text: 'Usage',
      formatter: (_: any, user: any) => {
        const {usage} = user;
        return <span className={getUsageColorClass(usage)}>{usage}%</span>;
      },
    },
    {
      text: 'Actions',
      classes: 'show-on-hover',
      formatter: () => {
        return (
          <div className="tr-flex tr-gap-2 tr-items-center action-buttons" style={{right: 'unset'}}>
            <IconButton title="Deprovision" onClick={() => {}} variant="ternary" icon="deprovisionAccess" size="md" />
            <IconButton title="Revoke" onClick={() => {}} variant="ternary" icon="accessRevoke" size="md" />
          </div>
        );
      },
    },
  ] as any;

  return (
    <div>
      <div className="tr-flex tr-items-center tr-gap-3 tr-bg-[#F8F9FC] tr-p-4 tr-mx-[-32px]">
        <h2 className="tr-mb-0 tr-ml-8">Trustle Users:</h2>
        {selection ? (
          <>
            <span className="tr-flex tr-gap-1 tr-items-center">
              <p className="tr-mb-0">{ykey}:</p>
              <strong>{yval}</strong>
            </span>
            <span>/</span>
            <span className="tr-flex tr-gap-1 tr-items-center">
              <p className="tr-mb-0">{xkey}:</p>
              <strong>{xval}</strong>
            </span>
            <Button variant="ternary" onClick={() => setSelection(undefined)}>
              <div className="tr-flex tr-items-center tr-gap-2 tr-text-xs">
                <Icon type="cancel" />
                Clear selection
              </div>
            </Button>
          </>
        ) : (
          <span className="tr-flex tr-gap-1 tr-items-center">
            <p className="tr-mb-0">Select a cell above to show corresponding Trustle Users</p>
          </span>
        )}
      </div>
      <div className="tr-px-8">
        <IfCondition condition={Boolean(selection)}>
          <div className="tr-flex tr-items-center tr-py-3">
            {USER_TYPES.map((type) => (
              <div key={type}>
                <Button variant="ternary" onClick={() => {}}>
                  <span className="tr-flex tr-items-center tr-gap-1">
                    <Icon type={`${type}User`} />
                    {_.startCase(type)}
                  </span>
                </Button>
              </div>
            ))}
          </div>
          <Table
            data={USERS_MOCK}
            columns={USER_DEMO_COLUMNS}
            tableKey={'demo'}
            rowClasses={'tr-h-16'}
            totalSize={USERS_MOCK.length}
            selectedRows={selectedUsers}
            selectRow={{
              mode: 'checkbox',
              clickToSelect: false,
              hideSelectAll: false,
              onSelect: (_row, _isSelect) => {
                const user = _.find(USERS_MOCK, (user) => user.id === _row.id);
                if (!user) {
                  return;
                }

                if (_isSelect) {
                  setSelectedUsers([...selectedUsers, user]);
                } else {
                  setSelectedUsers(selectedUsers.filter((u) => u.id !== user.id));
                }
              },
              selectionRenderer: ({rowKey}) => {
                const user = _.find(USERS_MOCK, (user) => user.id === rowKey);
                if (!user) {
                  return <></>;
                }
                return (
                  <Checkbox
                    id={`whatever`}
                    data-testid={`whatever`}
                    disabled={false}
                    checked={selectedUsers.includes(user)}
                  />
                );
              },
              selectionHeaderRenderer: ({indeterminate, checked}) => {
                return (
                  <Checkbox
                    className={'tr-min-w-[45px]'}
                    id={`activate-users-all`}
                    checked={checked}
                    ref={(input) => {
                      if (input) {
                        input.indeterminate = indeterminate;
                      }
                    }}
                  />
                );
              },
              onSelectAll(isSelect) {
                if (isSelect) {
                  setSelectedUsers(USERS_MOCK);
                } else {
                  setSelectedUsers([]);
                }
              },
            }}
            multiActionComponent={<BulkActions selectedCount={selectedUsers.length} />}
            showClearSelectionBtn={true}
            onClearSelection={() => {
              setSelectedUsers([]);
            }}
          />
        </IfCondition>
      </div>
    </div>
  );
}

function BulkActions({selectedCount}: {selectedCount: number}) {
  if (selectedCount === 0) {
    return null;
  }
  return (
    <Dropdown
      title="Actions"
      dropdownMenu={
        <DropdownMenu>
          <DropdownItem
            onClick={() => {}}
            className="tr-bg-white hover:tr-bg-trustle-bglighter tr-px-2 tr-flex tr-items-center"
          >
            <Icon type="flagOff" />
            <div className="tr-pl-4">Flag Users</div>
          </DropdownItem>
          <hr key="first-separator" className="tr-m-0" />
          <DropdownItem onClick={() => {}} className="tr-bg-white hover:tr-bg-trustle-bglighter tr-px-0">
            <div className="tr-pl-4">Deprovision Access</div>
          </DropdownItem>
          <DropdownItem onClick={() => {}} className="tr-bg-white hover:tr-bg-trustle-bglighter tr-px-0">
            <div className="tr-pl-4">Revoke Access</div>
          </DropdownItem>
        </DropdownMenu>
      }
      variant="secondary"
    />
  );
}
