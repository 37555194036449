import React, {useEffect, useState} from 'react';
import {TableStatus} from '../../../../../common/TableStatus';
import {Table} from '@trustle/component-library';
import {useUserCountByColumns} from './useUserCountByColumns';
import {useUsersCountBy} from '../../../hooks/useUsersCountBy';

type Props = {
  countBy: 'department' | 'role' | 'title';
};

export function UsersCountByTable({countBy}: Props) {
  const {data: usersCountedBy, loading, getUsersCountedBy} = useUsersCountBy();

  const COLUMNS = useUserCountByColumns({countBy});

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(50);

  useEffect(() => {
    void getUsersCountedBy({filter: countBy, page, size});
  }, [page, size, countBy]);

  if (loading || !usersCountedBy) {
    return <TableStatus text={`Loading ${countBy}...`} status="loading" description="Please stand by" />;
  }

  const {items, total} = usersCountedBy;

  return (
    <Table
      data={items}
      columns={COLUMNS}
      tableKey="teams-summary-table"
      totalSize={total}
      sizePerPage={size}
      page={page}
      remote={{
        filter: true,
        pagination: true,
        sort: false,
        cellEdit: false,
      }}
      onSizePerPageChange={(size) => {
        setSize(size);
        return {};
      }}
      onTableChange={(_type: string, {page}: {page: number}) => {
        setPage(page);
      }}
      showEmptyElement={<TableStatus status="no-data" text={`No ${countBy} found`} />}
    />
  );
}
