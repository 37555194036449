import {Modal} from '@trustle/component-library';
import React from 'react';

type PropsT = {
  closeModal: () => void;
  title: string;
  children: React.ReactNode;
};

function PermissionActionModal(props: PropsT) {
  const {closeModal, title, children} = props;

  return (
    <Modal visible={true} width="lg" title={title} onClose={closeModal}>
      {children}
    </Modal>
  );
}

export default PermissionActionModal;
