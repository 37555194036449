import React from 'react';
import {ToggleSwitch} from 'src/components/design';
import {useField} from 'formik';
import {observer} from 'mobx-react';
import {Checkbox} from '@trustle/component-library';

type PropsT = {
  name: string;
  disabled?: boolean;
  className?: string;
  type?: 'toggleSwitch' | 'checkbox';
  onClick?: any;
  onChange?: () => void;
};

const AutomatedProvisionToggle = observer(function AutomatedProvisionToggle(props: PropsT) {
  const [field, meta, helpers] = useField(props);
  const {onClick = () => {}, onChange = () => {}} = props;
  const type = !props.type ? 'toggleSwitch' : props.type;
  const isAutomaticProvisioning = meta.value === true;
  if (type === 'toggleSwitch') {
    return (
      <ToggleSwitch
        checked={isAutomaticProvisioning}
        onToggle={(e: any) => {
          helpers.setValue(e);
        }}
        name={field.name}
        disabled={props.disabled}
        data-testid="retrieveUsageData"
      />
    );
  }
  return (
    <Checkbox
      className={`tr-w-5 tr-h-5 tr-text-green-500 tr-rounded-lg ${props.className}`}
      name={field.name}
      type="checkbox"
      onClick={(e: any) => {
        helpers.setValue(e.target.checked);
        onClick();
      }}
      onChange={onChange}
      disabled={props.disabled}
      checked={isAutomaticProvisioning}
      id={field.name}
    />
  );
});
export default AutomatedProvisionToggle;
