import React, {useMemo} from 'react';
import {LicenseUsageChartProps} from './utils';
import {LicenseOverallUsageAreaChart} from './internal/LicenseOverallUsageAreaChart';
import {ALL_LICENSES_KEY, NO_DATA_MESSAGES} from '../../constants';
import {LicenseUsageStackedBarChart} from './internal/LicenseUsageStackedBarChart';

export function LicensesUsageChart({
  data,
  series,
  summarizeAllLicenses,
  onboardDateStr,
  licenseByKeyMap,
}: LicenseUsageChartProps & {onboardDateStr?: string}) {
  const noDataMessage = useMemo(() => {
    if (data.length === 0) {
      return NO_DATA_MESSAGES.NO_LICENSE_DATA;
    }

    if (series.length === 0 && !summarizeAllLicenses) {
      return NO_DATA_MESSAGES.NO_LICENSES_SELECTED;
    }
  }, [data, series, summarizeAllLicenses]);

  const onboardXAxisPos = useMemo(() => {
    return calculateOnboardDateXPosition(data, onboardDateStr);
  }, [data, onboardDateStr]);

  return (
    <>
      {summarizeAllLicenses ? (
        <LicenseOverallUsageAreaChart
          data={data}
          series={[ALL_LICENSES_KEY]}
          noDataMessage={noDataMessage}
          onboardXAxisPos={onboardXAxisPos}
          licenseByKeyMap={licenseByKeyMap}
        />
      ) : (
        <LicenseUsageStackedBarChart
          data={data}
          series={series}
          noDataMessage={noDataMessage}
          onboardXAxisPos={onboardXAxisPos}
          licenseByKeyMap={licenseByKeyMap}
        />
      )}
    </>
  );
}

function calculateOnboardDateXPosition(data: {date: string}[], onboardDateStr?: string) {
  if (!onboardDateStr) {
    return null;
  }
  const normalizeOnboardDate = (date: string) => {
    const d = new Date(date);
    const year = d.getFullYear();
    const month = d.getMonth() + 1;
    const day = d.getDate();

    const fillWithZero = (n: number) => (n < 10 ? `0${n}` : `${n}`);
    return `${year}-${fillWithZero(month)}-${fillWithZero(day)}`;
  };

  const onboardDate = new Date(normalizeOnboardDate(onboardDateStr));
  const parsedArray = data.map((obj) => new Date(obj.date));
  for (let i = 0; i < parsedArray.length - 1; i++) {
    const currentDate = parsedArray[i];
    const nextDate = parsedArray[i + 1];

    const isHighOrEqual = onboardDate.getTime() >= currentDate.getTime();
    const isLow = onboardDate.getTime() < nextDate.getTime();

    if (isHighOrEqual && isLow) {
      return (data[i] as any).name;
    }
  }
  return null;
}
