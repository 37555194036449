import React, {useState} from 'react';
import _ from 'lodash';
import {InputGroup} from 'react-bootstrap';
import './MainSearchbar.scss';
import {Icon} from '@trustle/component-library';

type PropsT = {
  setQuery: (v: string) => void;
  placeholder?: string;
  rightIcon?: JSX.Element;
  leftIcon?: boolean;
  small?: boolean;
} & React.InputHTMLAttributes<HTMLInputElement>;

/** @deprecated use Search */
function MainSearchbar(props: PropsT) {
  const {setQuery, placeholder, rightIcon, leftIcon, small, ...rest} = props;
  const [currentQuery, setCurrentAccessQuery] = useState('');

  return (
    <div className="main-search-bar input-group" data-testid={`mainSearchBarWrapper`}>
      <InputGroup className="">
        {leftIcon && (
          <InputGroup.Text className={`left-icon`}>
            <Icon type="search" />
          </InputGroup.Text>
        )}
        <input
          {...rest}
          type="text"
          data-testid="mainSearchBar"
          name="mainSearchQuery"
          value={currentQuery}
          placeholder={placeholder ? placeholder : 'Type to search available resources.'}
          className={`form-control ${leftIcon && 'with-left-icon'} ${small && 'small'} ${
            rightIcon && 'with-right-icon'
          } ${rest.className ?? ''}`}
          onChange={(e) => {
            const newQuery = e.target.value;
            setCurrentAccessQuery(newQuery);
            setQuery(_.escapeRegExp(newQuery.toLowerCase()));
          }}
        />
        {rightIcon && <InputGroup.Text className="right-icon">{rightIcon}</InputGroup.Text>}
      </InputGroup>
    </div>
  );
}

export default MainSearchbar;
