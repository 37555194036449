import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCreditCard} from '@fortawesome/free-solid-svg-icons';
import './PaymentMethod.scss';
import {faTimesCircle} from '@fortawesome/free-regular-svg-icons';
import {useRootStore} from 'src/lib/hooks';
import {observer} from 'mobx-react';
import {Confirmation} from '@trustle/component-library';

type PropsT = {
  pm: any;
  onClick?: () => void;
  selected?: boolean;
  removeAllowed?: boolean;
};

const PaymentMethod = observer(function PmCmp(props: PropsT) {
  const {pm, onClick, selected, removeAllowed} = props;
  const [showConfirmRemovePaymentMethod, setShowConfirmRemovePaymentMethod] = useState(false);
  const {org} = useRootStore();

  return (
    <div
      onClick={onClick ?? onClick}
      data-testid={`method-${pm.id}`}
      className={`payment-method ${props.onClick ? 'cursor' : ''} tr-flex tr-flex-row justify-content-start p-2 ${
        selected ? 'selected' : ''
      }`}
    >
      <div className="ml-3 mr-3">
        <FontAwesomeIcon icon={faCreditCard} />
      </div>
      <div className="capitalize-text text-navy font-weight-bold">
        {pm.card.brand} ****<span>{pm.card.last4}</span>
      </div>
      {removeAllowed && (
        <FontAwesomeIcon
          data-testid={`payment-method-remove${pm.id}`}
          icon={faTimesCircle}
          title="Remove Payment Method"
          className="ml-2 mr-2 remove"
          onClick={() => {
            setShowConfirmRemovePaymentMethod(true);
          }}
        />
      )}
      {showConfirmRemovePaymentMethod && (
        <Confirmation
          onConfirm={async () => {
            await org!.removePaymentMethod(pm.id);
          }}
          onClose={() => setShowConfirmRemovePaymentMethod(false)}
          title={'Confirm Payment Method Removal'}
        >
          <div>
            Please confirm that you want to remove {pm.card.brand} ****{pm.card.last4} card{' '}
          </div>
        </Confirmation>
      )}
    </div>
  );
});

export default PaymentMethod;
