import React from 'react';
import {EXPOSURE_COLOR} from '../utils';

type Props = {
  series: string[];
  licenseByKeyMap: Record<string, {name: string; color?: string}>;
};
export function CustomLegend({series, licenseByKeyMap}: Props) {
  return (
    <div className="tr-flex tr-gap-4 tr-mt-6 tr-text-xs">
      <div className="tr-flex tr-gap-1 tr-items-center">
        {legendIcon(EXPOSURE_COLOR)}
        <strong className="tr-text-[#64748B]">Exposure</strong>
      </div>
      {series.map((name) => (
        <div key={name} className="tr-flex tr-gap-1 tr-items-center">
          {legendIcon(licenseByKeyMap[name].color)}
          <strong className="tr-text-[#64748B]">{licenseByKeyMap[name].name}</strong>
        </div>
      ))}
    </div>
  );
}

function legendIcon(color?: string) {
  return (
    <div
      className="tr-rounded-full tr-w-4 tr-h-4 tr-flex tr-justify-center tr-items-center"
      style={{backgroundColor: color}}
    >
      <div className="tr-rounded-full tr-w-2 tr-h-2" style={{backgroundColor: 'white'}} />
    </div>
  );
}
