import React from 'react';
import ComplexModal from 'src/components/modals/ComplexModal';
import {AuthenticationPolicyT} from './authenticationTypes';

export const SwitchAuthModal: React.ElementType = (props: {
  policy: AuthenticationPolicyT;
  onConfirm: (policy: AuthenticationPolicyT) => void;
  onClose: () => void;
}) => {
  const {policy, onConfirm, onClose} = props;

  return (
    <ComplexModal
      id={'auth-settings-switch-modal'}
      icon={'warningCircle'}
      confirmBtnClassName={'btn-warning'}
      confirmButtonTitle={'Confirm'}
      cancelButtonTitle={'Cancel'}
      onConfirm={() => {
        onConfirm(policy);
      }}
      onClose={onClose}
      title={'Change Authentication Method?'}
      content={
        <div className="text-center small">
          <div>
            <p>
              <b>Changing the authentication method implies that:</b>
            </p>
            <p>
              Currently activated methods will be deactivated, setting the one selected as the only available
              authentication method for all users.
            </p>
          </div>
        </div>
      }
    />
  );
};
