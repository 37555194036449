import _ from 'lodash';
import React from 'react';
import {logger} from 'src/lib';
import {Field} from 'formik';

import {ResourceT, DurationUnitT, MinSensitivitySettingT} from 'src/types';
import axios from 'axios';
import {Form, SubmitButton} from 'src/components/design';
import {Formik, FormikForm} from 'src/components/design/formik';
import {SensitivityDefaultSelector} from 'src/components/sensitivity';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Button, TextAreaInput, TextInput, DurationInput} from '@trustle/component-library';
import {useRootStore} from 'src/lib/hooks';

type PropsT = {
  parent: ResourceT | Resource;
  sensitivitySetting?: MinSensitivitySettingT | null;
  close: () => void;
  afterSubmit: () => void;
};

type NewChildResourceFormT = {
  name: string;
  description: string;
  sensitivityId: string;
  approvalDuration: {
    durationValue: number;
    durationUnit: DurationUnitT;
  };
};
function ChildResource(props: PropsT) {
  const {org} = useRootStore();

  if (_.isNil(org)) {
    return <></>;
  }

  const {parent: parentResource, close, afterSubmit} = props;
  const {defaultSensitivityId} = org;
  const sensitivitySettings = org.sensitivitySettingsSorted;

  async function save(resource: NewChildResourceFormT) {
    try {
      await axios.post(` /api/resources/${parentResource.id}/children`, {
        ..._.pick(resource, ['name', 'description', 'sensitivityId']),
        durationUnit: resource.approvalDuration.durationUnit,
        durationValue: resource.approvalDuration.durationValue,
      });
      afterSubmit();
      close();
      return {error: false, msg: 'Saved the resource.'};
    } catch (err) {
      logger.error(err);
      return {error: true, msg: 'Failed to save the resource.'};
    }
  }

  return (
    <Formik
      initialValues={{
        name: '',
        description: '',
        sensitivityId: _.get(props.sensitivitySetting, 'id', defaultSensitivityId) as any,
        approvalDuration: {
          durationValue: 6,
          durationUnit: 'month',
        },
      }}
      onSubmit={save}
    >
      {({isSubmitting, values}) => {
        const selectedSensitivity = values.sensitivityId
          ? _.find(sensitivitySettings, {id: values.sensitivityId})
          : null;
        return (
          <FormikForm noValidate>
            <Field component={TextInput} label="Name" description="" name="name" required />
            <div className="form-group">
              <Field component={TextAreaInput} description="" label="Description" name="description" required />
            </div>
            <div className="mb-5">
              <Field name="sensitivityId">
                {({field}: any) => (
                  <Form.Group>
                    <SensitivityDefaultSelector
                      sensitivitySettings={sensitivitySettings!}
                      sensitivityId={field.value}
                      onSelect={({sensitivityId}) => {
                        field.onChange({target: {name: field.name, value: sensitivityId}});
                      }}
                    />
                  </Form.Group>
                )}
              </Field>
              <Field
                component={DurationInput}
                name="approvalDuration"
                maxDurationValue={selectedSensitivity?.maxApprovalDurationValue}
                maxDurationUnit={selectedSensitivity?.maxApprovalDurationUnit}
                titleMargin
                required
              />
            </div>
            <SubmitButton name="resourceSave" label="Save" inSubmit={isSubmitting} />
            <Button
              variant="secondary"
              onClick={() => {
                close();
              }}
            >
              Cancel
            </Button>
          </FormikForm>
        );
      }}
    </Formik>
  );
}

export default ChildResource;
