import {Button, Icon, Select} from '@trustle/component-library';
import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import _ from 'lodash';

type Props = {
  account: any;
};

// copied from `frontend/src/views/Resource/Account/MapUser.tsx`
const TrustleUserDropDown = observer((props: Props) => {
  const {account} = props;
  const [input, setInput] = useState<string>();

  return (
    <div className="d-flex align-items-center">
      <Select
        className="my-2"
        inputValue={input}
        id={`account-map-${account.account}`}
        placeholder="Trustle user's name"
        onInputChange={(input, {action}) => {
          if (action === 'input-change') {
            setInput(input);
          } else if (action === 'set-value') {
            setInput(input);
          }
        }}
        menuPortalTarget={document.querySelector('body')}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            fontSize: '12px',
            paddingLeft: '4px',
          }),
        }}
      />
      {!_.isEmpty(input) && (
        <Button
          style={{height: '2.4rem'}}
          variant="primary"
          id={`account-map-${account.account}-button`}
          key="complete"
          className={'tr-p-2 tr-ml-1'}
        >
          <Icon type="linked" title="Link User" forcedSize={20} />
        </Button>
      )}
    </div>
  );
});

export default TrustleUserDropDown;
