import {faCircleNotch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Alert, Button} from '@trustle/component-library';
import React from 'react';
import StepIndicator from 'src/components/StepIndicator';
import {ImportStateT, ImportStatus} from 'src/types';
import _ from 'lodash';

export default function TestConnection(props: {
  name: string;
  connectorStatus: string;
  connectionOwner?: any;
  onTest?: () => void;
  testing: boolean;
  errorMsg?: string;
  onUpdate?: () => void;
  updating: boolean;
  lastImport?: ImportStateT;
}) {
  const {name, connectorStatus, onTest, onUpdate, updating, testing, errorMsg} = props;

  const status =
    connectorStatus === ImportStatus.FINISHED
      ? 'completed'
      : connectorStatus === ImportStatus.FAILED
      ? 'failed'
      : connectorStatus === ImportStatus.PENDING
      ? 'processing'
      : 'pending';

  return (
    <div className="rounded-md tr-p-4 shadow-md bg-white text-trustle-dark">
      <div className="tr-flex tr-flex-row tr-my-4">
        <div className="tr-flex tr-flex-col">
          <div className="tr-flex body6">
            {testing && (
              <div className="tr-flex body6" title="Testing connection in progress">
                <FontAwesomeIcon className="tr-mx-4" icon={faCircleNotch} spin size="2x" />
              </div>
            )}
            {!testing && <StepIndicator status={status} />}
            <div className="tr-ml-2 body4">{name}</div>
          </div>
          <div className="tr-ml-8 body6 tr-mt-3">{props.connectionOwner}</div>
        </div>
        <div className="tr-flex align-items-center justify-content-end ml-5" style={{minWidth: '250px'}}>
          {!!onUpdate && (
            <Button variant="secondary" selected={updating} onClick={onUpdate}>
              Update
            </Button>
          )}
          <Button variant="secondary" onClick={onTest}>
            Test
          </Button>
        </div>
      </div>
      {errorMsg && (
        <Alert colorVariant="danger" title={'An error ocurred trying to validate the credentials'}>
          {_.map(_.split(errorMsg, '. '), (e) => {
            if (!_.isEmpty(e)) {
              return <div className="tr-mt-2">{e}.</div>;
            }
          })}
        </Alert>
      )}
    </div>
  );
}
