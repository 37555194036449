import {AuthorityServiceE} from '../../../../types';

export const oktaFields = {
  [AuthorityServiceE.OKTA]: {
    Username: 'login',
    'First name': 'firstName',
    'Last name': 'lastName',
    'Middle name': 'middleName',
    'Honorific prefix': 'honorificPrefix',
    'Honorific suffix': 'honorificSuffix',
    'Primary email': 'email',
    Title: 'title',
    'Display name': 'displayName',
    Nickname: 'nickName',
    'Profile Url': 'profileUrl',
    'Secondary email': 'secondEmail',
    'Mobile phone': 'mobilePhone',
    'Primary phone': 'primaryPhone',
    'Street address': 'streetAddress',
    City: 'city',
    State: 'state',
    'Zip code': 'zipCode',
    'Country code': 'countryCode',
    'Postal Address': 'postalAddress',
    'Preferred language': 'preferredLanguage',
    Locale: 'locale',
    'Time zone': 'timezone',
    'User type': 'userType',
    'Employee number': 'employeeNumber',
    'Cost center': 'costCenter',
    Organization: 'organization',
    Division: 'division',
    Department: 'department',
    ManagerId: 'managerId',
    Manager: 'manager',
  },
};
