import React, {useEffect, useMemo, useState} from 'react';
import Chart from 'react-apexcharts';
import {HEAT_MAP_OPTIONS} from './config';
import {Select} from '@trustle/component-library';
import {CustomLegend} from './internal/CustomLegend';
import './ApexHeatmap.css';
import {useLicenseHeatmap} from '../../hooks/useLicenseHeatmap';
import {HeatmapSeriesType, ViewType} from '../../types';
import NoData from '../../../../components/NoData';
import {HeatmapPagination} from './internal/HeatmapPagination';
import {HEATMAP_NULL_LABEL} from '../../constants';

const OPTIONS: {value: HeatmapSeriesType; label: string}[] = [
  {
    value: 'department',
    label: 'Departments',
  },
  {
    value: 'remote_role',
    label: 'Roles',
  },
  {
    value: 'team_name',
    label: 'Teams',
  },
  {
    value: 'title',
    label: 'Titles',
  },
];

type Props = {
  view: ViewType;
  licenses: string[];
  resourceId: string;
  setHeatmapLoading: (value: boolean) => void;
  showLicensesPanel: boolean;
  summarizeAllLicenses?: boolean;
  setSelection: (selection: {ykey: string; xkey: string; yval: string; xval: string}) => void;
};

const AVERAGE_LABEL = 'Average';
const Y_AXIS_PAGE_SIZE = 10;
const X_AXIS_PAGE_SIZE = 10;

// Workaround to handel unexpected values for heatmap rows/cols labels
const resolveRowColLabel = (value: any) => {
  if (!value || value === 'NULL') {
    return HEATMAP_NULL_LABEL;
  }
  return value;
};

const refreshChart = async (series: ApexAxisChartSeries | ApexNonAxisChartSeries) => {
  const chartInstance = ApexCharts.getChartByID('responsive-heatmap');
  if (chartInstance) {
    void chartInstance.updateSeries(series, true);
  }
};

const getYValueForChart = (value: any) => {
  if (value === null) {
    return -1; // Identifies null values when rendering the chart and assigns a different color.
  }
  return value * 100;
};

export function LicenseUsageHeatMap({
  view,
  licenses,
  resourceId,
  setHeatmapLoading,
  showLicensesPanel,
  summarizeAllLicenses,
  setSelection,
}: Props) {
  const [selectedYValue, setSelectedYValue] = useState<{value: HeatmapSeriesType; label: string}>();
  const [selectedXValue, setSelectedXValue] = useState<{value: HeatmapSeriesType; label: string}>();

  const [xAxisPage, setXAxisPage] = useState(0);
  const [yAxisPage, setYAxisPage] = useState(0);

  const {data: heatmapData, fetchLicenseUsageHeatmap, loading} = useLicenseHeatmap({resourceId});

  const resetPages = () => {
    setXAxisPage(0);
    setYAxisPage(0);
  };

  useEffect(() => {
    if (selectedYValue && selectedXValue) {
      resetPages();
      void fetchLicenseUsageHeatmap({
        view,
        licenses,
        x_attribute: selectedXValue.value,
        y_attribute: selectedYValue.value,
        allLicenses: summarizeAllLicenses,
      });
    }
  }, [licenses, view, selectedXValue, selectedYValue]);

  useEffect(() => {
    setHeatmapLoading(loading);
  }, [loading]);

  const series = useMemo(() => {
    if (!heatmapData || !heatmapData.data || !heatmapData.data.length) {
      return [];
    }
    const {heatmap_rows, heatmap_columns, data, heatmap_column_means, heatmap_row_means} = heatmapData;
    // Calculates all series for the heatmap
    const series = heatmap_rows.map((rowName, rowIndex) => {
      return {
        name: resolveRowColLabel(rowName),
        data: heatmap_columns.map((colName, colIndex) => {
          return {
            x: resolveRowColLabel(colName),
            y: getYValueForChart(data[rowIndex][colIndex]),
          };
        }),
      };
    });

    const pagedSeries = paginateSeries(series, xAxisPage, yAxisPage).reverse();

    // Calculates for column average (Adds new row average)
    const columnMeanSeries = () => {
      const currentColumns = pagedSeries[0].data;

      return [
        {
          name: AVERAGE_LABEL,
          data: [
            ...currentColumns.map(({x: colName}: {x: string}) => {
              const averageValue =
                colName === HEATMAP_NULL_LABEL ? 0 : heatmap_column_means[heatmap_columns.indexOf(colName)];
              return {
                x: resolveRowColLabel(colName),
                y: getYValueForChart(averageValue),
              };
            }),
            {
              x: AVERAGE_LABEL,
              y: Math.round(
                ([...heatmap_column_means, ...heatmap_row_means].reduce((sum, val) => sum + val, 0) /
                  [...heatmap_column_means, ...heatmap_row_means].length) *
                  100
              ),
            },
          ],
        },
      ];
    };

    // Calculates for row average (Adds new column average)
    const seriesWithRowMeans = pagedSeries.map((serie) => {
      const {name, data} = serie;
      const averageValue = name === HEATMAP_NULL_LABEL ? 0 : heatmap_row_means[heatmap_rows.indexOf(name)];
      return {
        name,
        data: [...data, {x: AVERAGE_LABEL, y: getYValueForChart(averageValue)}],
      };
    });

    const newSeries = [...columnMeanSeries(), ...seriesWithRowMeans];
    void refreshChart(newSeries);
    return newSeries;
  }, [heatmapData, xAxisPage, yAxisPage]);

  const showPagination = Boolean(heatmapData && heatmapData?.data?.length);

  useEffect(() => {
    void refreshChart(series);
  }, [showLicensesPanel]);

  const HEAT_MAP_OPTIONS_WITH_EVENTS = {
    ...HEAT_MAP_OPTIONS,
    chart: {
      ...HEAT_MAP_OPTIONS.chart,
      events: {
        // @ts-ignore
        dataPointSelection: function (event: any, chartContext: any, opts: {seriesIndex: any; dataPointIndex: any}) {
          const rowIndex = opts.seriesIndex; // Row index
          const columnIndex = opts.dataPointIndex; // Column index

          if (rowIndex !== undefined && columnIndex !== undefined) {
            const row = series[rowIndex].name; // Row name
            const column = series[rowIndex].data[columnIndex].x; // Column name
            // const value = series[rowIndex].data[columnIndex].y; // Cell value

            setSelection({
              ykey: selectedYValue?.label || '',
              xkey: selectedXValue?.label || '',
              yval: row,
              xval: column,
            });
          }
        },
      },
    },
  };

  return (
    <div>
      <div className="tr-flex tr-p-4 tr-gap-8">
        <CustomLegend />
        <div className="tr-flex tr-gap-4 tr-ml-auto tr-items-center tr-w-full tr-max-w-[50%]">
          <label className="tr-mb-0">Y Value</label>
          <Select
            name="y-value"
            defaultValue={selectedYValue}
            value={selectedYValue}
            options={OPTIONS.filter((o) => o.value !== selectedXValue?.value)}
            onChange={(e: any) => {
              setSelectedYValue(e);
            }}
            required
          />
          <label className="tr-mb-0">X Value</label>
          <Select
            name="x-value"
            defaultValue={selectedXValue}
            value={selectedXValue}
            options={OPTIONS.filter((o) => o.value !== selectedYValue?.value)}
            onChange={(e: any) => {
              setSelectedXValue(e);
            }}
            required
          />
        </div>
      </div>
      {showPagination && (heatmapData?.heatmap_columns?.length || 0) > X_AXIS_PAGE_SIZE && (
        <div className="tr-flex tr-justify-center tr-mt-8">
          <HeatmapPagination
            page={xAxisPage}
            setPage={setXAxisPage}
            pagesCount={getPageCount(heatmapData?.heatmap_columns, X_AXIS_PAGE_SIZE)}
          />
        </div>
      )}

      {!heatmapData || heatmapData?.data?.length === 0 ? (
        <NoData src={selectedYValue && selectedXValue ? '/russel-thinking.svg' : '/russel-curious.svg'}>
          <div className="tr-flex tr-flex-col tr-items-center tr-gap-4">
            <span className="tr-font-normal tr-max-w-[350px]">
              {loading
                ? 'Data is loading...'
                : selectedYValue && selectedXValue
                ? 'There is no data for this selection. Please try selecting different values or date range.'
                : 'Select the two values above to compare them. '}
            </span>
            {!loading && (
              <a
                className="tr-text-trustle-link tr-no-underline"
                href="https://learn.trustle.com/tutorial/installing-microsoft-365/"
                target="_blank"
                rel="noreferrer"
              >
                Questions? See documentation
              </a>
            )}
          </div>
        </NoData>
      ) : (
        <div className="tr-flex">
          {showPagination && heatmapData.heatmap_rows.length > Y_AXIS_PAGE_SIZE && (
            <div className="tr-my-auto">
              <HeatmapPagination
                page={yAxisPage}
                setPage={setYAxisPage}
                direction="vertical"
                pagesCount={getPageCount(heatmapData.heatmap_rows, Y_AXIS_PAGE_SIZE)}
              />
            </div>
          )}
          <div className="tr-w-full">
            <Chart options={HEAT_MAP_OPTIONS_WITH_EVENTS} series={series} type="heatmap" height={400} />
          </div>
        </div>
      )}
    </div>
  );
}

function getPageCount(array: any[] = [], pageSize: number) {
  const quotient = Math.floor(array.length / pageSize);
  const remainder = array.length % pageSize;
  return remainder > 0 ? quotient + 1 : quotient;
}

function paginateSeries(series: any[], xAxisPage: number, yAxisPage: number) {
  let pagedOnYaxis = sliceOnYAxis(series, yAxisPage);

  // Adjust if Y-axis page has fewer items than page size (for the last page)
  if (pagedOnYaxis.length < Y_AXIS_PAGE_SIZE) {
    const start = Math.max(series.length - Y_AXIS_PAGE_SIZE, 0);
    pagedOnYaxis = series.slice(start);
  }

  return pagedOnYaxis.map((serie) => {
    let pagedOnXaxis = sliceOnXAxis(serie, xAxisPage);

    // Adjust if X-axis page has fewer items than page size (for the last page)
    if (pagedOnXaxis.length < X_AXIS_PAGE_SIZE) {
      const start = Math.max(serie.data.length - X_AXIS_PAGE_SIZE, 0);
      pagedOnXaxis = serie.data.slice(start);
    }
    return {
      ...serie,
      data: pagedOnXaxis,
    };
  });
}

function sliceOnYAxis(series: any[], yAxisPage: number) {
  return series.slice(yAxisPage * Y_AXIS_PAGE_SIZE, (yAxisPage + 1) * Y_AXIS_PAGE_SIZE);
}

function sliceOnXAxis(serie: {data: any[]}, xAxisPage: number) {
  return serie.data.slice(xAxisPage * X_AXIS_PAGE_SIZE, (xAxisPage + 1) * X_AXIS_PAGE_SIZE);
}
