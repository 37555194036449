import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {useUserFilters} from '../../UserFiltersContext';
import {USER_STATUSES} from '../../constants';
import {UserStatus} from '../../types';
import {UsersSummaryResponse, useUsersSummary} from '../../hooks/useUsersSummary';
import {Tab} from '@trustle/component-library';

type UserStatusWUnselected = UserStatus | 'unselected';

export function StatusQuickFilter() {
  const {filters, setFilter} = useUserFilters();
  const {data: usersSummary, getUsersSummary} = useUsersSummary();
  const [selectedStatus, setSelectedStatus] = useState<UserStatus | undefined>();
  const tabs: UserStatusWUnselected[] = [...USER_STATUSES, 'unselected'];
  const unselectedIndex = tabs.length - 1;
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    void getUsersSummary({});
  }, []);

  useEffect(() => {
    const {status} = filters;
    setSelectedStatus(status);
    setSelectedIndex(status ? tabs.indexOf(status) : unselectedIndex);
  }, [filters]);

  const changeSelectedIndex = (number: number) => {
    if (number === selectedIndex) {
      setSelectedIndex(unselectedIndex);
    } else {
      setSelectedIndex(number);
    }

    const status = tabs[number];
    setFilter('status', status === selectedStatus ? '' : status);
  };

  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={changeSelectedIndex}>
      <Tab.List variant={'line'}>
        {tabs.map((status, index) => (
          <Tab
            key={status}
            className={unselectedIndex === index ? 'hidden' : ''}
            onClick={() => changeSelectedIndex(index)}
          >
            <span>{_.startCase(status)}</span>
            <span className="tr-ml-1">{getUsersSummaryValue(status, usersSummary)}</span>
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
}

function getUsersSummaryValue(status: UserStatusWUnselected, usersSummary?: UsersSummaryResponse) {
  if (!usersSummary || !status) {
    return '';
  }
  switch (status) {
    case 'active':
      return usersSummary.activeUserCount;
    case 'inactive':
      return usersSummary.inactiveUserCount;
    case 'disabled':
      return usersSummary.disabledUserCount;
    case 'offboarded':
      return usersSummary.offboardedUserCount;
  }
}
