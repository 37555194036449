import {Button, Modal} from '@trustle/component-library';
import React from 'react';

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  enabled: boolean;
};

export function ConfirmDisableEnableAuthority(props: Props) {
  const {onConfirm, onCancel, enabled} = props;
  const title = enabled ? 'Enable authority' : 'Disable authority';
  const confirmButtonLabel = enabled ? 'Enable' : 'Disable';

  const message = enabled
    ? 'This will enable the authority. Your users will keep in sync with your remote system.'
    : 'This will disable the authority. Remote system changes will not be synced with Trustle.';
  return (
    <Modal onClose={onCancel} title={title}>
      <div className="tr-flex tr-flex-col tr-gap-4">{message}</div>
      <div className="tr-flex tr-justify-end">
        <Button variant="secondary" label="Cancel" onClick={() => onCancel()} />
        <Button
          variant="primary"
          colorVariant={enabled ? 'primary' : 'danger'}
          label={confirmButtonLabel}
          onClick={() => onConfirm()}
        />
      </div>
    </Modal>
  );
}
