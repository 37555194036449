import React from 'react';
import {Task} from 'src/stores/domainObjects/Task';
import {ProvisionOptions} from 'src/types';
type ProvisionConfigT = {
  completeLabel: string;
  description: React.ReactNode;
  info: React.ReactNode;
  infoInHistory?: boolean;
};

const initiateDeprovisionText = {
  completeLabel: 'Initiate Deprovision',
  description: (
    <>
      Click <b>Initiate Deprovision</b> to begin the automatic deprovision task.
    </>
  ),
};

const initiateProvisionText = {
  completeLabel: 'Initiate Provision',
  description: (
    <>
      Click <b>Initiate Provision</b> to begin the automatic provision task.
    </>
  ),
};

const completeProvisionText = {
  completeLabel: `It's Complete`,
  description: (
    <>Please perform the change on the remote system manually, and then click 'It's Complete' upon completion.</>
  ),
};

const completeDeprovisionText = {
  completeLabel: `It's Complete`,
  description: (
    <>Please perform the change on the remote system manually, and then click 'It's Complete' upon completion.</>
  ),
};

const deprovisionTaskConfiguration: Record<string, ProvisionConfigT> = {
  connector_automatic: {
    ...initiateDeprovisionText,
    info: <>The connector is set to automatically deprovision, but an error occurred during deprovisioning.</>,
    infoInHistory: true,
  },
  connector_manual: {
    ...completeDeprovisionText,
    info: (
      <>
        The connector supports automatic deprovisioning, but the connector is set to manual initiation in Connection
        settings.
      </>
    ),
  },
  connector_unmanaged: {
    ...completeDeprovisionText,
    info: (
      <>
        The connector supports automatic deprovisioning, but the permission is set to be unmanaged in the Resource
        settings.
      </>
    ),
  },
  connector_readonly: {
    ...completeDeprovisionText,
    info: (
      <>
        The connector supports automatic deprovisioning, but the connector is set to read-only mode in Connection
        settings.
      </>
    ),
  },
  manual: {
    ...completeDeprovisionText,
    info: <>The connector does not support automatic provisioning.</>,
  },
  disabled: {
    ...completeProvisionText,
    info: (
      <>The connector does not support deprovisioning, because the connection is disabled in Connection settings.</>
    ),
  },
  // new deprovisioning modes:
  p_disabled: {
    ...completeProvisionText,
    info: (
      <>The connector does not support deprovisioning, because the connection is disabled in Connection settings.</>
    ),
  },
  p_off: {
    completeLabel: 'No action',
    description: 'The connector is set to off deprovision in system settings, so you could not take any action here',
    info: <>The connector is set to off deprovision in system settings.</>,
  },
  p_manual: {
    ...completeDeprovisionText,
    info: <>The connector is set to manual deprovision in system settings.</>,
  },
  p_interactive: {
    ...initiateDeprovisionText,
    info: <>The connector is set to interactive deprovision in system settings.</>,
  },
  p_automatic: {
    ...initiateDeprovisionText,
    info: <>The connector is set to automatically deprovision, but an error occurred during deprovisioning.</>,
    infoInHistory: true,
  },
};

const provisionTaskConfiguration: Record<string, ProvisionConfigT> = {
  //legacy old modes:
  connector_automatic: {
    ...initiateProvisionText,
    info: <>The connector is set to automatically provision, but an error occurred during provisioning.</>,
    infoInHistory: true,
  },
  connector_manual: {
    ...completeProvisionText,
    info: (
      <>
        The connector supports automatic provisioning, but the connector is set to manual initiation in Connection
        settings.
      </>
    ),
  },
  connector_unmanaged: {
    ...completeProvisionText,
    info: (
      <>
        The connector supports automatic provisioning, but the permission is set to be unmanaged in the Resource
        settings.
      </>
    ),
  },
  connector_readonly: {
    ...completeProvisionText,
    info: (
      <>
        The connector supports automatic provisioning, but the connector is set to read-only mode in Connection
        settings.
      </>
    ),
  },
  manual: {
    ...completeProvisionText,
    info: <>The connector does not support automatic provisioning.</>,
  },
  disabled: {
    ...completeProvisionText,
    info: <>The connector does not support provisioning, because the connection is disabled in Connection settings.</>,
  },
  // new provisioning modes:
  p_disabled: {
    ...completeProvisionText,
    info: <>The connector does not support provisioning, because the connection is disabled in Connection settings.</>,
  },
  p_off: {
    completeLabel: 'No action',
    description: 'The connector is set to off provision in system settings, so you could not take any action here',
    info: <>The connector is set to off provision in system settings.</>,
  },
  p_manual: {
    ...completeProvisionText,
    info: <>The connector is set to manual provision in system settings.</>,
  },
  p_interactive: {
    ...initiateProvisionText,
    info: <>The connector is set to interactive provision in system settings.</>,
  },
  p_automatic: {
    ...initiateProvisionText,
    info: <>The connector is set to automatically provision, but an error occurred during provisioning.</>,
    infoInHistory: true,
  },
};

export function getDeprovisionTaskConfiguration(accessRequest: Task) {
  const {provisionDetails} = accessRequest;
  if (provisionDetails?.connectionDisabled) {
    return provisionTaskConfiguration[`p_disabled`];
  }
  return deprovisionTaskConfiguration[`p_${provisionDetails?.deprovisionMode || ProvisionOptions.off}`];
}

export function getProvisionTaskConfiguration(accessRequest: Task) {
  const {provisionDetails} = accessRequest;
  if (provisionDetails?.connectionDisabled) {
    return provisionTaskConfiguration[`p_disabled`];
  }
  return provisionTaskConfiguration[`p_${provisionDetails?.provisionMode || ProvisionOptions.off}`];
}
