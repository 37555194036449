import React from 'react';
import {Link, Route, useHistory, useParams} from 'react-router-dom';
import {publishSessionEvent, useFeatureFlags, useRootStore} from 'src/lib/hooks';
import AdminNotifications from 'src/views/admin/AdminNotifications';
import AdminSecurity from 'src/views/admin/AdminSecurity';
import AdminSetupWrapper from 'src/views/admin/AdminSetupWrapper';
import TeamsTab from 'src/views/admin/teams/TeamsTab';
import BillingTab from 'src/views/admin/payments/BillingTab';
import {FacetIdEnum} from 'src/types';
import {Icon, Tab} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import planDescriptions from 'src/views/admin/payments/PlanDescriptions';
import AdminUsersStatuses from 'src/views/admin/users/AdminUserStatuses';
import {AdminUsersPanel} from '../views/v2/admin/admin-users-panel/AdminUsersPanel';

enum AdminTab {
  USERS = 'users',
  TEAMS = 'teams',
  SECURITY = 'security',
  NOTIFICATIONS = 'notifications',
  SETUP = 'setup',
  BILLING = 'billing',
}

const AdminBase = observer(() => {
  const history = useHistory();
  const {org, currentUser, usersStore} = useRootStore();
  const {tab} = useParams<{tab?: AdminTab}>();
  const featureFlagViewer = useFeatureFlags();
  const newAdminPanel = featureFlagViewer.isEnabled('enable_new_admin_panel');
  const newTeamsApi = featureFlagViewer.isEnabled('enable_teams_api');

  if (_.isNil(org) || _.isNil(currentUser)) {
    return <></>;
  }

  if (!currentUser?.isOrgOwner) {
    history.replace('/home');
  }

  const isFreePlan = org.facetId === FacetIdEnum.FREE_AWS;
  const billingEnabled = isFreePlan || org.subscription;
  const tabs = [AdminTab.USERS];
  if (!newTeamsApi) {
    tabs.push(AdminTab.TEAMS);
  }
  tabs.push(AdminTab.SECURITY, AdminTab.NOTIFICATIONS, AdminTab.SETUP);
  if (billingEnabled) {
    tabs.push(AdminTab.BILLING);
  }

  if (tab === undefined) {
    history.replace('/admin/users');
  }
  publishSessionEvent();

  const selectedIndex = Math.max(tabs.indexOf(tab!), 0);

  const plan = org?.subscriptionPlan ?? org?.package;
  const planDescription = plan?.name && planDescriptions[plan.name];
  const availableUsersPlan = planDescription?.usersLimit !== -1 ? planDescription?.usersLimit : 'Unlimited';
  const isClickable = org.subscriptionPlan || isFreePlan;
  return (
    <Tab.Group selectedIndex={selectedIndex}>
      <div className="tr-bg-trustle-royal pt-4 text-white mb-5">
        <div className="container">
          <div className="tr-flex tr-justify-between">
            <div className="tr-flex tr-flex-col">
              <div className="tr-flex tr-justify-between tr-items-center tr-pb-4">
                <div className="tr-flex">
                  <Icon type="admin" size="xl" className="tr-opacity-100" />
                  <h1 className="tr-ml-2 tr-mb-0">Admin</h1>
                </div>
              </div>
              <Tab.List variant="folder" className="tr-mb-0">
                {tabs.map((key) => (
                  <Tab
                    key={key}
                    as={Link}
                    to={`/admin/${key}${key === AdminTab.BILLING ? '/status' : ''}`}
                    data-testid={`admin-tab-${key}`}
                    className="text-white aria-selected:bg-white aria-selected:text-blue opacity-100 truncate tr-px-2 md:tr-px-4"
                  >
                    {key === AdminTab.SETUP
                      ? 'Setup Guide'
                      : key === AdminTab.USERS
                      ? 'User Management'
                      : _.upperFirst(key)}
                  </Tab>
                ))}
              </Tab.List>
            </div>
            {!usersStore.loading && (
              <div className="tr-flex tr-flex-col tr-justify-center">
                <div className="tr-flex tr-items-center tr-pb-2 tr-ml-1">
                  <div>
                    <span className="tr-text-2xl tr-font-bold tr-pr-4 tr-leading-[23px]">
                      <span data-testid="seatsNumber">{org.seats}</span>/{availableUsersPlan}
                    </span>
                  </div>
                  <div className="tr-leading-6">{planDescription?.name} Plan </div>
                  {isClickable && (
                    <div className="tr-flex tr-items-center">
                      <div className="tr-w-[1px] tr-h-[24px] relative tr-bg-gray-300 tr-mx-2"></div>{' '}
                      <Link className="tr-text-sm tr-leading-[23px] tr-text-white" to="/admin/billing/plan">
                        See more
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Tab.Panels as={'div'} className="container">
        <Tab.Panel key={AdminTab.USERS}>
          {newAdminPanel ? (
            <Route path={`/admin/${AdminTab.USERS}`} component={AdminUsersPanel} />
          ) : (
            <Route path={`/admin/${AdminTab.USERS}/:activeTab?`} component={AdminUsersStatuses} />
          )}
        </Tab.Panel>
        {!newTeamsApi && (
          <Tab.Panel key={AdminTab.TEAMS}>
            <Route path="/admin/teams/:activeTab?" component={TeamsTab} />
          </Tab.Panel>
        )}
        <Tab.Panel key={AdminTab.SECURITY} data-testid="admin-tab-security">
          <Route path="/admin/security/:activeTab?" component={AdminSecurity} />
        </Tab.Panel>
        <Tab.Panel key={AdminTab.NOTIFICATIONS}>
          <AdminNotifications />
        </Tab.Panel>
        <Tab.Panel key={AdminTab.SETUP}>
          <AdminSetupWrapper />
        </Tab.Panel>
        {billingEnabled && (
          <Tab.Panel key={AdminTab.BILLING}>
            <Route path="/admin/billing/:activeTab/:step?" component={BillingTab} />
          </Tab.Panel>
        )}
      </Tab.Panels>
    </Tab.Group>
  );
});

export default AdminBase;
