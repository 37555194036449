import _ from 'lodash';
import {makeAutoObservable} from 'mobx';

class ToastStore {
  pendingMessages: any[];

  constructor() {
    makeAutoObservable(this);
    this.pendingMessages = [];
  }

  add(message: JSX.Element | string, opts: _.Dictionary<any>) {
    this.pendingMessages = [...this.pendingMessages, {message, opts}];
  }

  success(message: JSX.Element | string) {
    this.pendingMessages = [...this.pendingMessages, {message, opts: {appearance: 'success', autoDismiss: true}}];
  }

  error(message: JSX.Element | string) {
    this.pendingMessages = [...this.pendingMessages, {message, opts: {appearance: 'error', autoDismiss: false}}];
  }

  getNextMessage(): any {
    return this.pendingMessages.shift();
  }
}

export default ToastStore;
