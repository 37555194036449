import React from 'react';
import {SystemSettingsActions} from './internal/system-actions/SystemSettingsActions';
import {Resource} from '../../../stores/domainObjects/Resource';
import {ConnectionServiceE} from '../../../types';
import {ImportStatus} from './internal/ImportStatus';
import {useRootStore} from '../../../lib/hooks';

type Props = {
  resource: Resource;
};

export function SettingsHeader({resource}: Props) {
  const {newResourceStore: resourceStore} = useRootStore();

  const refreshResource = () => {
    void resourceStore.loadDetailed(resource.id, true);
  };

  const {connector, name: resourceName} = resource;
  const lastImport = connector?.lastImport;

  const canEditResource = resource.userIsOwner;

  const showImportStatus =
    canEditResource && connector && connector.service !== ConnectionServiceE.GENERIC && lastImport;

  return (
    <div className="tr-flex tr-items-center tr-justify-between">
      <h1>{resourceName} Settings</h1>
      <div className="tr-flex tr-gap-4">
        {showImportStatus && <ImportStatus lastImport={lastImport} />}
        <SystemSettingsActions resource={resource} refreshResource={refreshResource} />
      </div>
    </div>
  );
}
