import React, {useEffect, useState} from 'react';
import Chart from 'react-apexcharts';
import {HeatmapSeriesType} from '../types';
import {CustomLegend} from '../internal/license-usage-heat-map/internal/CustomLegend';
import {Select} from '@trustle/component-library';
import {HEAT_MAP_OPTIONS} from '../internal/license-usage-heat-map/config';

const OPTIONS: {value: HeatmapSeriesType; label: string}[] = [
  {
    value: 'department',
    label: 'Departments',
  },
  {
    value: 'remote_role',
    label: 'Roles',
  },
  {
    value: 'team_name',
    label: 'Teams',
  },
  {
    value: 'title',
    label: 'Titles',
  },
];

const series = [
  {
    name: 'Average',
    data: [
      {x: 'Team1', y: 56},
      {x: 'Team2', y: 65},
      {x: 'Team3', y: 78},
      {x: 'Team4', y: 68},
      {x: 'Team5', y: 76},
      {x: 'Team6', y: 78},
      {x: 'Team7', y: 72},
      {x: 'Team8', y: 70},
      {x: 'Team9', y: 88},
      {x: 'Team10', y: 69},
      {x: 'Average', y: 72},
    ],
    group: 'apexcharts-axis-0',
  },
  {
    name: 'Finance',
    data: [
      {x: 'Team1', y: 55},
      {x: 'Team2', y: 62},
      {x: 'Team3', y: 77},
      {x: 'Team4', y: 49},
      {x: 'Team5', y: 68},
      {x: 'Team6', y: 85},
      {x: 'Team7', y: 74},
      {x: 'Team8', y: 63},
      {x: 'Team9', y: 91},
      {x: 'Team10', y: 58},
      {x: 'Average', y: 68},
    ],
    group: 'apexcharts-axis-0',
  },
  {
    name: 'Human Resources',
    data: [
      {x: 'Team1', y: 45},
      {x: 'Team2', y: 54},
      {x: 'Team3', y: 68},
      {x: 'Team4', y: 73},
      {x: 'Team5', y: 82},
      {x: 'Team6', y: 77},
      {x: 'Team7', y: 61},
      {x: 'Team8', y: 56},
      {x: 'Team9', y: 89},
      {x: 'Team10', y: 66},
      {x: 'Average', y: 67},
    ],
    group: 'apexcharts-axis-0',
  },
  {
    name: 'IT',
    data: [
      {x: 'Team1', y: 78},
      {x: 'Team2', y: 82},
      {x: 'Team3', y: 94},
      {x: 'Team4', y: 71},
      {x: 'Team5', y: 67},
      {x: 'Team6', y: 85},
      {x: 'Team7', y: 79},
      {x: 'Team8', y: 64},
      {x: 'Team9', y: 93},
      {x: 'Team10', y: 81},
      {x: 'Average', y: 79},
    ],
    group: 'apexcharts-axis-0',
  },
  {
    name: 'Marketing',
    data: [
      {x: 'Team1', y: 51},
      {x: 'Team2', y: 65},
      {x: 'Team3', y: 73},
      {x: 'Team4', y: 80},
      {x: 'Team5', y: 77},
      {x: 'Team6', y: 69},
      {x: 'Team7', y: 72},
      {x: 'Team8', y: 61},
      {x: 'Team9', y: 88},
      {x: 'Team10', y: 74},
      {x: 'Average', y: 71},
    ],
    group: 'apexcharts-axis-0',
  },
  {
    name: 'Sales',
    data: [
      {x: 'Team1', y: 44},
      {x: 'Team2', y: 58},
      {x: 'Team3', y: 65},
      {x: 'Team4', y: 77},
      {x: 'Team5', y: 85},
      {x: 'Team6', y: 73},
      {x: 'Team7', y: 63},
      {x: 'Team8', y: 68},
      {x: 'Team9', y: 90},
      {x: 'Team10', y: 62},
      {x: 'Average', y: 69},
    ],
    group: 'apexcharts-axis-0',
  },
  {
    name: 'Operations',
    data: [
      {x: 'Team1', y: 64},
      {x: 'Team2', y: 72},
      {x: 'Team3', y: 81},
      {x: 'Team4', y: 59},
      {x: 'Team5', y: 66},
      {x: 'Team6', y: 74},
      {x: 'Team7', y: 85},
      {x: 'Team8', y: 76},
      {x: 'Team9', y: 94},
      {x: 'Team10', y: 70},
      {x: 'Average', y: 74},
    ],
    group: 'apexcharts-axis-0',
  },
  {
    name: 'Legal',
    data: [
      {x: 'Team1', y: 53},
      {x: 'Team2', y: 49},
      {x: 'Team3', y: 67},
      {x: 'Team4', y: 78},
      {x: 'Team5', y: 85},
      {x: 'Team6', y: 64},
      {x: 'Team7', y: 69},
      {x: 'Team8', y: 75},
      {x: 'Team9', y: 83},
      {x: 'Team10', y: 62},
      {x: 'Average', y: 68},
    ],
    group: 'apexcharts-axis-0',
  },
  {
    name: 'Procurement',
    data: [
      {x: 'Team1', y: 40},
      {x: 'Team2', y: 52},
      {x: 'Team3', y: 71},
      {x: 'Team4', y: 66},
      {x: 'Team5', y: 79},
      {x: 'Team6', y: 85},
      {x: 'Team7', y: 54},
      {x: 'Team8', y: 63},
      {x: 'Team9', y: 92},
      {x: 'Team10', y: 58},
      {x: 'Average', y: 66},
    ],
    group: 'apexcharts-axis-0',
  },
  {
    name: 'Customer Support',
    data: [
      {x: 'Team1', y: 72},
      {x: 'Team2', y: 81},
      {x: 'Team3', y: 88},
      {x: 'Team4', y: 77},
      {x: 'Team5', y: 74},
      {x: 'Team6', y: 69},
      {x: 'Team7', y: 92},
      {x: 'Team8', y: 85},
      {x: 'Team9', y: 93},
      {x: 'Team10', y: 76},
      {x: 'Average', y: 81},
    ],
    group: 'apexcharts-axis-0',
  },
  {
    name: 'Research and Development',
    data: [
      {x: 'Team1', y: 63},
      {x: 'Team2', y: 77},
      {x: 'Team3', y: 86},
      {x: 'Team4', y: 72},
      {x: 'Team5', y: 89},
      {x: 'Team6', y: 95},
      {x: 'Team7', y: 74},
      {x: 'Team8', y: 67},
      {x: 'Team9', y: 93},
      {x: 'Team10', y: 81},
      {x: 'Average', y: 80},
    ],
    group: 'apexcharts-axis-0',
  },
];

type Props = {
  setSelection: (selection: {ykey: string; xkey: string; yval: string; xval: string}) => void;
  showSitesPanel: boolean;
};

export function SitesUsageHeatmap({setSelection, showSitesPanel}: Props) {
  const [selectedYValue, setSelectedYValue] = useState<{value: HeatmapSeriesType; label: string}>({
    value: 'department',
    label: 'Departments',
  });
  const [selectedXValue, setSelectedXValue] = useState<{value: HeatmapSeriesType; label: string}>({
    value: 'team_name',
    label: 'Teams',
  });

  const HEAT_MAP_OPTIONS_WITH_EVENTS = {
    ...HEAT_MAP_OPTIONS,
    chart: {
      ...HEAT_MAP_OPTIONS.chart,
      events: {
        dataPointSelection: function (event: any, chartContext: any, opts: {seriesIndex: any; dataPointIndex: any}) {
          const rowIndex = opts.seriesIndex; // Row index
          const columnIndex = opts.dataPointIndex; // Column index

          if (rowIndex !== undefined && columnIndex !== undefined) {
            const row = series[rowIndex].name; // Row name
            const column = series[rowIndex].data[columnIndex].x; // Column name
            // const value = series[rowIndex].data[columnIndex].y; // Cell value

            setSelection({
              ykey: selectedYValue.label,
              xkey: selectedXValue.label,
              yval: row,
              xval: column,
            });
          }
        },
      },
    },
  };

  const refreshChart = async (series: ApexAxisChartSeries | ApexNonAxisChartSeries) => {
    const chartInstance = ApexCharts.getChartByID('responsive-heatmap');
    if (chartInstance) {
      void chartInstance.updateSeries(series, true);
    }
  };

  useEffect(() => {
    void refreshChart(series);
  }, [showSitesPanel]);

  return (
    <div>
      <div className="tr-flex tr-p-4 tr-gap-8">
        <CustomLegend />
        <div className="tr-flex tr-gap-4 tr-ml-auto tr-items-center tr-w-full tr-max-w-[50%]">
          <label className="tr-mb-0">Y Value</label>
          <Select
            name="y-value"
            defaultValue={selectedYValue}
            value={selectedYValue}
            options={OPTIONS.filter((o) => o.value !== selectedXValue?.value)}
            onChange={(e: any) => {
              setSelectedYValue(e);
            }}
            required
          />
          <label className="tr-mb-0">X Value</label>
          <Select
            name="x-value"
            defaultValue={selectedXValue}
            value={selectedXValue}
            options={OPTIONS.filter((o) => o.value !== selectedYValue?.value)}
            onChange={(e: any) => {
              setSelectedXValue(e);
            }}
            required
          />
        </div>
      </div>

      <div className="tr-flex">
        <div className="tr-w-full">
          <Chart options={HEAT_MAP_OPTIONS_WITH_EVENTS} series={series} type="heatmap" height={400} />
        </div>
      </div>
    </div>
  );
}
