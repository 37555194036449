import {Confirmation} from '@trustle/component-library';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import {useDataLoader} from 'src/lib';
import {Permission} from 'src/stores/domainObjects/Permission';
type PropsT = {
  permission: Permission;
  closeModal: (permissionWasArchived: boolean) => void;
};

function ConfirmDeletePermissionModal(props: PropsT) {
  const {permission, closeModal} = props;
  const [accessResponse, accessControl] = useDataLoader(`/api/permissions/${props.permission.id}/check_archive_ready`);
  const [allowDelete, setAllowDelete] = useState<boolean>(false);

  useEffect(() => {
    setAllowDelete(!accessControl.isError && accessControl.isReady && accessResponse.numAccess <= 0);
  }, [accessResponse, accessControl]);

  async function runAction(e: any) {
    e.preventDefault();
    await axios.post(`/api/permissions/${permission.id}/delete`);
    closeModal(true);
  }

  return (
    <Confirmation
      onConfirm={allowDelete ? runAction : closeModal}
      onClose={() => closeModal(false)}
      title={allowDelete ? 'Confirm delete permission' : `Deprovision access before deleting`}
      confirmButtonLabel={allowDelete ? 'Confirm' : ''}
      cancelButtonLabel={allowDelete ? 'Cancel' : 'Close'}
    >
      <>
        {!allowDelete && (
          <div>
            This permission has {accessResponse?.numAccess} provisioned users associated with it, and they must be
            deprovisioned before this permission can be deleted.
          </div>
        )}
        {allowDelete && (
          <div>
            Please confirm that you would like to delete the permission: <strong>{permission.label}</strong>.
            <p className="mt-3">
              <small>This action will be remove all pending tasks for the permissions</small>
            </p>
          </div>
        )}
      </>
    </Confirmation>
  );
}

export default ConfirmDeletePermissionModal;
