import React from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import ReactJson from 'react-json-view';

type PropsT = {
  connectorsData: any;
};

const GoogleWorkspacePermissionMetadata = observer(function GoogleWorkspacePermissionMetadata(props: PropsT) {
  const {connectorsData} = props;
  if (_.isEmpty(connectorsData)) {
    return <>No metadata available</>;
  }
  return (
    <ReactJson
      name={false}
      collapsed={2}
      theme="tomorrow"
      collapseStringsAfterLength={30}
      sortKeys={true}
      src={_.map(connectorsData, (cd) => {
        return cd.data.data;
      })}
    />
  );
});

export default GoogleWorkspacePermissionMetadata;
