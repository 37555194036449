import React, {useMemo} from 'react';
import {DashboardCard} from '../DashboardCard';
import {AccessRecord} from '../../stores/domainObjects/AccessRecord';
import _ from 'lodash';
import {SystemIcons, SystemsAccordion} from '@trustle/component-library';
import {SystemCounts} from '@trustle/component-library/dist/molecules/SystemsModal/SystemsAccordion';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';

type Props = {
  accessRecords: AccessRecord[];
};

export function SummaryHeader({accessRecords}: Props) {
  const {accessesCountBySystemType, totalSystemsCount, accountsCount} = useMemo(() => {
    const accessesCountBySystemType = _.countBy(accessRecords, 'forSystem.type');
    const totalSystemsCount = _.size(_.keys(_.groupBy(accessRecords, 'forSystem.name')));
    const accountsCount = _.uniq(accessRecords.map((rec) => rec.forAccount?.id)).length;
    return {accessesCountBySystemType, totalSystemsCount, accountsCount};
  }, [accessRecords]);

  return (
    <DashboardCard>
      <div className="tr-flex tr-gap-12 tr-mt-4 tr-p-2">
        <div className="tr-flex tr-flex-col tr-text-start tr-justify-center">
          <p className="tr-text-[53px] tr-font-bold tr-text-trustle-primary">{accountsCount}</p>
          <label className="tr-text-xs tr-font-bold">Accounts</label>
        </div>
        {/*Divider*/}
        <div className="tr-flex tr-flex-col tr-border-gray-200 tr-border-[0px] tr-border-l-[2px] tr-border-solid"></div>
        <div className="tr-flex tr-gap-2 tr-items-center">
          <div className="tr-flex tr-flex-col tr-text-start tr-justify-center">
            <p className="tr-text-[53px] tr-font-bold tr-text-trustle-primary">{totalSystemsCount}</p>
            <label className="tr-text-xs tr-font-bold">Systems</label>
          </div>
          {/*TODO: This should be well calculated*/}
          {!_.isEmpty(accessesCountBySystemType) && (
            <SystemsAccordion {...(accessesCountBySystemType as unknown as SystemCounts)} showLabel={true} />
          )}
        </div>
        {/*Divider*/}
        <div className="tr-flex tr-flex-col tr-border-gray-200 tr-border-[0px] tr-border-l-[2px] tr-border-solid"></div>
        <div className="tr-flex tr-flex-col tr-justify-center ">
          <div className="tr-flex tr-gap-4">
            {!_.isEmpty(accessesCountBySystemType) ? (
              <>
                {Object.keys(accessesCountBySystemType).map((key) => (
                  <div key={key} className="tr-flex tr-gap-2 tr-items-center">
                    <SystemIcons name={key as SystemIconsTypesEnum} size="md" />
                    <p className="tr-text-[35px] tr-font-bold tr-text-trustle-primary tr-mb-0">
                      {accessesCountBySystemType[key]}
                    </p>
                  </div>
                ))}
              </>
            ) : (
              <p className="tr-font-bold tr-text-trustle-primary">No Accesses</p>
            )}
          </div>
          <label className="tr-text-xs tr-font-bold">Accesses</label>
        </div>
      </div>
    </DashboardCard>
  );
}
