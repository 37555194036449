import React from 'react';
import {
  Button,
  Checkbox,
  Confirmation,
  Icon,
  Modal,
  RoleIcon,
  Select,
  TextInput,
  ToggleSwitch,
  Tooltip,
  UserAvatarAndName,
} from '@trustle/component-library';
import {Field, Form, Formik} from 'formik';
import {boolean, object, string} from 'yup';
import _ from 'lodash';
import {useRootStore} from 'src/lib/hooks';
import {CreateUserRequestT} from 'src/stores/usersStore';
import {FacetIdEnum, UserType} from 'src/types';
import {User} from 'src/stores/domainObjects/User';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import {formatUserName} from '../../../lib';

const userTypeOpts = Object.values(UserType).map((value) => ({label: _.upperFirst(value), value}));

const roleOpts = [
  {label: 'User', value: 'user'},
  {label: 'Owner', value: 'owner'},
];

const initialValues: Partial<Omit<CreateUserRequestT, 'startDate' | 'role'>> & {
  role: 'user' | 'owner';
  teams: string[];
  manager?: string;
  activateUser: boolean;
} = {role: 'user', type: UserType.employee, allowLogin: true, teams: [], activateUser: false};

type Props = {
  show: boolean;
  setShow: (show: boolean) => void;
};

export function AddUserModal({show, setShow}: Props) {
  const history = useHistory();
  const {usersStore, org} = useRootStore();

  const isFreePlan = org.facetId === FacetIdEnum.FREE_AWS;

  return (
    <>
      {isFreePlan && org.billableUsersCount >= 5 ? (
        <>
          {show && (
            <Confirmation
              onConfirm={() => {
                history.push('/admin/billing/plan');
              }}
              onClose={() => {
                setShow(false);
              }}
              title="Upgrade to add another user"
              confirmButtonLabel="Upgrade"
            >
              {'To add another user, please upgrade to Teams version. You will get a free 14-day trial'}
            </Confirmation>
          )}
        </>
      ) : (
        <Formik
          initialValues={initialValues}
          validationSchema={object().shape({
            firstname: string().required('Please fill out this field'),
            lastname: string().required('Please fill out this field'),
            department: string(),
            remoteRole: string(),
            title: string(),
            email: string()
              .email('Invalid email')
              .required('Please fill out this field')
              .test('alreadyUsed', 'email is already being used', async (val) => {
                if (await string().email().required().validate(val)) {
                  return (await axios.get<{valid: boolean}>(`/api/users/validate/${val}`)).data.valid;
                }
                return false;
              }),
            type: string().required('Please select an employee type'),
            role: string().required('Please select a role'),
            allowLogin: boolean(),
            manager: string(),
            activateUser: boolean(),
          })}
          onSubmit={(values) => {
            try {
              void usersStore.createUser({
                firstname: values.firstname!,
                lastname: values.lastname!,
                department: values.department,
                remoteRole: values.remoteRole,
                title: values.title!,
                email: values.email!,
                managerEmail: values.manager ? usersStore.usersMap[values.manager].email : undefined,
                type: values.type!,
                allowLogin: values.allowLogin ?? true,
                startDate: new Date().toISOString(),
                isOrgOwner: values.role === 'owner',
                activateUser: values.activateUser,
              });
              setShow(false);
            } catch (e) {
              setShow(true);
            }
          }}
          enableReinitialize
        >
          {({isValid, dirty, values, setFieldValue, submitForm}) => {
            return (
              <Form className="text-dark-gray">
                <Modal
                  visible={show}
                  onClose={() => {
                    setShow(false);
                  }}
                  width="lg"
                  title={
                    <div className="tr-flex tr-items-center tr-gap-2">
                      <Icon type="addNewUser" size="md" /> Add New Trustle User
                    </div>
                  }
                  footer={
                    <>
                      <Button variant="secondary" onClick={() => setShow(false)}>
                        Cancel
                      </Button>
                      <Button
                        type="submit"
                        variant="primary"
                        disabled={!isValid || !dirty}
                        onClick={() => submitForm()}
                        data-testid="submit-add-user-btn"
                      >
                        Add New User
                      </Button>
                    </>
                  }
                >
                  <h2 className="tr-text-sm">Basic Information</h2>
                  <div className="tr-flex flex-wrap tr-gap-4">
                    <Field component={TextInput} label="First name *" name="firstname" />
                    <Field component={TextInput} label="Last name *" name="lastname" />
                  </div>
                  <div className="tr-flex flex-wrap tr-gap-4">
                    <Field component={TextInput} label="Username *" name="email" />
                    <Field component={TextInput} label="Department" name="department" />
                  </div>
                  <div className="tr-flex flex-wrap tr-gap-4">
                    <Field component={TextInput} label="Organization Role" name="remoteRole" />
                    <Field component={TextInput} label="Title" name="title" />
                  </div>
                  <hr className="tr-mb-4 tr-mt-0" />
                  <h2 className="tr-text-sm tr-mb-4">Assign Manager</h2>
                  <Field
                    data-testid="manager"
                    component={Select}
                    label="Manager"
                    className="tr-max-w-[50%] tr-pr-2"
                    name="manager"
                    options={usersStore.activeUsers}
                    formatOptionLabel={(user: User) => (
                      <UserAvatarAndName displayName={formatUserName(user)} size="xs" />
                    )}
                    filterOption={(option: {data: User}, searchText: string) => {
                      const {firstname, lastname, email} = option.data;
                      return `${firstname?.toLowerCase() ?? ''}${lastname?.toLowerCase() ?? ''}${
                        email?.toLowerCase() ?? ''
                      }`.includes(searchText);
                    }}
                  />
                  <hr className="tr-my-4" />
                  <h2 className="tr-text-sm tr-mb-4">Role and Access</h2>
                  <div className="tr-flex">
                    <Field
                      component={Select}
                      className="flex-1 mr-4"
                      label={<span data-testid="select-user-type">Trustle User Type</span>}
                      name="type"
                      formatOptionLabel={(userType: {label: string; value: UserType}) => (
                        <span className="tr-flex tr-gap-2 tr-items-center">
                          <RoleIcon type={userType.value} size={'sm'} /> {userType.label}
                        </span>
                      )}
                      options={userTypeOpts}
                      onChange={({value}: {value: UserType}) => {
                        if ([UserType.customer, UserType.system, UserType.contractor].includes(value)) {
                          setFieldValue('role', 'user', false);
                        }
                        if ([UserType.customer, UserType.system].includes(value)) {
                          setFieldValue('allowLogin', false, false);
                        }
                      }}
                    />
                    <Field
                      component={Select}
                      className="flex-1"
                      label="Trustle Role"
                      name="role"
                      options={roleOpts}
                      isDisabled={
                        values.type && [UserType.customer, UserType.system, UserType.contractor].includes(values.type)
                      }
                    />
                  </div>
                  <div className="tr-flex tr-gap-4 tr-items-center tr-mt-8">
                    <div className="tr-flex tr-items-center tr-gap-2 tr-w-full">
                      <ToggleSwitch
                        leftBackgroundColor="tr-bg-[#0067C4]"
                        rightBackgroundColor="tr-bg-gray"
                        checked={!!values.allowLogin}
                        onToggle={(e: any) => {
                          setFieldValue('allowLogin', e, false);
                        }}
                        name="allowLogin"
                        disabled={values?.type && [UserType.customer, UserType.system].includes(values.type)}
                      />
                      <span className="text-xs font-bold text-trustle-dark">Allow Trustle Login</span>
                    </div>

                    <div className="tr-flex tr-items-center tr-gap-2 tr-w-full tr-justify-end">
                      <Checkbox
                        className="tr-flex-none"
                        checked={values.activateUser}
                        onChange={(e: any) => setFieldValue('activateUser', e.target.checked)}
                        name="activateUser"
                      />
                      <span className="text-xs font-bold text-trustle-dark">Activate User</span>
                      <Tooltip
                        size="md"
                        content={
                          <div className="tr-grid tr-grid-cols-1 tr-gap-y-4 tl-w-90">
                            <div className="font-bold text-trustle-dark">Activate User.</div>
                            <div>
                              Activate User - If selected, this will enable the Trustle user and send an account setup
                              message to the user's email address.
                            </div>
                          </div>
                        }
                      >
                        <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                      </Tooltip>
                    </div>
                  </div>
                </Modal>
              </Form>
            );
          }}
        </Formik>
      )}
    </>
  );
}
