import _ from 'lodash';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {Filter} from 'src/Routes/Recommendations/AccountRecommendations/AccountRecommendations';
import {ConnectionServiceE, ResourceTabMode} from 'src/types';
import ResourceForm from '../Edit/ResourceForm';
import {ResourceOptionT} from './ConnectResourceSelector';

export const ManualSystemMetadata: ResourceOptionT = {
  label: 'Manual',
  description:
    "Use Trustle's workflow as a ticketing system to track permissions requests and changes. Administrators of the system will need to perform the work directly on the external system.",
  redirect: `/resources/create/manual`,
  upperText: 'Sign in to',
  bottomText: 'Manual',
  key: ConnectionServiceE.CUSTOM,
};

function ManualResource() {
  const history = useHistory();

  function onComplete(response: any) {
    const {resource} = response.data;
    history.push(
      `/resource/manage/${resource.id}/${ResourceTabMode.recommendations}/accounts/${Filter.ACCOUNTS_DETECTED}`
    );
  }

  return (
    <>
      <h2>{ManualSystemMetadata.label} System</h2>
      <p>
        {_.map(_.split(ManualSystemMetadata.description, '.'), (sentence: string, i) => {
          return (
            <span key={i}>
              {sentence}
              {!_.isEmpty(sentence) && '.'}
            </span>
          );
        })}
      </p>

      <ResourceForm descriptionInTitle={true} isCreating={true} cancel={() => {}} refresh={onComplete} />
    </>
  );
}

export default ManualResource;
