import React from 'react';
import {OrgEventsDataParamsT} from './OrgEvents';
import _ from 'lodash';
import {formatUserName} from 'src/lib';
import {ActivityReadableMapping, OrgEventEnum} from './types';
import {UserAvatarAndName} from '@trustle/component-library';

interface ReplaceBracesProps {
  type: OrgEventEnum;
  data: OrgEventsDataParamsT;
}

const EventHeader: React.FC<ReplaceBracesProps> = ({data, type}) => {
  return (
    <div className="text-left tr-m-2 tr-flex-col tr-justify-start tr-items-start">
      <div className="tr-font-bold">{ActivityReadableMapping[type as OrgEventEnum]}</div>
      <div>
        {data.resource && (
          <span>
            Resource: <b>{data.resource.name}</b>
          </span>
        )}
      </div>
      <div className="tr-flex tr-flex-col tr-justify-start tr-items-start">
        {data.actor && data.actor.id ? (
          <UserAvatarAndName wrapper={false} displayName={formatUserName(data.actor)} subtitle={data.actor.email} />
        ) : data.actor?.type ? (
          <span>Actor: {_.startCase(data.actor.type)}</span>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default EventHeader;
