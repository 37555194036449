import _ from 'lodash';
import {Task} from 'src/stores/domainObjects/Task';
import {TaskTypesLabelEnum} from 'src/types';
import {Alert} from '@trustle/component-library';
import React from 'react';
import {ColorVariantEnum} from '@trustle/component-library/dist/types';
import {formatUserName} from 'src/lib';

function filterRequests(accessRequests: Task[], filters: any) {
  let filtered = _.filter(accessRequests, (ar) => {
    return _.isNil(filters.Resource) || ar.forSystem?.name === filters.Resource;
  });

  filtered = _.filter(filtered, (ar) => {
    return _.isNil(filters.Sensitivity) || ar.sensitivity?.level === filters.Sensitivity;
  });

  filtered = _.filter(filtered, (task) => {
    return _.isNil(filters.Type) || TaskTypesLabelEnum[task.calculatedType] === filters.Type;
  });

  filtered = _.filter(filtered, (task) => {
    return _.isNil(filters.User) || formatUserName(task.forUser) === filters.User;
  });

  return filtered;
}

const NoActionAlert = (props: {
  title?: string;
  colorVariant?: ColorVariantEnum;
  className?: string;
  children?: React.ReactElement | string;
}) => {
  const {
    title = 'Trustle’s Connection disabled for this System',
    colorVariant = 'danger',
    children = `You couldn't perform any action because the connection for this system is disabled. Please, contact your system owner.`,
    className = '',
  } = props;

  let fontColor = 'tr-text-[#C72943]'; // danger by default
  if (colorVariant === 'warning') {
    fontColor = 'tr-text-[#A77D00]';
  }
  const cn = `${className}+ ${fontColor}`;
  return (
    <Alert title={title} colorVariant={colorVariant} className={cn}>
      <div className={`body5-normal ${cn}`}>{children}</div>
    </Alert>
  );
};

export const NoActionAlerts = (props: {connectionEnabledForSystem: boolean; isAllowedToAct: boolean}) => {
  if (props.connectionEnabledForSystem) {
    return <></>;
  }
  return <NoActionAlert />;
};

export {filterRequests};
