import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {SubmitButton, TrustleUserSelector} from 'src/components/design';
import {TeamT, TeamTypeEnum, UserT} from 'src/types';
import _ from 'lodash';
import {Loading} from '@trustle/component-library';
import {useToasts} from 'react-toast-notifications';
import {logger} from 'src/lib';
import {Button, Select} from '@trustle/component-library';

type PropsT = {
  selectedUsers: UserT[];
  afterSubmit: () => void;
};

type OptionType = {
  value: string;
  label: string;
};

export default function TeamForm(props: PropsT) {
  const {afterSubmit} = props;
  const {addToast} = useToasts();
  const defaultUsers = props.selectedUsers.map((user) => {
    return {value: user.id, label: user.email};
  });
  const [isLoading, setLoading] = useState<boolean>(false);
  const [teams, setTeams] = useState<TeamT[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<OptionType[]>(defaultUsers);
  const [selectedTeam, setSelectedTeam] = useState<OptionType>();
  const [managedTeams, setManagedTeams] = useState<TeamT[]>([]);
  const [manager, setManager] = useState<UserT | undefined>();

  useEffect(() => {
    async function loaderTeams() {
      await loadTeams();
    }
    void loaderTeams();
  }, []);

  useEffect(() => {
    async function loaderTeamsForManager() {
      await loadTeamsForManager();
    }
    void loaderTeamsForManager();
  }, [manager]);

  async function loadTeams(): Promise<void> {
    setLoading(true);
    const response = await axios.get(`/api/teams`);
    setTeams(response.data);
    setLoading(false);
  }

  async function loadTeamsForManager(): Promise<void> {
    if (manager) {
      const response = await axios.get(`/api/users/${manager.id}/teams`);
      const managedTeams = _.filter(response.data, (team: TeamT): boolean => {
        return !!team.managed && team.type !== TeamTypeEnum.OWNER;
      });

      setManagedTeams(managedTeams);
    } else {
      setManagedTeams([]);
    }
  }

  async function onSubmit() {
    if (!selectedTeam) {
      return;
    }

    const body = {
      members: _.map(selectedUsers, 'value'),
    };

    try {
      await axios.post(`/api/teams/${selectedTeam.value}/members`, body);

      addToast(`Team saved successfully`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (e: any) {
      const errorMessage = e.response.data.error.message || `An error occurred while saving your changes`;
      addToast(errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      });
      logger.error(e);
      return false;
    }
    return true;
  }

  if (isLoading) {
    return <Loading />;
  }

  const filteredTeams = (manager ? managedTeams : teams).map((team) => {
    return {value: team.id, label: team.name};
  });

  return (
    <div className="tr-my-2">
      <div className="tr-my-3 tr-flex tr-flex-wrap">
        <div className="tr-w-1/2">
          <TrustleUserSelector
            name="manager"
            suggestUrl="/api/orgs/users/suggest"
            label={'Select Manager'}
            id="manager"
            onChange={(selectedElement: UserT, _ids?: string[], _newUser?: any) => {
              setManager(_.isEmpty(selectedElement) ? undefined : selectedElement);
              setSelectedTeam({
                value: '',
                label: '',
              });
            }}
            multiple={false}
          />
        </div>
        <div className="tr-w-1/2">
          <Select
            label="Select Team"
            name="team"
            defaultValue={{
              value: '',
              label: '',
            }}
            value={selectedTeam}
            options={filteredTeams}
            onChange={(e: any) => {
              setSelectedTeam(e);
            }}
            required
          />
        </div>
        <div className="tr-w-full">
          <TrustleUserSelector
            name="members"
            suggestUrl="/api/orgs/users/suggest?type=suggestForTeams"
            label={`Users (${selectedUsers.length})`}
            id="members"
            multiple={true}
            defaultSelected={props.selectedUsers.map((user: UserT) => {
              return {value: user.id, label: user.email};
            })}
            onChange={(_selectedElement: UserT, _ids?: string[], newUser?: any[]) => {
              setSelectedUsers(
                _.map(newUser, (user: any) => {
                  return {value: user.id, label: user.label};
                })
              );
            }}
          />
        </div>
      </div>
      <div className="tr-flex tr-justify-between tr-items-center">
        <Button variant="secondary" onClick={afterSubmit}>
          Cancel
        </Button>
        <SubmitButton
          name="submit"
          label="Save"
          disabled={_.isEmpty(selectedUsers) || _.isEmpty(selectedTeam?.value)}
          inSubmit={isLoading}
          onClick={async () => {
            const submitWithoutErrors = await onSubmit();

            if (submitWithoutErrors) {
              afterSubmit();
            }
          }}
        />
      </div>
    </div>
  );
}
