import React, {createContext, PropsWithChildren, useContext} from 'react';
import useUrlSyncPaginationAndFilters, {
  PaginationParams,
} from 'src/views/v2/admin/admin-users-panel/hooks/useUrlSyncPaginationAndFilters';
import {AccountFilterKey} from '../../../admin/admin-users-panel/types';

export type AccountQueryFiltersAndPagination = {
  [key in AccountFilterKey]?: any;
} & PaginationParams;

type AccountFiltersContextType = {
  filters: AccountQueryFiltersAndPagination;
  setFilter: (key: keyof AccountQueryFiltersAndPagination, value: any) => void;
  setFilters: (filters: AccountQueryFiltersAndPagination) => void;
};

const AccountFiltersContext = createContext<AccountFiltersContextType | undefined>(undefined);

type AccountsFilterProviderProps = PropsWithChildren<{}>;

const DEFAULT_FILTERS: AccountQueryFiltersAndPagination = {
  linkStatus: 'linked',
  page: 1,
  size: 10,
};

export const AccountsFilterProvider: React.FC<AccountsFilterProviderProps> = ({children}) => {
  const [filters, setFilters] = useUrlSyncPaginationAndFilters(DEFAULT_FILTERS);

  return (
    <AccountFiltersContext.Provider
      value={{
        filters,
        setFilter: (key: keyof AccountQueryFiltersAndPagination, value: any) => {
          if (!value) {
            const toSet = {...filters};
            delete toSet[key];
            setFilters(toSet);
          } else {
            setFilters({...filters, [key]: value});
          }
        },
        setFilters,
      }}
    >
      {children}
    </AccountFiltersContext.Provider>
  );
};

export const useAccountFilters = () => {
  const context = useContext(AccountFiltersContext);
  if (context === undefined) {
    throw new Error('useAccountFilters must be used within a AccountsFilterProvider');
  }
  return context;
};
