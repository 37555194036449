import axios from 'axios';
import {makeAutoObservable, onBecomeObserved, runInAction} from 'mobx';
import {
  AccessRecordStateT,
  TaskT,
  JobT,
  ReferenceType,
  AccessStatusE,
  ConnectionServiceE,
  ConnectorsDataT,
} from 'src/types';
import RootStore from '..';
import {Account} from './Account';
import {Permission} from './Permission';
import {Resource} from './Resource';
import {User} from './User';

import {Task} from './Task';
import _ from 'lodash';
export class AccessRecord {
  id!: string;
  state?: AccessRecordStateT;
  username?: string;
  oid?: string;
  pid!: string;
  rid!: string;
  rootSid?: string;
  uid!: string;
  uaid?: string;
  accountType?: string;
  changeBy?: string;
  changeReason?: string;
  changeId?: string;
  task?: TaskT;
  isApproved!: boolean;
  isProvisioned!: boolean;
  expiresTs?: string;
  accessExpiresTs?: string;
  jobs!: JobT[];
  created!: string;
  reviewedAt?: string;
  changedAt?: string;
  accessDurationUnit?: string;
  accessDurationValue?: string;
  durationUnit?: string;
  durationValue?: string;
  accessPendingChanges?: string;
  refId?: string;
  connectorsData?: ConnectorsDataT = undefined;

  constructor(private rootStore: RootStore, attributes: Partial<AccessRecord>) {
    Object.assign(this, _.omit(attributes, 'invitedAt'));

    makeAutoObservable(this, {id: false});

    onBecomeObserved(this, 'connectorsData', async () => {
      try {
        // we should use a narrower endpoint here
        const res = await axios.get<ConnectorsDataT[]>(
          `/api/permissions/${this.forPermission.id}/connectors_data?includeAccesses=true`
        );
        runInAction(() => {
          this.connectorsData = res.data.find((e) => e.refType === 'access' && e.refId === this.id);
        });
      } catch (err) {
        runInAction(() => {
          this.connectorsData = undefined;
        });
      }
    });
  }

  get forResource(): Resource {
    return this.rootStore.newResourceStore.resourceMap[this.rid];
  }

  get forSystem(): Resource {
    return this.rootStore.newResourceStore.resourceMap[this.rootSid ?? this.rid];
  }

  get forPermission(): Permission {
    return this.rootStore.permissionStore.permissionMap[this.pid];
  }

  get status(): string {
    return this.rootStore.accessStore.statusArray[this.statusValue];
  }

  get hasAssociatedAccount(): boolean {
    if (this.forAccount?.uid) {
      return true;
    }
    if (this.forAccount?.refId && this.forAccount?.refType === ReferenceType.UserAccount) {
      return true;
    }
    return false;
  }

  get hasAssociatedResource(): boolean {
    if (this.forAccount?.refId && this.forAccount?.refType === ReferenceType.Resource) {
      return true;
    }
    return false;
  }

  get statusObject(): AccessRecordStateT {
    // isApproved, isProvisisioned, isPendingApproval, isPendingProvision, isPendingDeprovision, isPendingReapproval
    let state: AccessRecordStateT = {iA: false, iP: false, iPA: false, iPP: false, iPD: false, iPR: false};
    if (this.state && !_.isEmpty(this.state)) {
      state = this.state;
    } else {
      //This is temporary until we remove the flag. enable_fast_access
      const steps = (this.accessPendingChanges ?? '').split(',');

      state = {
        iA: this.isApproved,
        iP: this.isProvisioned,
        iPA: steps.includes('approval'),
        iPD: steps.includes('deprovision') || steps.includes('revoke') || steps.includes('expire'),
        iPP: steps.includes('provision'),
        iPR: steps.includes('reapproval'),
        wAE: this.expiresTs,
      };
    }

    return state;
  }

  get statusValue(): number {
    //Uid is for accesses belonging to users, refid for the ones that references to resources
    if (!(this.hasAssociatedAccount || this.hasAssociatedResource)) {
      return AccessStatusE.UnlinkedAccount;
    }
    const state = this.statusObject;
    if (state.iA && state.iP) {
      return AccessStatusE.Active;
    } else if (state.iPA || state.iPR || (state.iA && state.iPP)) {
      return AccessStatusE.Pending;
    } else if (state.iPD) {
      return AccessStatusE.Deprovisioning;
    } else if (state.iA) {
      return AccessStatusE.Approved;
    } else if (!state.iPA && !state.iPP && !state.iPR) {
      return AccessStatusE.Revoked;
    } else {
      return AccessStatusE.UnlinkedAccount;
    }
  }

  get isProvisionable(): boolean {
    const nonProvisionable = [
      AccessStatusE.Pending,
      AccessStatusE.Active,
      AccessStatusE.UnlinkedAccount,
      AccessStatusE.Revoked,
    ];

    if (this.forPermission.isLocked || _.includes(nonProvisionable, this.statusValue)) {
      return false;
    }

    return !this.forPermission.connectorModeDisabled && !this.forPermission.provisionModeOff;
  }

  get isDeprovisionable(): boolean {
    const nonDeprovisionable = [
      AccessStatusE.Deprovisioning,
      AccessStatusE.Approved,
      AccessStatusE.UnlinkedAccount,
      AccessStatusE.Revoked,
      AccessStatusE.Pending,
    ];

    if (this.forPermission.isLocked || _.includes(nonDeprovisionable, this.statusValue)) {
      return false;
    }

    if (this.forSystem.connector?.service === ConnectionServiceE.GENERIC) {
      return false;
    }

    return !this.forPermission.connectorModeDisabled && !this.forPermission.deprovisionModeOff;
  }

  get isRevokable(): boolean {
    const nonRevokableStatuses =
      this.statusValue === AccessStatusE.Revoked ||
      (this.statusValue === AccessStatusE.Pending && !this.statusObject.iA);

    if (this.forPermission.isLocked || nonRevokableStatuses) {
      return false;
    }

    if (
      this.forPermission.deprovisionModeOff &&
      (this.statusValue === AccessStatusE.Deprovisioning || this.statusObject.iP)
    ) {
      return false;
    }

    const revokableStatuses = [
      AccessStatusE.Pending,
      AccessStatusE.Approved,
      AccessStatusE.Active,
      AccessStatusE.Deprovisioning,
      AccessStatusE.UnlinkedAccount,
    ];
    return _.includes(revokableStatuses, this.statusValue);
  }

  get isCancelable(): boolean {
    const cancellableStatuses = [AccessStatusE.Pending, AccessStatusE.Deprovisioning];
    return !this.forPermission.isLocked && _.includes(cancellableStatuses, this.statusValue);
  }

  async provision(): Promise<void> {
    if (this.isProvisionable) {
      await axios.post(`/api/resources/${this.forPermission.refId}/access/${this.id}/modify/provision`);
      await this.forUser.loadAccesses();
      runInAction(() => (this.statusObject.iPP = true));
    }
  }

  async deprovision(): Promise<void> {
    if (this.isDeprovisionable) {
      await axios.post(`/api/resources/${this.forPermission.refId}/access/${this.id}/modify/deprovision`);
      await this.forUser.loadAccesses();
      runInAction(() => (this.statusObject.iPD = true));
    }
  }

  async revoke(): Promise<void> {
    if (this.isRevokable) {
      await axios.post(`/api/resources/${this.forPermission.refId}/access/${this.id}/modify/revoke`);
      await this.forUser.loadAccesses();
      runInAction(() => (this.statusObject.iA = false));
    }
  }

  async cancelPending(): Promise<void> {
    if (this.isCancelable) {
      await axios.post(`/api/resources/${this.forPermission.refId}/access/${this.id}/modify/cancel`);
      await this.forUser.loadAccesses();
      runInAction(() => (this.statusObject.iPA = false));
    }
  }

  get message(): string {
    if (this.statusObject.iA) {
      return 'Access Granted';
    }
    if (this.statusObject.iPA) {
      return 'Pending Manager Approval';
    }
    return 'Request Access again';
  }

  get sensitivityLevel(): string | undefined {
    return this.rootStore.org?.sensitivitySettings.find((s) => s.id === this.forPermission.sensitivityId)?.level ?? '';
  }

  get forAccount(): Account | undefined {
    if (this.uaid === undefined || this.forSystem?.accountIds === undefined) {
      return undefined;
    }

    return this.forSystem.accounts.find((a) => a.id === this.uaid);
  }

  get forUser(): User {
    const uid = this.uid || this.forAccount?.uid || '';
    return this.rootStore.usersStore.usersMap[uid];
  }

  get referencedResource(): Resource | undefined {
    if (!this.forAccount?.refId) {
      return;
    }
    return this.rootStore.newResourceStore.resourceMap[this.forAccount.refId];
  }

  getOwnerTaskForUser() {
    return this.rootStore.tasksStore.tasks.find((task: Task) => {
      return task.forAccess?.id === this.id;
    });
  }

  getTaskForRecord() {
    return this.rootStore.tasksStore.tasks.find((task: Task) => {
      return task.forAccess?.id === this.id;
    });
  }

  get additionalInformation(): ConnectorsDataT | undefined {
    switch (this.forResource.type) {
      case ConnectionServiceE.AWS_IDENTITY_CENTER:
        // the resource being of type AWS_IDENTITY_CENTER is not enough reason
        // to ask to the backend for the ConnectorsDataT
        // in addition to that, the access should relate
        // an AWS IDC Group with an AWS Account.
        // to add such condition, the ingest process should store
        // accesses/permissions differently than
        // for AWS IDC User - AWS IDC Group relationships.
        return this.connectorsData;
      default:
        return undefined;
    }
  }
}
