import React from 'react';
import {TabsDocsT} from '../DocSection';
import DocSection from '../DocSection';
import {AccountAccessType} from 'src/types';
import _ from 'lodash';
import {AccountTabTypeE} from 'src/views/Resource/Account/ResourceAccounts';

function AccountsCSVMappingGuide() {
  const columns = [
    {key: 'Last_name', description: "The user's last name.", subtext: 'e.g: Appleseed'},
    {key: 'First_name', description: "The user's first name.", subtext: 'e.g.: Jon T.'},
    {key: 'Account_type', description: 'The type of account.', subtext: `One of: ${_.values(AccountAccessType)}`},
    {
      key: 'Account',
      description: 'The account name in the remote system.',
      subtext: 'e.g.: accountName@remote-system.com, apiUser',
    },
    {
      key: 'Trustle_user',
      description: "The user's email address, which will be the primary username in Trustle.",
      subtext: 'e.g.: username@example.com',
    },
  ];

  const tabs: TabsDocsT = [
    {id: 'overview', label: 'Overview', description: overview(), active: true},
    {id: 'considerations', label: 'Considerations and scenarios', description: considerations(), active: false},
  ];

  function overview(): JSX.Element {
    return (
      <div>
        <p className="tr-w-75">
          In the context of a connector, accounts imported to Trustle from a remote system that are not linked to a
          Trustle user can be linked through a CSV file.
        </p>
        <div className={'border-bottom pt-3 mb-2'}>
          <p className={'mb-1'}>
            <strong>Column Definitions</strong>
          </p>
        </div>
        <p>The following list of columns is a specification of the required format for the CSV:</p>
        <div className={'pb-4'}>
          {columns.map((column: any) => (
            <div className={'mb-1'} key={column.key}>
              <b className={'text-capitalize pr-2'}>{`${column.key}:`}</b> {column.description}
              <br />
              <div className={'text-muted'}>{column.subtext}</div>
            </div>
          ))}
        </div>
        <p className="pb-3 tr-w-75">
          <div className={'border-bottom pt-3 mb-2'}>
            <p className={'mb-1'}>
              <strong>Steps to Link Accounts</strong>
            </p>
          </div>
          <div className="pl-4">
            <li>
              Go to <strong>Manage</strong> {'>'} Select your connector {'>'} Move to the <strong>Accounts</strong> tab.
            </li>
            <li>
              Select the <strong>{_.startCase(AccountTabTypeE.UNASSIGNED)}</strong> accounts tab.
            </li>
            <li>
              Click on <strong>Bulk Linking</strong> button.
            </li>
            <div className="my-4 image-wrap">
              <img src="/docs/csv/map_accounts_screen.png" className="vertical-align mb-4 mt-3 shadow overflow-auto" />
            </div>
            <li>Download the example.</li>
            <li>Review and edit the downloaded file, if needed.</li>
            <li>Upload the file in the current modal.</li>
            <li>Correct the errors, if any, and re-upload it as many times as needed.</li>
            <li>
              Finally, save the changes. Your linked accounts will be moved to the list in the{' '}
              <strong>{_.startCase(AccountTabTypeE.ASSIGNED)}</strong> accounts tab.
            </li>
          </div>
        </p>
      </div>
    );
  }

  function considerations(): JSX.Element {
    return (
      <div>
        <p className="pb-3 tr-w-75">
          The CSV file can be automatically generated based on the unlinked accounts in your connector by downloading
          the example in the upload modal, and then reviewed and edited if necessary before uploading it.
          <p></p>
          <div className="pl-4">
            <li>
              If Trustle is able to retrieve additional attributes related to the account from the remote system, the{' '}
              <strong>First Name</strong> and <strong>Last Name</strong> columns will be filled, otherwise the
              corresponding values will have a placeholder instead and they will require to be edited manually.
            </li>
            <li>
              The <strong>Account</strong> column must much one of the names in the Accounts list under the{' '}
              {AccountTabTypeE.UNASSIGNED} tab. In any other case, the entire row will be ignored when the file is
              processed.
            </li>
            <li>
              If Trustle identifies there's possible match between the current unlinked accounts and the existent
              Trustle users in the organization, the system will pick the best match suggestion and fill the{' '}
              <strong>Trustle User</strong> column with it. In any other case, Trustle will suggest a new unexistent
              username for the new user that will be created as a result of uploading this file.
            </li>
            <li>
              After the file is uploaded and processed, and before confirming to save the result of the parsing, Trustle
              will notify the number of rows that are ready to be saved and the rows that will be ignored, if any,
              specifing the reason. The system will also notify it there's any error related to the format of the file
              or a specific issue with any of the rows.
            </li>
          </div>
        </p>
      </div>
    );
  }

  return (
    <>
      <DocSection tabs={tabs} title="Guide: How to link accounts to users through CSV file upload" />
    </>
  );
}

export default AccountsCSVMappingGuide;
