import {formatTimestamp} from '../../../../../dashboard/m365-visibility/utils';
import React from 'react';
import {observer} from 'mobx-react';
import {useAuthorityLastSync} from '../../hooks/useAuthorityLastSync';

export const LastSyncLabel = observer(({givenDate}: {givenDate?: string}) => {
  const lastSync = useAuthorityLastSync();

  if (!lastSync) {
    return null;
  }

  return (
    <span className="tr-text-gray-400 tr-text-xs">
      <strong>Last Sync: </strong>
      {formatTimestamp(givenDate || lastSync)}
    </span>
  );
});
