import {Tooltip} from '@trustle/component-library';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import React from 'react';
import HistoryEventDetails from '../../../../../../../../User/Profile/HistoryDetails';
import {formatDateFromNow, formatFullDate, formatUserName} from '../../../../../../../../../lib';

export const HISTORY_COLUMNS = [
  {
    dataField: 'created',
    sort: true,
    text: 'Date',
    formatter: (cell: any, _row: any) => {
      return <Tooltip content={formatFullDate(cell)}>{formatDateFromNow(cell)}</Tooltip>;
    },
  },
  {
    dataField: 'event',
    sort: false,
    classes: 'text-left',
    headerClasses: 'text-left',
    text: 'Event',
    formatter: (cell: any, row: any) => {
      return <>{row.event ? cell : <span>{row.data ? _.capitalize(row.data.name) : ''}</span>}</>;
    },
  },
  {
    dataField: 'details',
    sort: false,
    classes: 'text-left',
    headerClasses: 'text-left',
    text: 'Details',
    formatter: (cell: any, row: any) => {
      return (
        <div className="row ">
          {row.event ? (
            <HistoryEventDetails event={row.event} dict={cell} />
          ) : (
            <span>{row.data ? `${_.capitalize(row.data.name)} using ${_.capitalize(row.data.mode)} mode` : ''}</span>
          )}
        </div>
      );
    },
  },
  {
    dataField: 'by',
    sort: true,
    classes: 'text-left',
    headerClasses: 'text-left',
    text: 'By',
    formatter: (cell: any, _row: any) => {
      const user = cell;
      if (_.isNil(user)) {
        return 'N/A';
      }
      if (_.isNil(user?.id)) {
        return formatUserName(user);
      }
      return (
        <Link to={`/users/${user?.id}`} target="_blank">
          {formatUserName(user)}
        </Link>
      );
    },
  },
];
