import {StatusActionIcon, Tooltip} from '@trustle/component-library';
import React from 'react';
import {getTimeRange, formatFullDate} from 'src/lib';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';

type PropsT = {access: AccessRecord};

export function AccessExpiration(props: PropsT) {
  const {access} = props;

  if (!access.accessExpiresTs && !access.expiresTs) {
    return <>Not set</>;
  }

  return (
    <Tooltip
      position="top"
      content={
        <div className="tooltip-wrapper">
          <div className="tr-flex tr-justify-start tr-items-start flex-column align-content-start p-1 tr-text-sm pl-2">
            <div className=" ">Access Expiration:</div>
            <div className="text-left">
              <b>{getTimeRange(access.accessExpiresTs)}</b>
            </div>
            <div className="text-left">{formatFullDate(access.accessExpiresTs)}</div>
          </div>
          <div className="tr-flex tr-justify-start tr-items-start flex-column align-content-start p-1 tr-text-sm pl-2">
            <div>Approval Expiration:</div>
            <div className="text-left">
              <b>{getTimeRange(access.expiresTs)}</b>
            </div>
            <div className="text-left tr-text-sm">{formatFullDate(access.expiresTs)}</div>
          </div>
        </div>
      }
    >
      <div className="tr-flex align-items-center">
        <StatusActionIcon action="revoke" size="sm" />
        <div className="ml-1">
          {getTimeRange(access.accessExpiresTs)} / {getTimeRange(access.expiresTs)}
        </div>
      </div>
    </Tooltip>
  );
}
