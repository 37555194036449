import React, {useState} from 'react';
import {useHistory, Link} from 'react-router-dom';
import {ImportStatus} from 'src/types';
import './ResourceCard.scss';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Icon, TileButton, Tooltip} from '@trustle/component-library';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';

type PropsT = {
  resource: Resource;
  isManaging?: boolean;
};

function ResourceMiniCard(props: PropsT) {
  const {resource: originalResource, isManaging} = props;
  const history = useHistory();
  const [descriptionVisibility, setDescriptionVisibility] = useState<boolean>(false);

  const url = `/resource${isManaging ? '/manage' : '/access'}`;

  function toggleDescriptionVisibility() {
    setDescriptionVisibility(!descriptionVisibility);
  }

  const resource = originalResource;

  const setupIcon = (() => {
    if (!isManaging) {
      return null;
    }

    if (resource.connector?.lastImport?.status === ImportStatus.FAILED) {
      const content = (
        <div className="tr-px-5">
          <div>
            <b>{'Broken Connection'}</b>
          </div>
          <div className="">
            {`Go to Connection Settings tab within this connector to fix the connection. 
            Trustle will not be able to track or perform any action until the connection is reestablished.`}
          </div>
        </div>
      );

      return (
        <div className="failing-system">
          <Link to={`/resource/manage/${resource.id}/settings/edit_connection`}>
            <Tooltip position="right" size="lg" content={content}>
              <Icon type="unlinkedUser" className="tr-mb-2 tr-text-white" size="sm" />
            </Tooltip>
          </Link>
        </div>
      );
    }

    if (resource.connectionId && resource.pendingSetup) {
      const content = (
        <div className="tr-px-5">
          <div>
            <b>{'Pending Connection'}</b>
          </div>
          <div className="">
            {`Go to the `}
            <a href={`/connect/reentry/${resource.connectionId}`}>Connector Setup screen</a>
            {` to finish configuring the system. 
            Trustle will not be able to track or perform any action until the connection is established.`}
          </div>
        </div>
      );

      return (
        <div className="pending-system">
          <Link to={`/connect/reentry/${resource.connectionId}`}>
            <Tooltip position="right" size="lg" content={content}>
              <Icon
                type={'cog'}
                onClick={() => {
                  toggleDescriptionVisibility();
                }}
              />
            </Tooltip>
          </Link>
        </div>
      );
    }

    return null;
  })();

  return (
    <div className="trustle-card--wrapper mini">
      <div className={`trustle-card mini`}>
        {!isManaging && resource.userHasAccess && (
          <span className="card--icon-left">
            <Icon type={'checkCircle'} />
          </span>
        )}
        {setupIcon}
        <TileButton
          name={resource.type! as SystemIconsTypesEnum}
          onClick={() => {
            history.push(`${url}/${resource.id}${isManaging ? '/overview' : ''}`);
          }}
        />
      </div>
    </div>
  );
}

export default ResourceMiniCard;
