import React from 'react';
import {DropdownItem, Icon} from '@trustle/component-library';

type Props = {
  onClick: () => void;
};

export function UnarchiveSystemAction({onClick}: Props) {
  return (
    <DropdownItem key="unarchive-system" onClick={onClick}>
      <div className="tr-flex tr-items-center tr-justify-between">
        <span>Unarchive</span>
        <Icon type="unArchive" size="sm" />
      </div>
    </DropdownItem>
  );
}
