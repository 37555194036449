import React from 'react';

export function EULAContent() {
  return (
    <>
      <div className="my-5" id="eula-content">
        {'DOCUMENT CONTENT WILL GO HERE'}
      </div>
    </>
  );
}
