import {ConnectionServiceE} from 'src/types';
import {History, Location} from 'history';

export function parseAndCleanUpQueryParams(history: History, location: Location, onError: (e: any) => void) {
  const queryParams = new URLSearchParams(location.search);

  if (queryParams.has('oauth_error')) {
    const error = queryParams.get('oauth_error');
    onError(error);
    queryParams.delete('oauth_error');
    history.replace({search: queryParams.toString()});
    return error;
  }
}

export const getAuthorizationURL = (
  connectionId: string,
  service: ConnectionServiceE,
  credentialsType: any,
  editMode: boolean
) => {
  const params = new URLSearchParams({
    connectionId: connectionId,
    editConnection: `${editMode}`,
    credentialsType,
    grantType: 'authorization_code',
  });

  return `/api/connect/${service}/oauth/start?${params.toString()}`;
};
