import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {Modal, ModalProps} from 'react-bootstrap';
import {Link, Route, useHistory, useParams} from 'react-router-dom';
import {Search} from 'src/components/Search/Search';
import {fuseSearch, useFeatureFlags} from 'src/lib';
import {useQueryStrings, useRootStore} from 'src/lib/hooks';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import {Account} from 'src/stores/domainObjects/Account';
import {Resource} from 'src/stores/domainObjects/Resource';
import {ConnectionServiceE, OwnerAccountAction, UserType} from 'src/types';
import AccountDetailModal, {TabModeE} from 'src/views/Resource/Account/AccountDetailsModal';
import ConfirmAccountActionModal from 'src/views/Resource/Account/ConfirmAccountActionModal';
import AccessDetailModal from 'src/views/Resource/Access/AccessDetailModal';
import * as AccountColumns from 'src/components/design/tables/SharedColumnDefinitions/AccountColumns';
import * as AccessColumns from 'src/components/design/tables/SharedColumnDefinitions/AccessColumns';
import {
  Loading,
  Button,
  CircleGauge,
  Confirmation,
  Icon,
  Table,
  Tooltip,
  Tab,
  Dropdown,
  DropdownItem,
  DropdownMenu,
} from '@trustle/component-library';
import {TableSetupEnum} from 'src/stores/userStore';
import {AccountTabTypeE} from 'src/views/Resource/Account/ResourceAccounts';
import FilterToggleBtn from 'src/components/FilterToggleBtn';

export enum Filter {
  ACCOUNTS_DETECTED = 'Accounts Detected',
  UNUSED_ACCOUNTS = 'Unused Accounts',
  ACCESS_REVIEW = 'Access Review',
}

type AccountsTablePropsT = {
  system: Resource;
  title: string;
  accounts: Account[];
  showNumAccessesColumn?: boolean;
  selected?: string[];
  allSelected?: boolean;
  setSelected?: (selected: string[]) => void;
  setSearchedAccounts?: (searchedAccountsNum: string[]) => void;
  tableKey: string;
};

const AccountsTable = observer(
  ({
    system,
    title,
    accounts,
    showNumAccessesColumn = false,
    selected,
    setSelected,
    setSearchedAccounts,
    tableKey,
  }: AccountsTablePropsT) => {
    const [query, setQuery] = useState('');
    const [filters, setFilters] = useQueryStrings({});
    const [showAccountDetails, setShowAccountDetails] = useState<Account>();
    const [accountDetailsTab, setAccountDetailsTab] = useState<TabModeE>();

    const filteredAccounts = accounts.filter(
      (account) =>
        _.isNil(filters['Trustle User Type']) ||
        filters['Trustle User Type'].split(',').includes(account.forUser?.type ?? 'unlinked')
    );

    const searchedAccounts = fuseSearch(
      query,
      ['account', 'forUser.firstname', 'forUser.lastname', 'forUser.email'],
      filteredAccounts
    );

    useEffect(() => setSearchedAccounts?.(_.map(searchedAccounts, 'id')), [query, accounts.length]);
    const columns = [
      AccountColumns.FlaggedForReviewColumn,
      AccountColumns.UserAccountColumn({}),
      AccountColumns.LastActivityColumn,
      {...AccountColumns.NumAccessesColumn, skip: !showNumAccessesColumn},
    ];

    if (system.type === ConnectionServiceE.AWS) {
      columns.push({
        ...AccountColumns.RiskScoreCol,
        formatExtraData: {
          onClick: (account: Account) => {
            setAccountDetailsTab(TabModeE.RISK_REPORT);
            setShowAccountDetails(account);
          },
        },
      });
    }

    if (system.retrieveUsageDataEnabled) {
      columns.push({
        ...AccountColumns.UsageCol,
        formatExtraData: {
          onClick: (account: Account) => {
            setAccountDetailsTab(TabModeE.SERVICE_USAGE);
            setShowAccountDetails(account);
          },
        },
      });
    }

    columns.push(
      AccountColumns.RecommendationsColumn,
      AccountColumns.ActionsColumn({showDetails: setShowAccountDetails})
    );

    const countByType = (type: UserType | undefined) => accounts.filter((acc) => acc.forUser?.type === type).length;
    return (
      <>
        <div className="tr-mx-2 tr-my-3 tr-flex tr-items-center">
          <h3 className="tr-mb-0 tr-mr-3">{title}</h3>
          <div className="tr-max-w-xl tr-mr-3 grow">
            <Search
              placeholder="Search"
              filterOptions={{
                'Trustle User Type': [..._.values(UserType), 'unlinked'],
              }}
              onChange={(query, filters) => {
                setQuery(query);
                setFilters(filters);
              }}
            />
          </div>
        </div>
        <div className="tr-my-3 tr-flex">
          <FilterToggleBtn
            filterKey="Trustle User Type"
            value={UserType.employee}
            disabled={countByType(UserType.employee) === 0}
          >
            <Icon type="employeeUser" className={`tr-mr-1`} size="sm" />
            Employee
            <span className="tr-ml-2">{countByType(UserType.employee)}</span>
          </FilterToggleBtn>
          <FilterToggleBtn
            filterKey="Trustle User Type"
            value={UserType.contractor}
            disabled={countByType(UserType.contractor) === 0}
          >
            <Icon type="contractorUser" className={`tr-mr-1`} size="sm" />
            Contractor
            <span className="tr-ml-2">{countByType(UserType.contractor)}</span>
          </FilterToggleBtn>
          <FilterToggleBtn
            filterKey="Trustle User Type"
            value={UserType.customer}
            disabled={countByType(UserType.customer) === 0}
          >
            <Icon type="customerUser" className={`tr-mr-1`} size="sm" />
            Customer
            <span className="tr-ml-2">{countByType(UserType.customer)}</span>
          </FilterToggleBtn>
          <FilterToggleBtn
            filterKey="Trustle User Type"
            value={UserType.system}
            disabled={countByType(UserType.system) === 0}
          >
            <Icon type="systemUser" className={`tr-mr-1`} size="sm" />
            System
            <span className="tr-ml-2">{countByType(UserType.system)}</span>
          </FilterToggleBtn>
          <FilterToggleBtn filterKey="Trustle User Type" value={'unlinked'} disabled={countByType(undefined) === 0}>
            <Icon type="unlinkedUser" className={`tr-mr-1`} size="sm" />
            Unlinked
            <span className="tr-ml-2">{countByType(undefined)}</span>
          </FilterToggleBtn>
        </div>
        <Table
          data={searchedAccounts}
          tableKey={tableKey}
          tableRid={system.id}
          rowClasses={(row: Account) => (row.isAuthzOwner ? 'authz-row' : '')}
          selectRow={
            selected
              ? {
                  mode: 'checkbox',
                  selected,
                  onSelect: ({id}, isSelect) => {
                    setSelected?.(isSelect ? _.uniq([...selected, id]) : _.without(selected, id));
                  },
                  onSelectAll: (isSelect) => setSelected?.(isSelect ? _.map(searchedAccounts, 'id') : []),
                }
              : undefined
          }
          columns={columns}
          striped={false}
          wrapperClasses="rounded-xl border border-black rounded"
          bordered={false}
        />
        {!_.isNil(showAccountDetails) && (
          <AccountDetailModal
            closeModal={() => setShowAccountDetails(undefined)}
            userAccount={showAccountDetails}
            mode={accountDetailsTab}
          />
        )}
      </>
    );
  }
);

type FlagAccountsModalPropsT = ModalProps & {
  selected: string[];
  setSelected: (selected: string[]) => void;
  isFlagging?: boolean;
};

/** modal shown when unused accounts are flagged */
const FlagAccountsModal = observer(({selected, setSelected, isFlagging, ...rest}: FlagAccountsModalPropsT) => {
  const {accountsStore} = useRootStore();
  const [isConfirming, setIsConfirming] = useState<boolean>(false);

  const hide = () => {
    rest.onHide();
    if (isConfirming) {
      setSelected([]);
    }
    // bootstrap modal hide animation takes a few ms, so modal state isn't reset immediately
    setTimeout(() => setIsConfirming(false), 250);
  };

  return (
    <Modal {...rest} onHide={hide}>
      <Modal.Header closeButton>
        {!isConfirming && (
          <Modal.Title className="font-weight-bold text-dark">
            <Icon type="flagOn" />
            {isFlagging ? 'Flag' : 'UnFlag'} {selected.length} Account{selected.length > 1 ? 's' : ''} for Review
          </Modal.Title>
        )}
      </Modal.Header>
      <Modal.Body>
        {isConfirming ? (
          <div className="tr-flex flex-column align-items-center">
            <img className="-m-5 tr-h-72 tr-w-72" src="/russel-excited.svg" alt="" />
            <Modal.Title className="m-3 text-xl text-center font-weight-bold text-dark">
              {selected.length} account{selected.length > 1 ? 's were' : ' was'} flagged for review
            </Modal.Title>
          </div>
        ) : (
          <p>
            This will {isFlagging ? 'flag' : 'unflag'} {selected.length} accounts for review and inform the user of what
            will happen with these accounts.
          </p>
        )}
      </Modal.Body>
      {isConfirming ? (
        <Modal.Footer className="tr-pt-0 tr-flex tr-flex-col tr-items-center tr-border-top-0">
          <Button onClick={hide}>Go back to recommendations</Button>
          <Button
            variant="ternary"
            onClick={() => {
              accountsStore.setFlaggedForReview(selected, false);
              hide();
            }}
          >
            Undo
          </Button>
        </Modal.Footer>
      ) : (
        <Modal.Footer>
          <Button variant="secondary" onClick={hide}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              accountsStore.setFlaggedForReview(selected, isFlagging ?? false);
              // skip 2nd screen when unflagging
              if (isFlagging) {
                setIsConfirming(true);
              } else {
                setSelected([]);
                hide();
              }
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      )}
    </Modal>
  );
});

const AccessTable = observer(({backLink}: {backLink: React.ReactNode}) => {
  const {uaid} = useParams<{uaid: string}>();
  const {accountsStore, accessStore} = useRootStore();
  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState<string[]>([]);
  const [selectedForAction, setSelectedForAction] = useState<string[]>(selected);
  const [accountAction, setAccountAction] = useState<OwnerAccountAction>();
  const [showDetails, setShowDetails] = useState<AccessRecord>();

  useEffect(() => setSelectedForAction(selected), [selected]);

  const account = accountsStore.accountsMap[uaid];

  if (!uaid || !account) {
    return <Loading />;
  }

  const searchedAccessRecords = fuseSearch(query, ['forResource.name', 'forPermission.label'], account.accesses ?? []);
  const selectedAccesses: AccessRecord[] = selectedForAction.map((id) => accessStore.accessMap[id]);

  const MultiActionComponent = observer(() => {
    if (_.isEmpty(selectedAccesses)) {
      return <></>;
    }

    const availableActions = [];
    if (selectedAccesses.every((access) => access.isDeprovisionable)) {
      availableActions.push(
        <DropdownItem key="deprovision" onClick={() => setAccountAction(OwnerAccountAction.START_DEPROVISION)}>
          Deprovision
        </DropdownItem>
      );
    }

    if (selectedAccesses.every((access) => access.isRevokable)) {
      availableActions.push(
        <DropdownItem key="revoke" onClick={() => setAccountAction(OwnerAccountAction.START_REVOKE)}>
          Revoke
        </DropdownItem>
      );
    }

    return _.isEmpty(availableActions) ? (
      <></>
    ) : (
      <Dropdown title="Select action" dropdownMenu={<DropdownMenu>{availableActions}</DropdownMenu>} />
    );
  });

  return (
    <>
      {backLink}
      <div className="tr-mx-2 tr-my-3 tr-flex tr-items-center">
        <h3 className="tr-mb-0 tr-mr-3">{account.account}'s Resource Access</h3>
        <div style={{width: '433px'}}>
          <Search placeholder="Search" onChange={(query) => setQuery(query)} />
        </div>
      </div>
      <Table
        data={searchedAccessRecords}
        tableKey={TableSetupEnum.RECOMENDATIONS_ACCESS}
        tableRid={account.rootResource.id}
        columns={[
          AccessColumns.StatusColumn,
          AccessColumns.ResourceNameColumn,
          AccessColumns.PermissionNameColumn,
          AccessColumns.LastActivityColumn,
          AccessColumns.AccessRiskScoreColumn,
          AccessColumns.AccessActionsColumn({
            showDetails: setShowDetails,
            deprovision: (access: AccessRecord) => {
              setSelectedForAction([access.id]);
              setAccountAction(OwnerAccountAction.START_DEPROVISION);
            },
            revoke: (access: AccessRecord) => {
              setSelectedForAction([access.id]);
              setAccountAction(OwnerAccountAction.START_REVOKE);
            },
          }),
        ]}
        selectRow={{
          mode: 'checkbox',
          selected,
          onSelect: ({id}, isSelect) => {
            setSelected?.(isSelect ? _.uniq([...selected, id]) : _.without(selected, id));
          },
          onSelectAll: (isSelect) => setSelected?.(isSelect ? _.map(searchedAccessRecords, 'id') : []),
        }}
        multiActionComponent={<MultiActionComponent />}
      />
      {accountAction && (
        <ConfirmAccountActionModal
          action={accountAction}
          accessRecords={selectedAccesses}
          closeModal={() => {
            setAccountAction(undefined);
          }}
        />
      )}
      <AccessDetailModal visible={!!showDetails} access={showDetails} closeModal={() => setShowDetails(undefined)} />
    </>
  );
});

const Empty = observer(({title, content}: {title?: React.ReactNode; content?: React.ReactNode}) => (
  <div className="tr-flex tr-flex-col tr-items-center">
    <img className="tr-w-72" src="/russel-magnifying-glass.svg" alt="" />
    <h2 className="tr-mt-0 tr-text-xl tr-text-center font-weight-bold tr-text-dark">{title}</h2>
    {content}
  </div>
));

type SystemRecommendationsPropsT = {system: Resource};
const AccountRecommendations = observer(({system}: SystemRecommendationsPropsT) => {
  const {accountsStore} = useRootStore();
  const {tab} = useParams<{tab: Filter}>();
  const history = useHistory();
  const featureFlagViewer = useFeatureFlags();
  const enableRecommendationReports = featureFlagViewer.isEnabled('enable_recommendation_reports');

  const [unusedAccountsSelected, setUnusedAccountsSelected] = useState<string[]>([]);
  const [searchedUnusedAccounts, setSearchedUnusedAccounts] = useState<string[]>([]);
  const [accountsToCertify, setAccountsToCertify] = useState<string[]>([]);
  const [flagAccountModalShowing, setFlagAccountModalShowing] = useState(false);
  const [isFlagging, setIsFlagging] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [showConfirmAccountCertifaction, setShowConfirmAccountCertification] = useState(false);

  const [unusedFlagged, unusedUnflagged] = _.partition(system.unusedAccounts, {flaggedForReview: true});

  const allUnusedAccountsSelected = searchedUnusedAccounts.length === unusedAccountsSelected.length;

  useEffect(() => {
    const refreshCounts = async () => await accountsStore.loadAccessesCount(system.id);
    void refreshCounts();
  }, []);

  useEffect(() => {
    if (system.connectionId) {
      void system.getConnectorStatus(system.connectionId);
    }
  }, [system.connectionId]);

  const accountsToCertifyCount = _.size(accountsToCertify);

  return (
    <div className="container tr-mx-auto system-recommendations">
      <Link className="my-3 tr-flex align-items-center" to={`/resource/manage/${system.id}/recommendations`}>
        <Icon type="back" className="tr-mr-1" />
        Go to Recommendations Main Page
      </Link>
      <div className="tr-flex tr-justify-between align-items-center">
        <h1 className="tr-mb-0">Accounts</h1>
        <div className="tr-flex align-items-center">
          <h2 className="tr-mb-0 tr-mr-3 text-xl">Trustle Score</h2>
          <CircleGauge
            className="tr-w-16 tr-h-16"
            circles={[{color: '#32C5FF', percent: system.percentOfAccountRecsCompleted}]}
          >
            <span className="text-xl font-weight-bold" data-cy="score">
              {Math.round(100 * system.percentOfAccountRecsCompleted)}%
            </span>
          </CircleGauge>
        </div>
      </div>
      <hr />
      <div className="tr-flex tr-flex-wrap">
        {[
          {
            number: _.filter(system.accounts, {isOffboarded: false, refId: null}).length,
            option: Filter.ACCOUNTS_DETECTED,
            testId: 'accounts-detected',
            btnTxt: 'Create Report',
            hideButton: !enableRecommendationReports,
            description: (
              <div>
                To gain insight into the current state of all accounts within your system and ensure completeness, we
                suggest generating a comprehensive report. We recommend linking unlinked accounts to Trustle users, this
                will enable you to review and manage all accounts in your system effectively.
              </div>
            ),
          },
          {
            number: _.filter(system.unusedAccounts, {isOffboarded: false, refId: null}).length,
            option: Filter.UNUSED_ACCOUNTS,
            testId: 'unused-accounts',
            btnTxt: `${selectedIndex === 1 ? 'Unflag' : 'Flag'} ${
              _.isEmpty(unusedAccountsSelected) ? '' : allUnusedAccountsSelected ? 'All' : unusedAccountsSelected.length
            } For Review`,
            percentage: Math.round(100 * system.percentOfUnusedAccountsUnflagged),
            description: (
              <div>
                Review and eliminate abandoned or unused accounts. We recommend conducting a thorough audit of accounts
                that have access and promptly removing any lingering accounts that were never cleaned up, to enhance
                security and optimize account management.
              </div>
            ),
          },
          {
            number: _.filter(system.unreviewedAccounts, {isOffboarded: false, refId: null}).length,
            option: Filter.ACCESS_REVIEW,
            testId: 'access-review',
            btnTxt: `Certify ${
              accountsToCertifyCount === 0
                ? 'Access'
                : accountsToCertifyCount === _.size(system.unreviewedAccounts)
                ? 'All Accounts'
                : accountsToCertifyCount > 1
                ? `${accountsToCertifyCount} Accounts`
                : `1 Account`
            }`,
            percentage: Math.round(100 * system.percentOfAccountsUnreviewed),
            description: (
              <div>
                Regularly authorize and evaluate current account access status, including trimming down over-provisioned
                access and assessing if each account still requires all access. This helps mitigate security risks and
                identify high-risk accounts.
              </div>
            ),
          },
        ].map(({number, option, btnTxt, percentage, hideButton, description, testId}) => (
          <div key={option} className="tr-mb-4 tr-flex tr-mx-[15px]">
            <Tooltip content={description} position="bottom" size="lg">
              <div
                role="button"
                className={`tr-text-left tr-flex align-items-center tr-justify-between shadow-lg rounded-xl px-3 py-4 tr-h-full relative ${
                  tab === option ? 'text-white bg-navy' : 'text-blue'
                }`}
                onClick={() => history.push(`/resource/manage/${system.id}/recommendations/accounts/${option}`)}
                aria-disabled={tab === option}
                data-testid={testId}
              >
                <div className="tr-px-3">
                  <h2 className="tr-text-5xl" data-cy={option}>
                    {number}
                  </h2>
                  <p className="tr-m-0 tr-text-lg">{option}</p>
                </div>
                {!_.isNil(percentage) && (
                  <div className="absolute tr-top-0 tr-right-0 tr-pt-1 tr-pr-3">{percentage}%</div>
                )}
                {!hideButton && (
                  <Button
                    onClick={() => {
                      switch (tab) {
                        case Filter.ACCOUNTS_DETECTED:
                          window.open(`/api/accounts/report?rid=${system.id}`);

                          break;
                        case Filter.UNUSED_ACCOUNTS:
                          if (_.isEmpty(unusedAccountsSelected)) {
                            setUnusedAccountsSelected(searchedUnusedAccounts);
                          }
                          setIsFlagging(selectedIndex !== 1);
                          setFlagAccountModalShowing(true);

                          break;
                        default:
                          setShowConfirmAccountCertification(true);
                      }
                    }}
                    disabled={(() => {
                      if (tab !== option) {
                        return true;
                      }

                      switch (tab) {
                        case Filter.ACCOUNTS_DETECTED:
                          return false;
                        case Filter.UNUSED_ACCOUNTS:
                          return _.isEmpty(unusedAccountsSelected);
                        default:
                          return _.isEmpty(accountsToCertify);
                      }
                    })()}
                  >
                    {btnTxt}
                  </Button>
                )}
              </div>
            </Tooltip>
          </div>
        ))}
      </div>
      <Route exact path={`/resource/manage/${system.id}/recommendations/accounts/${Filter.ACCOUNTS_DETECTED}/:uaid`}>
        <AccessTable
          backLink={
            <Link to={`/resource/manage/${system.id}/recommendations/accounts/${Filter.ACCOUNTS_DETECTED}`}>
              <Icon type="back" className="tr-mr-1" />
              Go back to Accounts Detected
            </Link>
          }
        />
      </Route>
      <Route exact path={`/resource/manage/${system.id}/recommendations/accounts/${Filter.ACCOUNTS_DETECTED}`}>
        <AccountsTable
          system={system}
          accounts={system.activeAccounts}
          title="Accounts Detected"
          tableKey={`${TableSetupEnum.RECOMENDATIONS}_accounts_detected`}
        />
      </Route>
      <Route exact path={`/resource/manage/${system.id}/recommendations/accounts/${Filter.UNUSED_ACCOUNTS}/:uaid`}>
        <AccessTable
          backLink={
            <Link to={`/resource/manage/${system.id}/recommendations/accounts/${Filter.UNUSED_ACCOUNTS}`}>
              <Icon type="back" className="tr-mr-1" />
              Go back to Unused Accounts
            </Link>
          }
        />
      </Route>
      <Route exact path={`/resource/manage/${system.id}/recommendations/accounts/${Filter.UNUSED_ACCOUNTS}`}>
        {(() => {
          if (_.isEmpty(unusedUnflagged)) {
            return (
              <Empty
                title={
                  <>
                    <Icon type="flagOff" size="sm" className="tr-mr-2 tr-h-7 tr-w-7" title="flagged for review" />
                    {_.isEmpty(unusedFlagged)
                      ? `There are no unused accounts detected`
                      : `All unused accounts are flagged`}
                  </>
                }
                content={
                  <Link to={`/resource/manage/${system.id}/accounts/${AccountTabTypeE.FLAGGED}`}>
                    See all flagged accounts here
                  </Link>
                }
              />
            );
          } else if (_.isEmpty(unusedFlagged)) {
            return (
              <AccountsTable
                system={system}
                accounts={unusedUnflagged}
                title="Unused Accounts"
                selected={unusedAccountsSelected}
                setSelected={setUnusedAccountsSelected}
                setSearchedAccounts={setSearchedUnusedAccounts}
                tableKey={`${TableSetupEnum.RECOMENDATIONS}_unused_accounts`}
              />
            );
          } else {
            return (
              <Tab.Group selectedIndex={selectedIndex} onChange={(i: number) => setSelectedIndex(i)}>
                <Tab.List>
                  <Tab data-testid={'unflagged'} key={'unflagged'}>
                    {`Unused Accounts ${unusedUnflagged.length}`}
                  </Tab>
                  <Tab data-testid={'flagged'} key={'flagged'}>
                    {`Recently Flagged ${unusedFlagged.length}`}
                  </Tab>
                </Tab.List>
                <Tab.Panels>
                  <Tab.Panel
                    data-testid={'unflagged'}
                    key={'unflagged'}
                    onChange={(e: number) => {
                      setSelectedIndex(e);
                    }}
                  >
                    <AccountsTable
                      system={system}
                      accounts={unusedUnflagged}
                      title="Unused Accounts"
                      selected={unusedAccountsSelected}
                      setSelected={setUnusedAccountsSelected}
                      setSearchedAccounts={setSearchedUnusedAccounts}
                      tableKey={`${TableSetupEnum.RECOMENDATIONS}_unused_accounts`}
                    />
                  </Tab.Panel>
                  <Tab.Panel data-testid={'flagged'} key={'flagged'}>
                    <AccountsTable
                      system={system}
                      accounts={unusedFlagged}
                      title="Recently Flagged"
                      selected={unusedAccountsSelected}
                      setSelected={setUnusedAccountsSelected}
                      setSearchedAccounts={setSearchedUnusedAccounts}
                      tableKey={`${TableSetupEnum.RECOMENDATIONS}_recently_flagged`}
                    />
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            );
          }
        })()}
      </Route>
      <Route exact path={`/resource/manage/${system.id}/recommendations/accounts/${Filter.ACCESS_REVIEW}/:uaid`}>
        <AccessTable
          backLink={
            <Link to={`/resource/manage/${system.id}/recommendations/accounts/${Filter.ACCESS_REVIEW}`}>
              <Icon type="back" className="tr-mr-1" />
              Go back to Access Review
            </Link>
          }
        />
      </Route>
      <Route exact path={`/resource/manage/${system.id}/recommendations/accounts/${Filter.ACCESS_REVIEW}`}>
        {_.isEmpty(system.unreviewedAccounts) ? (
          <Empty title="There are no accesses that need review" />
        ) : (
          <AccountsTable
            system={system}
            accounts={system.unreviewedAccounts}
            title="Access Review"
            selected={accountsToCertify}
            setSelected={setAccountsToCertify}
            showNumAccessesColumn={true}
            tableKey={`${TableSetupEnum.RECOMENDATIONS}_access_review`}
          />
        )}
      </Route>
      <FlagAccountsModal
        selected={unusedAccountsSelected}
        setSelected={setUnusedAccountsSelected}
        isFlagging={isFlagging}
        centered
        show={flagAccountModalShowing}
        onHide={() => setFlagAccountModalShowing(false)}
      />
      {showConfirmAccountCertifaction && (
        <Confirmation
          onConfirm={() => {
            void accountsStore.markAsReviewed(accountsToCertify);
            setShowConfirmAccountCertification(false);
            setAccountsToCertify([]);
          }}
          onClose={() => setShowConfirmAccountCertification(false)}
          title="Confirm Action"
        >
          <div>Certify {accountsToCertify.length > 0 ? `${accountsToCertify.length} accounts` : `1 account`}</div>
        </Confirmation>
      )}
    </div>
  );
});

export default AccountRecommendations;
