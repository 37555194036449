import React from 'react';
import {formatDateFromNow, formatFullDate, humanize} from 'src/lib';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import {Badge, Table} from '@trustle/component-library';
import moment from 'moment';
import {DataParamsT} from 'src/types';
import ReactJsonView from 'react-json-view';
import NoData from 'src/components/NoData';

/**
 * Component that automatic format a dictionary data to a table using SimpleTable
 * By default apply:
 * - badge for false/true values
 * - date format for date time values
 * - put a '-' when for empty values
 * - put object formatter if it is an object
 * - put just the text/number/symbol in other cases
 */
const AutoFormatMetadataTable = observer((props: {data?: _.Dictionary<any> | DataParamsT}) => {
  if (_.isEmpty(props.data)) {
    return <NoData>Hmm, we couldn’t seem to find any metadata yet.</NoData>;
  }
  const data = _.map(props.data, (value: any, key: string) => {
    return {fieldName: key, value: value};
  });

  const autoFormatColumns = [
    {
      dataField: 'fieldName',
      text: 'Additional Data',
      headerClasses: 'text-left pl-5',
      headerStyle: {width: '30em'},
      style: {width: '30.0em'},
      classes: 'text-left',
      formatter: (cell: any, _row: any) => {
        return <strong className="text-left">{humanize(cell)}</strong>;
      },
    },
    {
      dataField: 'value',
      text: '',
      headerClasses: 'text-left',
      classes: 'text-left pl-5',
      formatter: (cell: any, _row: any) => {
        if (cell === false || cell === 'false') {
          return <Badge variant="warning">No</Badge>;
        }
        if (cell === true || cell === 'true') {
          return <Badge variant="success">Yes</Badge>;
        }
        if (
          moment(
            cell,
            ['YYYY/MM/DD', 'YYYY-MM-DD', 'YYYY/MM/DD HH:mm:ss', 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DDTHH:mm:ssZ'],
            true
          ).isValid()
        ) {
          return (
            <span>
              {formatDateFromNow(cell)} ({formatFullDate(cell)})
            </span>
          );
        }
        if (_.isObject(cell)) {
          if (_.isArray(cell) && _.size(cell) === 0) {
            // []
            return '-';
          }
          if (_.isEmpty(cell)) {
            // {}
            return '-';
          }
          return (
            <>
              <ReactJsonView
                theme={'hopscotch'}
                src={cell}
                displayDataTypes={false}
                quotesOnKeys={false}
                collapsed={true}
              />
            </>
          );
        }
        try {
          new URL(cell);
          return (
            <a href={cell} rel="noopener noreferrer" target="_blank">
              {cell}
            </a>
          );
        } catch (e) {
          // nothing to do
        }

        return <>{cell === '' || _.isNil(cell) ? '-' : cell}</>;
      },
    },
  ];

  return (
    <Table
      data={data}
      tableKey="autoformatmetadata"
      striped={true}
      bordered={false}
      overrideKeyField="node_id"
      columns={autoFormatColumns}
    />
  );
});

export default AutoFormatMetadataTable;
