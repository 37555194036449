import _ from 'lodash';
import React from 'react';
import {useState} from 'react';
import {GlobalInfoContext} from 'src/context';

const GlobalInfoContextWrapper: React.FC = ({children}) => {
  const [globalState, setGlobalState] = useState<_.Dictionary<any>>({});

  const clearGlobalInfo = () => setGlobalState({});

  const setGlobalInfo = (name: string, value: any) =>
    setGlobalState((gs) => {
      const newGs: any = {...gs};
      newGs[name] = value;
      return newGs;
    });

  const getGlobalInfo = (name: string) => globalState[name];

  return (
    <GlobalInfoContext.Provider
      value={{
        version: '2020',
        state: globalState,
        set: setGlobalInfo,
        get: getGlobalInfo,
        clear: clearGlobalInfo,
      }}
    >
      {children}
    </GlobalInfoContext.Provider>
  );
};

export default GlobalInfoContextWrapper;
