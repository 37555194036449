import React from 'react';
import {FlowProgressBar} from './FlowProgressBar';
import {Header, SystemIcons} from '@trustle/component-library';
import _ from 'lodash';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';

type PropsT = {
  title: string;
  currentStepIdx: number;
  description?: string;
  steps: string[];
  image: React.ReactNode;
  systemKey?: SystemIconsTypesEnum;
};

export default function FlowProgressHeader(props: PropsT) {
  return (
    <Header
      backButton={{
        text: 'Back to Create Resource Page',
        redirectTo: '/resources/create',
      }}
    >
      <div className="tr-flex tr-w-full">
        <div className={`tr-basis-1/5 tr-flex tr-items-center tr-justify-center`}>
          <SystemIcons name={props.systemKey as SystemIconsTypesEnum} size="lg" />
        </div>

        <div className={`tr-basis-4/5 p-2 pt-4 tr-flex`}>
          <div className={`tr-basis-2/3 `}>
            <div className="d-inline-block p-2">
              <h2>{props.title}</h2>
              <p className="tr-text-sm" title={props.description}>
                {_.truncate(props.description, {length: 150})}
              </p>
            </div>
          </div>
          <div className={`tr-basis-1/3 tr-flex tr-items-center tr-justify-center`}>
            <FlowProgressBar currentStepIdx={props.currentStepIdx} steps={props.steps} />
          </div>
        </div>
      </div>
    </Header>
  );
}
