import {Button, Icon} from '@trustle/component-library';
import React, {useMemo, useState} from 'react';
import {FiltersSidePanel} from '../filters-side-panel/FiltersSidePanel';
import {useUserFilters} from '../../UserFiltersContext';
import _ from 'lodash';

export function AdvancedSearchButton() {
  const {filters} = useUserFilters();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const filtersCount = useMemo(() => {
    const availableAdvancedFilters = _.omit(filters, ['groupBy', 'sort', 'page', 'size', 'nameOrEmail', 'orderBy']);

    return Object.keys(availableAdvancedFilters).filter((value) => value).length;
  }, [filters]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <div>
      <Button variant="secondary" onClick={toggleDrawer}>
        <span className="tr-flex tr-gap-1 tr-items-center">
          <Icon type="filter" />
          Advanced Search
          {filtersCount > 0 && <strong className="tr-text-trustle-navy">({filtersCount})</strong>}
        </span>
      </Button>
      <FiltersSidePanel isOpen={isDrawerOpen} onClose={toggleDrawer} />
    </div>
  );
}
