import React, {useState} from 'react';
import {SubmitButton} from 'src/components/design';
import '../common/editConnection/EditConnection.scss';
import ConnectorStatus from 'src/components/StatusIndicator';
import axios from 'axios';
import _ from 'lodash';
import FontAwesomeIcon from 'src/components/FontAwesomeIcon';
import {faCircleNotch} from 'src/components/FontAwesomeIcon';
import {parseResponse} from 'src/connectors/aws/helper';
import {ParsedTestConnectionT} from 'src/connectors/aws/helper';
import {Button, TextAreaInput, TextInput} from '@trustle/component-library';
import {Formik, FormikForm} from 'src/components/design/formik';
import {object, string} from 'yup';
import {Field} from 'formik';
import {useToasts} from 'react-toast-notifications';
import {ResourceT} from 'src/types';
import {useSystemSetup} from 'src/lib/hooks/useSystemSetup';

/**
 * Form for update the credentials of GCP connection
 * Allow to test the connection and show errors
 *
 * @param props
 * @returns
 */
export default function EditConnectionGCP(props: {resource: ResourceT}) {
  const {resource} = props;
  const {addToast} = useToasts();

  const [resultTestConnection, setResultTestConnection] = useState<ParsedTestConnectionT>();
  const [testingConnection, setTestingConnection] = useState<boolean>(false);
  const [updatingCredentials, setUpdatingCredentials] = useState<boolean>(false);

  const {systemSetup, updateSetup} = useSystemSetup(resource.connectionId ?? '');
  const lastImport = resource.connector?.lastImport;
  const [connectorStatus, setConnectorStatus] = useState<string | undefined>(lastImport?.status);

  async function testConnection(values: {superAdminUser: string; saCredentials: string}) {
    setTestingConnection(true);
    setConnectorStatus('pending');
    const {data: responseData} = await axios.post(`/api/connect/${resource.connectionId}/setup/test_connection`, {
      superAdminUser: values.superAdminUser,
      serviceAccountCredential: values.saCredentials,
    });
    if (!responseData.ok) {
      setConnectorStatus('failed');
      addToast(responseData.error.message ?? 'Connection failed', {appearance: 'error', autoDismiss: false});
    } else {
      setConnectorStatus('finished');
      addToast('Succesfully connected', {appearance: 'success', autoDismiss: true});
    }

    setResultTestConnection(await parseResponse(responseData));
    setTestingConnection(false);
  }

  async function submitConnection(values: {superAdminUser: string; saCredentials: string}, formikArgs: any) {
    updateSetup({connectionId: resource.connectionId ?? '', keys: values});
    const {data: responseData} = await axios.post(`/api/connect/${resource.connectionId}/credentials`, {
      serviceAccountCredential: values.saCredentials,
      superAdminUser: values.superAdminUser,
    });

    if (resource.connector && responseData && responseData.authzOwner) {
      resource.connector.authzOwner = responseData.authzOwner;
      const parsedResponseData = await parseResponse(responseData);
      setResultTestConnection(parsedResponseData);
    }
    setUpdatingCredentials(false);
    formikArgs.resetForm();
  }
  return (
    <>
      <div className="edit-connection-aws pb-7 ">
        <Formik
          key={`newResourceaccount`}
          initialValues={{
            superAdminUser: systemSetup?.superAdminUser ?? '',
            saCredentials: systemSetup?.saCredentials ?? '',
          }}
          onSubmit={submitConnection}
          validationSchema={object().shape({superAdminUser: string().required(), saCredentials: string().required()})}
        >
          {({values, isSubmitting, dirty}) => {
            return (
              <FormikForm>
                <div>
                  <div className="tr-w-full lg:tr-w-2/3">
                    <div className="tr-my-2">
                      <div className="test-box tr-flex tr-px-8 tr-items-center tr-justify-between">
                        <div className="tr-my-2">
                          <ConnectorStatus className="tr-mr-3" status={connectorStatus}></ConnectorStatus>
                          <span className="body4">{resource.name}</span>
                        </div>

                        <div className="tr-flex tr-flex-col">
                          <Button
                            variant="secondary"
                            className="tr-w-[180px] tr-text-sm tr-mb-2"
                            data-testid="updatecredentials"
                            disabled={testingConnection || updatingCredentials}
                            onClick={async () => {
                              setUpdatingCredentials(true);
                              setResultTestConnection(undefined);
                            }}
                          >
                            Update Credentials &gt;
                          </Button>

                          <Button
                            variant="secondary"
                            className="tr-w-[180px] tr-text-sm"
                            data-testid="test-id-button"
                            disabled={!(values.superAdminUser && values.saCredentials) || testingConnection}
                            onClick={async () => {
                              await testConnection(values);
                            }}
                          >
                            {testingConnection ? (
                              <>
                                Testing <FontAwesomeIcon icon={faCircleNotch} spin size="lg" />
                              </>
                            ) : (
                              'Test'
                            )}
                          </Button>
                        </div>
                      </div>
                      <div className="item tr-flex tr-justify-between mt-4 tr-mx-4">
                        {!updatingCredentials ? (
                          <></>
                        ) : (
                          <>
                            <h3>Credentials for Trustle integration</h3>
                            <Button
                              variant="ternary"
                              onClick={() => {
                                setUpdatingCredentials(false);
                              }}
                            >
                              Close
                            </Button>
                          </>
                        )}
                      </div>
                      {updatingCredentials && (
                        <div className="no-padding tr-mx-4">
                          <Field
                            component={TextInput}
                            label="Super Admin User"
                            className="tr-w-1/2"
                            placeholder="Enter super admin user here"
                            showreveal={true}
                            name="superAdminUser"
                            key="superAdminUser"
                            required
                          />
                          <Field
                            component={TextAreaInput}
                            label="Service Account Credentials"
                            showreveal={true}
                            className="tr-w-1/2"
                            placeholder="Enter service account credentials here"
                            name="saCredentials"
                            key="saCredentials"
                            required
                          />

                          <div className="body5">
                            <a
                              href="https://docs.aws.amazon.com/en_en/general/latest/gr/aws-sec-cred-types.html#access-keys-and-secret-access-keys"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <span className="text-underline">Learn how to generate new credentials</span>
                            </a>
                          </div>
                          <div className="text-right">
                            <SubmitButton
                              name="edit-connection-save"
                              data-testid="edit-connection-save"
                              disabled={
                                !dirty ||
                                isSubmitting ||
                                _.isNil(resultTestConnection) ||
                                (resultTestConnection && !resultTestConnection.ok)
                              }
                              label="Save Credentials"
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </FormikForm>
            );
          }}
        </Formik>
      </div>
    </>
  );
}
