import {useEffect, useState} from 'react';
import {useRootStore} from '../hooks';

export type SystemKeyConfig = {
  connectionId: string;
  userId?: string;
  keys: any;
};

export function useSystemSetup(connectionId: string) {
  const {currentUser} = useRootStore();
  const userId = currentUser.id;
  const CONFIG_KEY = `system_keys`;

  const [systemSetup, setSystemSetup] = useState({connectionId: '', userId: '', keys: {}} as SystemKeyConfig);

  useEffect(() => {
    const storedConfigurations = localStorage.getItem(CONFIG_KEY);
    if (storedConfigurations) {
      const config = JSON.parse(storedConfigurations);
      if (config.connectionId === connectionId && config.userId === userId) {
        setSystemSetup(config);
      } else {
        //Overwrite if connection does not match
        localStorage.setItem(CONFIG_KEY, '');
      }
    }
  }, [CONFIG_KEY, connectionId]);

  const updateSetup = (values: SystemKeyConfig) => {
    setSystemSetup(values);
    localStorage.setItem(CONFIG_KEY, JSON.stringify({...values, userId: userId}));
  };

  return {systemSetup: systemSetup.keys, updateSetup};
}
