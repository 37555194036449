import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {ConnectionInfoT, ImportStatus} from 'src/types';
import {Loading} from '@trustle/component-library';
import AzureADSetup from './AzureADSetup';
import {AzureADCredentialTypeE} from './types';
import TestConnection from '../common/editConnection/TestConnection';
import {useHistory, useLocation} from 'react-router-dom';
import {parseAndCleanUpQueryParams} from '../common/helpers';
import {Resource} from 'src/stores/domainObjects/Resource';
import {useToasts} from 'react-toast-notifications';
import {useConnectionCredentials} from '../../lib/hooks';

export default function EditConnectionAzureAD(props: {resource: Resource}) {
  const {resource} = props;

  const lastImport = resource.connector?.lastImport;

  const [connectionInfo, setConnectionInfo] = useState<ConnectionInfoT>();
  const [displayCredentialsForm, setDisplayCredentialsForm] = useState<boolean>(false);
  const [connectorStatus] = useState<string | undefined>(lastImport?.status);
  const [testConnectionInProgress, setTestConnectionInProgress] = useState<boolean>(false);
  const [error, setError] = useState<string | null>();
  const history = useHistory();
  const location = useLocation();
  const {addToast} = useToasts();
  const {testConnection: testConnectionAPI} = useConnectionCredentials();

  async function loadConnection() {
    const connectionResponse = await axios.get(`/api/connect/${resource.connectionId}`);
    setConnectionInfo(connectionResponse.data);
  }

  async function onCredentialsUpdated() {
    await loadConnection();
    setDisplayCredentialsForm(false);
  }

  useEffect(() => {
    void loadConnection();
    parseAndCleanUpQueryParams(history, location, setError);
  }, [resource.connectionId]);

  if (!connectionInfo) {
    return <Loading />;
  }

  const {connection} = connectionInfo;
  const {serviceFriendlyName} = connection;

  async function testConnection() {
    setTestConnectionInProgress(true);
    const {data: azureMgmtResponse} = await testConnectionAPI(connection.id, {
      credentialsType: AzureADCredentialTypeE.AZURE_MANAGEMENT,
    });

    if (azureMgmtResponse.error) {
      addToast(azureMgmtResponse.error.message ?? azureMgmtResponse.message ?? 'Connection failed', {
        appearance: 'error',
        autoDismiss: false,
      });
      setTestConnectionInProgress(false);

      return;
    }

    const {data: graphResponse} = await testConnectionAPI(connection.id, {
      credentialsType: AzureADCredentialTypeE.MICROSOFT_GRAPH,
    });

    setTestConnectionInProgress(false);
    setError(null);
    if (graphResponse.ok) {
      addToast('Succesfully connected', {appearance: 'success', autoDismiss: true});
      await axios.post(`/api/connect/${resource.connectionId}/start_import`);
    }
  }

  return (
    <div className="tr-flex">
      <div className="tr-my-2 no-padding">
        <div className="tr-flex">
          <TestConnection
            name={serviceFriendlyName!}
            connectorStatus={error ? ImportStatus.FAILED : connectorStatus!}
            onTest={testConnection}
            testing={testConnectionInProgress}
            onUpdate={() => setDisplayCredentialsForm(!displayCredentialsForm)}
            updating={displayCredentialsForm}
          />
          {displayCredentialsForm && (
            <AzureADSetup connectionInfo={connectionInfo} onComplete={onCredentialsUpdated} editMode={true} />
          )}
        </div>
      </div>
    </div>
  );
}
