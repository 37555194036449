import {Table} from '@trustle/component-library';
import React from 'react';

type PropsT = {
  cols: string[];
  headerMap: _.Dictionary<any>;
  setHeaderMap: (args: any) => void;
};

function MergeHeaderMapping(props: PropsT) {
  const {cols, headerMap, setHeaderMap} = props;

  const handleHeaderSelect = (header: string) => (e: any) => {
    const v = e.target.value;
    setHeaderMap((oldHeaderMap: any) => {
      return {...oldHeaderMap, [header]: v};
    });
  };

  const missingHeaders = Object.keys(headerMap).filter((k) => headerMap[k] === undefined);
  const availableCols = cols.filter((c) => !Object.values(headerMap).some((h) => c === h));

  return (
    <div>
      <p>Some of the required column names were not found. Please select the correct column from the drop down list.</p>
      <Table
        tableKey="githubmapaccountsImport"
        data={missingHeaders.map((header) => ({
          required: header,
          maps: '----',
          uploaded: (
            <select onChange={handleHeaderSelect(header)} value={headerMap[header]} className="form-control">
              <option value=""></option>
              {availableCols.map((csvHeader) => (
                <option key={csvHeader} value={csvHeader}>
                  {csvHeader}
                </option>
              ))}
            </select>
          ),
        }))}
        columns={[
          {
            dataField: 'required',
            text: 'Required Column',
          },
          {
            dataField: 'maps',
            text: '(Maps To)',
          },
          {
            dataField: 'uploaded',
            text: 'Uploaded Columns',
          },
        ]}
        striped={false}
        bordered={false}
      />
    </div>
  );
}

export default MergeHeaderMapping;
