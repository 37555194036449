import {observer} from 'mobx-react';
import {Permission} from 'src/stores/domainObjects/Permission';
import React from 'react';
import {Icon, SensitivityIcon} from '@trustle/component-library';
import {AwsResourceType, ConnectionServiceE, ResourceCategoryEnum} from 'src/types';
import _ from 'lodash';
import PermissionDetailsButton from '../../Setup/PermissionDetailsButton';
import {PermTabModeE} from '../../Setup/PermissionDetailsModal';
import {AccessStatus, GetLastEvent, RelatedEntities} from './PermissionBranchComponents';
import MetadataButton from '../../Setup/MetadataButton';
import RiskBadge from '../../Account/RiskBadge';

export const PermissionAttributes = observer(
  ({permission, accessMode}: {permission: Permission; accessMode: boolean}) => {
    const sensitivityIcon = (
      <SensitivityIcon
        size="sm"
        title="Sensitivity set for this specific permission"
        level={permission.sensitivity?.level ?? ''}
        className="tr-pr-2"
      />
    );
    const isAwsRole =
      permission.rootResource.type === ConnectionServiceE.AWS &&
      permission.rootResource.foreignId === AwsResourceType.ROLES;
    if (!permission.rootResource.userIsOwner || accessMode) {
      return <>{sensitivityIcon}</>;
    }

    return (
      <>
        {sensitivityIcon}
        {permission.rootResource.type === ConnectionServiceE.AWS && !isAwsRole && (
          <MetadataButton
            permission={permission}
            defaultSelectedTab={PermTabModeE.RISK_REPORT}
            text={<RiskBadge value={permission.riskReport?.riskScore} />}
          />
        )}
        {permission.rootResource.connectionId && (
          <PermissionDetailsButton
            as={Icon}
            size="md"
            type="metadata"
            title="metadata"
            permission={permission}
            defaultSelectedTab={
              permission.parentResource.type === ConnectionServiceE.AWS
                ? isAwsRole
                  ? PermTabModeE.PERMISSIONS_POLICIES
                  : PermTabModeE.POLICY
                : PermTabModeE.METADATA
            }
          />
        )}
        {permission.parentResource.category === ResourceCategoryEnum.GROUP && (
          <RelatedEntities permission={permission} />
        )}
        {permission.rootResource.retrieveUsageDataEnabled && (
          <PermissionDetailsButton
            permission={permission}
            defaultSelectedTab={PermTabModeE.SERVICE_USAGE}
            className="tr-flex tr-flex-row  w-min-60 tr-items-center tr-mt-1 tr-p-0 tr-border-0 tr-border-b-2 tr-bg-transparent tr-border-transparent hover:tr-border-blue-500"
          >
            <Icon type="usage" size="sm" useDefaultColor={true} />
            <span className="tr-text-xs tr-text-blue-500">{_.round(permission.usageData?.usage ?? 0)}%</span>
          </PermissionDetailsButton>
        )}

        <AccessStatus permission={permission} />
        <GetLastEvent permission={permission} />
      </>
    );
  }
);
