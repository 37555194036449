import React, {useEffect, useRef, useCallback} from 'react';
import {observer} from 'mobx-react-lite';
import EULAInitialContent from './EULAInitialContent';

type EulaProps = {
  onShowBtnChange: (value: boolean) => void;
};

const Eula = observer(({onShowBtnChange}: EulaProps) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const useDetectScrolledToBottom = (node: any) => {
    const handleScroll = useCallback(() => {
      const {scrollTop, scrollHeight, clientHeight} = node.current;
      scrollTop + clientHeight === scrollHeight ? onShowBtnChange(true) : onShowBtnChange(false);
    }, [node]);

    useEffect(() => {
      if (node.current) {
        node.current.addEventListener('scroll', handleScroll);
        return () => node.current.removeEventListener('scroll', handleScroll);
      }
    }, [node, handleScroll]);
  };

  useDetectScrolledToBottom(contentRef);

  return (
    <div ref={contentRef} className="tr-h-[400px] tr-overflow-y-scroll blue-scrollbar tr-my-8">
      <div className="tr-flex tr-flex-col">
        <EULAInitialContent />
      </div>
    </div>
  );
});

export default Eula;
