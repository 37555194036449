import {TileButton} from '@trustle/component-library';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';
import _ from 'lodash';
import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {Resource} from 'src/stores/domainObjects/Resource';

type CategorizedSystemListPropsT = {
  categorizedSystems: _.Dictionary<Resource[]>;
  calculateCount?: (type: string) => number;
  clickHandler?: (type: string) => void;
};

const ServiceRadioSelector = observer(({categorizedSystems, clickHandler}: CategorizedSystemListPropsT) => {
  const [selectedType, setSelectedType] = useState<string>();
  return (
    <div role="radiogroup" className="tr-border-solid tr-border-gray-medium tr-border-0 tr-border-b tr-mb-4 tr-pb-8">
      {_.toPairs(categorizedSystems)
        .filter(([type]) => _.size(categorizedSystems[type]) > 0)
        .map(([type]) => (
          <TileButton
            data-testid={type}
            key={type}
            colorVariant={selectedType === type ? 'primary' : undefined}
            size={'lg'}
            badge={_.size(categorizedSystems[type]) > 1 ? _.size(categorizedSystems[type]) : undefined}
            onClick={() => {
              setSelectedType(type);
              clickHandler?.(type);
            }}
            role="radio"
            name={type as SystemIconsTypesEnum}
            aria-checked={location.pathname.includes(type)}
            className="tr-m-2"
          />
        ))}
    </div>
  );
});

export default ServiceRadioSelector;
