import React from 'react';
import {Alert} from '@trustle/component-library';
import {TabsDocsT} from '../DocSection';
import DocSection from '../DocSection';
import CircleStep from '../../components/CircleStep';
import Highlight from 'src/components/highlightjs/Highlight';
import TrustleApiReadPolicyTemplate from './TrustleApiReadPolicyTemplate.json';
import TrustleApiWritePolicyTemplate from './TrustleApiWritePolicyTemplate.json';
function ConfigureAWS() {
  const tabs: TabsDocsT = [
    {id: 'aws-overview', label: 'AWS Connector Overview', description: overview(), active: true},
    // {id: 'aws-contents', label: 'Contents', description: contentDetail(), active: true},
    // {id: 'aws-supported-features', label: 'Supported Features', description: supportedFeatures(), active: false},
    {id: 'aws-configuration-steps', label: 'Configuration Steps', description: configurationSteps(), active: true},
  ];

  function overview(): React.ReactNode {
    return (
      <>
        <p>
          Our AWS connector provides deep visibility into user accounts, permissions, usage patterns, and policies on
          your AWS account.{' '}
        </p>
        <ul>
          <li>
            With our <span className="strong">Free version</span>, you get a read-only view that will guide you through
            account cleanups, recommendations, and reporting. You can invite up to 5 total stakeholders to join and
            begin monitoring and planning your AWS permissions. Please note that the Free version is limited to one AWS
            account.
          </li>
          <li>
            With the <span className="strong">Teams</span> and <span className="strong">Enterprise versions</span>, you
            also are able to use Trustle’s request/approval workflows, just-in-time access, on-call access, and full
            auditing of permissions grants and removals. These features are available to any number of users and roles
            on your teams. Please note that the connector can operate in Read-only and Read-write mode, but the latter
            requires Write access to automate access grants.
          </li>
        </ul>
        <p>
          Getting started is easy. You just need an AWS access key for an account that has the policy listed below.
          Trustle only imports IAM data, and our analysis of your account should only take a few minutes, depending on
          the number of user accounts, roles, groups, and policies in your AWS account.
        </p>
      </>
    );
  }

  function configurationSteps(): React.ReactNode {
    return (
      <>
        <div>
          <div className="tr-mb-4">
            Use the following steps to connect to{' '}
            <a
              href="https://docs.aws.amazon.com/en_en/IAM/latest/UserGuide/id_users_create.html"
              target="_blank"
              rel="noreferrer"
            >
              AWS Identity and Access Management (IAM)
            </a>
            . This setup requires an AWS user to create a new IAM User with the included permissions. API credentials
            for this account will be used by Trustle for all operations.
          </div>
          <div className="tr-mb-4 body3">AWS Setup Steps</div>

          <div className="body5 tr-mt-4 tr-pt-2">
            <CircleStep>1</CircleStep>Login to AWS console and find the IAM (Identify and Access Management) service.
          </div>

          <div className=" tr-mt-4 tr-pt-2">
            <div>
              <CircleStep>2</CircleStep>
              <span className="body5">Create a User for Trustle system.</span>
            </div>
            <div>
              <div className="tr-ml-5 tr-mt-2">
                <div>On the AWS Management Console, click Users in the left pane and then click Add user</div>
                <div>
                  <img
                    src="/docs/connectors/aws/connect/aws_add_user.png"
                    className="vertical-align tr-mb-4 mt-3 shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="body5 tr-mt-4 tr-pt-3">
            <CircleStep>3</CircleStep> Enter a User name in the User name field. For example, <i>Trustle-User</i>
          </div>

          <div className="body5 tr-mt-4 tr-pt-3">
            <div>
              <CircleStep>4</CircleStep> On next step, Select <i>Programmatic access</i> for Access type and then click{' '}
              <i>Next: Permissions</i> button
            </div>
            <div>
              <div className="tr-ml-5 tr-mt-2">
                <img
                  src="/docs/connectors/aws/connect/aws_programmatic_access.png"
                  className="vertical-align tr-mb-4 mt-3 shadow"
                />
              </div>
            </div>
          </div>

          <div className="tr-mt-4 tr-pt-3">
            <div>
              <CircleStep>5</CircleStep>
              <span className="body5">
                Click on <i>Attach existing policies directly</i> and then <i>Create policy</i>.
              </span>
            </div>
            <div className="tr-ml-5 tr-mt-2">
              <div>The Create policy page opens in a new browser tab.</div>
            </div>
          </div>

          <div className="tr-mt-4 tr-pt-3">
            <div>
              <CircleStep>6</CircleStep>
              <span className="body5">Click the JSON tab.</span>
            </div>
            <div>
              <div className="tr-ml-5 tr-mt-2">
                <img
                  src="/docs/connectors/aws/connect/aws_json_tab.png"
                  className="vertical-align tr-mb-4 mt-3 shadow"
                />
              </div>
            </div>
            <div className="tr-ml-5 tr-mt-2">
              Delete the existing code in the JSON tab and replace it with the code underneath "Trustle Read policy":
            </div>
            <div>
              <div className="tr-ml-5 tr-mt-2 tr-grid tr-gap-4 tr-grid-cols-2">
                <div className="shadow ">
                  <div className="tr-p-2">
                    <strong>Trustle Read policy:</strong>
                  </div>
                  <Highlight language="json" showCopy={true} className="shadow">
                    {JSON.stringify(TrustleApiReadPolicyTemplate)}
                  </Highlight>
                </div>
                <div className="shadow ">
                  <div className="tr-p-2">
                    <strong>Trustle Write policy:</strong>
                  </div>
                  <Highlight language="json" showCopy={true} className="shadow">
                    {JSON.stringify(TrustleApiWritePolicyTemplate)}
                  </Highlight>
                  <Alert
                    title={'Limit "Trustle Group Write" Policy to a subset of IAM Groups'}
                    colorVariant={'warning'}
                  >
                    <div>
                      <p>
                        We highly recommend that System Owners create a policy that is limited to a number of Groups.
                        Edit the line <code>"Resource": "*" </code> to a specific set of groups. This will restrict
                        Trustle to add and remove a user from the configured "allowed list" of IAM Groups.
                      </p>
                      <p>
                        To control the "allowed list" of IAM Groups, the System Owner will specify the ARN of the IAM
                        Groups they would like to allow Trustle to add or remove users from. Example:
                      </p>
                      <pre>
                        "Resource": "arn:aws:iam::<span className="text-danger">accountId</span>:group/
                        <span className="text-danger">groupName</span>"
                      </pre>
                      <p>
                        A System Owner should change the line with a specific list of ARNs for IAM Groups. This "Allow
                        List" of IAM Groups will restrict the add and remove functionality of Trustle to the ARNs in the
                        list.
                      </p>
                      Learn more about this in Amazon's documentation:<br></br>
                      <a
                        href="https://docs.aws.amazon.com/en_en/IAM/latest/UserGuide/reference_policies_elements_resource.html"
                        target="_blank"
                      >
                        "IAM JSON policy elements: Resource"
                      </a>
                    </div>
                  </Alert>
                </div>
              </div>
            </div>
          </div>

          <div className="body5 tr-mt-4 tr-pt-3">
            <CircleStep>7</CircleStep> Click <i>Next: Tags</i> (refer to{' '}
            <a
              href="https://docs.aws.amazon.com/en_en/IAM/latest/UserGuide/access_policies.html"
              target="_blank"
              rel="noreferrer"
            >
              AWS documentation
            </a>{' '}
            for details).
          </div>

          <div className="body5 tr-mt-4 tr-pt-3">
            <CircleStep>8</CircleStep> If you prefer, you can add descriptive tags for the user. Then click{' '}
            <i>Next: Review.</i>
          </div>

          <div className="tr-mt-4 tr-pt-3">
            <CircleStep>9</CircleStep>
            <span className="body5">
              Enter a name and optional description for the policy, for example: <i>Trustle-Read-Policy</i>.
            </span>
          </div>

          <div className="tr-mt-4 tr-pt-3">
            <div>
              <CircleStep>9</CircleStep>
              <span className="body5">
                Click on <i>Create Policy</i>
              </span>
            </div>
            <div className="tr-ml-5 tr-mt-2">
              <div>
                The app returns you to a window where you can continue creating policies through <i>Create Policy</i>{' '}
                button.
              </div>
              <Alert colorVariant="primary">
                To allow Trustle to write on AWS, repeat Steps 6 to 9 using the policy named "Trustle Write Policy"
                described in Step 6. You may choose any name for this policy, but we recommend that you use a
                descriptive name to easily identify its purpose, as <i>Trustle-Write-Policy</i>.
              </Alert>
            </div>
          </div>

          <div className="body5 tr-mt-4 tr-pt-3">
            <CircleStep>10</CircleStep> Go to the first tab on your browser (User creation) and Select{' '}
            <i>Attach existing policies directly</i> if is not selected.
          </div>

          <div className="body5 tr-mt-4 tr-pt-3">
            <CircleStep>11</CircleStep> Click the <i>Refresh button</i>, enter the name of the policy you created in the
            search field, and select the policy. Repeat this steps for each policy created before.
          </div>

          <div className="body5 tr-mt-4 tr-pt-3">
            <CircleStep>12</CircleStep> Click <i>Next: Tags</i>
          </div>

          <div className="body5 tr-mt-4 tr-pt-3">
            <CircleStep>13</CircleStep> Click <i>Next: Review</i>
          </div>

          <div className="body5 tr-mt-4 tr-pt-3">
            <CircleStep>14</CircleStep> Click <i>Create user</i>
          </div>

          <div className="tr-mt-4 tr-pt-3">
            <div>
              <CircleStep>15</CircleStep>
              <span className="body5">
                Finally, copy the keys in the <i>Access key ID</i> and <i>Secret access key</i> columns and click Close.
                You'll need these keys to complete the configuration on Trustle
              </span>
              <div className="tr-ml-5 tr-mt-2">
                <div>
                  This is the only time that you can view the Secret access key. If for any reason you lost the secret
                  key or forgot it, you have the ability to regenerate them on AWS.{' '}
                </div>
                <div>
                  Refer to{' '}
                  <a
                    href="https://docs.aws.amazon.com/en_en/IAM/latest/UserGuide/id_credentials_access-keys.html"
                    target="_blank"
                    rel="noreferrer"
                  >
                    AWS documentation
                  </a>{' '}
                  for more detail.
                </div>
                <img
                  src="/docs/connectors/aws/connect/aws_iam_keys.png"
                  className="vertical-align shadow tr-mb-4 mt-3"
                />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

  return <DocSection tabs={tabs} title="How to configure Amazon Web Services (AWS) in Trustle" />;
}

export default ConfigureAWS;
