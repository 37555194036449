import {useHistory} from 'react-router-dom';
import {TileButton, Tooltip} from '@trustle/component-library';
import React from 'react';
import {AuthorityServiceE} from '../../../../../../types';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';

type AuthoritySelectItemProps = {
  service: AuthorityServiceE;
  goTo: string;
  key: string;
  name?: string;
  isConfigured?: boolean;
};
export function AuthoritySelectItem({key, service, goTo, name, isConfigured}: AuthoritySelectItemProps) {
  const history = useHistory();

  const tooltipDescription = (
    <div className="tr-p-1  tr-text-center">
      <strong>{name || service}</strong>
    </div>
  );

  return (
    <div key={key} className="tr-flex tr-flex-col tr-items-center tr-gap-2 tr-p-2">
      <Tooltip content={tooltipDescription} position="bottom">
        <TileButton
          size="xl"
          name={service as unknown as SystemIconsTypesEnum}
          onClick={() => {
            history.push(goTo);
          }}
          data-testid={'authority-item-button'}
          key={key}
          connectionStatus={!isConfigured ? undefined : isConfigured ? 'active' : 'pending'}
        />
      </Tooltip>
    </div>
  );
}
