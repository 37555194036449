import {EditAccountModal} from './EditAccountModal';
import {AccountModalAction} from 'src/types';
import AccountDetailModal from './AccountDetailsModal';
import React, {useState} from 'react';
import {useRootStore} from 'src/lib/hooks';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Account} from 'src/stores/domainObjects/Account';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import {Confirmation, IconButton} from '@trustle/component-library';

type PropsT = {
  account: Account;
  resource: Resource;
  refresh: () => void;
};

const AccountActionsDropdown = observer((props: PropsT) => {
  const {account, resource, refresh} = props;
  const {accountsStore} = useRootStore();
  const [modalSelection, setModalSelection] = useState<{
    action?: AccountModalAction;
    account?: Account;
  }>({});

  const closeModal = (refreshData: boolean = false) => {
    setModalSelection({});
    if (refreshData) {
      refresh();
    }
  };

  async function handleUnassign(accounts: Account[]) {
    //TO DO: We need to move accounts to domain objects, will do in a upcoming pr
    await accountsStore.unassignAccounts(accounts);
  }

  const modalElem = (() => {
    if (!modalSelection.action) {
      return null;
    }

    switch (modalSelection.action) {
      case AccountModalAction.DETAILS: {
        return (
          <AccountDetailModal
            userAccount={account}
            closeModal={() => {
              closeModal();
            }}
          />
        );
      }
      case AccountModalAction.UNASSIGN: {
        return (
          <Confirmation
            onConfirm={async () => {
              await handleUnassign([account]);
              closeModal(true);
            }}
            onClose={() => {
              closeModal();
            }}
            title={'Confirm Unlinking'}
          >
            <div>This action will remove the reference to the user from the account.</div>
          </Confirmation>
        );
      }
      case AccountModalAction.EDIT: {
        return (
          <EditAccountModal
            userAccount={account}
            isConnectedSystem={!!resource.connectionId}
            closeModal={() => {
              closeModal(true);
            }}
          />
        );
      }
      default:
        return null;
    }
  })();

  function getAccountActions(params: {account: Account}) {
    const {account} = params;
    const items = [
      <IconButton
        icon="details"
        variant="ternary"
        key={'detail'}
        title="Details"
        data-testid="details-icon"
        onClick={(e: any) => {
          e.stopPropagation();
          setModalSelection({action: AccountModalAction.DETAILS, account});
        }}
      />,
    ];

    if (!_.isNil(account.forUser)) {
      items.push(
        <IconButton
          icon="edit"
          variant="ternary"
          key={'edit'}
          title="Edit"
          data-testid="edit-icon"
          onClick={(e: any) => {
            e.stopPropagation();
            setModalSelection({action: AccountModalAction.EDIT, account});
          }}
        />
      );

      items.push(
        <IconButton
          icon="startOffboard"
          variant="ternary"
          key={'unassign'}
          data-testid="unassign-icon"
          title={'Unlink'}
          onClick={(e: any) => {
            e.stopPropagation();
            setModalSelection({action: AccountModalAction.UNASSIGN, account});
          }}
        />
      );
    }
    return (
      <div className="flex-align account-actions-wrapper  perm-actions-wrapper  justify-content-center">{items}</div>
    );
  }

  return (
    <>
      {getAccountActions({
        account: account,
      })}
      {modalElem}
    </>
  );
});

export default AccountActionsDropdown;
