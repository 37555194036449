import React from 'react';
import {Card} from 'react-bootstrap';
import TrustleWhiteLogo from 'src/images/trustle-white-logo.svg';
import './DocSection.scss';
import {Accordion} from '@trustle/component-library';

export type TabsDocsT = {
  id: string;
  label: string;
  description: React.ReactNode;
  active: boolean;
}[];

type DocSectionT = {
  tabs: TabsDocsT;
  title: string;
};

function DocSection(props: DocSectionT) {
  const {tabs, title} = props;

  return (
    <div className="doc-section">
      <div className="header">
        <img src={TrustleWhiteLogo} />
      </div>

      <div className="main-margin tr-mt-5 tr-mb-5">
        <div className="tr-flex tr-flex-col">
          <h2>{title}</h2>
        </div>

        {tabs.map((tab) => (
          <Accordion key={tab.id} content={<h3 className="tr-ml-2">{tab.label}</h3>}>
            {() => {
              return <Card.Body>{tab.description}</Card.Body>;
            }}
          </Accordion>
        ))}
        <hr />
      </div>
    </div>
  );
}

export default DocSection;
