import React from 'react';
import {TeamsSummaryTable} from './internal/TeamsSummaryTable';
import {useUserFilters} from '../../UserFiltersContext';
import {IfCondition} from '../../../../common/IfCondition';
import {UsersCountByTable} from './internal/UsersCountByTable';

export function GroupByTable() {
  const {filters} = useUserFilters();
  const {groupBy} = filters;

  if (!groupBy) {
    return null;
  }

  return (
    <>
      <IfCondition condition={groupBy === 'team'}>
        <TeamsSummaryTable />
      </IfCondition>
      <IfCondition condition={['department', 'role', 'title'].includes(groupBy)}>
        <UsersCountByTable countBy={groupBy as 'department' | 'role' | 'title'} />
      </IfCondition>
    </>
  );
}
