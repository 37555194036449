import React, {useEffect, useState} from 'react';
import {CardElement, useStripe, useElements} from '@stripe/react-stripe-js';
import axios from 'axios';
import {Button} from 'react-bootstrap';
import _ from 'lodash';
import {formatAsDollarsAndCents} from 'src/lib';
import PaymentMethod from './PaymentMethod';
import {Field, Formik} from 'formik';
import {FormikForm} from 'src/components/design/formik';
import {observer} from 'mobx-react';
import {useRootStore} from 'src/lib/hooks';
import BillingAddress from './BillingAddress';
import {Loading} from '@trustle/component-library';
import {useToasts} from 'react-toast-notifications';
import {TextInput} from '@trustle/component-library';

type PropsT = {
  price: any;
  quantity: number;
};

type CreditCardFormT = {
  city: string;
  country: string;
  line1: string;
  line2: string;
  postalCode: string;
  state: string;
};

export type BillingAddressT = {
  id: string;
  city: string;
  country: string;
  line1: string;
  line2: string;
  postalCode: string;
  state: string;
  ipAddress: string;
};

const CheckoutForm = observer(function CheckoutFormCmp(props: PropsT) {
  const {org, usersStore} = useRootStore();
  const {quantity, price} = props;
  const [error, setError] = useState<any>(null);
  const [customerId, setCustomerId] = useState<string>('');
  const [taxPrice, setTaxPrice] = useState<any>();
  const [address, setAddress] = useState<any>();
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState<string>('');
  const [processing, setProcessing] = useState<boolean>(false);
  const [confirming, setConfirming] = useState<boolean>(false);
  const stripe = useStripe();
  const {addToast} = useToasts();
  const elements = useElements();
  useEffect(() => {
    async function loadProducts() {
      const {data} = await axios.get('/api/payments/customerId');

      setCustomerId(data.id);
    }

    void loadProducts();
  }, []);

  useEffect(() => {
    async function loadFutureSubscription() {
      if (!address) {
        return;
      }
      const {data} = await axios.post('/api/payments/preview_subscription', {
        priceId: price.id,
        quantity: quantity,
        address: {
          city: address.city,
          country: address.country,
          line1: address.line1,
          line2: address.line2,
          postal_code: address.postalCode,
          state: address.state,
        },
      });

      setTaxPrice(data);
    }

    void loadFutureSubscription();
  }, [customerId, quantity, address]);

  if (_.isNil(org)) {
    return <></>;
  }

  async function handleChange(event: any) {
    setError(event.error ? event.error.message : '');
  }

  async function submitAddress(values: CreditCardFormT) {
    try {
      await axios.post('/api/payments/billing_address', {...values});
      setAddress(values);
      setConfirming(true);
      setError(null);
    } catch (e: any) {
      setError('Address could not be validated, please confirm that is correct');
    }
  }

  async function handleSubmit() {
    if (!stripe || !elements) {
      return;
    }
    let paymentMethodId = selectedPaymentMethodId;
    const cardElement = elements.getElement(CardElement);
    if (!cardElement && !paymentMethodId) {
      return;
    }

    setProcessing(true);

    try {
      //We need to create through stripe, PCI compliance on their side for this task.
      //If the customer has already set cards, we should offer selecting them.
      if (!paymentMethodId && cardElement) {
        const {paymentMethod, error} = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            address: {
              city: address.city,
              country: address.country,
              line1: address.line1,
              line2: address.line2,
              postal_code: address.postalCode,
              state: address.state,
            },
            name: org!.name,
          },
        });

        if (error) {
          setError(error.message);
          setProcessing(false);
          return;
        }

        if (paymentMethod) {
          paymentMethodId = paymentMethod.id;
        }
      }

      if (error) {
        setError(error);
      } else {
        try {
          const {error} = await org.createSubscription({
            paymentMethodId: paymentMethodId,
            priceId: price.id,
            quantity: quantity,
            customerId: customerId,
          });

          if (error) {
            setError(error);
          }

          await usersStore.rootStore.reloadOrg();

          addToast(`You’ve Successfully Upgraded your package.`, {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          addToast('Error trying to create the subscription.', {
            appearance: 'error',
            autoDismiss: false,
          });
          throw err;
        }
      }
    } catch (e: any) {
      setError(e.message);
    }

    setProcessing(false);
  }

  const cardStyle = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: 'Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#blue',
        },
      },
      invalid: {
        fontFamily: 'Arial, sans-serif',
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
    hidePostalCode: true,
  };

  function initializeForm(): CreditCardFormT {
    return {
      city: address?.city ?? '',
      country: address?.country ?? 'US',
      line1: address?.line1 ?? '',
      line2: address?.line2 ?? '',
      postalCode: address?.postalCode ?? '',
      state: address?.state ?? '',
    };
  }

  const paymentMethods = org.methods;

  const confirmationContent = (
    <div className={confirming ? 'visible-component' : 'hidden-component'}>
      {paymentMethods && (
        <h3>
          Your cards <span className="grayed-out body6">(click to select)</span>
        </h3>
      )}
      {!selectedPaymentMethodId && (
        <div className="card-wrapper">
          <CardElement id="card-element" options={cardStyle} onChange={handleChange} />
        </div>
      )}
      {processing && <Loading />}
      <div className="body6 grayed-out mb-2 text-center">
        Your card is processed and stored via Stripe. We do not share your information, and you will not be charged
        until after the free trial period has ended. If you wish to avoid being charged, please cancel your within the
        trial period.
      </div>
      <div className="tr-flex">
        <div className="tr-flex-1 body3 grayed-out">Organization Size</div>
        <div className="tr-flex-1"></div>
      </div>
      <div className="tr-flex">
        <div className="body3 tr-p-3">{`${quantity} Users`}</div>
      </div>
      <div className="tr-flex">
        <div className="tr-flex-1 body3 grayed-out">Billing Cycle</div>
        <div className="tr-flex-1"></div>
      </div>
      <div className="tr-flex body3 gray-bottom-border tr-pb-2">
        <div className="body3 tr-tr-p-3">{`${_.startCase(price?.recurring?.interval)}`}</div>
      </div>

      <div className="tr-mb-2">
        <div className="tr-flex-1"></div>
        <div className="tr-flex-1">
          <div className="tr-flex body3 gray-bottom-border tr-pb-1">
            <div className="tr-flex-1 body3 tr-p-3">Subtotal:</div>
            <div className="tr-flex-1 body3 tr-p-3 text-right">
              {taxPrice && formatAsDollarsAndCents(taxPrice.subtotal / 100)}
            </div>
          </div>
          <div className="tr-flex body3 gray-bottom-border tr-pb-1">
            <div className="tr-flex-1 body3 tr-p-3 grayed-out">Sales Tax:</div>
            <div className="tr-flex-1 body3 tr-p-3 text-right">
              {taxPrice && formatAsDollarsAndCents(taxPrice.tax / 100)}
            </div>
          </div>
          <div className="tr-flex body3 gray-bottom-border tr-pb-1">
            <div className="tr-flex-1 body3 tr-p-3">Total:</div>
            <div className="tr-flex-1 body3 tr-p-3 tr-text-right">
              {taxPrice && formatAsDollarsAndCents(taxPrice.total / 100)}
            </div>
          </div>
        </div>
      </div>
      {error && (
        <div>
          <div className="card-error tr-mb-2 tr-mt-2" role="alert">
            {error}
          </div>
        </div>
      )}
      <div>
        <div className="tr-text-right body1 tr-flex tr-justify-between">
          <Button
            variant="ternary"
            onClick={() => {
              setConfirming(false);
              setError(null);
            }}
            className="tr-p-3"
          >
            {'< Go Back'}
          </Button>
          <Button
            className="btn-primary"
            onClick={() => {
              void handleSubmit();
            }}
            disabled={!price || !address || error}
          >
            {processing ? <Loading /> : `Confirm`}
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="tr-m-3">
      <div className={!confirming ? 'visible-component' : 'hidden-component'}>
        {!_.isEmpty(org.billingAddresses) && (
          <h3>
            Your addresses <span className="grayed-out body6">(click to select)</span>
          </h3>
        )}
        <div className="tr-m-3 tr-pt-2">
          {(org?.billingAddresses ?? []).map((ad: any) => {
            return (
              <div className="tr-w-1/2" key={ad.id}>
                <BillingAddress
                  address={ad}
                  onClick={() => {
                    if (ad.id !== address?.id) {
                      setAddress(ad);
                    } else {
                      setAddress(undefined);
                    }
                  }}
                  selected={address?.id === ad.id}
                />
              </div>
            );
          })}
        </div>
        <Formik
          initialValues={initializeForm()}
          validate={(values: any) => {
            const errors: _.Dictionary<string> = {};
            if (_.isEmpty(values.line1)) {
              errors.line1 = 'This field is required.';
            }

            return errors;
          }}
          onSubmit={submitAddress}
        >
          {({isValid, values}) => (
            <>
              <FormikForm>
                {!address?.id && (
                  <div className="tr-flex tr-flex-col">
                    <div>
                      <Field
                        component={TextInput}
                        label="Address Line 1"
                        placeholder="185 Berry St. Suite 550"
                        name="line1"
                        id="line1"
                        data-cy="line1"
                        required
                      />
                    </div>
                    <div>
                      <Field
                        component={TextInput}
                        placeholder="Optional"
                        label="Address Line 2"
                        name="line2"
                        id="line2"
                        data-cy="line2"
                      />
                    </div>
                    <div className="tr-flex">
                      <div className="tr-flex-1">
                        <Field
                          component={TextInput}
                          label="Country"
                          name="country"
                          placeholder="US"
                          data-cy="country"
                          disabled
                          required
                        />
                      </div>
                      <div className="tr-flex-1">
                        <Field
                          component={TextInput}
                          label="State"
                          name="state"
                          id="state"
                          placeholder="California"
                          type="state"
                          data-cy="state"
                          required
                        />
                      </div>
                    </div>
                    <div>
                      <div className="tr-flex-1">
                        <Field
                          component={TextInput}
                          label="City"
                          name="city"
                          type="city"
                          id="city"
                          placeholder="San Francisco"
                          data-cy="city"
                          required
                        />
                      </div>
                      <div className="tr-flex-1">
                        <Field
                          component={TextInput}
                          label="Postal Code"
                          name="postalCode"
                          id="postalCode"
                          placeholder="94103"
                          type="postalCode"
                          data-cy="postalCode"
                          required
                        />
                      </div>
                    </div>
                  </div>
                )}
                {error && (
                  <div>
                    <div className="card-error tr-mb-2 tr-mt-2" role="alert">
                      {error}
                    </div>
                  </div>
                )}
                <div className="tr-m-3 tr-pt-2">
                  {(paymentMethods ?? []).map((pm: any) => {
                    return (
                      <div className="tr-w-1/2" key={pm.id}>
                        <PaymentMethod
                          pm={pm}
                          removeAllowed={false}
                          onClick={() => {
                            if (pm.id !== selectedPaymentMethodId) {
                              setSelectedPaymentMethodId(pm.id);
                            } else {
                              setSelectedPaymentMethodId('');
                            }
                          }}
                          selected={selectedPaymentMethodId === pm.id}
                        />
                      </div>
                    );
                  })}
                </div>
                <div>
                  <div className="tr-text-right body1 ">
                    <Button
                      name="createAccount"
                      onClick={async () => {
                        if (!address) {
                          await submitAddress(values);
                        } else {
                          setConfirming(true);
                        }
                      }}
                      disabled={!(isValid || address?.id)}
                    >
                      {'Accept'}
                    </Button>
                  </div>
                </div>
              </FormikForm>
            </>
          )}
        </Formik>
      </div>
      {confirmationContent}
    </div>
  );
});

export default CheckoutForm;
