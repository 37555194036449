import React from 'react';
import {observer} from 'mobx-react';
import {Permission} from 'src/stores/domainObjects/Permission';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Icon} from '@trustle/component-library';

type PropsT = {
  permission?: Permission;
  resource: Resource;
};

const VisibilityToggle = observer((props: PropsT) => {
  const {permission, resource} = props;
  const name = permission ? permission.label : resource.name;
  const hidden = permission ? permission.hidden : resource.hidden;
  const isOwner = resource.userIsOwner;

  return (
    <div
      className={`hideable-icon ${!hidden ? 'hideable-button' : 'visible-button'} tr-flex justify-content-center`}
      onClick={isOwner ? () => (permission ?? resource).update({hidden: !hidden}) : () => {}}
    >
      <div className={`${isOwner && 'owner'}`}>
        <Icon
          data-testid="toggleIcon"
          title={`${hidden ? 'Show' : 'Hide'} ${name}`}
          type={hidden ? 'visibilityOff' : 'visibilityOn'}
          color="black"
          size="xs"
        />
      </div>
    </div>
  );
});

export default VisibilityToggle;
