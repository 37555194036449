import React, {useEffect, useState} from 'react';
import {Route, Switch, useHistory} from 'react-router-dom';
import {Button, Icon} from '@trustle/component-library';
import LoginPage from './loginPage/LoginPage';
import FindOrgsForm from './FindOrgsForm';
import ShowOrgs from './ShowOrgs';
import ForgotPasswordForm from './ForgotPasswordForm';
import CreateOrgPage from './CreateOrgPage/CreateOrgPage';
import LoginForm from './LoginForm';
import FoundOrgLoginForm from './LoginForm';
import {OrgInfoT} from 'src/Routes/Component';
import axios from 'axios';

export type ConfigProps = {
  featureFlags: string[];
  env: string;
  publishableKeys: {
    stripe: string;
    authPubKey: string;
    bugsnagKey: string;
  };
};
const EntryPage = ({orgInfo}: {orgInfo: OrgInfoT}) => {
  const [config, setConfig] = useState<ConfigProps | null>(null);
  const history = useHistory();

  const fetchConfig = async () => {
    try {
      const response = await axios.get('/api/orgs/config');
      setConfig(response.data);
    } catch (error) {
      setConfig(null);
    }
  };

  useEffect(() => {
    void fetchConfig();
  }, []);

  if (!config) {
    return <> </>;
  }

  const newLoginFlowEnabled = config.featureFlags.includes('show_new_login_flow');
  const currentYear = new Date().getFullYear();

  const baseDomain = process.env['TA_BASE_DOMAIN'] ?? '';

  const homePageUrl =
    config.env === 'development'
      ? 'http://app.local.trustle.xyz:3000'
      : config.env === 'staging'
      ? 'https://www.trustle-stg.xyz'
      : 'https://www.trustle.com';

  return (
    <div className="tr-flex tr-flex-row tr-min-h-screen">
      <div className="tr-hidden lg:tr-flex tr-relative tr-basis-1/2 tr-bg-trustle">
        <div className="tr-absolute tr-inset-0 tr-flex tr-flex-col tr-justify-between tr-text-white tr-py-16 lg:tr-px-4 xl:tr-px-8 2xl:tr-px-16">
          <a href={homePageUrl} rel="noopener noreferrer" className="tr-text-white tr-hover:underline">
            <Icon type="chevronLeft" /> Go to Trustle Home Page
          </a>
          <div className="tr-self-end tr-flex tr-flex-col tr-items-center">
            <img src="/logo/trustle-white.svg" width={482} alt="trustle" />
            <p className="tr-text-2xl tr-font-bold mt-4">Access management simplified</p>
          </div>
          <p>&copy; {currentYear} Trustle Security all rights reserved</p>
        </div>
      </div>
      <div className="tr-grow tr-min-w-[26rem] tr-flex tr-flex-col">
        <div className="tr-flex tr-flex-wrap tr-justify-between tr-items-center tr-px-8 tr-pt-8 tr-pb-0 xl:tr-px-16">
          <img src="/logo/trustle-blue.svg" width={133} height={33} alt="trustle" />
          <div>
            <Switch>
              <Route path="/signup">
                <span className="tr-mr-4">Already have an account?</span>
                <Button onClick={() => history.push('/login')}>Login</Button>
              </Route>
              <Route path={['/login', '/forgot_password']}></Route>
              <Route path="*">
                <span className="tr-mr-4">Don't have an account?</span>
                <Button onClick={() => history.push(`${baseDomain ?? ''}/signup`)}>Sign Up </Button>
              </Route>
            </Switch>
          </div>
        </div>
        <div className="tr-flex tr-justify-center xl:tr-justify-start xl:tr-ml-16 tr-items-center tr-flex-grow">
          <div className="tr-inline-block tr-w-96">
            <Switch>
              <Route
                path="/login"
                component={newLoginFlowEnabled ? (orgInfo.found ? FoundOrgLoginForm : LoginPage) : LoginForm}
              />
              <Route path="/signup" component={CreateOrgPage} />
              <Route path="/find_orgs" component={FindOrgsForm} />
              <Route path="/show_orgs" component={ShowOrgs} />
              <Route path="/forgot_password" component={ForgotPasswordForm} />
            </Switch>
            <a className="tr-block tr-my-16" href="mailto:support@trustle.com">
              <Icon type="support" size="md" className="tr-mr-2 tr-opacity-100" />
              Contact Support
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EntryPage;
