import {useAccountFilters} from '../../AccountsFiltersContext';
import {Resource} from 'src/stores/domainObjects/Resource';
import {AccountsTable} from '../accounts-table/AccountsTable';
import React from 'react';
import {LinkedAccountsView} from './LinkedAccountsView';
import {UnlinkedAccountsView} from './UnlinkedAccountsView';
import {AccountsSummaryResponse} from '../../hooks/useAccountsSummary';

type Props = {system: Resource; accountsSummary?: AccountsSummaryResponse};

export function AccountsView({system, accountsSummary}: Props) {
  const {filters} = useAccountFilters();
  const {linkStatus} = filters;

  return (
    <div>
      {linkStatus === 'linked' && <LinkedAccountsView accountsSummary={accountsSummary} />}
      {linkStatus === 'unlinked' && <UnlinkedAccountsView accountsSummary={accountsSummary} />}
      <AccountsTable system={system} />
    </div>
  );
}
