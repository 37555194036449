import React, {useMemo} from 'react';
import {Card, IconButton, Select} from '@trustle/component-library';
import {SelectItem, UserFilterKey} from '../../../types';

type Props = {
  key: UserFilterKey;
  title: string;
  selected?: SelectItem[] | SelectItem;
  setSelected: (value: SelectItem[] | SelectItem) => void;
  removeFilter: () => void;
  options: SelectItem[];
  isMulti?: boolean;
};

export function Filter({key, title, selected = [], setSelected, removeFilter, options = [], isMulti = false}: Props) {
  const selectedWithLabel = useMemo(() => {
    if (!selected) {
      return undefined;
    }

    if (isMulti) {
      return (selected as SelectItem[]).map((item) => {
        return options.find((option) => option.value === item.value);
      }) as SelectItem[];
    }
    return options.find((option) => option.value === (selected as SelectItem).value);
  }, [selected, options]);

  return (
    <Card className="tr-bg-white tr-p-0 tr-border-solid tr-border-[2px] tr-border-gray-200" variant="secondary">
      <div className="tr-flex tr-flex-col tr-gap-4 tr-pb-4">
        <div className="tr-flex tr-justify-between tr-items-center">
          <h4 className="tr-mb-0">{title}</h4>
          <IconButton onClick={removeFilter} variant="ternary" icon="remove" />
        </div>
        <Select
          name={key}
          defaultValue={selectedWithLabel}
          value={selectedWithLabel}
          options={options}
          onChange={(e: any) => {
            setSelected(e);
          }}
          required
          isMulti={isMulti}
        />
      </div>
    </Card>
  );
}
