import React, {Fragment, useState} from 'react';
import {Dropdown, DropdownMenu} from '@trustle/component-library';
import {DocumentationAction} from './actions/DocumentationAction';
import {Resource} from '../../../../../stores/domainObjects/Resource';
import {useAuthority} from '../../../../../connectors/common/authority/hooks/useAuthority';
import {ImportNowAction} from './actions/ImportNowAction';
import {SyncUsersAction} from './actions/SyncUsersAction';
import {UnarchiveSystemAction} from './actions/UnarchiveSystemAction';
import {DeleteSystemAction} from './actions/DeleteSystemAction';
import {ArchiveSystemAction} from './actions/ArchiveSystemAction';
import {useToasts} from 'react-toast-notifications';
import {useHistory} from 'react-router-dom';
import ConfirmDeleteResourceModal from '../../../Edit/ConfirmDeleteResourceModal';
import ArchiveResourceModal from '../../../Edit/ArchiveResourceModal';
import UnarchiveResourceModal from '../../../Edit/UnarchiveResourceModal';
import {observer} from 'mobx-react';
import {SyncUsersModal} from '../../../../../connectors/common/authority/modals/sync-users-modal/SyncUsersModal';
import {IfCondition} from '../../../../v2/common/IfCondition';

type Props = {
  resource: Resource;
  refreshResource: () => void;
};

export const SystemSettingsActions = observer(({resource, refreshResource}: Props) => {
  const {resource: authorityResource} = useAuthority();
  const {addToast} = useToasts();
  const history = useHistory();

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showArchiveModal, setShowArchiveModal] = useState<boolean>(false);
  const [showUnarchiveModal, setShowUnarchiveModal] = useState<boolean>(false);
  const [showSyncUsersModal, setShowSyncUsersModal] = useState<boolean>(false);

  const isArchived = Boolean(resource.archived);
  const isAbleToSyncUsers = resource.id === authorityResource?.id;

  return (
    <>
      <Dropdown
        data-testid="system-settings-actions"
        dropdownMenu={
          <DropdownMenu>
            <IfCondition condition={!resource.shouldDisableInteractions}>
              <ImportNowAction resource={resource} />
            </IfCondition>
            <IfCondition condition={isAbleToSyncUsers}>
              <SyncUsersAction onClick={() => setShowSyncUsersModal(true)} />
            </IfCondition>
            <hr key="first-separator" className="tr-m-0" />
            <IfCondition condition={isArchived}>
              <UnarchiveSystemAction onClick={() => setShowUnarchiveModal(true)} />
            </IfCondition>
            <IfCondition
              condition={isArchived}
              fallback={<ArchiveSystemAction onClick={() => setShowArchiveModal(true)} />}
            >
              <DeleteSystemAction onClick={() => setShowDeleteModal(true)} />
            </IfCondition>
            <hr key="second-separator" className="tr-m-0" />
            <IfCondition condition={!!resource.connector?.service}>
              <DocumentationAction service={resource.connector?.service!} />
            </IfCondition>
          </DropdownMenu>
        }
        variant={'primary'}
        size={'md'}
        dropdownToggle={<div>Actions</div>}
      />
      {showArchiveModal && (
        <ArchiveResourceModal
          resource={resource}
          closeModal={(wasArchived: boolean) => {
            if (!wasArchived) {
              setShowArchiveModal(false);
              return;
            }

            history.push('/manage');
          }}
        />
      )}
      {showUnarchiveModal && (
        <UnarchiveResourceModal
          resource={resource as Resource}
          closeModal={(wasUnarchive: boolean) => {
            setShowUnarchiveModal(false);
            if (!wasUnarchive) {
              return;
            }
            void refreshResource();
          }}
        />
      )}
      {showDeleteModal && (
        <ConfirmDeleteResourceModal
          resource={resource}
          closeModal={(wasDeleted: boolean) => {
            if (!wasDeleted) {
              setShowDeleteModal(false);
              return;
            }

            addToast(`${resource.name} has been deleted`, {
              appearance: 'success',
              autoDismiss: true,
            });
            history.push('/manage');
          }}
        />
      )}
      {showSyncUsersModal && <SyncUsersModal setShowModal={setShowSyncUsersModal} resource={resource} />}
    </>
  );
});
