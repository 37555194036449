import React from 'react';
import {TabsDocsT} from '../DocSection';
import DocSection from '../DocSection';

function ConfigureAWSIdentityCenter() {
  const tabs: TabsDocsT = [
    {
      id: 'aws-idc-overview',
      label: 'AWS IAM Identity Center Connector Overview',
      description: overview(),
      active: true,
    },
    {id: 'aws-idc-configuration-steps', label: 'Configuration Steps', description: configurationSteps(), active: true},
  ];

  function overview(): React.ReactNode {
    return (
      <>
        <p>
          The following guide describes the required steps to configure a connection between Trustle and AWS IAM
          Identity Center.
        </p>
        <p>This connector will allow Trustle to retrieve the data from AWS IAM Identity Center.</p>
        <p>With this connector, you'll effortlessly handle group memberships, among other functionalities.</p>
        <p>
          If you have any questions, please contact us at{' '}
          <a href="mailto:support@trustle.com" rel="noreferrer">
            support@trustle.com
          </a>
          .
        </p>
      </>
    );
  }

  function configurationSteps(): React.ReactNode {
    return (
      <>
        <div>
          <div className="tr-mb-4">
            Please take a look to the &nbsp;
            <a href="https://learn.trustle.com/tutorial/installing-aws-idc" target="_blank" rel="noreferrer">
              reference guides for this integration.
            </a>
          </div>
        </div>
      </>
    );
  }

  return (
    <DocSection tabs={tabs} title="How to configure Amazon Web Services IAM Identity Center (AWS IAM IDC) in Trustle" />
  );
}

export default ConfigureAWSIdentityCenter;
