import {useState} from 'react';
import axios from 'axios';
import {OrganizationSummaryParams} from '../types';

export type OrganizationSummaryResponse = {
  users_count: number;
  systems_count: number;
  teams_count: number;
  departments_count: number;
  roles_count: number;
  titles_count: number;
};

export function useOrganizationSummary() {
  const [data, setData] = useState<OrganizationSummaryResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BASE_URL = '/api/v2/organization_summary';

  const getOrganizationSummary = async (params: OrganizationSummaryParams) => {
    const parsedParams: Record<string, string> = {};

    Object.keys(params).forEach((key) => {
      const typedKey = key as keyof typeof params; // Ensure TypeScript understands the key type
      const value = params[typedKey] as string | number;
      if (params[typedKey] !== undefined) {
        parsedParams[typedKey] = value.toString();
      }
    });

    const queryString = new URLSearchParams(parsedParams).toString();

    setLoading(true);
    try {
      const response = await axios.get<OrganizationSummaryResponse>(`${BASE_URL}?${queryString}`);
      const responseData = response.data;
      setData(responseData);
    } catch (err: any) {
      // TODO: Implement handling error
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {data, loading, error, getOrganizationSummary};
}
