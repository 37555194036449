import React from 'react';
import {observer} from 'mobx-react';
import _ from 'lodash';

export default observer(function ShowAccount(props: {
  authzOwner?: any;
  label?: string | React.ReactElement;
  className?: string;
}) {
  const {authzOwner, label, className} = props;
  if (_.isNil(authzOwner) || _.isEmpty(authzOwner)) {
    return <></>;
  }
  return (
    <>
      <div className={className}>
        {label && label}
        <span>
          <div>
            <span className="tr-mr-1 tr-font-bold">Directory (tenant) ID:</span> {authzOwner.tenantId}
          </div>

          <div>
            <span className="tr-mr-1 tr-font-bold">Application (client) ID:</span> {authzOwner.clientId}
          </div>

          <div>
            <span className="tr-mr-1 tr-font-bold">Client Secret Value: </span>
            {authzOwner.clientSecret}
          </div>
        </span>
      </div>
    </>
  );
});
