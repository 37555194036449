import React, {Fragment} from 'react';
import {Filter} from './Filter';
import {SelectItem, FilterToApply, UserFilterKey} from '../../../types';
import {useFiltersConfigMap} from '../../../hooks/useUserFiltersConfigMap';
import {Button, Icon} from '@trustle/component-library';

type Props = {
  filters: FilterToApply[];
  setFilters: (filters: FilterToApply[]) => void;
  removeFilter: (key: UserFilterKey) => void;
  updateFilters: (key: UserFilterKey, selected: SelectItem[] | SelectItem) => void;
};

export function FiltersSection({filters, setFilters, removeFilter, updateFilters}: Props) {
  const FILTERS_CONFIG_MAP = useFiltersConfigMap();

  return (
    <div className="tr-flex tr-flex-col tr-gap-4 tr-h-full">
      <div className="tr-flex tr-justify-between tr-items-center">
        <h4 className="tr-mb-0 tr-text-trustle-navy text-lg">Applied Filters</h4>
        {filters.length > 0 && (
          <Button variant="secondary" className="tr-text-trustle-link tr-py-2 tr-px-3" onClick={() => setFilters([])}>
            <Icon type="cancel" /> Clear All
          </Button>
        )}
      </div>
      <div className="tr-flex tr-flex-col tr-gap-4 tr-overflow-auto">
        {filters.map(({key, value}) => (
          <Fragment key={key}>
            <Filter
              key={key}
              title={FILTERS_CONFIG_MAP[key].title}
              selected={value}
              setSelected={(selected) => {
                updateFilters(key, selected);
              }}
              removeFilter={() => removeFilter(key)}
              options={FILTERS_CONFIG_MAP[key].options}
              isMulti={FILTERS_CONFIG_MAP[key].isMulti}
            />
          </Fragment>
        ))}
      </div>
    </div>
  );
}
