import {Table} from '@trustle/component-library';
import React, {useEffect} from 'react';
import {useAccounts} from 'src/views/v2/system/accounts/panel/hooks/useAccounts';
import {useAccountFilters} from 'src/views/v2/system/accounts/panel/AccountsFiltersContext';
import {TableStatus} from 'src/views/v2/common/TableStatus';
import {useAccountsColumns} from './useAccountColumns';
import {Resource} from 'src/stores/domainObjects/Resource';

const TABLE_KEY = 'accounts-table';
type Props = {system: Resource};

export function AccountsTable({system}: Props) {
  const {data: listAccountsResponse, loading, fetchAccounts} = useAccounts();
  const columns = useAccountsColumns(system);
  const {filters, setFilter} = useAccountFilters();

  useEffect(() => {
    void fetchAccounts({systemId: system.id, ...filters});
  }, [filters]);

  const handleTableChange = async (_type: string, {page}: {page: number}) => {
    setFilter('page', page);
  };

  const handleSizePerPageChange = (size: number) => {
    setFilter('size', size);
  };

  if (loading || !listAccountsResponse) {
    return <TableStatus text="Loading accounts..." status="loading" description="Please stand by" />;
  }

  const {principals, total} = listAccountsResponse;

  return (
    <Table
      data={principals}
      columns={columns}
      tableKey={TABLE_KEY}
      totalSize={total}
      sizePerPage={filters.size}
      page={filters.page}
      remote={{
        filter: true,
        pagination: true,
        sort: false,
        cellEdit: false,
      }}
      onSizePerPageChange={(size) => {
        handleSizePerPageChange(size);
        return {};
      }}
      onTableChange={handleTableChange}
      showEmptyElement={
        <TableStatus status="no-data" text="No accounts found" description="Try with different filter criteria" />
      }
    />
  );
}
