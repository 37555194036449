import {Icon, Tooltip} from '@trustle/component-library';
import React from 'react';
import StatusIndicator from './StatusIndicator';

type PropsT = {
  status: 'pending' | 'processing' | 'completed' | 'failed' | 'warning';
  title?: string;
  onClickErrorIcon?: () => void;
};

function StepIndicator(props: PropsT) {
  const {status, title, onClickErrorIcon = () => {}} = props;

  const color = status === 'completed' ? 'success' : status === 'failed' ? 'text-danger' : 'pending';

  return (
    <div className="tr-flex align-items-center">
      <StatusIndicator status={color} />
      {title && (
        <span className={`${color} body6 ml-2`}>
          {title}
          {status === 'failed' ? (
            <span className="cursor" onClick={onClickErrorIcon}>
              <Tooltip content={<div>Click on the "See details" button below to see why it is failing.</div>}>
                <Icon type="moreInfo" size="sm" />
              </Tooltip>
            </span>
          ) : (
            <></>
          )}
        </span>
      )}
    </div>
  );
}

export default StepIndicator;
