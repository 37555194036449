import React, {useState} from 'react';
import {DashboardCard} from '../DashboardCard';
import {Icon, Select, Tab} from '@trustle/component-library';
import {ItemIconTypesEnum} from '@trustle/component-library/dist/types';
import {M365LicenseUsageProvider, useM365LicenseUsage} from './M365LicenseUsageContext';
import {M365LicenseUsage} from './M365LicenseUsage';
import {ViewType} from './types';
import {M365SitesUsage} from './demo-remove/M365SitesUsage';

type Props = {
  resourceId: string;
  showHeatmap?: boolean; // Default to false
  accountExternalId?: string;
};

export function M365VisibilityCard({showHeatmap = false, accountExternalId, resourceId = ''}: Props) {
  return (
    <M365LicenseUsageProvider resourceId={resourceId}>
      <M365VisibilityCardInternal
        showHeatmap={showHeatmap}
        accountExternalId={accountExternalId}
        resourceId={resourceId}
      />
    </M365LicenseUsageProvider>
  );
}

const DEFAULT_VIEW: {value: ViewType; label: string} = {
  value: 'week',
  label: 'Last 7 days',
};

const OPTIONS: {value: ViewType; label: string}[] = [
  {value: 'quarter', label: 'Last 90 days'},
  {value: 'month', label: 'Last 30 days'},
  {value: 'week', label: 'Last 7 days'},
];

function M365VisibilityCardInternal({showHeatmap = false, accountExternalId, resourceId = ''}: Props) {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [selectedView, setSelectedView] = useState(DEFAULT_VIEW);

  // TODO: Should be normalized with selectedView
  const {setView} = useM365LicenseUsage();

  return (
    <DashboardCard>
      <div className="tr-flex tr-justify-between tr-py-4">
        <div className="tr-flex tr-items-center tr-gap-2">
          <Icon type={'M365' as ItemIconTypesEnum} size="lg" />
          <h3 className="tr-mb-0">M365 Visibility</h3>
        </div>
        <div>
          <Select
            name="view"
            defaultValue={selectedView}
            value={selectedView}
            options={OPTIONS}
            onChange={(view) => {
              setSelectedView(view as any);
              // TODO: Should be normalized with selectedView
              setView((view as {value: ViewType; label: string}).value);
            }}
            required
          />
        </div>
      </div>
      <Tab.Group selectedIndex={selectedIndex} onChange={(i: number) => setSelectedIndex(i)}>
        <Tab.List variant="line">
          <Tab key={'LICENSES'}>Licenses</Tab>
          <Tab key={'SITES'}>Sharepoint Sites</Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel key={'LICENSES'}>
            <M365LicenseUsage
              showHeatmap={showHeatmap}
              accountExternalId={accountExternalId}
              resourceId={resourceId}
              selectedView={selectedView}
            />
          </Tab.Panel>
          <Tab.Panel key={'SITES'}>
            <M365SitesUsage />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </DashboardCard>
  );
}
