import React from 'react';
import {observer} from 'mobx-react';
import {Icon, ResourceIcon, SystemIcons, AccessStatusIcon} from '@trustle/component-library';
import {ResourceCategoryEnum} from 'src/types';
import '../PermissionBranch.scss';
import _ from 'lodash';
import {HiddenOrLocked} from './ResourceBranchComponents';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';
import {ResourceBranchPropsT} from '../ResourceBranch';

const ResourceHeader = observer((props: ResourceBranchPropsT) => {
  const {resource, accessMode, user} = props;

  if (!resource) {
    return <></>;
  }

  const category = resource.rootResource.category ?? ResourceCategoryEnum.RESOURCE;

  return (
    <>
      <HiddenOrLocked target={resource} accessMode={accessMode} />
      <div className="ml-2 mr-2 tr-flex tr-justify-center tr-items-center">
        {resource.rootSid ? (
          <ResourceIcon category={resource.category ?? category} size="sm" />
        ) : (
          <SystemIcons
            className="tr-p-1"
            size="md"
            name={resource.type as SystemIconsTypesEnum}
            title={resource.typeDisplayName}
          />
        )}
      </div>
      <div className="tr-ml-3 tr-mr-1">
        <div className="tr-flex tr-flex-row">
          <strong className="tr-truncate">{resource.name}</strong>
          <Icon type="moreInfo" size="sm" title={resource.description} className="tr-text-trustle-link" />
        </div>
        {!accessMode && !user && (
          <div className="tr-flex align-items-center body6 ">
            <Icon type="resource" title={category} size="xs" useDefaultColor={true} />
            <span className="px-1">{_.size(resource.childResources)}</span>
            <Icon type="permission" title="Permissions" size="xs" useDefaultColor={true} />
            <span className="px-1">{_.size(resource.permissions)}</span>
            <AccessStatusIcon status={5} title={'Active Accesses'} size="xs" />
            <span className="pl-1 body5-normal">
              {props.user
                ? _.size(resource.allCurrentUserActiveAccessRecords)
                : _.size(resource.allActiveAccessRecords)}
            </span>
          </div>
        )}
      </div>
    </>
  );
});

export default ResourceHeader;
