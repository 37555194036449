import _ from 'lodash';
import {Icon, Select, Tooltip, UserInfo} from '@trustle/component-library';
import React from 'react';
import StatusIndicator, {translateAccountStatus} from 'src/components/StatusIndicator';
import {Resource} from 'src/stores/domainObjects/Resource';
import {
  AccountColumnsE,
  sortByAccessCount,
  lastLoginIcon,
  unavailableIcon,
  OptionType,
} from 'src/components/design/tables/SharedColumnDefinitions/AccountColumns';
import {NumAccessesLink} from './columns/NumAccesesLink';
import {Risk} from './columns/Risk';
import moment from 'moment';
import {formatDateFromNow, formatFullDate} from 'src/lib';
import {useAccountFilters} from '../../AccountsFiltersContext';
import {AccountAccessType} from 'src/types';
import {OptionProps} from 'react-select';
import TrustleUserDropDown from '../accounts-view/TrustleUserDropDown';
import {Usage} from './columns/Usage';

export function useAccountsColumns(system: Resource) {
  const {filters} = useAccountFilters();
  const {linkStatus} = filters;

  return [
    {
      dataField: 'id',
      text: '',
      hidden: true,
    },
    {
      dataField: 'status',
      sort: true,
      configTitle: AccountColumnsE.STATUS,
      text: '',
      headerStyle: {width: '1em', paddingLeft: '0', paddingRight: '0'},
      style: {width: '1em', paddingLeft: '0', paddingRight: '0'},
      formatter: (_unused: any, account: any) => (
        <div>
          <StatusIndicator
            status={translateAccountStatus(account.status!)}
            messages={[_.capitalize(account.status!)]}
          />
        </div>
      ),
    },
    {
      dataField: 'user/account',
      sort: true,
      classes: 'show-on-hover',
      text: AccountColumnsE.ACCOUNT_NAME,
      formatter: (_unused: string, account: any) => (
        <>
          <UserInfo
            accountType={account?.account_type}
            account={account.account}
            className="tr-text-trustle-link"
          ></UserInfo>
        </>
      ),
      sortValue: (_cell: any, account: any) => {
        return account.account ?? '';
      },
    },
    {
      dataField: 'accesses.length',
      sort: true,
      text: AccountColumnsE.ACCESSES,
      formatter: (_unused: any, account: any) => <NumAccessesLink account={account} systemId={system.id} />,
      sortFunc: sortByAccessCount,
    },
    {
      dataField: 'riskScore',
      text: AccountColumnsE.RISK_SCORE,
      formatter: (_unused: any, account: any) => <Risk account={account} />,
      sort: true,
      sortValue: (_cell: any, account: any) => (_.isNumber(account.riskScore) ? account.riskScore : -1),
    },
    {
      dataField: 'usage',
      text: AccountColumnsE.USAGE,
      formatter: (_unused: any, account: any) => <Usage account={account} system={system} />,
      sort: true,
    },
    {
      dataField: 'accountType',
      sort: true,
      hidden: linkStatus === 'linked',
      text: 'Match Trustle User and Type',
      headerStyle: {width: '25em', textAlign: 'left', paddingLeft: '4px'},
      style: {width: '25em', textAlign: 'left', paddingLeft: '4px'},
      formatter: (_unused: any, account: any) => {
        const options = [
          {
            label: (
              <Tooltip content="Personal Account" position="bottom">
                <Icon type="user" forcedSize={20} />
              </Tooltip>
            ),
            value: AccountAccessType.PERSONAL,
          },
          {
            label: (
              <Tooltip content="Service Account" position="bottom">
                <Icon type="service" forcedSize={20} />
              </Tooltip>
            ),
            value: AccountAccessType.SERVICE,
          },
        ];

        const CustomOption = ({innerProps, data}: OptionProps<OptionType, boolean>) => (
          <div {...innerProps} className={'mx-2 my-1 account-select-item'}>
            {data.label}
          </div>
        );
        return (
          <div className="tr-py-1 account-selector tr-flex tr-items-center tr-gap-2">
            <div className="tr-basis-1.5/3.5">
              <Select
                aria-label={'select-account-type'}
                options={options}
                name="select-account-type"
                data-testid={`testselect-account-type}`}
                components={_.pickBy({Option: CustomOption}, _.identity)}
                menuPortalTarget={document.querySelector('body')}
                defaultValue={_.find(options, {value: account.accountType ?? AccountAccessType.PERSONAL})}
              />
            </div>
            <div className="tr-basis-2/3.5">
              <TrustleUserDropDown account={account} />
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'lastLogin',
      text: AccountColumnsE.LAST_LOGIN,
      formatter: (_unused: any, account: any) => {
        if (account.lastLogin) {
          const date = moment(account.lastLogin).toISOString();
          return <Tooltip content={formatFullDate(date)}>{formatDateFromNow(date)}</Tooltip>;
        } else {
          return (
            <span className="text-muted">{system?.isUsageLastLoginAvailable ? lastLoginIcon : unavailableIcon}</span>
          );
        }
      },
      sort: true,
    },
  ] as any;
}
