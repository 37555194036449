import moment from 'moment';
import _ from 'lodash';
import React from 'react';
import PermissionActionModal from 'src/components/PermissionActionModal';
import {DurationT} from 'src/types';
import {SubmitButton} from 'src/components/design';
import {Formik, FormikForm} from 'src/components/design/formik';
import {formatFullDate} from 'src/lib';
import axios from 'axios';
import {observer} from 'mobx-react-lite';
import {useRootStore} from 'src/lib/hooks';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import {Field} from 'formik';
import {Button, DurationInput, TextAreaInput} from '@trustle/component-library';

type RequestAccessModalProps = {
  accessRecord: AccessRecord;
  afterSaving: () => void;
  closeModal: () => void;
};

type RequestPermissionFormT = {
  reason: string;
  accessDuration: DurationT;
};

const ExtendAccessModal = observer(function ExtendAccessModalCmp(props: RequestAccessModalProps) {
  const {closeModal, accessRecord} = props;
  const {tasksStore} = useRootStore();
  const accessDuration = accessRecord.forPermission.calcAccessDuration;

  async function submit(formData: RequestPermissionFormT) {
    await axios.post(`/api/access/${accessRecord.id}/extend_provision`, formData);
    props.afterSaving();
  }

  const summaryText = (() => {
    const {accessExpiresTs, expiresTs} = accessRecord;
    if (!accessExpiresTs || moment(accessExpiresTs! || expiresTs!).isBefore(moment())) {
      return <p className="body5-normal">This access has expired. Extend it before being deprovisioned.</p>;
    }

    return (
      <p className="body5-normal">
        This access is set to expire at{' '}
        <span className="body5">{formatFullDate(accessRecord.accessExpiresTs! || expiresTs!)}</span>. If the account
        needs access beyond that point, you can request an extension using this form.
      </p>
    );
  })();

  return (
    <PermissionActionModal title="Extend access" closeModal={closeModal}>
      <Formik
        initialValues={{
          reason: '',
          accessDuration: _.defaults(accessDuration, {durationValue: '', durationUnit: ''}),
        }}
        onSubmit={async (formData: RequestPermissionFormT) => {
          await submit(formData);
          void tasksStore.refresh();
        }}
      >
        {({isSubmitting}) => (
          <FormikForm>
            {summaryText}
            <Field
              component={TextAreaInput}
              label="Reason"
              name="reason"
              description="Describe the reason you need this permission"
              required
            />
            <Field
              component={DurationInput}
              label="Extra Time"
              description="How much extra time will you need to be provisioned for"
              name="accessDuration"
              required
            />

            <div className="tr-flex tr-justify-between">
              <div>
                <Button name="permissionCancel" variant="secondary" onClick={closeModal}>
                  Cancel
                </Button>
              </div>
              <div className="clearfix">
                <div className="float-right">
                  <SubmitButton name="createRequest" inSubmit={isSubmitting} label={'Extend Access'} />
                </div>
              </div>
            </div>
          </FormikForm>
        )}
      </Formik>
    </PermissionActionModal>
  );
});

export {ExtendAccessModal};
