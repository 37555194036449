import _ from 'lodash';
import React from 'react';
import {SubmitButton} from 'src/components/design';
import './GAppsConnector.scss';
import {GAppsNextStepT} from './types';
import {ConnectionInfoT} from '../../types';
import {useFeatureFlags, useQueryParams} from 'src/lib';
import ConnectorStatus from 'src/components/StatusIndicator';
import {GAppsAuthenticationMethodE} from '../../types';
import {AuthenticationMethodSelection} from '../components/AuthenticationMethodSelection';
import {Icon} from '@trustle/component-library';

type GAppsAppPropsT = {
  onComplete: GAppsNextStepT;
  connectionInfo: ConnectionInfoT;
  editMode?: boolean;
  completeLabel?: string;
};

export default function GAppsAppSetup(props: GAppsAppPropsT) {
  const {completeLabel = 'Continue', editMode = false, connectionInfo} = props;
  const [actor, error, authenticationMethod] = useQueryParams(['actor', 'error', 'authenticationMethod']);
  const connected = !_.isEmpty(actor) && !error;
  const readOnlyMode = authenticationMethod === GAppsAuthenticationMethodE.APP_READ;
  const readWriteMode = authenticationMethod === GAppsAuthenticationMethodE.APP_WRITE;
  const connectedAsReadOnly = connected && readOnlyMode;
  const connectedAsReadWrite = connected && readWriteMode;
  const connectionId = _.get(connectionInfo, 'connection.id');

  const featureFlagViewer = useFeatureFlags();
  const gwsEnableReadOnly = featureFlagViewer.isEnabled('gws_enable_readonly');

  const authMethod = (() => {
    if (readWriteMode) {
      return 'automatic';
    }

    if (readOnlyMode) {
      return 'manual';
    }

    return null;
  })();

  const getConnectedMsg = (): React.ReactNode => {
    return (
      <>
        <span className="body5">
          <div className="tr-flex">
            <ConnectorStatus className="tr-mr-3" status={'success'}></ConnectorStatus>
            <span className="label success">Connected </span> - Authorized by: {actor}
          </div>
        </span>
      </>
    );
  };

  const getDisconnectedMsg = (): React.ReactNode => {
    return (
      <>
        <span className="body5">
          <div className="tr-flex">
            <ConnectorStatus className="tr-mr-3" status={'error'}></ConnectorStatus>
            <span>Not connected yet.</span>{' '}
          </div>

          {error && (
            <div className="tr-m-3">
              <Icon
                type="error"
                className="tr-mt-2"
                size="lg"
                title="Click here to see the Troubleshooting guide for Google workspace errors."
              />
              <a
                href="/docs/connectors/gapps/configure_gapps"
                className="tr-text-red-600"
                target="_blank"
                rel="noreferrer"
                title="Click here to see the Troubleshooting guide for Google workspace errors."
              >
                {error}.
              </a>
            </div>
          )}
        </span>
      </>
    );
  };

  const getConnectedStatusMsg = (): React.ReactNode => {
    if (editMode) {
      return;
    }
    if (connected) {
      return getConnectedMsg();
    }
    return getDisconnectedMsg();
  };

  const getUrlInstallAppContent = (method: GAppsAuthenticationMethodE) => {
    if (method === GAppsAuthenticationMethodE.APP_READ) {
      const params = new URLSearchParams({
        connectionId: connectionId,
        editConnection: `${editMode}`,
        authenticationMethod: `${GAppsAuthenticationMethodE.APP_READ}`,
        reauth: connected ? 'true' : 'false',
      });
      return `/api/connect/gapps/oauth/start?${params.toString()}`;
    }

    const params = new URLSearchParams({
      connectionId: connectionId,
      editConnection: `${editMode}`,
      authenticationMethod: `${GAppsAuthenticationMethodE.APP_WRITE}`,
      reauth: connected ? 'true' : 'false',
    });
    return `/api/connect/gapps/oauth/start?${params.toString()}`;
  };

  const [manualBtnText, automaticBtnText] = (() => {
    if (connectedAsReadOnly) {
      return ['Renew', 'Switch to Automatic'];
    }
    if (connectedAsReadWrite) {
      return ['Switch to Manual', 'Renew'];
    }
    return ['Select Manual', 'Select Automatic'];
  })();

  const installAppContent = (
    <AuthenticationMethodSelection
      enableReadOnlyMode={gwsEnableReadOnly}
      manual={{
        authenticationStatus: getConnectedStatusMsg(),
        description: 'This application allows Trustle to import groups and members to track access.',
        button: {
          label: manualBtnText,
          href: getUrlInstallAppContent(GAppsAuthenticationMethodE.APP_READ),
        },
      }}
      automatic={{
        authenticationStatus: getConnectedStatusMsg(),
        description:
          'This application allows Trustle to import your Google Workspace Organization data and will automatically provision and deprovision Google Workspace Organization access.',
        button: {
          label: automaticBtnText,
          href: getUrlInstallAppContent(GAppsAuthenticationMethodE.APP_WRITE),
        },
      }}
      method={authMethod}
    />
  );

  return (
    <div className="github-app-setup connect-section">
      <p>
        Trustle uses OAuth to import and provision access to your Google Workspace account. To connect Trustle to your
        Google Workspace organization, you must use a Google account with the required permissions.{' '}
        <strong>
          Please be sure to grant the predefined administrator role{' '}
          <a
            href="https://support.google.com/a/answer/2405986?sjid=14217478134709019929-SA#super_admin"
            target="_blank"
            rel="noreferrer"
          >
            Super Admin
          </a>{' '}
          to the user that you use here.
        </strong>{' '}
      </p>

      <div className="tr-pb-4 tr-pt-4">
        {gwsEnableReadOnly && <div className="body4 pb-3">Select one of the following authentication methods</div>}
        <div className="body5-normal pb-2">{installAppContent}</div>
      </div>
      {!editMode && (
        <div>
          <div className="text-right">
            <SubmitButton
              name="appComplete"
              disabled={!connected}
              onClick={() => {
                props.onComplete({});
              }}
              label={completeLabel}
            />
          </div>
        </div>
      )}
    </div>
  );
}
