import _ from 'lodash';
import moment from 'moment';
import {AccountAccessType, AccountStatus, UserType} from 'src/types';
import React from 'react';
import {ColumnDescription, Icon, Tooltip} from '@trustle/component-library';
import {formatDateFromNow, formatFullDate} from 'src/lib';
import {ItemIconTypesEnum} from '@trustle/component-library/dist/types';

export enum UploadedEntityType {
  RESOURCE = 'resource',
  ACCOUNT = 'account',
  PERMISSION = 'permission',
  EVENT = 'event',
}

export enum CSVContent {
  ACCOUNT = 'account',
  RESOURCE_PERMISSION = 'resource/permission',
  ACCESS_RECORD = 'access',
  EVENT = 'event',
}

type ColumnDefT = {
  key: string;
  isRequired: boolean;
  description: string;
  hide: boolean;
  validOptions?: string[];
  conditions?: string | string[];
};

export const requiredAccountColumns: ColumnDescription[] = [
  { dataField: 'id', text: 'ID' },
  { dataField: 'name', text: 'name' },
];

export const optionalAccountColumns: ColumnDescription[] = [
  { dataField: 'type', text: 'type' },
  { dataField: 'subtype', text: 'subtype' },
  { dataField: 'firstName', text: 'firstName' },
  { dataField: 'lastName', text: 'lastName' },
  { dataField: 'email', text: 'email' },
  { dataField: 'userType', text: 'userType' },
  { dataField: 'status', text: 'status' },
  { dataField: 'metadata', text: 'metadata' },
];

export const exampleDataCSVAccounts = [
  {
    id: 'ACCOUNT-001',
    name: 'administrator',
    email: 'administrator@trustle.com',
    status: 'active',
    userType: 'employee',
    type: 'account',
    subtype: 'admin',
    firstName: 'Admin',
    lastName: 'Trustle',
    metadata: '{"role": "full-access"}'
  },
  {
    id: 'ACCOUNT-002',
    name: 'username',
    email: 'username@trustle.com',
    status: 'offboarded',
    userType: 'contractor',
    type: 'account',
    subtype: 'temporary',
    firstName: 'User',
    lastName: 'Name',
    metadata: '{"role": "limited-access"}'
  },
  {
    id: 'ACCOUNT-003',
    name: 'trustle_system',
    email: 'system_user@trustle.com',
    status: 'suspended',
    userType: 'system',
    type: 'account',
    subtype: 'system',
    firstName: 'System',
    lastName: 'User',
    metadata: '{"role": "system-task"}'
  },
  {
    id: 'ACCOUNT-004',
    name: 'new_user',
    type: 'account',
    subtype: 'personal',
    firstName: 'New',
    lastName: 'User',
    email: 'new_user@trustle.com',
    userType: 'employee',
    status: 'active',
    metadata: '{"role": "user"}'
  },
  {
    id: 'ACCOUNT-005',
    name: 'service_account',
    type: 'account',
    subtype: 'service',
    firstName: 'Service',
    lastName: 'Account',
    email: 'service_account@trustle.com',
    userType: 'system',
    status: 'active',
    metadata: '{"role": "service-access"}'
  },
];

export const requiredResPermColumns = [
  { dataField: 'id', text: 'ID' },
  { dataField: 'name', text: 'name' },
  { dataField: 'type', text: 'type' },
];

export const optionalResPermColumns = [
  { dataField: 'subtype', text: 'subtype' },
  { dataField: 'description', text: 'description' },
  { dataField: 'resource', text: 'resource' },
  { dataField: 'metadata', text: 'metadata' },
];

export const requiredAccessColumns: ColumnDescription[] = [
  { dataField: 'id', text: 'ID' },
  { dataField: 'name', text: 'name' },
];

export const optionalAccessColumns: ColumnDescription[] = [
  { dataField: 'type', text: 'type' },
  { dataField: 'permissions', text: 'permissions' },
];

export const exampleDataCSVAccess = [
  {
    id: 'ACCESS-001',
    name: 'administrator',
    type: 'access',
    permissions: ['PERM_001, PERM_002'],
  },
  {
    id: 'ACCESS-002',
    name: 'username',
    type: 'access',
    permissions: ['PERM_005, PERM_002'],

  },
  {
    id: 'ACCESS-003',
    name: 'trustle_system',
    type: 'access',
    permissions: ['PERM_001, PERM_002'],
  }
];

export const exampleDataCSVResourcesAndPermissions = [
  { id: 'RES_001', name: 'AWS Groups', type: 'resource', subtype: '', description: 'This is an example', resource: '', metadata: '' },
  { id: 'RES_002', name: 'AWS Managed Policies', type: 'resource', subtype: '', description: 'This is an example', resource: '', metadata: '' },
  { id: 'RES_003', name: 'AWS Inline Policies', type: 'resource', subtype: '', description: 'This is an example', resource: '', metadata: '' },
  { id: 'PERM_001', name: 'AmazonS3ReadOnlyAccess', type: 'permission', subtype: '', description: 'This is an example', resource: 'AWS Managed Policies', metadata: '{"Version":"2012-10-17"}' },
  { id: 'PERM_002', name: 'development', type: 'permission', subtype: '', description: 'This is an example', resource: 'AWS Groups', metadata: '{"owner":"administrator"}' },
  { id: 'PERM_003', name: 'list_access_reports', type: 'permission', subtype: '', description: 'This is an example', resource: 'AWS Inline Policies', metadata: '{"Statement":[{"Effect":"Allow", "Resource":"*"}]}' },
];

export const entityColumnsDef = (csvContent?: CSVContent): ColumnDefT[] => {
  const notSpecifiedContent = _.isNil(csvContent);
  const isAccount = csvContent === CSVContent.ACCOUNT;
  const isAccess = csvContent === CSVContent.ACCESS_RECORD;
  const isEvent = csvContent === CSVContent.EVENT;
  const entityName = notSpecifiedContent ? 'entity' : isAccess ? 'account' : csvContent?.toString();
  const isResOrPerm = csvContent === CSVContent.RESOURCE_PERMISSION;

  const sDate = moment().format('YYYY-MM-DD hh:mm:ss A');

  return [
    {
      key: 'id',
      isRequired: !isEvent,
      description: `Unique identifier for the ${entityName}.`,
      hide: isEvent,
      conditions: `e.g.: "123e4567-e89b-12d3-a456-426652340000", "ABCDEFGHIJ12345KLMN9", "123456"`,
    },
    {
      key: 'name',
      isRequired: true,
      description:
        isAccount || isAccess
          ? 'Username of the account.'
          : `Name used to identify the ${entityName} (may not be unique).`,
      hide: false,
      conditions: isEvent
        ? ['login_success', 'last_activity']
        : isAccount
        ? 'e.g.: "username@domain.com", "administrator", "App Service"'
        : 'e.g.: "Subscription A", "My Groups", "Production Resources", "Read/Write", "Administrator role"',
    },
    {
      key: 'type',
      isRequired: notSpecifiedContent || isResOrPerm,
      description: 'Type of entity represented by the row.',
      hide: !(notSpecifiedContent || isResOrPerm),
      conditions: isResOrPerm
        ? [UploadedEntityType.RESOURCE, UploadedEntityType.PERMISSION]
        : _.values(UploadedEntityType),
    },
    {
      key: 'subtype',
      isRequired: false,
      description: `Sub-type of ${entityName}.`,
      hide: isAccess || isResOrPerm,
      conditions: _.values(AccountAccessType),
    },
    {
      key: 'firstName',
      isRequired: false,
      description: 'First name for the account (personal or service).',
      hide: !(notSpecifiedContent || isAccount),
      conditions: 'E.g.: "Jane", "Admin Console"',
    },
    {
      key: 'lastName',
      isRequired: false,
      description: 'Last name for the account (personal or service).',
      hide: !(notSpecifiedContent || isAccount),
      conditions: 'E.g.: "Doe", "App"',
    },
    {
      key: 'email',
      isRequired: false,
      description: 'Email for the account, based on which a Trustle user will linked to the account.',
      hide: !(notSpecifiedContent || isAccount),
      conditions: 'Required format: "somename@domain.something"',
    },
    {
      key: 'userType',
      isRequired: false,
      description: 'Type of user, only required if email is specified.',
      hide: !(notSpecifiedContent || isAccount),
      conditions: _.values(UserType),
    },
    {
      key: 'description',
      isRequired: false,
      description: `Short description for the ${entityName}.`,
      hide: !isResOrPerm,
    },
    {
      key: 'createdDate',
      isRequired: isEvent,
      description: `Date of creation of the ${entityName}.`,
      hide: isAccess,
      conditions: `Required format: YYYY-MM-DD hh:mm:ss A, e.g. "${sDate}"`,
    },
    {
      key: 'deletedDate',
      isRequired: false,
      description: `Date of deletion of the ${entityName}.`,
      hide: isEvent || isAccess,
      conditions: `Required format: YYYY-MM-DD hh:mm:ss A, e.g. "${sDate}"`,
    },
    {
      key: 'status',
      isRequired: false,
      description: `Current status of the ${entityName}.`,
      hide: !isAccount,
      conditions: _.values(AccountStatus),
    },
    {
      key: 'metadata',
      isRequired: isEvent,
      description: `Any type of extra information associated with the ${entityName}. ${
        isEvent ? 'Must include a key called entityId to specify the related account.' : ''
      }`,
      hide: isAccess,
      conditions: 'Required format: escaped JSON string, e.g.: "{""key1"": ""value1"", ""key2"": ""value2""}".',
    },
    {
      key: 'resource',
      isRequired: false,
      description: `${
        isResOrPerm
          ? 'Only valid for permissions: name of the resource to which the permission belongs. If null, the entity will be considered as a system permission.'
          : `Name of the resource to which the ${entityName} belongs.`
      }`,
      hide: !(notSpecifiedContent || isResOrPerm),
      conditions: 'e.g.: "My Groups", "Production Resources"',
    },
    {
      key: 'permissions',
      isRequired: false,
      description: `List of permissions granted to the ${entityName}.`,
      hide: !(notSpecifiedContent || isAccess),
      conditions: 'Required format: comma-separated list of ids, e.g.: "permission-id-1, permission-id-n"',
    },
    {
      key: 'lastLogin',
      isRequired: false,
      description: 'Last date and time when the account logged in.',
      hide: !(notSpecifiedContent || isAccount),
      conditions: `Required format: YYYY-MM-DD hh:mm:ss A, e.g. "${sDate}"`,
    },
  ];
};

export const parseDate = (row: any, sDate: string, setError: any): string | undefined => {
  const parsed = _.trim(sDate)
    ? moment(_.trim(sDate), 'YYYY-MM-DDThh:mm:ss.000Z a').format('YYYY-MM-DDTHH:mm:ss.000Z')
    : undefined;

  if (parsed === 'Invalid date') {
    setError(`Incorrect date format on row (${row.id}: ${row.name})`);
    throw `Incorrect date format on row (${row.id}: ${row.name})`;
  }
  return parsed;
};

export function parseMetadata(row: any, setError: any): any | undefined {
  try {
    const value = _.trim(row.metadata) ?? {};
    return _.isEmpty(value) ? undefined : JSON.parse(value);
  } catch (err) {
    setError(`Incorrect metadata format on row (${row.id}: ${row.name})`);
    throw new Error(`Incorrect metadata format on row (${row.id}: ${row.name})`);
  }
}

export function parseArray(row: any, sArray: string | undefined, setError: any): string[] | undefined {
  try {
    const clean = (sArray || '').replaceAll(' ', '').replaceAll('[', '').replaceAll(']', '');
    const splitted = _.split(clean, ',');
    return _.isEmpty(clean) || _.size(splitted) === 0 ? undefined : _.toArray(splitted);
  } catch (err) {
    setError(`Incorrect array format on row (${row.id}: ${row.name})`);
    throw new Error(`Incorrect array format on row (${row.id}: ${row.name})`);
  }
}
export const getColumns = (csvContent: CSVContent, selectedIndex: number): ColumnDescription[] => {
  if (csvContent === CSVContent.ACCOUNT) {
    return getAccountColumns(selectedIndex);
  } else if (csvContent === CSVContent.RESOURCE_PERMISSION) {
    return getResourceColumns();
  } else if (csvContent === CSVContent.ACCESS_RECORD) {
    return getAccessColumns();
  } else {
    return [
      {dataField: 'id', hidden: true, text: 'ID'},
      {
        dataField: 'name',
        sort: true,
        text: 'Name',
        headerStyle: {width: '9em', textAlign: 'left'},
        style: {width: '9em', textAlign: 'left'},
        formatter: (_field: string, record: any) => {
          const {name, resource, type} = record;
          return (
            <div className="tr-flex-col tr-my-1">
              <div className="text-gray-500 tr-mb-1">
                {type === 'permission' && _.isEmpty(resource) ? 'System Permission' : resource}
              </div>
              <div className="tr-font-bold">{record.label ?? name}</div>
            </div>
          );
        },
      },
    ];
  }
};

// TODO: comments will be deleted after decided what optional charged fields should render the table
const getAccountColumns = (selectedIndex: number): ColumnDescription[] => {
  return [
    {
      dataField: 'id',
      text: 'ID',
      hidden: false,
      headerStyle: {width: '10em', textAlign: 'left'},
      style: {width: '10em', textAlign: 'left'},
    },
    // StatusColumn,
    {
      dataField: 'name',
      sort: true,
      text: 'Name',
      headerStyle: {width: '11em', textAlign: 'left'},
      style: {width: '11em', textAlign: 'left'},
      formatter: (_field: string, record: any) => {
        const {id, firstName, lastName, name, subtype} = record;

        return (
          <div key={id} className={`tr-my-1  `}>
            <div className="tr-font-bold tr-mb-1">{`${firstName ?? ''} ${lastName ?? ''}`}</div>
            <div className="tr-flex">
              {subtype === 'service' && <Icon type="service" title="Service" size="sm" />}
              <div>{name}</div>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'deletedDate',
      sort: true,
      text: 'Deleted Date',
      hidden: selectedIndex !== 2,
      headerStyle: {width: '7em', textAlign: 'left'},
      style: {width: '7em', textAlign: 'left'},
      formatter: (deletedDate: string, _record: any) => {
        const deletedAt = deletedDate ?? moment().toISOString();
        return <Tooltip content={formatFullDate(deletedAt)}>{formatDateFromNow(deletedAt)}</Tooltip>;
      },
    },
  ];
};

const getResourceColumns = (): ColumnDescription[] => {
  return [
    {
      dataField: 'id',
      text: 'ID',
      hidden: false,
      headerStyle: {width: '10em', textAlign: 'left'},
      style: {width: '10em', textAlign: 'left'},
    },
    {
      dataField: 'name',
      sort: true,
      text: 'Name',
      headerStyle: {width: '10em', textAlign: 'left'},
      style: {width: '10em', textAlign: 'left'},
      formatter: (_field: string, record: any) => {
        const {name, resource, type} = record;
        return (
          <div className="tr-flex-col tr-my-1">
            <div className="text-gray-500 tr-mb-1">
              {type === 'permission' && _.isEmpty(resource) ? 'System Permission' : resource}
            </div>
            <div className="tr-font-bold">{record.label ?? name}</div>
          </div>
        );
      },
    },
    {
      dataField: 'type',
      hidden: false,
      sort: true,
      text: 'Type',
      headerStyle: {width: '10em', textAlign: 'left'},
      style: {width: '10em', textAlign: 'left'},
      formatter: (_field: string, record: any) => {
        return (
          <div className="tr-flex-col tr-my-1">
            <div className="text-gray-500 tr-mb-1">{record.type ?? record.refId ? 'Permission' : 'Resource'}</div>
            <div className="tr-font-bold">{record.label ?? record.name}</div>
          </div>
        );
      },
    },
    {
      dataField: 'description',
      sort: true,
      text: 'Description',
      headerStyle: {width: '10em', textAlign: 'left'},
      style: {width: '10em', textAlign: 'left'},
    },
    {
      dataField: 'resource',
      sort: true,
      text: 'Resource',
      headerStyle: {width: '10em', textAlign: 'left'},
      style: {width: '10em', textAlign: 'left'},
      formatter: (_field: string, record: any) => {
        return <div className="tr-flex-col tr-my-1">{record.resource ?? record.foreignId}</div>;
      },
    },
  ];
};

const getAccessColumns = (): ColumnDescription[] => {
  return [
    {dataField: 'id', hidden: true, text: ''},
    {
      dataField: 'type',
      text: '',
      headerStyle: {width: '3em'},
      style: {width: '3em', textAlign: 'center'},
      formatter: (type: string, _record: any) => {
        return (
          <div className="tr-flex-row">
            <div>{<Icon type={type as ItemIconTypesEnum} title={type} size="sm" />}</div>
          </div>
        );
      },
    },
    {
      dataField: 'relatedName',
      sort: true,
      text: 'Permission',
      headerStyle: {width: '25em', textAlign: 'left'},
      style: {width: '25em', textAlign: 'left'},
      formatter: (field: string, _record: any) => {
        return <div className="tr-font-bold">{field}</div>;
      },
    },
    {
      dataField: 'account',
      sort: true,
      text: 'Account',
      headerStyle: {width: '30em', textAlign: 'left'},
      style: {width: '30em', textAlign: 'left'},
      formatter: (field: string, _record: any) => {
        return <div>{field}</div>;
      },
    },
  ];
};
