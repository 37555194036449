import {ConnectionServiceE} from 'src/types';
import {FormFieldTypes} from '../common/types';
import {ConnectorSchema} from './default';

const gcp: ConnectorSchema = {
  service: ConnectionServiceE.GCP,
  name: 'GCP',
  description: 'Connect to Google Cloud Provider',
  serviceId: 'gcp',
  auth: {
    type: 'accesKeys',
    title: 'Service account configuration',
    description: 'Trustle uses a service account to import an provision access to your GCP account.',
    docs: {
      link: `/docs/connectors/gcp/configure_gcp`,
      linkText: `Guide: Setting up an GCP Integration`,
    },
    fields: [
      {
        placeholder: 'Enter super admin user email',
        label: 'Super Admin User',
        type: FormFieldTypes.EMAIL,
        name: 'superAdminUser',
        id: 'superAdminUser',
        required: true,
      },
      {
        placeholder: 'Upload service account credential',
        label: 'Service account credential',
        type: FormFieldTypes.TEXTAREA,
        name: 'serviceAccountCredential',
        id: 'serviceAccountCredential',
        required: true,
      },
    ],
  },
};

export default gcp;
