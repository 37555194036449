import React from 'react';

function ConfigureOAuth() {
  return (
    <>
      <div className="mb-4 body3">OAuth Overview</div>
      <div>Coming soon...</div>
    </>
  );
}

export default ConfigureOAuth;
