import _ from 'lodash';
import React from 'react';
import CircleStep from 'src/components/CircleStep';
import {useRootStore} from 'src/lib/hooks';
import DocSection, {TabsDocsT} from '../DocSection';

function ConfigureSAML() {
  const {org} = useRootStore();

  if (_.isNil(org)) {
    return <></>;
  }

  function overview(): React.ReactNode {
    return (
      <>
        <p>
          The following guide describes the required steps to configure SAML on Trustle, using Okta as example for the
          Identity Provider.
        </p>
        <p>
          This feature will allow the organization users to access Trustle by clicking the configured app from the User
          Home in the IdP, as shown below:
        </p>
        <div className="image-wrap">
          <img src="/docs/authentication/idp_user_home.png" className="vertical-align mb-4 mt-3 shadow overflow-auto" />
        </div>
        <p>
          If you have any questions, please contact us at <a href="mailto:support@trustle.com">support@trustle.com</a>.
        </p>
        <cite className="blockquote-footer">
          Note that, regardless of the provider used here, any IdP can be configured following these instructions and it
          will be supported by Trustle.
        </cite>
      </>
    );
  }

  function Step(props: {
    stepNumber: number;
    title: string;
    details?: string;
    img?: string;
    more?: string;
  }): JSX.Element {
    const {stepNumber, title, details, img, more} = props;

    return (
      <p className="py-2">
        <CircleStep>{stepNumber}</CircleStep>
        <span className="body5">{title}</span>
        <p className="ml-5">
          <p>{details}</p>
          {more && <cite className="blockquote-footer">{more}</cite>}
          {img && (
            <div className="image-wrap">
              <img src={img} className="vertical-align mb-4 mt-3 shadow" />
            </div>
          )}
        </p>
      </p>
    );
  }

  function Item(props: {attribute: string; value: string}): JSX.Element {
    const {attribute, value} = props;
    return (
      <li>
        {attribute}: <code className="text-info">{value}</code>
      </li>
    );
  }

  const configurationSteps = () => {
    return (
      <>
        <div className="tr-w-full">
          <p className="tr-mb-4 body3">Requirements: user creation</p>
          <p>
            Organization users need to be created and match in the SP and the IdP. Please, verify the following settings
            before proceeding with the configuration steps:
          </p>
          <p className="tr-m-4 tr-mb-3">
            <li>
              In the IdP, add all the users that will have access to Trustle.
              <cite className="blockquote-footer">
                For Okta, you can do this in <em>Directory/People</em>.
              </cite>
            </li>
            <li>
              In Trustle, go to <em>Admin/Users</em> and verify there's a user matching each email in the IdP list, or
              add it if required.
            </li>
          </p>

          <p className="tr-mb-4 body3">Identity Provider (IdP) Configuration</p>

          <Step
            stepNumber={1}
            title="Log in to the IdP"
            details="Sign in, then go to the Admin Dashboard and look for the Applications section."
          />
          <Step
            stepNumber={2}
            title="Create a new app integration"
            details="Create an app integration for SAML 2.0."
            img="/docs/authentication/idp_create_new_app.png"
          />
          <Step stepNumber={3} title="Configure the application" details="Specify the following values in the form:" />
          <p className="ml-5 mb-3">
            <Item attribute="App name" value="Trustle" />
            <Item
              attribute="Single Sign On (SSO) URL"
              value={`${org.getOrgRedirectUrl('/api/auth/saml/login/callback')}`}
            />
            <Item attribute="Recipient URL and Destination URL" value="(same as above)" />
            <Item attribute="Audience URI (SP Entity ID)" value={org.getOrgRedirectUrl('')} />
            <Item attribute="Name ID format" value="EmailAddress" />
            <Item attribute="Application username" value="Email" />
          </p>
          <Step
            stepNumber={4}
            title="Configure the users"
            details="Add all the users that will have access to the new app."
            more='For Okta, go to "Assignments" and select "Assign to People".'
            img="/docs/authentication/idp_assign_to.png"
          />
          <Step
            stepNumber={5}
            title="Get the parameters for the Service Provider configuration"
            details="Look for the IdP metadata, this information will be required in the next steps. The information should include: IdP Issuer, IdP Login (SSO) URL and X.509 Certificate."
            more='For Okta, go to the Sign-on section and click on "View Setup Instructions".'
            img="/docs/authentication/idp_metadata.png"
          />

          <p className="tr-mb-4 body3">Service Provider (SP) Configuration</p>

          <Step
            stepNumber={6}
            title="Add an Identity Provider"
            details="Go back to the Security Settings in the Admin screen and create a new SAML Provider."
            img="/docs/authentication/sp_add_provider.png"
          />
          <Step
            stepNumber={7}
            title="Fill the form"
            details="Use the IdP setup instructions from step 5 to complete the required fields."
          />
          <Step
            stepNumber={8}
            title="Activate the SAML Identity Provider"
            details="By doing this, the rest of the authentication methods will be automatically disabled."
          />
          <cite className="tr-ml-5 blockquote-footer">
            <b>Note:</b> {'If you loose access to Trustle due to a misconfiguration, please contact us at '}
            <a href="mailto:support@trustle.com">support@trustle.com</a>.
          </cite>
        </div>
      </>
    );
  };

  const tabs: TabsDocsT = [
    {id: 'saml-overview', label: 'Overview', description: overview(), active: true},
    {id: 'saml-configuration-steps', label: 'Configuration Steps', description: configurationSteps(), active: false},
  ];

  return (
    <>
      <DocSection tabs={tabs} title="How to configure SAML authentication in Trustle" />
    </>
  );
}

export default ConfigureSAML;
