import {useState} from 'react';
import {LicensesResponse} from '../types';
import axios from 'axios';

type FetchParams = {
  accountExternalId?: string;
};

type Props = {
  resourceId: string;
};

export function useLicenses({resourceId}: Props) {
  const [data, setData] = useState<LicensesResponse>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const BASE_URL = `/api/resources/${resourceId}/licenses`;

  const fetchLicenses = async (params?: FetchParams) => {
    const {accountExternalId} = params || {};

    const queryString = accountExternalId
      ? new URLSearchParams({account_external_id: accountExternalId}).toString()
      : '';

    const URL_WITH_QUERY = queryString ? `${BASE_URL}?${queryString}` : BASE_URL;

    setLoading(true);
    try {
      const response = await axios.get<LicensesResponse>(URL_WITH_QUERY);
      const responseData = response.data;
      setData(responseData);
    } catch (err: any) {
      // TODO: Implement handling error
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {data, loading, error, fetchLicenses};
}
