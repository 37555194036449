import React, {useEffect, useState} from 'react';
import {Formik, Form as FormikForm, Field} from 'formik';
import {SubmitButton} from 'src/components/design';
import {MinUserT, TeamT, UserT, TeamTypeEnum} from 'src/types';
import _ from 'lodash';
import {components, ControlProps, OptionProps} from 'react-select';
import {formatUserNameAndEmail} from 'src/lib';
import {Button, Loading, Select, TextAreaInput, TextInput, TileButton} from '@trustle/component-library';
import {array, object, string} from 'yup';
import axios from 'axios';
import {useToasts} from 'react-toast-notifications';
import {ListUsersParams} from '../admin-users-panel/hooks/useUsers';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';

type PropsT = {
  team?: Partial<TeamT>;
  teams?: TeamT[];
  users: UserT[];
  members?: UserT[];
  onClose: () => void;
  onAfterSubmit: (team: TeamT) => void;
};

type OptionType = {
  value: string;
  label: string;
};

export type TeamFormT = {
  id: string | undefined;
  name: string;
  description: string;
  color?: {value: string; label: string} | string;
  type?: TeamTypeEnum;
  members: string[];
  managers: string[];
};

export default function TeamForm(props: PropsT) {
  const {addToast} = useToasts();
  const {team, users = [], members = [], onClose, onAfterSubmit} = props;
  const [loading, setLoading] = useState(false);

  const [localMembers, setMembers] = useState<UserT[]>(members);

  const loadData = async (params: ListUsersParams) => {
    const {page, size, ...rest} = params;

    const parsedParams: Record<string, string> = {
      page: (page || 1).toString(),
      size: (size || 100).toString(),
    };
    Object.keys(rest).forEach((key) => {
      const typedKey = key as keyof typeof rest;
      const value = rest[typedKey] as string | number;
      if (rest[typedKey] !== undefined) {
        parsedParams[typedKey] = value.toString();
      }
    });
    delete parsedParams.status;
    delete parsedParams.groupBy;

    if (team?.id) {
      parsedParams.team_id = team.id;
    }

    const queryString = new URLSearchParams(parsedParams).toString();
    setLoading(true);
    try {
      const response = await axios.get(`/api/v2/users?${queryString}`);
      setMembers(response.data.users);
      setLoading(false);
    } catch (e: any) {
      const errorMessage = e.response.data?.error?.message || 'An error occurred while saving your changes';
      addToast(errorMessage, {appearance: 'error', autoDismiss: true});
      setLoading(false);
      throw errorMessage;
    }
  };

  useEffect(() => {
    if (team?.id) {
      void loadData({});
    }
  }, []);

  const teamTypes = _.filter(
    [
      {label: 'Organization', value: TeamTypeEnum.ORGANIZATION},
      {label: 'Ad Hoc', value: TeamTypeEnum.ADHOC},
      {label: 'Owner', value: TeamTypeEnum.OWNER},
    ],
    (teamOption) => {
      // Existing teams cannot change type.
      if (team?.id) {
        return teamOption.value === team?.type;
      }

      // 'OWNER' teams cannot be created.
      return teamOption.value !== TeamTypeEnum.OWNER;
    }
  );

  const teamColors = [
    {label: 'Blue', value: 'blue'},
    {label: 'Dark Blue', value: 'dark-blue'},
    {label: 'Green', value: 'green'},
    {label: 'Violet', value: 'violet'},
    {label: 'Red', value: 'red'},
    {label: 'Yellow', value: 'yellow'},
    {label: 'Pink', value: 'pink'},
    {label: 'Orange', value: 'orange'},
    {label: 'Turquoise', value: 'turquoise'},
  ];

  function convertTeamToForm(team: Partial<TeamT>): TeamFormT {
    // Defaulting to ORGANIZATION teams until we support AdHoc as an option.
    const teamType = team?.id ? team.type : TeamTypeEnum.ADHOC;
    const color = _.find(teamColors, {value: team.color});

    return {
      id: team.id,
      name: team.name || '',
      description: team.description || '',
      color: color ? color.value : '',
      type: teamType as TeamTypeEnum,
      members:
        _.map(localMembers, (o: MinUserT) => {
          return o.id;
        }) ?? [],
      managers:
        _.map(team.managers, (o: MinUserT) => {
          return o.id;
        }) ?? [],
    };
  }

  async function onSubmit(values: TeamFormT) {
    const body = {
      name: values.name,
      description: values.description,
      color: values.color,
      type: values.type,
      members: values.members,
      managers: values.managers,
    };

    if (team?.id) {
      delete body.type;
    }

    try {
      const uri = team?.id ? `/api/teams/${team?.id}` : `/api/teams`;
      const {data} = await axios.post(uri, body);
      const successMessage = `Team ${team?.name} ${team?.id ? 'edited' : 'created'} successfully`;
      addToast(successMessage, {appearance: 'success', autoDismiss: true});
      onAfterSubmit(data);
      onClose();
    } catch (e: any) {
      const errorMessage = e.response.data?.error?.message || 'An error occurred while saving your changes';
      addToast(errorMessage, {appearance: 'error', autoDismiss: true});
      throw errorMessage;
    }
  }

  const CustomOption = ({innerProps, data}: OptionProps<OptionType, boolean>) => (
    <div {...innerProps} className={'tr-flex tr-my-1 tr-cursor-pointer '}>
      <div className={`tr-ml-2 selection-option-color team-gradient-${data.value}`}></div>
      {data.label}
    </div>
  );

  const CustomControl = ({children, ...rest}: ControlProps<OptionType, boolean>) => {
    const selected = rest.getValue();
    const value = Array.isArray(selected) ? selected[0] : selected;
    return (
      <components.Control {...rest}>
        <div className={`tr-ml-2 tr-my-1 selection-option-color team-gradient-${value?.value}`}></div>
        {children}
      </components.Control>
    );
  };

  if (team?.id && loading) {
    return <Loading />;
  }

  const service = (team?.service ?? 'trustle') as SystemIconsTypesEnum;
  return (
    <Formik
      initialValues={convertTeamToForm(team ?? {})}
      onSubmit={onSubmit}
      initialTouched={{managers: true, members: true}}
      validationSchema={object().shape({
        managers: array()
          .of(string())
          .min(1)
          .required('There needs to be at least 1 valid manager')
          .test('managers', 'User cant be both member and manager of a team', (managers = [], {parent}) => {
            return !managers.some((manager) => _.includes(parent.members, manager));
          }),
        members: array()
          .of(string())
          .min(1)
          .required('There needs to be at least 1 valid member')
          .test('members', 'User cant be both member and manager of a team', (members = [], {parent}) => {
            return !parent.managers.some((manager: string) => _.includes(members, manager));
          }),
        name: string().required('Please select a name for the team'),
        description: string(),
      })}
    >
      {({isSubmitting, isValid}) => {
        const disableTypeSelection = true;
        return (
          <FormikForm>
            <span className="tr-font-bold tr-text-sm">Team created by:</span>
            <div className="tr-grid tr-gap-4 tr-grid-cols-2">
              <div className="tr-flex">
                <TileButton
                  size="lg"
                  className="tr-my-1 tr-mx-1"
                  name={service}
                  data-testid={`manage-${service}`}
                  key={service}
                />
                <div className="tr-flex tr-flex-col tr-justify-center tr-ml-2 tr-font-bold">
                  {service === 'trustle' ? 'Manually created in Trustle' : `Team created by ${service}`}
                </div>
              </div>

              <Field
                component={Select}
                label="Color"
                name="color"
                className="tr-mb-5"
                options={teamColors}
                required
                components={{Option: CustomOption, Control: CustomControl}}
              />
            </div>
            <Field component={TextInput} label="Name" name="name" id="name" disabled={isSubmitting} required />

            <Field
              component={TextAreaInput}
              name="description"
              id="description"
              label={
                <div className="tr-flex tr-justify-between">
                  <div className="tr-font-bold tr-text-xs tr-text-trustle-dark">Description</div>
                  <div className="tr-text-xs text-gray tr-font-normal">This will be shown to end users</div>
                </div>
              }
              rows={3}
              required
              className="tr-pb-4"
              data-testid="description"
            />
            {TeamTypeEnum.ADHOC === team?.type && (
              <>
                <Field
                  component={Select}
                  isDisabled={disableTypeSelection}
                  label="Type"
                  name="type"
                  options={teamTypes}
                  required
                />
                <Field
                  component={Select}
                  name={'managers'}
                  id={'managers'}
                  data-testid="typeahead-select"
                  isMulti={true}
                  className="tr-my-4"
                  label="Team Managers"
                  options={_.map(users, (suggestion: any) => {
                    return {
                      id: suggestion.id,
                      value: suggestion.id,
                      label: formatUserNameAndEmail(suggestion) ?? `${suggestion.email}`,
                    };
                  })}
                />
              </>
            )}

            <div className="tr-flex tr-justify-end tr-items-center">
              <Button variant="secondary" onClick={onClose}>
                Cancel
              </Button>
              <SubmitButton
                name="submit"
                label={`${team?.id ? `Update` : `Create`} Team`}
                inSubmit={isSubmitting}
                disabled={!isValid || isSubmitting}
                data-testId="submitTeam"
              />
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  );
}
