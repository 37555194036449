import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import {useRootStore} from 'src/lib/hooks';
import {Loading} from '@trustle/component-library';
import {Resource} from 'src/stores/domainObjects/Resource';
import _ from 'lodash';
import {Permission} from 'src/stores/domainObjects/Permission';
import RequestAccessModal from 'src/components/modals/RequestAccessModal';
import {Icon, Tab} from '@trustle/component-library';
import './Access.scss';
import ActiveTab from './ActiveTab';
import AvailableTab from './AvailableTab';
import {useAvailableSystems} from './useAvailableSystems';

const Access = observer(() => {
  const activeTab = useParams<{activeTab: string}>().activeTab;
  const history = useHistory();
  const {newResourceStore: resourceStore, currentUser} = useRootStore();
  const {availableSystems, isLoading} = useAvailableSystems();

  const [requestedPermission, setRequestedPermission] = useState<Permission>();
  const [requestModalShowing, setRequestModalShowing] = useState<boolean>(false);

  if (resourceStore.loadingSystems || isLoading) {
    return <Loading />;
  }

  const EmptyMessage = (
    <div className="text-center">
      <img className="tr-w-72" src="/russel-magnifying-glass.svg" alt="" />
      <h2 className="mt-0 text-xl text-center font-weight-bold text-dark">No Systems Found</h2>
      {!_.isEmpty(availableSystems) && <Link to="/access/available">{availableSystems.length} Systems Available</Link>}
    </div>
  );

  const onRequestAccess = (permission?: Permission) => {
    setRequestedPermission(permission);
    setRequestModalShowing(true);
  };

  const getCategorizedSystems = (systems: Resource[]): _.Dictionary<Resource[]> => {
    return _(systems)
      .filter({pendingSetup: false, hasPermissions: true})
      .filter('type')
      .sortBy((s) => s.type + _.toLower(s.name))
      .groupBy('type')
      .value();
  };

  const accessRecords = currentUser.nonPlaceholderAccesses.filter((access) => {
    return access.statusValue > 1 && access.forSystem !== undefined;
  });

  const sids = _.map(accessRecords, (access) => access.rid);

  const getActiveCategorizedSystems = (systems: Resource[]): _.Dictionary<Resource[]> => {
    return _(systems)
      .filter({pendingSetup: false, hasPermissions: true})
      .filter((sys) => {
        return sids.includes(sys.id);
      })
      .filter('type')
      .sortBy((s) => s.type + _.toLower(s.name))
      .groupBy('type')
      .value();
  };

  const availableCategorizedSystems = getCategorizedSystems(availableSystems);

  const activeCategorizedSystems = getActiveCategorizedSystems(availableSystems);

  const tabs = ['active', 'available'];
  // url may be /access/:sid, if thats the case redirect to system overview for that system. If system doesn't exist redirect to /available
  if (!tabs.includes(activeTab)) {
    const sid = resourceStore.resourceMap[activeTab]?.rootResource?.id;
    const type = _.keys(availableCategorizedSystems).find((key) => _.some(availableCategorizedSystems[key], {id: sid}));

    history.replace(_.isUndefined(type) ? '/access/available' : `/access/available/${type}/${sid}`);
  }

  const selectedIndex = Math.max(tabs.indexOf(activeTab), 0);

  return (
    <div className="access">
      <Tab.Group selectedIndex={selectedIndex}>
        <div className="pt-4 text-white tr-bg-trustle-royal">
          <div className="container">
            <div className="tr-flex tr-pb-4">
              <Icon type="access" size="xl" className="tr-opacity-100" />
              <h1 className="mb-0 ml-4">Your Resources</h1>
            </div>
            <Tab.List variant="folder">
              {tabs.map((key) => (
                <Tab
                  id={`${key}tab`}
                  key={key}
                  as={Link}
                  to={`/access/${key}`}
                  className="text-white opacity-100 aria-selected:bg-white aria-selected:text-blue"
                >
                  {_.upperFirst(key)}
                </Tab>
              ))}
            </Tab.List>
          </div>
        </div>
        <Tab.Panels className="container">
          <Tab.Panel key="active">
            <ActiveTab
              categorizedSystems={activeCategorizedSystems}
              onRequestAccess={onRequestAccess}
              emptyMessage={EmptyMessage}
            />
          </Tab.Panel>
          <Tab.Panel key="available">
            <AvailableTab categorizedSystems={availableCategorizedSystems} onRequestAccess={onRequestAccess} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
      <RequestAccessModal
        permission={requestedPermission}
        show={requestModalShowing}
        onHide={() => setRequestModalShowing(false)}
      />
    </div>
  );
});

export default Access;