import React, {useContext} from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {ConnectorsDataT, PermissionMetadataT} from '../../../types';
import {Tab, Nav, Accordion, Card, useAccordionToggle, AccordionContext, ListGroup, Badge} from 'react-bootstrap';
import Highlight from 'src/components/highlightjs/Highlight';
import FontAwesomeIcon from 'src/components/FontAwesomeIcon';
import {faChevronUp, faChevronDown} from 'src/components/FontAwesomeIcon';

type PropsT = {
  connectorsData: any;
};

type ContextPropsT = {
  children?: any;
  eventKey: string;
  callback?: (e: string) => void;
};

const PermissionMetadataViewer = observer(function PermissionMetadataViewer(props: PropsT) {
  const {connectorsData} = props;

  const metadataByType = _.groupBy(connectorsData, (d) => d.data.metadataType);

  const showPermissionAssociatedData = (): React.ReactElement => {
    const listPolicies = (listPolicies: ConnectorsDataT[]) => {
      if (_.isEmpty(listPolicies)) {
        return <span>There are no items to show</span>;
      }
      return (
        <>
          <span>
            <small>Select an item from the list:</small>
          </span>
          <Tab.Container id="left-tabs-example" defaultActiveKey={listPolicies[0].id}>
            <div className="tr-flex ">
              <div className="tr-flex tr-w-5/12">
                <ListGroup>
                  {listPolicies.map((p: ConnectorsDataT) => {
                    const key = (p.data as PermissionMetadataT).id;
                    return (
                      <ListGroup.Item key={key} eventKey={key}>
                        {p.title}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </div>

              <div className="tr-flex tr-w-7/12">
                <Tab.Content>
                  <Nav.Item>
                    {listPolicies.map((p: ConnectorsDataT) => {
                      const meta = p.data as PermissionMetadataT;

                      return (
                        <Tab.Pane key={meta.id} eventKey={meta.id} unmountOnExit={true}>
                          {p && p.data && (
                            <p className="my-3">
                              {'Foreign ID: '}
                              <Badge variant="secondary">{meta.id}</Badge>
                              <span className="blockquote-footer">{meta.details.description}</span>
                            </p>
                          )}
                          {meta && !_.isEmpty(meta.details.scopes) && (
                            <div>
                              {'Scopes: '}
                              <Highlight language="json">{JSON.stringify(meta.details.scopes, null, '  ')}</Highlight>
                            </div>
                          )}
                          {meta && !_.isEmpty(meta.details.jsonData) && (
                            <div>
                              {'Permissions: '}
                              <Highlight language="json">{JSON.stringify(meta.details.jsonData, null, '  ')}</Highlight>
                            </div>
                          )}
                        </Tab.Pane>
                      );
                    })}
                  </Nav.Item>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </>
      );
    };

    function ContextAwareToggle({children, eventKey, callback}: ContextPropsT) {
      const currentEventKey = useContext(AccordionContext);
      const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
      const isCurrentEventKey = currentEventKey === eventKey;
      return (
        <div onClick={decoratedOnClick}>
          <div>
            {children}
            <div className="float-right">
              <FontAwesomeIcon icon={isCurrentEventKey ? faChevronUp : faChevronDown} />
            </div>
          </div>
        </div>
      );
    }

    return (
      <Accordion className="mt-4" defaultActiveKey={_.first(_.keys(metadataByType))}>
        {_.map(_.keys(metadataByType), (key) => {
          const metaList = metadataByType[key];

          return (
            <Card key={key}>
              <Card.Header>
                <ContextAwareToggle eventKey={key}>
                  <span className="tr-mr-3">
                    <strong>
                      {_.startCase(key)} ({_.size(metaList)})
                    </strong>
                  </span>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey={key}>
                <Card.Body>{listPolicies(metaList)}</Card.Body>
              </Accordion.Collapse>
            </Card>
          );
        })}
      </Accordion>
    );
  };

  if (_.size(metadataByType) > 0) {
    return showPermissionAssociatedData();
  }

  return null;
});

export default PermissionMetadataViewer;
