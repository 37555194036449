import React, {useState} from 'react';
import {TeamsFilterProvider} from './TeamsFiltersContext';
import {TeamsListedProvider} from './TeamsListedContext';
import {TeamsTable} from './TeamsTable';
import {Icon, Button, Modal, PrefixInput} from '@trustle/component-library';
import TeamForm from './TeamForm';
import {useRootStore} from 'src/lib/hooks';

export function AdminTeamsPanel() {
  return (
    <TeamsFilterProvider>
      <TeamsListedProvider>
        <TeamsPanelInternal />
      </TeamsListedProvider>
    </TeamsFilterProvider>
  );
}

function TeamsPanelInternal() {
  const createTeamLabel = `Create Team`;
  const {usersStore} = useRootStore();
  const [creatingTeam, setCreatingTeam] = useState<boolean>();
  return (
    <main className="tr-mb-8">
      <section id="filter-and-authority">
        <div className="tr-bg-trustle-royal tr-flex tr-text-white tr-pt-4 tr-h-32">
          <div className="container tr-flex align-items-center">
            <div className="tr-flex">
              <Icon type="team" size="xl" className="tr-opacity-100" />
              <div className="tr-flex tr-mb-0 tr-ml-4 tr-flex-col">
                <h1 className="trustle-header-text">Teams</h1>
                <p className="body6 tr-max-w-[450px]">Team management page</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container tr-flex tr-flex-col tr-gap-4">
          <div className="tr-flex tr-items-center tr-gap-3 tr-bg-[#F8F9FC] tr-py-3 tr-px-2.5">
            <Icon type={'team'} size="md" />
            <h2 className="tr-mb-0">{'Manage Teams'}</h2>

            <PrefixInput
              prefixIcon="search"
              placeholder="Search for teams"
              className="tr-min-w-[300px] tr-max-w-[600px]"
            />

            <Button
              key="createTeam"
              data-testid="createTeamButton"
              id="createTeamButton"
              onClick={() => {
                setCreatingTeam(true);
              }}
            >
              {createTeamLabel}
            </Button>
          </div>

          {creatingTeam && (
            <Modal
              width="lg"
              onClose={() => {
                setCreatingTeam(false);
              }}
              title={createTeamLabel}
            >
              <TeamForm
                users={usersStore.users}
                onClose={() => {
                  setCreatingTeam(false);
                }}
                onAfterSubmit={() => {
                  setCreatingTeam(false);
                }}
              />
            </Modal>
          )}
          <TeamsTable />
        </div>
      </section>
    </main>
  );
}
