import React from 'react';
import {TabsDocsT} from '../DocSection';
import DocSection from '../DocSection';
import {ConnectionServiceE} from 'src/types';
import {useRootStore} from 'src/lib/hooks';
import _ from 'lodash';
import {observer} from 'mobx-react';

function ConfigureOkta() {
  const {org} = useRootStore();

  if (_.isNil(org)) {
    return <></>;
  }

  function overview(): React.ReactNode {
    return (
      <div className="tr-w-75">
        <p>
          <div>
            The following guide describes the required steps to configure a connection between Trustle and your
            organization in Okta.
          </div>
          <div>
            This connector will allow Trustle to retrieve the data in your Directory in Okta, as well as the information
            related to Administrator Roles and Applications.
          </div>
        </p>
        <p>
          Through the connector, you will be able to easily manage the group memberships and know all the details of the
          roles and applications associated to each group and user.
        </p>
        <p>
          If you have any questions, please contact us at <a href="mailto:support@trustle.com">support@trustle.com</a>.
        </p>
      </div>
    );
  }

  function domain(): React.ReactNode {
    return (
      <div className="tr-w-75">
        <p>
          <div className="mt-2 ml-4">
            <li>Resource sets are collections of resources that your admin's roles are constrained to.</li>
            <li>
              Roles are the permissions that your admin has - can be a standard Okta role or your own custom role.
            </li>
            <li>Admins can be individual users or groups that get this role.</li>
          </div>
        </p>
      </div>
    );
  }

  function supportedFeatures(): React.ReactNode {
    return (
      <div className="tr-w-75">
        <p>
          Trustle will be able to retrieve the following information from the organization in Okta:
          <div className="mt-2 ml-4">
            <li>People and Groups in the Okta Directory</li>
            <li>Administrator Roles</li>
            <li>Resource Sets</li>
            <li>App Integrations</li>
            <li>Application event logs</li>
          </div>
        </p>
      </div>
    );
  }

  const steps = observer(() => {
    return (
      <div className="tr-w-75">
        <p>
          <strong>Configurations in Okta</strong>
        </p>
        <p>
          <div>The following settings are required to be set prior to set up a connection in Trustle.</div>
          <div> In order to configure them, the user needs to have access to Okta's Admin Dashboard.</div>
          <div className="mt-2 ml-4">
            <li>
              <>
                Create an OIDC - OpenID Connect App Integration: this will allow Trustle to authenticate to your
                organization in Okta and perform queries
              </>
              <div>
                Go to: <code className="text-info">Applications: Create App Integration</code> and set the following
                parameters:
                <div>
                  <ul>
                    <li>
                      Sign-in Method: <strong>OIDC - OpenID Connect</strong>
                    </li>
                    <li>
                      Application Type: <strong>Web Application</strong>
                    </li>
                    <li>
                      Name: <strong>Okta-Trustle connection</strong>
                    </li>
                    <li>
                      Grant Type: <strong>Authorization code, Refresh token</strong>
                    </li>
                    <li>
                      {'Redirect URI: '}
                      <strong>{`${org.getOrgRedirectUrl(`/api/connect/${ConnectionServiceE.OKTA}/oauth`)}`}</strong>
                    </li>
                    <li>
                      Okta API scopes -you will need to select permissions to allow Trustle to access data related to:
                      <ul>
                        <li aria-level={2}>
                          {'Users: '}
                          <code className="text-info">
                            okta.<strong>users</strong>.read.self, okta.<strong>users</strong>.read/manage
                          </code>
                        </li>
                        <li>
                          {'Groups: '}
                          <code className="text-info">
                            okta.<strong>groups</strong>.read/manage
                          </code>
                        </li>
                        <li>
                          {'Apps: '}
                          <code className="text-info">
                            okta.<strong>apps</strong>.read/manage
                          </code>
                        </li>
                        <li>
                          {'Roles: '}
                          <code className="text-info">
                            okta.<strong>roles</strong>.read/manage
                          </code>
                        </li>
                        <li>
                          {'Logs: '}
                          <code className="text-info">
                            okta.<strong>logs</strong>.read
                          </code>
                        </li>
                      </ul>
                    </li>
                    <li>
                      Assignments: Assign the same user you are currently logged in as [who is creating this Trustle
                      Connector Application]. This is the same user you will need to login as for oAuth authentication
                      step at the end of the Okta Integration setup.
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </div>
        </p>
        <p className="mt-5">
          <strong>Configurations in Trustle</strong>
        </p>
        <p>
          <div>Once the previous steps are done, only two values will be required by Trustle:</div>
          <div className="mt-2 ml-4">
            <li>
              <>
                Organization URL: it should have the following format:
                <div>
                  <code className="text-info">{`https://{YOUR_ORG_ID}.okta.com/`}</code>
                  {' or '}
                  <code className="text-info">{`https://{YOUR_ORG_ID}.oktapreview.com/`}</code>.
                </div>
              </>
            </li>
            <li>
              <>
                Client ID and Client Secret: You will find this information in the "General" tab for the App Integration
                created on Okta during the previous step.
              </>
            </li>
          </div>
        </p>
      </div>
    );
  });

  const tabs: TabsDocsT = [
    {id: 'overview', label: 'Overview', description: overview(), active: true},
    {id: 'domain', label: 'About the Okta domain', description: domain(), active: false},
    {id: 'supported', label: 'Supported Features', description: supportedFeatures(), active: false},
    {id: 'steps', label: 'Configuration Steps', description: steps(), active: false},
  ];
  return (
    <>
      <DocSection tabs={tabs} title="Guide: How to setup an Okta connector" />
    </>
  );
}

export default ConfigureOkta;
