import React, {useState} from 'react';
import {Button} from '@trustle/component-library';
import {observer} from 'mobx-react-lite';
import {AddUserModal} from '../../views/admin/add-users-dropdown/AddUserModal';

const AddUserButton = observer(() => {
  const [show, setShow] = useState(false);

  return (
    <>
      <Button variant="primary" onClick={() => setShow(true)}>
        Add New User
      </Button>
      <AddUserModal show={show} setShow={setShow} />
    </>
  );
});

export default AddUserButton;
