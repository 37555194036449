import React, {useState} from 'react';
import {PermissionModalAction} from 'src/types';
import ConfirmDeletePermissionModal from 'src/views/Permissions/Modals/ConfirmDeletePermissionModal';
import PermissionEdit from 'src/views/Permissions/PermissionEdit';
import {formatFullDate} from 'src/lib';
import _ from 'lodash';
import {Permission} from 'src/stores/domainObjects/Permission';
import PermissionDetailsModal from './PermissionDetailsModal';
import {IconButton, Modal} from '@trustle/component-library';
import RequestAccessModal from 'src/components/modals/RequestAccessModal';

type PropsT = {
  permission: Permission;
  accessMode?: boolean;
};

function PermissionDropdown(props: PropsT) {
  const {permission, accessMode} = props;

  const readOnly = !permission.rootResource?.userIsOwner;
  const [modalSelection, setModalSelection] = useState<{
    action?: PermissionModalAction;
    pid?: string;
    permission?: Permission;
  }>({});

  const isConnectedSystem = !_.isEmpty(permission.rootResource.connector);

  const modalElem = (() => {
    if (!modalSelection.action) {
      return null;
    }

    switch (modalSelection.action) {
      case PermissionModalAction.VIEW_CONNECTOR_DATA:
      case PermissionModalAction.VIEW_DETAILS:
        return (
          <PermissionDetailsModal
            key={`${permission.id} ${modalSelection.action}`}
            permission={permission}
            closeModal={() => {
              setModalSelection({});
            }}
          />
        );
      case PermissionModalAction.DELETE_PERMISSION: {
        return (
          <ConfirmDeletePermissionModal
            permission={modalSelection.permission!}
            closeModal={() => {
              setModalSelection({});
            }}
          />
        );
      }
      case PermissionModalAction.EDIT_PERMISSION: {
        return (
          <Modal
            onClose={() => {
              setModalSelection({});
            }}
            width={'lg'}
            title={`Edit ${permission.label}`}
          >
            <div className="tr-flex tr-pt-2 tr-pb-2 tr-m-1 permission-dates-wrapper description-row">
              <div className="tr-flex tr-w-2/12 tr-p-0 body6 tr-font-bold tr-text-center">Date Added:</div>
              <div className="tr-flex tr-w-1/3 tr-p-0 body6">
                {permission.created && formatFullDate(permission.created)}
              </div>
              <div className="tr-flex tr-w-2/12 tr-p-0 body6 tr-font-bold tr-text-center">Edited:</div>
              <div className="tr-flex tr-w-1/3 tr-p-0 body6">
                <>{formatFullDate(permission.updated)}</>
              </div>
            </div>
            <PermissionEdit
              permission={permission}
              resource={permission.parentResource}
              isConnectedSystem={isConnectedSystem}
              cancel={() => {
                setModalSelection({});
              }}
              refresh={() => {
                setModalSelection({});
              }}
            />
          </Modal>
        );
      }
      case PermissionModalAction.GRANT_PERMISSION: {
        return (
          <RequestAccessModal
            onHide={() => setModalSelection({})}
            permission={permission}
            isManaging={!readOnly}
            show={true}
          />
        );
      }
      default:
        return null;
    }
  })();

  const grantAccess = (
    <IconButton
      data-testid={`${permission.label}-request-access`}
      key={`permission-action-status${permission.id}`}
      title={accessMode ? 'Request Access' : 'Grant Permission'}
      variant="ternary"
      onClick={(e: any) => {
        e.stopPropagation();
        setModalSelection({action: PermissionModalAction.GRANT_PERMISSION, permission});
      }}
      icon="grantHover"
    />
  );

  function getPermissionActions() {
    if (accessMode) {
      return [grantAccess];
    }

    const items = [
      <IconButton
        icon="details"
        variant="ternary"
        data-testid={`details-icon-permission-${permission.label}`}
        title="Details"
        key={`permission-action-detail${permission.id}`}
        onClick={(e: any) => {
          e.stopPropagation();
          setModalSelection({action: PermissionModalAction.VIEW_DETAILS, permission});
        }}
      />,
    ];

    if (!readOnly) {
      items.push(
        <IconButton
          icon="edit"
          variant="ternary"
          title="Edit"
          key={`permission-action-edit${permission.id}`}
          data-testid={`edit-icon-permission-${permission.label}`}
          onClick={(e: any) => {
            e.stopPropagation();
            setModalSelection({action: PermissionModalAction.EDIT_PERMISSION, permission});
          }}
        />
      );
    }

    if (permission.isGrantable) {
      items.push(grantAccess);
    }

    if (!isConnectedSystem && !readOnly) {
      items.push(
        <IconButton
          icon="deleted"
          variant="ternary"
          data-testid="delete-icon"
          title="Delete"
          key={`permission-action-remove${permission.id}`}
          onClick={(e: any) => {
            e.stopPropagation();
            setModalSelection({action: PermissionModalAction.DELETE_PERMISSION, permission});
          }}
        />
      );
    }
    return <div className="tr-w-full tr-flex tr-items-start justify-content-start perm-actions-wrapper">{items}</div>;
  }

  return (
    <>
      {getPermissionActions()}
      {modalElem}
    </>
  );
}

export default PermissionDropdown;
