import {useState} from 'react';
import axios from 'axios';
import {LinkStatus, UserType} from 'src/views/v2/admin/admin-users-panel/types';

type ListAccountsResponse = {
  principals: any[]; // TODO: define type
  total: number;
  page: number;
  size: number;
  pages: number;
};

type PaginationParams = {
  page?: number;
  size?: number;
};

export type ListAccountParams = {
  userType?: UserType;
  linkStatus?: LinkStatus;
  systemId?: string;
} & PaginationParams;

export function useAccounts() {
  const [data, setData] = useState<ListAccountsResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BASE_URL = '/api/v2/principals';

  const fetchAccounts = async (params: ListAccountParams) => {
    setLoading(true);
    try {
      const {page, size, ...rest} = params;
      const parsedParams: Record<string, string> = {
        page: (page || 1).toString(),
        size: (size || 10).toString(),
      };

      Object.keys(rest).forEach((key) => {
        const typedKey = key as keyof typeof rest;
        const value = rest[typedKey] as string | number;
        if (rest[typedKey] !== undefined) {
          parsedParams[typedKey] = value.toString();
        }
      });

      const queryString = new URLSearchParams(parsedParams).toString();
      const response = await axios.get<ListAccountsResponse>(`${BASE_URL}?${queryString}`);
      const responseData = response.data;
      setData(responseData);
    } catch (err: any) {
      // TODO: Implement handling error
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {data, loading, error, fetchAccounts};
}
