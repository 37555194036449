import React, {useState} from 'react';
import {observer} from 'mobx-react';
import './AwsPolicyRisk.scss';
import {useRootStore} from 'src/lib/hooks';
import {Loading} from '@trustle/component-library';
import {Card} from 'src/components/design';
import RiskBadge from 'src/views/Resource/Account/RiskBadge';
import NoData from 'src/components/NoData';
import PolicyRiskTable from './PolicyRiskTable';
import _ from 'lodash';
import {Permission} from 'src/stores/domainObjects/Permission';
import {RiskScorePermissionsT} from 'src/types';
import {Accordion, Button} from '@trustle/component-library';
import FontAwesomeIcon, {faChevronDown, faChevronUp, faChevronRight} from 'src/components/FontAwesomeIcon';
type PropsT = {
  riskScoresPermissions: RiskScorePermissionsT[];
  header?: JSX.Element;
};

const AwsPolicyRiskPerPermission = observer(function AwsPolicyRiskPerPermission(props: PropsT) {
  const {riskScoresPermissions, header = <></>} = props;
  const [expandAll, setExpandAll] = useState<boolean>(false);
  const {permissionStore} = useRootStore();
  const riskReports: any[] = [];
  const permissions: Permission[] = [];
  for (const riskScorePermission of riskScoresPermissions) {
    permissions.push(permissionStore.permissionMap[riskScorePermission.pid]);
    if (permissionStore.permissionMap[riskScorePermission.pid]?.riskReport === undefined) {
      riskReports.push(permissionStore.permissionMap[riskScorePermission.pid]?.riskReport);
      return <Loading />;
    }
  }

  if (_.some(riskReports, (e) => e.riskReports?.isSystemUnavailable)) {
    return <NoData>Hmm, we couldn’t seem to find any risk report data yet.</NoData>;
  }

  const showExpand = (expanded: boolean) => {
    if (expanded) {
      return (
        <div className="tr-grid tr-justify-items-end">
          <Button
            variant="ternary"
            size="sm"
            onClick={() => {
              setExpandAll(!expandAll);
            }}
            className="tr-m-0 tr-top-0"
          >
            Collapse All
            <FontAwesomeIcon className="menu-arrow tr-ml-2" icon={faChevronUp} />
          </Button>
        </div>
      );
    }
    return (
      <div className="tr-grid tr-justify-items-end">
        <Button
          variant="ternary"
          size="sm"
          onClick={() => {
            setExpandAll(!expandAll);
          }}
          className="tr-m-0 tr-top-0"
        >
          Expand All
          <FontAwesomeIcon className="menu-arrow tr-ml-2" icon={faChevronDown} />
        </Button>
      </div>
    );
  };
  return (
    <Card>
      <Card.Header>{header}</Card.Header>
      <Card.Body>
        <Card.Title className="body4 tr-mb-0">
          <span className="text text-muted body6 mt-0 pt-0">
            This is a real time score and could differ from the score on the resource table. The resource table score is
            generated every 2 hours.
            {showExpand(expandAll)}
          </span>
        </Card.Title>
        <div className="poly-risk-permission">
          {_.map(permissions, (permission) => {
            return (
              <>
                <Accordion
                  variant="secondary"
                  className="tr-mt-0 tr-pt-0"
                  size="sm"
                  key={permission.id}
                  defaultState={expandAll}
                  content={
                    <div className="tr-flex tr-justify-between">
                      <div>
                        <span className="body5">
                          <FontAwesomeIcon className="menu-arrow tr-ml-2 tr-mr-2" icon={faChevronRight} />
                          {permission.label}
                        </span>
                      </div>
                      <div className="tr-mr-2">
                        <RiskBadge value={permission.riskReport?.riskScore} />
                      </div>
                    </div>
                  }
                >
                  {() => {
                    return <PolicyRiskTable riskReport={permission.riskReport} />;
                  }}
                </Accordion>
              </>
            );
          })}
        </div>
      </Card.Body>
    </Card>
  );
});

export default AwsPolicyRiskPerPermission;
