import {Confirmation, Modal} from '@trustle/component-library';
import MergeManager from '../../MergeManager';
import React, {useState} from 'react';
import {useRootStore} from '../../../lib/hooks';
import {useHistory} from 'react-router-dom';
import {FacetIdEnum} from '../../../types';

type Props = {
  show: boolean;
  setShow: (show: boolean) => void;
};
export function UserCsvImportModal({show, setShow}: Props) {
  const {usersStore, org} = useRootStore();
  const [modalTitle, setModalTitle] = useState<string>('Import Users');

  const history = useHistory();

  const isFreePlan = org.facetId === FacetIdEnum.FREE_AWS;

  const refresh = () => {
    void usersStore.refresh();
    setShow(false);

    if (usersStore.error) {
      usersStore.rootStore.toast.add('Failed to load users.', {appearance: 'error', autoDismiss: true});
    }
  };

  return (
    <>
      {show && (
        <>
          {isFreePlan && org.billableUsersCount >= 5 ? (
            <Confirmation
              onConfirm={() => {
                history.push('/admin/billing/plan');
              }}
              onClose={() => {
                setShow(false);
              }}
              title="Upgrade to add another user"
              confirmButtonLabel="Upgrade"
            >
              {'To add another user, please upgrade to Teams version. You will get a free 14-day trial'}
            </Confirmation>
          ) : (
            <Modal width={'xl'} onClose={refresh} visible={true} title={modalTitle}>
              <a
                href={'/docs/admin/users/csv_guide'}
                rel="noopener noreferrer"
                target="_blank"
                className="text-underline"
              >
                <p style={{fontSize: 'small'}}>Documentation</p>
              </a>
              <MergeManager setModalTitle={setModalTitle} onClose={refresh} />
            </Modal>
          )}
        </>
      )}
    </>
  );
}
