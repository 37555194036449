import _ from 'lodash';
import {observer} from 'mobx-react';
import React from 'react';
import {useRootStore} from 'src/lib/hooks';
import {Permission} from 'src/stores/domainObjects/Permission';

export const Breadcrumb = observer(
  (props: {showLink?: boolean; permission: Permission; separator?: string; showIcon?: boolean}): React.ReactElement => {
    const {newResourceStore: resourceStore, permissionStore} = useRootStore();
    const {showLink = false, permission, separator = ' > ', showIcon = false} = props;

    if (!permission) {
      return <></>;
    }
    const perm = permissionStore.permissionMap[permission.id];
    if (_.isEmpty(perm)) {
      return <></>;
    }
    const ancestorsRidOfPermission = perm.ancestorIds;
    const breadcrumb = [];
    for (const rid of ancestorsRidOfPermission) {
      const formatted = _.pick(resourceStore.resourceMap[rid], ['id', 'name']);
      if (!_.isEmpty(formatted)) {
        breadcrumb.push(formatted);
      }
    }
    const filteredBreadcrumb = _.compact(breadcrumb);
    if (_.isEmpty(filteredBreadcrumb)) {
      return <></>;
    }
    if (!showLink) {
      return <>{_.join(_.compact(_.map(filteredBreadcrumb, 'name')).concat([perm.label]), separator)}</>;
    }

    const SystemLink = (systemId: string, systemName: string) => {
      if (!systemId) {
        return systemName;
      }
      return (
        <a target="_blank" rel="noreferrer" href={`/resource/manage/${systemId}/resources`}>
          {systemName}
        </a>
      );
    };
    const SystemIcon = (systemId: string) => {
      if (!showIcon) {
        return <></>;
      }
      if (!systemId) {
        return <></>;
      }
      const icon = resourceStore.resourceMap[systemId]?.icon;
      if (!icon) {
        return <></>;
      }
      return <img src={icon} height="20px" className="tr-mw-25 tr-mr-5" />;
    };
    const ResourceLink = (systemId: string, resourceName: string) => {
      if (!systemId) {
        return resourceName;
      }
      return (
        <a target="_blank" rel="noreferrer" href={`/resource/manage/${systemId}/resources?Resource=${resourceName}`}>
          {resourceName}
        </a>
      );
    };
    const PermissionLink = (systemId: string, permissionName: string) => {
      if (!systemId) {
        return permissionName;
      }
      return (
        <a
          target="_blank"
          rel="noreferrer"
          href={`/resource/manage/${systemId}/resources?Permission=${permissionName}`}
        >
          {permissionName}
        </a>
      );
    };
    const Separator = (separator: string) => {
      return <>{separator}</>;
    };
    const breadcrumbs = _.compact(filteredBreadcrumb);
    if (_.size(breadcrumbs) === 0) {
      return <></>;
    }

    return (
      <>
        {_.map(breadcrumbs, (bc, index) => {
          if (index === 0) {
            return (
              <>
                {SystemIcon(bc.id)}
                {SystemLink(bc.id, bc.name)}
                {Separator(separator)}
              </>
            );
          }
          return (
            <>
              {ResourceLink(bc.id, bc.name)}
              {Separator(separator)}
            </>
          );
        })}
        {PermissionLink(filteredBreadcrumb[0]?.id, perm.label)}
      </>
    );
  }
);
export default Breadcrumb;
