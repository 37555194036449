import React from 'react';

type PropsT = {
  children: React.ReactNode;
};
function OnboardingWrapper(props: PropsT) {
  return (
    <div className="tr-flex tr-flex-col md:tr-flex-row  login tr-h-full p-0 m-0">
      <div className="tr-w-full tr-h-1/2 md:tr-h-full md:tr-w-1/2 team-gradient-blue d-inline-block tr-flex align-items-center justify-content-end">
        <img
          className="tr-h-full left-0 absolute mix-blend-screen"
          src="/signin/signin_cloud_datacenter_background.png"
        />
        <div className="trial-signup tr-flex tr-h-full tr-flex-col tr-items-end tr-justify-center text-right pr-5 mr-3">
          <img src="/logo/trustle-white.svg" width="400px" />
          <div className="left-subtitle body2 text-white">access management simplified</div>
        </div>
      </div>
      <div className="tr-w-full md:tr-w-1/2 tr-items-center tr-pl-5 login-form">
        <div className="login-form tr-flex tr-h-full">
          <div className="row my-auto">
            <div className="card-body">{props.children}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OnboardingWrapper;
