import {Button, Loading} from '@trustle/component-library';
import _ from 'lodash';
import React from 'react';

type SubmitButtonPropsT = {label?: string; inSubmit?: boolean; disabled?: boolean} & Omit<
  React.ComponentProps<'button'>,
  'type' | 'ref'
>;

export default function SubmitButton(props: SubmitButtonPropsT) {
  const buttonProps = _.omit(props, ['label', 'inSubmit']);
  return (
    <Button {...buttonProps} type="submit" disabled={props.inSubmit || props.disabled}>
      {props.inSubmit && <Loading />}
      {props.label}
    </Button>
  );
}
