import {Button, Modal, TextInput} from '@trustle/component-library';
import React from 'react';
import {SubmitButton} from 'src/components/design';
import {authDisplayName, AuthenticationPolicyT, SamlConfigurationT} from './authenticationTypes';
import {SAMLSetupForm} from './SAMLSetupForm';
import {object, string} from 'yup';
import {Field, Form, Formik} from 'formik';

export const EditModal: React.ElementType = (props: {
  policy: AuthenticationPolicyT;
  config?: SamlConfigurationT;
  onSave: (policy: any, config?: SamlConfigurationT) => void;
  onClose: () => void;
}) => {
  const {policy, onClose} = props;

  return (
    <Modal
      title={`${policy.id ? 'Edit' : 'Add'} ${authDisplayName(policy)}`}
      width={'md'}
      onClose={onClose}
      visible={true}
    >
      {policy.name !== 'saml' && (
        <Formik
          initialValues={{domain: policy.targets[0].domain || ''}}
          validationSchema={object().shape({
            domain: string().required('Please fill out this field'),
          })}
          onSubmit={(values) => {
            const newTarget = {...policy.targets[0], domain: values.domain};
            const updatedPolicy = {...policy, targets: [newTarget]};
            props.onSave(updatedPolicy);
          }}
          enableReinitialize
        >
          {() => (
            <Form className="text-dark-gray">
              <div className="tr-flex tr-flew-wrap tr-mx-auto">
                <Field component={TextInput} label="Domain" name="domain" placeholder={'*'} />
              </div>
              <div className="tr-flex tr-justify-between mt-5">
                <Button variant="secondary" onClick={props.onClose}>
                  Cancel
                </Button>
                <SubmitButton label="Save" />
              </div>
            </Form>
          )}
        </Formik>
      )}
      {policy.name === 'saml' && <SAMLSetupForm {...props} />}
    </Modal>
  );
};
