import React from 'react';
import {observer} from 'mobx-react-lite';
import {useRootStore} from 'src/lib/hooks';
import {formatUnixDaysFromNow} from 'src/lib';
import moment from 'moment';
import {Link} from 'react-router-dom';

import {FacetIdEnum} from 'src/types';
import _ from 'lodash';
import {Icon, Tooltip} from '@trustle/component-library';

const PackageBadge = observer(() => {
  const {org, currentUser} = useRootStore();

  if (
    _.isNil(org) ||
    _.isNil(currentUser) ||
    org.subscription === undefined ||
    org.package === undefined ||
    org.products === undefined
  ) {
    return <></>;
  }

  const isFreePlan = org.facetId === FacetIdEnum.FREE_AWS;
  const plan = org.subscriptionPlan ?? org.package;
  const trialEnd = org.subscription?.trial_end;

  return (
    <div className="ml-4 tr-font-bold tr-text-white tr-flex">
      {isFreePlan && currentUser.isOrgOwner ? (
        <>
          Free<span className="tr-hidden sm:tr-inline">&nbsp;Version</span>
          <Link className="ml-4 tr-text-white tr-underline tr-hidden md:tr-block" to="/admin/billing/plan">
            Upgrade
          </Link>
        </>
      ) : (
        <Link className="tr-flex tr-items-center hover:tr-no-underline" to="/admin/billing/plan">
          <span className="tr-ml-1 tr-text-white">{plan?.name ?? ''}</span>
          {trialEnd && trialEnd > moment().unix() && (
            <>
              <div className="tr-ml-3">
                <div className="tr-bg-white tr-rounded-xl tr-text-black tr-whitespace-nowrap tr-text-sm md:tr-block tr-px-3">
                  <Tooltip
                    position="bottom"
                    content={
                      <div className="tr-text-start">
                        <b>Time remaining on your trial before billing starts.</b>
                        <div>If you wish to update your billing information, please go to the Billing page.</div>
                      </div>
                    }
                  >
                    <div className="tr-flex tr-items-center">
                      <Icon type="time" size="sm" />
                      <span className="tr-ml-1">{`${formatUnixDaysFromNow(trialEnd)} Days Remaining`}</span>
                    </div>
                  </Tooltip>
                </div>
              </div>
            </>
          )}
        </Link>
      )}
    </div>
  );
});

export default PackageBadge;
