import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {Select, SensitivityIcon} from '@trustle/component-library';
import {Field, Form, Formik} from 'formik';
import {Permission} from 'src/stores/domainObjects/Permission';
import _ from 'lodash';
import {Resource} from 'src/stores/domainObjects/Resource';
import RequestAccessModal from 'src/components/modals/RequestAccessModal';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';

type PropsT = {
  resource: Resource;
  access?: AccessRecord;
};

const PermissionInLinkedResource = observer(({resource, access}: PropsT) => {
  const [changingPermission, setChangingPermission] = useState<Permission>();
  return (
    <>
      <Formik<{pid: string}>
        key={resource.id}
        initialValues={{
          pid: access?.pid ?? '',
        }}
        onSubmit={() => {}}
      >
        {() => {
          return (
            <Form>
              <Field
                component={Select}
                name="pid"
                key={`${resource.id}select`}
                className="tr-w-32"
                value={_.find(resource.permissions, {id: access?.pid ?? ''})}
                options={resource.permissions}
                formatOptionLabel={(permission: Permission) => {
                  const accessInPerm = _.first(permission.accessActiveInPermission);
                  return (
                    <div key={accessInPerm?.id ?? 'without'} className="tr-flex tr-flex-row tr-items-center">
                      <div className="tr-flex tr-flex-row tr-flex-1">
                        {permission.sensitivity && <SensitivityIcon size="sm" level={permission.sensitivity.level} />}
                      </div>
                      <div className="tr-align-left tr-flex-2">{permission.label}</div>
                    </div>
                  );
                }}
                onChange={(permission: Permission) => {
                  setChangingPermission(permission);
                }}
                isSearchable={false}
                isDisabled={true}
              />
            </Form>
          );
        }}
      </Formik>
      {changingPermission && (
        <RequestAccessModal
          permission={changingPermission}
          show={!!changingPermission}
          onHide={() => setChangingPermission(undefined)}
        />
      )}
    </>
  );
});

export default PermissionInLinkedResource;
