import React from 'react';
import StepTabs from './StepTabs';

type HeaderProps = {
  currentPage: number;
  changePage: (pageNumber: number) => void;
  pages: Array<number>;
};

const Header = ({currentPage, changePage, pages}: HeaderProps) => {
  const currentTitle = ['Create New Trustle Organization', 'Check Your Email'];
  return (
    <div className="tr-border-solid tr-border-gray-300 tr-border-0 tr-border-b-2 tr-flex tr-items-center tr-justify-between">
      <h2 className="tr-text-2xl">{currentTitle[currentPage - 1]}</h2>
      <StepTabs activePage={currentPage} changePage={changePage} pages={pages} />
    </div>
  );
};
export default Header;
