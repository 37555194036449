import React from 'react';
import {formatUserName} from 'src/lib';
import _ from 'lodash';

import {Icon, UserAvatar, Tooltip, UserAvatarAndName} from '@trustle/component-library';
import {User} from 'src/stores/domainObjects/User';

export function getListOfMails(users: User[], list: string[], title?: string) {
  const usersListed = _.size(list);
  let avatar = <></>;
  const listCmp = (
    <>
      <div>{title}</div>
      {list.map((item) => {
        const user = _.find(users, {email: item});

        if (!user) {
          return null;
        }

        if (usersListed === 1) {
          avatar = <UserAvatar key={user?.id} text={formatUserName(user)} size="xs" title={formatUserName(user)} />;
        }

        return (
          <div className="m-2" key={item}>
            <UserAvatarAndName displayName={formatUserName(user)} />
          </div>
        );
      })}
    </>
  );
  return (
    <Tooltip content={listCmp} position="bottom">
      <div className={'tr-flex tr-justify-start tr-items-start tr-cursor-pointer'}>
        {usersListed > 1 ? (
          <span className="body6 tr-flex tr-items-end">
            <Icon type="members" /> ({_.size(list)})
          </span>
        ) : (
          avatar
        )}
      </div>
    </Tooltip>
  );
}
