import React from 'react';
import {Confirmation, UserAvatarAndName} from '@trustle/component-library';
import {Task} from 'src/stores/domainObjects/Task';
import {formatUserName} from 'src/lib';
import {SizesEnum} from '@trustle/component-library/dist/types';

type DeprovisionTaskDisplayPropsT = {
  title: string;
  text: JSX.Element;
  comment: string;
  actionFn: () => void;
  setVisibility: (value: boolean) => void;
  accessRequest: Task;
  size?: SizesEnum;
};

function TakeOverTaskModal(props: DeprovisionTaskDisplayPropsT) {
  const {actionFn, setVisibility, comment, text, accessRequest, title, size = 'sm'} = props;

  const activeAssignees = accessRequest.assignees.filter(
    (u) => !u.tombstone && !u.terminated && u.allowLogin && !u.expiresTs
  );

  return (
    <Confirmation
      onConfirm={actionFn}
      onClose={() => setVisibility(false)}
      title={title}
      size={size}
    >
      <div className="takeover-modal">
        {accessRequest.forSystem && (
          <div>
            <div className="tr-text-sm tr-m-1 tr-mb-3">
              <span className="tr-p-1">{accessRequest.forSystem?.name}</span>
              {'>'}
              <span className="tr-p-1">{accessRequest.forResource?.name}</span>
              {'>'}
              <span className="tr-p-1">{accessRequest.forPermission?.label}</span>
            </div>
            {text}
          </div>
        )}
        <div className="tr-flex tr-justify-between tr-items-center tr-m-2">
          <div>
            {activeAssignees.length > 0 ? (
              <>
                <span className="tr-text-xs">Originally assigned to:</span>
                {activeAssignees.map((assignee) => (
                  <UserAvatarAndName
                    key={assignee.id}
                    displayName={formatUserName(assignee)}
                    role={assignee.email}
                  />
                ))}
              </>
            ) : (
              <span className="tr-text-xs">Unassigned task</span>
            )}
          </div>
        </div>
        <label className="tr-m-1 tr-text-sm">Reason for taking over task:</label>
        <div className="body6 m-1 grayed-out">"{comment}"</div>
      </div>
    </Confirmation>
  );
}

export default TakeOverTaskModal;
