import {useState, useEffect} from 'react';
import axios from 'axios';

type ConfigProps = {
  featureFlags: string[];
  env: string;
  publishableKeys: {
    stripe: string;
    authPubKey: string;
    bugsnagKey: string;
  };
};

const useConfig = () => {
  const [config, setConfig] = useState<ConfigProps | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await axios.get('/api/orgs/config');
        setConfig(response.data);
      } catch (error) {
        setError('Error fetching config');
        setConfig(null);
      }
    };
    void fetchConfig();
  }, []);

  return {config, error};
};

export default useConfig;
