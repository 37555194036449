import _ from 'lodash';
import axios from 'axios';
import React, {useState} from 'react';
import {SubmitButton} from 'src/components/design';
import {Formik, FormikForm} from 'src/components/design/formik';
import {logger} from 'src/lib';
import {useToasts} from 'react-toast-notifications';
import {ResourceT} from 'src/types';
import {convertResourceToForm} from 'src/lib/ancestors';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Field} from 'formik';
import {TextInput} from '@trustle/component-library';
import {useRootStore} from 'src/lib/hooks';

type PropsT = {
  connectorType?: string;
  onComplete: (response: any) => void;
};

function SystemFlowNameStep(props: PropsT) {
  const rootStore = useRootStore();
  const {addToast} = useToasts();
  const [errorMessage, setErrorMessage] = useState<string>();

  if (_.isNil(rootStore.currentUser) || _.isNil(rootStore.org)) {
    return <></>;
  }

  async function save(values: any) {
    try {
      const data: Partial<Omit<ResourceT, 'connection'> & {connection?: {service: string}}> = {
        ..._.pick(values, ['name']),
        description: 'System created by connector flow',
        pendingSetup: true,
        connection: props.connectorType ? {service: props.connectorType} : undefined,
        owners: [rootStore.currentUser!.email],
        provisioners: [rootStore.currentUser!.email],
        sensitivityId: values.sensitivityId || undefined,
      };
      const response = await axios.post('/api/resources/system', {
        resource: data,
      });

      addToast(`System saved successfully`, {
        appearance: 'success',
        autoDismiss: true,
      });

      props.onComplete(response);
    } catch (err) {
      addToast(`An error occurred while saving `, {
        appearance: 'error',
        autoDismiss: true,
      });
      logger.error('response data:', err);
      const etext = 'Something went wrong. Please try again or contact support@trustle.io.';

      setErrorMessage(etext);
    }
  }

  return (
    <Formik initialValues={convertResourceToForm(rootStore.org, {} as Resource)} onSubmit={save}>
      {({isSubmitting}) => (
        <FormikForm>
          <div className="mb-5">
            <Field
              component={TextInput}
              label="Name"
              placeholder="Resource name"
              description={'Name you want to assign to the newly created system'}
              id="name"
              name="name"
              disabled={isSubmitting}
              required
            />
          </div>
          {errorMessage && <div className="text-danger">{errorMessage}</div>}
          <div className="text-right">
            <SubmitButton label="Save" name="submit-resource" inSubmit={isSubmitting} />
          </div>
        </FormikForm>
      )}
    </Formik>
  );
}

export default SystemFlowNameStep;
