import React, {useEffect, useState} from 'react';
import ReactPaginate from 'react-paginate';
import {observer} from 'mobx-react';
import {Permission} from 'src/stores/domainObjects/Permission';

import {Resource} from 'src/stores/domainObjects/Resource';
import './PermissionBranch.scss';
import _ from 'lodash';
import {getPermissionTemplateComponent, getResourceTemplateComponent} from 'src/connectors/manifests';
import {ResourceBranchPropsT} from './ResourceBranch';

const ResourceChildren = observer((props: ResourceBranchPropsT) => {
  const {
    template,
    resource,
    isTreeView = false,
    isRootNode = false,
    hideRoot = false,
    prefilteredAccesses = [],
    opened = [],
    user,
    accessMode = false,
    hidePermissionsWithoutAccesses = false,
    showTabs = false,
    sort,
    visibleItems,
  } = props;

  const ResourceCmp = getResourceTemplateComponent(resource);
  const [limit, setLimit] = useState(0);

  function isVisible(
    target: Permission | Resource,
    visibleItems: {resources: string[]; permissions: string[]; accesses: string[]} | undefined
  ) {
    if (target instanceof Resource) {
      return visibleItems?.resources.includes(target.id);
    }
    if (target instanceof Permission) {
      return visibleItems?.permissions.includes(target.id);
    }
  }

  const sortOrder = sort && sort.order ? sort.order : 'desc';
  const resources =
    !resource.rootSid && !accessMode && !user
      ? []
      : _.orderBy(resource.childResources, sort?.value ?? '', [sortOrder as 'desc' | 'asc']);
  const permissions = _.orderBy(resource.permissions, sort?.value ?? '', [sortOrder as 'desc' | 'asc']);

  const filteredPermissions = permissions.filter((permission) => {
    if (accessMode && permission.calculatedHidden.value) {
      return false;
    }

    return isVisible(permission, visibleItems);
  });

  const filteredChildResources = resources.filter((child) => {
    const isAnyChildVisible = _.some(child.subResourcesAndPermissions, (e) => {
      //is true then is hidden, we need to check that just one is visible
      return !e.calculatedHidden.value;
    });

    if (showTabs && resource.isSystem) {
      return false;
    }

    if (accessMode && !(isAnyChildVisible || !child.calculatedHidden.value)) {
      return false;
    }

    return isVisible(child, visibleItems);
  });

  const contentItemList: (Permission | Resource)[] = [...filteredPermissions, ...filteredChildResources];

  const pageSize = 20;
  const contentItems = _.size(contentItemList);

  useEffect(() => {
    const index = _.findIndex(contentItemList, (item) => {
      return opened.includes(item.id);
    });
    const page = _.floor(index / pageSize);
    setLimit(page > 0 ? page * pageSize : 0);
  }, [_.size(visibleItems?.resources), _.size(visibleItems?.permissions), opened]);

  const handlePageClick = (event: any) => {
    const newOffset = (event.selected * pageSize) % contentItems;
    setLimit(newOffset);
  };

  const currentItems = contentItemList.slice(limit, limit + pageSize);

  //Defferring component instantiation for performance enhacement
  const contentCmpList = currentItems.map((permOrResource: Permission | Resource) => {
    if (permOrResource instanceof Permission) {
      const PermissionCmp = getPermissionTemplateComponent(template, permOrResource);
      return (
        <PermissionCmp.main
          {...{
            key: permOrResource.id,
            template: template,
            isRootNode: isRootNode && !resource.rootSid ? isRootNode : false,
            accessMode: accessMode,
            permission: permOrResource,
            isTreeView: !hideRoot,
            opened: opened,
            filters: props.filters,
            setFilters: props.setFilters,
            prefilteredAccesses: prefilteredAccesses,
            hidePermissionsWithoutAccesses,
            user: user,
            visibleItems: visibleItems,
          }}
        />
      );
    } else if (permOrResource instanceof Resource) {
      return (
        <ResourceCmp.main
          {...{
            template: template,
            isRootNode: hideRoot ? isRootNode : false,
            accessMode: accessMode,
            resource: permOrResource,
            isTreeView: isTreeView,
            opened: opened,
            key: permOrResource.id,
            filters: props.filters,
            setFilters: props.setFilters,
            prefilteredAccesses: prefilteredAccesses,
            sort: sort,
            hidePermissionsWithoutAccesses,
            user: user,
            visibleItems: visibleItems,
          }}
        />
      );
    } else {
      return <></>;
    }
  });

  const pageCount = Math.ceil(contentItems / pageSize);

  const content = (
    <div key={resource.id}>
      {_.size(contentItemList) > pageSize ? (
        <>
          <ReactPaginate
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
            forcePage={_.floor(limit / pageSize)}
            pageCount={pageCount}
            previousLabel="<"
            pageClassName="page-item"
            pageLinkClassName="tr-px-4 tr-py-2 tr-text-sm"
            previousClassName="page-item"
            previousLinkClassName="tr-px-4 tr-py-2 tr-text-sm"
            nextClassName="page-item"
            nextLinkClassName="tr-px-4 tr-py-2 tr-text-sm"
            breakLabel="..."
            breakClassName="page-item"
            breakLinkClassName="tr-px-4 tr-py-2 tr-text-sm"
            containerClassName="pagination tr-flex tr-justify-end"
            activeClassName="tr-font-bold"
            renderOnZeroPageCount={null}
          />
          {contentCmpList}
        </>
      ) : (
        contentCmpList
      )}
    </div>
  );

  if (!isVisible(resource, visibleItems)) {
    return (
      <>
        {showTabs && isRootNode && (
          <div className="text-center tr-pt-2">
            <img className="tr-w-72" src="/russel-magnifying-glass.svg" alt="" />
            <h2 className="mt-0 text-xl text-center font-weight-bold text-dark">No Available Permissions Found</h2>
          </div>
        )}
      </>
    );
  }

  return content;
});

export default ResourceChildren;
