import {AuthorityServiceE} from '../../../../types';

export const gappsFields = {
  [AuthorityServiceE.GAPPS]: {
    Email: 'primaryEmail',
    Manager: 'manager',
    'Employee Type': 'employeeInfo.description',
    Department: 'employeeInfo.department',
    Title: 'employeeInfo.title',
    Role: 'employeeInfo.customType',
  },
};
