import {Confirmation} from '@trustle/component-library';
import React from 'react';

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
};

export function ConfirmAssociateAuthorityModal(props: Props) {
  const {onConfirm, onCancel} = props;
  return (
    <Confirmation
      onConfirm={onConfirm}
      onClose={onCancel}
      title="Associate as User Authority"
      confirmButtonLabel="Associate Authority"
      size="md"
    >
      <div className="tr-flex tr-flex-col tr-gap-4">
        <strong>
          This will import your Users and Create and Connect Trustle users from the Users detected in that import.
        </strong>
        <p>
          <strong>All new Users are brought in as inactive by default. </strong>
          They will need to be sent an invite to set up their Trustle account before they become active.
        </p>
        <p>
          <strong>Make sure you have tested your Field Mapping is correct. </strong>
          Invalid Imports will need to be corrected and synchronized.
        </p>
      </div>
    </Confirmation>
  );
}
