import React from 'react';
import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import {AccessRequestCommentT} from 'src/types';
import {Formik, FormikForm} from 'src/components/design/formik';
import {HistoryTab} from './HistoryTab';
import {Field} from 'formik';
import {Button, Tab, TextAreaInput} from '@trustle/component-library';
import {Task} from 'src/stores/domainObjects/Task';

import {useToasts} from 'react-toast-notifications';
import OrgEvents from '../Events/OrgEvents';

type RequestCommentsPropsT = {
  accessRequest: Task;
};

function RequestCommentsTab(props: RequestCommentsPropsT) {
  const {accessRequest} = props;
  const {addToast} = useToasts();

  async function addNewComment({newComment}: {newComment: string}, {resetForm}: {resetForm: any}): Promise<void> {
    const res = await axios.post(`/api/tasks/access_request/${accessRequest.forAccessRequest?.id}/commented`, {
      message: newComment,
    });
    addToast('Comment was successfully added', {appearance: 'success', autoDismiss: true});
    setTimeout(resetForm, 200);
    accessRequest.forAccessRequest = res.data.accessRequest;
  }

  return (
    <div>
      <ul className="list-unstyled">
        {_.map(accessRequest.forAccessRequest?.comments, (comment: AccessRequestCommentT) => {
          return (
            <li key={comment.id} className="my-3">
              <div className="mb-2">
                <span className="body5 pr-2">{moment(comment.createdAt).format('M/D/Y hh:mma')}</span>
                <span className="body5">{comment.forUser.email}</span>
              </div>
              <div className="body6">{comment.message}</div>
            </li>
          );
        })}
      </ul>
      <div className="body1">
        <Formik initialValues={{newComment: ''}} onSubmit={addNewComment}>
          {({isSubmitting, handleSubmit}) => {
            return (
              <FormikForm onSubmit={handleSubmit}>
                <div className="tr-w-full">
                  <Field component={TextAreaInput} name="newComment" placeholder="Add comment..." required />
                  <div className="text-right">
                    <Button type="submit" disabled={isSubmitting}>
                      Save
                    </Button>
                  </div>
                </div>
              </FormikForm>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

type PropsT = {
  accessRequest: Task;
};

function FocusedTaskTabs(props: PropsT) {
  const {accessRequest} = props;

  return (
    <div className="tr-my-3">
      <Tab.Group>
        <Tab.List variant="secondary">
          <Tab data-testid="history" key="history">
            <span>History</span>
          </Tab>
          <Tab data-testid="comments" key="comments">
            <span>Comments</span>
          </Tab>
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel key="history">
            {accessRequest.forAccess ? (
              <OrgEvents entityType={'accesses'} id={accessRequest.access.id} />
            ) : (
              <HistoryTab accessRequest={accessRequest} />
            )}
          </Tab.Panel>
          <Tab.Panel key="comments">
            <RequestCommentsTab accessRequest={accessRequest} />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
}

export default FocusedTaskTabs;
