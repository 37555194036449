import _ from 'lodash';
import {Icon, SystemIcons, Tooltip, UserInfo} from '@trustle/component-library';
import {Link} from 'react-router-dom';
import {formatDateFromNow, formatFullDate, formatUserName} from '../../../../../../lib';
import React from 'react';
import {useRootStore} from '../../../../../../lib/hooks';
import {SourceOfTruthE} from '../../../../../../types';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';
import {observer} from 'mobx-react';
import {UserStatus} from '../../types';
import moment from 'moment/moment';
import {UserResponse} from '../../hooks/useUsers';
import {UserActions} from '../user-actions/UserActions';

export const USER_COLUMNS = [
  {
    dataField: 'id',
    text: '',
    hidden: true,
  },
  {
    dataField: 'invitationStatus',
    headerStyle: {width: '35px', paddingLeft: '8px', paddingRight: '8px', textAlign: 'center'},
    style: {width: '35px', paddingLeft: '8px', paddingRight: '8px', textAlign: 'center'},
    formatter: (_: any, user: UserResponse) => {
      const {invitationStatus, userStatus, invitedAt} = user;

      const invitationString = `Invited on ${moment(invitedAt).format('M/D/Y hh:mma')}`;

      const statusClassMap: Record<UserStatus, string> = {
        active: 'tr-bg-status-success',
        inactive: 'tr-bg-status-pending',
        disabled: 'tr-bg-status-disabled',
        offboarded: 'tr-bg-status-failed',
      };

      if (!['disabled', 'offboarded', 'active'].includes(userStatus) && invitedAt) {
        if (invitationStatus === 'expired') {
          return (
            <span data-testid="emailExpired">
              <Icon type="emailExpired" title={`${invitationString}, it is expired now`} size="sm" />
            </span>
          );
        }
        return (
          <span data-testid="emailPending">
            <Icon type="emailPending" title={`${invitationString}`} size="sm" />
          </span>
        );
      }
      return (
        <div
          data-testid={`status${userStatus}`}
          className="tr-flex tr-items-center tr-space-x-2 tr-w-6 tr-justify-center"
          title={`User Status: ${userStatus}`}
        >
          <div className={`tr-w-[9px] tr-h-[9px] ${statusClassMap[userStatus]} tr-rounded-full`}></div>
        </div>
      );
    },
  },
  {
    dataField: 'linked',
    headerStyle: {width: '35px', paddingLeft: '8px', paddingRight: '8px', textAlign: 'center'},
    style: {width: '35px', paddingLeft: '8px', paddingRight: '8px', textAlign: 'center'},
    formatter: (_: any, user: UserResponse) => {
      return (
        <span data-testid={`statusLink${user.email}`} className="tr-text-trustle-link">
          {!user.linked && <Icon title="User is not linked to any System" type="unlinkedUser" size="sm" />}
        </span>
      );
    },
  },
  {
    text: 'Name',
    sort: true,
    dataField: 'firstname',
    formatter: (_: any, user: UserResponse) => (
      <div id="userNameCol" data-testid={`name${user.email}`}>
        <UserInfo type={user.type} isOrgOwner={user.isOrgOwner} className="tr-text-trustle-link">
          <Link className="" to={`/users/${user.id}/current`}>
            {formatUserName(user)}
          </Link>
        </UserInfo>
      </div>
    ),
  },
  {
    dataField: 'manager',
    text: 'Manager',
    formatter: (_: any, user: UserResponse) => {
      const {manager} = user;
      if (!manager) {
        return <Icon type="emptyData" title="No Manager" size="sm" />;
      }
      return <Link to={`/users/${manager?.id}`}>{formatUserName(manager)}</Link>;
    },
  },
  {
    dataField: 'lastLogin',
    sort: true,
    text: 'Last Login',
    headerStyle: {width: '150px'},
    style: {width: '150px'},
    formatter: (_: any, user: UserResponse) => {
      const {lastLogin} = user;
      return !lastLogin ? (
        <Icon type="emptyData" title="Never" size="sm" />
      ) : (
        <Tooltip position="top" content={formatFullDate(lastLogin)}>
          {formatDateFromNow(lastLogin)}
        </Tooltip>
      );
    },
  },
  {
    dataField: 'department',
    headerStyle: {width: '12em', textAlign: 'left', overFlow: 'visible'},
    style: {width: '12em', textAlign: 'left'},
    formatter: (_: string, user: UserResponse) => {
      const {department, remoteRole, title} = user;

      if (!department && !remoteRole && !title) {
        return <Icon type="emptyData" title="No data" size="sm" />;
      }

      return (
        <div className="tr-my-1 tr-flex-col">
          <div className="tr-font-bold">{department ?? '[No department defined]'}</div>
          <div>{remoteRole ?? '[No role defined]'}</div>
          <div className="tr-text-gray-600">{title ?? '[No title defined]'}</div>
        </div>
      );
    },
    headerFormatter: (_column: any, _index: number) => {
      return (
        <div className="tr-flex">
          <div className="tr-py-1">
            <span className="tr-mx-1">Dept</span>
          </div>
          <div className="tr-py-1">{'/'}</div>
          <div className="tr-py-1">
            <span className="tr-mx-1">Role</span>
          </div>
          <div className="tr-py-1">{'/'}</div>
          <div className="tr-py-1">
            <span className="tr-mx-1">Title</span>
          </div>
        </div>
      );
    },
  },
  {
    dataField: 'sourceOfTruth',
    sort: true,
    text: 'Authority',
    headerStyle: {width: '90px'},
    style: {width: '90px'},
    formatter: (_cell: any, user: UserResponse) => {
      const ObservableAuthority = observer((props: {user: any}) => {
        const {org} = useRootStore();
        const {user} = props;

        const isTrustle = _.defaultTo(user.sourceOfTruth, SourceOfTruthE.TRUSTLE) === SourceOfTruthE.TRUSTLE;
        const icon = isTrustle ? (
          <Icon type="Trustle" title="Trustle" forcedSize={32} />
        ) : (
          <SystemIcons name={org.idpSettings?.service as SystemIconsTypesEnum} size="sm" />
        );

        return (
          <div className="tr-py-4">
            <a href="#" title={org.orgUsersAuthorityLabel}>
              {icon}
            </a>
          </div>
        );
      });

      return <ObservableAuthority user={user} />;
    },
  },
  {
    classes: 'show-on-hover',
    formatter: (_: any, user: UserResponse) => {
      return (
        <div className="action-buttons tr-mr-2">
          <UserActions user={user} />
        </div>
      );
    },
  },
] as any;
