import React from 'react';

type InputErrorPropsT = {
  meta: {touched: boolean; error?: string};
};

export function InputError(props: InputErrorPropsT) {
  const {meta} = props;
  if (!meta.error) {
    return null;
  }
  return <div className="tr-invalid-feedback">{meta.error}</div>;
}
