import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {formatDateFromNow, formatFullDate, formatUserName, fuseSearch} from 'src/lib';
import {Link} from 'react-router-dom';
import {useRootStore} from 'src/lib/hooks';
import _ from 'lodash';

import AccountDetailModal, {TabModeE} from 'src/views/Resource/Account/AccountDetailsModal';
import {Account} from 'src/stores/domainObjects/Account';
import {Resource} from 'src/stores/domainObjects/Resource';
import {SearchAndReportToggle} from './SearchAndReportToggle';
import {Table, Tooltip} from '@trustle/component-library';

import HistoryEventDetails from './HistoryDetails';

type PropsT = {
  uid: string;
};

export enum UserEventType {
  USER_INVITED = 'User Invited',
  TRUSTLE_USER_ONBOARDED = 'User Onboarded',
  TRUSTLE_USER_UPDATED = 'User Profile Updated',
  TRUSTLE_USER_MAPPED = 'User Linked To Account',
  TRUSTLE_USER_UNMAPPED = 'User Unlinked From Account',
  TRUSTLE_USER_OFFBOARDED = 'User Offboarded',
  TRUSTLE_USER_MANAGER_UPD = 'User Manager Updated',
  TRUSTLE_PROFILE_USER_MANAGER_UPD = 'User Profile Manager Updated',
}

const UserHistory = observer(function StatusCmp(props: PropsT) {
  const {usersStore, currentUser} = useRootStore();

  if (_.isNil(currentUser)) {
    return <></>;
  }

  const {uid} = props;
  const [query, setQuery] = useState('');
  const [accountModalData, setAccountModalData] = useState<{account: Account; resource: Resource} | undefined>(
    undefined
  );

  const userEvents = usersStore.usersMap[uid].history;
  const loginEvents = usersStore.usersMap[uid].loginHistory;

  const events = [...loginEvents, ...userEvents];
  const columns = [
    {
      dataField: 'created',
      sort: true,
      text: 'Date',
      headerStyle: {width: '5rem'},
      style: {width: '5rem'},
      formatter: (cell: any, _row: any) => {
        return <Tooltip content={formatFullDate(cell)}>{formatDateFromNow(cell)}</Tooltip>;
      },
    },
    {
      dataField: 'event',
      sort: false,
      classes: 'text-left',
      headerClasses: 'text-left',
      headerStyle: {width: '12rem'},
      style: {width: '12rem'},
      text: 'Event',
      formatter: (cell: any, row: any) => {
        return <>{row.event ? cell : <span>{row.data ? _.capitalize(row.data.name) : ''}</span>}</>;
      },
    },
    {
      dataField: 'details',
      sort: false,
      classes: 'text-left',
      headerClasses: 'text-left',
      text: 'Details',
      headerStyle: {width: '20rem'},
      style: {width: '20rem'},
      formatter: (cell: any, row: any) => {
        return (
          <div className="row ">
            {row.event ? (
              <HistoryEventDetails event={row.event} dict={cell} />
            ) : (
              <span>{row.data ? `${_.capitalize(row.data.name)} using ${_.capitalize(row.data.mode)} mode` : ''}</span>
            )}
          </div>
        );
      },
    },
    {
      dataField: 'by',
      sort: true,
      classes: 'text-left',
      headerClasses: 'text-left',
      headerStyle: {width: '15rem'},
      style: {width: '15rem'},
      text: 'By',
      formatter: (cell: any, _row: any) => {
        const user = cell ? cell : usersStore.usersMap[_row.uid];
        if (_.isNil(user)) {
          return 'N/A';
        }
        if (_.isNil(user?.id)) {
          return formatUserName(user);
        }
        return (
          <Link to={`/users/${user?.id}`} target="_blank">
            {formatUserName(user)}
          </Link>
        );
      },
    },
  ];

  const searchedEvents = fuseSearch(
    query,
    ['event', 'by.firstname', 'by.lastname', 'plainDetails', 'data.mode', 'data.name'],
    _.map(events, (ev) => {
      return {...ev, plainDetails: JSON.stringify(ev)};
    })
  );

  return (
    <div className="tr-mt-8 container">
      <div className="tr-flex">
        <SearchAndReportToggle reportUrl={`/api/users/${uid}/report`} setQuery={setQuery} />
      </div>
      <Table
        overrideKeyField={'id'}
        tableKey="resourcedetailmodalsreport"
        defaultSorted={[{dataField: 'created', order: 'desc'}]}
        data={searchedEvents}
        columns={columns}
        striped={false}
        wrapperClasses="rounded-xl border border-black rounded"
        bordered={false}
      />
      {accountModalData && (
        <AccountDetailModal
          mode={TabModeE.HISTORY}
          userAccount={accountModalData.account}
          closeModal={() => {
            setAccountModalData(undefined);
          }}
        />
      )}
    </div>
  );
});

export default UserHistory;
