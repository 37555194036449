import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {Account} from 'src/stores/domainObjects/Account';
import {
  ConnectedAppsCol,
  FlaggedForReviewColumn,
  IssueCol,
  LastActivityColumn,
  NumAccessesLinkCol,
  RiskScoreCol,
  StatusColumn,
  UsageCol,
  UserAccountColumn,
  MultipleActionsCol,
} from 'src/components/design/tables/SharedColumnDefinitions/AccountColumns';

import AccountDetailModal, {TabModeE} from './AccountDetailsModal';
import {useQueryStrings, useRootStore} from 'src/lib/hooks';
import _ from 'lodash';
import {Resource} from 'src/stores/domainObjects/Resource';
import {AccountTabTypeE, EmptyAccountsTab} from './ResourceAccounts';
import {ColumnDescription, Icon, Table, Tooltip} from '@trustle/component-library';
import {getAccountsTemplate} from 'src/connectors/manifests';
import {ConnectionServiceE} from 'src/types';

const flaggedAccounts = observer(({accounts, system}: {accounts: Account[]; system: Resource}) => {
  const {accountsStore} = useRootStore();
  const [filters, setFilters] = useQueryStrings();

  const preSelectedAccount = accountsStore.accountsMap[filters['Account Name']];
  const [detailsModal, setDetailsModal] = useState<{account: Account; mode: TabModeE} | undefined>(
    filters.open && preSelectedAccount ? {account: preSelectedAccount, mode: TabModeE.SERVICE_USAGE} : undefined
  );

  if (accounts.length === 0) {
    return <EmptyAccountsTab tab={AccountTabTypeE.FLAGGED} />;
  }

  const isAWSConnection = system.type === ConnectionServiceE.AWS;
  const isGAPPSConnection = system.type === ConnectionServiceE.GAPPS;

  const template = getAccountsTemplate(system?.type as string);
  const FlaggedAccountsTable = template!.Flagged ? template!.Flagged({accounts, system}) : [];

  const defaultCols: ColumnDescription<Account>[] = [
    {
      ...FlaggedForReviewColumn,
      formatter: (flaggedForReview, account: Account) => {
        const FlaggedComponent = (flaggedForReview: any) => {
          return (
            <div className={flaggedForReview ? 'flag-on-hover-effect' : 'show-flag-on-hover'}>
              <Icon type="flagOn" size="sm" />
            </div>
          );
        };
        return (
          <div
            role="button"
            onClick={() => {
              accountsStore.setFlaggedForReview([account.id], !flaggedForReview);
            }}
          >
            <Tooltip content="Click to toggle flag">
              <FlaggedComponent />
            </Tooltip>
          </div>
        );
      },
    },
    StatusColumn,
    UserAccountColumn({}),
    NumAccessesLinkCol,
    {
      ...ConnectedAppsCol,
      hidden: !isGAPPSConnection,
      formatExtraData: {
        onClick: (account: Account) => setDetailsModal({account, mode: TabModeE.CONNECTED_APPLICATIONS}),
      },
    },
    {
      ...UsageCol,
      formatExtraData: {
        onClick: (account: Account) => setDetailsModal({account, mode: TabModeE.SERVICE_USAGE}),
      },
      hidden: !system.retrieveUsageDataEnabled,
    },
    {
      ...RiskScoreCol,
      hidden: !isAWSConnection,
      formatExtraData: {
        onClick: (account: Account) => setDetailsModal({account, mode: TabModeE.RISK_REPORT}),
      },
    },
    LastActivityColumn,
    {
      ...IssueCol,
      formatExtraData: {
        onClick: (account: Account) => setDetailsModal({account, mode: TabModeE.ACCOUNT_SECURITY}),
      },
      hidden: !system.retrieveUsageDataEnabled,
    },
    {...MultipleActionsCol, hidden: false},
  ];

  const enabledColumns: ColumnDescription<Account>[] = _.map(
    defaultCols,
    (col: ColumnDescription<Account>): ColumnDescription<Account> => {
      return {...col, hidden: col.hidden || !FlaggedAccountsTable.includes(col.dataField)};
    }
  );

  return (
    <>
      <Table
        data={accounts}
        tableKey="flaggedaccount"
        columns={enabledColumns}
        rowClasses={(row: Account) => (row.isAuthzOwner ? 'authz-row' : '')}
        striped={false}
        wrapperClasses="rounded-xl border border-black rounded"
        bordered={false}
      />
      {detailsModal && (
        <AccountDetailModal
          userAccount={detailsModal.account}
          mode={detailsModal.mode}
          closeModal={() => {
            setDetailsModal(undefined);
            if (filters['open']) {
              setFilters(_.omit(filters, 'open'));
            }
          }}
        />
      )}
    </>
  );
});

export default flaggedAccounts;
