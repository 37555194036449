import {Icon} from '@trustle/component-library';
import React from 'react';

export const LICENSES_AVAILABLE_COLORS = ['#0D2357', '#8D2C72', '#26A666', '#FE7637'];

export const uniqueColorProvider = (colorsList: string[]) => {
  const colors = [...colorsList];

  const getColor = () => {
    return colors.shift();
  };

  return {
    getColor,
  };
};

export const EXPOSURE_COLOR = '#D3D3D3';
export const USAGE_COLOR = '#0091FF';

export type LicenseUsageChartProps = {
  data: any[];
  series: string[];
  summarizeAllLicenses?: boolean;
  noDataMessage?: string;
  licenseByKeyMap: Record<string, {name: string; color?: string}>;
};

export function formatDateForLegend(key: string) {
  const format = (key: string) => {
    const [year, month, day] = key.split('-');
    return `${month}/${day}/${year.slice(2)}`;
  };

  const splitted = key.split('|');
  if (splitted.length === 1) {
    return format(key);
  } else {
    return `${format(splitted[0])} - ${format(splitted[1])}`;
  }
}

export function renderXAxisReferenceLabel({viewBox: {x, y}}: any) {
  const d = 28;
  const r = d / 2;

  const transform = `translate(${x - r} ${y - d - 4})`;

  return (
    <g transform={transform}>
      <Icon type="Trustle" color="red" size="md" />
    </g>
  );
}
