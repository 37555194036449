import axios from 'axios';
import {useState} from 'react';

export type AccountsSummaryResponse = {
  linkedAccountCount: number;
  unlinkedAccountCount: number;
  flaggedAccountCount: number;
  perfectMatchAccountCount: number;
  partialMatchAccountCount: number;
  noMatchAccountCount: number;
  employeeUserCount: number;
  contractorUserCount: number;
  customerUserCount: number;
  systemUserCount: number;
};

export type AccountsSummaryParams = {
  systemId?: string;
};

export function useAccountsSummary() {
  const [data, setData] = useState<AccountsSummaryResponse>();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<Error | null>(null);

  const BASE_URL = '/api/v2/principals_summary';

  const getAccountsSummary = async (params: AccountsSummaryParams) => {
    setLoading(true);
    setError(null);
    try {
      const {...rest} = params;
      const parsedParams: Record<string, string> = {};

      Object.keys(rest).forEach((key) => {
        const typedKey = key as keyof typeof rest;
        const value = rest[typedKey] as string | number;
        if (rest[typedKey] !== undefined) {
          parsedParams[typedKey] = value.toString();
        }
      });
      const queryString = new URLSearchParams(parsedParams).toString();
      const {data: responseData} = await axios.get<AccountsSummaryResponse>(`${BASE_URL}?${queryString}`);
      setData(responseData);
    } catch (err: any) {
      // TODO: Implement handling error
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {data, loading, error, getAccountsSummary};
}
