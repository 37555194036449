import {Alert, Button} from '@trustle/component-library';
import React from 'react';
import {useHistory} from 'react-router-dom';

type Props = {
  resourceId: string;
};
export function AlreadyConfiguredAuthorityBanner(props: Props) {
  const {resourceId} = props;
  const history = useHistory();

  return (
    <Alert title="Already configured authority" colorVariant="warning" className="tr-w-full">
      <div className="tr-flex tr-items-baseline tr-justify-between">
        <p>You already have a configured authority for your users. Disassociate it before continuing.</p>
        <Button
          variant="secondary"
          onClick={() => {
            history.push(`/resource/manage/${resourceId}/settings/authority_settings`);
          }}
        >
          Go to authority
        </Button>
      </div>
    </Alert>
  );
}
