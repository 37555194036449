import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {ConnectionInfoT, ImportStatus} from 'src/types';
import {Loading} from '@trustle/component-library';
import M365Setup from './M365Setup';
import TestConnection from '../common/editConnection/TestConnection';
import {useHistory, useLocation} from 'react-router-dom';
import {parseAndCleanUpQueryParams} from '../common/helpers';
import {Resource} from 'src/stores/domainObjects/Resource';
import {useToasts} from 'react-toast-notifications';
import {useConnectionCredentials} from '../../lib/hooks';
import ShowM365Account from './ShowM365Account';

export default function EditConnectionM365(props: {resource: Resource}) {
  const {resource} = props;

  const lastImport = resource.connector?.lastImport;

  const [connectionInfo, setConnectionInfo] = useState<ConnectionInfoT>();
  const [displayCredentialsForm, setDisplayCredentialsForm] = useState<boolean>(false);
  const [connectorStatus] = useState<string | undefined>(lastImport?.status);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [testConnectionInProgress, setTestConnectionInProgress] = useState<boolean>(false);
  const [error, setError] = useState<string | null>();

  const history = useHistory();
  const location = useLocation();
  const {addToast} = useToasts();
  const {testConnection: testConnectionAPI} = useConnectionCredentials();

  async function loadConnection() {
    const connectionResponse = await axios.get(`/api/connect/${resource.connectionId}`);
    setConnectionInfo(connectionResponse.data);
  }

  async function onCredentialsUpdated() {
    await loadConnection();
    setDisplayCredentialsForm(false);
  }

  useEffect(() => {
    void loadConnection();
    parseAndCleanUpQueryParams(history, location, setError);
  }, [resource.connectionId]);

  if (!connectionInfo) {
    return <Loading />;
  }

  const {connection} = connectionInfo;
  const {serviceFriendlyName} = connection;

  async function testConnection() {
    setTestConnectionInProgress(true);
    const {data: graphResponse} = await testConnectionAPI(connection.id, {autoTest: true});
    if (!graphResponse.ok) {
      const err = graphResponse.error.message ?? graphResponse.message ?? 'Connection failed';

      //filitering message:
      const regex = /Trace ID:.*/s;
      const result = err.replace(regex, '');

      setErrorMsg(result);
      addToast('An error ocurred trying to validate the credentials', {
        appearance: 'error',
        autoDismiss: false,
      });
      setTestConnectionInProgress(false);
      return;
    }

    if (graphResponse.ok) {
      setErrorMsg('');
      addToast('Succesfully connected', {appearance: 'success', autoDismiss: true});
      setTestConnectionInProgress(false);
    }
  }

  return (
    <div>
      <div className="tr-my-2 no-padding">
        <div className="tr-w-full lg:tr-w-2/3">
          <TestConnection
            name={serviceFriendlyName!}
            connectionOwner={<ShowM365Account authzOwner={connectionInfo.connection?.authzOwner}></ShowM365Account>}
            connectorStatus={error ? ImportStatus.FAILED : connectorStatus!}
            onTest={testConnection}
            errorMsg={errorMsg}
            testing={testConnectionInProgress}
            onUpdate={() => setDisplayCredentialsForm(!displayCredentialsForm)}
            updating={displayCredentialsForm}
            lastImport={lastImport}
          />

          {displayCredentialsForm && lastImport && (
            <M365Setup connectionInfo={connectionInfo} onComplete={onCredentialsUpdated} editMode={true} />
          )}
        </div>
      </div>
    </div>
  );
}
