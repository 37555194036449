import React from 'react';
import {Alert, Loading} from '@trustle/component-library';
import {ConnectionInfoT} from 'src/types';
import _ from 'lodash';
import ConnectorStatus from 'src/components/StatusIndicator';

import {useQueryParams} from 'src/lib';
import {SubmitButton} from 'src/components/design';
import {ConnectorSchema} from '../schemas/default';

function ManifestOauth(props: {
  manifest: ConnectorSchema;
  nextStep: any;
  connectionInfo: ConnectionInfoT;
  editMode?: boolean;
}) {
  const {manifest, connectionInfo, editMode = false, nextStep} = props;

  //We need to work on generalize this more
  const [actor, error, authenticationMethod] = useQueryParams(['actor', 'error', 'authenticationMethod']);
  const connected = !_.isEmpty(actor) && !error;
  const manifestButtons = manifest.auth?.buttons;
  const connectionId = _.get(connectionInfo, 'connection.id');

  //This is where all the possible values live, surely can be filtered better.
  const defaultParams: _.Dictionary<any> = {
    actor,
    authenticationMethod,
    connected,
    connectionId,
    editConnection: editMode,
  };

  if (!manifest) {
    return <Loading />;
  }

  function renderTestConnection() {
    return (
      <span className={`tr-my-3 body6`}>
        {connected && <Alert colorVariant="success">Connected Successfully!</Alert>}
        {!connected && error && <Alert colorVariant="danger">An error occured: {error}</Alert>}
      </span>
    );
  }

  const getConnectedMsg = (): React.ReactNode => {
    return (
      <>
        <ConnectorStatus className="tr-mr-3" status={'success'}></ConnectorStatus>
        <span className="body5">
          <span className="label success">Connected </span> - Authorized by: {actor}
        </span>
      </>
    );
  };

  return (
    <div>
      {connected && getConnectedMsg()}
      {(connected || error) && renderTestConnection()}
      {manifestButtons &&
        manifestButtons.map((button) => {
          let params: _.Dictionary<any> = {};
          const unsetParams = _.omitBy(button.params, (param) => {
            return !_.isUndefined(param) || _.isFunction(param);
          });

          for (const key in unsetParams) {
            params[key] = defaultParams[key];
          }

          const setParams = _.omitBy(button.params, (param) => {
            return _.isUndefined(param) || _.isFunction(param);
          });

          params = {...params, ...setParams};

          const functionParams = _.omitBy(button.params, (param) => {
            return !_.isFunction(param);
          });

          for (const key in functionParams) {
            //TO DO: We should dinamyze what can be used as param.
            params[key] = functionParams[key](connected);
          }

          return (
            <>
              <div className="tr-pb-2">
                <div className="tr-mb-2 row">
                  <div className="col-md-4">
                    <h5>{button.title}</h5>
                  </div>
                  <div className="col-md-10">{button.authenticationStatus}</div>
                </div>
                <div className="body5-normal">{button.description}</div>
                {button.install}
              </div>
              <div className="body5 text-right">
                <a
                  className="btn btn-primary"
                  href={`${button.href}?${new URLSearchParams(params).toString()}`}
                  rel="noreferrer"
                >
                  {button.label}
                </a>
              </div>
            </>
          );
        })}
      {!editMode && (
        <div className="tr-mt-4">
          <div className="text-right">
            <SubmitButton
              name="appComplete"
              disabled={!connected}
              onClick={() => {
                nextStep({});
              }}
              label={'Save'}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ManifestOauth;
