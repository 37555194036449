import {observer} from 'mobx-react';
import React from 'react';
import {Route, Redirect, RouteProps} from 'react-router-dom';
import {useRootStore} from 'src/lib/hooks';

/**
 * redirects to "/login" when user isn't logged in (adding originally requested route to state). Otherwise
 * renders like a normal Route.
 */
const PrivateRoute = observer(({render, ...rest}: RouteProps) => {
  const {authInfo} = useRootStore();

  return (
    <Route
      {...rest}
      render={
        authInfo?.isLoggedIn
          ? (props) => <>{render?.(props)}</>
          : ({location}) => <Redirect to={{pathname: `/login`, state: {from: location}}} />
      }
    />
  );
});

export default PrivateRoute;
