import React from 'react';

const EULAInitialContent = () => {
  return (
    <>
      <h3 className="tr-mx-auto">Trustle, Inc.</h3>
      <h3 className="tr-mx-auto">Enterprise Terms & Conditions</h3>
      <div className="tr-my-4">
        PLEASE READ THESE ENTERPRISE TERMS (“TERMS”) CAREFULLY BEFORE USING THE SERVICES OFFERED BY Trustle, Inc.
        (“Trustle”). BY MUTUALLY EXECUTING ONE OR MORE ORDER FORMS WITH TRUSTLE WHICH REFERENCE THESE TERMS (EACH, AN
        “ORDER FORM”), YOU (“CUSTOMER”) AGREE TO BE BOUND BY THESE TERMS (TOGETHER WITH ALL ORDER FORMS, THE
        “AGREEMENT”) TO THE EXCLUSION OF ALL OTHER TERMS. IN ADDITION, ANY ONLINE ORDER FORM WHICH YOU SUBMIT VIA
        TRUSTLE’S STANDARD ONLINE PROCESS AND WHICH IS ACCEPTED BY TRUSTLE SHALL BE DEEMED TO BE MUTUALLY EXECUTED. IF
        THE TERMS OF THIS AGREEMENT ARE CONSIDERED AN OFFER, ACCEPTANCE IS EXPRESSLY LIMITED TO SUCH TERMS.
      </div>
      <h4>1. Order Forms Access to the Service</h4>
      <p>
        Upon mutual execution, each Order Form shall be incorporated into and form a part of the Agreement. For each
        Order Form, subject to Customer’s compliance with the terms and conditions of this Agreement (including any
        limitations and restrictions set forth on the applicable Order Form) Trustle grants Customer a nonexclusive,
        limited, personal, nonsublicensable, nontransferable right and license to internally access and use the Trustle
        product(s) and/or service(s) specified in such Order Form (collectively, the “Service,” or “Services”) during
        the applicable Order Form Term (as defined below) for the internal business purposes of Customer, only as
        provided herein and only in accordance with Trustle’s applicable then-current official user documentation for
        such Service (the “Documentation”).
      </p>
      <h4>2. Free Trial</h4>
      <p>
        If a Free Trial is provided for on an Order Form, Trustle will make the Service available to Customer on a trial
        basis free of charge (“Free Trial”), which will commence upon Customer’s first use of the Service and shall
        continue for the trial period specified on such Order Form (the “Trial Period”). Customer may use the Free Trial
        for internal evaluation purposes only and may not use it in a commercial, production and/or live environment.
        During such Trial Period, if Customer provides any personally identifiable information to Trustle, Trustle shall
        have no obligations with respect to such data, and Trustle expressly disclaims any liability with respect to
        such data, which includes, but is not limited to, liability arising from any data security or applicable privacy
        laws. Notwithstanding anything to the contrary in this Agreement, (i) the Service under the Free Trial is
        provided “AS-IS” without warranty of any kind, (ii) Trustle shall not have any obligation to provide any support
        for the Service (including, without limitation, pursuant to Section 4 (Support), and (iii) Trustle shall have no
        obligations under Section 12 (Indemnification) or liability of any kind with respect to the Service for the Free
        Trial (unless such exclusion of liability is not enforceable under applicable law, in which case Trustle’s
        liability with respect to the Service provided during the Free Trial shall not exceed $100.00). Customer shall
        be fully liable under this Agreement to Trustle for any damages arising out of Customer’s use of the Service
        under the Free Trial, any breach by Customer of this Agreement and any of Customer’s indemnification obligations
        hereunder. In the event of a conflict between this Section 2 and any other portion of this Agreement, this
        Section 2 shall control. Either party may terminate the applicable Order Form for any reason, upon notice to the
        other party, during the Trial Period.
      </p>
      <h4>3. Implementation</h4>
      <p>
        Upon payment of any applicable fees set forth in each Order Form, Trustle agrees to use reasonable commercial
        efforts to provide standard implementation assistance for the Service only if and to the extent such assistance
        is set forth on such Order Form (“Implementation Assistance”). If Trustle provides Implementation Assistance in
        excess of any hours estimate on the applicable Order Form, or if Trustle otherwise provides additional services
        beyond those agreed in an Order Form, Customer will pay Trustle at its then-current hourly rates for such
        services.
      </p>
      <h4>4. Support</h4>
      <p>
        Subject to Customer’s payment of all applicable fees, Trustle will undertake commercially reasonable efforts to
        make the Service available twenty-four (24) hours a day, seven (7) days a week. Notwithstanding the foregoing,
        Trustle reserves the right to suspend Customer’s access to the Service: (i) for scheduled or emergency
        maintenance, or (ii) in the event Customer is in breach of this Agreement, including failure to pay any amounts
        due to Trustle.
      </p>
      <h4>5. Service Updates</h4>
      <p>
        From time to time, Trustle may provide upgrades, patches, enhancements, or fixes for the Services to its
        customers generally without additional charge (“Updates”), and such Updates will become part of the Services and
        subject to this Agreement; provided that Trustle shall have no obligation under this Agreement or otherwise to
        provide any such Updates. Customer understands that Trustle may make improvements and modifications to the
        Services at any time in its sole discretion; provided that Trustle will use commercially reasonable efforts to
        give Customer reasonable prior notice of any major changes.
      </p>
      <h4>6. Ownership; Feedback</h4>
      <p>
        As between the parties, Trustle retains all right, title, and interest in and to the Services, and all software,
        products, works, and other intellectual property and moral rights related thereto or created, used, or provided
        by Trustle for the purposes of this Agreement, including any copies and derivative works of the foregoing. Any
        software which is distributed or otherwise provided to Customer hereunder (including without limitation any
        software identified on an Order Form) shall be deemed a part of the “Services” and subject to all of the terms
        and conditions of this Agreement. No rights or licenses are granted except as expressly and unambiguously set
        forth in this Agreement. Customer may (but is not obligated to) provide suggestions, comments or other feedback
        to Trustle with respect to the Service (“Feedback”). Feedback, even if designated as confidential by Customer,
        shall not create any confidentiality obligation for Trustle notwithstanding anything else. Trustle acknowledges
        and agrees that all Feedback is provided “AS IS” and without warranty of any kind. Customer shall, and hereby
        does, grant to Trustle a nonexclusive, worldwide, perpetual, irrevocable, transferable, sublicensable,
        royalty-free, fully paid up license to use and exploit the Feedback for any purpose. Nothing in this Agreement
        will impair Trustle’s right to develop, acquire, license, market, promote or distribute products, software or
        technologies that perform the same or similar functions as, or otherwise compete with any products, software or
        technologies that Customer may develop, produce, market, or distribute.
      </p>
      7. <h4>Fees; Payment</h4>
      <p>
        Customer shall pay Trustle fees for the Service as set forth in each Order Form (“Fees”). Unless otherwise
        specified in an Order Form, all Fees shall be invoiced annually in advance and all invoices issued under this
        Agreement are payable in U.S. dollars within thirty (30) days from date of invoice. Past due invoices are
        subject to interest on any outstanding balance of the lesser of 1.5% per month or the maximum amount permitted
        by law. Customer shall be responsible for all taxes associated with Service (including, without limitation,
        sales and use tax; and excluding only taxes based on Trustle’s net income). All Fees paid are non-refundable and
        are not subject to set-off. If Customer exceeds any user or usage limitations set forth on an Order Form, then
        (i) Trustle shall invoice Customer for such additional users or usage at the overage rates set forth on the
        Order Form (or if no overage rates are set forth on the Order Form, at Trustle’s then-current fees), in each
        case on a pro-rata basis from the first date of such overage through the end of the Order Form Initial Term or
        then-current Order Form Renewal Term (as applicable), and (ii) if such Order Form Term renews (in accordance
        with the section entitled “Term; Termination”, below), such renewal shall include the additional fees for such
        overage.
      </p>
      <h4>8. Restrictions</h4>
      <p>
        Except as expressly set forth in this Agreement, Customer shall not (and shall not permit any third party to),
        directly or indirectly: (i) reverse engineer, decompile, disassemble, or otherwise attempt to discover the
        source code, object code, or underlying structure, ideas, or algorithms of the Service (except to the extent
        applicable laws specifically prohibit such restriction); (ii) modify, translate, or create derivative works
        based on the Service; (iii) copy, rent, lease, distribute, pledge, assign, or otherwise transfer or encumber
        rights to the Service; (iv) use the Service for the benefit of a third party; (v) remove or otherwise alter any
        proprietary notices or labels from the Service or any portion thereof; (vi) use the Service to build an
        application or product that is competitive with any Trustle product or service; (vii) interfere or attempt to
        interfere with the proper working of the Service or any activities conducted on the Service; or (viii) bypass
        any measures Trustle may use to prevent or restrict access to the Service (or other accounts, computer systems
        or networks connected to the Service). Customer is responsible for all of Customer’s activity in connection with
        the Service, including but not limited to uploading Customer Data (as defined below) onto the Service. Customer
        (a) shall use the Service only in compliance with all applicable local, state, national and foreign laws,
        treaties and regulations in connection with Customer’s use of the Service (including those related to data
        privacy, international communications, export laws and the transmission of technical or personal data laws), and
        (b) shall not use the Service in a manner that violates any third party intellectual property, contractual or
        other proprietary rights. THE SERVICES ARE NOT DESIGNED, MANUFACTURED, OR INTENDED FOR USE IN HAZARDOUS
        ENVIRONMENTS REQUIRING FAIL-SAFE PERFORMANCE WHERE THE FAILURE OF THE SERVICE COULD LEAD DIRECTLY TO DEATH,
        PERSONAL INJURY, OR SIGNIFICANT PHYSICAL OR ENVIRONMENTAL DAMAGE (“HIGH RISK ACTIVITIES”). USE OF THE SERVICES
        IN HIGH RISK ACTIVITIES IS NOT AUTHORIZED.
      </p>
      <h4>9. Customer Data</h4>
      <p>
        For purposes of this Agreement, “Customer Data” shall mean any data, information or other material provided,
        uploaded, or submitted by Customer to the Service in the course of using the Service. Customer shall retain all
        right, title and interest in and to the Customer Data, including all intellectual property rights therein.
        Customer, not Trustle, shall have sole responsibility for the accuracy, quality, integrity, legality,
        reliability, appropriateness, and intellectual property ownership or right to use of all Customer Data. Customer
        represents and warrants that it has all rights necessary to provide the Customer Data to Trustle as contemplated
        hereunder, in each case without any infringement, violation or misappropriation of any third party rights
        (including, without limitation, intellectual property rights and rights of privacy). Trustle shall use
        commercially reasonable efforts to maintain the security and integrity of the Service and the Customer Data.
        Trustle is not responsible to Customer for unauthorized access to Customer Data or the unauthorized use of the
        Service unless such access is due solely to Trustle’s gross negligence or willful misconduct. Customer is
        responsible for the use of the Service by any person to whom Customer has given access to the Service or who
        accesses the Service using Customer’s login credentials, even if Customer did not authorize such use. Customer
        agrees and acknowledges that Customer Data may be irretrievably deleted if Customer’s account is ninety (90)
        days or more delinquent. Notwithstanding anything to the contrary, Customer acknowledges and agrees that Trustle
        may (i) internally use and modify (but not disclose) Customer Data for the purposes of (A) providing the Service
        to Customer and (B) generating Aggregated Anonymous Data (as defined below), and (ii) freely use and make
        available Aggregated Anonymous Data for Trustle’s business purposes (including without limitation, for purposes
        of improving, testing, operating, promoting and marketing Trustle’s products and services). “Aggregated
        Anonymous Data” means data submitted to, collected by, or generated by Trustle in connection with Customer’s use
        of the Service, but only in aggregate, anonymized form which is not linked specifically to Customer.
      </p>
      <h4>10. Third Party Services</h4>
      <p>
        Customer acknowledges and agrees that the Service may operate on, with and/or using application programming
        interfaces (APIs) and/or other services operated or provided by third parties (“Third Party Services”),
        including without limitation through integrations or connectors to such Third Party Services that are provided
        by Trustle. Trustle is not responsible for the operation of any Third Party Services nor the availability or
        operation of the Service to the extent such availability and operation is dependent upon Third Party Services.
        Customer is solely responsible for procuring any and all rights necessary for Customer to access Third Party
        Services (including any Customer Data or other information relating thereto) and for complying with any
        applicable terms or conditions thereof. Trustle does not make any representations or warranties with respect to
        Third Party Services or any third party providers. Any exchange of data or other interaction between Customer
        and a third party provider is solely between Customer and such third party provider and is governed by such
        third party’s terms and conditions.
      </p>
      <h4>11. Term; Termination</h4>
      <p>
        This Agreement shall commence upon the date of the first Order Form, and, unless earlier terminated in
        accordance herewith, shall last until the expiration of all Order Form Terms. For each Order Form, unless
        otherwise specified therein, the “Order Form Term” shall begin as of the Effective Date set forth on such Order
        Form, and unless earlier terminated as set forth herein, (x) shall continue during the Trial Period and during
        the Initial Term specified on such Order Form (the “Order Form Initial Term”), and (y) following the Order Form
        Initial Term, shall automatically renew for additional successive periods of equal duration to the Order Form
        Initial Term (each, a “Order Form Renewal Term”) unless either party notifies the other party of such party’s
        intention not to renew no later than thirty (30) days prior to the expiration of the Order Form Initial Term or
        then-current Order Form Renewal Term, as applicable. In the event of a material breach of this Agreement by
        either party, the non-breaching party may terminate this Agreement by providing written notice to the breaching
        party, provided that the breaching party does not materially cure such breach within thirty (30) days of receipt
        of such notice. Without limiting the foregoing, Trustle may suspend or limit Customer’s access to or use of the
        Service if (i) Customer’s account is more than forty five (45) days past due, or (ii) Customer’s use of the
        Service results in (or Trustle believes is reasonably likely to result in) damage to or material degradation of
        the Service which interferes with Trustle’s ability to provide access to the Service to other customers;
        provided that in the case of subsection (ii): (a) Trustle shall use reasonable good faith efforts to work with
        Customer to resolve or mitigate the damage or degradation in order to resolve the issue without resorting to
        suspension or limitation; (b) prior to any such suspension or limitation, Trustle shall use commercially
        reasonable efforts to provide notice to Customer describing the nature of the damage or degradation; and (c)
        Trustle shall reinstate Customer’s use of or access to the Service, as applicable, if Customer remediates the
        issue within ten (10) days of receipt of such notice. All provisions of this Agreement which by their nature
        should survive termination shall survive termination, including, without limitation, accrued payment
        obligations, ownership provisions, warranty disclaimers, indemnity and limitations of liability. In the case of
        expiration or termination of this Agreement, upon written request by Customer made before, or within thirty (30)
        days after, the effective date of expiration or termination, Trustle will make available to Customer a download
        of readily exportable Customer Data in Trustle’s standard format. For clarity, any services provided by Trustle
        to Customer, including the data export set out above, and any assistance in exporting the Customer Data, shall
        be billable at the data export rate set forth in an Order Form or, if not such rate is provided, then at
        Trustle’s standard rates then in effect.
      </p>
      <h4>12. Indemnification</h4>
      <p>
        Each party ('Indemnitor') shall defend, indemnify, and hold harmless the other party, its affiliates and each of
        its and its affiliates’ employees, contractors, directors, officers, suppliers and representatives
        (collectively, the “Indemnitee”) from all liabilities, claims, and expenses paid or payable to an unaffiliated
        third party (including reasonable attorneys’ fees) (“Losses”), that arise from or relate to any claim that (i)
        in the case of Customer as Indemnitor, that the Customer Data or Customer’s use of the Service infringes,
        violates, or misappropriates any third party intellectual property or proprietary right, including any rights of
        privacy, or violates any applicable law, or (ii) the Service (in the case of Trustle as Indemnitor), infringes,
        violates, or misappropriates any third party intellectual property or proprietary right. Each Indemnitor’s
        indemnification obligations hereunder shall be conditioned upon the Indemnitee providing the Indemnitor with:
        (x) prompt written notice of any claim (provided that a failure to provide such notice shall only relieve the
        Indemnitor of its indemnity obligations to the extent that the Indemnitor is materially prejudiced by such
        failure); (y) the option to assume sole control over the defense and settlement of any claim (provided that the
        Indemnitee may participate in such defense and settlement at its own expense); and (z) reasonable information
        and assistance in connection with such defense and settlement (at the Indemnitor’s expense). The foregoing
        obligations of Trustle do not apply with respect to the Service or any information, technology, materials or
        data (or any portions or components of the foregoing) to the extent (i) not created or provided by Trustle
        (including without limitation any Customer Data or third party services), (ii) made in whole or in part in
        accordance to Customer specifications, (iii) modified after delivery by Trustle, (iv) combined with other
        products, processes or materials not provided by Trustle (where the alleged Losses arise from or relate to such
        combination), (v) where Customer continues allegedly infringing activity after being notified thereof or after
        being informed of modifications that would have avoided the alleged infringement, or (vi) Customer’s use of the
        Service is not strictly in accordance herewith.
      </p>
      <h4>13. Disclaimer</h4>
      <p>
        EXCEPT AS EXPRESSLY SET FORTH HEREIN, THE SERVICE IS PROVIDED “AS IS” AND “AS AVAILABLE” AND IS WITHOUT WARRANTY
        OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE,
        NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE
        OF PERFORMANCE, USAGE OF TRADE, OR COURSE OF DEALING, ALL OF WHICH ARE EXPRESSLY DISCLAIMED.
      </p>
      <h4>14. Limitation of Liability</h4>
      <p>
        EXCEPT FOR THE PARTIES’ INDEMNIFICATION OBLIGATIONS AND FOR CUSTOMER’S BREACH OF SECTION 8, IN NO EVENT SHALL
        EITHER PARTY, NOR ITS DIRECTORS, OFFICERS, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS, BE
        LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO
        THE SUBJECT MATTER OF THIS AGREEMENT (I) FOR ANY LOST PROFITS, DATA LOSS, COST OF PROCUREMENT OF SUBSTITUTE
        GOODS OR SERVICES, OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER,
        SUBSTITUTE GOODS OR SERVICES (HOWEVER ARISING), (II) FOR ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE
        (REGARDLESS OF THE SOURCE OF ORIGINATION), OR (III) FOR ANY DIRECT DAMAGES IN EXCESS OF (IN THE AGGREGATE) THE
        FEES PAID (OR PAYABLE) BY CUSTOMER TO TRUSTLE HEREUNDER IN THE SIX (6) MONTHS PRIOR TO THE EVENT GIVING RISE TO
        A CLAIM HEREUNDER.
      </p>
      <h4>15. Miscellaneous</h4>
      <p>
        This Agreement represents the entire agreement between Customer and Trustle with respect to the subject matter
        hereof, and supersedes all prior or contemporaneous communications and proposals (whether oral, written or
        electronic) between Customer and Trustle with respect thereto. The Agreement shall be governed by and construed
        in accordance with the laws of the State of California, excluding its conflicts of law rules, and the parties
        consent to exclusive jurisdiction and venue in the state and federal courts located in San Francisco,
        California. All notices under this Agreement shall be in writing and shall be deemed to have been duly given
        when received, if personally delivered or sent by certified or registered mail, return receipt requested; or the
        business day after it is sent, if sent for next day delivery by nationally-recognized overnight delivery
        service. Notices must be sent to the contacts for each party set forth on the Order Form. Either party may
        update its address set forth above by giving notice in accordance with this section. Except as otherwise
        provided herein, no modification or amendment of any provision of this Agreement shall be effective unless
        agreed by both parties in writing, and no waiver of any provision of this Agreement shall be effective unless in
        writing and signed by the waiving party. Except for payment obligations, neither party shall be liable for any
        failure to perform its obligations hereunder where such failure results from any cause beyond such party’s
        reasonable control, including, without limitation, the elements; fire; flood; severe weather; earthquake;
        vandalism; pandemic; accidents; sabotage; power failure; denial of service attacks or similar attacks; Internet
        failure; acts of God and the public enemy; acts of war; acts of terrorism; riots; civil or public disturbances;
        strikes lock-outs or labor disruptions; any laws, orders, rules, regulations, acts or restraints of any
        government or governmental body or authority, civil or military, including the orders and judgments of courts.
        Neither party may assign any of its rights or obligations hereunder without the other party’s consent; provided
        that (i) either party may assign all of its rights and obligations hereunder without such consent to a
        successor-in-interest in connection with a sale of substantially all of such party’s business relating to this
        Agreement, and (ii) Trustle may utilize subcontractors in the performance of its obligations hereunder. No
        agency, partnership, joint venture, or employment relationship is created as a result of this Agreement and
        neither party has any authority of any kind to bind the other in any respect. In any action or proceeding to
        enforce rights under this Agreement, the substantially prevailing party shall be entitled to recover reasonable
        costs and attorneys’ fees. If any provision of this Agreement is held to be unenforceable for any reason, such
        provision shall be reformed only to the extent necessary to make it enforceable. The failure of either party to
        act with respect to a breach of this Agreement by the other party shall not constitute a waiver and shall not
        limit such party’s rights with respect to such breach or any subsequent breaches. This Agreement is the result
        of negotiations among, and has been received by, Trustle and Customer and their respective counsel. Accordingly,
        this Agreement shall be deemed to be the product of all parties hereto, and no ambiguity shall be construed in
        favor of or against Trustle or Customer. The terms, provisions or conditions of any purchase order or other
        business form or written authorization used by Customer will have no effect on the rights, duties or obligations
        of the parties under, or otherwise modify, this Agreement, regardless of any failure of Trustle to object to
        those terms, provisions or conditions.
      </p>
      <span id="bottomTos" className="tr-h-[10px]"></span>
    </>
  );
};

export default EULAInitialContent;
