import React from 'react';

type PropsT = {
  errMsg?: string | React.ReactNode;
};

function Error(props: PropsT) {
  const {errMsg} = props;
  return (
    <div className="tr-flex tr-w-full tr-h-full">
      <div className="m-auto text-center">
        <h2 className="text-muted">Uh Oh!</h2>
        <div>{errMsg ? errMsg : 'An error occured...'}</div>
      </div>
    </div>
  );
}

export default Error;
