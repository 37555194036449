import axios from 'axios';
import React, {useContext, useState, useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import SetupScreenTopByBottom from 'src/components/SetupScreenTopByBottom';
import SetupNav from 'src/components/SetupNav';
import {logger} from 'src/lib';
import _ from 'lodash';
import {GlobalInfoContext} from 'src/context';
import {Confirmation} from '@trustle/component-library';


function MagicLinkVerify() {
  const history = useHistory();
  const {token} = useParams<{token: string}>();
  const [error, setError] = useState<{message?: string}>();
  const [, setSessionActive] = useState(false);
  const [showLogoutWarning, setShowLogoutWarning] = useState(false);
  const globalInfo = useContext(GlobalInfoContext);

  useEffect(() => {
    const checkActiveSession = async () => {
      try {
        const response = await axios.get('/api/auth/info'); // Check if the session is active
        if (response.data && response.data.user) {
          setSessionActive(true);
          setShowLogoutWarning(true);
        } else {
          await proceedWithMagicLink(); // No session active, proceed
        }
      } catch (err) {
        logger.error(err);
        await proceedWithMagicLink(); // Handle as no session and proceed
      }
    };
    void checkActiveSession();
  }, []);

  const proceedWithMagicLink = () => {
    void axios
      .post('/api/magic/conjure', {token})
      .then((res) => {
        const {magicInfo, nextStepUrl} = res.data.uxData;
        globalInfo.set('magicData', magicInfo);
        if (nextStepUrl?.startsWith('http')) {
          window.location.href = nextStepUrl;
        } else if (!_.isNil(nextStepUrl)) {
          history.push(nextStepUrl ?? '/home');
        }
        setError(magicInfo?.error);
      })
      .catch((err: any) => {
        logger.error(err);
        // avoid displaying technical error about response in UI
        err.message = 'An unknown error occurred.';
        setError(err);
      });
  };

  const handleLogoutAndProceed = async () => {
    try {
      await axios.get('/api/auth/logout');
      setShowLogoutWarning(false);
      proceedWithMagicLink();
    } catch (err) {
      logger.error('err', err);
      setError({message: 'Error logging out current session.'});
    }
  };

  if (error) {
    return (
      <>
        <SetupNav />
        <SetupScreenTopByBottom title="You clicked a magic link!" imageUrl="/russel-excited.svg">
          <p>{error?.message ?? 'An unknown error occurred.'}</p>
        </SetupScreenTopByBottom>
      </>
    );
  }

  if (showLogoutWarning) {
    return (
      <>
        <SetupNav />
        <Confirmation
          title="Active session detected"
          confirmButtonLabel="Log out and proceed"
          cancelButtonLabel="Cancel"
          onConfirm={handleLogoutAndProceed}
          onClose={() => setShowLogoutWarning(false)}
        >
          <p>
            You're currently logged in. Continuing with this magic link will log out your current session.
          </p>
        </Confirmation>
      </>
    );
  }

  return <></>;
}

export default MagicLinkVerify;
