import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import {useRootStore} from 'src/lib/hooks';
import {getActions} from 'src/lib';
import {OwnerAccountAction} from 'src/types';
import {IconButton, Modal} from '@trustle/component-library';
import AccessDetailModal from '../Access/AccessDetailModal';
import ConfirmAccountActionModal from '../Account/ConfirmAccountActionModal';
import TaskDetailedView from 'src/views/Tasks/components/TaskDetailedView';
import _ from 'lodash';
import RequestAccessModal from 'src/components/modals/RequestAccessModal';

type PropsT = {
  accessRecord: AccessRecord;
};

const AccessActions = observer(({accessRecord}: PropsT) => {
  const {tasksStore} = useRootStore();
  const [confirmActionTarget, setConfirmActionTarget] = useState<OwnerAccountAction>();
  const [historyModal, setOpenHistoryModal] = useState<boolean>(false);
  const [requestAccessModalVisible, setRequestAccessModalVisible] = useState<boolean>(false);

  const systemConnectionDisabled = accessRecord.forResource.rootResource?.connector?.disabled;

  const actions = systemConnectionDisabled ? [] : getActions(accessRecord);
  const task = accessRecord.getOwnerTaskForUser();

  const items = _.map(actions, (action: OwnerAccountAction) => {
    if (action === OwnerAccountAction.START_REVOKE && accessRecord.isRevokable) {
      return (
        <IconButton
          icon="revoke"
          variant="ternary"
          key={`revoke${accessRecord.id}`}
          data-testid="revoke-icon"
          title="Revoke"
          onClick={() => {
            setConfirmActionTarget(action);
          }}
        />
      );
    }
    if (action === OwnerAccountAction.START_DEPROVISION && accessRecord.isDeprovisionable) {
      return (
        <IconButton
          icon="deprovision"
          variant="ternary"
          onClick={() => {
            setConfirmActionTarget(action);
          }}
          title="Deprovision"
        />
      );
    }
    if (accessRecord.isCancelable) {
      const actionButton =
        action === OwnerAccountAction.ACCEPT
          ? 'approve'
          : action === OwnerAccountAction.START_PROVISION
          ? OwnerAccountAction.START_DEPROVISION
          : action;
      return (
        <IconButton
          icon={actionButton}
          variant="ternary"
          onClick={() => {
            setConfirmActionTarget(action);
          }}
          title={actionButton.toUpperCase()}
        />
      );
    }
    return;
  });

  items.unshift(
    <IconButton
      icon="details"
      variant="ternary"
      key={`history${accessRecord.id}`}
      data-testid={`history${accessRecord.id}`}
      title={'Details'}
      onClick={() => {
        setOpenHistoryModal(true);
      }}
    />
  );
  items.unshift(
    <IconButton
      data-testid={`${accessRecord.forPermission.label}-request-access`}
      key={`permission-action-status${accessRecord.forPermission.id}`}
      title={'Request Access'}
      variant="ternary"
      onClick={(e: any) => {
        e.stopPropagation();
        setRequestAccessModalVisible(true);
      }}
      icon="grantHover"
    />
  );

  if (task) {
    items.push(
      <IconButton
        icon="task"
        variant="ternary"
        data-testid={`task-${task.forAccess?.id}`}
        title={'There is a open task related to this access'}
        onClick={async () => await tasksStore.setSelectedTask(task)}
      />
    );
  }

  return (
    <>
      <div className="tr-flex tr-justify-start tr-items-center  perm-actions-wrapper">{items}</div>
      <AccessDetailModal
        visible={historyModal}
        access={accessRecord}
        closeModal={() => {
          setOpenHistoryModal(false);
        }}
      />
      {confirmActionTarget && (
        <ConfirmAccountActionModal
          accessRecords={[accessRecord]}
          action={confirmActionTarget}
          closeModal={() => {
            setConfirmActionTarget(undefined);
          }}
        />
      )}
      <RequestAccessModal
        onHide={() => setRequestAccessModalVisible(false)}
        permission={accessRecord.forPermission}
        isManaging={false}
        show={requestAccessModalVisible}
      />
      <Modal
        visible={!!tasksStore.selected}
        onClose={() => {
          tasksStore.setSelectedTask(undefined);
        }}
      >
        {tasksStore.selected && <TaskDetailedView accessRequest={tasksStore.selected} />}
      </Modal>
    </>
  );
});

export default AccessActions;
