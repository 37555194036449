import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {UserType} from 'src/types';
import {RowAction, UserChangeRecordT} from './MergeDisplay';
import {Checkbox, Table} from '@trustle/component-library';
import {Icon, Tooltip} from '@trustle/component-library';
import {DepartmentRoleTypeCol, UserTypeCol} from 'src/components/design/tables/SharedColumnDefinitions/AccountColumns';
import {useRootStore} from 'src/lib/hooks';

type PropsT = {
  records: UserChangeRecordT[];
  status: string;
  setRecords: (args: any) => void;
  onError: (error: string | undefined) => void;
};

function MergeDisplayTable(props: PropsT) {
  const {records, status, setRecords, onError} = props;
  const [isChecked, setChecked] = useState<boolean>(false);

  const {currentUser} = useRootStore();

  useEffect(() => {
    onValidateAll();
  }, []);

  const onValidateAll = () => {
    _.each(records, (u) => {
      return onValidate(u);
    });
  };

  const onValidate = (u: UserChangeRecordT | undefined) => {
    if (_.isUndefined(u)) {
      return false;
    }

    if (_.isEmpty(u.email)) {
      onError(`Email is a mandatory field: ${u.firstname} ${u.lastname}`);
      return false;
    }

    if (_.includes([RowAction.NEW, RowAction.CHANGED], u.status) && _.isEmpty(u.firstname)) {
      onError(`First name is a mandatory field: ${u.email}`);
      return false;
    }

    if (_.includes([RowAction.NEW, RowAction.CHANGED], u.status) && _.isEmpty(u.lastname)) {
      onError(`Last name is a mandatory field: ${u.email}`);
      return false;
    }

    if (u.managerEmail === u.email) {
      onError(`Users cannot be their own manager: ${u.email}`);
      return false;
    }

    if (!_.isEmpty(u.type) && !_.includes(_.values(UserType), u.type)) {
      onError(`Invalid user type: ${u.type}`);
      return false;
    }

    if (!_.isEmpty(u.managerEmail)) {
      const manager = _.find(records, {email: u.managerEmail});
      if (!manager) {
        onError(`Could not find manager with the provided email address: ${u.managerEmail}`);
        return false;
      }
    }

    return true;
  };

  const selector = {
    dataField: 'doAction',
    text: 'Do Action',
    sort: false,
    headerStyle: {width: '7%', padding: '0px', 'align-items': 'center'},
    style: {width: '7%', 'text-align': 'center'},
    formatter: (_cell: any, item: any) => {
      if (isOrgOwner(item)) {
        return (
          <Tooltip
            content={<div>{'This is an Organization Owner in Trustle and cannot be disabled by CSV upload.'}</div>}
            className="tr-font-normal"
          >
            <Checkbox
              data-testid={`activate-${item.email}`}
              id={`activate-${item.email}-${item.id}`}
              checked={false}
              disabled={true}
            />
          </Tooltip>
        );
      }

      return (
        <div className="tr-justify-center">
          <Checkbox
            data-testid={`activate-${item.email}`}
            id={`activate-${item.email}-${item.id}`}
            checked={item.doAction}
            onChange={(_e) => checkOne(item.email)}
          />
        </div>
      );
    },
    headerFormatter: (_column: any, _index: number) => {
      return (
        <div className={`sorting`}>
          <a
            href="#0"
            onClick={(e) => {
              e.preventDefault();
              checkAll(!isChecked);
            }}
          >
            {`${!isChecked ? 'All' : 'None'}`}
          </a>
        </div>
      );
    },
  };

  const columns = [
    UserTypeCol,
    {
      dataField: 'firstname',
      text: 'User / Email',
      headerStyle: {width: '15em', 'text-align': 'left'},
      style: {width: '15em', 'text-align': 'left'},
      sort: true,
      formatter: (_field: string, record: any) => {
        const {firstname, lastname, email} = record;

        return (
          <div className="tr-my-1">
            <div className="font-weight-bold tr-mb-1`">{`${firstname} ${lastname}`}</div>
            <div>{email}</div>
          </div>
        );
      },
    },
    {...DepartmentRoleTypeCol, sort: true},
    {
      dataField: 'managerEmail',
      sort: true,
      text: 'Manager',
      headerStyle: {width: '9em', 'text-align': 'left'},
      style: {width: '9em', 'text-align': 'left'},
      formatter: (field: string, _record: any) => {
        if (_.isEmpty(field)) {
          return <Icon type="emptyData" title="No data" size="sm" />;
        }
        return <div>{field}</div>;
      },
    },
    {
      dataField: 'allowLogin',
      text: 'Can Login',
      sort: true,
      style: {width: '4rem'},
      headerStyle: {width: '4rem'},
      formatter: (field: boolean, _record: any) => {
        return <Checkbox checked={field} disabled />;
      },
    },
  ];

  const filteredRecords = records.filter((r) => r.status === status);

  const checkAll = (value: boolean) => {
    setChecked(value);
    setRecords((old: UserChangeRecordT[]) =>
      old.map((record: UserChangeRecordT) => {
        if (record.status === status && !isOrgOwner(record)) {
          record.doAction = value;
        }
        return record;
      })
    );
  };

  const checkOne = (email: string) => {
    let user = undefined;

    setRecords((old: UserChangeRecordT[]) =>
      old.map((v: UserChangeRecordT) => {
        if (v.email === email && !isOrgOwner(v)) {
          v.doAction = !v.doAction;
          user = v;
        }
        return v;
      })
    );

    onValidate(user);
  };

  const isOrgOwner = (u: any) => u.email === currentUser.email && currentUser.isOrgOwner;

  return (
    <div className="my-3">
      <Table
        data={filteredRecords.map((rec: UserChangeRecordT) => ({...rec, id: rec.email}))}
        columns={status === RowAction.MISSING ? [selector, ...columns] : columns}
        tableKey={'mergetabledispla'}
        striped={false}
        wrapperClasses="rounded-xl border border-black rounded"
        bordered={false}
        rowClasses={(row: UserChangeRecordT, _rowIndex: number) =>
          isOrgOwner(row)
            ? 'authz-row'
            : (isChecked || row.doAction) && row.action === 'delete'
            ? 'bg-lightblue-tab'
            : ''
        }
      />
    </div>
  );
}

export default MergeDisplayTable;
