import {useState} from 'react';
import axios from 'axios';

type AdvancedFiltersResponse = {
  teams: any[];
  departments: any[];
  roles: any[];
  titles: any[];
  managers: any[];
};

export function useAdvancedFilters() {
  const [data, setData] = useState<AdvancedFiltersResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BASE_URL = '/api/v2/advanced_filters';

  const fetchAdvancedFilters = async () => {
    setLoading(true);
    try {
      const response = await axios.get<AdvancedFiltersResponse>(BASE_URL);
      const responseData = response.data;
      setData(responseData);
    } catch (err: any) {
      // TODO: Implement handling error
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {data, loading, error, fetchAdvancedFilters};
}
