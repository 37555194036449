import _ from 'lodash';
import {AwsResourceType, ConnectorsDataDataTypeE} from 'src/types';
import ViewAwsPolicies from './ViewAwsPolicies';
import {Resource} from 'src/stores/domainObjects/Resource';
import AwsViewRoleMetadata from './AwsViewRoleMetadata';
import {PermTabModeE} from 'src/views/Resource/Setup/PermissionDetailsModal';
import {Permission} from 'src/stores/domainObjects/Permission';

type PropsT = {
  connectorsData: any;
  permission: Permission;
  resource: Partial<Resource>;
  system: Resource;
};

const AwsPermissionsMetadata = function AwsPermissionsMetadata(
  props: PropsT
):
  | null
  | Record<string, unknown>
  | _.Dictionary<{key: PermTabModeE; element: JSX.Element | {key: string; value: string | Date | undefined}[]}> {
  const {connectorsData, permission, resource, system} = props;
  const isRole: boolean = _.includes([AwsResourceType.ROLES], resource.foreignId);

  const showEntityData = () => {
    if (isRole) {
      return AwsViewRoleMetadata({
        connectorsData: connectorsData,
        permission: permission,
        system: system,
      });
    }

    const permissionMetadata = _.find(connectorsData, (cd) => {
      return cd.dataType === ConnectorsDataDataTypeE.ENTITY_DATA;
    });
    if (_.isEmpty(permissionMetadata)) {
      return null;
    }

    return {
      [PermTabModeE.GENERAL]: [
        {key: 'ARN', value: _.get(permissionMetadata, 'data.arn', 'Not available')},
        {key: 'Id', value: _.get(permissionMetadata, 'data.id', 'Not available')},
      ],
    };
  };
  const isManagedPolicy: boolean = _.includes(['Managed policies'], resource?.name);
  const isInlinePolicy: boolean = _.includes(['Inline policies'], resource?.name);

  if (_.isEmpty(connectorsData)) {
    return null;
  }

  const viewAwsPolicies = ViewAwsPolicies({
    isRole,
    isManagedPolicy: isManagedPolicy,
    isInlinePolicy: isInlinePolicy,
    connectorsData: connectorsData,
    permission: permission,
    system: system,
  });
  const entityData = showEntityData();
  return {...entityData, ...viewAwsPolicies};
};

export default AwsPermissionsMetadata;
