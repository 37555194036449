import _ from 'lodash';
import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {useQueryStrings, useRootStore} from 'src/lib/hooks';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import {Resource} from 'src/stores/domainObjects/Resource';
import {User} from 'src/stores/domainObjects/User';
import {filterItems} from './utils';
import {getResourcesTemplate} from '../../../connectors/manifests';
import SystemFilters from './SystemFilters';
import {FilterOption, SortOption, SortState} from './shared';

export type TreeTabPanelT = {
  resources: Resource[];
  system?: Resource;
  canEditResource: boolean;
  prefilteredAccesses?: AccessRecord[];
  user?: User;
  hideRoots?: boolean;
  hideFilters?: boolean;
  fixedFilters?: Record<string, string>;
  hidePermissionsWithoutAccesses?: boolean;
  showTabs?: boolean;
  disableFilters?: boolean;
  showInactiveSystemFilter?: boolean;
  banner?: React.ReactElement | null;
  allowExpand?: boolean;
  availableFilters?: FilterOption[];
  availableSorts?: SortOption[];
  displayActiveAccesses?: boolean;
};

const TreeTabPanel = observer((props: TreeTabPanelT) => {
  const {
    resources = [],
    user,
    system,
    prefilteredAccesses = [],
    fixedFilters,
    disableFilters = false,
    showInactiveSystemFilter,
    banner = null,
    showTabs = false,
    canEditResource,
    displayActiveAccesses = false,
  } = props;

  const {tasksStore, usersStore} = useRootStore();
  const [filters, setFilters] = !disableFilters ? useQueryStrings({...fixedFilters}) : useState({});

  const [query, setQuery] = useState('');
  const accessRequests = tasksStore.accessRequestTasks;

  const [sortBy, setSortBy] = useState<SortState>();
  const firstResource: Resource | undefined = system ?? _.first(resources);
  const template = getResourcesTemplate(firstResource?.type as string);

  const Wrapper = template.Wrapper!.default!.main!;
  const isPrefiltered = _.size(prefilteredAccesses) > 0;

  useEffect(() => {
    if (_.size(query) > 3 && system && !isPrefiltered) {
      void system.fetchMatchingAccesses(query);
    }
  }, [query]);

  const allNestedResources = _.uniqBy(
    [
      ...resources,
      ...resources.flatMap((r) => {
        return r ? r.allNestedResources : [];
      }),
    ],
    'id'
  );

  const allPermissions = [
    ...(system ? system.permissions : []),
    ...resources.flatMap((r) => {
      return r ? r.allNestedPermissions : [];
    }),
  ];

  let pids: string[] = [];

  if (isPrefiltered) {
    pids = _.map(prefilteredAccesses, (access) => {
      return access.pid;
    });
  }

  const allAccesses = user
    ? resources.flatMap((r) => r?.getUserAccesses(user.id) || [])
    : system
    ? system.allNestedAccessRecords
    : [];

  const [filteredResources, filteredPerms, filteredAccess] = filterItems(
    isPrefiltered
      ? _.filter(allNestedResources, (res: Resource) => {
          return _.some(res.allNestedPermissions, (perm) => {
            return pids.includes(perm.id);
          });
        })
      : allNestedResources,
    allPermissions,
    isPrefiltered ? prefilteredAccesses : allAccesses,
    accessRequests,
    fixedFilters ? {...filters, ...fixedFilters} : filters,
    query,
    prefilteredAccesses,
    usersStore,
    canEditResource,
    displayActiveAccesses
  );

  return (
    <div key={`tree-view-${system?.id}`} data-testid={`tree-view-${system?.id}`}>
      <SystemFilters
        {...props}
        setFilters={setFilters}
        filters={filters}
        allNestedResources={allNestedResources}
        allPermissions={allPermissions}
        setQuery={setQuery}
        sortBy={sortBy}
        setSortBy={setSortBy}
        showInactiveSystemFilter={showInactiveSystemFilter}
        showTabs={showTabs}
      />
      {banner && <div className="tr-py-3">{banner}</div>}
      <Wrapper
        {..._.omit(props, 'resources')}
        resources={_.filter(props.resources, (r) => {
          return filteredResources.includes(r.id);
        })}
        visibleItems={{resources: filteredResources, permissions: filteredPerms, accesses: filteredAccess}}
        filters={filters}
        setFilters={setFilters}
        sortBy={sortBy}
        showTabs={showTabs}
      />
    </div>
  );
});

export default TreeTabPanel;
