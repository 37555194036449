import React from 'react';
import OnboardingWrapper from 'src/views/OnboardingWrapper';
import ResetPasswordForm from './ResetPasswordForm';

function ResetPassword() {
  const title = 'Set your password';

  return (
    <OnboardingWrapper>
      <h4 className="card-title form-title">{title}</h4>
      <div className="card-text">
        <ResetPasswordForm />
      </div>
    </OnboardingWrapper>
  );
}

export default ResetPassword;
