import _ from 'lodash';
import React from 'react';
import {SubmitButton} from 'src/components/design';
import {Formik, FormikForm} from 'src/components/design/formik';
import {useRootStore} from 'src/lib/hooks';
import {SamlConfigurationT, AuthenticationPolicyT} from './authenticationTypes';
import {Button, Select, TextAreaInput, TextInput} from '@trustle/component-library';
import {Field} from 'formik';
import {observer} from 'mobx-react';

export const SAMLSetupForm: React.ElementType = observer(
  (props: {
    policy: AuthenticationPolicyT;
    onSave: (policy: AuthenticationPolicyT, samlConfig?: SamlConfigurationT) => void;
    onClose: () => void;
  }) => {
    const {org} = useRootStore();

    if (_.isNil(org)) {
      return <></>;
    }

    const {onSave} = props;
    const nameidOptions = [
      {value: 'unspecified', label: 'Unspecified'},
      {value: 'emailAddress', label: 'Email Address'},
      {value: 'x509SubjectName', label: 'x509 Subject Name'},
      {value: 'persistent', label: 'Persistent'},
      {value: 'transient', label: 'Transient'},
    ];
    const authnContextOptions = [
      {value: 'password', label: 'Password'},
      {value: 'internetProtocol', label: 'InternetProtocol'},
      {value: 'internetProtocolPassword', label: 'InternetProtocolPassword'},
      {value: 'kerberos', label: 'Kerberos'},
    ];

    const {settings} = props.policy;
    const config = settings || {
      audience: org.getOrgRedirectUrl(''),
      nameidFormat: 'emailAddress',
      authnContextClass: 'password',
    };
    return (
      <Formik
        enableReinitialize
        initialValues={{
          idpIssuer: config.idpIssuer || '',
          idpCert: config.idpCert || '',
          idpLoginUrl: config.idpLoginUrl || '',
          authnContextClass: _.find(authnContextOptions, {value: config.authnContextClass}),
          nameidFormat: _.find(nameidOptions, {value: config.nameidFormat}),
          audience: config.audience || '',
          acs: `${config.audience}/api/auth/saml/login/callback`,
          requireEncryption: false,
          signRequest: false,
        }}
        onSubmit={(values: any) => {
          values.authnContextClass = values.authnContextClass.value;
          values.nameidFormat = values.nameidFormat.value;
          onSave(props.policy, _.omit(values, ['acs']));
        }}
      >
        {({isSubmitting}) => (
          <FormikForm>
            <div className="tr-flex tr-flex-col">
              <div className="text-right vertical-align">
                <a
                  href={'/docs/authentication/configure_saml'}
                  target="_blank"
                  rel="noreferrer"
                  className="text-underline mr-3"
                >
                  <p style={{fontSize: 'small'}}>Need help? View Documentation Here</p>
                </a>
              </div>
              <Field
                component={TextInput}
                label="Service Provider (SP) Entity ID"
                id="audience"
                name="audience"
                disabled={true}
              />
              <Field
                component={TextInput}
                label="Assertion Consumer Service (ACS)"
                id="acs"
                name="acs"
                disabled={true}
              />
              <Field
                component={TextInput}
                label="Identity Provider (IdP) Issuer"
                id="idpIssuer"
                name="idpIssuer"
                placeholder="https://example.idp.com"
                required
              />
              <Field
                component={TextAreaInput}
                label="IdP Certificate"
                rows={8}
                name="idpCert"
                data-cy="idpCert"
                id="idpCert"
                required
              />
              <Field
                component={TextInput}
                label="IdP Login URL"
                placeholder="https://example.idp.com/sso"
                id="idpLoginUrl"
                name="idpLoginUrl"
                required
              />
              <div className="tr-grid tr-gap-4 tr-grid-cols-2">
                <Field
                  component={Select}
                  label="AuthN Context Class"
                  name="authnContextClass"
                  options={authnContextOptions}
                  disabled={true}
                  required
                />

                <Field
                  component={Select}
                  label="Name ID Format"
                  name="nameidFormat"
                  options={nameidOptions}
                  disabled={true}
                  required
                />
              </div>

              <div className="tr-flex tr-justify-between tr-mt-5 tr-mb-3">
                <Button onClick={props.onClose}>Cancel</Button>
                <SubmitButton inSubmit={isSubmitting} label="Save" />
              </div>
            </div>
          </FormikForm>
        )}
      </Formik>
    );
  }
);
