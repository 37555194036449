import React from 'react';
import PermissionActionModal from 'src/components/PermissionActionModal';
import {AccountAccessType} from 'src/types';
import {observer} from 'mobx-react-lite';
import {Account} from 'src/stores/domainObjects/Account';
import {Formik, Form, Field} from 'formik';
import {Button, Icon, Select, TextInput, Tooltip, UserAvatarAndName} from '@trustle/component-library';
import {useRootStore} from 'src/lib/hooks';
import {User} from 'src/stores/domainObjects/User';
import {object, string} from 'yup';
import {formatUserName} from 'src/lib';

type EditAccountModalProps = {
  closeModal: any;
  userAccount: Account;
  isConnectedSystem: boolean;
};

const userTypeOptions = [
  {label: 'Personal Account', value: 'personal'},
  {label: 'Service Account', value: 'service'},
];

const EditAccountModal = observer((props: EditAccountModalProps) => {
  const {closeModal, userAccount} = props;
  const {usersStore, newResourceStore} = useRootStore();
  const resource = userAccount.rid ? newResourceStore.resourceMap[userAccount.rid] : null;

  return (
    <PermissionActionModal title="Edit" closeModal={closeModal}>
      <Formik<{accountType: AccountAccessType; account: string; uid: string}>
        initialValues={{
          account: userAccount.account,
          accountType: userAccount.accountType ?? AccountAccessType.PERSONAL,
          uid: userAccount.uid!,
        }}
        validationSchema={object().shape({
          account: string().required('Please provide an account name'),
          uid: string(),
        })}
        onSubmit={(values) => {
          void userAccount.edit(values);
          closeModal();
        }}
      >
        {({isValid, dirty}) => (
          <Form>
            <Field
              component={TextInput}
              label={
                <>
                  Account Name
                  <Tooltip
                    content={
                      <div>
                        Represents the account in the connected system. It's the user account/principal in the connected
                        system, that is connected into a Trustle User. Learn more in the{' '}
                        <a target="_blank" rel="noreferrer" href="https://learn.trustle.com/">
                          Docs
                        </a>
                      </div>
                    }
                  >
                    <Icon type="moreInfo" size="sm" />
                  </Tooltip>
                </>
              }
              name="account"
              subdescription="Represents the account in the connected system"
              disabled={props.isConnectedSystem}
            />

            <Field component={Select} label="Account Type" name="accountType" options={userTypeOptions} />
            <Field
              component={Select}
              label="Trustle Username"
              name="uid"
              options={usersStore.activeUsers}
              formatOptionLabel={(user: User) => {
                const role = resource ? resource.getResourceRole(user) : '';

                return (
                  <UserAvatarAndName
                    displayName={formatUserName(user)}
                    isOwner={user.isOrgOwner}
                    subtitle={role}
                    size="xs"
                  />
                );
              }}
              filterOption={(option: {data: User}, searchText: string) => {
                const {firstname, lastname, email} = option.data;
                return `${firstname?.toLowerCase() ?? ''}${lastname?.toLowerCase() ?? ''}${
                  email?.toLowerCase() ?? ''
                }`.includes(searchText);
              }}
            />
            <Button className="tr-block tr-ml-auto" type="submit" disabled={!isValid || !dirty}>
              Update
            </Button>
          </Form>
        )}
      </Formik>
    </PermissionActionModal>
  );
});

export {EditAccountModal};
