import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {Link, useHistory} from 'react-router-dom';
import {LegalAgreementT} from 'src/context';
import {FacetIdEnum, LegalDocName} from 'src/types';
import {LegalAgreementModal} from 'src/views/signup/LegalAgreementModal';
import axios from 'axios';
import {useToasts} from 'react-toast-notifications';
import {formatUnixDaysFromNow, logger, useFeatureFlags} from 'src/lib';
import {ConsentAction} from '../design/CookiesConsentPopup';
import {Icon, Modal, Popover} from '@trustle/component-library';
import {useCookies} from 'react-cookie';
import {useRootStore} from 'src/lib/hooks';
import Sidebar from './SideBar';
import {observer} from 'mobx-react-lite';
import moment from 'moment';
import ProfileDropdown from '../navigation/ProfileDropdown';
import {Task} from 'src/stores/domainObjects/Task';
import TaskCardItem from 'src/views/Tasks/components/TaskCardItem';
import TaskDetailedView from 'src/views/Tasks/components/TaskDetailedView';
import {Breadcrumbs} from '../navigation/Breadcrumb';

const PackageBadge = observer(() => {
  const {org, currentUser} = useRootStore();
  const isFreePlan = org.facetId === FacetIdEnum.FREE_AWS;
  const plan = org.subscriptionPlan ?? org.package;
  const trialEnd = org.subscription?.trial_end;
  const isClickable = org.subscriptionPlan || isFreePlan;

  if (currentUser === undefined) {
    return <></>;
  }

  const content = (
    <>
      <span className="tr-text-trustle-blue tr-font-bold">
        {isFreePlan ? 'Free Version' : plan?.name}
        {currentUser.isOrgOwner && isFreePlan && <span className="tr-underline tr-ml-2">Upgrade</span>}
      </span>
      {trialEnd && trialEnd > moment().unix() && (
        <div className="tr-bg-trustle-primary tr-text-white tr-text-sm tr-font-bold tr-rounded-2xl tr-ml-2 tr-px-4 tr-py-1 tr-flex tr-items-center">
          <Icon type="time" size="sm" />
          <span className="tr-ml-1">{`${formatUnixDaysFromNow(trialEnd)} Days Remaining`}</span>
        </div>
      )}
    </>
  );

  return (
    <>
      {isClickable ? (
        <Link className="tr-flex tr-items-center hover:tr-no-underline tr-mr-4" to="/admin/billing/plan">
          {content}
        </Link>
      ) : (
        <div className="tr-flex tr-items-center tr-mr-4">{content}</div>
      )}
    </>
  );
});

const NotificationsPopover = observer(() => {
  const {tasksStore} = useRootStore();
  const [selectedTask, setSelectedTask] = useState<Task | undefined>(undefined);

  const tasks = _.compact(tasksStore.openTasksAndRequests).map((accessRequest: Task, index: number) => (
    <TaskCardItem
      key={index + accessRequest.createdAt + accessRequest.id}
      accessRequest={accessRequest}
      data-testid={`card-${accessRequest.id}`}
      onSelectedTaskChange={() => setSelectedTask(accessRequest)}
    />
  ));

  return (
    <Popover className="tr-mx-4">
      <div className="tr-relative">
        <Popover.Button as={Icon} type="bell" style={{fill: tasks.length > 0 ? 'currentColor' : 'none'}} />
        {tasks.length > 0 && (
          <span className="tr-absolute tr-bg-red-800 tr-w-2 tr-h-2 tr-rounded-full tr-top-0 tr-right-0" />
        )}
      </div>
      <Popover.Panel className="tr-bg-white tr-right-0 tr-mt-6 tr-max-h-[90vh] blue-scrollbar">
        <div className="tr-p-4 tr-flex tr-justify-between tr-items-center">
          <h2 className="tr-text-lg tr-m-0">Tasks</h2>
          <Link to="/tasks">see all</Link>
        </div>
        {tasks.length > 0 ? (
          tasks
        ) : (
          <div className="tr-border-solid tr-border-0 tr-border-t tr-p-4 tr-flex tr-flex-col tr-items-center">
            <img width="250" src="/russel-celebrating.svg" alt="" />
            <p className="tr-text-xl">Great job!</p>
            <p className="tr-text-lg tr-font-bold">You've Completed Your Tasks.</p>
          </div>
        )}
      </Popover.Panel>
      <Modal visible={selectedTask !== undefined} onClose={() => setSelectedTask(undefined)}>
        {selectedTask && <TaskDetailedView accessRequest={selectedTask} />}
      </Modal>
    </Popover>
  );
});

type PropsT = {
  children: React.ReactNode;
};
export default function AppLayout(props: PropsT) {
  const {addToast} = useToasts();

  const history = useHistory();
  const {currentUser, org} = useRootStore();
  const [cookies] = useCookies();
  const featureFlagViewer = useFeatureFlags();
  const eulaModalEnabled = featureFlagViewer.isEnabled('enable_eula_agreement');

  const [displayLicense, setDisplayLicense] = useState<boolean>();
  const checkConsentCookie = async () => {
    const consent = cookies['cookies-consent'];
    const explicitConsent = consent?.action === ConsentAction.ACCEPT;

    if (!_.isNil(currentUser)) {
      try {
        await axios.post('/api/auth/user_cookies_consent', {
          categories: consent?.categories,
          widthdrawConsent: !explicitConsent,
        });
      } catch (err: any) {
        logger.error('There was an issue while saving the cookies consent:', err);
      }
    }
  };

  useEffect(() => {
    void checkConsentCookie();

    const agreements: LegalAgreementT[] = org?.agreements ?? [];

    const termsAgreed = _.some(agreements, {docName: LegalDocName.TERMS_OF_SERVICE});
    const privacyPolicyAgreed = _.some(agreements, {docName: LegalDocName.PRIVACY_POLICY});
    const EULAAgreed = _.some(agreements, {docName: LegalDocName.EULA});

    // TODO (GO): see TRUS-2500
    setDisplayLicense(!termsAgreed || !privacyPolicyAgreed || !EULAAgreed);
  }, []);

  const onResponse = async (accepted: boolean) => {
    if (!accepted) {
      addToast('User agreement is required in order to use this site.', {appearance: 'error', autoDismiss: false});

      setTimeout(() => {
        history.push('/logout');
      }, 3000);
    } else {
      try {
        await axios.post('/api/auth/user_agreement');

        setDisplayLicense(false);
      } catch (err: any) {
        logger.error('There was an issue while saving the agreement:', err);
        addToast('There was an issue while saving the agreement', {appearance: 'error', autoDismiss: true});
      }
    }
  };

  return (
    <div className="tr-flex tr-h-screen">
      <Sidebar />
      <main className="tr-grow tr-overflow-auto tr-relative">
        <div className="tr-sticky tr-top-0 tr-w-full tr-bg-white tr-z-40 tr-shadow-xl">
          <div className="container tr-flex tr-justify-between tr-items-center tr-h-14">
            <Breadcrumbs />
            <div className="tr-flex tr-items-center">
              <PackageBadge />
              <NotificationsPopover />
              <ProfileDropdown className="tr-text-black" toggleColor="black" />
            </div>
          </div>
        </div>
        {eulaModalEnabled && displayLicense && <LegalAgreementModal onResponse={onResponse} />}
        {props.children}
      </main>
    </div>
  );
}
