import React, {useMemo, useState} from 'react';
import {observer} from 'mobx-react-lite';
import {ConnectionServiceE} from 'src/types';
import {Account} from 'src/stores/domainObjects/Account';
import {useQueryStrings, useRootStore} from 'src/lib/hooks';
import {
  ConnectedAppsCol,
  FlaggedForReviewColumn,
  IssueCol,
  LastActivityColumn,
  LastLoginColumn,
  MultipleActionsCol,
  NumAccessesLinkCol,
  RiskScoreCol,
  StatusColumn,
  UsageCol,
  UserAccountColumn,
} from 'src/components/design/tables/SharedColumnDefinitions/AccountColumns';
import _ from 'lodash';
import AccountDetailModal, {TabModeE} from './AccountDetailsModal';
import {Checkbox, ColumnDescription, Confirmation, Table} from '@trustle/component-library';
import {Resource} from 'src/stores/domainObjects/Resource';
import {AccountTabTypeE, EmptyAccountsTab} from './ResourceAccounts';
import {TableSetupEnum} from 'src/stores/userStore';
import {getAccountsTemplate} from 'src/connectors/manifests';

const LinkedAccountsTable = observer(({accounts, system}: {accounts: Account[]; system: Resource}) => {
  const {accountsStore} = useRootStore();
  const [filters, setFilters] = useQueryStrings();
  const [selected, setSelected] = useState<string[]>([]);
  const [confirmModalShowing, setConfirmModalShowing] = useState(false);

  const preSelectedAccount = accountsStore.accountsMap[filters['Account Name']];
  const [detailsModal, setDetailsModal] = useState<{account: Account; mode: TabModeE} | undefined>(
    filters.open && preSelectedAccount ? {account: preSelectedAccount, mode: TabModeE.SERVICE_USAGE} : undefined
  );

  if (accounts.length === 0) {
    return <EmptyAccountsTab tab={AccountTabTypeE.ASSIGNED} />;
  }

  const columns = useMemo(() => {
    const isAWSConnection = system.type === ConnectionServiceE.AWS;
    const isGAPPSConnection = system.type === ConnectionServiceE.GAPPS;

    const template = getAccountsTemplate(system?.type as string);
    const LinkedAccountsTable = template!.Linked ? template!.Linked({accounts, system}) : [];

    const defaultCols: ColumnDescription<Account>[] = [
      FlaggedForReviewColumn,
      StatusColumn,
      UserAccountColumn({}),
      NumAccessesLinkCol,
      {
        ...ConnectedAppsCol,
        hidden: !isGAPPSConnection,
        formatExtraData: {
          onClick: (account: Account) => setDetailsModal({account, mode: TabModeE.CONNECTED_APPLICATIONS}),
        },
      },
      {
        ...RiskScoreCol,
        hidden: !isAWSConnection,
        formatExtraData: {
          onClick: (account: Account) => setDetailsModal({account, mode: TabModeE.RISK_REPORT}),
        },
      },
      {
        ...UsageCol,
        formatExtraData: {
          onClick: (account: Account) => setDetailsModal({account, mode: TabModeE.SERVICE_USAGE}),
        },
        hidden: !system.retrieveUsageDataEnabled,
      },
      {
        ...LastActivityColumn,
        hidden:
          system.type !== ConnectionServiceE.GITHUB &&
          system.type !== ConnectionServiceE.AWS &&
          system.type !== ConnectionServiceE.M365,
      },
      {
        ...IssueCol,
        formatExtraData: {
          onClick: (account: Account) => setDetailsModal({account, mode: TabModeE.ACCOUNT_SECURITY}),
        },
        hidden: !system.retrieveUsageDataEnabled,
      },
      {
        ...LastLoginColumn,
        hidden:
          system.type === ConnectionServiceE.GITHUB ||
          system.type === ConnectionServiceE.AWS ||
          system.type === ConnectionServiceE.M365,
      },

      {...MultipleActionsCol, hidden: !system.userIsOwner},
    ];

    const enabledColumns: ColumnDescription<Account>[] = _.map(
      defaultCols,
      (col: ColumnDescription<Account>): ColumnDescription<Account> => {
        return {...col, hidden: col.hidden || !LinkedAccountsTable.includes(col.dataField)};
      }
    );
    return enabledColumns;
  }, [system?.type]);

  return (
    <>
      <Table
        tableKey={TableSetupEnum.LINKED_ACCOUNTS}
        data={accounts}
        selectRow={{
          hideSelectColumn: !system.userIsOwner,
          mode: 'checkbox',
          style: {background: 'rgba(0,81,155,0.1)'},
          selected,
          onSelect: (account: Account, isSelect: boolean) =>
            setSelected(isSelect ? _.union(selected, [account.id]) : _.without(selected, account.id)),
          onSelectAll: (isSelect: boolean, accounts: Account[]) =>
            setSelected(isSelect ? accounts.map((a) => a.id) : []),
          selectionRenderer: ({rowIndex}) => (
            <Checkbox
              id={`activate-${accounts[rowIndex].id}`}
              checked={selected.includes(accounts[rowIndex].id)}
              className="tr-w-4 tr-h-4 tr-rounded-[1px] tr-border-2 tr-border-neutral-700 tr-cursor-pointer"
            />
          ),
          selectionHeaderRenderer: ({indeterminate, checked}: {indeterminate: any; checked: boolean}) => {
            return (
              <Checkbox
                id={`activate-accounts-all`}
                checked={checked}
                ref={(input) => {
                  if (input) {
                    input.indeterminate = indeterminate;
                  }
                }}
                className="tr-w-4 tr-h-4 tr-rounded-[1px] tr-border-2 tr-border-neutral-700 tr-cursor-pointer"
              />
            );
          },
        }}
        bulkActionBtnProps={{
          btnLabel: `Unlink ${selected.length} Account${selected.length !== 1 ? 's' : ''}`,
          onClick: () => setConfirmModalShowing(true),
        }}
        columns={columns}
        rowClasses={(row: Account) => (row.isAuthzOwner ? 'authz-row' : '')}
        striped={false}
        wrapperClasses="rounded-xl border border-black rounded"
        bordered={false}
      />
      {confirmModalShowing && (
        <Confirmation
          onConfirm={async () => {
            await accountsStore.unassignAccounts(selected.map((id) => accountsStore.accountsMap[id]));
            setSelected([]);
            setConfirmModalShowing(false);
            // refresh();
          }}
          onClose={() => {
            setSelected([]);
            setConfirmModalShowing(false);
            // refresh();
          }}
          title="Confirm Unlinking"
        >
          <div>{`This action will remove the reference to the user from the ${
            selected.length > 1 ? `${selected.length} selected accounts` : 'selected account'
          }.`}</div>
        </Confirmation>
      )}
      {detailsModal && (
        <AccountDetailModal
          userAccount={detailsModal.account}
          mode={detailsModal.mode}
          closeModal={() => {
            setDetailsModal(undefined);
            if (filters['open']) {
              setFilters(_.omit(filters, 'open'));
            }
          }}
        />
      )}
    </>
  );
});

export default LinkedAccountsTable;
