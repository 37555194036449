import {Button, Modal} from '@trustle/component-library';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {Resource} from '../../stores/domainObjects/Resource';

export function SuggestAuthorityConfigOnboardModal({resource}: {resource: Resource}) {
  const [show, setShow] = useState(true);
  const history = useHistory();

  const handleSetup = () => {
    setShow(false);
    history.push(`/resource/manage/${resource.id}/settings/authority_settings`);
  };

  return (
    <Modal
      visible={show}
      onClose={() => setShow(false)}
      width="lg"
      title={
        <div className="tr-flex tr-items-center tr-justify-center tr-text-trustle-primary">
          <h1>{`Success! ${resource.typeDisplayName} is Connected!`}</h1>
        </div>
      }
    >
      <div className="tr-flex tr-flex-col tr-items-center tr-justify-center tr-text-trustle-primary">
        <h2>Did you know that you can Create Trustle Users via this system?</h2>
        <p className="tr-text-center">
          Setting up this system as your Trustle User Authority will allow Trustle
          <br />
          to automatically make updates to Trustle Users.
          <br />( ie. manager changes, email, and Okta Account status).
        </p>
      </div>
      <div className="tr-flex tr-flex-col tr-items-center tr-gap-2">
        <Button variant="primary" label="Setup" onClick={() => handleSetup()} />
        <Button variant="ternary" label="No thanks, Maybe Later" onClick={() => setShow(false)} />
      </div>
    </Modal>
  );
}
