import React, {useEffect, useState} from 'react';
import {ErrorMessage, Field, Formik} from 'formik';
import * as Yup from 'yup';
import {
  Alert,
  Button,
  FileInput,
  Loading,
  NumberInput,
  TextAreaInput,
  TextInput,
  PasswordInput,
} from '@trustle/component-library';
import {FormikForm} from 'src/components/design/formik';
import {ConnectionInfoT, TestConnectionResponseT} from 'src/types';
import _ from 'lodash';
import {ConnectorSchema} from '../schemas/default';
import {FormFieldTypes} from '../common/types';
import {useConnectionCredentials} from '../../lib/hooks';
import {useSystemSetup} from 'src/lib/hooks/useSystemSetup';

const FormFields = {
  text: TextInput,
  email: TextInput,
  password: PasswordInput,
  textArea: TextAreaInput,
  number: NumberInput,
  file: FileInput,
};

export interface FormField {
  name: string;
  id: string;
  placeholder?: string;
  type: FormFieldTypes;
  label: string;

  required?: boolean;
}

interface FormData {
  [key: string]: any;
}

function ManifestForm(props: {manifest: ConnectorSchema; nextStep: any; connectionInfo: ConnectionInfoT}) {
  const {manifest, connectionInfo} = props;

  const [testConnectionResult, setTestConnectionResult] = useState<TestConnectionResponseT>();
  const [testConnectionInProgress, setTestConnectionInProgress] = useState<boolean>(false);
  const [saveConnectionInProgress, setSaveConnectionInProgress] = useState<boolean>(false);
  const [formFilled, setFormFilled] = useState(false);

  const [validationSchema, setValidationSchema] = useState<Yup.ObjectSchema<any> | null>(null);
  const {credentials, testConnection: testConnectionAPI} = useConnectionCredentials();
  const {systemSetup, updateSetup} = useSystemSetup(connectionInfo.connection.id);
  const [formData] = useState<FormData>(systemSetup ? {...systemSetup} : {});

  useEffect(() => {
    if (manifest) {
      const schemaFields: any = {};

      manifest.auth?.fields &&
        manifest.auth?.fields?.forEach((field: FormField) => {
          if (field.required) {
            schemaFields[field.name] = Yup.string().required(`${field.label} is mandatory`);
          } else {
            schemaFields[field.name] = Yup.string();
          }
          formData[field.name] = '';
          if ((field.type as FormFieldTypes) === 'email') {
            schemaFields[field.name] = schemaFields[field.name].email(`${field.label} should be a valid email address`);
          }
        });
      //Extra default values
      formData.accessKeyRotation = {
        durationValue: _.get(connectionInfo, 'connection.accessKeyRotationValue', 3),
        durationUnit: _.get(connectionInfo, 'connection.accessKeyRotationUnit', 'month'),
      };

      const schema = Yup.object().shape(schemaFields);

      setValidationSchema(schema);
    }
  }, [manifest]);

  if (!manifest) {
    return <Loading />;
  }

  const handleSubmit = async (values: FormData) => {
    setSaveConnectionInProgress(true);
    updateSetup({connectionId: connectionInfo.connection.id, keys: values});
    const success = await credentials(connectionInfo.connection.id, values);
    setSaveConnectionInProgress(false);
    if (!success) {
      return;
    }
    props.nextStep();
  };

  async function testConnection(values: FormData) {
    setTestConnectionInProgress(true);
    const {data: responseData} = await testConnectionAPI(connectionInfo.connection.id, values);
    setTestConnectionResult(responseData as TestConnectionResponseT);
    setTestConnectionInProgress(false);
  }

  function renderTestConnection() {
    const testConnectionText = (() => {
      if (testConnectionInProgress) {
        return (
          <div className="body6 text-right">
            <Loading /> Connecting
          </div>
        );
      }
      return (
        <div className={`${testConnectionResult?.ok ? 'text-success' : 'text-danger'} body6`}>
          {testConnectionResult?.ok && <Alert colorVariant="success">Connected Successfully!</Alert>}
          {testConnectionResult?.error?.message && (
            <Alert colorVariant="danger">An error occured: {testConnectionResult?.error?.message}</Alert>
          )}
        </div>
      );
    })();

    return <div className="text-right body6">{testConnectionText}</div>;
  }

  return (
    <div>
      {renderTestConnection()}
      <div className="d-inline-block p-2">
        <h2>{manifest.auth?.title}</h2>
        <p className="tr-text-sm" title={manifest.auth?.description}>
          {_.truncate(manifest.auth?.description, {length: 150})}
        </p>
        {manifest.auth?.docs && (
          <a
            href={manifest.auth?.docs.link}
            className="tr-text-sm"
            target="_blank"
            title={manifest.auth?.docs.linkText}
            rel="noreferrer"
          >
            {_.truncate(manifest.auth?.docs.linkText, {length: 150})}
          </a>
        )}
      </div>

      <Formik initialValues={formData} enableReinitialize validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({values, isValid, handleChange}) => {
          const handleInputChange = (e: any) => {
            handleChange(e);
            setFormFilled(Object.values(values).some((value) => !!value));
          };
          return (
            <FormikForm className="tr-my-3">
              {manifest.auth?.fields?.map((field) => (
                <div key={field.name}>
                  <label htmlFor={field.name}>{field.label}</label>
                  <Field
                    component={FormFields[field.type]}
                    id={field.name}
                    placeholder={field.placeholder ?? 'Placeholder'}
                    name={field.name}
                    onChange={handleInputChange}
                    className="tr-w-full"
                  />
                  <ErrorMessage name={field.name} component="div" />
                </div>
              ))}
              <div className="tr-justify-end tr-flex">
                <Button
                  name="test-connection"
                  data-testid="test-connection"
                  disabled={!isValid || testConnectionInProgress || saveConnectionInProgress || !formFilled}
                  variant="secondary"
                  onClick={() => testConnection(values)}
                >
                  Test Connection
                </Button>
                <Button
                  name="edit-connection-save"
                  disabled={(!testConnectionResult?.ok ?? true) || saveConnectionInProgress}
                  variant="primary"
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );
}

export default ManifestForm;
