export const FILTER_OPTIONS = ['Permission', 'Account', 'User', 'Provisioning', 'Lock', 'Tasks', 'Status'] as const;

export type FilterOption = typeof FILTER_OPTIONS[number];

export type FilterOptions = Partial<{
  [key in FilterOption]: string[];
}>;

export const SORT_OPTIONS = ['Name', 'Sensitivity', 'Risk', 'Usage', 'User'] as const;

export type SortOption = typeof SORT_OPTIONS[number];
export type SortState = {label: SortOption; value: string[]; order: 'asc' | 'desc'};
