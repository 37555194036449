import React from 'react';
import {useM365LicenseUsage} from '../../M365LicenseUsageContext';

type Props = {
  licenseId: string;
};

function LicenseUsageCell({licenseId}: Props) {
  const {licenseUsagePercentageMap} = useM365LicenseUsage();

  return (
    <div className="tr-flex tr-items-baseline">
      <span className="tr-font-bold tr-text-trustle-navy">{licenseUsagePercentageMap[licenseId]}%</span>
    </div>
  );
}

export const COLUMNS = [
  {
    dataField: 'name',
    text: 'Licenses',
    formatter: (name: string) => (
      <div className="tr-flex tr-items-baseline" title={name}>
        <span className="tr-text-trustle-link">{name}</span>
      </div>
    ),
  },
  {
    dataField: 'id',
    text: 'Usage',
    headerStyle: {width: '25%'},
    style: {width: '25%'},
    formatter: (id: string) => <LicenseUsageCell licenseId={id} />,
  },
];
