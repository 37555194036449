import {Loading} from '@trustle/component-library';
import React from 'react';
import {ConnectionServiceE} from 'src/types';
import {ComponentStateT, RouteStepT} from '../types';

import UAKSetup from './UAKSetup';

const steps: RouteStepT[] = [
  {
    name: 'Authenticate',
    path: `/connect/${ConnectionServiceE.AWS}/:connectionId/authenticate`,
    component: (params: ComponentStateT) => {
      return (
        <>
          {params.connectionInfo ? (
            <UAKSetup onComplete={params.nextStep} connectionInfo={params.connectionInfo} />
          ) : (
            <Loading />
          )}
        </>
      );
    },
  },
];

export const meta = {
  steps: steps,
  key: ConnectionServiceE.AWS,
  description:
    'Connect your AWS Identity and Access Management (IAM) account and sync the data with your Trustle organization.',
  title: 'Add a New AWS System',
};
