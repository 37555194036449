import axios from 'axios';
import {observer} from 'mobx-react';
import React from 'react';
import {useDataLoader} from 'src/lib';
import {Alert, Confirmation, Loading} from '@trustle/component-library';
import {Resource} from 'src/stores/domainObjects/Resource';
import moment from 'moment';
import {useRootStore} from '../../../lib/hooks';

type PropsT = {
  resource: Resource;
  closeModal: (resourceWasArchived: boolean) => void;
};

const ConfirmDeleteResourceModal = observer(function ConfirmDeleteResourceModal(props: PropsT) {
  const {resource, closeModal} = props;
  const {currentUser} = useRootStore();
  const [accessResponse, accessControl] = useDataLoader(`/api/resources/${props.resource.id}/check_archive_ready`);

  async function runAction(e: any) {
    e.preventDefault();
    await axios.post(`/api/resources/${resource.id}/delete`);
    resource.deletedAt = moment().format();
    closeModal(true);
  }

  if (!accessControl.isReady) {
    return <Loading dataLoadStatus={accessResponse} />;
  }

  // System-level resources can be deleted before all provisioned accesses are removed.
  const allowDelete = !resource.rootSid || accessResponse.numAccess <= 0;
  const showUnownedResource = !resource.userIsOwner && currentUser.isOrgOwner;
  return (
    <Confirmation
      onConfirm={allowDelete ? runAction : closeModal}
      onClose={() => closeModal(false)}
      title={allowDelete ? 'Confirm delete resource' : `Deprovision access before deleting`}
      confirmButtonLabel={allowDelete ? 'Confirm' : ''}
      size="md"
    >
      <>
        {showUnownedResource && (
          <div className="mb-4">
            <Alert title="Unowned resource" colorVariant="danger">
              <p>
                You are not the owner of this system, but are about to delete it anyway. Are you sure you wish to
                proceed?
              </p>
            </Alert>
          </div>
        )}
        {!allowDelete && (
          <div>
            This resource has {accessResponse?.numAccess} provisioned users associated with it, and they must be
            deprovisioned before this resource can be deleted.
          </div>
        )}
        {allowDelete && (
          <div>
            Please confirm that you would like to delete the resource:
            <br />
            <strong>{resource.name}</strong>.
            <p className="mt-2">
              <small>This action will be remove all pending tasks for the resource</small>
            </p>
          </div>
        )}
      </>
    </Confirmation>
  );
});

export default ConfirmDeleteResourceModal;
