import React from 'react';
import {useRootStore} from 'src/lib/hooks';

type HubspotPropT = {
  field: string;
  value: string;
  sendEmail?: boolean;
};
/**
 * Send the field and value to Hubspot for tracking.
 * Email and form with id are needed to track the data.
 * @param props
 * @returns
 */
const IntegrationHubspot = ({field, value, sendEmail}: HubspotPropT) => {
  return (
    <>
      <label className="d-none">{field}</label>
      <input name={field} id={field} required value={value} hidden readOnly />
      {sendEmail && (
        <>
          <label className="d-none">email</label>
          <input type="email" name="email" id="email" value={useRootStore().currentUser?.email ?? ''} hidden readOnly />
        </>
      )}
    </>
  );
};

export default IntegrationHubspot;
