export const USERS_MOCK = [
  {
    id: '1',
    name: 'Alice Johnson',
    email: 'alice.johnson@company',
    type: 'employee',
    is_org_owner: true,
    firstname: 'Alice',
    lastname: 'Johnson',
    last_used: '2024-11-20T14:30:00.000Z',
    assigned_on: '2024-11-15T09:15:00.000Z',
    flagged: false,
    licenses: 5,
    usage: 80,
  },
  {
    id: '2',
    name: 'Bob Smith',
    email: 'bob.smith@company',
    type: 'contractor',
    is_org_owner: false,
    firstname: 'Bob',
    lastname: 'Smith',
    last_used: '2024-11-19T12:00:00.000Z',
    assigned_on: '2024-11-12T08:45:00.000Z',
    flagged: true,
    licenses: 3,
    usage: 65,
  },
  {
    id: '3',
    name: 'Carol Lee',
    email: 'carol.lee@company',
    type: 'system',
    is_org_owner: false,
    firstname: 'Carol',
    lastname: 'Lee',
    last_used: '2024-11-18T18:20:00.000Z',
    assigned_on: '2024-11-10T11:00:00.000Z',
    flagged: false,
    licenses: 0,
    usage: 20,
  },
  {
    id: '4',
    name: 'David Brown',
    email: 'david.brown@company',
    type: 'customer',
    is_org_owner: false,
    firstname: 'David',
    lastname: 'Brown',
    last_used: '2024-11-20T10:50:00.000Z',
    assigned_on: '2024-11-18T09:00:00.000Z',
    flagged: false,
    licenses: 7,
    usage: 40,
  },
  {
    id: '5',
    name: 'Eve Davis',
    email: 'eve.davis@company',
    type: 'employee',
    is_org_owner: false,
    firstname: 'Eve',
    lastname: 'Davis',
    last_used: '2024-11-17T16:40:00.000Z',
    assigned_on: '2024-11-16T15:30:00.000Z',
    flagged: true,
    licenses: 4,
    usage: 50,
  },
  {
    id: '6',
    name: 'Frank Wilson',
    email: 'frank.wilson@company',
    type: 'contractor',
    is_org_owner: false,
    firstname: 'Frank',
    lastname: 'Wilson',
    last_used: '2024-11-15T14:20:00.000Z',
    assigned_on: '2024-11-10T10:00:00.000Z',
    flagged: false,
    licenses: 2,
    usage: 30,
  },
  {
    id: '7',
    name: 'Grace Taylor',
    email: 'grace.taylor@company',
    type: 'system',
    is_org_owner: false,
    firstname: 'Grace',
    lastname: 'Taylor',
    last_used: '2024-11-20T08:00:00.000Z',
    assigned_on: '2024-11-12T07:00:00.000Z',
    flagged: false,
    licenses: 6,
    usage: 90,
  },
  {
    id: '8',
    name: 'Hank Moore',
    email: 'hank.moore@company',
    type: 'customer',
    is_org_owner: false,
    firstname: 'Hank',
    lastname: 'Moore',
    last_used: '2024-11-18T13:25:00.000Z',
    assigned_on: '2024-11-11T12:00:00.000Z',
    flagged: true,
    licenses: 10,
    usage: 70,
  },
  {
    id: '9',
    name: 'Ivy Clark',
    email: 'ivy.clark@company',
    type: 'employee',
    is_org_owner: false,
    firstname: 'Ivy',
    lastname: 'Clark',
    last_used: '2024-11-19T10:10:00.000Z',
    assigned_on: '2024-11-13T09:00:00.000Z',
    flagged: false,
    licenses: 8,
    usage: 85,
  },
  {
    id: '10',
    name: 'Jack White',
    email: 'jack.white@company',
    type: 'contractor',
    is_org_owner: false,
    firstname: 'Jack',
    lastname: 'White',
    last_used: '2024-11-20T15:45:00.000Z',
    assigned_on: '2024-11-14T12:00:00.000Z',
    flagged: false,
    licenses: 1,
    usage: 10,
  },
  {
    id: '11',
    name: 'Laura Green',
    email: 'laura.green@company',
    type: 'employee',
    is_org_owner: false,
    firstname: 'Laura',
    lastname: 'Green',
    last_used: '2024-11-19T14:15:00.000Z',
    assigned_on: '2024-11-10T08:30:00.000Z',
    flagged: true,
    licenses: 4,
    usage: 60,
  },
  {
    id: '12',
    name: 'Mike Adams',
    email: 'mike.adams@company',
    type: 'system',
    is_org_owner: false,
    firstname: 'Mike',
    lastname: 'Adams',
    last_used: '2024-11-18T09:45:00.000Z',
    assigned_on: '2024-11-11T07:00:00.000Z',
    flagged: false,
    licenses: 2,
    usage: 25,
  },
  {
    id: '13',
    name: 'Nina Scott',
    email: 'nina.scott@company',
    type: 'customer',
    is_org_owner: false,
    firstname: 'Nina',
    lastname: 'Scott',
    last_used: '2024-11-20T13:20:00.000Z',
    assigned_on: '2024-11-18T10:00:00.000Z',
    flagged: false,
    licenses: 7,
    usage: 75,
  },
  {
    id: '14',
    name: 'Oscar Hall',
    email: 'oscar.hall@company',
    type: 'contractor',
    is_org_owner: false,
    firstname: 'Oscar',
    lastname: 'Hall',
    last_used: '2024-11-17T11:10:00.000Z',
    assigned_on: '2024-11-14T09:00:00.000Z',
    flagged: true,
    licenses: 5,
    usage: 45,
  },
  {
    id: '15',
    name: 'Paula Young',
    email: 'paula.young@company',
    type: 'employee',
    is_org_owner: true,
    firstname: 'Paula',
    lastname: 'Young',
    last_used: '2024-11-20T16:30:00.000Z',
    assigned_on: '2024-11-19T14:00:00.000Z',
    flagged: false,
    licenses: 9,
    usage: 95,
  },
];
