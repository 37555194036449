import React from 'react';
import {Button, IconButton} from '@trustle/component-library';

type Props = {
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  direction?: 'horizontal' | 'vertical';
  pagesCount: number;
};

export function HeatmapPagination({page, setPage, direction = 'horizontal', pagesCount}: Props) {
  const directionClass = direction === 'horizontal' ? 'tr-flex tr-gap-2' : 'tr-flex tr-flex-col tr-gap-2';
  const prevIcon = direction === 'horizontal' ? 'chevronLeft' : 'chevronUp';
  const nextIcon = direction === 'horizontal' ? 'chevronRight' : 'chevronDown';

  const handlePageChange = (go: 'next' | 'prev') => {
    if (go === 'next') {
      setPage(page + 1);
    } else {
      setPage(page - 1);
    }
  };

  return (
    <div className={directionClass}>
      <IconButton onClick={() => handlePageChange('prev')} disabled={page === 0} icon={prevIcon} variant="ternary" />
      {Array.from({length: pagesCount}, (_, i) => i + 1).map((i) => (
        <Button key={i} onClick={() => setPage(i - 1)} variant={i === page + 1 ? 'primary' : 'secondary'}>
          {i}
        </Button>
      ))}
      <IconButton
        onClick={() => handlePageChange('next')}
        icon={nextIcon}
        disabled={page + 1 >= pagesCount}
        variant="ternary"
      />
    </div>
  );
}
