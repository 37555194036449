import _ from 'lodash';
import axios from 'axios';
import React, {useState, useEffect} from 'react';
import {TypeaheadSelect} from './form/TypeaheadSelect/TypeaheadSelect';
import './TrustleUserSelector.scss';
import {isValidEmailAccount} from 'src/lib';
import {Button, TextInput} from '@trustle/component-library';
import {useRootStore} from 'src/lib/hooks';
import {object, string} from 'yup';
import {Field, Form, Formik} from 'formik';

export enum AllowNewOutsideTypeE {
  RIGHT = 'right',
  DOWN = 'down',
}

type PropsT = {
  id?: string;
  label?: string | React.ReactElement;
  required?: boolean;
  validated?: boolean;
  defaultSelected?: {value: string; label: string}[];
  selected?: any;
  name: string;
  description?: string;
  subdescription?: string | React.ReactElement;
  multiple?: boolean;
  limit?: number;
  allowNew?: boolean;
  horizontal?: boolean;
  newSelectionPrefix?: string;
  validationFeedback?: string;
  placeholder?: string;
  onBlur?: (e: any) => void | undefined;
  onChange: (selectedElement: any, ids?: string[], newUser?: any) => void;
  onChangeNewUser?: (newUser: any) => void;
  noResultsEvent?: (e: any) => void | undefined;
  resetInput?: () => void;
  allowNewOutsideType?: AllowNewOutsideTypeE;
  allowNewAction?: (e: any) => void | undefined;
  prefilledSearch?: () => string;
  prefilledUser?: {firstName?: string; lastName?: string};
  emptyLabel?: string;
  createTrustleUserBtnLabel?: string;
  disabled?: boolean;
  suggestUrl?: string;
  rightBtn?: React.ReactElement;
};

type NewUserParamsT = Partial<{
  email?: string;
  firstName?: string;
  lastName?: string;
  managerUid?: string;
}>;

/** @deprecated use UserSelector */
const TrustleUserSelector = React.forwardRef((props: PropsT, ref: any) => {
  const allowNewOutside = props.allowNewOutsideType || false;
  const allowNewOutsideType = props.allowNewOutsideType || AllowNewOutsideTypeE.DOWN;
  const createTrustleUserBtnLabel = props.createTrustleUserBtnLabel
    ? props.createTrustleUserBtnLabel
    : 'Create Trustle User';
  const {currentUser} = useRootStore();
  const prefilledSearch = props.prefilledSearch ? props.prefilledSearch() : '';
  const [emailCreateNewUser, setEmailCreateNewUser] = useState<string | undefined>(undefined);
  const [createNewUser, setCreateNewUser] = useState<boolean>(
    !!prefilledSearch && allowNewOutside && allowNewOutsideType === AllowNewOutsideTypeE.DOWN
  );
  const [hasResults, setHasResults] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<NewUserParamsT>({
    email: prefilledSearch,
    firstName: props.prefilledUser ? props.prefilledUser.firstName : '',
    lastName: props.prefilledUser ? props.prefilledUser.lastName : '',
  });
  const [internalSelected, setInternalSelected] = useState<{value: string; label: string}[]>([]);
  const allowNewAction = props.allowNewAction;
  const typeaheadSelectRef = React.useRef<any>();

  async function loadUserSuggestions(email: string) {
    let hasResultsLocal = false;
    const url = props.suggestUrl ? props.suggestUrl : '/api/orgs/users/suggest';
    const suggestionsResponse = await axios.post(url, {email});
    if (suggestionsResponse.data.length === 0) {
      hasResultsLocal = false;
      if (props.noResultsEvent) {
        props.noResultsEvent(props);
      }
    } else {
      hasResultsLocal = true;
    }

    setHasResults(hasResultsLocal);
    if (!hasResultsLocal) {
      setEmailCreateNewUser(email);
      setNewUser({...newUser, email: email});
      return [];
    } else {
      setNewUser({...newUser, email: ''});
      setEmailCreateNewUser(undefined);
      setCreateNewUser(false);
      return _.map(suggestionsResponse.data, (suggestion: any) => {
        return {
          id: suggestion.id,
          value: suggestion.email,
          label: suggestion.email,
        };
      });
    }
  }

  useEffect(() => {
    if (_.isFunction(props.onChangeNewUser)) {
      props.onChangeNewUser(newUser);
    }
  }, [newUser]);

  const extraProps = _.pick(props, [
    'id',
    'name',
    'label',
    'required',
    'onChange',
    'selected',
    'defaultSelected',
    'description',
    'subdescription',
    'multiple',
    'allowNew',
    'newSelectionPrefix',
    'validationFeedback',
    'placeholder',
    'onChangeNewUser',
    'horizontal',
    'resetInput',
    'emptyLabel',
    'disabled',
  ]);

  React.useImperativeHandle(ref, () => ({
    resetCreateNewUser: () => resetCreateNewUser(),
    loadUserSuggestions: (email: string) => loadUserSuggestions(email),
  }));

  function resetCreateNewUser() {
    setCreateNewUser(false);
    setEmailCreateNewUser(undefined);
    setNewUser({});
    setInternalSelected([]);
    setHasResults(false);
    if (_.isFunction(typeaheadSelectRef.current.clear)) {
      typeaheadSelectRef.current.clear();
    }
  }

  const allowNewOutsideRight = allowNewOutside && allowNewOutsideType === AllowNewOutsideTypeE.RIGHT;
  const allowNewOutsideDown = allowNewOutside && allowNewOutsideType === AllowNewOutsideTypeE.DOWN;
  const showCreateNewUser = internalSelected.length === 0 && !createNewUser && emailCreateNewUser;
  const showCreateNewUserOnRight = showCreateNewUser && allowNewOutsideRight;
  const showCreateNewUserOnDown = showCreateNewUser && allowNewOutsideDown;
  const showManagerField = currentUser?.isOrgOwner || currentUser?.isHrAdmin;

  const createUser = () => {
    if (allowNewAction) {
      allowNewAction(emailCreateNewUser);
    } else {
      setCreateNewUser(true);
      setNewUser({...newUser, email: emailCreateNewUser});
    }
  };

  return (
    <>
      <Formik
        initialValues={{firstname: newUser.firstName ?? '', lastname: newUser.lastName ?? ''}}
        validationSchema={object().shape({
          firstname: string().required('Please fill out this field'),
          lastname: string().required('Please fill out this field'),
        })}
        onSubmit={(values) => {
          if (allowNewAction) {
            allowNewAction(emailCreateNewUser);
          } else {
            setCreateNewUser(true);
            setNewUser({...newUser, ...values, email: emailCreateNewUser});
          }
        }}
        enableReinitialize
      >
        {() => (
          <Form className="text-dark-gray">
            <div className="row">
              <div className={allowNewOutsideRight ? 'col-md-8 pl-0 pr-0' : 'col-md-12'}>
                <TypeaheadSelect
                  ref={typeaheadSelectRef}
                  id="trustleUserSelector"
                  prefilledSearch={props.prefilledSearch}
                  className={hasResults ? 'with-results' : 'hide-no-results'}
                  validated={props.validated ? props.validated : false}
                  onSearch={loadUserSuggestions}
                  {...extraProps}
                  allowNew={false}
                  allowNewOutside={allowNewOutsideType ? true : false}
                  newSelectionPrefix="Add new user:"
                  placeholder="Type Trustle username here..."
                  disabled={props.disabled}
                  onInternalSelectedChange={(e: any) => {
                    setInternalSelected(e);
                  }}
                  emptyLabel=""
                />
              </div>
              <div className="col-md-4" style={{padding: '0px'}}>
                {showCreateNewUserOnRight && (
                  <Button variant="secondary" label={createTrustleUserBtnLabel} onClick={createUser} />
                )}
                <div className="mr-2 ml-0">{!showCreateNewUserOnRight && props.rightBtn}</div>
              </div>
            </div>
            {showCreateNewUserOnDown && (
              <div className="tr-mb-3">
                <div className="tr-w-full">
                  {
                    <>
                      <small className="tr-ml-3">
                        <strong>No username found</strong>
                      </small>
                      <div className="tr-float-right inline-create-user tr-mr-3" onClick={createUser}>
                        <strong>Create new user</strong>
                      </div>
                    </>
                  }
                </div>
              </div>
            )}
            {allowNewOutside && internalSelected.length === 0 && createNewUser && (
              <>
                <div className="tr-flex tr-flex-wrap">
                  <div className="tr-w-full md:tr-w-2/3 ">
                    {!isValidEmailAccount(newUser.email) && (
                      <>
                        <span className="ml-3 text-danger">
                          <small>A valid email address is required</small>
                        </span>
                      </>
                    )}
                  </div>
                  <div className="tr-w-full md:tr-w-1/3">
                    <div
                      className=" tr-float-right inline-create-user tr-mr-3 tr-mt-0"
                      onClick={() => {
                        resetCreateNewUser();
                      }}
                    >
                      <strong>Cancel</strong>
                    </div>
                  </div>
                </div>
                <div className="tr-flex tr-flex-wrap">
                  <div className="tr-w-full md:tr-w-1/2">
                    <Field component={TextInput} label="First name *" name="firstname" />
                  </div>
                  <div className="tr-w-full md:tr-w-1/2">
                    <Field component={TextInput} label="Last name *" name="lastname" />
                  </div>
                </div>
                {showManagerField && (
                  <div>
                    <div className="tr-w-full">
                      <TrustleUserSelector
                        label="Manager Email"
                        name="managerEmail"
                        id="managerEmail"
                        data-cy="managerEmail"
                        placeholder="Who is the manager for this user"
                        onChange={(e: any) => {
                          setNewUser({...newUser, managerUid: e ? e.id : null});
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </Form>
        )}
      </Formik>
    </>
  );
});
export default TrustleUserSelector;
