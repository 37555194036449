import {Confirmation} from '@trustle/component-library';
import React from 'react';

type Props = {
  onConfirm: () => void;
  onCancel: () => void;
  name?: string;
};

export function ConfirmDisassociateAuthorityModal(props: Props) {
  const {onConfirm, onCancel, name} = props;
  return (
    <Confirmation
      onConfirm={onConfirm}
      onClose={onCancel}
      title="Disassociate as User Authority"
      confirmButtonLabel="Disassociate Authority"
      size="md"
    >
      <div className="tr-flex tr-flex-col tr-gap-4">
        <strong>This will disassociate you Authority-Created Trustle Users from {name}.</strong>
        <p>
          Your Trustle users will remain active in Trustle, but will be no longer be associated by the remote system as
          an authority. The Authortiy will switch to Trustle for these users. If you change your mind, you can
          re-associate them in the future.
        </p>
      </div>
    </Confirmation>
  );
}
