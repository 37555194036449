import React from 'react';
import {ConnectionServiceE} from 'src/types';
import {ComponentStateT, RouteStepT} from '../types';
import {Loading} from '@trustle/component-library';
import AuthenticateStep from './AuthenticateStep';
import _ from 'lodash';

const GitHubHeaderDescription = `
Sync GitHub with your Trustle organization. This process will guide you through authorizing
Trustle to access your GitHub organization.
`;

const steps: RouteStepT[] = [
  {
    name: 'Authenticate',
    path: `/connect/${ConnectionServiceE.GITHUB}/:connectionId/authenticate`,
    component: (params: ComponentStateT) => {
      return (
        <>
          {params.connectionInfo ? (
            <AuthenticateStep {..._.pick(params, ['orgName', 'systemId', 'nextStep', 'connectionId'])} />
          ) : (
            <Loading />
          )}
        </>
      );
    },
  },
];

export const meta = {
  steps: steps,
  key: ConnectionServiceE.GITHUB,
  description: GitHubHeaderDescription,
  title: 'Add a New Github System',
};
