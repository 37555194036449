import {useState} from 'react';
import axios from 'axios';
import {InvitationStatus, UserStatus, UserType} from '../types';

export type UserResponse = {
  id: string;
  allowLogin: boolean;
  canSwitchSoT: boolean;
  confirmed: boolean;
  created: string;
  createdByUid: string;
  department: string;
  email: string;
  firstname: string;
  lastname: string;
  invitationStatus: InvitationStatus;
  linked: boolean;
  sourceOfTruth: string;
  userStatus: UserStatus;
  type: UserType;
  title: string;
  invitedAt: string;
  isOrgOwner: boolean;
  lastLogin: string;
  remoteRole: string;
  loginMethod: string;
  manager: {
    id: string;
    firstname: string;
    lastname: string;
    email: string;
  };
};

type ListUsersResponse = {
  users: UserResponse[]; // TODO: define type
  total: number;
  page: number;
  size: number;
  pages: number;
};

type PaginationParams = {
  page?: number;
  size?: number;
};

// TODO: implement params once peryton API is ready
export type ListUsersParams = {
  status?: UserStatus;
  type?: UserType;
  invitationStatus?: InvitationStatus;
  team?: string;
  nameOrEmail?: string;
  manager?: string;
  role?: string;
  title?: string;
  department?: string;
  orderBy?: string;
  sort?: 'asc' | 'desc';
} & PaginationParams;

export function useUsers() {
  const [data, setData] = useState<ListUsersResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BASE_URL = '/api/v2/users';

  const fetchUsers = async (params: ListUsersParams) => {
    const {page, size, ...rest} = params;

    const parsedParams: Record<string, string> = {
      page: (page || 1).toString(),
      size: (size || 50).toString(),
    };

    Object.keys(rest).forEach((key) => {
      const typedKey = key as keyof typeof rest; // Ensure TypeScript understands the key type
      const value = rest[typedKey] as string | number;
      if (rest[typedKey] !== undefined) {
        parsedParams[typedKey] = value.toString();
      }
    });

    const queryString = new URLSearchParams(parsedParams).toString();

    setLoading(true);
    try {
      const response = await axios.get<ListUsersResponse>(`${BASE_URL}?${queryString}`);
      const responseData = response.data;
      setData(responseData);
    } catch (err: any) {
      // TODO: Implement handling error
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {data, loading, error, fetchUsers};
}
