import _ from 'lodash';
import React, {useState} from 'react';
import {Field, useField} from 'formik';
import {Loading} from '@trustle/component-library';
import {useDataLoader} from 'src/lib';
import {SensitivityMonoIcon} from 'src/components/sensitivity';
import {PermissionT} from 'src/types';
import {InputError} from './InputError';
import {Select} from '@trustle/component-library';

type PropsT = {
  name: string;
  uid?: string;
  rid?: string;
  required?: boolean;
  onPermissionSelected: (permission: PermissionT) => void;
  onSystemSelected: (resource: any) => void;
};

export function TrustlePermissionSelector(props: PropsT) {
  function validate(_value: any) {
    if (props.required && !selectedPermission) {
      return `This field is required.`;
    }
  }
  const [, meta, helpers] = useField({name: props.name, validate});

  const [selectedSystemId, setSelectedSystemId] = useState<string | null>(props.rid ? props.rid : null);
  const [selectedPermission, setSelectedPermission] = useState<PermissionT | null>(null);

  const [resourceResponse, resourceResponseCtrl] = useDataLoader(`/api/resources/system_selector/${props.uid}`);

  if (!resourceResponseCtrl!.isReady) {
    return <Loading />;
  }

  const {systems} = resourceResponse;
  const systemOptions = _.map(systems, (systemData: any) => {
    const {system} = systemData;
    return {
      value: system.id,
      label: system.name,
    };
  });

  const selectedSystemData = _.find(systems, (systemData: any) => {
    return systemData.system.id === selectedSystemId;
  });

  const permissionOptions = (() => {
    if (!selectedSystemData) {
      return [];
    }
    const parentResourcesById = _.keyBy(selectedSystemData.resources, 'id');

    return _(selectedSystemData.permissions)
      .orderBy(['refId', 'label'], ['desc', 'asc'])
      .map((permission: PermissionT) => {
        return {
          value: permission.id,
          label: `${parentResourcesById[permission.refId].name} > ${permission.label}`,
          meta: permission.sensitivityId,
        };
      })
      .value();
  })();

  return (
    <>
      <Field
        component={Select}
        name={`${props.name}.system`}
        required={true}
        disabled={!!props.rid && selectedSystemData}
        label="Select System"
        className="tr-mt-4"
        options={systemOptions}
        value={
          selectedSystemData ? [{value: selectedSystemData!.system.id, label: selectedSystemData!.system.name}] : null
        }
        onChange={(selectedElement: any) => {
          setSelectedSystemId(selectedElement.value);
          setSelectedPermission(null);
          helpers.setValue(null);
          props.onSystemSelected(selectedElement.value);
        }}
      />
      <Field
        component={Select}
        name={`${props.name}.permission`}
        required={true}
        label="Select Permission"
        className="tr-mt-4"
        options={permissionOptions}
        value={selectedPermission}
        formatOptionLabel={(data: {value: string; label: string; meta?: string}) => {
          return (
            <div className="tr-flex align-items-center">
              <SensitivityMonoIcon className="mr-2" sensitivityId={data.meta} />
              {data.label}
            </div>
          );
        }}
        onChange={(permissionOption: any) => {
          setSelectedPermission(permissionOption);
          const permission = _.find(selectedSystemData.permissions, {id: permissionOption.value});
          helpers.setValue(permission);
          props.onPermissionSelected(permission);
        }}
      />
      <div className="tr-w-full">
        <InputError meta={meta} />
      </div>
    </>
  );
}
