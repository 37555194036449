import axios from 'axios';
import _ from 'lodash';
import {makeAutoObservable, reaction} from 'mobx';
import {logger} from 'src/lib';
import {DurationUnitT} from 'src/types';
import RootStore from '..';

export class PermissionSet {
  id!: string;
  name!: string;
  orgId!: string;
  permissions!: string[];
  resourceId?: string;
  oncallListId?: string | null;
  accessDurationValue?: number;
  accessDurationUnit?: DurationUnitT;
  sensitivitySettingId?: string;
  createdAt?: string;
  assignedBy?: string;
  permissionsCount?: number;
  updatedAt?: string;

  constructor(private rootStore: RootStore, permissionSet: Partial<PermissionSet>) {
    Object.assign(this, permissionSet);
    makeAutoObservable(this, {id: false});

    reaction(
      () => _.pick(this, ['name', 'accessDurationValue', 'accessDurationUnit', 'oncallListId']),
      async (change: Partial<PermissionSet>) => {
        try {
          await axios.put(`/api/oncall_sets/${this.id}`, {
            name: change.name,
            accessDurationValue: change.accessDurationValue,
            accessDurationUnit: change.accessDurationUnit,
            oncallListId: change.oncallListId,
          });
          this.rootStore.toast.add(`Successfully updated permission set`, {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          logger.error(err);
          this.rootStore.toast.add(`An error occured while updating ${change.name}`, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    );

    reaction(
      () => _.pick(this, ['permissions']),
      async (change: Partial<PermissionSet>) => {
        try {
          await axios.put(`/api/oncall_sets/${this.id}/oncall_permissions`, change.permissions);
          this.rootStore.toast.add(`Successfully updated permission set`, {
            appearance: 'success',
            autoDismiss: true,
          });
          if (this.resourceId && this.rootStore.newResourceStore.resourceMap[this.resourceId]) {
            void this.rootStore.newResourceStore.resourceMap[this.resourceId].getPermissionSets();
          }
        } catch (err) {
          logger.error(err);
          this.rootStore.toast.add(`An error occured while updating ${change.name}`, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    );
  }

  get getOncallListData() {
    if (!this.oncallListId) {
      return [];
    }
    const ol = _.find(this.rootStore.org?.onCallLists, (ol) => ol.id === this.oncallListId);
    if (!ol) {
      return null;
    }
    return {id: ol.id, value: ol.id, label: ol.name};
  }

  update(values: Partial<PermissionSet>) {
    this.name = values.name ?? '';
    this.accessDurationUnit = values.accessDurationUnit;
    this.accessDurationValue = values.accessDurationValue;
    this.permissions = values.permissions ?? [];
    if (!_.isUndefined(values.oncallListId)) {
      // only update if is not undefined - null means desassocciate
      this.oncallListId = values.oncallListId ?? null;
    }
  }
}
