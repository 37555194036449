import React, {useEffect, useState} from 'react';
import {Card} from 'src/components/design';
import {Badge, Icon, Table, Tooltip} from '@trustle/component-library';
import {formatDateFromNow, formatFullDate} from 'src/lib';
import {ConnectionServiceE, ConnectorsDataT, ThirdPartyDataOT} from 'src/types';
import _ from 'lodash';
import moment from 'moment';
import {Resource} from 'src/stores/domainObjects/Resource';

import {Account} from 'src/stores/domainObjects/Account';
import {observer} from 'mobx-react-lite';
import {BadgeVariantEnum} from '@trustle/component-library/dist/types';

type AdditionalInformationProps = {userAccount: Account; resource: Resource; showGroup: GroupsE};
type DescriptionDictT = _.Dictionary<{title: string; isDate?: boolean; description?: string; group?: string}>;

export enum GroupsE {
  ACCOUNT_INFO = 'account_info',
  MFA = 'mfa',
  ACCESS_KEYS = 'access_keys',
  CERTIFICATES = 'certificates',
}
const descriptionDictGoogleWorkspace: DescriptionDictT = {
  agreedToTerms: {
    title: 'Agreed to terms',
    description:
      'This property is yes if the user has completed an initial login and accepted the Terms of Service agreement.',
    group: GroupsE.ACCOUNT_INFO,
  },
  archived: {title: 'Archived', description: 'Indicates if user is archived.', group: GroupsE.ACCOUNT_INFO},
  changePasswordAtNextLogin: {
    title: 'Change password at next login',
    description:
      "Indicates if the user is forced to change their password at next login. This setting doesn't apply when the user signs in via a third-party identity provider.",
    group: GroupsE.ACCOUNT_INFO,
  },
  ipWhitelisted: {
    title: 'Ip whitelisted',
    description: "If true, the user's IP address is whitelisted.",
    group: GroupsE.ACCOUNT_INFO,
  },
  isAdmin: {
    title: 'Is admin',
    description:
      'Indicates a user with super admininistrator privileges. The isAdmin property can only be edited in the Make a user an administrator operation ( makeAdmin method). If edited in the user insert or update methods, the edit is ignored by the API service.',
    group: GroupsE.ACCOUNT_INFO,
  },
  isDelegatedAdmin: {
    title: 'Is delegated admin',
    description:
      'Indicates if the user is a delegated administrator. Delegated administrators are supported by the API but cannot create or undelete users, or make users administrators. These requests are ignored by the API service. Roles and privileges for administrators are assigned using the Admin console.',
    group: GroupsE.ACCOUNT_INFO,
  },
  isEnforcedIn2Sv: {
    title: 'Is enforced in 2-step verification',
    description: 'Is 2-step verification enforced',
    group: GroupsE.ACCOUNT_INFO,
  },
  isEnrolledIn2Sv: {
    title: 'Is enrolled in 2-step verification',
    description: 'Is enrolled in 2-step verification',
    group: GroupsE.ACCOUNT_INFO,
  },
  isMailboxSetup: {
    title: 'Is mailbox setup',
    description:
      "Indicates if the user's Google mailbox is created. This property is only applicable if the user has been assigned a Gmail license.",
    group: GroupsE.ACCOUNT_INFO,
  },
};

const descriptionDictAws: DescriptionDictT = {
  user: {
    group: GroupsE.ACCOUNT_INFO,
    title: 'User',
    description: 'The friendly name of the user.',
  },
  arn: {title: 'ARN', description: 'The Amazon Resource Name (ARN) of the user.', group: GroupsE.ACCOUNT_INFO},
  user_creation_time: {
    group: GroupsE.ACCOUNT_INFO,
    title: 'User creation time',
    description: 'The date and time when the user was created.',
  },
  password_enabled: {
    group: GroupsE.ACCOUNT_INFO,
    title: 'Password enabled',
    description: 'When the user has a password this value is yes. Otherwise it is no.',
  },
  password_last_used: {
    group: GroupsE.ACCOUNT_INFO,
    title: 'Password last used',
    isDate: true,
    description:
      "The date and time when the AWS account root user or IAM user's password was last used to sign in to an AWS website. AWS websites that capture a user's last sign-in time are the AWS Management Console, the AWS Discussion Forums, and the AWS Marketplace. When a password is used more than once in a 5-minute span, only the first use is recorded in this field.",
  },
  password_last_changed: {
    group: GroupsE.ACCOUNT_INFO,
    title: 'Password last changed',
    isDate: true,
    description:
      "The date and time when the user's password was last set, in ISO 8601 date-time format. If the user does not have a password, the value in this field is -. The value for the AWS account (root) is always not_supported.",
  },
  password_next_rotation: {
    group: GroupsE.ACCOUNT_INFO,
    title: 'Password next rotation',
    isDate: true,
    description:
      'When the account has a password policy that requires password rotation, this field contains the date and time, in ISO 8601 date-time format, when the user is required to set a new password. The value for the AWS account (root) is always not_supported.',
  },
  mfa_active: {
    group: GroupsE.MFA,
    title: 'MFA active',
    description:
      'When a multi-factor authentication (MFA) device has been enabled for the user, this value is YES. Otherwise it is NO.',
  },
  access_key_1_active: {
    group: GroupsE.ACCESS_KEYS,
    title: 'Access key 1 active',
    description:
      "When the user has an access key and the access key's status is Active, this value is YES. Otherwise it is NO.",
  },
  access_key_1_last_rotated: {
    group: GroupsE.ACCESS_KEYS,
    title: 'Access key 1 last rotated',
    isDate: true,
    description:
      "The date and time, in ISO 8601 date-time format, when the user's access key was created or last changed. If the user does not have an active access key, the value in this field is -.",
  },
  access_key_1_last_used_date: {
    group: GroupsE.ACCESS_KEYS,
    title: 'Access key 1 last used date',
    isDate: true,
    description:
      "The date and time, in ISO 8601 date-time format, when the user's access key was most recently used to sign an AWS API request. When an access key is used more than once in a 15-minute span, only the first use is recorded in this field.",
  },
  access_key_1_last_used_region: {
    group: GroupsE.ACCESS_KEYS,
    title: 'Access key 1 last used region',
    description:
      'The AWS Region in which the access key was most recently used. When an access key is used more than once in a 15-minute span, only the first use is recorded in this field.',
  },
  access_key_1_last_used_service: {
    group: GroupsE.ACCESS_KEYS,
    title: 'Access key 1 last used service',
    description:
      "The AWS service that was most recently accessed with the access key. The value in this field uses the service's namespace—for example, s3 for Amazon S3 and ec2 for Amazon EC2. When an access key is used more than once in a 15-minute span, only the first use is recorded in this field.",
  },

  access_key_2_active: {
    group: GroupsE.ACCESS_KEYS,
    title: 'Access key 2 active',
    description:
      "When the user has a second access key and the second key's status is Active, this value is YES. Otherwise it is NO.",
  },
  access_key_2_last_rotated: {
    group: GroupsE.ACCESS_KEYS,
    title: 'Access key 2 last rotated',
    isDate: true,
    description:
      "The date and time, in ISO 8601 date-time format, when the user's second access key was created or last changed. If the user does not have a second active access key, the value in this field is -.",
  },
  access_key_2_last_used_date: {
    group: GroupsE.ACCESS_KEYS,
    title: 'Access key 2 last used date',
    isDate: true,
    description:
      "The date and time, in ISO 8601 date-time format, when the user's second access key was most recently used to sign an AWS API request. When an access key is used more than once in a 15-minute span, only the first use is recorded in this field.",
  },
  access_key_2_last_used_region: {
    group: GroupsE.ACCESS_KEYS,
    title: 'Access key 2 last used region',
    description:
      "The AWS Region in which the user's second access key was most recently used. When an access key is used more than once in a 15-minute span, only the first use is recorded in this field.",
  },
  access_key_2_last_used_service: {
    group: GroupsE.ACCESS_KEYS,
    title: 'Access key 2 last used service',
    description:
      "The AWS service that was most recently accessed with the user's second access key. The value in this field uses the service's namespace—for example, s3 for Amazon S3 and ec2 for Amazon EC2. When an access key is used more than once in a 15-minute span, only the first use is recorded in this field.",
  },
  cert_1_active: {
    group: GroupsE.CERTIFICATES,
    title: 'Cert 1 active',
    description:
      "When the user has an X.509 signing certificate and that certificate's status is Active, this value is YES. Otherwise it is NO.",
  },
  cert_1_last_rotated: {
    group: GroupsE.CERTIFICATES,
    title: 'Cert 1 last rotated',
    isDate: true,
    description:
      "The date and time, in ISO 8601 date-time format, when the user's signing certificate was created or last changed. If the user does not have an active signing certificate, the value in this field is -.",
  },
  cert_2_active: {
    group: GroupsE.CERTIFICATES,
    title: 'Cert 2 active',
    description:
      "When the user has a second X.509 signing certificate and that certificate's status is Active, this value is YES. Otherwise it is NO.",
  },
  cert_2_last_rotated: {
    group: GroupsE.CERTIFICATES,
    title: 'Cert 2 last rotated',
    isDate: true,
    description:
      "The date and time, in ISO 8601 date-time format, when the user's second signing certificate was created or last changed. If the user does not have a second active signing certificate, the value in this field is -.",
  },
};

const descriptionDictOkta: DescriptionDictT = {
  id: {title: 'Foreign ID', description: 'Unique key for user', group: GroupsE.ACCOUNT_INFO},
  login: {title: 'Username', group: GroupsE.ACCOUNT_INFO, description: 'Username on remote system'},
  created: {
    title: 'Created',
    isDate: true,
    description: 'Timestamp when user was created',
    group: GroupsE.ACCOUNT_INFO,
  },
  activated: {
    title: 'Activated',
    isDate: true,
    description: 'Timestamp when transition to ACTIVE status completed',
    group: GroupsE.ACCOUNT_INFO,
  },
  lastUpdated: {
    title: 'Last Updated',
    isDate: true,
    description: 'Timestamp when user was last updated',
    group: GroupsE.ACCOUNT_INFO,
  },
  statusChanged: {
    title: 'Status Changed',
    isDate: true,
    description: 'Timestamp when status last changed',
    group: GroupsE.ACCOUNT_INFO,
  },
  passwordChanged: {
    title: 'Password Changed',
    isDate: true,
    description: 'Timestamp when password last changed',
    group: GroupsE.ACCOUNT_INFO,
  },
  lastLogin: {title: 'Last Login', isDate: true, description: 'Timestamp of last login', group: GroupsE.ACCOUNT_INFO},
};

const parseValue = (
  value: string | boolean,
  isDate: boolean = false,
  shortDate?: boolean
): {text: string | React.ReactElement; class: string} => {
  if (isDate && !_.isEmpty(value) && value !== 'N/A' && !_.isBoolean(value)) {
    if (shortDate) {
      return {
        text: (
          <Tooltip content={formatFullDate(moment(value).toISOString())} position="bottom">
            {formatDateFromNow(moment(value).toISOString()).toString()}
          </Tooltip>
        ),
        class: '',
      };
    } else {
      return {
        text: `${formatDateFromNow(moment(value).toISOString()).toString()} (${formatFullDate(
          moment(value).toISOString()
        )})`,
        class: '',
      };
    }
  }

  switch (value) {
    case 'true':
    case true: {
      return {text: 'Yes', class: 'success'};
    }
    case 'false':
    case false: {
      return {text: 'No', class: 'warning'};
    }
    case '':
    case null:
    case undefined:
    case 'N/A': {
      return {text: '-', class: ''};
    }
    default: {
      return {text: value, class: ''};
    }
  }
};

const getDictToUse = (type: ConnectionServiceE | 'custom-system' | undefined): DescriptionDictT => {
  switch (type) {
    case ConnectionServiceE.AWS:
      return descriptionDictAws;
    case ConnectionServiceE.OKTA:
      return descriptionDictOkta;
    case ConnectionServiceE.GAPPS:
      return descriptionDictGoogleWorkspace;
    default:
      return {};
  }
};

export enum WarningMessageEnum {
  KEY1 = 'Key #1 Needs to be Rotated',
  KEY2 = 'Key #2 Needs to be Rotated',
  CERT1 = 'Certificate #1 Needs to be Rotated',
  CERT2 = 'Certificate #2 Needs to be Rotated',
  MFA = 'Password is enabled or set, Multi-factor authentication is recommended to be enabled',
  USAGE = 'Usage % is below threshold',
  GENERIC = 'Please check the account settings',
}

export const accountSecurityWarning = (
  userAccount: Account,
  additionalInformation: ConnectorsDataT | undefined
): {mfa: boolean; accessKey1: boolean; accessKey2: boolean; cert1: boolean; cert2: boolean} => {
  const dynamicThreshold = moment
    .duration(
      userAccount.rootResource.connector?.accessKeyRotationValue,
      (userAccount.rootResource.connector?.accessKeyRotationUnit || 'months') as moment.unitOfTime.DurationConstructor
    )
    .asMinutes();

  const defaultTreshold = moment.duration(3, 'months').asMinutes(); // by default 3 months
  const lastRotatedMinutesThresholdDanger = userAccount.isAuthzOwner ? dynamicThreshold : defaultTreshold;
  const key1LastRotated = _.get(additionalInformation, 'data.data.access_key_1_last_rotated');
  const key2LastRotated = _.get(additionalInformation, 'data.data.access_key_2_last_rotated');
  const key1Active = _.get(additionalInformation, 'data.data.access_key_1_active') === 'true';
  const key2Active = _.get(additionalInformation, 'data.data.access_key_2_active') === 'true';
  const cert1LastRotated = _.get(additionalInformation, 'data.data.cert_1_last_rotated');
  const cert2LastRotated = _.get(additionalInformation, 'data.data.cert_2_last_rotated');
  const cert1Active = _.get(additionalInformation, 'data.data.cert_1_active') === 'true';
  const cert2Active = _.get(additionalInformation, 'data.data.cert_2_active') === 'true';
  const passwordEnabled = _.get(additionalInformation, 'data.data.password_enabled') === 'true';
  return {
    mfa: _.get(additionalInformation, 'data.data.mfa_active') === 'false' && passwordEnabled,
    accessKey1:
      key1Active &&
      key1LastRotated !== 'N/A' &&
      moment().diff(key1LastRotated, 'minutes') >= lastRotatedMinutesThresholdDanger,
    accessKey2:
      key2Active &&
      key2LastRotated !== 'N/A' &&
      moment().diff(key2LastRotated, 'minutes') >= lastRotatedMinutesThresholdDanger,
    cert1:
      cert1Active &&
      cert1LastRotated !== 'N/A' &&
      moment().diff(cert1LastRotated, 'minutes') >= lastRotatedMinutesThresholdDanger,
    cert2:
      cert2Active &&
      cert2LastRotated !== 'N/A' &&
      moment().diff(cert2LastRotated, 'minutes') >= lastRotatedMinutesThresholdDanger,
  };
};

export const getAccountSecurityWarningMessages = (userAccount: Account): WarningMessageEnum[] => {
  const accountWarnings = accountSecurityWarning(userAccount, userAccount.additionalInformation);
  const messages: WarningMessageEnum[] = [];
  if (accountWarnings.accessKey1) {
    messages.push(WarningMessageEnum.KEY1);
  }

  if (accountWarnings.accessKey2) {
    messages.push(WarningMessageEnum.KEY2);
  }
  if (accountWarnings.cert1) {
    messages.push(WarningMessageEnum.CERT1);
  }

  if (accountWarnings.cert2) {
    messages.push(WarningMessageEnum.CERT2);
  }

  if (accountWarnings.mfa) {
    messages.push(WarningMessageEnum.MFA);
  }
  if (userAccount.nodeImportState?.warning && !_.isNil(userAccount.usage) && userAccount.usage < 30) {
    messages.push(WarningMessageEnum.USAGE);
  }

  return messages;
};

const AdditionalInformationForAccount = observer((props: AdditionalInformationProps) => {
  const {userAccount, resource, showGroup} = props;
  const [issueKeys, setIssueKeys] = useState<string[]>([]);

  type ElementStringT = React.ReactElement | string;
  type AdditionalInformationT = {
    description: ElementStringT;
    label: ElementStringT;
    value: {content: ElementStringT; class?: string};
    id: string;
  };

  type FieldDataT = {value: {text: ElementStringT; class: string}; description: string | undefined};
  type AdditionalInformationCertificateT = {
    id: string;
    title: FieldDataT;
    active: FieldDataT;
    lastRotated: FieldDataT;
  };
  type AdditionalInformationAccessKeysT = {
    id: string;
    title: FieldDataT;
    active: FieldDataT;
    lastRotated: FieldDataT;
    lastUsed: FieldDataT;
    lastUsedRegion: FieldDataT;
    lastUsedService: FieldDataT;
  };

  const parseAdditionalInformationCertificate = (props: {
    additionalInformation: ConnectorsDataT | undefined;
    dictToUse: DescriptionDictT;
    group: GroupsE;
  }): AdditionalInformationCertificateT[] => {
    const {additionalInformation, dictToUse, group} = props;
    if (_.isEmpty(_.get(additionalInformation, 'data.data', undefined))) {
      return [];
    }
    if (group === GroupsE.CERTIFICATES) {
      const data = (additionalInformation?.data as ThirdPartyDataOT).data;
      return [
        {
          id: 'certificate_1',
          title: {value: parseValue('Certificate #1 (X.509)', false), description: ''},
          active: {value: parseValue(data.cert_1_active, false), description: dictToUse.cert_1_active?.description},
          lastRotated: {
            value: parseValue(data.cert_1_last_rotated, true),
            description: dictToUse.cert_1_last_rotated?.description,
          },
        },
        {
          id: 'certificate_2',
          title: {value: parseValue('Certificate #2 (X.509)', false), description: ''},
          active: {value: parseValue(data.cert_2_active, false), description: dictToUse.cert_2_active?.description},
          lastRotated: {
            value: parseValue(data.cert_2_last_rotated, true),
            description: dictToUse.cert_2_last_rotated?.description,
          },
        },
      ];
    }
    return [];
  };

  const parseAdditionalInformationAccessKeys = (props: {
    additionalInformation: ConnectorsDataT | undefined;
    dictToUse: DescriptionDictT;
    group: GroupsE;
    onlyAccessKeysWithIssues?: boolean;
  }): AdditionalInformationAccessKeysT[] => {
    const {additionalInformation, dictToUse, group} = props;
    const returnData: AdditionalInformationAccessKeysT[] = [];
    if (_.isEmpty(_.get(additionalInformation, 'data.data', undefined))) {
      return returnData;
    }

    if (group === GroupsE.ACCESS_KEYS) {
      const data = (additionalInformation?.data as ThirdPartyDataOT).data;

      const parsedLastRotated = (accessKeyLastRotatedField: any, forAccessKey: 'access_key_1' | 'access_key_2') => {
        const parsed = parseValue(accessKeyLastRotatedField, true, true);
        return {
          text: <>{parsed.text}</>,
          class: _.includes(issueKeys, forAccessKey) ? 'text-danger body5' : '',
        };
      };

      returnData.push(
        {
          id: 'access_key_1',
          title: {
            value: parseValue('Access Key #1', false),
            description: dictToUse.access_key_1_active.description,
          },
          active: {
            value: parseValue(data.access_key_1_active, false),
            description: dictToUse.access_key_1_active.description,
          },
          lastRotated: {
            value: parsedLastRotated(data.access_key_1_last_rotated, 'access_key_1'),
            description: dictToUse.access_key_1_last_rotated.description,
          },
          lastUsed: {
            value: parseValue(data.access_key_1_last_used_date, true, true),
            description: dictToUse.access_key_1_last_used_date.description,
          },
          lastUsedRegion: {
            value: parseValue(data.access_key_1_last_used_region, false),
            description: dictToUse.access_key_1_last_used_region.description,
          },
          lastUsedService: {
            value: parseValue(data.access_key_1_last_used_service, false),
            description: dictToUse.access_key_1_last_used_service.description,
          },
        },
        {
          id: 'access_key_2',
          title: {
            value: parseValue('Access Key #2', false),
            description: dictToUse.access_key_2_active.description,
          },
          active: {
            value: parseValue(data.access_key_2_active, false),
            description: dictToUse.access_key_2_active.description,
          },
          lastRotated: {
            value: parsedLastRotated(data.access_key_2_last_rotated, 'access_key_2'),
            description: dictToUse.access_key_2_last_rotated.description,
          },
          lastUsed: {
            value: parseValue(data.access_key_2_last_used_date, true, true),
            description: dictToUse.access_key_2_last_used_date.description,
          },
          lastUsedRegion: {
            value: parseValue(data.access_key_2_last_used_region, false),
            description: dictToUse.access_key_2_last_used_region.description,
          },
          lastUsedService: {
            value: parseValue(data.access_key_2_last_used_service, false),
            description: dictToUse.access_key_2_last_used_service.description,
          },
        }
      );
      if (props.onlyAccessKeysWithIssues) {
        if (!_.includes(issueKeys, 'access_key_1')) {
          returnData.shift();
        }
        if (!_.includes(issueKeys, 'access_key_2')) {
          returnData.pop();
        }
      }
    }
    return returnData;
  };

  const parseAdditionalInformation = (props: {
    additionalInformation: ConnectorsDataT | undefined;
    dictToUse: DescriptionDictT;
    group: GroupsE;
  }): AdditionalInformationT[] => {
    const {additionalInformation, dictToUse, group} = props;

    if (_.isEmpty(additionalInformation?.data)) {
      return [];
    }

    const values = !_.isNil(_.get(additionalInformation?.data, ['data']))
      ? (additionalInformation?.data as ThirdPartyDataOT)
      : additionalInformation;

    if (_.isEmpty(values)) {
      return [];
    }

    const filteredElements = _.omitBy(values?.data, (_value, key) => {
      return group !== dictToUse[key]?.group;
    });

    return _.map(filteredElements, (v: any, key: string) => {
      const parsedValue = parseValue(v, dictToUse[key]?.isDate);
      const info: any = {
        description: dictToUse[key]?.description ? (
          <Tooltip content={dictToUse[key].description}>
            <Icon type="moreInfo" size="sm" className="tr-inline-block tr-text-trustle-link" />
          </Tooltip>
        ) : (
          ''
        ),
        id: key,
        label: dictToUse[key] ? <strong>{dictToUse[key].title}</strong> : <strong>{_.startCase(key)}</strong>,
        value: {content: parsedValue.text, class: parsedValue.class},
      };

      return info;
    });
  };

  const additionalInformation = userAccount.additionalInformation;
  const dictToUse = getDictToUse(resource.type);

  const isGappsConnector = resource.type === ConnectionServiceE.GAPPS;
  const columnWidth = {
    description: 5,
    label: isGappsConnector ? 45 : 30,
    value: isGappsConnector ? 50 : 65,
  };

  useEffect(() => {
    if (resource.type === ConnectionServiceE.AWS) {
      const securityWarning = accountSecurityWarning(userAccount, additionalInformation);
      if (securityWarning.accessKey1) {
        setIssueKeys((e) => {
          return e.concat(['access_key_1']);
        });
      }

      if (securityWarning.accessKey2) {
        setIssueKeys((e) => {
          return e.concat(['access_key_2']);
        });
      }
    }
  }, [additionalInformation]);

  const renderContent = (group: GroupsE) => {
    let additionalInformationMFA: AdditionalInformationT[];
    let additionalInformationCertificates: AdditionalInformationCertificateT[];
    let additionalInformationAccessKeys: AdditionalInformationAccessKeysT[];
    let mfaActive: boolean | undefined;
    let mfaData: AdditionalInformationT | undefined;
    let mfaDataAvailable: boolean;
    switch (group) {
      case GroupsE.ACCOUNT_INFO:
        return (
          <Table
            data={parseAdditionalInformation({additionalInformation, dictToUse, group: GroupsE.ACCOUNT_INFO})}
            tableKey={GroupsE.ACCOUNT_INFO}
            columns={[
              {
                dataField: 'description',
                headerClasses: 'text-left',
                text: 'Account info',
                classes: 'text-left pl-2',
                style: {width: `${columnWidth.description}%`},
                formatter: (_cell: any, row: any) => {
                  return row.description;
                },
              },
              {dataField: 'label', text: '', classes: 'text-left', style: {width: `${columnWidth.label}%`}},
              {
                dataField: 'value',
                text: '',
                style: {width: `${columnWidth.value}%`},
                formatter: (cell: {class?: string; content: ElementStringT}) => {
                  return cell.class ? (
                    <Badge variant={cell.class as BadgeVariantEnum}>{cell.content}</Badge>
                  ) : (
                    cell.content
                  );
                },
              },
            ]}
            emptyMessage={<div>No additional information available</div>}
            striped={true}
            bordered={false}
          ></Table>
        );
      case GroupsE.MFA:
        additionalInformationMFA = parseAdditionalInformation({additionalInformation, dictToUse, group: GroupsE.MFA});
        mfaData = _.isEmpty(additionalInformationMFA) ? undefined : _.head(additionalInformationMFA);
        mfaDataAvailable = !_.isEmpty(mfaData) && !_.isEmpty(_.get(mfaData, 'value.content', undefined));
        mfaActive = _.get(mfaData, 'value.content', undefined) === 'Yes';
        return (
          <Card>
            <Card.Header className="tr-pt-2 tr-pb-2 tr-pl-3 tr-pr-3 bg-white">
              <span className="font-weight-bold">Multi-factor authentication</span>
            </Card.Header>
            <Card.Body className="tr-pt-2 tr-pb-2 tr-pl-3 tr-pr-3">
              {!mfaDataAvailable && (
                <div className="tr-mb-4 tr-tr-mb-5">
                  <div className="ms-auto tr-text-center">
                    <div className="body6 tr-mt-4">No additional information available</div>
                  </div>
                </div>
              )}
              {(() => {
                const passwordEnabled = _.get(additionalInformation, 'data.data.password_enabled') === 'true';
                if (!passwordEnabled) {
                  return (
                    <div className="tr-text-center tr-mt-5 tr-mb-5">
                      <div>This is an API-only user</div>
                    </div>
                  );
                } else if (mfaDataAvailable && mfaData) {
                  return (
                    <>
                      <div className="tr-flex tr-mb-4 tr-mb-5">
                        <div className="ms-auto">
                          <span className="font-weight-bold mr-4">{mfaData.label}</span>
                        </div>
                        <div className="ms-auto">
                          <Badge variant={mfaData.value.class as BadgeVariantEnum}>{mfaData.value.content}</Badge>
                        </div>
                      </div>
                      <div className="tr-text-center tr-mt-5 tr-mb-2">
                        <div className="tr-w-full">
                          <span>
                            {mfaActive ? (
                              <Icon type="enabled" />
                            ) : (
                              <span className="tr-text-error tr-pl-1">
                                <Icon type="warning" />
                              </span>
                            )}
                          </span>
                          <span className="tr-ml-2">
                            {mfaActive
                              ? 'Well done! You have MFA active in your account'
                              : 'We suggest turning on MFA to increase your security.'}
                          </span>
                        </div>
                      </div>
                      <div className="tr-text-center tr-mt-1">
                        <div>
                          <span>{mfaActive ? 'See what is MFA' : 'See Instructions'} </span>
                          <a
                            href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_credentials_mfa.html"
                            className="underline"
                            target="_blank"
                            rel="noreferrer"
                          >
                            here
                          </a>
                        </div>
                      </div>
                    </>
                  );
                }
              })()}
            </Card.Body>
          </Card>
        );
      case GroupsE.CERTIFICATES:
        additionalInformationCertificates = parseAdditionalInformationCertificate({
          additionalInformation,
          dictToUse,
          group: GroupsE.CERTIFICATES,
        });
        return (
          <Table
            data={additionalInformationCertificates}
            tableKey={GroupsE.CERTIFICATES}
            caption="Signing Certificates"
            columns={[
              {
                dataField: 'title',
                text: 'Name',
                formatter: (cell: FieldDataT, _row: any) => {
                  return cell.value.text;
                },
                style: {width: `30%`},
                headerStyle: {width: `30%`},
              },
              {
                dataField: 'active',
                text: 'Active',
                headerFormatter: (column: any, _index: any, _component: any) => {
                  return (
                    <Tooltip content={dictToUse.cert_1_active.description} position="bottom">
                      {column.text}
                    </Tooltip>
                  );
                },
                formatter: (cell: FieldDataT, _row: any) => {
                  return cell.value.class ? (
                    <Badge variant={cell.value.class as BadgeVariantEnum}>{cell.value.text}</Badge>
                  ) : (
                    cell.value.text
                  );
                },
                style: {width: `20%`},
                headerStyle: {width: `20%`},
              },
              {
                dataField: 'lastRotated',
                text: 'Last Rotated',
                headerFormatter: (column: any, _index: any, _component: any) => {
                  return (
                    <Tooltip content={dictToUse.cert_1_last_rotated.description} position="bottom">
                      {column.text}
                    </Tooltip>
                  );
                },
                formatter: (cell: FieldDataT, _row: any) => {
                  return cell.value.class ? (
                    <Badge variant={cell.value.class as BadgeVariantEnum}>{cell.value.text}</Badge>
                  ) : (
                    cell.value.text
                  );
                },
                style: {width: `50%`},
                headerStyle: {width: `50%`},
              },
            ]}
            showRowCount={false}
            emptyMessage={<div>No additional information available</div>}
            striped={true}
            bordered={false}
          ></Table>
        );
      case GroupsE.ACCESS_KEYS:
        additionalInformationAccessKeys = parseAdditionalInformationAccessKeys({
          additionalInformation,
          dictToUse,
          group: GroupsE.ACCESS_KEYS,
        });
        return (
          <>
            <Table
              data={additionalInformationAccessKeys}
              tableKey={GroupsE.ACCESS_KEYS}
              caption="Access Keys"
              columns={[
                {
                  dataField: 'title',
                  text: 'Name',
                  formatter: (cell: FieldDataT, _row: any) => {
                    return cell.value.text;
                  },
                  style: {width: '15%'},
                  headerStyle: {width: '15%'},
                },
                {
                  dataField: 'active',
                  text: 'Active',
                  headerFormatter: (column: any, _index: any, _component: any) => {
                    return (
                      <Tooltip content={dictToUse.access_key_1_active.description} position="bottom">
                        {column.text}
                      </Tooltip>
                    );
                  },
                  formatter: (cell: FieldDataT, _row: any) => {
                    return cell.value.class ? (
                      <Badge variant={cell.value.class as BadgeVariantEnum}>{cell.value.text}</Badge>
                    ) : (
                      cell.value.text
                    );
                  },
                  style: {width: '12%'},
                  headerStyle: {width: '12%'},
                },
                {
                  dataField: 'lastRotated',
                  text: 'Last Rotated',
                  style: {width: '23%'},
                  headerStyle: {width: '23%'},
                  headerFormatter: (column: any, _index: any, _component: any) => {
                    return (
                      <Tooltip content={dictToUse.access_key_1_last_rotated.description} position="bottom">
                        {column.text}
                      </Tooltip>
                    );
                  },
                  formatter: (cell: FieldDataT, _row: any) => {
                    return <span className={`${cell.value.class}`}>{cell.value.text}</span>;
                  },
                },
                {
                  dataField: 'lastUsed',
                  text: 'Last Used',
                  style: {width: '20%'},
                  headerStyle: {width: '20%'},
                  headerFormatter: (column: any, _index: any, _component: any) => {
                    return (
                      <Tooltip content={dictToUse.access_key_1_last_used_date.description} position="bottom">
                        {column.text}
                      </Tooltip>
                    );
                  },
                  formatter: (cell: FieldDataT, _row: any) => {
                    return cell.value.class ? (
                      <Badge variant={cell.value.class as BadgeVariantEnum}>{cell.value.text}</Badge>
                    ) : (
                      cell.value.text
                    );
                  },
                },
                {
                  dataField: 'lastUsedRegion',
                  text: 'Region',
                  headerFormatter: (column: any, _index: any, _component: any) => {
                    return (
                      <Tooltip content={dictToUse.access_key_1_last_used_region.description} position="bottom">
                        {column.text}
                      </Tooltip>
                    );
                  },
                  formatter: (cell: FieldDataT, _row: any) => {
                    return cell.value.class ? (
                      <Badge variant={cell.value.class as BadgeVariantEnum}>{cell.value.text}</Badge>
                    ) : (
                      cell.value.text
                    );
                  },
                },
                {
                  dataField: 'lastUsedService',
                  text: 'Service',
                  style: {width: '18%'},
                  headerStyle: {width: '18%'},
                  headerFormatter: (column: any, _index: any, _component: any) => {
                    return (
                      <>
                        <Tooltip content={dictToUse.access_key_1_last_used_service.description} position="bottom">
                          {column.text}
                        </Tooltip>
                      </>
                    );
                  },
                  formatter: (cell: FieldDataT, _row: any) => {
                    return (
                      <Tooltip content={cell.description} position="bottom">
                        {cell.value.class ? (
                          <Badge variant={cell.value.class as BadgeVariantEnum}>{cell.value.text}</Badge>
                        ) : (
                          cell.value.text
                        )}
                      </Tooltip>
                    );
                  },
                },
              ]}
              striped={true}
              bordered={false}
            ></Table>
            {!_.isEmpty(issueKeys) && (
              <Table
                tableKey={'emptyissuekeys'}
                data={parseAdditionalInformationAccessKeys({
                  additionalInformation,
                  dictToUse,
                  group: GroupsE.ACCESS_KEYS,
                  onlyAccessKeysWithIssues: true,
                })}
                caption="Issues"
                headerWrapperClasses="hidden"
                columns={[
                  {
                    dataField: 'title',
                    text: '',
                    formatter: (cell: FieldDataT, _row: any) => {
                      return cell.value.text;
                    },
                    style: {textAlign: 'left', width: '20%'},
                  },
                  {
                    dataField: 'lastRotated',
                    text: '',
                    formatter: (cell: FieldDataT, _row: any) => {
                      return <span className={`${cell.value.class} flex-align`}>{cell.value.text}</span>;
                    },
                    style: {textAlign: 'left'},
                  },
                  {
                    dataField: 'lastRotated',
                    text: '',
                    formatter: () => {
                      return (
                        <a
                          href="https://docs.aws.amazon.com/IAM/latest/UserGuide/id_credentials_access-keys.html#Using_RotateAccessKey"
                          className="underline"
                          target="_blank"
                          rel="noreferrer"
                        >
                          More information
                        </a>
                      );
                    },
                  },
                ]}
                striped={true}
                wrapperClasses="simple-table-bordered mt-1"
                bordered={false}
              ></Table>
            )}
          </>
        );
      default:
        return <></>;
    }
  };

  return <>{renderContent(showGroup)}</>;
});

export default AdditionalInformationForAccount;
