import {observer} from 'mobx-react';
import React, {ReactNode} from 'react';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import {useFeatureFlags, useRootStore} from 'src/lib/hooks';
import _ from 'lodash';
import {Permission} from 'src/stores/domainObjects/Permission';
import {Button} from '@trustle/component-library';
import TreeTabPanel from '../TreeView/TreeTabPanel';
import ServiceRadioSelector from './ServiceRadioSelector';
import {Resource} from 'src/stores/domainObjects/Resource';

type ActiveTabPropsT = {
  categorizedSystems: _.Dictionary<Resource[]>;
  onRequestAccess: (p?: Permission) => void;
  emptyMessage?: ReactNode;
};

const ActiveTab = observer(({categorizedSystems, onRequestAccess, emptyMessage = ''}: ActiveTabPropsT) => {
  const history = useHistory();
  const {currentUser} = useRootStore();
  const featureFlagViewer = useFeatureFlags();
  const hideGenericRequests = featureFlagViewer.isEnabled('hide_generic_requests');
  const accessRecords = currentUser.nonPlaceholderAccesses.filter((access) => {
    return access.statusValue > 1 && access.forSystem !== undefined;
  });
  //All system ids related to accesses for later use
  const sids = _.map(accessRecords, 'rid');

  const defaultSystemType = _.keys(categorizedSystems)[0];

  return (
    <>
      <div className="my-3 tr-flex">
        <div className="tr-grow">
          <h2>Active Systems</h2>
          <p>Select a system below to see active Resources</p>
        </div>
        {!hideGenericRequests && (
          <div className="tr-flex justify-content-lg-end align-items-center px-lg-0">
            <span>Don't see what you are looking for?</span>
            <Button variant="primary" size="md" onClick={() => onRequestAccess()} className="ml-3">
              Request Access
            </Button>
          </div>
        )}
      </div>
      <Switch>
        <Route
          path={`/access/active/:type`}
          render={(props) => {
            const {type} = props.match.params;

            // redirect back to /active if type doesn't exist (user has typed something in url or data has changed since refresh)
            if (_.isEmpty(categorizedSystems[type])) {
              return <Redirect to="/access/active" />;
            }

            const systemsWithAccesses = _.filter(categorizedSystems[type], (system) => {
              return sids.includes(system.id);
            });

            return (
              <>
                <ServiceRadioSelector
                  clickHandler={(type) => history.push(`/access/active/${type}`)}
                  categorizedSystems={categorizedSystems}
                />

                <TreeTabPanel
                  hideRoots={false}
                  user={currentUser}
                  hideFilters={false}
                  fixedFilters={{Status: 'Active,Pending,Provisioning,De-provisioning', Expand: 'All'}}
                  resources={systemsWithAccesses}
                  prefilteredAccesses={accessRecords}
                  hidePermissionsWithoutAccesses={true}
                  canEditResource={false}
                  allowExpand={false}
                  availableFilters={['Permission']}
                  availableSorts={['Name']}
                  displayActiveAccesses
                />
              </>
            );
          }}
        />
        {defaultSystemType && <Redirect exact from="/access/active" to={`/access/active/${defaultSystemType}`} />}
        <Route>{emptyMessage}</Route>
      </Switch>
    </>
  );
});

export default ActiveTab;
