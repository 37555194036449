import React from 'react';
import {UserResponse} from '../../hooks/useUsers';
import {IconButton} from '@trustle/component-library';
import {IfCondition} from '../../../../common/IfCondition';
import {useRootStore} from '../../../../../../lib/hooks';
import {UserAction} from '../../types';
import {UserActionModal} from './internal/UserActionModal';
import _ from 'lodash';
import axios from 'axios';

export function UserActions({user}: {user: UserResponse}) {
  const {currentUser, org} = useRootStore();
  const {toast} = useRootStore();

  const [action, setAction] = React.useState<UserAction | null>();

  const {
    id,
    userStatus,
    type,
    allowLogin,
    confirmed,
    invitedAt,
    isOrgOwner,
    sourceOfTruth,
    invitationStatus,
    canSwitchSoT,
  } = user;

  const isOffboarded = userStatus === 'offboarded';
  const isCurrentUser = user.id === currentUser.id;
  const isCustomerOrSystem = ['customer', 'system'].includes(type);
  const isTrustleAuthority = !sourceOfTruth || sourceOfTruth === 'trustle';

  const canSwitchAuthority = !_.isEmpty(org.idpSettings) && canSwitchSoT;

  const handleToggleEnableDisable = async () => {
    try {
      await axios.post(`/api/orgs/admin/${id}/disable`, {isOwner: !allowLogin});
      toast.success(!allowLogin ? 'User enabled successfully' : 'User disabled successfully');
    } catch (error) {
      toast.error(!allowLogin ? "User couldn't be enabled" : "User couldn't be disabled");
    }
  };

  const handleActivateUser = async () => {
    try {
      await axios.post(`/api/orgs/admin/${id}/verifyUser`, {isOwner: !allowLogin});
      toast.success('User activated successfully');
    } catch (error) {
      toast.error("User couldn't be activated");
    }
  };

  const actionButtonClasses =
    'tr-w-[40px] tr-h-[40px] hover:tr-bg-[#E4ECFA] tr-opacity-70 hover:tr-opacity-100 active:tr-bg-[#C2E5FF]';

  return (
    <div className="tr-flex tr-gap-2 tr-items-center tr-bg-[#F8F9FC]">
      <IconButton
        key={`user-details-${id}`}
        title="User Details"
        onClick={() => {
          setAction('VIEW_USER');
        }}
        variant="ternary"
        icon="details"
        className={actionButtonClasses}
      />
      <IfCondition condition={isOffboarded}>
        <IconButton
          key={`delete-user-${id}`}
          onClick={() => {
            setAction('DELETE');
          }}
          variant="ternary"
          title="Delete user"
          icon="remove"
          className={actionButtonClasses}
        />
      </IfCondition>
      <IfCondition condition={!isOffboarded}>
        <IfCondition condition={canSwitchAuthority}>
          <IconButton
            key={`switch-user-authority-${id}`}
            onClick={() => {
              setAction('SWITCH_AUTHORITY');
            }}
            variant="ternary"
            title="Switch Authority"
            icon="refresh"
            className={actionButtonClasses}
          />
        </IfCondition>
        <IfCondition condition={!isCurrentUser}>
          <IfCondition condition={allowLogin && !confirmed && !isCustomerOrSystem}>
            <IconButton
              key={`send-invite-${id}`}
              onClick={() => {
                setAction(invitedAt ? 'RESEND_INVITE' : 'SEND_INVITE');
              }}
              variant="ternary"
              title={invitedAt ? 'Resend Invite' : 'Send Invite'}
              icon="email"
              className={actionButtonClasses}
            />
          </IfCondition>
          <IfCondition condition={type === 'employee'}>
            <IconButton
              key={`toggle-owner-${id}`}
              onClick={() => {
                setAction(isOrgOwner ? 'REMOVE_OWNER_ROLE' : 'ADD_OWNER_ROLE');
              }}
              variant="ternary"
              title={isOrgOwner ? 'Remove Owner Role' : 'Add Owner Role'}
              icon={isOrgOwner ? 'removeOwnerRole' : 'addOwnerRole'}
              className={actionButtonClasses}
            />
          </IfCondition>
          <IfCondition condition={isTrustleAuthority}>
            <IfCondition condition={invitationStatus !== 'pending'}>
              <IfCondition condition={!isCustomerOrSystem}>
                <IconButton
                  key={`disable-user-${id}`}
                  onClick={handleToggleEnableDisable}
                  variant="ternary"
                  title={allowLogin ? 'Disable User' : 'Enable User'}
                  icon={allowLogin ? 'disableUser' : 'enableUser'}
                  className={actionButtonClasses}
                />
              </IfCondition>
              <IconButton
                key={`offboard-user-${id}`}
                onClick={() => {
                  setAction('START_OFFBOARD');
                }}
                variant="ternary"
                title="Offboard User"
                icon={'startOffboard'}
                className={actionButtonClasses}
              />
            </IfCondition>
            <IfCondition condition={!confirmed && isCustomerOrSystem}>
              <IconButton
                key={`activate-user-${id}`}
                onClick={handleActivateUser}
                variant="ternary"
                title="Activate User"
                icon="task"
                className={actionButtonClasses}
              />
            </IfCondition>
          </IfCondition>
        </IfCondition>
      </IfCondition>
      {action && <UserActionModal user={user} action={action} setAction={setAction} />}
    </div>
  );
}
