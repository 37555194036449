import React from 'react';
import {UserAction} from '../../../types';
import {IfCondition} from '../../../../../common/IfCondition';
import {UserDetailsModal} from './user-details-modal/UserDetailsModal';
import {UserResponse} from '../../../hooks/useUsers';
import {UserSendInviteModal} from './user-send-invite-modal/UserSendInviteModal';
import {UserToggleOwnerRoleModal} from './user-toggle-owner-role-modal/UserToggleOwnerRoleModal';
import {UserToggleAuthorityModal} from './user-toggle-authority-modal/UserToggleAuthorityModal';
import {UserDeleteModal} from './user-delete-modal/UserDeleteModal';
import {UserOffboardModal} from './user-offboard-modal/UserOffboardModal';
import {useUsersListed} from '../../../UsersListedContext';

type Props = {
  user: UserResponse;
  action: UserAction;
  setAction: (action: UserAction | null) => void;
};

export function UserActionModal({user, action, setAction}: Props) {
  const setActionToNull = () => setAction(null);
  const {refetchUsers} = useUsersListed();

  const handleConfirmAction = () => {
    void refetchUsers();
  };

  return (
    <>
      <IfCondition condition={action === 'VIEW_USER'}>
        <UserDetailsModal user={user} handleCloseFn={setActionToNull} />
      </IfCondition>
      <IfCondition condition={['SEND_INVITE', 'RESEND_INVITE'].includes(action)}>
        <UserSendInviteModal
          user={user}
          handleCloseFn={setActionToNull}
          reSend={action === 'RESEND_INVITE'}
          onConfirm={handleConfirmAction}
        />
      </IfCondition>
      <IfCondition condition={['ADD_OWNER_ROLE', 'REMOVE_OWNER_ROLE'].includes(action)}>
        <UserToggleOwnerRoleModal user={user} handleCloseFn={setActionToNull} onConfirm={handleConfirmAction} />
      </IfCondition>
      <IfCondition condition={action === 'SWITCH_AUTHORITY'}>
        <UserToggleAuthorityModal user={user} handleCloseFn={setActionToNull} onConfirm={handleConfirmAction} />
      </IfCondition>
      <IfCondition condition={action === 'DELETE'}>
        <UserDeleteModal user={user} handleCloseFn={setActionToNull} onConfirm={handleConfirmAction} />
      </IfCondition>
      <IfCondition condition={action === 'START_OFFBOARD'}>
        <UserOffboardModal user={user} handleCloseFn={setActionToNull} onConfirm={handleConfirmAction} />
      </IfCondition>
    </>
  );
}
