import {FilterToApply} from '../../../types';
import _ from 'lodash';

const FILTER_VIEW_PREFIX = 'filter_view_';

export function useSaveFilterView() {
  const saveFilterView = (viewName: string, filters: FilterToApply[]) => {
    localStorage.setItem(FILTER_VIEW_PREFIX + viewName, JSON.stringify(filters));
  };

  const getFilterView = (viewName: string) => {
    return localStorage.getItem(FILTER_VIEW_PREFIX + viewName);
  };

  const matchedFilterView = (filters: FilterToApply[]) => {
    // Avoid comparing label
    // Sort both arrays by key for better equality
    const normalize = (f: FilterToApply[]) =>
      f.map(({key, value}) => ({
        key,
        value: Array.isArray(value) ? value.map((v) => ({value: v.value})) : value && {value: value.value},
      }));

    return getAllFilterViews().find(({filters: viewFilters}) =>
      _.isEqual(normalize(_.sortBy(viewFilters, ['key'])), normalize(_.sortBy(filters, ['key'])))
    );
  };

  const deleteFilterView = (viewName: string) => {
    localStorage.removeItem(FILTER_VIEW_PREFIX + viewName);
  };

  const getAllFilterViews = () => {
    const allFilterViews: {name: string; filters: FilterToApply[]}[] = [];
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      if (key?.startsWith(FILTER_VIEW_PREFIX)) {
        allFilterViews.push({
          name: key.slice(FILTER_VIEW_PREFIX.length),
          filters: JSON.parse(localStorage.getItem(key) as string) as FilterToApply[],
        });
      }
    }
    return allFilterViews;
  };

  return {saveFilterView, getFilterView, getAllFilterViews, matchedFilterView, deleteFilterView};
}
