import {ItemIconTypesEnum} from '@trustle/component-library/dist/types';

export type LevelDefinitionT = {
  level: string;
  name: string;
  icon: ItemIconTypesEnum;
  description: string;
};

export const SensitivityLevelDefinitions: LevelDefinitionT[] = [
  {
    level: 'none',
    name: 'None',
    icon: 'level0',
    description: 'No approval required. Once the Requests are made, it is provisioned.',
  },
  {
    level: 'low',
    name: 'Low',
    icon: 'level1',
    description:
      'No approval required. However, a message is sent to let you know someone has requested and gained access.',
  },
  {
    level: 'medium',
    name: 'Medium',
    icon: 'level2',
    description: 'Manager approval required before access can be granted.',
  },
  {
    level: 'high',
    name: 'High',
    icon: 'level3',
    description: 'Manager and Owner approval required before access can be granted.',
  },
  {
    level: 'critical',
    name: 'Critical',
    icon: 'level4',
    description: 'Manager and Owner approval required, as well as Executive approval before access can be granted. ',
  },
];
