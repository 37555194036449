import React, {useEffect, useState} from 'react';
import {observer} from 'mobx-react';
import '../common/editConnection/EditConnection.scss';
import axios from 'axios';
import {Loading} from '@trustle/component-library';
import {useToasts} from 'react-toast-notifications';
import {ConnectionInfoT, ImportStatus, ResourceT} from 'src/types';
import {parseAndCleanUpQueryParams} from '../common/helpers';
import {useHistory, useLocation} from 'react-router-dom';
import TestConnection from '../common/editConnection/TestConnection';
import ShowAWSIdcAccount from './ShowAWSIdcAccount';
import {useConnectionCredentials} from 'src/lib/hooks';
import AWSIDCSetup from './AWSIDCSetup';

/**
 * Form for update the credentials of AWS IDC connection
 * Allow to test the connection and show errors
 *
 * @param props
 * @returns
 */
export default observer(function EditConnectionAWSIDC(props: {resource: ResourceT}) {
  const {resource} = props;
  const {addToast} = useToasts();
  const [connectionInfo, setConnectionInfo] = useState<ConnectionInfoT>();
  const [displayCredentialsForm, setDisplayCredentialsForm] = useState<boolean>(false);
  const [testConnectionInProgress, setTestConnectionInProgress] = useState<boolean>(false);
  const {testConnection: testConnectionAPI} = useConnectionCredentials();
  const [errorMsg, setErrorMsg] = useState<string>();
  const lastImport = resource.connector?.lastImport;
  const [connectorStatus] = useState<string | undefined>(lastImport?.status);

  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState<string | null>();

  async function loadConnection() {
    const connectionResponse = await axios.get(`/api/connect/${resource.connectionId}`);
    setConnectionInfo(connectionResponse.data); // Update state with fetched data
  }

  useEffect(() => {
    void loadConnection();
    parseAndCleanUpQueryParams(history, location, setError);
  }, [resource.connectionId]);

  if (!connectionInfo) {
    return <Loading />;
  }

  const {connection} = connectionInfo;
  const {serviceFriendlyName} = connection;

  async function onCredentialsUpdated() {
    await loadConnection();
    setDisplayCredentialsForm(false);
  }

  async function testConnection() {
    setTestConnectionInProgress(true);
    const {data: responseData} = await testConnectionAPI(connection.id, {autoTest: true});
    if (!responseData.ok) {
      const err = responseData.error.message ?? responseData.message ?? 'Connection failed';
      setErrorMsg(err);
      addToast('An error ocurred trying to validate the credentials', {
        appearance: 'error',
        autoDismiss: false,
      });
      setTestConnectionInProgress(false);
      return;
    }

    if (responseData.ok) {
      setErrorMsg('');
      addToast('Succesfully connected', {appearance: 'success', autoDismiss: true});
      setTestConnectionInProgress(false);
    }
  }

  return (
    <div>
      <div className="tr-my-2 no-padding">
        <div className="tr-w-full lg:tr-w-2/3">
          <TestConnection
            name={serviceFriendlyName!}
            connectionOwner={<ShowAWSIdcAccount authzOwner={connectionInfo.connection?.authzOwner}></ShowAWSIdcAccount>}
            connectorStatus={error ? ImportStatus.FAILED : connectorStatus!}
            onTest={testConnection}
            errorMsg={errorMsg}
            testing={testConnectionInProgress}
            onUpdate={() => setDisplayCredentialsForm(!displayCredentialsForm)}
            updating={displayCredentialsForm}
            lastImport={lastImport}
          />
          {displayCredentialsForm && lastImport && (
            <AWSIDCSetup connectionInfo={connectionInfo} onComplete={onCredentialsUpdated} editMode={true} />
          )}
        </div>
      </div>
    </div>
  );
});
