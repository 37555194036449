import _ from 'lodash';
import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {Loading} from '@trustle/component-library';
import {useRootStore} from 'src/lib/hooks';
import {SetupStepT} from 'src/types';
import './AdminSetupWrapper.scss';
import planDescriptions from './payments/PlanDescriptions';
import SetupCircle from './setupSteps/SetupCircle';
import SetupProgressCircles from './setupSteps/SetupProgressCircles';

const AdminSetupWrapper = observer(() => {
  const [loading, setLoading] = useState(true);
  const [modules, setModules] = useState<any>();
  const {org} = useRootStore();

  useEffect(() => {
    async function loadSteps() {
      const modules = await import('./setupSteps');
      setModules(modules);
      setLoading(false);
    }
    if (!modules) {
      void loadSteps();
    }
  }, []);

  if (loading || _.isNil(org) || org?.steps === undefined || org?.package === undefined) {
    return <Loading />;
  }

  const [finishedSteps, unfinishedSteps] = _.partition(org.steps, (step: SetupStepT) => {
    return step.finalized;
  });

  const planDescription = planDescriptions[org.package?.name ?? 'Free'];

  async function submitStepFinished(id: string) {
    await org!.markSetupStepDone(id);
  }

  return (
    <div className="step-detail-wrapper">
      <div className="step-detail-heading">
        <div className="step-detail-header-title">
          <h2>{planDescription.setupTitle}</h2>
          <span>{planDescription.setupDescription}</span>
        </div>
        <div className="step-detail-right-section">
          <SetupProgressCircles finishedSteps={finishedSteps} unfinishedSteps={unfinishedSteps} />
        </div>
      </div>

      {org.steps.map((gsStep, i) => {
        if (!_.isFunction(modules[gsStep.componentName])) {
          return (
            <div key={`${gsStep.id}-${i}`} className="step-wrapper">
              Step not Available
            </div>
          );
        }
        return (
          <div key={`${gsStep.id}-${i}`} className="step-wrapper">
            <SetupCircle step={gsStep} />
            <div className="custom-step-detail-heading ml-5">
              <div className="step-detail-header-title">
                <h2>{gsStep.name}</h2>
                <span>{gsStep.description}</span>
              </div>
              {modules[gsStep.componentName](gsStep, () => {
                void submitStepFinished(gsStep.id);
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default AdminSetupWrapper;
