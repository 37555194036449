import React from 'react';
import {observer} from 'mobx-react';
import {Button, Icon, Modal} from '@trustle/component-library';
import {useHistory} from 'react-router-dom';
import _ from 'lodash';
import {Resource} from 'src/stores/domainObjects/Resource';
import PermissionInLinkedResource from './PermissionInLinkedResource';

type PropsT = {
  resource: Resource;
  onClose: () => void;
  visible: boolean;
  referencingResources: Resource[];
};
const LinkedResources = observer(({resource, onClose, visible = false, referencingResources}: PropsT) => {
  const history = useHistory();
  return (
    <Modal visible={visible} width="md" data-testid="linkedRepos" title="Linked repositories" onClose={onClose}>
      {_.map(referencingResources, (item) => {
        return (
          <div
            key={item.id}
            className="tr-px-2 tr-py-1 tr-flex tr-flex-row tr-justify-between tr-items-center tr-border-solid tr-border-0 tr-border-t-[1px]  tr-border-gray-medium first:tr-border-none "
          >
            <div
              className="tr-text-trustle-link body5-normals tr-cursor-pointer"
              onClick={() => {
                onClose();
                history.push(`/resource/manage/${resource.rootSid}/resources?Opened=${item.ancestorIds.join(',')}`);
              }}
            >
              {item.name}
            </div>
            <div className="tr-flex tr-flex-row tr-justify-between tr-items-center">
              <PermissionInLinkedResource resource={item} />
              <Button variant="ternary" disabled={true}>
                <Icon type="remove" />
              </Button>
            </div>
          </div>
        );
      })}
    </Modal>
  );
});

export default LinkedResources;
