import React from 'react';
import FontAwesomeIcon, {faDownload} from 'src/components/FontAwesomeIcon';
import {Dropdown, DropdownItem, DropdownMenu} from '@trustle/component-library';

type PropsT = {
  title: string;
  description?: string;
  url?: string;
  queryParams?: URLSearchParams;
  showCSV?: boolean;
  showPDF?: boolean;
  showPreview?: boolean;
  disabled?: boolean;
};

function ReportDropdown(props: PropsT) {
  const {title, url, queryParams, showCSV = true, showPDF = true, showPreview = true, disabled = false} = props;

  const items = [];
  if (showCSV) {
    items.push({
      key: `downloadcsv`,
      href: `${url}?${queryParams?.toString()}`,
      name: 'Download CSV',
    });
  }
  if (showPDF) {
    items.push({
      key: `downloadPdf`,
      href: `${url}?format=pdf&${queryParams?.toString()}`,
      name: 'Download PDF',
    });
  }

  if (showPreview) {
    items.push({
      key: `previewButton`,
      href: `${url}?format=html&${queryParams?.toString()}`,
      name: 'Preview',
      target: '_blank',
    });
  }

  return (
    <Dropdown
      variant="ternary"
      size="sm"
      disabled={disabled}
      dropdownToggle={
        <div>
          <span className="tr-mr-1">{title}</span>
          <FontAwesomeIcon icon={faDownload} />
        </div>
      }
      dropdownMenu={
        <DropdownMenu>
          {items.map((action) => {
            return (
              <DropdownItem key={action.key}>
                <a href={action.href} target={action.target}>
                  {action.name}
                </a>
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      }
    />
  );
}

export default ReportDropdown;
