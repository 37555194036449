import axios from 'axios';
import _ from 'lodash';
import {makeAutoObservable, reaction} from 'mobx';
import {formatUserName, logger} from 'src/lib';
import {OnCallFormT} from 'src/views/admin/onCall/OnCallModal';
import RootStore from '..';

export class OnCallList {
  id!: string;
  name!: string;
  orgId?: string;
  usersCount?: number;
  users?: string[];
  permissionId?: string;
  createdAt?: string;
  updatedAt?: string;
  deletedAt?: string;

  constructor(private rootStore: RootStore, oncall: Partial<OnCallList>) {
    Object.assign(this, oncall);
    makeAutoObservable(this, {id: false});

    reaction(
      // update oncall users associated to oncall list when users array change
      () => _.pick(this, ['users']),
      async (change: Partial<OnCallList>) => {
        const users = change.users ?? [];
        try {
          await axios.put(`/api/oncall_lists/${this.id}/oncall_users`, users);
          this.rootStore.toast.add(`Successfully updated on-call list`, {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          logger.error(err);
          this.rootStore.toast.add(`An error occured while updating ${this.name}`, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    );

    reaction(
      () => _.pick(this, ['name']),
      async (change: Partial<OnCallList>) => {
        try {
          await axios.put(`/api/oncall_lists/${this.id}`, {name: change.name});
          this.rootStore.toast.add(`Successfully updated permission set`, {
            appearance: 'success',
            autoDismiss: true,
          });
        } catch (err) {
          logger.error(err);
          this.rootStore.toast.add(`An error occured while updating ${change.name}`, {
            appearance: 'error',
            autoDismiss: true,
          });
        }
      }
    );
  }

  get userData() {
    if (!this.users) {
      return [];
    }
    return _.compact(
      this.users.map((id) => {
        const user = _.find(this.rootStore.usersStore.users, (u) => u.id === id);
        if (!user) {
          return null;
        }
        return {id: user.id, value: user.id, label: formatUserName(user)};
      })
    );
  }

  update(values: OnCallFormT) {
    this.name = values.name;
    this.users = _.map(values.users, 'id');
  }
}
