import React from 'react';
import {DropdownItem, Icon} from '@trustle/component-library';

type Props = {
  onClick: () => void;
};

export function ArchiveSystemAction({onClick}: Props) {
  return (
    <DropdownItem className="tr-text-red-500" key="archive-system" onClick={onClick}>
      <div className="tr-flex tr-items-center tr-justify-between">
        <span>Archive System</span>
        <Icon type="archived" size="sm" />
      </div>
    </DropdownItem>
  );
}
