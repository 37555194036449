import React, {Fragment} from 'react';
import {Bar, BarChart, CartesianGrid, Label, ReferenceLine, ResponsiveContainer, Tooltip, XAxis} from 'recharts';
import {EXPOSURE_COLOR, formatDateForLegend, LicenseUsageChartProps, renderXAxisReferenceLabel} from '../utils';
import {CustomLegend} from './CustomLegend';
import {CustomTooltip} from './CustomTooltip';
import NoData from '../../../../../components/NoData';
import {CustomYAxis} from './CustomYAxis';

export function LicenseUsageStackedBarChart({
  data,
  series,
  noDataMessage,
  onboardXAxisPos,
  licenseByKeyMap,
}: LicenseUsageChartProps & {onboardXAxisPos?: string}) {
  return (
    <div className="tr-flex tr-flex-col tr-gap-2">
      <CustomLegend series={series} licenseByKeyMap={licenseByKeyMap} />
      {noDataMessage ? (
        <NoData>{noDataMessage}</NoData>
      ) : (
        <ResponsiveContainer width="100%" height="100%" minWidth={500} minHeight={400}>
          <BarChart
            data={data}
            barCategoryGap={15}
            margin={{
              top: 50,
            }}
          >
            <CartesianGrid />
            {CustomYAxis()}
            <XAxis
              key="frontBar"
              dataKey="name"
              xAxisId="frontBar"
              textAnchor="middle"
              tickFormatter={(tick) => {
                return formatDateForLegend(tick);
              }}
            />
            <XAxis key="backBar" dataKey="name" xAxisId="backBar" hide />
            <Tooltip content={<CustomTooltip licenseByKeyMap={licenseByKeyMap} />} cursor={{fill: '#f1efef'}} />
            {renderBars(series, licenseByKeyMap)}
            <ReferenceLine
              x={onboardXAxisPos}
              xAxisId={'frontBar'}
              isFront
              strokeWidth={3}
              stroke="#00519B"
              strokeDasharray="3 3"
              textAnchor={'left'}
              label={<Label position="top" content={renderXAxisReferenceLabel} />}
            />
            );
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

function renderBars(series: string[], licenseByKeyMap: Record<string, {name: string; color?: string | undefined}>) {
  return series.map((key) => {
    const baseDataKey = `data.${key}`;
    return (
      <Fragment key={key}>
        <Bar dataKey={`${baseDataKey}.sum_of_granted_hours`} xAxisId="backBar" fill={EXPOSURE_COLOR} maxBarSize={66} />
        <Bar
          dataKey={`${baseDataKey}.sum_of_usage_hours`}
          xAxisId="frontBar"
          fill={licenseByKeyMap[key].color}
          maxBarSize={66}
        />
      </Fragment>
    );
  });
}
