import axios from 'axios';
import React from 'react';
import {Confirmation} from '@trustle/component-library';
import {Resource} from 'src/stores/domainObjects/Resource';
import {useAuthority} from '../../../connectors/common/authority/hooks/useAuthority';

type PropsT = {
  resource: Resource;
  closeModal: (resourceWasArchived: boolean) => void;
};

function ArchiveResourceModal(props: PropsT) {
  const {resource, closeModal} = props;
  const {resource: authorityResource, updateAuthority} = useAuthority();
  const isUsedAsAuthority = resource.id === authorityResource?.id;

  async function runAction(e: any) {
    e.preventDefault();
    await axios.post(`/api/resources/${resource.id}/archive`);
    if (isUsedAsAuthority) {
      await updateAuthority({associated: false});
    }
    closeModal(true);
  }

  return (
    <Confirmation onConfirm={runAction} onClose={() => closeModal(false)} title={'Confirm Archive'} size="md">
      <div>
        <p>
          Please confirm that you would like to archive <strong>{resource.name}</strong>
        </p>
        {isUsedAsAuthority && (
          <p>
            <strong>This system is used as Authority for your users.</strong> By archiving this system, you will
            dissociate the system as Authority.
          </p>
        )}
      </div>
    </Confirmation>
  );
}

export default ArchiveResourceModal;
