import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {SubmitButton} from 'src/components/design';
import {AzureADCredentialTypeE} from './types';
import {ConnectionInfoT, ConnectionServiceE, ConnectionT, DurationUnitT} from '../../types';
import {Formik, FormikForm} from 'src/components/design/formik';
import './AzureADConnector.scss';
import {Field} from 'formik';
import {Button, DurationInput, Loading, PasswordInput, TextInput, Alert} from '@trustle/component-library';
import {useFeatureFlags} from 'src/lib';
import {useHistory, useLocation} from 'react-router-dom';
import {getAuthorizationURL, parseAndCleanUpQueryParams} from '../common/helpers';
import {useConnectionCredentials, useRootStore} from '../../lib/hooks';
import {useSystemSetup} from '../../lib/hooks/useSystemSetup';

type AzureADPropsT = {
  onComplete: any;
  connectionInfo: ConnectionInfoT;
  editMode?: boolean;
};

function AzureADSetup(props: AzureADPropsT) {
  const {editMode = false, connectionInfo} = props;
  const [connection] = useState<ConnectionT>(connectionInfo.connection);
  const {org} = useRootStore();
  const [APIsConnected, setAPIsConnected] = useState<boolean>(false);
  const [testInProgress, setTestInProgress] = useState<boolean>(!editMode);
  const [info, setInfo] = useState<string>('');
  const [infoType, setInfoType] = useState<string>('text-warning');
  const history = useHistory();
  const location = useLocation();
  const {credentials, testConnection: testConnectionAPI} = useConnectionCredentials();
  const {systemSetup, updateSetup} = useSystemSetup(connection.id);
  const featureFlagViewer = useFeatureFlags();
  const enabledKeyRotationRecommedation = featureFlagViewer.isEnabled('enable_recommendation_azure_tokens');

  useEffect(() => {
    async function load() {
      const err = parseAndCleanUpQueryParams(history, location, onError);

      if (!err) {
        await testAPIsConnection();
      }
    }

    void load();
  }, []);

  function onError(error: string) {
    setTestInProgress(false);

    setInfoType('text-danger');
    setInfo(error === 'invalid_client' ? `Please, verify the crendentials are valid.` : _.startCase(error));
  }

  async function testAPIsConnection() {
    setTestInProgress(true);

    setInfoType('text-warning');
    setInfo('Trying to authenticate against Azure Management API...');
    const mgmtAPIConnected = await testConnection(AzureADCredentialTypeE.AZURE_MANAGEMENT, () => {});

    if (mgmtAPIConnected) {
      setInfo('Trying to authenticate against Microsoft Graph API...');
      const connected = await testConnection(AzureADCredentialTypeE.MICROSOFT_GRAPH, setAPIsConnected);

      if (!connected) {
        window.location.replace(
          getAuthorizationURL(
            connection.id,
            ConnectionServiceE.AZURE_AD,
            AzureADCredentialTypeE.MICROSOFT_GRAPH,
            editMode
          )
        );
      } else {
        setInfoType('text-success');
        setInfo('Successfully Connected');
      }
    } else {
      setInfoType('text-secondary');
      setInfo('Please, authenticate with valid credentials.');
    }

    setTestInProgress(false);
  }

  async function testConnection(credentialsType: AzureADCredentialTypeE, cb: any) {
    try {
      const {data} = await testConnectionAPI(connection.id, {credentialsType});
      const result = !!data.ok;

      cb(result);
      return result;
    } catch (err: any) {
      cb(false);
      return false;
    }
  }

  async function submitCredentials(
    values: {
      tenantId: string;
      clientId: string;
      clientSecret: string;
      accessKeyRotation: {durationValue: number; durationUnit: DurationUnitT};
    },
    setSubmitting: (submitting: boolean) => void
  ) {
    const tokens = _.pick(values, ['tenantId', 'clientId', 'clientSecret']);

    updateSetup({connectionId: connection.id, keys: values});
    try {
      await credentials(connection.id, {
        key: AzureADCredentialTypeE.CLIENT_CREDENTIALS,
        value: tokens,
        accessKeyRotation: enabledKeyRotationRecommedation ? values.accessKeyRotation : undefined,
      });

      window.location.replace(
        getAuthorizationURL(
          connection.id,
          ConnectionServiceE.AZURE_AD,
          AzureADCredentialTypeE.AZURE_MANAGEMENT,
          editMode
        )
      );
    } catch (err: any) {
      setInfoType('text-danger');
      setInfo(err.response.data?.error?.message || err.response.statusText);
    }

    setSubmitting(false);
  }

  return (
    <div className="tr-py-4">
      <h3>App Registration credentials</h3>
      <div className="tr-mb-2">
        Trustle uses OAuth to import and provision access to your Azure account. Use your App Registration credentials
        to connect to Azure.
      </div>
      <small>
        <a href="https://learn.trustle.com/tutorial/installing-azure" rel="noopener noreferrer" target="_blank">
          Guide: App Registration in Azure
        </a>
      </small>
      <div>
        <span className="tr-text-gray-400 tr-text-sm">
          When prompted for a callback/redirect URL, please enter the following:{' '}
        </span>
        <span className="tr-font-bold tr-text-gray-400 tr-text-sm">
          {org.getOrgRedirectUrl(`/api/connect/${ConnectionServiceE.AZURE_AD}/oauth`)}
        </span>
      </div>
      <div className="tr-mt-4">
        <Formik
          enableReinitialize
          initialValues={{
            tenantId: systemSetup?.tenantId ?? '',
            clientId: systemSetup?.clientId ?? '',
            clientSecret: systemSetup?.clientSecret ?? '',
            accessKeyRotation: {
              durationValue: _.get(connectionInfo, 'connection.accessKeyRotationValue', 3),
              durationUnit: _.get(connectionInfo, 'connection.accessKeyRotationUnit', 'month'),
            },
          }}
          validate={(values: any) => {
            const errors: _.Dictionary<string> = {};
            if (!values.tenantId || values.tenantId.length !== 36) {
              errors.tenantId = 'Tenant ID should have 36 characters long';
            }
            if (!values.clientId || values.clientId.length !== 36) {
              errors.clientId = 'Client ID should have 36 characters long';
            }
            if (!values.clientSecret || values.clientSecret.length < 34) {
              errors.clientSecret = 'Client Secret: Value should have more than 34 characters long';
            }
            return errors;
          }}
          onSubmit={(values: any, {setSubmitting}: any) => {
            void submitCredentials(values, setSubmitting);
          }}
        >
          {({isSubmitting, status, isValid, dirty}) => {
            return (
              <FormikForm className="azure-ad-setup">
                {(editMode || (!APIsConnected && !testInProgress)) && (
                  <div>
                    <div className="tr-flex tr-flex-col tr-mx-auto">
                      <div className="tr-flex">
                        <Field
                          component={TextInput}
                          label="Directory (tenant) ID"
                          placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                          name="tenantId"
                          required
                        />
                      </div>
                      <div className="tr-flex">
                        <Field
                          component={TextInput}
                          label="Application (client) ID"
                          placeholder="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx"
                          name="clientId"
                          required
                        />
                      </div>
                      <div className="tr-flex">
                        <Field
                          component={PasswordInput}
                          label="Client Secret: Value"
                          className="tr-w-full"
                          placeholder="xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx"
                          name="clientSecret"
                          showreveal={true}
                          required
                        />
                      </div>
                    </div>
                    {enabledKeyRotationRecommedation && (
                      <div className="tr-flex tr-flex-col tr-mt-2 tr-pt-4 border-top tr-pl-0">
                        <Field
                          component={DurationInput}
                          name="accessKeyRotation"
                          label="Trustle Connector key rotation"
                          required
                          includeNoneOption={false}
                          description="Prompt me to rotate the Trustle access key in the period defined below"
                        />
                      </div>
                    )}
                  </div>
                )}

                <div className="tr-flex tr-flex-wrap tr-justify-between tr-items-center">
                  <div className="tr-text-left tr-mt-5">
                    <div>
                      {testInProgress && <Loading />}
                      <small className={`ml-2 ${infoType}`}>{info}</small>
                    </div>
                  </div>
                  <div className="tr-text-right tr-mt-5 tr-mb-3">
                    {((!editMode && !APIsConnected) || editMode) && (
                      <SubmitButton
                        inSubmit={isSubmitting}
                        disabled={testInProgress || !dirty || !isValid}
                        label={'Authenticate'}
                      />
                    )}
                    {!editMode && APIsConnected && (
                      <Button disabled={!APIsConnected} onClick={() => props.onComplete({})}>
                        {'Continue'}
                      </Button>
                    )}
                  </div>
                </div>
                {status && status.message && <Alert colorVariant={'danger'}>{status.message}</Alert>}
              </FormikForm>
            );
          }}
        </Formik>
      </div>
      <hr />
    </div>
  );
}

export default AzureADSetup;
