import {Button, Confirmation, IconButton, Select, Table} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {useRootStore} from 'src/lib/hooks';
import {PermissionSet} from 'src/stores/domainObjects/PermissionSet';
import OnCallPermissionModal from './OnCallPermissionModal';

enum OnCallModalE {
  DELETE_LIST = 'delete_list',
  EDIT_LIST = 'edit_list',
  ASSOCIATE_LIST = 'associate_list',
}

const OnCallSettings = observer(function OnCallSettingscmp(props: {id: string}) {
  const {id} = props;
  const [modalAction, setModalAction] = useState<OnCallModalE>();
  const [target, setTarget] = useState<PermissionSet>();
  const [associateListTarget, setAssociateListTarget] = useState<{
    permissionSet: PermissionSet;
    oncallList: {id: string; value: string; label: string};
  }>();
  const {newResourceStore: resourceStore, org, currentUser} = useRootStore();
  const [createOnCall, setCreateOnCall] = useState(false);
  const system = resourceStore.resourceMap[id];

  const userEmail = currentUser!.email.toLowerCase();
  const canSeeResource = currentUser.isOrgOwner && !_.includes(system.owners, userEmail);

  useEffect(() => {
    if (system) {
      void system.getPermissionSets();
    }
  }, [system]);

  if (org?.oncallListData === undefined) {
    return <></>;
  }

  const columns = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Access',
    },
    {
      dataField: 'createdAt',
      hidden: true,
      text: 'createdAt',
    },
    {
      dataField: 'name',
      sort: true,
      classes: 'ellipsis-overflow',
      text: 'Name',
      headerStyle: {width: '20%'},
      style: {width: '20%'},
      formatter: (cell: any) => {
        return <div>{cell}</div>;
      },
    },
    {
      dataField: 'permissionsCount',
      classes: 'ellipsis-overflow',
      text: 'Number of Permissions',
      formatter: (cell: any) => {
        return <div>{cell}</div>;
      },
    },
    {
      dataField: 'oncallListId',
      text: 'On-call list',
      formatter: (_cell: any, row: PermissionSet) => {
        return (
          <Select
            label=""
            name="oncallLists"
            isClearable={true}
            options={org.oncallListData}
            onChange={
              ((oncallList: {id: string; value: string; label: string}) => {
                setAssociateListTarget({permissionSet: row, oncallList: oncallList});
                setModalAction(OnCallModalE.ASSOCIATE_LIST);
              }) as (selectedItem: any) => void
            }
            value={row.getOncallListData}
            isDisabled={canSeeResource}
          />
        );
      },
    },
    {
      dataField: 'actions',
      sort: true,
      headerStyle: {width: '90px'},
      style: {width: '90px'},
      text: 'Actions',
      formatter: (_cell: any, row: PermissionSet) => {
        return <div>{!canSeeResource && getActions(row)}</div>;
      },
    },
  ];

  function getActions(permissionSet: React.SetStateAction<PermissionSet | undefined>) {
    const items = [];

    items.push(
      <IconButton
        icon="edit"
        variant="ternary"
        key={'edit'}
        title="Edit"
        data-testid="edit-icon"
        onClick={(e: any) => {
          e.stopPropagation();
          setTarget(permissionSet);
          setModalAction(OnCallModalE.EDIT_LIST);
        }}
      />
    );

    items.push(
      <IconButton
        icon="remove"
        variant="ternary"
        key={'delete'}
        data-testid="delete-icon"
        title="Delete"
        onClick={(e: any) => {
          e.stopPropagation();
          setTarget(permissionSet);
          setModalAction(OnCallModalE.DELETE_LIST);
        }}
      />
    );

    return <div className="tr-flex justify-content-center ">{items}</div>;
  }

  const resourceModalActionContent = ((): React.ReactNode => {
    if (!modalAction) {
      return null;
    }
    if (!target && _.includes([OnCallModalE.DELETE_LIST, OnCallModalE.EDIT_LIST], modalAction)) {
      return;
    }
    if (!associateListTarget && _.includes([OnCallModalE.ASSOCIATE_LIST], modalAction)) {
      return;
    }

    switch (modalAction) {
      case OnCallModalE.DELETE_LIST: {
        return (
          <Confirmation
            onConfirm={async () => {
              if (target) {
                await system.deletePermissionSet(target.id);
              }
              setModalAction(undefined);
            }}
            onClose={() => setModalAction(undefined)}
            title={`Deleting On-Call Set`}
          >
            <>Are you sure you want to delete this On-Call Set?.</>
          </Confirmation>
        );
      }
      case OnCallModalE.EDIT_LIST: {
        return (
          <OnCallPermissionModal system={system} closeModal={() => setModalAction(undefined)} defaultValues={target} />
        );
      }
      case OnCallModalE.ASSOCIATE_LIST: {
        let oncallList = undefined;
        const isDissociateList = associateListTarget && !associateListTarget.oncallList;
        if (!isDissociateList) {
          oncallList = _.find(org.onCallLists, (ol) => ol.id === associateListTarget?.oncallList.id);
        }
        const oncallListName = oncallList ? oncallList.name : 'N/A';
        const permissionSet = associateListTarget?.permissionSet;

        const showModalMessage = (): React.ReactElement => {
          if (isDissociateList) {
            return (
              <div className="body5-normal">
                Are you sure you want to dissociate the oncall list for the permission set{' '}
                <span className="body5">{permissionSet?.name}</span>?.
              </div>
            );
          }
          return (
            <div className="body5-normal">
              <div className="mb-3">
                Are you sure you want to associate the oncall list <span className="body5">{oncallListName}</span> for
                the permission set <span className="body5">{permissionSet?.name}</span>?.
              </div>
              This will allow the users in <span className="body5">{oncallListName}</span> to access permissions in{' '}
              <span className="body5">{permissionSet?.name}</span> instantly upon request.
            </div>
          );
        };

        return (
          <Confirmation
            onConfirm={async () => {
              permissionSet?.update({
                ...associateListTarget?.permissionSet,
                oncallListId: associateListTarget?.oncallList ? associateListTarget?.oncallList.id : null,
              });
              setModalAction(undefined);
              setAssociateListTarget(undefined);
            }}
            onClose={() => setModalAction(undefined)}
            title={`${isDissociateList ? 'Unassigning' : 'Assigning'} On-Call User List`}
          >
            <>{showModalMessage()}</>
          </Confirmation>
        );
      }
    }
  })();

  return (
    <div className="tr-flex tr-flex-col">
      <div className="bottom-border-gray mt-3 oncall-settings">
        <div className="tr-flex tr-flex-col">
          <h3>System Permission Sets</h3>
          <div className="body5-normal">
            Permission Sets are groups of permissions belonging to a system that can be assigned to a On-Call list for
            management.
          </div>
        </div>
      </div>
      <div className="bottom-border-gray">
        <Button variant="ternary" onClick={() => setCreateOnCall(true)} disabled={canSeeResource}>
          + Add New Permission Set
        </Button>
      </div>
      <div className="bottom-border-gray">
        <Table
          defaultSorted={[{dataField: 'createdAt', order: 'asc'}]}
          data={system?.permissionSets ?? []}
          columns={columns}
          tableKey="oncallsettings"
          striped={false}
          wrapperClasses="rounded-xl border border-black rounded"
          bordered={false}
        />
      </div>
      {resourceModalActionContent}
      {createOnCall && <OnCallPermissionModal system={system} closeModal={() => setCreateOnCall(false)} />}
    </div>
  );
});

export default OnCallSettings;
