import React from 'react';
import {TabsDocsT} from '../DocSection';
import DocSection from '../DocSection';
import {UserType} from 'src/types';
import _ from 'lodash';
import {Table} from '@trustle/component-library';

function UsersUploadGuide() {
  const columns = [
    {
      key: 'firstname',
      description: "The user's first name.",
      subtext: 'e.g.: Jon T.',
    },
    {
      key: 'lastname',
      description: "The user's last name.",
      subtext: 'e.g: Appleseed',
    },
    {
      key: 'email_address',
      description: "The user's email address, which will be their primary username.",
      subtext: 'e.g.: username@example.com',
    },
    {
      key: 'user_type',
      description: `The user can be either an ${UserType.employee}, a ${UserType.contractor}, a ${UserType.customer}, or a ${UserType.system}.`,
      subtext: `One of: ${_.values(UserType).join(', ')}`,
    },
    {
      key: 'managers_email_address',
      description: "The email address for the user's manager.",
      subtext:
        "The CEO's manager's email address should be left blank, manager approvals will go to the Trustle workspace owners for approval.",
    },
    {
      key: 'allow_login',
      description: 'Flag to determine if the user can login.',
      subtext: 'Valid options: true ("true", "t", 1, "yes", or "y"), false (anything else).',
    },
    {
      key: 'department',
      description: 'The division or business unit of the organization to which this user belongs.',
      subtext: 'e.g. IT',
    },
    {
      key: 'role',
      description: 'The position or purpose that this user has in the organization.',
      subtext: 'e.g. Chief Technology Officer',
    },
    {
      key: 'title',
      description: 'Professional degree',
      subtext: 'e.g. Systems Engineer',
    },
  ];

  const tabs: TabsDocsT = [
    {id: 'users-overview', label: 'Overview', description: overview(), active: true},
    {id: 'users-examples', label: 'Examples', description: examples(), active: false},
  ];

  function overview(): JSX.Element {
    return (
      <div>
        <div>
          <p className="tr-w-75">
            Users in Trustle can be uploaded through a CSV file.
            <p>The following list of columns is a specification of the required format for the CSV.</p>
          </p>
        </div>
        <div className={'border-bottom pt-3 mb-2'}>
          <p className={'mb-1'}>
            <strong>Column Definitions</strong>
          </p>
        </div>
        <div className={'pb-4'}>
          {columns.map((column: any) => (
            <div className={'mb-1'} key={column.key}>
              <b className={'text-capitalize pr-2'}>{`${column.key}:`}</b> {column.description}
              <br />
              <div className={'text-muted'}>{column.subtext}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function examples(): JSX.Element {
    return (
      <div>
        <p className="pb-3">
          The result of uploading this CSV for the first time will be the following:
          <div className="pl-4 tr-w-75">
            <li>
              5 new users will be created: a manager, a vendor and three regular users, one of them disabled, one with a
              manager set and one without a manager set.
            </li>
            <li>
              A new team called "<strong>Team manager1@test.com</strong>" will be created, where Manager #1 will be the
              manager of the team, and 3 users will be their members (Employee #1, Employee #2 and Vendor #1).
            </li>
            <li>
              In the case of Employee #3, the user will become a member of "<strong>Unassigned</strong>" team, since no
              manager is set.
            </li>
          </div>
          <p className="pt-2">If the same CSV is uploaded a second time:</p>
          <div className="pl-4 tr-w-75">
            <li>No users will be created or updated if there are no changes in the file.</li>
            <li>If the email_address changes, it will be considered as a different user.</li>
            <li>If the managers_email_address changes, the corresponding user will be moved to a different team.</li>
            <li>If any of the other values changes, the corresponding user will be updated.</li>
            <li>If any of the created users is missing from the file, there will be an option to offboard the user.</li>
          </div>
          <p></p>
        </p>
        <ExampleTable />
      </div>
    );
  }

  return (
    <>
      <DocSection tabs={tabs} title="Guide: How to create/update users through CSV file upload" />
    </>
  );
}

const getColumns = () => {
  return [
    {dataField: 'firstname', text: 'firstname'},
    {dataField: 'lastname', text: 'lastname'},
    {dataField: 'email_address', text: 'email_address'},
    {dataField: 'user_type', text: 'user_type'},
    {dataField: 'managers_email_address', text: 'managers_email_address'},
    {dataField: 'allow_login', text: 'allow_login'},
    {dataField: 'department', text: 'department'},
    {dataField: 'role', text: 'role'},
    {dataField: 'title', text: 'title'},
  ];
};

const getSampleData = (): any[] => {
  const managerEmail = 'manager1@test.com';

  return [
    {
      firstname: 'Manager #1',
      lastname: 'Test (manager)',
      email_address: managerEmail,
      user_type: UserType.employee,
      managers_email_address: '',
      allow_login: 'y',
      department: 'Department ABC',
      role: 'Role ABC',
      title: 'Title ABC',
    },
    {
      firstname: 'Employee #1',
      lastname: 'Test (regular)',
      email_address: 'employee1@test.com',
      user_type: UserType.employee,
      managers_email_address: managerEmail,
      allow_login: 'y',
      department: 'Department ABC',
      role: 'Role ABC',
      title: 'Title ABC',
    },
    {
      firstname: 'Employee #2',
      lastname: 'Test (disabled)',
      email_address: 'employee2@test.com',
      user_type: UserType.employee,
      managers_email_address: managerEmail,
      allow_login: 'n',
      department: 'Department ABC',
      role: 'Role ABC',
      title: 'Title ABC',
    },
    {
      firstname: 'Employee #3',
      lastname: 'Test (no manager)',
      email_address: 'employee3@test.com',
      user_type: UserType.employee,
      allow_login: 'y',
      department: 'Department ABC',
      role: 'Role ABC',
      title: 'Title ABC',
    },
    {
      firstname: 'Vendor #1',
      lastname: 'Test (vendor)',
      email_address: 'vendor1@test.com',
      user_type: UserType.contractor,
      managers_email_address: managerEmail,
      allow_login: 'y',
      department: 'Department ABC',
      role: 'Role ABC',
      title: 'Title ABC',
    },
  ];
};

function ExampleTable(): JSX.Element {
  const columns = getColumns();
  const data = getSampleData();
  const link = `/api/users/admin/download_example`;

  return (
    <div className="mb-5">
      <div className={'border-bottom tr-flex tr-justify-between my-2'}>
        <p className={'mb-1'}></p>
        <a href={link} className="link" download>
          {'Download example'}
        </a>
      </div>

      <Table data={data} tableKey="uploadguide" columns={columns} />
    </div>
  );
}

export default UsersUploadGuide;
