import {useEffect, useState} from 'react';
import {Resource} from '../../../stores/domainObjects/Resource';
import {useRootStore} from '../../../lib/hooks';
import {ProvisionOptions} from '../../../types';
import _ from 'lodash';

/**
 * Hook that returns the list of available systems for the current user based on their permissions and system states.
 *
 * The systems are loaded asynchronously from the `newResourceStore` and filtered based on various criteria:
 * - Systems without permissions are excluded.
 * - Organization and system owners see all systems.
 * - End-users can only see systems they have access to or that meet specific conditions (e.g., not disabled or provisioned off unless they have prior access or the system is custom).
 *
 * @returns {Object} - Returns an object with:
 *   - `availableSystems` {Resource[]} - The filtered list of systems available to the current user.
 *   - `isLoading` {boolean} - A flag indicating if the systems are still being loaded.
 */

export function useAvailableSystems() {
  const {newResourceStore: resourceStore, currentUser} = useRootStore();
  const [availableSystems, setAvailableSystems] = useState<Resource[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    let systems: Resource[] = [];

    const loadAvailableSystems = async () => {
      setIsLoading(true);
      await resourceStore.loadSystems();
      systems = resourceStore.availableReviewedSystems.filter((system) => {
        // Systems without permissions should be skipped
        if (!system.hasPermissions) {
          return false;
        }
        const isOrgOrSystemOwner = currentUser.isOrgOwner || system.userIsOwner;
        // Org and system owners should see all systems
        if (isOrgOrSystemOwner) {
          return true;
        }
        // This section is for an end-user

        const userAccessesByRid = _.groupBy(currentUser.accesses, 'rid');
        const userHasAccess = userAccessesByRid[system.id]?.length > 0;

        // Provision off systems are filtered for end-users unless they have previous access or system is custom.
        if (system.isProvisionStatus(ProvisionOptions.off) && !userHasAccess && system.type !== 'custom-system') {
          return false;
        }
        // Systems that are disabled are filtered to end-users unless they have previous access.
        return !(system.isDisabled && !userHasAccess);
      });
    };
    void loadAvailableSystems()
      .then(() => setAvailableSystems(systems))
      .finally(() => setIsLoading(false));
  }, []);

  return {availableSystems, isLoading};
}
