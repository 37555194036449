import React from 'react';
import AccessTable, {AccessBranchPropsT} from 'src/views/Resource/TreeView/AccessTable';
import {PermissionActions} from 'src/views/Resource/TreeView/BranchComponents/PermissionActions';
import {PermissionAttributes} from 'src/views/Resource/TreeView/BranchComponents/PermissionAttributes';
import {PermissionHeader} from 'src/views/Resource/TreeView/BranchComponents/PermissionHeader';
import PermissionBranch, {PermissionBranchPropsT} from 'src/views/Resource/TreeView/PermissionBranch';
import ResourceBranch, {ResourceBranchPropsT} from 'src/views/Resource/TreeView/ResourceBranch';

import {ConnectorSchema} from './default';
import {AccountTableParams, FormFieldTypes} from '../common/types';
import {ConnectionServiceE} from 'src/types';

const aws: ConnectorSchema = {
  service: ConnectionServiceE.AWS,
  name: 'AWS',
  description:
    'Connect your AWS Identity and Access Management (IAM) account and sync the data with your Trustle organization.',
  serviceId: 'aws-endpoint',
  auth: {
    type: 'accesKeys',
    title: 'API Key configuration',
    description: 'Trustle uses OAuth to import an provision access to your AWS account',
    docs: {
      link: `https://learn.trustle.com/tutorial/installing-aws`,
      linkText: `Guide: Setting up an AWS Connector`,
    },
    fields: [
      {
        label: 'Enter the access key ID for the Trustle Connector',
        type: FormFieldTypes.PASSWORD,
        name: 'accessKeyId',
        id: 'accessKeyId',
        placeholder: 'Enter access key here',
        required: true,
      },
      {
        placeholder: 'Enter the secret access key',
        label: 'Secret key',
        type: FormFieldTypes.PASSWORD,
        name: 'secretAccessKey',
        id: 'secretAccessKey',
        required: true,
      },
    ],
  },
  templates: {
    resources: {
      tree: {
        Resource: {
          default: {
            main: (props: ResourceBranchPropsT) => {
              return <ResourceBranch {...props} />;
            },
          },
        },
        Permission: {
          default: {
            main: (props: PermissionBranchPropsT) => {
              return <PermissionBranch {...props} />;
            },
            attributes: (props: PermissionBranchPropsT) => {
              return <PermissionAttributes permission={props.permission} accessMode={props.accessMode ?? false} />;
            },
            actions: (props: PermissionBranchPropsT) => {
              return <PermissionActions {...props} />;
            },
            header: (props: PermissionBranchPropsT) => {
              return <PermissionHeader {...props} />;
            },
          },
        },
        AccessRecord: {
          default: {
            main: (props: AccessBranchPropsT) => {
              return <AccessTable {...props} />;
            },
          },
        },
      },
    },
    accounts: {
      tables: {
        Linked: (_props: AccountTableParams) => {
          return [
            'flaggedForReview',
            'status',
            'user/account',
            'accesses.length',
            //enabled just for aws
            'riskScore',
            'usage',
            'issue',
            'lastAccountActivity',
            'lastLogin',
            'actions',
          ];
        },
        Unlinked: (_props: AccountTableParams) => {
          return [
            'flaggedForReview',
            'status',
            'user/account',
            'accesses.length',
            'usage',
            'riskScore',
            'accountType',
            'email',
            'lastAccountActivity',
            'lastLogin',
            'issue',
            'actions',
          ];
        },
        Flagged: (_props: AccountTableParams) => {
          return [
            'flaggedForReview',
            'status',
            'user/account',
            'accesses.length',
            'usage',
            'riskScore',
            'lastAccountActivity',
            'lastLogin',
            'issue',
            'actions',
          ];
        },
      },
    },
  },
};

export default aws;
