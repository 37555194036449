import {observer} from 'mobx-react';
import {Permission} from 'src/stores/domainObjects/Permission';

import React from 'react';
import {Icon, Tooltip} from '@trustle/component-library';

export const PermissionWarning = observer(({permission}: {permission: Permission}) => {
  if (!permission.nodeImportState?.warning) {
    return <></>;
  }
  //NR: This should highlight better the warnings.

  return (
    <div className="tr-ml-2 tr-mr-2 text-trustle-navy tr-font-normal tr-text-xs">
      <Tooltip content={<div>Configuration is not secure enough</div>}>
        <Icon className="tr-text-warning" type="warning" size="sm" />
      </Tooltip>
    </div>
  );
});
