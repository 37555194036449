import {makeAutoObservable} from 'mobx';
import RootStore from '.';
import {Permission} from './domainObjects/Permission';
import {ProvisionOptions} from 'src/types';

export type PermissionResponseT = {
  id: string;
  oid: string;
  tombstone?: boolean | null;
  refId: string;
  refType: string;
  label: string;
  description?: string | null;
  approvalDefinition?: string;
  provisionInstructions?: string;
  reapprovalDefinition?: string;
  provisionDefinition?: string;
  deprovisionDefinition?: string;
  accessInstructions?: string;
  durationValue?: number;
  durationUnit?: string;
  accessDurationValue?: number;
  accessDurationUnit?: string;
  hidden?: boolean;
  autoProvision?: boolean;
  foreignId?: string; // id on remotely connected system
  sensitivityId?: string | null;
  created?: string;
  updated?: string;
  reviewedAt?: string;
  locked?: boolean;
  sid: string;
  provisionMode: ProvisionOptions;
  deprovisionMode: ProvisionOptions;
};

export class PermissionStore {
  permissionMap: Record<string, Permission> = {};

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this);
  }

  updatePermissionFromServer(attributes: Partial<PermissionResponseT> & {id: string}) {
    const existingPermission = this.permissionMap[attributes.id];
    if (existingPermission) {
      Object.assign(existingPermission, attributes);
    } else {
      this.permissionMap[attributes.id!] = new Permission(this.rootStore, attributes);
    }
  }
}
