import _ from 'lodash';
import React from 'react';
import {AuthenticationPolicyT} from './authenticationTypes';
import {Dropdown, DropdownItem, DropdownMenu, Icon, Tooltip, Button} from '@trustle/component-library';
import {ItemIconTypesEnum} from '@trustle/component-library/dist/types';

export type Settings = {
  name: string;
  type: string;
  label: string;
  icon: ItemIconTypesEnum;
  className?: string;
  display: boolean;
  disabled: boolean;
};

export function AuthMethodSelector(props: {policies: AuthenticationPolicyT[];  isSaveDisabled:boolean; onSelected: (e: Settings) => void; onSave: () => void;}) {
  const {policies, isSaveDisabled, onSelected, onSave} = props;

  const settings: Settings[] = [
    {
      name: 'password',
      type: 'password',
      label: 'Email/Password',
      icon: 'email',
      display: true,
      disabled: false,
    },
    {
      name: 'google',
      type: 'oauth',
      label: 'Google OAuth',
      icon: 'GoogleLogo',
      display: true,
      disabled: false,
    },
    {
      name: 'saml',
      type: 'saml',
      label: 'SAML 2.0',
      icon: 'Saml',
      className: 'tr-text-sensitivity-red',
      display: true,
      disabled: _.some(policies, (p) => {
        return p.name === 'saml';
      }),
    },
  ];

  const authOptionsList = _.compact(
    _.map(settings, (authSetting) => {
      if (!authSetting.display) {
        return null;
      }

      return (
        <DropdownItem key={authSetting.name} onClick={() => onSelected(authSetting)}>
          {renderItem(authSetting)}
        </DropdownItem>
      );
    })
  );

  function renderItem(props: Settings) {
    const {icon, className, label} = props;
    return (
      <span>
        <span className="mr-2">
          <Icon type={icon} size="sm" className={className ?? ''} />
        </span>
        <span className="body5">{label}</span>
      </span>
    );
  }

  return (
    <div>
      <h3>Authentication Method</h3>
      <div className="body5-normal">
        Select an authentication method below.
        <Tooltip
          content={<div>Activating SAML as authentication method will disable all other identity providers</div>}
        >
          <Icon type="moreInfo" size="sm" className="tr-text-trustle-link tr-mx-2" />
        </Tooltip>
      </div>

      <div className="tr-mt-12 tr-flex tr-justify-between">
        <Dropdown title={'+ Add Identity Provider'} dropdownMenu={<DropdownMenu>{authOptionsList}</DropdownMenu>} />
        <Button disabled={isSaveDisabled} onClick={onSave}>Save</Button>
      </div>
    </div>
  );
}
