import React, {useState} from 'react';
import SliceStringList from '../../components/SliceStringList';
import {Loading, Table} from '@trustle/component-library';
import {formatDateFromNow, formatFullDate, fuseSearch} from 'src/lib';
import HeaderServiceUsage, {AccessTypeE} from './HeaderServiceUsage';
import _ from 'lodash';
import {Resource} from 'src/stores/domainObjects/Resource';
import {connectorsUsageServiceAccountDataT, ServiceUsageT} from 'src/types';
import {Search} from 'src/components/Search/Search';
import ShowAccountsAccess from './ShowAccountsAccess';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import EmptySection from './EmptySection';
import {Permission} from 'src/stores/domainObjects/Permission';
import {observer} from 'mobx-react-lite';
import NoData from 'src/components/NoData';

type GroupServiceUsageProps = {
  permission: Permission;
  system: Resource;
  hideMemberColumn?: boolean;
  emptyMessage?: string;
};

const GroupServiceUsage = observer((props: GroupServiceUsageProps) => {
  const {
    permission,
    system,
    hideMemberColumn = false,
    emptyMessage = 'This groups does not have any service permissions. No data can be shown until permissions are attached.',
  } = props;
  const serviceUsage = permission.serviceUsage;
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  const [filtersStatus, setFiltersStatus] = useState<string[]>([AccessTypeE.ACCESSED]);
  const [query, setQuery] = useState('');
  const [showAccountAccess, setShowAccountAccess] = useState<connectorsUsageServiceAccountDataT[]>([]);
  const [selectedService, setSelectedService] = useState<string | undefined>(undefined);
  if (permission.serviceUsage === undefined) {
    return <Loading />;
  }
  const filteredServiceUsage = _.filter(
    serviceUsage,
    (su: ServiceUsageT) =>
      (_.isNil(filters.Service) || (su.description && filters.Service === su.description)) &&
      (_.isNil(filters.Member) || (su.account && _.includes(_.map(su.account, 'account'), filters.Member))) &&
      (_.size(filtersStatus) > 1 ? true : _.includes(filtersStatus, AccessTypeE.ACCESSED) ? su.accessed : !su.accessed)
  );
  const searchedServiceUsages = fuseSearch(
    query,
    ['description', 'identifier', 'account.account'],
    filteredServiceUsage
  );
  const columns: any = [
    {
      dataField: 'description',
      hidden: false,
      sort: true,
      text: 'Service',
      headerStyle: {width: '35%', textAlign: 'left'},
      style: {width: '35%', textAlign: 'left'},
      formatter: (cell: any) => {
        return <div className="text-left">{cell}</div>;
      },
    },
    {
      dataField: 'account',
      hidden: true,
      text: 'Policies granting permissions',
      headerStyle: {width: '10%'},
      style: {width: '10%'},
      formatter: (cell: any) => {
        return <div className="text-left">{cell}</div>;
      },
    },
    {
      dataField: 'members',
      sort: true,
      text: 'Access by members',
      hidden: hideMemberColumn,
      headerStyle: {width: '30%', textAlign: 'left'},
      style: {width: '30%', textAlign: 'left'},
      formatter: (_cell: any, row: any) => {
        return (
          <SliceStringList
            elements={row.account}
            label="account"
            onClick={(ad: connectorsUsageServiceAccountDataT[]) => {
              setShowAccountAccess(ad);
              setSelectedService(row.description);
            }}
          />
        );
      },
    },
    {
      dataField: 'eventDate',
      sort: true,
      text: 'Last accessed',
      headerStyle: {width: '30%', textAlign: 'left'},
      style: {width: '30%', textAlign: 'left'},
      formatter: (_cell: any, row: any) => {
        const maxEventDate = _.max(_.map(row.account, 'eventDate'));
        if (!maxEventDate) {
          return <div className="text-left text-muted">Not accessed in the tracking period</div>;
        }
        return (
          <div className="text-left">{`${formatDateFromNow(maxEventDate)} (${formatFullDate(maxEventDate)})`}</div>
        );
      },
    },
  ];
  const [accessed, notAccessed] = _.partition(serviceUsage, (su: ServiceUsageT) => {
    return su.accessed;
  });

  if (accessed.length === 0 && notAccessed.length === 0) {
    return <EmptySection system={permission.rootResource} type="resource" />;
  }
  const isShowingAccountAccess = !_.isEmpty(showAccountAccess);

  if (
    !permission.rootResource.retrieveUsageDataEnabled ||
    (permission.rootResource.retrieveUsageDataEnabled &&
      permission.rootResource.isServiceUsageStatusImporting &&
      accessed.length === 0 &&
      notAccessed.length === 0)
  ) {
    return <EmptySection system={permission.rootResource} type="resource" />;
  }
  return (
    <>
      <HeaderServiceUsage
        accessedInTrackingPeriodNum={_.size(accessed)}
        notAccessedInTrackingPeriodNum={_.size(notAccessed)}
        system={system}
        onClick={(e) => {
          setFiltersStatus(e);
        }}
      />
      {!isShowingAccountAccess && (
        <div className="tr-flex tr-mt-2">
          <div className="tr-flex tr-flex-col body3">
            <span>{_.join(_.orderBy(filtersStatus), ' and ')} </span>{' '}
            {!showSearchBar && (
              <FontAwesomeIcon
                className="ml-2"
                size="xs"
                color="gray"
                icon={faSearch}
                onClick={() => setShowSearchBar(true)}
              />
            )}
          </div>
        </div>
      )}

      {!isShowingAccountAccess && showSearchBar && (
        <Search
          placeholder="Search for Service or Member"
          filterOptions={{
            Member: _.compact(_.uniq(_.map(_.flatMap(serviceUsage, 'account'), 'account'))),
            Service: _.map(serviceUsage, 'description'),
          }}
          editURL={false}
          onChange={(query, filters) => {
            setQuery(query);
            setFilters(filters);
          }}
          showCloseBtn={true}
          onClose={() => setShowSearchBar(false)}
        />
      )}
      {!isShowingAccountAccess &&
        (_.isEmpty(searchedServiceUsages) ? (
          <NoData>{emptyMessage}</NoData>
        ) : (
          <Table
            data={searchedServiceUsages}
            columns={columns}
            tableKey="groupserviceusage"
            striped={false}
            wrapperClasses="rounded-xl border border-black rounded bg-white"
          />
        ))}
      {isShowingAccountAccess && (
        <ShowAccountsAccess
          accountData={showAccountAccess}
          selectedService={selectedService}
          system={system}
          onClose={() => {
            setSelectedService(undefined);
            setShowAccountAccess([]);
          }}
        />
      )}
    </>
  );
});

export default GroupServiceUsage;
