import {ConnectionServiceE} from 'src/types';

export enum ConnectionIconSrc {
  GITHUB = '/connectors/github-logo.svg',
  AWS = '/connectors/aws-logo.svg',
  GCP = '/connectors/gcp.svg',
  GAPPS = '/connectors/gapps-logo.svg',
  AZURE_AD = '/connectors/azure-logo.svg',
  CLOUDFLARE = '/connectors/cloudflare-icon.svg',
  OKTA = '/connectors/okta-logo.svg',
  PAGERDUTY = '/connectors/pagerduty-logo.svg',
  STRONGDM = '/connectors/strongdm-logo.svg',
  TABLEAU = '/connectors/tableau-logo.svg',
  GENERIC = '/connectors/generic-logo.svg',
  CUSTOM = '/connectors/manual-logo.svg',
  JIRA = '/connectors/jira-logo.svg',
  M365 = '/connectors/m365-logo.svg',
}

export const MapIconToService: _.Dictionary<ConnectionServiceE | 'custom-system'> = {
  [ConnectionIconSrc.GITHUB]: ConnectionServiceE.GITHUB,
  [ConnectionIconSrc.AWS]: ConnectionServiceE.AWS,
  [ConnectionIconSrc.GAPPS]: ConnectionServiceE.GAPPS,
  [ConnectionIconSrc.AZURE_AD]: ConnectionServiceE.AZURE_AD,
  [ConnectionIconSrc.OKTA]: ConnectionServiceE.OKTA,
  [ConnectionIconSrc.PAGERDUTY]: ConnectionServiceE.PAGERDUTY,
  [ConnectionIconSrc.STRONGDM]: ConnectionServiceE.STRONGDM,
  [ConnectionIconSrc.TABLEAU]: ConnectionServiceE.TABLEAU,
  [ConnectionIconSrc.GENERIC]: ConnectionServiceE.GENERIC,
  [ConnectionIconSrc.CUSTOM]: 'custom-system',
  [ConnectionIconSrc.M365]: ConnectionServiceE.M365,
};
