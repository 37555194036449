import React from 'react';
import {TabsDocsT} from '../DocSection';
import DocSection from '../DocSection';

function ConfigureGithub() {
  const tabs: TabsDocsT = [
    {id: '1', label: 'Contents', description: 'Content detail', active: true},
    {id: '2', label: 'Supported Features', description: 'Supported features detail', active: false},
    {id: '3', label: 'Overview', description: 'Overview detail', active: false},
    {id: '4', label: 'Configuration Steps', description: 'Configuration steps detail', active: false},
  ];

  return (
    <>
      <DocSection tabs={tabs} title="How to configure Github for Trustle" />
    </>
  );
}

export default ConfigureGithub;
