import React, {useState} from 'react';
import axios from 'axios';
import {SubmitButton} from 'src/components/design';
import {TeamT, TeamTypeEnum, UserT} from 'src/types';
import _ from 'lodash';
import {useToasts} from 'react-toast-notifications';
import {formatUserNameAndEmail} from 'src/lib';
import {Button, Select} from '@trustle/component-library';
import {Field, Formik} from 'formik';
import {useRootStore} from 'src/lib/hooks';
import {FormikForm} from 'src/components/design/formik';

type PropsT = {
  team: TeamT;
  members: UserT[];
  afterSubmit: () => void;
};

export default function AddMembersModal(props: PropsT) {
  const {usersStore} = useRootStore();
  const {afterSubmit, members, team} = props;
  const {addToast} = useToasts();

  const [localMembers, setMembers] = useState<UserT[]>(members);

  async function onSubmit() {
    const body = {
      members: _.map(localMembers, 'value'),
    };

    try {
      await axios.post(`/api/teams/${team.id}/members`, body);

      addToast(`Team saved successfully`, {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (e: any) {
      const errorMessage = e.response.data.error.message || `An error occurred while saving your changes`;
      addToast(errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      });
      return false;
    }
    return true;
  }

  return (
    <div className="tr-my-2">
      <Formik initialValues={{members: []}} onSubmit={onSubmit}>
        {() => {
          return (
            <FormikForm>
              <div className="tr-my-3 tr-flex tr-flex-wrap">
                {TeamTypeEnum.ADHOC === team?.type && (
                  <Field
                    component={Select}
                    name={'members'}
                    id={'members'}
                    isMulti={true}
                    className="tr-my-2 pb-7"
                    label="Team Members"
                    required={true}
                    onChange={(e: any) => {
                      setMembers(e);
                    }}
                    options={_.map(usersStore.users, (suggestion: any) => {
                      return {
                        id: suggestion.id,
                        value: suggestion.id,
                        label: formatUserNameAndEmail(suggestion) ?? `${suggestion.email}`,
                      };
                    })}
                  />
                )}
              </div>
              <div className="tr-flex tr-justify-between tr-items-center">
                <Button variant="secondary" onClick={afterSubmit}>
                  Cancel
                </Button>
                <SubmitButton
                  name="submit"
                  label="Save"
                  onClick={async () => {
                    const submitWithoutErrors = await onSubmit();

                    if (submitWithoutErrors) {
                      afterSubmit();
                    }
                  }}
                />
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );
}
