import React from 'react';
import bg from '../images/bg.png';
import {Link} from 'react-router-dom';

const HomePage = () => {
  return (
    <div
      style={{backgroundImage: `url(${bg})`}}
      className="tr-min-h-screen tr-bg-cover tr-relative tr-flex tr-flex-col tr-justify-center"
    >
      <nav className="tr-absolute tr-inset-x-0 tr-top-0">
        <div className="tr-flex tr-justify-center sm:tr-justify-between tr-mx-auto tr-max-w-7xl tr-p-8">
          <img src="/logo/trustle-white.svg" width="150px" alt="trustle" className="tr-hidden sm:tr-block" />
          <div className="tr-flex">
            <Link to="/signup" className="tr-text-white tr-mr-8 sm:tr-mr-16">
              Signup
            </Link>
            <Link to="/login" className="tr-text-white">
              Login
            </Link>
          </div>
        </div>
      </nav>
      <div className="tr-flex tr-flex-col tr-items-center tr-justify-center tr-text-white tr-text-3xl tr-tracking-widest tr-font-bold tr-text-center">
        Say hello to
        <img src="/logo/trustle-white.svg" className="tr-my-12 tr-w-4/5 tr-max-w-2xl" alt="trustle" />
        Access management simplified
      </div>
    </div>
  );
};

export default HomePage;
