import React, {createContext, PropsWithChildren, useContext, useEffect} from 'react';
import {useUsers} from './hooks/useUsers';
import {useUserFilters} from './UserFiltersContext';

type UsersListedContextType = {
  listUsersResponse: any;
  loading: boolean;
  refetchUsers: () => Promise<void>;
};

const UsersListedContext = createContext<UsersListedContextType | undefined>(undefined);

type UsersListedProviderProps = PropsWithChildren<{}>;

export const UsersListedProvider: React.FC<UsersListedProviderProps> = ({children}) => {
  const {data: listUsersResponse, loading, fetchUsers} = useUsers();
  const {filters} = useUserFilters();

  useEffect(() => {
    void fetchUsers({...filters});
  }, [filters]);

  const refetchUsers = async () => {
    await fetchUsers({...filters});
  };

  return (
    <UsersListedContext.Provider
      value={{
        listUsersResponse,
        loading,
        refetchUsers,
      }}
    >
      {children}
    </UsersListedContext.Provider>
  );
};

export const useUsersListed = () => {
  const context = useContext(UsersListedContext);
  if (context === undefined) {
    throw new Error('useUsersListed must be used within a UsersListedProvider');
  }
  return context;
};
