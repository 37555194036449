import React, {useContext} from 'react';
import _ from 'lodash';
import {observer} from 'mobx-react';
import {ConnectorsDataT, MetadataType, PermissionMetadata} from '../../../types';
import {Tab, Nav, Accordion, Card, useAccordionToggle, AccordionContext, ListGroup} from 'react-bootstrap';
import {Badge} from '@trustle/component-library';
import Highlight from 'src/components/highlightjs/Highlight';
import FontAwesomeIcon from 'src/components/FontAwesomeIcon';
import {faChevronUp, faChevronDown} from 'src/components/FontAwesomeIcon';

type PropsT = {
  permission: {connectorData?: any[]};
};

type ContextPropsT = {
  children?: any;
  eventKey: string;
  callback?: (e: string) => void;
};

const GenericPermissionMetadata = observer(function GenericPermissionMetadata(props: PropsT) {
  const {permission} = props;
  const {connectorData} = permission;

  const definition = _.find(connectorData, (d) => {
    return d.data.metadataType === MetadataType.PERMISSION_DEFINITION;
  });

  const permissionsList = _.filter(connectorData, (d) => {
    return d.data.metadataType === MetadataType.RELATED_PERMISSION;
  });

  const showPermissionAssociatedData = (): React.ReactElement => {
    const listPolicies = (listPolicies: ConnectorsDataT[]) => {
      if (_.isEmpty(listPolicies)) {
        return <span>There are no items to show</span>;
      }
      return (
        <>
          <span>
            <small>Select an item from the list:</small>
          </span>
          <Tab.Container id="left-tabs-example" defaultActiveKey={listPolicies[0].id}>
            <div className="tr-flex">
              <div className="tr-w-5/12">
                <ListGroup>
                  {listPolicies.map((p: ConnectorsDataT) => {
                    const key = (p.data as PermissionMetadata).id;
                    return (
                      <ListGroup.Item key={key} eventKey={key}>
                        {p.title}
                      </ListGroup.Item>
                    );
                  })}
                </ListGroup>
              </div>

              <div className="tr-w-7/12">
                <Tab.Content>
                  <Nav.Item>
                    {listPolicies.map((p: ConnectorsDataT) => {
                      const meta = p.data as any;

                      return (
                        <Tab.Pane key={meta.id} eventKey={meta.id} unmountOnExit={true}>
                          {p && p.data && (
                            <p className="tr-my-3">
                              {'ID: '}
                              <Badge className="tr-mr-2" variant="secondary">
                                {meta.id}
                              </Badge>
                              {meta.details.subtype && <Badge variant="light">{meta.details.subtype}</Badge>}
                              <span className="blockquote-footer">{meta.details.description}</span>
                            </p>
                          )}
                          {meta && !_.isEmpty(meta.details) && (
                            <div>
                              {'Metadata: '}
                              <Highlight language="json">
                                {JSON.stringify(_.omit(meta.details, ['description', 'subtype']), null, '  ')}
                              </Highlight>
                            </div>
                          )}
                        </Tab.Pane>
                      );
                    })}
                  </Nav.Item>
                </Tab.Content>
              </div>
            </div>
          </Tab.Container>
        </>
      );
    };

    function ContextAwareToggle({children, eventKey, callback}: ContextPropsT) {
      const currentEventKey = useContext(AccordionContext);
      const decoratedOnClick = useAccordionToggle(eventKey, () => callback && callback(eventKey));
      const isCurrentEventKey = currentEventKey === eventKey;
      return (
        <div onClick={decoratedOnClick}>
          <div>
            {children}
            <div className="float-right">
              <FontAwesomeIcon icon={isCurrentEventKey ? faChevronUp : faChevronDown} />
            </div>
          </div>
        </div>
      );
    }

    const meta = definition?.data;
    const hasRelatedPerm = !_.isEmpty(permissionsList);

    return (
      <>
        {!_.isEmpty(meta) && !hasRelatedPerm && (
          <div className="tr-mx-3">
            <p className="tr-my-3">
              {'ID: '}
              <Badge className="tr-mr-2" variant="secondary">
                {meta.id}
              </Badge>
              {meta.details.subtype && <Badge variant="light">{meta.details.subtype}</Badge>}
              <span className="blockquote-footer">{meta.details.description}</span>
            </p>
            {'Metadata: '}
            <Highlight language="json">
              {JSON.stringify(_.omit(meta.details, ['description', 'subtype']), null, '  ')}
            </Highlight>
          </div>
        )}
        {_.isEmpty(meta) && !hasRelatedPerm && <span>No metadata</span>}
        {hasRelatedPerm && (
          <Accordion defaultActiveKey="0">
            <Card>
              <Card.Header>
                <ContextAwareToggle eventKey="0">
                  <span className="tr-mr-3">
                    <strong>Related Permissions ({permissionsList.length})</strong>
                  </span>
                </ContextAwareToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Card.Body>{listPolicies(permissionsList)}</Card.Body>
              </Accordion.Collapse>
            </Card>

            {!_.isEmpty(meta) && (
              <Card>
                <Card.Header>
                  <ContextAwareToggle eventKey="1">
                    <span className="tr-mr-3">
                      <strong>Definition</strong>
                    </span>
                  </ContextAwareToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div>
                      {meta && (
                        <div className="tr-mx-3">
                          <p className="tr-my-3">
                            {'ID: '}
                            <Badge variant="secondary">{meta.id}</Badge>
                            <span className="blockquote-footer">{meta.details.description}</span>
                          </p>
                          {'Metadata: '}
                          <Highlight language="json">
                            {JSON.stringify(_.omit(meta.details, 'description'), null, '  ')}
                          </Highlight>
                        </div>
                      )}
                      {!meta && <span>No metadata</span>}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            )}
          </Accordion>
        )}
      </>
    );
  };

  if (permissionsList.length > 0 || !_.isEmpty(definition)) {
    return showPermissionAssociatedData();
  }

  return null;
});

export default GenericPermissionMetadata;
