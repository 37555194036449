import React from 'react';
import {makeAutoObservable} from 'mobx';
import {ReferenceType} from 'src/types';
import moment from 'moment';
import RootStore from '..';
import {Account} from './Account';
import {Resource} from './Resource';
import _ from 'lodash';
import {AccountTabTypeE} from 'src/views/Resource/Account/ResourceAccounts';

export enum RECOMMENDATION_TYPE {
  REVIEW_DEFAULT_CONFIGURATION = 'Review Default Configuration',
  REVIEW_CONNECTION_SETTINGS = 'Review Connection Settings',
  ENABLE_SERVICE_USAGE = 'Enable Retrieving Service Usage Data',
  ROTATE_ACCESS_KEY_1 = 'Rotate Access Key 1',
  ROTATE_ACCESS_KEY_2 = 'Rotate Access Key 2',
  ROTATE_CERT_1 = 'Rotate Certificate 1',
  ROTATE_CERT_2 = 'Rotate Certificate 2',
}

export class Recommendation {
  id!: string;
  refId!: string;
  refType!: ReferenceType;
  createdOn!: Date;
  assignedOn?: Date;
  assignedTo?: string;
  assignedBy?: string;
  completedOn?: Date;
  completedBy?: string;
  type!: RECOMMENDATION_TYPE;
  data: {lastRotated?: string} = {};

  constructor(private rootStore: RootStore, data: Partial<Recommendation>) {
    makeAutoObservable(this, {id: false});
    Object.assign(this, data);
  }

  /** used for styling table rows displaying the recommendation */
  get classes(): string {
    switch (this.type) {
      case RECOMMENDATION_TYPE.ROTATE_ACCESS_KEY_1:
      case RECOMMENDATION_TYPE.ROTATE_ACCESS_KEY_2:
        return moment().diff(this.data.lastRotated, 'days') > 90 ? 'bg-error-300' : 'bg-warning-100';
      default:
        return '';
    }
  }

  get status(): React.ReactNode {
    switch (this.type) {
      case RECOMMENDATION_TYPE.REVIEW_DEFAULT_CONFIGURATION:
      case RECOMMENDATION_TYPE.REVIEW_CONNECTION_SETTINGS:
        return _.isNil(this.completedOn) ? 'Needs to be confirmed' : 'Settings reviewed';
      case RECOMMENDATION_TYPE.ENABLE_SERVICE_USAGE:
        return _.isNil(this.completedOn) ? 'Please Enable' : 'Setting enabled';
      case RECOMMENDATION_TYPE.ROTATE_ACCESS_KEY_1:
      case RECOMMENDATION_TYPE.ROTATE_ACCESS_KEY_2:
      case RECOMMENDATION_TYPE.ROTATE_CERT_1:
      case RECOMMENDATION_TYPE.ROTATE_CERT_2: {
        const daysSinceLastRotation = moment().diff(this.data.lastRotated, 'days');
        return daysSinceLastRotation > 90
          ? `Rotation Overdue By: ${daysSinceLastRotation - 90} Days`
          : `Rotate in ${90 - daysSinceLastRotation} Days`;
      }
      default:
        return '';
    }
  }

  get associatedAccount(): Account | undefined {
    if (this.refId.startsWith('ua_')) {
      return this.rootStore.accountsStore.accountsMap[this.refId];
    } else if (this.refId.startsWith('r_')) {
      return this.rootStore.newResourceStore.resourceMap[this.refId].connectorAccount;
    } else {
      return undefined;
    }
  }

  get system(): Resource | undefined {
    if (this.refId.startsWith('ua_')) {
      return this.associatedAccount?.forResource?.rootResource;
    } else if (this.refId.startsWith('r_')) {
      return this.rootStore.newResourceStore.resourceMap[this.refId].rootResource;
    } else {
      return undefined;
    }
  }

  get actionLink(): string {
    switch (this.type) {
      case RECOMMENDATION_TYPE.REVIEW_DEFAULT_CONFIGURATION:
        return `/resource/manage/${this.system?.id}/settings/edit`;
      case RECOMMENDATION_TYPE.REVIEW_CONNECTION_SETTINGS:
      case RECOMMENDATION_TYPE.ENABLE_SERVICE_USAGE:
        return `/resource/manage/${this.system?.id}/settings/edit_connection`;
      case RECOMMENDATION_TYPE.ROTATE_ACCESS_KEY_1:
      case RECOMMENDATION_TYPE.ROTATE_ACCESS_KEY_2:
      case RECOMMENDATION_TYPE.ROTATE_CERT_1:
      case RECOMMENDATION_TYPE.ROTATE_CERT_2: {
        const account = this.rootStore.accountsStore.accountsMap[this.refId];
        const escapedName = encodeURIComponent(account?.account)
          .replace('#', '%23')
          .replace("'", '%27')
          .replace('(', '%28')
          .replace(')', '%29')
          .replace('*', '%2A')
          .replace('+', '%%2B');
        return `/resource/manage/${this.system?.id}/accounts/${AccountTabTypeE.UNASSIGNED}?Account+Name=${escapedName}`;
      }
      default:
        return '';
    }
  }

  get actionText(): string {
    switch (this.type) {
      case RECOMMENDATION_TYPE.REVIEW_DEFAULT_CONFIGURATION:
        return `Review Configuration`;
      case RECOMMENDATION_TYPE.REVIEW_CONNECTION_SETTINGS:
        return `Review Connection`;
      case RECOMMENDATION_TYPE.ENABLE_SERVICE_USAGE:
        return `Enable Setting`;
      case RECOMMENDATION_TYPE.ROTATE_ACCESS_KEY_1:
      case RECOMMENDATION_TYPE.ROTATE_ACCESS_KEY_2:
      case RECOMMENDATION_TYPE.ROTATE_CERT_1:
      case RECOMMENDATION_TYPE.ROTATE_CERT_2:
        return `Link Account`;
      default:
        return '';
    }
  }
}
