import React from 'react';
import {TabsDocsT} from './DocSection';
import DocSection from './DocSection';

export function ConfigureTableau() {
  const tabs: TabsDocsT = [
    {id: 'overview', label: 'Introduction', description: overview(), active: true},
    {id: 'steps', label: 'Configuration Steps', description: steps(), active: true},
  ];

  function overview(): React.ReactNode {
    return (
      <div className="w-75">
        <p>
          <p>
            This guide will walk you through the steps required to establish a connection between Trustle and your
            Tableau Cloud site.
          </p>
          <p>
            Once connected, Trustle will be able to retrieve data from your Tableau site, including user and group
            information as well as metadata related to the content under the Explore tab, such as projects, workbooks,
            datasources, views, and their associated permissions.
          </p>
          <p>
            With the Tableau connector, you will have the ability to easily manage group memberships and view detailed
            information about the permissions assigned to each group and user.
          </p>
          <p>
            If you have any questions or need assistance, please reach out to our support team at{' '}
            <a href="mailto:support@trustle.com">support@trustle.com</a>.
          </p>
        </p>
      </div>
    );
  }

  function steps(): React.ReactNode {
    return (
      <div className="w-75">
        <p>
          <ol>
            <li>To connect to Tableau, you will need to generate a Personal Access Token.</li>
            <li>To do this, you must have access to Tableau Cloud as a "Site Administrator Creator".</li>
            <li>Log in to your Tableau account and navigate to the User Settings section.</li>
            <li>
              In the Personal Access Tokens section, click on "Create new token" and provide a name for the token.
              <div className="image-wrap">
                <img src={'/docs/connectors/tableau/step4.png'} className="vertical-align mb-4 mt-3 shadow" />
              </div>
            </li>
            <li>
              The generated token will only be shown once, so be sure to store it securely until needed by Trustle.
              <div className="image-wrap">
                <img src={'/docs/connectors/tableau/step5.png'} className="vertical-align mb-4 mt-3 shadow" />
              </div>
            </li>
            <li>
              {'In addition to the '}
              <code className="text-info">
                <strong>TOKEN NAME</strong>
              </code>
              {' and '}
              <code className="text-info">
                <strong>TOKEN SECRET</strong>
              </code>
              {', you will also need to provide the following information: '}
              <code className="text-info">
                https://<strong>SERVER_NAME</strong>/#/site/<strong>SITE_NAME</strong>/home
              </code>
              .
            </li>
            <li>
              Finally, log in to your Trustle organization and create a new Tableau connector by navigating to the
              "Manage tab" and clicking on the "+add System" button.
              <div className="image-wrap">
                <img src={'/docs/connectors/tableau/step7.png'} className="vertical-align mb-4 mt-3 shadow" />
              </div>
            </li>
          </ol>
        </p>
      </div>
    );
  }

  return (
    <>
      <DocSection tabs={tabs} title="Guide: Setting up a Tableau Connector" />
    </>
  );
}
