import _ from 'lodash';
import React, {useState} from 'react';

import {SensitivitySettingT} from 'src/types';
import {SensitivityLevelDefinitions, LevelDefinitionT} from 'src/components/sensitivity';
import {Button, DurationInput, Icon, Tab} from '@trustle/component-library';
import {Formik, Form, Field} from 'formik';

import moment from 'moment';

type SensitivityLevelParametersT = {
  sensitivitySettings: SensitivitySettingT[];
  updateSensitivity: (targetSetting: SensitivitySettingT, updatedValues: Partial<SensitivitySettingT>) => void;
  setShowAdvancedSettings: (setting: SensitivitySettingT | null) => void;
  sensitivityTab: string;
  setSensitivityTab: (tabMode: string) => void;
  setError: (error: boolean) => void;
};

const SensitivityLevelParameters = (props: SensitivityLevelParametersT) => {
  const {sensitivitySettings, updateSensitivity, setShowAdvancedSettings, setError} = props;

  const initialValuesMap = _.mapValues(_.keyBy(sensitivitySettings, 'level'), (setting) => ({
    approvalDuration: {
      durationValue: setting.maxApprovalDurationValue,
      durationUnit: setting.maxApprovalDurationUnit,
    },
    accessDuration: {
      durationValue: setting.maxAccessDurationValue,
      durationUnit: setting.maxAccessDurationUnit,
    },
  }));

  const sensitivityTabs = _.compact(
    _.map(SensitivityLevelDefinitions, (levelDefinition: LevelDefinitionT) => {
      const setting = _.find(sensitivitySettings, {level: levelDefinition.level});

      if (!setting) {
        return null;
      }

      const [errorMsg, setErrorMsg] = useState<{approval?: string; access?: string}>({});

      const content = (
        <div className="tr-py-3 tr-flex tr-flex-row-reverse tr-justify-between tr-items-center">
          <Button
            variant="ternary"
            data-testid="editMode-icon-enable"
            className="tr-flex tr-items-center cursor-pointer tr-h-12"
            onClick={(e: any) => {
              e.preventDefault();
              setShowAdvancedSettings(setting);
            }}
          >
            <Icon type="edit" title="Advanced" />
            <span>Advanced</span>
          </Button>

          <div className="py-3 tr-flex-col ">
            <div className="tr-w-[440px] tr-flex tr-justify-between tr-pb-4 ">
              <Field
                component={DurationInput}
                name={`${setting.level}.approvalDuration`}
                label="Maximum Approval Duration"
                onChange={(e: any) => {
                  setError(false);
                  setErrorMsg((prevState) => ({
                    ...prevState,
                    approval: undefined,
                    access: undefined,
                  }));

                  const targetName =
                    e.field === `${setting.level}.approvalDuration`
                      ? 'maxApprovalDurationUnit'
                      : 'maxApprovalDurationValue';
                  const targetValue = e.field === `${setting.level}.approvalDuration` ? e.value : e.target.value;

                  updateSensitivity(setting, {[targetName]: targetValue});

                  if (e?.target?.value < 1 || e?.value < 1) {
                    setError(true);
                    setErrorMsg((prevState) => ({
                      ...prevState,
                      approval: 'Duration value must be greater than 0',
                    }));
                  }

                  const value =
                    targetName === 'maxApprovalDurationValue' ? e.target.value : setting.maxApprovalDurationValue;
                  const unit = targetName === 'maxApprovalDurationUnit' ? e.value : setting.maxApprovalDurationUnit;

                  const original = moment().add(setting.maxAccessDurationValue, setting.maxAccessDurationUnit);
                  const updated = moment().add(value, unit);

                  if (original.diff(updated) > 0) {
                    setError(true);
                    setErrorMsg((prevState) => ({
                      ...prevState,
                      access: `The access duration can't be greater than the approval duration`,
                    }));
                  }
                }}
              />

              <Field
                component={DurationInput}
                name={`${setting.level}.accessDuration`}
                label="Maximum Access Duration"
                onChange={(e: any) => {
                  setError(false);
                  setErrorMsg((prevState) => ({
                    ...prevState,
                    access: undefined,
                  }));

                  const targetName =
                    e.field === `${setting.level}.accessDuration` ? 'maxAccessDurationUnit' : 'maxAccessDurationValue';
                  const targetValue = e.field === `${setting.level}.accessDuration` ? e.value : e.target.value;

                  updateSensitivity(setting, {[targetName]: targetValue});

                  if (e?.target?.value < 1 || e?.value < 1) {
                    setError(true);
                    setErrorMsg((prevState) => ({
                      ...prevState,
                      access: 'Duration value must be greater than 0',
                    }));
                  }

                  const value =
                    targetName === 'maxAccessDurationValue' ? e.target.value : setting.maxAccessDurationValue;
                  const unit = targetName === 'maxAccessDurationUnit' ? e.value : setting.maxAccessDurationUnit;

                  const original = moment().add(setting.maxApprovalDurationValue, setting.maxApprovalDurationUnit);
                  const updated = moment().add(value, unit);

                  if (original.diff(updated) < 0) {
                    setError(true);
                    setErrorMsg((prevState) => ({
                      ...prevState,
                      access: `The access duration can't be greater than the approval duration`,
                    }));
                  }
                }}
              />
            </div>
            {errorMsg?.approval && <div className="tr-text-red-500 tr-text-sm">{errorMsg.approval}</div>}
            {errorMsg?.access && <div className="tr-text-red-500 tr-text-sm">{errorMsg.access}</div>}
          </div>
        </div>
      );

      return {
        mode: levelDefinition.name,
        displayName: `${levelDefinition.name}${!_.isEmpty(errorMsg.access) ? ' *' : ''}`,
        content,
      };
    })
  );

  return (
    <>
      <Formik initialValues={initialValuesMap} onSubmit={() => {}} enableReinitialize={true}>
        {() => {
          return (
            <Form>
              <Tab.Group>
                <Tab.List variant="line">
                  {_.map(sensitivityTabs, (tab) => {
                    return (
                      <Tab data-testid={tab.mode} key={tab.mode}>
                        {tab.displayName}
                      </Tab>
                    );
                  })}
                </Tab.List>
                <Tab.Panels>
                  {_.map(sensitivityTabs, (tab) => {
                    return (
                      <Tab.Panel data-testid={tab.mode} key={tab.mode}>
                        {tab.content}
                      </Tab.Panel>
                    );
                  })}
                </Tab.Panels>
              </Tab.Group>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default SensitivityLevelParameters;
