import React, {useState} from 'react';
import {Link, Route, Switch, useParams} from 'react-router-dom';
import EditConnection from 'src/connectors/common/editConnection/EditConnection';
import ResourceForm from 'src/views/Resource/Edit/ResourceForm';
import {ConnectionServiceE} from 'src/types';
import './ResourceSettingsBase.scss';
import OnCallSettings from './OnCall/OnCallSettings';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Tab} from '@trustle/component-library';
import {useFeatureFlags} from 'src/lib';
import {AuthoritySettings} from '../../connectors/common/authority/authority-settings/AuthoritySettings';
import _ from 'lodash';
import {SettingsHeader} from './settings-header/SettingsHeader';

export enum ResourceSettingsTabs {
  EDIT = 'edit',
  CONNECTION_EDIT = 'edit_connection',
  AUTHORITY_SETTINGS = 'authority_settings',
  ONCALL = 'oncall',
}

type ResourceSettingsPropsT = {
  resource: Resource;
};

function ResourceSettings(props: ResourceSettingsPropsT) {
  const {resource} = props;
  const featureFlagViewer = useFeatureFlags();

  const routeParams = useParams() as {activeTab?: ResourceSettingsTabs};
  const tabs = _.values(ResourceSettingsTabs);
  const activeTab = _.findIndex(tabs, (tab) => {
    return tab === routeParams.activeTab;
  });
  const [selectedIndex, setSelectedIndex] = useState<number>(activeTab);

  const displayAuthoritySettings = resource.idpFeatureEnabled;

  const {connector} = resource;

  return (
    <div className="resource-settings container">
      <SettingsHeader resource={resource} />
      <div className="py-3">
        <Tab.Group selectedIndex={selectedIndex} onChange={(i: number) => setSelectedIndex(i)}>
          <Tab.List valiant="line" className="border-bottom">
            <Tab
              as={Link}
              to={`/resource/manage/${resource.id}/settings/${ResourceSettingsTabs.EDIT}`}
              key={ResourceSettingsTabs.EDIT}
            >{`System Settings`}</Tab>
            {connector && connector.service !== ConnectionServiceE.GENERIC && (
              <Tab
                as={Link}
                to={`/resource/manage/${resource.id}/settings/${ResourceSettingsTabs.CONNECTION_EDIT}`}
                key={ResourceSettingsTabs.CONNECTION_EDIT}
              >{`Connection Settings`}</Tab>
            )}
            {displayAuthoritySettings && (
              <Tab
                key={ResourceSettingsTabs.AUTHORITY_SETTINGS}
                as={Link}
                to={`/resource/manage/${resource.id}/settings/${ResourceSettingsTabs.AUTHORITY_SETTINGS}`}
              >
                {'Authority Settings'}
              </Tab>
            )}
            {!featureFlagViewer.isEnabled('disable_oncall') && ConnectionServiceE.GENERIC !== connector?.service && (
              <Tab
                key={ResourceSettingsTabs.ONCALL}
                as={Link}
                to={`/resource/manage/${resource.id}/settings/${ResourceSettingsTabs.ONCALL}`}
              >
                {'On-Call'}
              </Tab>
            )}
          </Tab.List>
        </Tab.Group>
        <Switch>
          <Route path={`/resource/manage/${resource.id}/settings/${ResourceSettingsTabs.EDIT}`} exact={true}>
            <ResourceForm resource={resource} isCreating={false} />
          </Route>
          <Route path={`/resource/manage/${resource.id}/settings/${ResourceSettingsTabs.CONNECTION_EDIT}`} exact={true}>
            <EditConnection resource={resource} />
          </Route>
          {displayAuthoritySettings && (
            <Route
              path={`/resource/manage/${resource.id}/settings/${ResourceSettingsTabs.AUTHORITY_SETTINGS}`}
              exact={true}
            >
              <AuthoritySettings resource={resource} />
            </Route>
          )}
          {!featureFlagViewer.isEnabled('disable_oncall') && (
            <Route path={`/resource/manage/${resource.id}/settings/${ResourceSettingsTabs.ONCALL}`} exact={true}>
              <OnCallSettings id={resource.id} />
            </Route>
          )}
        </Switch>
      </div>
    </div>
  );
}

export default ResourceSettings;
