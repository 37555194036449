import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import StepIndicator from 'src/components/StepIndicator';
import {ProgressStatusInfo} from 'src/types';

export default observer(function ImportTasksList(props: {progressStatus: ProgressStatusInfo; retry?: string}) {
  const {progressStatus} = props;
  if (_.isEmpty(progressStatus) || _.isEmpty(progressStatus.tasks)) {
    return <></>;
  }
  const {tasks, current, error, finished} = progressStatus;

  const [currentIdx, setCurrentIdx] = useState<number>(_.indexOf(tasks, current));

  const total = _.size(tasks);

  useEffect(() => {
    setCurrentIdx(finished && !error ? total : _.indexOf(tasks, current));
  }, [current, error, finished]);

  if (total === 0) {
    return <></>;
  }

  const renderTaskIndicators = () => {
    return (
      <>
        {_.map(tasks, (t: string) => {
          const i = _.indexOf(tasks, t);
          const status = i < currentIdx ? 'completed' : i > currentIdx ? 'pending' : error ? 'failed' : 'processing';
          return (
            <div
              className="tr-w-1/2 lg:tr-w-2/12 tr-p-0 tr-pt-3 tr-pb-2 tr-pr-3"
              style={{minHeight: '40px', display: 'flex', alignItems: 'center', flexWrap: 'wrap'}}
              key={i}
            >
              <StepIndicator status={status} title={t} key={t} />
            </div>
          );
        })}
      </>
    );
  };

  return renderTaskIndicators();
});
