import React, {useEffect} from 'react';
import {AccountsFilterProvider} from './AccountsFiltersContext';
import {AccountsView} from './internal/accounts-view/AccountsView';
import {Resource} from 'src/stores/domainObjects/Resource';
import {LinkStatusTabs} from './internal/link-status-tabs/LinkStatusTabs';
import {HeaderSearch} from './internal/header-search/HeaderSearch';
import {useAccountsSummary} from './hooks/useAccountsSummary';

type Props = {system: Resource};

export function SystemAccountsPanel({system}: Props) {
  return (
    <AccountsFilterProvider>
      <SystemAccountsPanelInternal system={system} />
    </AccountsFilterProvider>
  );
}

export function SystemAccountsPanelInternal({system}: Props) {
  const {data: accountsSummary, getAccountsSummary} = useAccountsSummary();

  useEffect(() => {
    void getAccountsSummary({systemId: system.id});
  }, []);

  return (
    <main className="container">
      <section id="header-search" className="tr-flex tr-mb-8">
        <div>
          <h1>Accounts</h1>
          <p className="text-muted tr-max-w-[450px]">
            Link your system accounts with Trustle Users to manage, analyze and track on Trustle.
          </p>
        </div>

        <div className="tr-flex tr-items-baseline tr-gap-3 tr-ml-auto">
          <div className="tr-flex tr-gap-2 tr-items-center"></div>
        </div>
      </section>
      <section>
        <HeaderSearch />
        <LinkStatusTabs accountsSummary={accountsSummary} />
        <div className="tr-flex tr-flex-col tr-gap-4">
          {<AccountsView system={system} accountsSummary={accountsSummary} />}
        </div>
      </section>
    </main>
  );
}
