import React, {useEffect, useState} from 'react';
import {
  Alert,
  Button,
  Confirmation,
  Icon,
  IconButton,
  Loading,
  Modal,
  PrefixInput,
  Table,
} from '@trustle/component-library';
import _ from 'lodash';
import {TableStatus} from '../../common/TableStatus';
import {QuickFilter} from '../admin-users-panel/internal/quick-filter/QuickFilter';
import {UsersFilterProvider, useUserFilters} from '../admin-users-panel/UserFiltersContext';
import axios from 'axios';
import {SourceOfTruthE, TeamT, TeamTypeEnum, UserT} from 'src/types';
import {Authority, DepartmentRoleTypeCol} from 'src/components/design/tables/SharedColumnDefinitions/UserColumns';
import {useHistory} from 'react-router-dom';
import {formatUserName} from 'src/lib';
import {ListUsersParams} from '../admin-users-panel/hooks/useUsers';
import OrgEvents from 'src/views/Events/OrgEvents';
import TeamForm from './TeamForm';
import AddMembersModal from './AddMembersModal';

type MemberResponse = {
  users: UserT[];
  total: number;
  page: number;
  size: number;
  pages: number;
};

export function TeamMembersPanel({team, users, summary}: {team: TeamT; users: UserT[]; summary: any}) {
  return (
    <UsersFilterProvider>
      <TeamMembers team={team} users={users} summary={summary} />
    </UsersFilterProvider>
  );
}

export function TeamMembers({team, users, summary}: {team: TeamT; users: UserT[]; summary: any}) {
  const [teamMembersResponse, setTeamMembersResponse] = useState<MemberResponse>();
  const [editingTeam, setEditingTeam] = useState<boolean>(false);
  const history = useHistory();
  const [addToTeam, setAddToTeam] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState<boolean>(false);
  const [columnSort, setColumnSort] = useState<string | undefined>(undefined);
  const [error, setError] = useState<any>();
  const {filters, setFilter, setFilters} = useUserFilters();
  const [userToDelete, setUserToDelete] = useState<UserT>();

  const loadData = async (params: ListUsersParams) => {
    const {page, size, ...rest} = params;

    const parsedParams: Record<string, string> = {
      page: (page || 1).toString(),
      size: (size || 50).toString(),
    };

    Object.keys(rest).forEach((key) => {
      const typedKey = key as keyof typeof rest;
      const value = rest[typedKey] as string | number;
      if (rest[typedKey] !== undefined) {
        parsedParams[typedKey] = value.toString();
      }
    });

    delete parsedParams.status;
    delete parsedParams.groupBy;
    parsedParams.team_id = team.id;

    const queryString = new URLSearchParams(_.omitBy(parsedParams, _.isNil)).toString();

    try {
      const response = await axios.get(`/api/v2/users?${queryString}`);
      setError(null);
      setTeamMembersResponse(response.data);
    } catch (err: any) {
      setError(err);
    }
  };

  useEffect(() => {
    void loadData(filters);
  }, [filters]);

  if (!team) {
    return <Loading />;
  }

  const multipleSortHandler = (field: string) => {
    setColumnSort(field);
    setFilters({...filters, sort: field === filters.orderBy ? 'asc' : 'desc', orderBy: field});
  };

  const columns = [
    {
      dataField: 'firstname',
      text: 'Name',
      sort: true,
      formatter: (_firstname: string, user: UserT) => (
        <div id="userNameCol" data-testid={`name${user.email}`}>
          {formatUserName(user)}
        </div>
      ),
    },
    {
      dataField: 'permissionsCount',
      text: 'Systems / Permissions',
      formatter: (cell: any) => {
        return (
          <div>
            <Icon type="permission" />
            {cell}
          </div>
        );
      },
    },
    {...Authority},
    DepartmentRoleTypeCol({idpFeatureEnabled: true, columnSort, setColumnSort: multipleSortHandler}),
    {
      dataField: 'actions',
      headerStyle: {width: '4rem'},
      style: {width: '4rem'},
      classes: 'show-on-hover',
      text: '',
      formatter: (cell: any, u: UserT) => {
        const items = [];

        items.push(
          <IconButton
            icon="details"
            variant="ternary"
            key={`viewmember${u.id}`}
            title="View member"
            data-testid="view-member"
            onClick={(e: any) => {
              e.stopPropagation();
              history.push(`/users/${u.id}`);
            }}
          />
        );

        const isTrustleSoT = _.defaultTo(u.authority, SourceOfTruthE.TRUSTLE) === SourceOfTruthE.TRUSTLE;

        if (isTrustleSoT && team && team.type === TeamTypeEnum.ADHOC) {
          items.push(
            <IconButton
              icon="cancel"
              variant="ternary"
              key={`removemember${u.id}`}
              title="Remove member"
              data-testid={`removemember${u.id}`}
              onClick={(e: any) => {
                e.stopPropagation();
                setUserToDelete(u);
              }}
            />
          );
        }

        return <div className="flex-align tr-justify-center action-buttons">{items}</div>;
      },
    },
  ];

  const members = teamMembersResponse ? teamMembersResponse.users : [];
  return (
    <>
      <div className="tr-flex tr-flex-col tr-w-full">
        {summary && summary.usersCount > 0 && (
          <div className="tr-flex tr-flex-row tr-w-full">
            <div className="tr-flex tr-justify-between tr-items-center tr-pb-2">
              <div className="tr-flex tr-flex-col tr-items-center tr-font-bold tr-m-3 tr-px-5 tr-mr-10 tr-border-gray-200 tr-border-solid tr-border-b-0 tr-border-l-[1px]  tr-border-r-0  tr-border-t-0">
                <div className="tr-flex tr-items-baseline tr-text-4xl  tr-text-blue ">{summary.usersCount}</div>
                <div className="tr-flex tr-items-baseline tr-text-md">Members</div>
              </div>
            </div>
            <div className="tr-flex tr-justify-between tr-items-center tr-pb-2">
              <div className="tr-flex tr-flex-col tr-items-center tr-font-bold tr-m-3 tr-px-5  tr-mr-10 tr-border-gray-200 tr-border-solid tr-border-b-0 tr-border-l-[1px]  tr-border-r-0  tr-border-t-0">
                <div className="tr-flex tr-items-baseline tr-text-4xl  tr-text-blue ">{summary.titlesCount}</div>
                <div className="tr-flex tr-items-baseline tr-text-md">Titles</div>
              </div>
            </div>
            <div className="tr-flex tr-justify-between tr-items-center tr-pb-2">
              <div className="tr-flex tr-flex-col tr-items-center tr-font-bold tr-m-3 tr-px-5  tr-mr-10 tr-border-gray-200 tr-border-solid tr-border-b-0 tr-border-l-[1px]  tr-border-r-0  tr-border-t-0">
                <div className="tr-flex tr-items-baseline tr-text-4xl  tr-text-blue ">{summary.departmentsCount}</div>
                <div className="tr-flex tr-items-baseline tr-text-md">Departments</div>
              </div>
            </div>
            <div className="tr-flex tr-justify-between tr-items-center tr-pb-2">
              <div className="tr-flex tr-flex-col tr-items-center tr-font-bold tr-m-3 tr-px-5 tr-mr-10 tr-border-gray-200 tr-border-solid tr-border-b-0 tr-border-l-[1px]  tr-border-r-0  tr-border-t-0">
                <div className="tr-flex tr-items-baseline tr-text-4xl  tr-text-blue ">{summary.rolesCount}</div>
                <div className="tr-flex tr-items-baseline tr-text-md">Roles</div>
              </div>
            </div>
          </div>
        )}
        {error && (
          <Alert colorVariant={'danger'} onClose={() => setError(undefined)}>
            {error.message}
          </Alert>
        )}
        <div className="tr-flex tr-items-center tr-gap-3 tr-bg-[#F8F9FC] tr-p-2.5 ">
          <Icon type={'team'} size="md" />
          <h2 className="tr-mb-0">{'Members'}</h2>
          <PrefixInput
            prefixIcon="search"
            placeholder="Search for members"
            className="tr-min-w-[300px] tr-max-w-[600px]"
            onChange={(event) => {
              setFilters({...filters, nameOrEmail: event.target.value});
            }}
          />
          <Button variant="ternary" onClick={() => setShowHistory(true)}>
            View history
          </Button>
          {team.type === TeamTypeEnum.ADHOC && (
            <Button
              key="editTeam"
              data-testid="editTeamButton"
              id="editTeamButton"
              onClick={() => {
                setAddToTeam(true);
              }}
            >
              {'Add members'}
            </Button>
          )}
        </div>

        <QuickFilter usersSummary={summary} />

        <Table
          data={members}
          columns={columns}
          tableKey="teams-members-table_2"
          sizePerPage={filters.size}
          page={filters.page}
          totalSize={teamMembersResponse ? teamMembersResponse.total : 0}
          remote={{
            filter: true,
            pagination: true,
            sort: true,
            cellEdit: false,
          }}
          onSizePerPageChange={(size) => {
            setFilter('size', size);
            return {};
          }}
          onTableChange={(type: string, props: any) => {
            if (type === 'sort') {
              setFilters({...filters, sort: props.sortOrder, orderBy: props.sortField});
            } else {
              setFilter('page', props.page);
            }
          }}
          showEmptyElement={<TableStatus status="no-data" text="No members found" />}
        />
        <Modal
          width="lg"
          onClose={() => {
            setEditingTeam(false);
          }}
          visible={editingTeam}
          title={'Edit Team'}
        >
          <TeamForm
            team={team}
            users={users}
            members={members}
            onClose={() => {
              setEditingTeam(false);
            }}
            onAfterSubmit={() => {
              setEditingTeam(false);
            }}
          />
        </Modal>
        <Modal
          width="md"
          height="xs"
          visible={addToTeam}
          onClose={() => {
            setAddToTeam(false);
          }}
          title="Add Members to Team"
        >
          <AddMembersModal
            members={members}
            team={team}
            afterSubmit={() => {
              setAddToTeam(false);
            }}
          />
        </Modal>
        <Modal visible={!!showHistory} onClose={() => setShowHistory(false)} width="lg" title="History Team">
          <OrgEvents entityType={'teams'} id={team.id} />
        </Modal>
        {userToDelete && (
          <Confirmation
            onConfirm={async () => {
              setUserToDelete(undefined);
              try {
                await axios.delete(`/api/teams/${team.id}/members/${userToDelete.id}`);
                setFilters({...filters});
              } catch (err: any) {
                setError(err);
              }
            }}
            onClose={() => setUserToDelete(undefined)}
            title="Confirm member removal"
          >
            {`Please confirm that you want to remove ${formatUserName(userToDelete)} from this group`}
          </Confirmation>
        )}
      </div>
    </>
  );
}
