import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {Permission} from 'src/stores/domainObjects/Permission';
import PermissionDetailsModal, {PermTabModeE} from './PermissionDetailsModal';

// TODO (jg): narrow types when component-library icon types are improved
interface ButtonProps extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type'> {
  //   as: React.ComponentType<React.ButtonHTMLAttributes<HTMLButtonElement>>;
  as?: any;
  type?: string;
  size?: string;
  permission: Permission;
  defaultSelectedTab?: PermTabModeE;
}

const PermissionDetailsButton: React.FC<ButtonProps> = observer(
  ({permission, defaultSelectedTab, as: Component = ({...props}) => <button {...props} />, ...rest}) => {
    const [open, setOpen] = useState(false);
    return (
      <div className="tr-mx-1 tr-flex tr-items-center">
        <Component
          {...rest}
          onClick={(e: any) => {
            if (rest.disabled) {
              return;
            }
            e.stopPropagation();
            setOpen(true);
            rest.onClick?.(e);
          }}
        />
        {open && (
          <PermissionDetailsModal
            permission={permission}
            defaultSelectedTab={defaultSelectedTab ?? PermTabModeE.GENERAL}
            closeModal={() => setOpen(false)}
          />
        )}
      </div>
    );
  }
);

export default PermissionDetailsButton;
