import React from 'react';
import './HeaderServiceUsage.scss';
import _ from 'lodash';
import {formatDateFromNow, formatFullDate} from 'src/lib';
import {Resource} from 'src/stores/domainObjects/Resource';
import {WARNING_SERVICE_USAGE_THRESHOLD} from '../../lib/constants';
import {Tooltip, Icon} from '@trustle/component-library';
import moment from 'moment';

type HeaderServiceUsageProps = {
  system: Resource;
  percentage: number | null | undefined;
};

function SimplifiedHeaderServiceUsage(props: HeaderServiceUsageProps) {
  const {percentage, system} = props;

  const warningPercentage = !_.isNil(percentage) && percentage < WARNING_SERVICE_USAGE_THRESHOLD;
  const lastImport = system.connector?.lastImportServiceUsage;
  const lastImportCreated = lastImport ? moment(lastImport.created).toISOString() : '';

  return (
    <div className="tr-my-2 tr-mx-5 service-usage-header">
      <div className="tr-flex tr-justify-between">
        <div className="body6 text-left tr-mt-1 tr-mb-2 text-muted text-right strong">
          {`This information is updated every 4 hours`}
        </div>
        {!_.isEmpty(lastImportCreated) && (
          <div className="body6 tr-mt-1 tr-mb-2 text-muted">
            <Tooltip content={formatFullDate(lastImportCreated)}>
              {'Last updated: '}
              <span className="strong">{formatDateFromNow(lastImportCreated)}</span>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="body3 text-left tr-my-4">
        <div className="tr-flex tr-mb-4 bb">
          <div className="tr-mr-2">Service Usage</div>
          {warningPercentage && (
            <div className="text-trustle-navy tr-font-normal tr-text-xs">
              <Tooltip content={<div>{`Usage is below ${WARNING_SERVICE_USAGE_THRESHOLD}%`}</div>}>
                <Icon className="tr-text-warning" type="warning" />
              </Tooltip>
            </div>
          )}
        </div>
        <div
          className={`col-auto bordered text-center box tr-py-3 tr-m-5 ${warningPercentage ? 'tr-text-[#e53451]' : ''}`}
        >
          {!_.isNil(percentage) ? `${_.round(percentage)}%` : 'N/A'}
        </div>
      </div>
    </div>
  );
}

export default SimplifiedHeaderServiceUsage;
