import {Badge, Icon, Tooltip} from '@trustle/component-library';
import _ from 'lodash';
import React from 'react';
import {Link} from 'react-router-dom';
import StatusIndicator from 'src/components/StatusIndicator';
import {WARNING_SERVICE_USAGE_THRESHOLD} from 'src/lib/constants';
import {BadgeVariant} from 'src/views/Resource/Account/TrustleBadge';

type Props = {account: any; system: any};

type UsageBadgeProps = {
  value?: any;
  system: any;
};

// copied from `frontend/src/views/Resource/Account/UsageBadge.tsx`
function UsageBadge({value, system}: UsageBadgeProps) {
  if (!system?.retrieveUsageDataEnabled) {
    // show disable status
    return (
      <Tooltip
        content={
          'Retrieve Service Usage toggle is disabled in connections page. Click on this label and you will redirect to enable it and get usage data.'
        }
        position="right"
      >
        <div className="tr-items-center tr-flex">
          <Badge className="text-white cursor" pill variant={BadgeVariant.SECONDARY}>
            <Link
              to={`/resource/manage/${system.id}/settings/edit_connection#retrieve_service_usage_data`}
              className="text-white"
            >
              Disabled
            </Link>
          </Badge>
        </div>
      </Tooltip>
    );
  }
  if (_.isNil(value)) {
    if (system.isServiceUsageStatusImporting) {
      // show importing status
      return (
        <Tooltip
          content={'Retrieving data for Service Usage is in progress. Click to see more details.'}
          position="right"
        >
          <Link
            to={`/resource/manage/${system.id}/settings/edit_connection#retrieve_service_usage_data`}
            className="text-white"
          >
            <StatusIndicator status="pending" />;
          </Link>{' '}
        </Tooltip>
      );
    }
    return <Icon title="Not Available" type="notAvailable" className="tr-mx-auto" size="sm" />;
  }
  const variantValue =
    value < WARNING_SERVICE_USAGE_THRESHOLD
      ? BadgeVariant.DANGER
      : value < 100
      ? BadgeVariant.WARNING
      : BadgeVariant.PRIMARY;
  return (
    <div className="tr-items-center tr-flex">
      <Badge size="sm" className="text-white" variant={variantValue}>
        {_.round(value)}%
      </Badge>
    </div>
  );
}

// copied from `frontend/src/components/design/tables/SharedColumnDefinitions/AccountColumns.tsx`
export function Usage({account, system}: Props) {
  return <UsageBadge value={account.usage} system={system} />;
}
