import React, {useState} from 'react';

interface ConnectorContextI {
  setContextState: (property: string, value: any) => void;
  getContextState: (property: string) => unknown;
}

type ConnectorContextWrapperPropsT = {children: React.ReactNode};
type ConnectorContextStateStoreT = {[x: string]: unknown};

const ConnectorContext = React.createContext({} as ConnectorContextI);

export function ConnectorContextWrapper({children}: ConnectorContextWrapperPropsT) {
  const [contextValue, _setContextValue] = useState<ConnectorContextStateStoreT>();

  const setContextState = (property: string, value: any) => {
    _setContextValue((oldValue) => {
      const newValue = {...oldValue};
      newValue[property] = value;
      return newValue;
    });
  };

  const getContextState = (property: string) => (contextValue === undefined ? undefined : contextValue[property]);

  const mainContext = {
    setContextState,
    getContextState,
  };

  return <ConnectorContext.Provider value={mainContext}>{children}</ConnectorContext.Provider>;
}
