import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Link} from 'react-router-dom';
import {Account} from 'src/stores/domainObjects/Account';

import {SizesEnum} from '@trustle/component-library/dist/types';
import {Icon, Tooltip} from '@trustle/component-library';

type PropsT = {account: Account | undefined; iconSize?: SizesEnum};

const ShowAuthorizedAccount = observer(function (props: PropsT) {
  const {account, iconSize = 'xs'} = props;
  const resourceId = account?.rid;
  if (_.isNil(resourceId) || _.isNil(account) || !account.isAuthzOwner) {
    return <></>;
  }
  const icon = <Icon type={'connection'} className="cursor tr-text-[#F08C00] mr-2" size={iconSize} />;

  return (
    <Tooltip content={'These credentials are in use by the Trustle connector.'}>
      {account.forResource.userIsOwner ? (
        <Link to={`/resource/manage/${resourceId}/settings/edit_connection`}>{icon}</Link>
      ) : (
        icon
      )}
    </Tooltip>
  );
});
export default ShowAuthorizedAccount;
