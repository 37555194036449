import React from 'react';
import {useRootStore} from '../../../../../../../../lib/hooks';
import {Button, Modal} from '@trustle/component-library';
import {SourceOfTruthE} from '../../../../../../../../types';
import {formatUserName} from '../../../../../../../../lib';
import axios from 'axios';
import {UserActionModalProps} from '../types';

export function UserToggleAuthorityModal({user, handleCloseFn, onConfirm}: UserActionModalProps) {
  const {toast} = useRootStore();
  const {sourceOfTruth, id} = user;

  const handleConfirm = async () => {
    try {
      await axios.patch(`/api/users/${id}`, {
        sourceOfTruth: sourceOfTruth === SourceOfTruthE.IDP ? SourceOfTruthE.TRUSTLE : SourceOfTruthE.IDP,
      });
      toast.success('Authority changed successfully');
    } catch (error) {
      toast.error("Authority couldn't be changed");
    } finally {
      onConfirm?.();
      handleCloseFn();
    }
  };

  return (
    <Modal onClose={handleCloseFn} title={'Confirm action'}>
      <div>
        <span>
          {`Please, confirm that you want to change the authority for user: `}
          <strong>{formatUserName(user)}</strong>
          {` to be `}
          {sourceOfTruth === SourceOfTruthE.IDP ? (
            <strong>Trustle</strong>
          ) : (
            <>
              {'the configured '}
              <strong>IDP</strong>
            </>
          )}
        </span>
        <div className="tr-flex tr-justify-end tr-mt-4">
          <Button onClick={() => handleCloseFn()} variant="secondary">
            Cancel
          </Button>
          <Button onClick={() => handleConfirm()}>Confirm</Button>
        </div>
      </div>
    </Modal>
  );
}
