import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {ConnectionInfoT, ImportStatus} from 'src/types';
import {Loading} from '@trustle/component-library';
import OktaSetup from './OktaSetup';
import {ReportType} from '../types';
import {Resource} from 'src/stores/domainObjects/Resource';
import TestConnection from '../common/editConnection/TestConnection';
import {useToasts} from 'react-toast-notifications';
import {useConnectionCredentials} from '../../lib/hooks';

export default function EditConnectionOkta(props: {resource: Resource}) {
  const {resource} = props;

  const {addToast} = useToasts();

  const [connectionInfo, setConnectionInfo] = useState<ConnectionInfoT>();
  const [displayCredentialsForm, setDisplayCredentialsForm] = useState<boolean>(false);
  const [testConnectionInProgress, setTestConnectionInProgress] = useState<boolean>(false);
  const [testStatus, setTestStatus] = useState<string | undefined>(undefined);
  const {testConnection: testConnectionAPI} = useConnectionCredentials();

  async function loadConnection() {
    const connectionResponse = await axios.get(`/api/connect/${resource.connectionId}`);
    setConnectionInfo(connectionResponse.data);
  }

  async function onCredentialsUpdated() {
    await testConnection();
  }

  useEffect(() => {
    void loadConnection();
  }, [resource.connectionId]);

  useEffect(() => {
    void testConnection();
  }, [connectionInfo]);

  useEffect(() => {
    const lastImport = resource.connector?.lastImport;

    if (testStatus === ImportStatus.FINISHED && lastImport?.status === ImportStatus.FAILED) {
      void resource.startImport();
    }
  }, [testStatus]);

  if (!connectionInfo) {
    return <Loading />;
  }

  const {connection} = connectionInfo;
  const {serviceFriendlyName} = connection;

  async function testConnection() {
    setTestConnectionInProgress(true);
    setTestStatus(ImportStatus.PENDING);

    try {
      const {data} = await testConnectionAPI(connection.id, {});

      if (data.error) {
        addToast(`Connection failed ${data.error.message ? `: ${data.error.message}` : ''}`, {
          appearance: 'error',
          autoDismiss: false,
        });
      }
      setTestConnectionInProgress(false);
      setTestStatus(data.error ? ImportStatus.FAILED : ImportStatus.FINISHED);
    } catch (err) {
      setTestStatus(ImportStatus.FAILED);
      setTestConnectionInProgress(false);
    }
  }

  return (
    <div className="tr-flex tr-flex-col">
      <div className="tr-my-2 no-padding">
        <div className="tr-flex tr-flex-col">
          <TestConnection
            name={serviceFriendlyName!}
            connectorStatus={testStatus!}
            onTest={testConnection}
            testing={testConnectionInProgress}
            onUpdate={() => setDisplayCredentialsForm(!displayCredentialsForm)}
            updating={displayCredentialsForm}
          />
          {displayCredentialsForm && (
            <OktaSetup connectionInfo={connectionInfo} onComplete={onCredentialsUpdated} editMode={true} />
          )}
        </div>
      </div>
      <div className="tr-pt-2 tr-my-4 no-padding tr-flex tr-flex-col">
        <h3>Available Reports</h3>
        <div className="tr-flex">
          <a
            href={`/api/connect/${connection.id}/report/${ReportType.APP_USAGE_PER_ACCOUNT}`}
            className="link"
            download
          >
            App Usage Per Account
          </a>
        </div>
      </div>
    </div>
  );
}
