import React from 'react';
import {Tab, Nav, ListGroup} from 'react-bootstrap';
import {Badge} from '@trustle/component-library';
import Highlight from 'src/components/highlightjs/Highlight';
import _ from 'lodash';
import {observer} from 'mobx-react';

type PropsT = {listPolicies: {id: string; title: string; arn?: string; document: string}[]};
const ListTabPolicies = observer(function ListTabPolicies(props: PropsT) {
  const {listPolicies} = props;
  if (_.isEmpty(listPolicies)) {
    return <span>There are no policies to show</span>;
  }
  return (
    <>
      <Tab.Container id="left-tabs-example" defaultActiveKey={listPolicies[0].id}>
        <div className="tr-flex ">
          <div className="tr-w-5/12">
            <ListGroup className="m-1">
              {listPolicies.map((p) => {
                return (
                  <ListGroup.Item key={p.id} eventKey={p.id}>
                    {p.title}
                  </ListGroup.Item>
                );
              })}
            </ListGroup>
          </div>

          <div className="tr-w-7/12">
            <Tab.Content>
              <Nav.Item className="mr-1">
                {listPolicies.map((p) => {
                  return (
                    <Tab.Pane key={p.id} eventKey={p.id} unmountOnExit={true}>
                      {p.arn && (
                        <>
                          <span>Policy ARN:</span>
                          <Badge variant="secondary">{p.arn}</Badge>
                        </>
                      )}
                      {p.document && <Highlight language="yaml">{decodeURIComponent(p.document)}</Highlight>}
                    </Tab.Pane>
                  );
                })}
              </Nav.Item>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </>
  );
});

export default ListTabPolicies;
