import _ from 'lodash';
import moment from 'moment';
import {Org} from 'src/stores/domainObjects/Org';
import {DurationUnitT, MinSensitivitySettingT} from 'src/types';
import {object} from 'yup';

export const translateToUnit = (
  maxDuration: {durationUnit: DurationUnitT; durationValue: number},
  toUnit: DurationUnitT = 'hour'
) => {
  const maxUnitInput = `${maxDuration.durationUnit}s` as moment.unitOfTime.DurationConstructor | undefined;
  const asMoment = moment.duration(maxDuration.durationValue, maxUnitInput);

  switch (toUnit) {
    case 'hour': {
      return asMoment.asHours();
    }
    case 'day': {
      return asMoment.asDays();
    }
    case 'month': {
      return asMoment.asMonths();
    }
  }
};

export function GetSensitivityDurationValidations(org: Org) {
  const sensitivitySettings = org.sensitivitySettingsSorted;
  return {
    accessDuration: object().test(
      'access duration allowed by sensitivity',
      ({durationUnit: unit, durationValue: value}, {parent, createError}) => {
        const sensitivity = _.find(sensitivitySettings, {id: parent.sensitivityId})!;
        const {maxAccessDurationUnit: maxUnit, maxAccessDurationValue: maxValue} = sensitivity;

        const maxAllowedDurationInHours = (maxUnit === 'month' ? 720 : maxUnit === 'day' ? 24 : 1) * maxValue;
        const durationInHours = (unit === 'month' ? 720 : unit === 'day' ? 24 : 1) * (value ?? 0);

        if (durationInHours <= 0) {
          return createError({message: `Duration value must be greater than 0`});
        }
        if (durationInHours > maxAllowedDurationInHours) {
          return createError({
            message: `Max duration: ${maxValue} ${maxUnit}(s). Limited by the sensitivity definition at organization level.`,
          });
        }

        const {durationValue = 0, durationUnit} = parent.approvalDuration;
        if (durationValue === 0) {
          return createError({message: 'This value depends on selecting a valid approval duration'});
        }

        if (durationInHours > moment.duration(durationValue, durationUnit).asHours()) {
          return createError({
            message: `This duration can't be greater than the approval duration: ${durationValue} ${durationUnit}(s).`,
          });
        }
        return true;
      }
    ),
    approvalDuration: object().test(
      'approval duration allowed by sensitivity',
      ({durationUnit: unit, durationValue: value}, {parent, createError}) => {
        const sensitivity: MinSensitivitySettingT = _.find(sensitivitySettings, {id: parent.sensitivityId})!;
        const {maxApprovalDurationUnit: maxUnit, maxApprovalDurationValue: maxValue} = sensitivity;
        const maxAllowedDurationInHours = (maxUnit === 'month' ? 720 : maxUnit === 'day' ? 24 : 1) * maxValue;
        const durationInHours = (unit === 'month' ? 720 : unit === 'day' ? 24 : 1) * (value ?? 0);

        if (durationInHours <= 0) {
          return createError({message: `Duration value must be greater than 0`});
        }
        if (durationInHours > maxAllowedDurationInHours) {
          return createError({
            message: `Max duration: ${maxValue} ${maxUnit}(s). Limited by the sensitivity definition at organization level.`,
          });
        }
        return true;
      }
    ),
  };
}
