import React, {useEffect, useState} from 'react';
import './ResourceAccounts.scss';
import _ from 'lodash';
import {fuseSearch} from 'src/lib';
import AddAccountButton from './AddAccountButton';
import {observer} from 'mobx-react';
import {generatePath, useHistory, useParams} from 'react-router-dom';
import {Resource} from 'src/stores/domainObjects/Resource';
import {useQueryStrings, useRootStore} from 'src/lib/hooks';
import {Account} from 'src/stores/domainObjects/Account';
import {SearchAndReportToggle} from 'src/views/User/Profile/SearchAndReportToggle';
import UnlinkedAccounts from './UnlinkedAccounts';
import LinkedAccounts from './LinkedAccounts';
import OffboardedAccounts from './OffboardedAccounts';
import FlaggedAccounts from './FlaggedAccounts';
import {Button, Tab} from '@trustle/component-library';
import {UploadModal} from 'src/components/UniversalDataUpload/UploadModal';
import {CSVContent} from 'src/components/UniversalDataUpload/helper';
import {ConnectionServiceE} from 'src/types';

export enum FilterMatchTypeE {
  PerfectMatch = 'perfectMatch',
  PartialMatch = 'partialMatch',
  NoMatch = 'noMatch',
}

export enum AccountTabTypeE {
  ASSIGNED = 'linked',
  UNASSIGNED = 'unlinked',
  FLAGGED = 'flagged',
  OFFBOARDED = 'offboarded',
}

export const EmptyAccountsTab = ({tab}: {tab: AccountTabTypeE}) => {
  return (
    <div className="text-center mt-4 pt-4 tr-w-full">
      <img src="/russel-thinking.svg" className="m-2" style={{width: '190px'}} alt="" />
      <div className="body5 mt-3">Hmm, we couldn't seem to find any {_.upperFirst(tab)} accounts.</div>
      <div className="body5 text-muted mt-2" style={{width: '250px', display: 'inline-grid'}}>
        Try clicking on one of the other tabs to see more accounts
      </div>
    </div>
  );
};

type PropsT = {resource: Resource; refresh: () => void};

const ResourceAccounts = observer(({resource, refresh}: PropsT) => {
  const {accountsStore} = useRootStore();
  const history = useHistory();

  useEffect(() => {
    const refreshCounts = async () => await accountsStore.loadAccessesCount(resource.id);
    void refreshCounts();
    void resource.refreshResourceAccounts();
  }, []);

  const {tab} = useParams<{tab: AccountTabTypeE}>();

  // redirect links without tab defined to /linked
  if (tab === undefined) {
    history.push({
      ...history.location,
      pathname: generatePath('/resource/manage/:rid/accounts/:tab', {rid: resource.id, tab: AccountTabTypeE.ASSIGNED}),
    });
  }

  const [showModal, setShowModal] = useState<boolean>(false);

  // state for searching and filtering
  const [query, setQuery] = useState('');
  const [filters, setFilters] = useQueryStrings({});

  const filteredAccounts = _.isEmpty(filters)
    ? resource.accounts.filter((a) => !a.refId)
    : resource.accounts.filter(
        (account) =>
          (_.isNil(filters.User) ||
            (account.forUser ? filters.User === account.forUser?.email : filters.User === 'unlinked')) &&
          (_.isNil(filters['Account Name']) || filters['Account Name'] === account?.account) &&
          (_.isNil(filters.Status) || filters.Status === account.status) &&
          (_.isNil(filters['Account Type']) || filters['Account Type'] === account?.accountType) &&
          (_.isNil(filters['Trustle User Type']) ||
            [undefined, ...filters['Trustle User Type'].split(',')].includes((account as Account).forUser?.type))
      );

  const searchedAccounts = fuseSearch(
    query,
    ['forUser.email', 'createdByUser.email', 'account', 'accountType', 'editedByUser.email'],
    filteredAccounts
  );

  const tabs = Object.values(AccountTabTypeE);
  const accounts = [
    searchedAccounts.filter(
      (a) => a.isLinked && !a.isOffboarded && !a.placeholder && !a.isUserOffboarded && !a.tombstone
    ),
    searchedAccounts.filter((a) => !a.isLinked && !a.isOffboarded && !a.placeholder && !a.tombstone),
    searchedAccounts.filter((a) => a.flaggedForReview && !a.placeholder),
    searchedAccounts.filter((a) => (a.isOffboarded || a.isUserOffboarded) && !a.placeholder),
  ];

  const selectedIndex = Math.max(tabs.indexOf(tab), 0);

  return (
    <div className="resource-accounts-container container tr-flex tr-flex-col">
      {showModal && (
        <UploadModal
          csvContent={CSVContent.ACCOUNT}
          resource={resource}
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
      <div className="tr-flex">
        {!resource.connectionId && (
          <div className="tr-row tr-m-4">
            <AddAccountButton resource={resource} refresh={refresh} />
          </div>
        )}
      </div>
      <div className="tr-flex">
        <div className="tr-w-full tr-mt-4 tr-mb-3 accounts-tab">
          <SearchAndReportToggle
            reportUrl={`/api/accounts/report`}
            queryParams={new URLSearchParams({rid: resource.id, status: tab})}
            setQuery={setQuery}
            setFilters={setFilters}
            filterOptions={{
              User: [..._.map(filteredAccounts, 'forUser.email'), 'unlinked'],
              'Account Name': _.map(filteredAccounts, 'account'),
              Status: _.map(filteredAccounts, 'status'),
              'Account Type': _.map(filteredAccounts, 'accountType'),
              'Trustle User Type': _.map(filteredAccounts, 'forUser.type'),
            }}
          />
          <div className="tr-my-4 tr-flex align-items-center tr-justify-end tr-w-full">
            {resource.type === ConnectionServiceE.GENERIC && (
              <Button onClick={() => setShowModal(true)}>Upload Accounts</Button>
            )}
          </div>

          <Tab.Group
            selectedIndex={selectedIndex}
            onChange={(i: number) => history.push(`/resource/manage/${resource.id}/accounts/${tabs[i]}`)}
          >
            <Tab.List className="border-bottom">
              {tabs.map((key, i) => (
                <Tab key={key}>
                  <span>{_.upperFirst(key)}</span> {accounts[i].length}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel key={AccountTabTypeE.ASSIGNED}>
                {tab === AccountTabTypeE.ASSIGNED && <LinkedAccounts accounts={accounts[0]} system={resource} />}
              </Tab.Panel>
              <Tab.Panel key={AccountTabTypeE.UNASSIGNED}>
                {tab === AccountTabTypeE.UNASSIGNED && <UnlinkedAccounts accounts={accounts[1]} system={resource} />}
              </Tab.Panel>
              <Tab.Panel key={AccountTabTypeE.FLAGGED}>
                {tab === AccountTabTypeE.FLAGGED && <FlaggedAccounts accounts={accounts[2]} system={resource} />}
              </Tab.Panel>
              <Tab.Panel key={AccountTabTypeE.OFFBOARDED}>
                {tab === AccountTabTypeE.OFFBOARDED && <OffboardedAccounts accounts={accounts[3]} system={resource} />}
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </div>
  );
});

export enum SyncAction {
  NEW = 'new',
  UPDATES = 'updates',
  DUPLICATES = 'duplicates',
  INVALID = 'invalid',
}

export default ResourceAccounts;
