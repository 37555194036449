import React from 'react';
import {Button, Modal} from '@trustle/component-library';
import {useRootStore} from '../../../../../../../../lib/hooks';
import {formatUserName} from '../../../../../../../../lib';
import axios from 'axios';
import {UserActionModalProps} from '../types';

export function UserOffboardModal({user, handleCloseFn, onConfirm}: UserActionModalProps) {
  const {toast} = useRootStore();
  const {id} = user;

  const handleConfirm = async () => {
    try {
      await axios.post(`/api/users/admin/${id}/offboard`);
      toast.success('User offboarded successfully');
    } catch (err: any) {
      toast.error("User couldn't be offboarded");
    } finally {
      onConfirm?.();
    }
  };

  return (
    <Modal onClose={handleCloseFn} title={'Confirm action'}>
      <div>
        Please, confirm that you want to offboard the user: <strong>{formatUserName(user)}</strong>
        {/*TODO: See how we handle this*/}
        {/*{_.size(modalUser?.permissionsPreventOffboarding) > 0 && (*/}
        {/*  <Alert title="Overriden deprovision settings" colorVariant="warning">*/}
        {/*    <div className="my-2">*/}
        {/*      We are unable to offboard this user because this user's permissions (*/}
        {/*      {_.size(modalUser?.permissionsPreventOffboarding)}):{' '}*/}
        {/*      <div className="tr-overflow-auto tr-my-2">*/}
        {/*        <ul className="tr-h-[70px]">*/}
        {/*          {modalUser &&*/}
        {/*            _.map(modalUser?.permissionsPreventOffboarding, (perm) => (*/}
        {/*              <li>*/}
        {/*                <Breadcrumb permission={perm} showLink={true} />*/}
        {/*              </li>*/}
        {/*            ))}*/}
        {/*        </ul>*/}
        {/*      </div>{' '}*/}
        {/*      are set to <strong>deprovisioning off</strong>. To <strong>override</strong> these settings,{' '}*/}
        {/*      <strong>click Confirm</strong> to continue offboarding this user. Otherwise, click Cancel, and contact*/}
        {/*      your systems administrator to manually remove the forementioned permissions from the user, and try again.*/}
        {/*    </div>*/}
        {/*  </Alert>*/}
        {/*)}*/}
        <div className="mt-3">
          <small>
            <strong>Keep in mind:</strong>
          </small>
          <ul>
            <li>
              <small>
                If a user that you offboard is the unique <strong>owner</strong> of a resource, then the organizations
                owners are automatically assigned as new owners of the resource.
              </small>
            </li>
            <li>
              <small>
                If a user that you offboard is the unique <strong>provisioner</strong> of a resource, then the owners of
                the resource are automatically assigned as new provisioners of the resource.
              </small>
            </li>
          </ul>
        </div>
        <div className="tr-flex tr-justify-end tr-mt-4">
          <Button onClick={() => handleCloseFn()} variant="secondary">
            Cancel
          </Button>
          <Button onClick={() => handleConfirm()}>Confirm</Button>
        </div>
      </div>
    </Modal>
  );
}
