import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {useRootStore} from 'src/lib/hooks';
import {OnCallList} from 'src/stores/domainObjects/OnCallList';
import OnCallModal from './OnCallModal';
import _ from 'lodash';
import {Loading, Button, ColumnDescription, Confirmation, Icon, IconButton, Table} from '@trustle/component-library';

enum OnCallModalE {
  DELETE_LIST = 'delete_list',
  EDIT_LIST = 'edit_list',
}

const OnCallSetup = observer(function OnCallCmp() {
  const [modalAction, setModalAction] = useState<OnCallModalE>();
  const [target, setTarget] = useState<OnCallList>();
  const [createOnCall, setCreateOnCall] = useState(false);
  const {org, usersStore} = useRootStore();

  if (usersStore.loading || _.isNil(org) || org.onCallLists === undefined || org.pageablePermissions === undefined) {
    return <Loading />;
  }

  const pageablePermissionOptions = _.map(org.pageablePermissions, (pdService) => {
    return {value: pdService.permissionId, label: `${pdService.systemName} > Services > ${pdService.permissionName}`};
  });

  const columns: ColumnDescription[] = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Access',
    },
    {
      dataField: 'createdAt',
      hidden: true,
      text: 'createdAt',
    },
    {
      dataField: 'name',
      sort: true,
      classes: 'ellipsis-overflow',
      text: 'Name',
      headerStyle: {width: '40%', textAlign: 'left', paddingLeft: '100px'},
      style: {width: '40%', textAlign: 'left', paddingLeft: '100px'},
      formatter: (cell: any) => {
        return <div className="tr-text-trustle-navy tr-font-bold">{cell}</div>;
      },
    },
    {
      dataField: 'permissionId',
      sort: true,
      classes: 'ellipsis-overflow',
      text: 'Type',
      headerStyle: {width: '30%', textAlign: 'left'},
      style: {width: '30%', textAlign: 'left'},
      formatter: (cell: any, row: any) => {
        if (!row.permissionId) {
          return 'Manual';
        }
        return <img src="/connectors/pagerduty-logo.svg" width="25px" alt="PagerDuty" />;
      },
    },
    {
      dataField: 'userData',
      sort: true,
      classes: 'ellipsis-overflow',
      text: 'Users',
      headerStyle: {width: '25%', textAlign: 'left'},
      style: {width: '25%', textAlign: 'left'},
      formatter: (cell: any, row: any) => {
        if (!row.permissionId) {
          return <div>{_.isEmpty(cell) ? <Icon type="emptyData" title="No Users" size="sm" /> : _.size(cell)}</div>;
        }
        return 'N/A';
      },
    },
    {
      dataField: 'actions',
      sort: true,
      text: 'Actions',
      classes: 'show-on-hover',
      headerStyle: {width: '90px'},
      style: {width: '90px'},
      formatter: (_cell: any, row: OnCallList) => {
        return <div>{getActions(row)}</div>;
      },
    },
  ];

  function getActions(list: OnCallList) {
    const items = [];

    items.push(
      <IconButton
        icon="edit"
        variant="ternary"
        key={'edit'}
        title="Edit"
        data-testid="edit-icon"
        onClick={(e: any) => {
          e.stopPropagation();
          setTarget(list);
          setModalAction(OnCallModalE.EDIT_LIST);
        }}
      />
    );

    items.push(
      <IconButton
        icon="remove"
        variant="ternary"
        key={'delete'}
        data-testid="delete-icon"
        title="Delete"
        onClick={(e: any) => {
          e.stopPropagation();
          setTarget(list);
          setModalAction(OnCallModalE.DELETE_LIST);
        }}
      />
    );

    return <div className="tr-flex justify-content-center  perm-actions-wrapper ">{items}</div>;
  }

  const resourceModalActionContent = ((): React.ReactNode => {
    if (!modalAction || !target) {
      return null;
    }

    switch (modalAction) {
      case OnCallModalE.DELETE_LIST: {
        return (
          <Confirmation
            onConfirm={async () => {
              await org.deleteOnCallList(target);
              setModalAction(undefined);
            }}
            onClose={() => setModalAction(undefined)}
            title={`Delete On-Call List`}
            confirmButtonLabel={'Delete List'}
          >
            <>
              <div className="body5">Are you sure you want to delete this list? </div>
              <div className="pt-2">This will remove every permission set related to this list as well.</div>
            </>
          </Confirmation>
        );
      }
      case OnCallModalE.EDIT_LIST: {
        return (
          <OnCallModal
            closeModal={() => setModalAction(undefined)}
            defaultValues={target}
            pageablePermissionOptions={pageablePermissionOptions}
          />
        );
      }
    }
  })();

  return (
    <>
      <div className="bottom-border-gray mt-3">
        <h3>On-Call User Lists</h3>
        <div className="body5-normal">Lists of users who can bypass approvals during on-call events.</div>
      </div>
      <div className="bottom-border-gray">
        <Button variant="ternary" onClick={() => setCreateOnCall(true)}>
          + Add New List{' '}
        </Button>
      </div>
      <div>
        <Table
          defaultSorted={[{dataField: 'createdAt', order: 'asc'}]}
          data={org.onCallLists}
          columns={columns}
          tableKey={'oncallsetup'}
          striped={false}
          bordered={false}
        />
      </div>
      {resourceModalActionContent}
      {createOnCall && (
        <OnCallModal pageablePermissionOptions={pageablePermissionOptions} closeModal={() => setCreateOnCall(false)} />
      )}
    </>
  );
});

export default OnCallSetup;
