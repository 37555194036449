import React from 'react';

import {Icon} from '@trustle/component-library';
import {User} from 'src/stores/domainObjects/User';

import {observer} from 'mobx-react';
import {useRootStore} from 'src/lib/hooks';
import _ from 'lodash';

const LinkedStatus = observer(function LinkedStatusFn({user}: {user: User}) {
  const {org} = useRootStore();
  return (
    <span data-testid={`statusLink${user.email}`} className="tr-text-trustle-link">
      {_.isNil(org.accountsDictionary[user.id]) && (
        <Icon title="User is not linked to any System" type="unlinkedUser" size="sm" />
      )}
    </span>
  );
});

export default LinkedStatus;
