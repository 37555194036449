import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {ColumnDescription} from '@trustle/component-library';
import {createdDaysAgo} from 'src/lib';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import RiskBadge from 'src/views/Resource/Account/RiskBadge';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Link} from 'react-router-dom';
import {LastActivityColumn as AccountLastActivityCol} from './AccountColumns';
import {AccessStatusIcon, Icon} from '@trustle/component-library';

/** Shared Columns for use in AccessRecord Tables */

export const StatusColumn: ColumnDescription<AccessRecord> = {
  dataField: '_status',
  text: '',
  formatter: (_status, access) => (
    <AccessStatusIcon size="sm" title={access.status} status={access.statusValue} className="m-auto" />
  ),
  headerStyle: {width: '4em'},
  style: {width: '4em'},
};

export const ResourceNameColumn: ColumnDescription<AccessRecord> = {
  dataField: 'forResource',
  text: 'Resource',
  align: 'left',
  headerAlign: 'left',
  headerStyle: {width: '15%'},
  style: {width: '15%'},
  formatter: (resource: Resource, _access: AccessRecord) => {
    if (_.isNil(resource)) {
      return <></>;
    }
    return <>{resource.name}</>;
  },
};

export const SystemNameCol: ColumnDescription<AccessRecord> = {
  dataField: 'forSystem',
  text: 'System',
  formatter: (system: Resource) => (
    <Link to={`/access/available/${system?.type}/${system?.id}`} className="d-inline-flex align-items-center">
      <div className="py-2 pr-2">{system?.iconImage}</div>
      <div>{system?.name}</div>
    </Link>
  ),
  align: 'left',
  headerAlign: 'left',
  headerStyle: {width: '20%'},
  style: {width: '20%'},
  sort: true,
  sortValue: (system: Resource) => system.name,
};

export const PermissionNameColumn: ColumnDescription<AccessRecord> = {
  dataField: 'forPermission.label',
  text: 'Permission',
  align: 'left',
  headerAlign: 'left',
  headerStyle: {width: '20%'},
  style: {width: '20%'},
};

export const MsgCol: ColumnDescription<AccessRecord> = {
  dataField: 'message',
  text: 'Message',
  align: 'left',
  headerAlign: 'left',
  sort: true,
};

export const RequestSentOnCol: ColumnDescription<AccessRecord> = {
  dataField: 'created',
  text: 'Date',
  formatter: createdDaysAgo,
  align: 'left',
  headerAlign: 'left',
  sort: true,
};

export const LastActivityColumn: ColumnDescription<AccessRecord> = {
  ...(AccountLastActivityCol as ColumnDescription<any>),
  dataField: 'forAccount.lastAccountActivity',
  formatter: (_unused, access, i) => AccountLastActivityCol.formatter?.(_unused, access.forAccount!, i),
};

const ObservableRisk = observer(({access}: {access: AccessRecord}) => (
  <RiskBadge value={access.forPermission.riskScore} />
));

export const AccessRiskScoreColumn: ColumnDescription<AccessRecord> = {
  dataField: '_riskScore',
  text: 'Risk score',
  align: 'left',
  headerAlign: 'left',
  formatter: (_unused, access: AccessRecord) => <ObservableRisk access={access} />,
};

export const AccessActionsColumn = ({
  showDetails,
  deprovision,
  revoke,
}: {
  showDetails?: (access: AccessRecord) => void;
  deprovision?: (access: AccessRecord) => void;
  revoke?: (access: AccessRecord) => void;
}): ColumnDescription<AccessRecord> => ({
  dataField: '_actions',
  text: 'actions',
  headerStyle: {width: '7rem'},
  style: {width: '7rem'},
  classes: 'opacity-0 hover:opacity-100',
  formatter: (_unused, access: AccessRecord) => (
    <div className="">
      {!_.isNil(showDetails) && <Icon type="details" onClick={() => showDetails(access)} />}
      {access.isDeprovisionable && !_.isNil(deprovision) && (
        <Icon type="deprovision" size="sm" onClick={() => deprovision(access)} />
      )}
      {access.isRevokable && !_.isNil(revoke) && <Icon type="revoke" size="sm" onClick={() => revoke?.(access)} />}
    </div>
  ),
});
