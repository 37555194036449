import React from 'react';
import {PolicyTypeAWS, ConnectorsDataT, AWSData} from '../../../types';
import {Card} from 'react-bootstrap';
import ViewAwsPolicy from './ViewAwsPolicy';
import AwsPolicyRisk from './AwsPolicyRisk';
import GroupServiceUsage from '../../ConnectorServiceUsage/GroupServiceUsage';
import ManagedPolicyServiceUsage from '../../ConnectorServiceUsage/ManagedPolicyServiceUsage';
import _ from 'lodash';
import {Resource} from 'src/stores/domainObjects/Resource';
import ListTabPolicies from './ListTabPolicies';
import {PermTabModeE} from 'src/views/Resource/Setup/PermissionDetailsModal';
import {Permission} from 'src/stores/domainObjects/Permission';
import NoData from 'src/components/NoData';
import {Accordion, AccordionProvider} from '@trustle/component-library';

type PropsT = {
  connectorsData: any;
  isRole: boolean;
  isManagedPolicy: boolean;
  isInlinePolicy: boolean;
  permission: Permission;
  system: Resource;
};

const ViewAwsPolicies = function ViewAwsPolicies(props: PropsT): _.Dictionary<JSX.Element> | null {
  const {connectorsData, isRole, isManagedPolicy, isInlinePolicy, permission, system} = props;
  const permissionId = permission.id;
  if (connectorsData === undefined) {
    return null;
  }

  const isManageOrInlinePolicy = isManagedPolicy || isInlinePolicy;
  if (_.isEmpty(connectorsData)) {
    return null;
  }
  if (isManageOrInlinePolicy) {
    let options = {
      [PermTabModeE.POLICY]: <ViewAwsPolicy connectorsData={connectorsData} permissionId={permissionId} />,
      [PermTabModeE.RISK_REPORT]: <AwsPolicyRisk permissionId={permissionId}></AwsPolicyRisk>,
    };

    if (isManagedPolicy) {
      options = {
        ...options,
        ...{[PermTabModeE.SERVICE_USAGE]: <ManagedPolicyServiceUsage permission={permission} system={system} />},
      };
    }
    return options;
  }

  const groupPolicyList = _.filter(connectorsData, (d) => {
    return d.data.PolicyType === PolicyTypeAWS.GroupPolicyList;
  });
  const attachedManagedPolicies = _.filter(connectorsData, (d) => {
    return d.data.PolicyType === PolicyTypeAWS.AttachedManagedPolicies;
  });
  const attachedManagedPoliciesLength = attachedManagedPolicies.length;
  const groupPolicyListLength = groupPolicyList.length;

  const showAWSPolicies = (): React.ReactElement => {
    if (attachedManagedPoliciesLength === 0 && groupPolicyListLength === 0) {
      return <NoData>Hmm, we couldn’t seem to find any policy data yet.</NoData>;
    }

    const listPolicies = (listPolicies: ConnectorsDataT[]) => {
      if (_.isEmpty(listPolicies)) {
        return <span>There are no policies to show</span>;
      }

      const formattedListPolicies = _.map(listPolicies, (e) => {
        return {
          id: e.id,
          title: e.title,
          arn: (e.data as AWSData).PolicyArn,
          document: (e.data as AWSData).PolicyDocument,
        };
      });

      return <ListTabPolicies listPolicies={formattedListPolicies} />;
    };

    return (
      <AccordionProvider>
        <Accordion
          id={'1'}
          contextAware
          content={
            <span className="tr-mr-3">
              <strong>Managed Policies ({attachedManagedPoliciesLength})</strong>
            </span>
          }
          isTreeView
        >
          {() => {
            return <Card.Body>{listPolicies(attachedManagedPolicies)}</Card.Body>;
          }}
        </Accordion>
        <Accordion
          id={'12'}
          contextAware
          content={
            <span className="tr-mr-3">
              <strong>Inline Policies ({groupPolicyListLength})</strong>
            </span>
          }
          isTreeView
        >
          {() => {
            return <>{listPolicies(groupPolicyList)}</>;
          }}
        </Accordion>
      </AccordionProvider>
    );
  };

  let tabs = {
    [PermTabModeE.RISK_REPORT]: <AwsPolicyRisk permissionId={permissionId}></AwsPolicyRisk>,
    [PermTabModeE.SERVICE_USAGE]: <GroupServiceUsage permission={permission} system={system} />,
  };

  if (!isRole) {
    tabs = {...{[PermTabModeE.POLICY]: showAWSPolicies()}, ...tabs};
  }

  return tabs;
};

export default ViewAwsPolicies;
