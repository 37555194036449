import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {Menu, MenuItem, Token, Typeahead, TypeaheadProps} from 'react-bootstrap-typeahead';
import {useQueryStrings} from 'src/lib/hooks';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './Search.scss';
import {Button, Icon} from '@trustle/component-library';

type SearchPropsT = Omit<
  TypeaheadProps<string>,
  | 'open'
  | 'onInputChange'
  | 'onChange'
  | 'selected'
  | 'renderToken'
  | 'options'
  | 'renderMenu'
  | 'paginate'
  | 'multiple'
> & {
  filterOptions?: Record<string, (string | undefined)[]>;
  onChange: (query: string, filters: Record<string, string>) => void;
  initialFilters?: Record<string, string>;
  initialQuery?: string;
  editURL?: boolean;
  onClose?: () => void;
  showCloseBtn?: boolean;
  showCustomIcon?: boolean;
  handleCustomIcon?: () => void;
  testidCustomIcon?: string;
};

/**
 * A searchbar with filters that you can deeplink to
 * @example
 * ```
 * <Search
 *     placeholder="Search for User or Status"
 *     filterOptions={{User: _.map(items, 'user'), Status: _.map(items, 'status')}
 *     onChange={(query, filters) => {
 *       setQuery(query);
 *       setFilters(filters);
 *     }}
 * />
 * ```
 */
export function Search({
  filterOptions = {},
  onChange,
  initialFilters = {},
  initialQuery = '',
  editURL = true,
  // ▼ default Typahead props ▼
  highlightOnlyResult = true,
  showCloseBtn = false,
  showCustomIcon = false,
  handleCustomIcon = () => {},
  testidCustomIcon,
  onClose = () => {},
  ...rest
}: SearchPropsT) {
  const [query, setQuery] = useState<string>(initialQuery);
  const [filters, setFilters] = editURL ? useQueryStrings(initialFilters) : useState(initialFilters);
  const [selectedProperty, setSelectedProperty] = useState<string>();

  // remove any duplicate or undefined property options
  const options: Record<string, string[]> = _.mapValues(filterOptions, (opts) => _(opts).compact().uniq().value());

  // update filter properties to defined options if case is similar
  const fixedFilters: Record<string, string> = {};
  _.entries(filters).forEach(([k, v]) => {
    if (_.includes(_.keys(_.map(options, 'lowercase')), _.lowerCase(k))) {
      // key case insensitive
      const correctedKey = _.keys(options).find((key) => _.lowerCase(key) === _.lowerCase(k));
      if (correctedKey) {
        fixedFilters[correctedKey] = v;
      }
    } else {
      // keep extra parameters
      fixedFilters[k] = v;
    }
  });

  if (!_.isEqual(filters, fixedFilters)) {
    setFilters(fixedFilters);
  }

  // top-level properties, filter out those already set and those without values defined
  const properties: string[] = _.keys(options).filter(
    (key) => !_.keys(filters).includes(key) && options[key]?.length > 1
  );

  useEffect(() => {
    onChange(query, filters);
  }, [query, filters]);

  return (
    <>
      {showCloseBtn && (
        <div className="trus-search text-right pointer text-sm text-bold" onClick={() => onClose()}>
          X close
        </div>
      )}

      <Typeahead
        {...rest}
        id="trus-search"
        className={`trus-search ${rest.className ?? ''}`}
        paginate={false}
        multiple
        highlightOnlyResult={highlightOnlyResult}
        open={_.isNil(selectedProperty) ? undefined : true}
        onInputChange={(val) => setQuery(val)}
        onChange={(selected: string[]) => {
          const newFilters: Record<string, string> = {};
          selected.forEach((value) => {
            if (value.includes(':')) {
              const [key, val] = value.split(':');
              newFilters[key] = val.substring(1);
            } else if (_.isNil(selectedProperty)) {
              setSelectedProperty(value);
            } else {
              newFilters[selectedProperty] = value;
              setSelectedProperty(undefined);
            }
          });
          if (!_.isEqual(filters, newFilters)) {
            setQuery('');
            setFilters(newFilters);
          }
          if (_.isEmpty(selected)) {
            setSelectedProperty(undefined);
          }
        }}
        selected={[
          ..._.entries(_.pick(filters, _.keys(options))).map(([key, val]) => `${key}: ${val}`),
          ...(_.isNil(selectedProperty) ? [] : [`${selectedProperty}:`]),
        ]}
        renderToken={(option, {onRemove}, idx) => (
          <Token key={idx} onRemove={onRemove} option={option} readOnly={!_.isNil(selectedProperty)}>
            {option}
          </Token>
        )}
        options={_.isNil(selectedProperty) ? properties : options[selectedProperty]}
        renderMenu={(results, menuProps) => (
          <>
            {_.isEmpty(results) || (
              <Menu {...menuProps}>
                <div className="p-2">{_.isNil(selectedProperty) ? `Properties` : `${selectedProperty}:`}</div>
                {results.map((result, index) => (
                  <MenuItem option={result} position={index} key={index}>
                    {result}
                  </MenuItem>
                ))}
              </Menu>
            )}
          </>
        )}
      >
        {({onClear, selected}) => (
          <div className={`rbt-aux tr-pointer-events-auto tr-flex ${showCustomIcon && 'tr-justify-end'}`}>
            {!!selected.length && (
              <>
                <Button aria-label="Clear" className="close rbt-close" onClick={onClear}>
                  <span aria-hidden="true">×</span>
                  <span className="sr-only visually-hidden">Clear</span>
                </Button>
              </>
            )}
            {showCustomIcon && (
              <div className="cursor tr-z-10">
                <Button
                  variant="ternary"
                  name="name"
                  onClick={handleCustomIcon}
                  size="sm"
                  data-testid={testidCustomIcon}
                >
                  <Icon type="filter" className="tr-px-0" />
                </Button>
              </div>
            )}
          </div>
        )}
      </Typeahead>
    </>
  );
}
