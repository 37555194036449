import {Button, DurationInput, Modal, Table, TextInput} from '@trustle/component-library';
import {Field} from 'formik';
import _ from 'lodash';
import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {SubmitButton} from 'src/components/design';
import ResourceMiniCard from 'src/components/design/card/resourceCard/ResourceMiniCard';
import {Formik, FormikForm} from 'src/components/design/formik';
import MainSearchbar from 'src/components/MainSearchbar';
import {fuseSearch} from 'src/lib';
import {Permission} from 'src/stores/domainObjects/Permission';
import {PermissionSet} from 'src/stores/domainObjects/PermissionSet';
import {Resource} from 'src/stores/domainObjects/Resource';
import {DurationUnitT, PermissionT} from 'src/types';

type OnCallModalPropsT = {
  closeModal: any;
  defaultValues?: PermissionSet;
  system: Resource;
};

const OnCallModal = observer(function OnCallPermissionModal(props: OnCallModalPropsT) {
  const {closeModal, defaultValues, system} = props;
  const [namingStep, setNamingStep] = useState<boolean>(false);
  const [selectedPermissions, setSelectedPermissions] = useState<string[]>(
    defaultValues ? defaultValues.permissions ?? [] : []
  );
  const [query, setQuery] = useState('');

  const permissions = system.allNestedPermissions;
  const filteredPermissions: Permission[] = fuseSearch(query, ['label', 'description'], permissions);

  const selectRow = {
    mode: 'checkbox' as const,
    style: {background: 'rgba(0,81,155,0.1)'},
    selected: selectedPermissions,
    onSelect: (row: PermissionT, isSelect: boolean) => {
      toggleMultiSelection(row, isSelect);
    },
    onSelectAll: (isSelect: boolean, rows: PermissionT[]) => {
      const rowsToUpdate = isSelect ? _.map(rows, (r) => r.id) : [];
      setSelectedPermissions(rowsToUpdate);
    },
  };

  function toggleMultiSelection(permission: PermissionT, checked: boolean) {
    const newSelectedPermissions = [...selectedPermissions];
    if (checked) {
      newSelectedPermissions.push(permission.id);
      setSelectedPermissions(newSelectedPermissions);
    } else {
      const newSelectedPermissions = _.reject(selectedPermissions, (p: string) => p === permission.id);
      setSelectedPermissions(newSelectedPermissions);
    }
  }

  const columns = [
    {
      dataField: 'id',
      hidden: true,
      text: 'Access',
    },
    {
      dataField: 'icon',
      text: '',
      headerStyle: {width: '50px'},
      style: {width: '50px'},
      formatter: (_cell: any, permission: Permission) => {
        return (
          <ResourceMiniCard resource={permission.parentResource} key={permission.parentResource.id} isManaging={true} />
        );
      },
    },
    {
      dataField: 'resource',
      sort: true,
      text: 'Resource',
      classes: 'ellipsis-overflow',
      formatter: (_cell: any, permission: Permission) => {
        return <div>{permission.parentResource?.name}</div>;
      },
    },
    {
      dataField: 'label',
      sort: true,
      text: 'Permission',
      classes: 'ellipsis-overflow',
      formatter: (cell: any) => {
        return <div>{cell}</div>;
      },
    },
  ];
  const id = defaultValues?.id;
  const isEdit = !!id;

  return (
    <Modal
      onClose={closeModal}
      visible={true}
      title={
        isEdit
          ? 'Edit On-Call Permission Set'
          : namingStep
          ? `What would you call this On-Call Permission Set? `
          : `Add On-Call Permission Set`
      }
    >
      {!namingStep && (
        <div className="tr-flex tr-flex-col tr-pr-3 tr-py-2">
          <div>{'Select Permission(s) to add to this On-Call Permission Set'}</div>
        </div>
      )}
      <Formik
        initialValues={{
          name: defaultValues?.name ?? '',
          duration: {
            durationValue: defaultValues?.accessDurationValue ?? 6,
            durationUnit: defaultValues?.accessDurationUnit ?? 'hour',
          },
          permissions: selectedPermissions ?? [],
        }}
        validate={(values: any) => {
          const errors: _.Dictionary<string> = {};
          if (values.name.length > 200) {
            errors.name = 'Name must not exceed the limit of 200 characters.';
          }

          const foundOncall = _.find(system.permissionSets, (os) => os.name === values.name);
          let uniqueConditionFailed = !_.isEmpty(foundOncall);
          if (isEdit) {
            uniqueConditionFailed = Boolean(foundOncall && id !== foundOncall.id);
          }
          if (uniqueConditionFailed) {
            errors.name = 'The Oncall set name already exists for this system and must be unique.';
          }
          return errors;
        }}
        onSubmit={async (
          values: Partial<PermissionSet> & {duration: {durationUnit: DurationUnitT | undefined; durationValue: number}}
        ) => {
          const permissionSet = {
            name: values.name,
            accessDurationValue: values.duration.durationValue,
            accessDurationUnit: values.duration.durationUnit,
            oncallListId: undefined,
          };
          if (isEdit && defaultValues) {
            defaultValues.update({...permissionSet, permissions: selectedPermissions});
          } else {
            await system.createPermissionSet(permissionSet, selectedPermissions);
            void system.getPermissionSets();
          }

          closeModal();
        }}
      >
        {({isSubmitting}) => (
          <FormikForm>
            <div className="tr-flex tr-flex-col">
              <div className="">
                {!namingStep && (
                  <>
                    <MainSearchbar setQuery={setQuery} placeholder="Type to search available permissions" />
                    <Table
                      data={filteredPermissions}
                      columns={columns}
                      selectRow={selectRow}
                      tableKey={'selectedpermissions'}
                      showClearSelectionBtn={false}
                      striped={true}
                    />
                  </>
                )}
                {namingStep && (
                  <div className="tr-mb-4">
                    <Field
                      component={TextInput}
                      label="Name"
                      placeholder="On-Call permission set"
                      id="name"
                      name="name"
                      required
                    />
                    <Field
                      component={DurationInput}
                      name="duration"
                      label="Access Duration"
                      required={true}
                      includeNoneOption={false}
                      description="This is the default amount of time a user will be provisioned for."
                    />
                  </div>
                )}
              </div>
              <div>
                {namingStep ? (
                  <div className="tr-flex tr-justify-between tr-items-center">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setNamingStep(false);
                      }}
                    >
                      {'< Go back to Permission List'}
                    </Button>
                    <SubmitButton
                      name="resourceSave"
                      inSubmit={isSubmitting}
                      label={isEdit ? 'Update List' : `Create List`}
                    />
                  </div>
                ) : (
                  <div className="tr-flex tr-justify-end tr-items-center ">
                    <Button
                      data-testid="addResourceButton"
                      onClick={() => {
                        setNamingStep(true);
                      }}
                    >
                      {isEdit ? 'Change name >' : `Next, Name this List >`}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
});

export default OnCallModal;
