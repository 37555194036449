import {makeAutoObservable, runInAction} from 'mobx';
import NewResourceStore from './resourceStore';
import TasksStore from './TasksStore';
import ToastStore from './toast';
import AccountsStore from './AccountStore';
import UsersStore from './usersStore';
import {UserStore} from './userStore';
import {AccessStore} from './AccessStore';
import {PermissionStore} from './PermissionStore';
import {AuthInfoT} from 'src/context';
import {TeamStore} from './TeamStore';
import {Org} from './domainObjects/Org';
import {User} from './domainObjects/User';
import axios from 'axios';

class RootStore {
  newResourceStore: NewResourceStore;
  tasksStore: TasksStore;
  accountsStore: AccountsStore;
  usersStore: UsersStore;
  teamStore: TeamStore;
  toast: ToastStore;
  accessStore: AccessStore;
  permissionStore: PermissionStore;
  /** @deprecated use user domain object corresponding to the current user */
  userStore: UserStore;
  /** @deprecated */
  authInfo: AuthInfoT;
  org: Org;

  constructor(authInfo: AuthInfoT) {
    makeAutoObservable(this);
    /** @deprecated try to use this.org, this.currentUser */
    this.authInfo = authInfo;
    this.newResourceStore = new NewResourceStore(this);
    this.tasksStore = new TasksStore(this);
    this.accountsStore = new AccountsStore(this);
    this.usersStore = new UsersStore(this);
    this.teamStore = new TeamStore(this);
    this.toast = new ToastStore();
    this.accessStore = new AccessStore(this);
    this.permissionStore = new PermissionStore(this);
    /** @deprecated use this.currentUser */
    this.userStore = new UserStore(this);

    this.usersStore.usersMap[this.authInfo.user.id] = new User(this, {
      ...authInfo.user,
    });

    const {
      org,
      publishableKeys,
      featureFlags,
      sensitivitySettings,
      idpSettings,
      env,
      userAgreements: agreements = [],
      filters = [],
    } = authInfo || {};
    this.org = new Org(this, {
      ...org,
      publishableKeys,
      featureFlags,
      env,
      sensitivitySettings,
      idpSettings,
      agreements,
      filters,
    });
  }

  get currentUser() {
    return this.usersStore.usersMap[this.authInfo.user.id];
  }

  async reloadOrg() {
    const {data} = await axios.get<any>('/api/auth/info');
    const {org} = data as any;
    runInAction(() => {
      this.org = new Org(this, {
        ...org,
        env: this.org.env,
        publishableKeys: this.org.publishableKeys,
        featureFlags: this.org.featureFlags,
        sensitivitySettings: this.org.sensitivitySettings,
        idpSettings: this.org.idpSettings,
        agreements: this.org.agreements,
      });
    });
  }
}

export default RootStore;
