import React from 'react';
import {DropdownItem} from '@trustle/component-library';
import {useAuthority} from '../../../../../../connectors/common/authority/hooks/useAuthority';
import {useAuthorityLastSync} from '../../../../../../connectors/common/authority/hooks/useAuthorityLastSync';
import {formatTimestamp} from '../../../../../../dashboard/m365-visibility/utils';

type Props = {
  onClick: () => void;
};

export function SyncUsersAction({onClick}: Props) {
  const {isDisabled} = useAuthority();
  const lastSync = useAuthorityLastSync();

  const title = lastSync ? `Last Sync: ${formatTimestamp(lastSync)}` : '';

  const className = isDisabled ? 'tr-text-gray-400 tr-cursor-not-allowed' : '';

  return (
    <DropdownItem
      key="sync-system"
      onClick={() => {
        if (isDisabled) {
          return;
        }
        onClick();
      }}
      className={className}
      title={title}
    >
      Sync Users
    </DropdownItem>
  );
}
