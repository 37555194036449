import React, {useState} from 'react';
import {NoteT, UserT} from 'src/types';

import {Formik, FormikForm} from '../design/formik';
import {observer} from 'mobx-react-lite';
import {Account} from 'src/stores/domainObjects/Account';
import {Button, Confirmation, TextAreaInput, UserAvatarAndName} from '@trustle/component-library';
import {Field} from 'formik';
import {useRootStore} from 'src/lib/hooks';
import {formatUserName} from 'src/lib';

type NotePropsT = {
  note: NoteT;
  loggedUser: Partial<UserT>;
  permission?: {name?: string; duration?: string};
  account: Account;
};

const Note = observer((props: NotePropsT) => {
  const {note, loggedUser, permission, account} = props;
  const [editing, setEditing] = useState(false);
  const {usersStore} = useRootStore();
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);

  const noteForm = (
    <Formik
      key={`noteform${note.id}`}
      initialValues={{editedComment: note.comment || 'No comments'}}
      onSubmit={async ({editedComment}: {editedComment: string}) => {
        await account.editNote(note.id, editedComment);
        setEditing(false);
      }}
      className="note-form"
    >
      {({isSubmitting}) => {
        return (
          <FormikForm key={`noteformikform${note.id}`}>
            <Field
              component={TextAreaInput}
              id={`editedComment${note.id}`}
              name="editedComment"
              placeholder="Add your notes here"
              required
              rows={4}
              cols={50}
            />
            <div className="tr-flex tr-flex-row tr-justify-between">
              <Button
                variant="ternary"
                onClick={() => {
                  setEditing(false);
                }}
              >
                Close
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  );

  const actions = () => {
    return (
      <div className="tr-flex flex-align-left">
        <div className="flex-align-column">
          {loggedUser.id === note.createdByUid && (
            <div className="actions">
              <Button
                variant="ternary"
                onClick={() => {
                  setShowConfirmDialog(true);
                }}
              >
                Delete
              </Button>
              <Button
                variant="ternary"
                onClick={() => {
                  setEditing(true);
                }}
              >
                Edit
              </Button>
            </div>
          )}
          {showConfirmDialog && (
            <Confirmation
              onConfirm={() => account.deleteNote(note.id)}
              onClose={() => setShowConfirmDialog(false)}
              title={'Are you sure?'}
            >
              <div>{`Do you want to remove this note?`}?</div>
            </Confirmation>
          )}
        </div>
      </div>
    );
  };
  const user = usersStore.usersMap[note.createdByUid];

  return (
    <li key={`note${note.id}`} className="my-3 note-wrapper">
      <div className="tr-flex align-items-start">
        {/* left */}
        <div className="m-1 pr-3">
          <div className="avatar-container tr-flex flex-align-column align-items-start">
            <UserAvatarAndName
              displayName={formatUserName(user)}
              isOwner={user.isOrgOwner}
              subtitle={note.created}
              size="sm"
            />
          </div>
          {permission?.name && <span>{`Permission: ${permission?.name}`}</span>}
          {permission?.duration && <span>{`Duration: ${permission?.duration}`}</span>}
        </div>
        {/* right */}
        {!editing ? <div className="body5-normal my-2 col-sm-8 ml-4">{note.comment}</div> : noteForm}
      </div>
      <div className="ml-1 my-1 actions-title-wrapper flex-align-row">{actions()}</div>
    </li>
  );
});

export default Note;
