import {createContext, ReactNode} from 'react';
import RootStore from './stores';
import React from 'react';
import {AuthInfoT} from './context';

let store: RootStore;
const StoreContext = createContext<RootStore | undefined>(undefined);

function RootStoreProvider({
  children,
  rootStore,
  authInfo,
}: {
  children: ReactNode;
  rootStore?: RootStore;
  authInfo: AuthInfoT;
}) {
  function getStore() {
    const authUserStillTheSame = store?.authInfo.user.id === authInfo?.user.id;
    if (store && authUserStillTheSame) {
      return store;
    }

    store = new RootStore(authInfo);
    return store;
  }

  const root = rootStore ?? getStore();

  return <StoreContext.Provider value={root}>{children}</StoreContext.Provider>;
}

export {RootStoreProvider, StoreContext};
