import React from 'react';
import {Badge, Icon} from '@trustle/component-library';
import {BadgeVariant} from './TrustleBadge';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
type PropsT = {
  value?: number | null;
  refValue?: number;
  separator?: string;
  onClick?: () => void;
  //levels: {value: number; label: string; color: BadgeVariant}[]; todo add dynamic levels for more flexibility
};

const RiskBadge = observer((props: PropsT) => {
  const {value, refValue = 10, separator = '/', onClick} = props;

  if (_.isNil(value)) {
    return (
      <Icon title="Not Available" type="notAvailable" className="tr-mx-auto" size="sm" data-testid="notAvailableIcon" />
    );
  }
  const level = value > 7 ? 'High' : value > 3 ? 'Medium' : 'Low';
  const tag = `${value}${separator}${refValue} - ${level}`;
  const color =
    level === 'High' ? BadgeVariant.DANGER : level === 'Medium' ? BadgeVariant.WARNING : BadgeVariant.PRIMARY;

  return (
    <div className="tr-items-center tr-flex">
      <Badge className="text-white cursor" pill variant={color} onClick={_.isFunction(onClick) ? onClick : () => {}}>
        {tag}
      </Badge>
    </div>
  );
});

export default RiskBadge;
