import {TextInput} from '@trustle/component-library';
import React from 'react';

export function HeaderSearch() {
  // const {filters, clearFilters} = useAccountFilters(); // seems like we wont need to access filter status in this component

  return (
    <div className="tr-flex tr-items-center tr-gap-3 tr-bg-[#F8F9FC] tr-py-3 tr-px-2.5">
      {/* <Icon type={icon} size="md" /> */}
      <TextInput placeholder="Search user or account name" className="tr-pb-0 tr-max-w-[600px] tr-h-[40px]" />
    </div>
  );
}
