import {observer} from 'mobx-react';
import React from 'react';
import {ColumnDescription} from '@trustle/component-library';
import {Link, useHistory} from 'react-router-dom';
import {formatDateFromNow} from 'src/lib';
import {Resource} from 'src/stores/domainObjects/Resource';
import ResourceMiniCard from '../../card/resourceCard/ResourceMiniCard';
import {Icon, Tooltip} from '@trustle/component-library';
import LockedOrProvision from 'src/views/Resource/TreeView/LockedOrProvision';

/** Shared Columns for use in a Resource Tables */

export const IssueCol: ColumnDescription<Resource> = {
  dataField: 'importState.warning',
  text: 'Issue',
  formatter: (warning) => {
    const description = 'Configuration is not secure enough';
    return (
      <div className="text-trustle-navy tr-font-normal tr-text-xs">
        {warning && (
          <Tooltip content={<div>{description}</div>}>
            <Icon className="tr-text-warning" type="warning" />
          </Tooltip>
        )}
      </div>
    );
  },
  headerStyle: {width: '7%', paddingRight: '0px'},
  style: {width: '7%'},
};

export const IconCol: ColumnDescription<Resource> = {
  dataField: 'iconImage',
  text: '',
  classes: 'py-1',
  formatter: (_icon, system) => <ResourceMiniCard resource={system} key={system.id} isManaging={true} />,
  headerStyle: {width: '10%'},
  style: {width: '10%'},
};

export const FormattedName = observer(({system}: {system: Resource}) => {
  const history = useHistory();
  return (
    <div
      className="cursor tr-flex tr-items-center"
      onClick={() => history.push(`/resource/manage/${system.id}/overview`)}
    >
      <strong id={system.name} data-testid={system.name} className="tr-truncate">
        {system.name}
      </strong>
      <Tooltip content={system.description}>
        <Icon type="moreInfo" size="sm" className="tr-inline-block" />
      </Tooltip>
    </div>
  );
});

export const NameCol: ColumnDescription<Resource> = {
  dataField: 'name',
  text: 'System',
  formatter: (_name, system) => <FormattedName system={system} />,
  headerAlign: 'left',
  align: 'left',
  sort: true,
  headerStyle: {width: '30%'},
  style: {width: '30%'},
};

export const ResourceCountCol: ColumnDescription<Resource> = {
  dataField: 'numResources',
  formatter: (num, system) => <Link to={`/resource/manage/${system.id}/resources`}>{num}</Link>,
  text: 'Resources',
  sort: true,
  headerStyle: {width: '20%'},
  style: {width: '20%'},
};

export const AccountsLinkedCol: ColumnDescription<Resource> = {
  dataField: 'percentOfAccountsLinked',
  text: 'Linked Accounts',
  formatter: (percent, system) => (
    <Link to={`/resource/manage/${system.id}/accounts`}>{Math.round(100 * percent)}%</Link>
  ),
  sort: true,
  headerStyle: {width: '20%'},
  style: {width: '20%'},
};

export const TrustleScoreCol: ColumnDescription<Resource> = {
  dataField: 'trustleScore',
  text: 'Score',
  formatter: (percent, system) => (
    <Link to={`/resource/manage/${system.id}/recommendations`} title="Trustle Score">
      {Math.round(100 * percent)}%
    </Link>
  ),
  sort: true,
  headerStyle: {width: '10%'},
  style: {width: '10%'},
};

const ObservableRecCount = observer(({system}: {system: Resource}) => {
  return <Link to={`/resource/manage/${system.id}/recommendations`}>{system.numSysRecsUncompleted}</Link>;
});

export const NumRecommendationsCol: ColumnDescription<Resource> = {
  dataField: 'numSysRecsUncompleted',
  text: 'Recommendations',
  formatter: (_num, system) => <ObservableRecCount system={system} />,
  sort: true,
  headerStyle: {width: '20%'},
  style: {width: '20%'},
};

const ObservableUserLink = observer(({system}: {system: Resource}) => {
  return system.archivedByLink;
});

export const ArchivedByCol: ColumnDescription<Resource> = {
  dataField: 'archivedByLink',
  text: 'Archived By',
  formatter: (_link, system) => <ObservableUserLink system={system} />,
  // sort: true,
  headerAlign: 'left',
  align: 'left',
  headerStyle: {width: '20%'},
  style: {width: '20%'},
};

export const ArchivedOnCol: ColumnDescription<Resource> = {
  dataField: 'archivedOn',
  text: 'Archived At',
  formatter: (timestamp) => formatDateFromNow(timestamp),
  sort: true,
  headerAlign: 'left',
  align: 'left',
  headerStyle: {width: '20%'},
  style: {width: '20%'},
};

export const LockedCol: ColumnDescription<Resource> = {
  dataField: 'userIsOwner',
  text: '',
  formatter: (isOwned, row: Resource) =>
    isOwned ? (
      <></>
    ) : (
      <Tooltip
        size="md"
        className="tr-mx-5"
        data-testid={`readonly${row.id}`}
        content={
          <div className="tr-px-2">{'You are not an owner of this system, you can open it in read-only mode'}</div>
        }
      >
        <Icon type="editDisabled" size="sm" title="" />
      </Tooltip>
    ),
  sort: true,
  headerStyle: {width: '6%'},
  style: {width: '6%'},
};

export const ProvisionModeCol: ColumnDescription<Resource> = {
  dataField: 'provisionMode',
  text: '',
  formatter: (_value: any, row: Resource) => {
    return (
      <div data-testid={`lockedprovision`} className="text-trustle-navy tr-font-normal tr-text-xs">
        <LockedOrProvision target={row} />
      </div>
    );
  },
  sort: true,
  headerStyle: {width: '10%'},
  style: {width: '10%'},
};
