import {Button} from '@trustle/component-library';
import React from 'react';
import {useRootStore} from 'src/lib/hooks';
import {UserFilter} from 'src/stores/domainObjects/Org';

type DeleteFilterModalPropsT = {
  loadedFilter: UserFilter | null;
  onClose: () => void;
};

const DeleteFilterModal: React.FC<DeleteFilterModalPropsT> = ({ loadedFilter, onClose }) => {
  const { org } = useRootStore();

  if (!loadedFilter) {
    return null;
  }

  const { name, owner } = loadedFilter;

  const handleDelete = async () => {
    await org.deleteFilter(owner, name);
    onClose();
  };

  return (
    <>
      <div className="tr-mb-4" data-testid="confirm-remove-text">
        This will permanently delete filter{' '}
        <span className="tr-font-bold" data-testid="loaded-filter-name">
          {name}
        </span>
        .
      </div>
      <div className="tr-flex tr-justify-end">
        <Button variant="ternary" label="Cancel" onClick={onClose} />
        <Button
          colorVariant="danger"
          label="Delete"
          data-testid="btn-confirm-remove"
          onClick={handleDelete}
        />
      </div>
    </>
  );
};

export default DeleteFilterModal;
