import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {useFeatureFlags} from 'src/lib';
import {Tab} from 'react-bootstrap';
import ListGroup from 'src/components/ListGroup/ListGroup';
import {useHistory} from 'react-router-dom';
import {CircleGauge} from '@trustle/component-library';
import {Recommendation} from 'src/stores/domainObjects/Recommendation';
import {Resource} from 'src/stores/domainObjects/Resource';
import {ResourceTabMode} from 'src/types';
import {SystemRecommendationsTab} from './SystemRecommendations/SystemRecommendations';
import {Button} from '@trustle/component-library';

export const recStats = (recs: Recommendation[]) => {
  const uncompleted = recs.filter((rec) => _.isNil(rec.completedOn));
  return {
    unreviewed: recs.filter((rec) => _.isNil(rec.assignedTo) && _.isNil(rec.completedOn)),
    inProcess: recs.filter((rec) => !_.isNil(rec.assignedTo) && _.isNil(rec.completedOn)),
    completed: recs.filter((rec) => !_.isNil(rec.completedOn)),
    uncompleted,
    percentCompleted: recs.length > 0 ? 1 - uncompleted.length / recs.length : 0,
  };
};

const MainPage = observer(({system}: {system: Resource}) => {
  const history = useHistory();
  const featureFlagViewer = useFeatureFlags();
  const enableRecommendationReports = featureFlagViewer.isEnabled('enable_recommendation_reports');

  const [tileHighlighted, setTileHighlighted] = useState<'systems' | 'accounts'>();
  const accountsHighlighted = tileHighlighted === 'accounts';
  const systemsHighlighted = tileHighlighted === 'systems';

  const goToAccountRecs = () =>
    history.push(`/resource/manage/${system.id}/${ResourceTabMode.recommendations}/accounts/Accounts Detected`);

  const goToSystemRecs = () =>
    history.push(
      `/resource/manage/${system.id}/${ResourceTabMode.recommendations}/system/${SystemRecommendationsTab.SYSTEM_RECOMMENDATIONS}`
    );

  return (
    <div className="container">
      <div className="tr-flex tr-justify-between tr-items-center">
        <h1 className="tr-my-3 tr-text-xl md:tr-text-2xl">Here is what we found!</h1>
        {enableRecommendationReports && <Button label={'Create Report'} disabled={true} />}
      </div>
      <hr />
      <div className="tr-flex tr-flex-col lg:tr-flex-row tr-items-center tr-justify-between tr-max-w-[900px]">
        <div className="tr-w-[240px] tr-mr-4 tr-mb-5">
          <h2 className="text-center text-xl">Trustle Score</h2>
          <CircleGauge
            circles={[
              {
                color: '#32C5FF',
                percent: system.percentOfAccountRecsCompleted,
                className: systemsHighlighted ? 'opacity-50' : undefined,
                onMouseEnter: () => setTileHighlighted('accounts'),
                onMouseLeave: () => setTileHighlighted(undefined),
              },
              {
                color: '#FF9500',
                percent: system.percentOfRecsCompleted,
                className: accountsHighlighted ? 'opacity-50' : undefined,
                onMouseEnter: () => setTileHighlighted('systems'),
                onMouseLeave: () => setTileHighlighted(undefined),
              },
            ]}
          >
            <span className="text-5xl tr-font-bold" data-testid="score">
              {(() => {
                switch (tileHighlighted) {
                  case 'systems':
                    return Math.round(100 * system.percentOfRecsCompleted);
                  case 'accounts':
                    return Math.round(100 * system.percentOfAccountRecsCompleted);
                  default:
                    return Math.round(
                      100 * _.mean([system.percentOfAccountRecsCompleted, system.percentOfRecsCompleted])
                    );
                }
              })()}
              %
            </span>
          </CircleGauge>
        </div>
        {[
          {
            title: 'System',
            number: recStats(system.recommendations).uncompleted.length,
            percent: system.percentOfRecsCompleted,
            color: '#FF9500',
            className: `${accountsHighlighted ? 'opacity-50' : ''} ${
              systemsHighlighted ? 'shadow-2xl -translate-y-2' : ''
            }`,
            onClick: goToSystemRecs,
            onMouseEnter: () => setTileHighlighted('systems'),
          },
          {
            title: 'Accounts',
            number: system.unusedAccounts.filter((a) => !a.flaggedForReview).length + system.unreviewedAccounts.length,
            percent: system.percentOfAccountRecsCompleted,
            color: '#32C5FF',
            className: `${systemsHighlighted ? 'opacity-50' : ''} ${
              accountsHighlighted ? 'shadow-2xl -translate-y-2' : ''
            }`,
            onClick: goToAccountRecs,
            onMouseEnter: () => setTileHighlighted('accounts'),
          },
        ].map(({title, number, percent, color, className, ...rest}) => (
          <div className="tr-mb-5" key={title}>
            <div
              role="button"
              className={`tr-flex flex-column align-items-center rounded-xl text-navy tr-w-72 relative shadow-md transition ${className}`.trim()}
              onMouseLeave={() => setTileHighlighted(undefined)}
              data-testid={title}
              {...rest}
            >
              <h3 className="tr-mt-3 tr-text-sm tr-font-bold tr-mb-0">{title}</h3>
              <div className="absolute top-0 right-0 tr-w-10 tr-h-10 tr-mt-2 tr-mr-2">
                <CircleGauge circles={[{percent, color}]}>
                  <span className="tr-text-xs" data-testid={`${title}scorepct`}>
                    {Math.round(100 * percent)}%
                  </span>
                </CircleGauge>
              </div>
              <p className="text-7.5xl tr-font-bold tr-mb-0">{number}</p>
              <hr className="tr-w-full tr-mt-0" />
              <p className="tr-text-xs text-primary">See Recommendations &gt;</p>
            </div>
          </div>
        ))}
      </div>
      <hr />
      <Tab.Container defaultActiveKey="1">
        <div className="tr-flex tr-flex-col md:tr-flex-row ">
          <div className="tr-w-full md:tr-w-1/3 tr-mr-4">
            <ListGroup>
              <ListGroup.Item action eventKey="1" variant="ltblue">
                What are Trustle recommendations?
              </ListGroup.Item>
              <ListGroup.Item action eventKey="2" variant="ltblue">
                How are the scores calculated?
              </ListGroup.Item>
              <ListGroup.Item action eventKey="3" variant="ltblue">
                What do all the percentages stand for?
              </ListGroup.Item>
              <ListGroup.Item action eventKey="4" variant="ltblue">
                What do the main buttons on each tab do?
              </ListGroup.Item>
            </ListGroup>
          </div>
          <div className="tr-w-full md:tr-w-2/3">
            <Tab.Content>
              <Tab.Pane eventKey="1">
                <h2>
                  Trustle regularly scans to and provides suggestions for you to keep your organization secure and
                  running smoothly.
                </h2>
                <p className="tr-text-xs tr-font-bold">
                  With a couple clicks using Trustle’s recommendation engine, you’ll be able to do things like:
                </p>
                <ul>
                  <li className="tr-mb-3">
                    <strong>Generate Audit Reports</strong> of all the Accounts detected in your AWS.
                  </li>
                  <li className="tr-mb-3">
                    <strong>Discover and Flag Unused Accounts</strong> to either deprovision access or remove
                    completely. Trustle looks at the last activity of each user’s account and compares their usage to
                    their level of access.
                  </li>
                  <li className="tr-mb-3">
                    <strong>Rotate Access Keys</strong> on accounts that have a keys in use for long periods of time.
                  </li>
                  <li>
                    <strong>Stay Up-To-Date.</strong> Trustle updates the recommendations every time you connect. This
                    will allow you to stay current with everything happening in your system so you know you are working
                    with the most recent and relevant information. Keep your Org Secure.
                  </li>
                </ul>
              </Tab.Pane>
              <Tab.Pane eventKey="2">
                <h2>Each of the rings corresponds to one of the different subcategories of recommendations.</h2>
                <p>AWS Trustle Score</p>
                <div className="tr-flex">
                  <img className="tr-mt-3" height={200} width={276} src="/recommendations/scores-breakdown.png" />
                  <div className="tr-px-3">
                    <p>Your AWS Trustle Score is the combined percentage of all the indiviudal recommendations.</p>
                    <p>
                      Each recommendation is calculated based on the the number of unreviewed recommendations, or
                      unfinished lingering tasks (i.e., linking accounts to a user’s Trustle account).
                    </p>
                    <p>
                      Each time a recommendation is completed, the percentage increases. When Trustle detects something
                      new, it goes down. Trustle keeps your org always up-to-date with the most current data.
                    </p>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="3">
                <h2>Each percentage is an aggregate of the different sub-categories.</h2>
                <div className="tr-flex m-3">
                  <div className="tr-flex flex-column tr-w-72">
                    <div className="tr-flex flex-column align-items-center">
                      <img className="mb-1" width={89} height={89} src="/recommendations/score-gauge.png" alt="50%" />
                      <p>AWS System Total</p>
                    </div>
                    <img className="mb-1" width={58} height={34} src="/recommendations/systems-score.png" alt="100%" />
                    <p>System</p>
                    <img width={58} height={34} src="/recommendations/accounts-score.png" alt="50%" />
                    <p>Accounts</p>
                  </div>
                  <div className="px-4">
                    <p className="tr-font-bold">
                      Your AWS Trustle Score is the combined percentage of all the Recommendations.
                    </p>
                    <div className="m-4">
                      <p className="tr-font-bold">The System Score percentage is calculated by assessing:</p>
                      <ul>
                        <li>Security and Configuration Settings</li>
                        <li>Token Rotation</li>
                        <li>Certificate Rotation</li>
                      </ul>
                      <p className="tr-font-bold">The Account Score percentage is calculated by assessing:</p>
                      <ul>
                        <li>Number of linked or “abandoned” accounts </li>
                        <li>Review and evaluate “high-risk” accounts </li>
                        <li>Re-certify access for every user account on your system</li>
                      </ul>
                    </div>
                    <p className="tr-font-bold">
                      Taking action on Trustle recommendations increases your overall score, which is designed to be
                      reflective of your system’s overall security.
                    </p>
                  </div>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="4">
                <h2>Each category has several recommendations designed to simplify your day-to-day workload.</h2>
                <div className="tr-flex">
                  <img width={217.81} height={222.96} src="/recommendations/category.png" />
                  <div className="mx-4">
                    <p className="tr-font-bold">Each recommendation tab is made up of 3 Elements:</p>
                    <div className="tr-flex tr-justify-between align-items-center">
                      <span className="tr-font-bold">1. Number Total</span>
                      <img width={36.58} height={33} src="/recommendations/80.png" alt="80" />
                    </div>
                    <p>
                      This number signifies the number of discovered accounts, issues, reviews etc. that exist under
                      each tab
                    </p>
                    <div className="tr-flex tr-justify-between align-items-center">
                      <span className="tr-font-bold">2. Bulk Action Button</span>
                      <img
                        width={85.91}
                        height={19.95}
                        src="/recommendations/flag-all-for-review-button.png"
                        alt="Flag All For Review"
                      />
                    </div>
                    <p>This buttons allows you to take bulk action, in 1-click on the entire section, or on specific</p>
                    <div className="tr-flex tr-justify-between align-items-center">
                      <span className="tr-font-bold">3. Percentage Score</span>
                      <img width={35.58} height={33} src="/recommendations/percentage-score.png" alt="65%" />
                    </div>
                    <p>This percentage contributes to the overall subcategory Score.</p>
                  </div>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </div>
        </div>
      </Tab.Container>
    </div>
  );
});

export default MainPage;
