import {Button, Modal} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {useRootStore} from 'src/lib/hooks';
import {User} from 'src/stores/domainObjects/User';
import {UserType} from 'src/types';

const NewUsersConfirmationModal = observer(function ({users}: {users: User[]}) {
  const {usersStore} = useRootStore();

  const history = useHistory();
  const departments = _.compact(_.uniq(_.map(users, 'department')));
  const titles = _.compact(_.uniq(_.map(users, 'title')));
  const roles = _.compact(_.uniq(_.map(users, 'remoteRole')));
  const countByType = (type: UserType) => users.filter((user) => user.type === type).length;
  const employees = countByType(UserType.employee);
  const contractor = countByType(UserType.contractor);
  const customer = countByType(UserType.customer);

  return (
    <>
      <Modal
        width={'lg'}
        onClose={() => usersStore.setNewlyImported([])}
        title={
          <div className="tr-flex tr-text-trustle-navy tr-items-center">
            Success! You’ve Added {users.length} New Users!
          </div>
        }
      >
        <span className="tr-text-trustle-navy">
          <div className="tr-my-5">
            <span className="tr-font-bold">All new users are added in a pending state by default.</span> This is to
            ensure that you can review and link them to system accounts prior to sending out an invite to activate their
            account.
          </div>
          <h3>We've also detected ...</h3>
          <span className="tr-font-bold tr-text-trustle-link tr-pl-2">
            {_.size(departments) > 0 && <p>{_.size(departments)} Departments</p>}
            {_.size(titles) > 0 && <p>{_.size(titles)} Titles</p>}
            {_.size(roles) > 0 && <p>{_.size(roles)} Roles</p>}
            {employees > 0 && <p>{employees} Employees</p>}
            {contractor > 0 && <p>{contractor} Contractors</p>}
            {customer > 0 && <p>{customer} Customers</p>}
          </span>
          <div className="tr-my-5">
            You will be able to look at your organization based on things like
            <span className="tr-font-bold"> ”Department” </span> and
            <span className="tr-font-bold"> “Contractors” </span>
            to have more visibility and gain insight into who is using your resources.
          </div>
        </span>
        <div className="tr-flex tr-justify-end">
          <Button
            variant="primary"
            onClick={() => {
              usersStore.setNewlyImported([]);
              history.push('/admin/users/inactive');
            }}
          >
            Go to Inactive
          </Button>
        </div>
      </Modal>
    </>
  );
});
export default NewUsersConfirmationModal;
