import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {ProgressStatusInfo, ProgressStatusPerImport} from 'src/types';
import ImportTasksList from './ImportTasksList';
import moment from 'moment';

export default observer(function ImportProgress(props: {progressPerImport?: ProgressStatusPerImport[]}) {
  if (_.isEmpty(props?.progressPerImport)) {
    return <></>;
  }
  const {progressPerImport = []} = props;
  const [total, setTotal] = useState<number>(0);
  const [overallProgress, setOverallProgress] = useState<number>(0);
  const [lastUpdated, setLastUpdated] = useState<string>();
  const [result, setResult] = useState<string>('success');

  const calcOverallProgress = () => {
    const unified: ProgressStatusInfo[] = [];

    for (const importProgress of progressPerImport) {
      if (!_.isEmpty(importProgress?.progressStatus)) {
        unified.push(importProgress.progressStatus);
      }
    }

    let count = 0;
    let overallError = _.some(unified, {error: true});
    let overallTaskCount = 0;
    let result = 'danger';
    let totalTasks = 0;

    for (let i = 0; i < unified.length; i++) {
      const progressStatus = unified[i];

      if (!progressStatus) {
        continue;
      }
      const {tasks = [], current = '', finished, error} = progressStatus;
      const tasksCnt = tasks.length;

      totalTasks += tasksCnt;
      count += finished && !error ? tasksCnt : tasks.indexOf(current);

      overallTaskCount += tasksCnt;
      overallError = overallError || error;
      if (count === overallTaskCount) {
        result = 'success';
      } else if (!overallError) {
        result = 'warning';
      }
    }

    setTotal(totalTasks);
    setResult(result);
    setLastUpdated(moment().toISOString());
    setOverallProgress(count < 0 ? 0 : count);
  };

  useEffect(() => {
    calcOverallProgress();
  }, [progressPerImport]);

  if (total === 0) {
    return <></>;
  }

  return (
    <div className="import-progress-bar my-1">
      <div className="tr-flex flex-column">
        <div className="tr-flex tr-flex-row mb-2">
          {/** left */}
          <div className={`tr-flex flex-column align-items-center justify-items-center text-${result}`}>
            <span className="h2 font-weight-bold">{`${overallProgress}/${total}`}</span>
            <div className="small font-weight-bold">{'Complete'}</div>
          </div>
          {/** right */}
          <div className="tr-ml-4">
            <div className="font-weight-bold">{`${
              result === 'warning' ? 'Import In Progress' : 'Import Finished'
            }`}</div>
            <div>{`Last updated: ${moment(lastUpdated).format('M/D/Y hh:mm:ss a')}`}</div>
            <div className="tr-px-0">
              <span style={{width: '120px'}} className="small text-muted">
                This process can take more than 1 hour, if it's taking longer, please{' '}
                <a href="mailto:support@trustle.com">contact us</a>.
              </span>
            </div>
          </div>
        </div>

        <div className="tr-flex tr-flex-wrap tr-ml-2">
          {_.map(progressPerImport, (importState) => {
            return <ImportTasksList key={importState.service} progressStatus={importState.progressStatus} />;
          })}
        </div>
      </div>
    </div>
  );
});
