import React from 'react';
import {Link} from 'react-router-dom';

type NumAccesesLinkProp = {account: any; systemId: any};

// copied from `frontend/src/components/design/tables/SharedColumnDefinitions/AccountColumns.tsx`
export function NumAccessesLink({account, systemId}: NumAccesesLinkProp) {
  const encodedAccount = encodeURIComponent(account?.account);
  return (
    <Link
      className="underline"
      data-testid="accesses"
      to={`/resource/manage/${systemId}/resources?Account=${encodedAccount}&Expand=All`}
    >
      {account.accessesCount}
    </Link>
  );
}
