import React, {useState} from 'react';
import {Dropdown, DropdownItem, DropdownMenu} from '@trustle/component-library';
import {UserCsvImportModal} from './UserCsvImportModal';
import {AddUserModal} from './AddUserModal';

export function AddUsersDropdown() {
  const [showAddUsers, setShowAddUsers] = useState(false);
  const [showCsvImport, setShowCsvImport] = useState(false);

  const dropdownMenu = (
    <DropdownMenu>
      <DropdownItem key="add-user" onClick={() => setShowAddUsers(true)}>
        Add user
      </DropdownItem>
      <DropdownItem key="import-from-csv" onClick={() => setShowCsvImport(true)}>
        Import from CSV
      </DropdownItem>
    </DropdownMenu>
  );

  return (
    <>
      <Dropdown dropdownMenu={dropdownMenu} title="Add Users" />
      <UserCsvImportModal show={showCsvImport} setShow={setShowCsvImport} />
      <AddUserModal show={showAddUsers} setShow={setShowAddUsers} />
    </>
  );
}
