import {Badge} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Link} from 'react-router-dom';
import {useRootStore} from 'src/lib/hooks';
import {SetupStepT} from 'src/types';

const GettingStarted = observer(() => {
  const {org} = useRootStore();

  if (_.isNil(org) || org.steps === undefined) {
    return <></>;
  }

  const gettingStartedSteps = org.steps ?? [];
  const [finishedSteps] = _.partition(gettingStartedSteps, (step: SetupStepT) => !!step.finalized);

  if (finishedSteps.length === gettingStartedSteps.length) {
    return <></>;
  }

  return (
    <Link to="/admin/setup" className="tr-hidden tr-mr-5 lg:tr-block hover:tr-no-underline">
      <Badge variant="success" size="md">
        {`Setup Guide ${finishedSteps.length}/${gettingStartedSteps.length}`}
      </Badge>
    </Link>
  );
});

export default GettingStarted;
