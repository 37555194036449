import React from 'react';
import {Link, Route, Switch} from 'react-router-dom';
import BillingPage from './Billing';
import PlansAndPricing from './PlansPricing';
import {useRootStore} from 'src/lib/hooks';
import CurrentPlan from './CurrentPlan';
import {FacetIdEnum} from 'src/types';
import {observer} from 'mobx-react';

import moment from 'moment';
import {Badge, Tab, Tooltip} from '@trustle/component-library';
import _ from 'lodash';
import {BadgeVariantEnum} from '@trustle/component-library/dist/types';

enum BillingTabs {
  BILLING = 'status',
  CURRENT = 'plan',
  PLANS = 'plans',
}

const BillingTab = observer(function CheckoutFormCmp() {
  const {org} = useRootStore();

  if (_.isNil(org)) {
    return <></>;
  }

  const isFreePlan = org.facetId === FacetIdEnum.FREE_AWS;

  const status = org.subscription?.cancel_at ? (
    <Tooltip
      content={`Canceled, will be downgraded on ${moment(org.subscription.cancel_at * 1000).format('M/D/Y hh:mm a')}`}
    >
      Canceled
    </Tooltip>
  ) : (
    org.subscription?.status?.replace('_', ' ')
  );
  return (
    <div>
      {org.subscriptionPlan && org.subscriptionStatus && (
        <div className="text-right text-navy body3">
          Status:
          <Badge pill variant={org.subscriptionStatus as BadgeVariantEnum}>
            <span className="capitalize-all">{status}</span>
          </Badge>
        </div>
      )}
      <Tab.Group>
        <Tab.List variant="line">
          <Tab as={Link} to={'/admin/billing/status'} data-testid={BillingTabs.BILLING} key={BillingTabs.BILLING}>
            {`Billing`}
          </Tab>
          {org.subscriptionPlan && (
            <Tab as={Link} to={'/admin/billing/plan'} data-testid={BillingTabs.CURRENT} key={BillingTabs.CURRENT}>
              {`Current Plan`}
            </Tab>
          )}
        </Tab.List>
      </Tab.Group>
      <Switch>
        <Route path="/admin/billing/status/:step?" exact={true}>
          {isFreePlan ? <CurrentPlan upgradeTo="Team" /> : <BillingPage />}
        </Route>
        <Route path="/admin/billing/plans" exact={true}>
          <PlansAndPricing />
        </Route>
        <Route path="/admin/billing/plan" exact={true}>
          {isFreePlan ? <CurrentPlan upgradeTo="Team" /> : <CurrentPlan />}
        </Route>
      </Switch>
    </div>
  );
});

export default BillingTab;
