import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {ConnectionInfoT, ImportStatus, ResourceT, TestConnectionResponseT} from 'src/types';
import {Card} from 'src/components/design';
import {Button, Loading} from '@trustle/component-library';
import ConnectorStatus from 'src/components/StatusIndicator';
import StrongDMSetup from './StrongDMSetup';
import FontAwesomeIcon, {faCircleNotch} from 'src/components/FontAwesomeIcon';
import {useConnectionCredentials} from '../../lib/hooks';

export default function EditConnectionPagerDuty(props: {resource: ResourceT}) {
  const {resource} = props;
  const lastImport = resource.connector?.lastImport;
  const [connectionInfo, setConnectionInfo] = useState<ConnectionInfoT>();
  const [displayCredentialsForm, setDisplayCredentialsForm] = useState<boolean>(false);
  const [connectorStatus] = useState<string | undefined>(lastImport?.status);
  const [testConnectionResult, setTestConnectionResult] = useState<TestConnectionResponseT>();
  const [testConnectionInProgress, setTestConnectionInProgress] = useState<boolean>(false);
  const {testConnection: testConnectionAPI} = useConnectionCredentials();

  async function loadConnection() {
    const connectionResponse = await axios.get(`/api/connect/${resource.connectionId}`);
    setConnectionInfo(connectionResponse.data);
  }

  async function onCredentialsUpdated() {
    await loadConnection();
    setTestConnectionResult(undefined);
    setDisplayCredentialsForm(false);
  }

  useEffect(() => {
    void loadConnection();
  }, [resource.connectionId]);

  if (!connectionInfo) {
    return <Loading />;
  }

  const {connection} = connectionInfo;
  const {serviceFriendlyName} = connection;

  async function testConnection() {
    setTestConnectionInProgress(true);
    const {data} = await testConnectionAPI(connection.id, {});
    setTestConnectionResult(data);
    setTestConnectionInProgress(false);

    if (data.error) {
      return;
    }

    await axios.post(`/api/connect/${resource.connectionId}/start_import`);
  }

  function renderTestConnection() {
    const testConnectionText = (() => {
      if (testConnectionInProgress) {
        return (
          <div className="body6 text-right">
            <FontAwesomeIcon icon={faCircleNotch} spin size="sm" /> Connecting
          </div>
        );
      }
      return (
        <span className={`${testConnectionResult?.ok ? 'text-success' : 'text-danger'} body6`}>
          {testConnectionResult?.ok ? 'Connected Successfully!' : testConnectionResult?.error?.message}
        </span>
      );
    })();

    return <div className="text-right body6">{testConnectionText}</div>;
  }

  return (
    <div>
      <div className="tr-my-2 no-padding">
        <h3>Authentication</h3>
        <div className="tr-w-full lg:tr-w-2/3">
          <div>
            {connectorStatus === ImportStatus.PENDING && (
              <span className="body6 badge badge-warning">Import in progress...</span>
            )}
            {renderTestConnection()}
          </div>
          <Card body>
            <div className="tr-flex align-items-top tr-justify-between">
              <div className="tr-flex">
                <ConnectorStatus className="tr-mr-3" status={connectorStatus}></ConnectorStatus>
                <div>
                  <div className="body4 ellipsis-wrap">{serviceFriendlyName}</div>
                </div>
              </div>
              <div>
                <Button
                  variant="secondary"
                  label="Update"
                  selected={displayCredentialsForm}
                  onClick={() => {
                    setDisplayCredentialsForm(!displayCredentialsForm);
                  }}
                />
                <Button variant="secondary" label="Test" onClick={() => testConnection()} />
              </div>
            </div>
          </Card>
          {displayCredentialsForm && (
            <StrongDMSetup connectionInfo={connectionInfo} onComplete={onCredentialsUpdated} editMode={true} />
          )}
        </div>
      </div>
    </div>
  );
}
