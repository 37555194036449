import _ from 'lodash';
import React from 'react';
import {observer} from 'mobx-react';

const DefaultOrOverriden = observer(function DefaultOrOverridenCmp(props: {
  value: any;
  touched?: boolean;
  forceDefault?: boolean;
}) {
  const OverridenCmp = <span className="tr-font-bold">Overridden</span>;
  const DefaultCmp = <>Default</>;
  const {value = null, touched = false, forceDefault = false} = props;
  if (forceDefault) {
    return DefaultCmp;
  }
  if (touched) {
    return OverridenCmp;
  }
  if (_.isNil(value)) {
    return DefaultCmp;
  }
  // not touched and has value - could be inherited (default) or could be have a value itself (overriden)
  return OverridenCmp;
});

export default DefaultOrOverriden;
