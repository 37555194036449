import React from 'react';
import FontAwesomeIcon, {faCheckCircle, faCircle} from 'src/components/FontAwesomeIcon';
import {SetupStepT} from 'src/types';

type PropsT = {
  step: SetupStepT;
  index?: number;
};

function SetupCircle(props: PropsT) {
  const {step, index} = props;
  return (
    <div className="setup-progress-circle">
      {step.finalized ? (
        <FontAwesomeIcon
          data-testid={`finished-step-circle${step.id}${index}`}
          key={`finished-step-circle${step.id}${index}`}
          icon={faCheckCircle}
          style={{color: 'green'}}
        />
      ) : (
        <FontAwesomeIcon
          data-testid={`unfinished-step-circle${step.id}${index}`}
          key={`unfinished-step-circle${step.id}${index}`}
          icon={faCircle}
          style={{color: '#EEEEEE'}}
        />
      )}
    </div>
  );
}

export default SetupCircle;
