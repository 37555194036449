import {ConnectionServiceE} from 'src/types';
import {FormFieldTypes} from '../common/types';
import {ConnectorSchema} from './default';

const jira: ConnectorSchema = {
  service: ConnectionServiceE.JIRA,
  name: 'Jira',
  description: 'Connect to jira',
  image: 'someroute/somiconroute.png',
  serviceId: 'jira',
  auth: {
    type: 'accesKeys',
    fields: [
      {
        placeholder: 'Enter the account URL',
        label: 'Account URL',
        type: FormFieldTypes.TEXT,
        name: 'accountUrl',
        id: 'accountUrl',
        required: true,
      },
      {
        label: 'Enter the username',
        type: FormFieldTypes.TEXT,
        name: 'username',
        id: 'username',
        placeholder: 'Enter username here',
        required: true,
      },
      {
        label: 'Enter the password',
        type: FormFieldTypes.PASSWORD,
        name: 'password',
        id: 'password',
        placeholder: 'Enter password here',
        required: true,
      },
    ],
  },
};

export default jira;
