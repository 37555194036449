import React, {useState} from 'react';
import {
  ConnectionServiceE,
  ResourceT,
  UsageServiceByConnectionType,
  CredentialReportServiceByConnectionType,
} from '../../../types';
import {useHistory} from 'react-router-dom';
import EditConnectionAWS from './EditConnectionAWS';
import EditConnectionGitHub from '../../github/EditConnectionGitHub';
import EditConnectionGApps from '../../gapps/EditConnectionGApps';
import EditConnectionAzureAD from '../../azuread/EditConnectionAzureAD';
import EditConnectionParams from './EditConnectionParams';
import EditConnectionOkta from 'src/connectors/okta/EditConnectionOkta';
import EditConnectionPagerDuty from 'src/connectors/pagerduty/EditConnectionPagerDuty';
import EditConnectionStrongDM from 'src/connectors/strongdm/EditConnectionStrongDM';
import EditConnectionTableau from 'src/connectors/tableau/EditConnectionTableau';
import EditConnectionM365 from 'src/connectors/m365/EditConnectionM365';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Button, Icon, Confirmation} from '@trustle/component-library';
import {observer} from 'mobx-react';
import './EditConnection.scss';
import {useFeatureFlags} from 'src/lib';
import ImportStatesHistory from './ImportStatesHistory';
import _ from 'lodash';
import EditConnectionGCP from 'src/connectors/gcp/EditConnectionGCP';
import EditConnectionAWSIDC from 'src/connectors/aws_idc/EditConnectionAWSIDC';

export default observer(function EditConnection(props: {resource: Resource}) {
  const {resource} = props;
  const {connector} = resource;

  const history = useHistory();

  if (!connector) {
    history.push(`/resource/manage/${resource.id}/settings/edit`);
    return null;
  }

  const featureFlagViewer = useFeatureFlags();
  const toggleConnectionFeature = featureFlagViewer.isEnabled('toggle_connection_feature');

  const [confirmEnableConnector, setConfirmEnableConnector] = useState<boolean>(false);
  const [confirmDisableConnector, setConfirmDisableConnector] = useState<boolean>(false);

  //TO DO (nr): We need to change this to use the actual store domain object
  const connectorComponents: Record<ConnectionServiceE, () => React.ReactNode> = {
    [ConnectionServiceE.AWS]: () => <EditConnectionAWS resource={resource} />,
    [ConnectionServiceE.GITHUB]: () => <EditConnectionGitHub resource={resource as ResourceT} />,
    [ConnectionServiceE.GAPPS]: () => <EditConnectionGApps resource={resource as ResourceT} />,
    [ConnectionServiceE.AZURE_AD]: () => <EditConnectionAzureAD resource={resource} />,
    [ConnectionServiceE.OKTA]: () => <EditConnectionOkta resource={resource} />,
    [ConnectionServiceE.PAGERDUTY]: () => <EditConnectionPagerDuty resource={resource as ResourceT} />,
    [ConnectionServiceE.GENERIC]: () => null,
    [ConnectionServiceE.SLACK]: () => null,
    [ConnectionServiceE.GCP]: () => <EditConnectionGCP resource={resource as ResourceT} />,
    [ConnectionServiceE.M365]: () => <EditConnectionM365 resource={resource} />,
    [ConnectionServiceE.CLOUDFLARE]: () => null,
    [ConnectionServiceE.JIRA]: () => null,
    [ConnectionServiceE.CUSTOM]: () => null,
    [ConnectionServiceE.CUSTOM_SYSTEM]: () => null,
    [ConnectionServiceE.STRONGDM]: () => <EditConnectionStrongDM resource={resource as ResourceT} />,
    [ConnectionServiceE.TABLEAU]: () => <EditConnectionTableau resource={resource} />,
    [ConnectionServiceE.AWS_IDENTITY_CENTER]: () => <EditConnectionAWSIDC resource={resource as ResourceT} />,
  };

  const editConnectorComponent = connectorComponents[connector.service] || null;
  const ShowToggleConnectionFeature = () => {
    if (!toggleConnectionFeature || !resource.isConnectedSystem || resource.archived) {
      return <></>;
    }

    return (
      <div
        className={`tr-mb-[80px] tr-border-2 rounded ${
          resource.connector?.disabled ? 'border-trustle-outline' : 'tr-border-red-500'
        } tr-border-solid tr-w-[90%]`}
      >
        {resource.connector?.disabled ? (
          <>
            <div className="tr-py-4 tr-px-6">
              <b>{"Reenable Trustle's Connection to This System"}</b>
              <div className="tr-mb-2 body5-normal">
                This will “turn on” all communication with this connected system. Also, this system will be visible on
                tasks and the access page for users unless manually hidden.
              </div>
              <div className="tr-flex tr-mt-2">
                <Button
                  variant="primary"
                  className="tr-flex"
                  colorVariant="primary"
                  onClick={() => setConfirmEnableConnector(true)}
                >
                  Enable
                </Button>
              </div>
            </div>
            {confirmEnableConnector && (
              <Confirmation
                onConfirm={async () => {
                  setConfirmEnableConnector(false);
                  await resource.updateConnectionSettings({disabled: false});
                }}
                onClose={() => setConfirmEnableConnector(false)}
                title={'Reenable Connector?'}
                confirmButtonLabel="Re-enable"
                variant="primary"
              >
                Are you sure that you would like to re enable the connector?
              </Confirmation>
            )}
          </>
        ) : (
          <>
            <div className="tr-py-4 tr-px-6">
              <b>Disable Trustle's Connection to This System</b>
              <div className="tr-mb-2 body5-normal">
                This will “turn off” any communication that Trustle has with this connected system. This system will
                also be hidden and not visible on tasks or access page for users unless the connection is re-enabled.
              </div>
              <div className="tr-text-white tr-flex tr-mt-2">
                <Button
                  colorVariant="danger"
                  className="tr-pl-2 tr-py-2 tr-pr-3 tr-text-sm tr-flex tr-items-center "
                  onClick={() => setConfirmDisableConnector(true)}
                >
                  <Icon type="disabled" size="sm" />
                  <div className="tr-ml-1">Disable</div>
                </Button>
              </div>
            </div>
            {confirmDisableConnector && (
              <Confirmation
                onConfirm={async () => {
                  setConfirmDisableConnector(false);
                  await resource.updateConnectionSettings({disabled: true});
                }}
                onClose={() => setConfirmDisableConnector(false)}
                title={'Disable Connector?'}
                confirmButtonLabel="Disable"
                className="tr-danger danger"
                size="lg"
              >
                <p>Are you sure that you would like to disable the connector?</p>
                <p>Upon disabling the connector:</p>
                <ul>
                  <li>Trustle will no longer poll for updates, provision, or deprovision access to this system.</li>
                  <li>
                    Access expiration monitoring, along with any auto deprovisioning (if enabled) will be disabled.
                  </li>
                  <li>
                    Users will no longer be able to see their existing access, or request new access through Trustle.
                  </li>
                  <li>Any pending tasks that were created before connector disablement will not be actionable.</li>
                </ul>
              </Confirmation>
            )}
          </>
        )}
      </div>
    );
  };
  return (
    <>
      <div className="tr-mt-3 tr-border-gray-200 tr-border-[0px] tr-border-b-[1px] tr-border-solid ">
        <h3 className="tr-my-3">Connector Status</h3>
        <div className="tr-w-[90%]">
          <div className="tr-pr-4">{editConnectorComponent()}</div>
        </div>
      </div>
      <div className="tr-py-6 tr-w-[90%]">
        <ImportStatesHistory
          resource={resource}
          services={_.compact([
            connector.service,
            UsageServiceByConnectionType[connector.service]?.service,
            CredentialReportServiceByConnectionType[connector.service],
          ])}
          limit={1}
        />
      </div>
      <hr />
      <EditConnectionParams resource={resource} />
      <ShowToggleConnectionFeature />
    </>
  );
});
