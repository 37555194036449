import {observer} from 'mobx-react';
import {Permission} from 'src/stores/domainObjects/Permission';

import React, {useState} from 'react';
import {ObservableRiskColumn} from 'src/components/design/tables/SharedColumnDefinitions/ResourceTableColumns';
import {Icon, Modal, StatusBadge, Tooltip} from '@trustle/component-library';
import {AwsResourceType, ConnectionServiceE, MetadataType} from 'src/types';
import _ from 'lodash';
import {formatDateFromNow, formatFullDate} from 'src/lib';

export const RiskComponent = observer(({permission}: {permission: Permission}) => {
  return (
    <div className="tr-ml-1 tr-mr-1 tr-w-min-90 tr-flex tr-justify-center tr-align-items-center">
      <ObservableRiskColumn item={permission} />
    </div>
  );
});

export const RelatedEntities = observer(({permission}: {permission: Permission}) => {
  const [relatedAppsVisible, setRelatedAppsVisible] = useState<boolean>(false);

  if (permission.rootResource.userIsOwner) {
    return <></>;
  }

  const relatedPermissionsList = _.filter(permission.connectorData, (d) => {
    return d.data?.metadataType === MetadataType.RELATED_PERMISSION;
  });

  const relatedCount = _.size(relatedPermissionsList);

  if (relatedCount === 0) {
    return <></>;
  }

  return (
    <div
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setRelatedAppsVisible(true);
      }}
      className="tr-ml-1 tr-mr-1 tr-flex tr-justify-center tr-items-center tr-cursor-pointer"
    >
      <Icon type="apps" title="Linked Apps" /> <span className="body6">{relatedCount}</span>
      <Modal
        visible={relatedAppsVisible}
        width="md"
        title="Linked Apps"
        onClose={() => {
          setRelatedAppsVisible(false);
        }}
      >
        {_.map(relatedPermissionsList, (item) => {
          return (
            <div className="tr-px-2 tr-py-1 tr-flex tr-flex-row tr-justify-between tr-items-center tr-border-solid tr-border-0 tr-border-t-[1px]  tr-border-gray-medium first:tr-border-none ">
              <div className="tr-text-trustle-link body5-normals tr-cursor-pointer">{item.title}</div>
            </div>
          );
        })}
      </Modal>
    </div>
  );
});

export const GetLastEvent = observer(({permission}: {permission: Permission}) => {
  const {retrieveUsageDataEnabled} = permission.rootResource;

  if (!retrieveUsageDataEnabled) {
    return <span></span>;
  }

  const lastEvent =
    permission.lastEvent ?? _.get(permission, 'connectorData[0].data.RoleLastUsed.LastUsedDate', undefined);

  return (
    <div className="tr-ml-1 tr-mr-1 tr-flex tr-justify-center tr-items-center body6" data-testid="importStatus">
      <Icon
        type="time"
        size="sm"
        useDefaultColor={false}
        color={_.isEmpty(lastEvent) ? '#D3D3D3' : 'text-trustle-dark'}
      />
      <Tooltip
        size="lg"
        content={`${
          _.isEmpty(lastEvent) ? 'No data for the tracked period' : `Last activity: ${formatFullDate(lastEvent)}`
        }`}
        position="bottom"
        data-testid="importStatusText"
      >
        {_.isEmpty(lastEvent) ? (
          <div className="tr-text-[#D3D3D3]">{'Never'}</div>
        ) : (
          <span data-testid="datefromnow">{_.capitalize(formatDateFromNow(lastEvent).toString())}</span>
        )}
      </Tooltip>
    </div>
  );
});

export const AccessStatus = observer(({permission}: {permission: Permission}) => {
  const system = permission.rootResource;

  const statuses = permission.accessStatusesAggregated;
  const isAwsRole = system.type === ConnectionServiceE.AWS && system.foreignId === AwsResourceType.ROLES;
  return (
    <div className="tr-flex tr-align-items-center w-min-74">
      {!isAwsRole && (
        <div className="tr-mr-1 flex tr-align-items-center !tr-text-[10px]">
          {statuses && <StatusBadge size="sm" statusCount={statuses} className="tr-mr-1" />}
          {permission.teamsAccessStatusesAggregated && (
            <StatusBadge
              size="sm"
              statusCount={permission.teamsAccessStatusesAggregated}
              isTeam={true}
              className="tr-mr-1"
            />
          )}
        </div>
      )}
    </div>
  );
});
