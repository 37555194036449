import {HEATMAP_COLORS} from '../config';
import React from 'react';
import {HEATMAP_NULL_LABEL, HEATMAP_ZERO_LABEL} from '../../../constants';

const HEATMAP_LAST_INDEX = HEATMAP_COLORS.length - 1;

function IndividualLegend({index, color, label = ''}: {index: number; color: string; label?: string}) {
  return (
    <div className="tr-flex tr-flex-col tr-h-10 tr-w-16 tr-justify-end tr-items-center">
      <p className="tr-mb-0">{label}</p>
      <div key={index} className="tr-h-3 tr-w-full" style={{backgroundColor: color}}></div>
    </div>
  );
}

export function CustomLegend() {
  return (
    <div className="tr-flex tr-text-xs tr-gap-8 tr-mb-4">
      <div className="tr-flex tr-gap-4">
        <IndividualLegend index={0} color={HEATMAP_COLORS[0]} label={HEATMAP_NULL_LABEL} />
        <IndividualLegend index={1} color={HEATMAP_COLORS[1]} label={HEATMAP_ZERO_LABEL} />
      </div>
      <div className="tr-flex">
        <IndividualLegend index={2} color={HEATMAP_COLORS[2]} label={'Least'} />
        {HEATMAP_COLORS.slice(3, HEATMAP_LAST_INDEX).map((color, index) => (
          <IndividualLegend key={index} index={index + 3} color={color} />
        ))}
        <IndividualLegend index={HEATMAP_LAST_INDEX} color={HEATMAP_COLORS[HEATMAP_LAST_INDEX]} label={'Most'} />
      </div>
    </div>
  );
}
