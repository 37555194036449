import React, {useState} from 'react';
import TeamsList from './TeamsList';
import CreateTeam from './CreateTeam';
import {Link, useParams} from 'react-router-dom';
import {Tab} from '@trustle/component-library';
import {useRootStore} from 'src/lib/hooks';
import _ from 'lodash';

enum TeamsTabsE {
  CREATE = 'create',
  LIST = 'list',
}

function TeamsTab() {
  const {org} = useRootStore();
  const routeParams = useParams() as {activeTab?: string};
  const [selectedIndex, setSelectedIndex] = useState<number>(routeParams.activeTab === 'create' ? 0 : 1);

  return (
    <Tab.Group selectedIndex={selectedIndex} onChange={(i: number) => setSelectedIndex(i)}>
      <Tab.List variant="line">
        <Tab as={Link} to={`/admin/teams`} data-testid={TeamsTabsE.LIST} key={TeamsTabsE.LIST}>
          {'All Teams'}
        </Tab>
        <Tab as={Link} to={`/admin/teams/create`} data-testid={TeamsTabsE.CREATE} key={TeamsTabsE.CREATE}>
          {`${!_.isEmpty(org.idpSettings) ? 'Create Team' : 'Create/Add to Team'}`}
        </Tab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel data-testid={TeamsTabsE.LIST} key={TeamsTabsE.LIST}>
          <TeamsList />
        </Tab.Panel>
        <Tab.Panel data-testid={TeamsTabsE.CREATE} key={TeamsTabsE.CREATE}>
          <CreateTeam />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
}

export default TeamsTab;
