import React from 'react';
import {OrgConfigurationT} from '../../../types';
import './AdminNotificationsConfigurationForm.scss';
import {Formik, FormikForm} from 'src/components/design/formik';
import ProvisionMethodToggle from 'src/connectors/components/FormikProvisionMethodToggle';
import {Form, SubmitButton} from 'src/components/design';
import axios from 'axios';
import {useToasts} from 'react-toast-notifications';
import {DurationInput, NumberInput} from '@trustle/component-library';
import {number, object} from 'yup';
import {Field} from 'formik';

type PropsT = {
  orgConfiguration: OrgConfigurationT;
  handleFetchPolicies?: any;
};

function AdminNotificationsConfigurationForm(props: PropsT) {
  const {addToast} = useToasts();
  const {orgConfiguration, handleFetchPolicies} = props;

  const orgNotificationDefaults = {
    maxNotifications: 10,
    periodValueNotifications: 1,
    periodUnitNotifications: 'hour',
  };

  const handleSave = async (values: any) => {
    try {
      await axios.post(`/api/orgs/admin/config-notifications`, {
        enabledThrottlingNotifications: values.enabledThrottlingNotifications,
        maxNotifications: values.maxNotifications,
        periodUnitNotifications: values.periodDuration.durationUnit,
        periodValueNotifications: values.periodDuration.durationValue,
      });
      addToast('Configuration changes have been saved.', {appearance: 'success', autoDismiss: true});
      handleFetchPolicies();
    } catch (err) {
      addToast('Error saving notification configuration. Try modifying the values', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  };

  const initialValues = {
    maxNotifications: orgConfiguration.maxNotifications ?? orgNotificationDefaults.maxNotifications,
    enabledThrottlingNotifications: orgConfiguration.enabledThrottlingNotifications,
    periodDuration: {
      durationUnit: orgConfiguration.periodUnitNotifications ?? orgNotificationDefaults.periodUnitNotifications,
      durationValue: orgConfiguration.periodValueNotifications ?? orgNotificationDefaults.periodValueNotifications,
    },
  };

  return (
    <div className="admin-notif-config">
      <h2 className="mb-3">Throttling</h2>
      <p className="mb-4">Limit the number of notifications members will receive within a configured timeframe.</p>
      <Formik
        key={`adminNotifForm`}
        initialValues={initialValues}
        validationSchema={object().shape({
          periodDuration: object().test('period validations', ({durationUnit, durationValue}, {createError}) => {
            if (!durationValue) {
              return createError({message: 'This field is required'});
            }

            if (durationUnit === 'minute' && (durationValue < 15 || durationValue > 90)) {
              return createError({message: 'Valid values for minutes: between 15 and 90'});
            }

            if (durationUnit === 'hour' && (durationValue < 1 || durationValue > 12)) {
              return createError({message: 'Valid values for hours: between 1 and 12'});
            }

            if (durationUnit === 'day' && (durationValue < 1 || durationValue > 5)) {
              return createError({message: 'Valid values for days: between 1 and 5'});
            }

            return true;
          }),
          maxNotifications: number().test('notifications validations', (value, {parent, createError}) => {
            const {durationUnit} = parent.periodDuration;

            if (!value) {
              return createError({message: 'This value is required'});
            }

            if (durationUnit === 'minute' && (value < 1 || value > 10)) {
              return createError({
                message: 'The number of notifications must be between 1 and 10 for the selected period',
              });
            }

            if (durationUnit === 'hour' && (value < 1 || value > 15)) {
              return createError({
                message: 'The number of notifications must be between 1 and 15 for the selected period',
              });
            }

            if (durationUnit === 'minute' && (value < 1 || value > 20)) {
              return createError({
                message: 'The number of notifications must be between 1 and 20 for the selected period',
              });
            }

            return true;
          }),
        })}
        onSubmit={handleSave}
        enableReinitialize={true}
      >
        {({isSubmitting, isValid, values, dirty}) => {
          return (
            <FormikForm key={`adminNotifForm`}>
              <Form.Row className={'ml-2'}>
                <Form.Label className={'strong mt-1 mr-2'} aria-labelledby="enabledThrottlingNotifications">
                  Active
                </Form.Label>
                <ProvisionMethodToggle name="enabledThrottlingNotifications" />
              </Form.Row>
              {values.enabledThrottlingNotifications && (
                <>
                  <div className="ml-2">
                    <div className="mt-1">
                      <Field
                        className="tr-w-16 whitespace-nowrap tr-mb-2"
                        component={NumberInput}
                        label="Maximum number of notifications"
                        id="maxNotifications"
                        name="maxNotifications"
                        min={1}
                        max={20}
                        maxLength={2}
                        step={1}
                        data-testid="maxNotifications"
                      />
                    </div>
                    <Field
                      className="tr-mb-2"
                      component={DurationInput}
                      name="periodDuration"
                      label="Timeframe"
                      titleMargin={true}
                      required
                      min={1}
                      maxDurationValue={90}
                      includeNoneOption={false}
                      options={[
                        {value: 'minute', label: 'Minutes'},
                        {value: 'hour', label: 'Hours'},
                        {value: 'day', label: 'Days'},
                      ]}
                      maxLength={2}
                    />
                  </div>
                </>
              )}
              <div className="tr-mt-6">
                <SubmitButton disabled={isSubmitting || !isValid || !dirty} label="Save" />
              </div>
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );
}

export default AdminNotificationsConfigurationForm;
