import React, {useState} from 'react';
import _ from 'lodash';
import './PlansPricing.scss';
import {observer} from 'mobx-react';
import {useRootStore} from 'src/lib/hooks';
import {useHistory} from 'react-router-dom';
import ContactModal from './ContactModal';
import planDescriptions, {createFeatureList, pricingTitle} from './PlanDescriptions';
import {Elements} from '@stripe/react-stripe-js';
import PlanDetailsForm from './PlanDetailsForm';
import {Loading} from '@trustle/component-library';
import {FacetIdEnum, PackageNameEnum} from 'src/types';
import PlanSettings from 'src/views/signup/PlanSettings';
import {Button} from '@trustle/component-library';

const CurrentPlan = observer(function CurrentPlanCmp(props: {upgradeTo?: string}) {
  const {upgradeTo} = props;
  const {org} = useRootStore();
  const [selectedPrice, setSelectedPrice] = useState<any>();
  const history = useHistory();
  const [contactOpen, setContactOpen] = useState<boolean>(false);

  if (_.isNil(org)) {
    return <Loading />;
  }

  const isFreePlan = org.facetId === FacetIdEnum.FREE_AWS;
  const plan = org.subscriptionPlan;
  const stripe = org.stripe;

  if (!isFreePlan && !plan) {
    return <Loading />;
  }

  const actualPlanName = upgradeTo ? upgradeTo : isFreePlan ? PackageNameEnum.FREE : plan.name;
  const product = org.getProductByName(actualPlanName);

  const planActions: Record<string, any> = {
    Enterprise: () => {
      setContactOpen(true);
    },
    Team: (product: any) => {
      history.push(`/payments?plan=${product.id}`);
    },
    Basic: (product: any) => {
      history.push(`/payments?plan=${product.id}`);
    },
  };

  const buildActionButton = (altText: string, onClick: any) => {
    return (isActive: any, product: any) => {
      return (
        <Button onClick={_.partial(onClick, product)} disabled={isActive} data-testid={`actionButton-${altText}`}>
          {isActive ? 'Your plan' : altText}
        </Button>
      );
    };
  };
  if (!product) {
    return <Loading />;
  }
  const isActive = (isFreePlan && actualPlanName === PackageNameEnum.FREE) || plan?.id === product.id;
  const planDescription = planDescriptions[actualPlanName]!;
  const emptyFunction = () => {};
  const action = planActions[actualPlanName] ?? emptyFunction;

  return (
    <div className="plans-pricing tr-mt-8">
      <Elements stripe={stripe}>
        <div className="tr-flex">
          <div key={product.id} className={`bg-white tr-p-4 tr-flex-grow ${isActive ? 'active-shadow' : ''}`}>
            <div className="tr-flex flex-column">
              <div>
                <div>
                  <h1 className="grayed-out">{planDescription.name}</h1>
                </div>
                <div className="mb-3 tr-flex justify-content-around align-content-center flex-column">
                  <div className={'tr-flex flex-column justify-content-center align-content-center'}>
                    <div className="tr-flex justify-content-start align-content-center">
                      {pricingTitle(planDescription.name, product)}
                    </div>
                    <div className="text-left body6 grayed-out">{planDescription.subtitle}</div>
                  </div>
                </div>
              </div>
              {!upgradeTo && (
                <div className="text-center mb-4">
                  {buildActionButton(planDescription.actionButtonLabel, action)(isActive, product)}
                </div>
              )}
              <div>
                <div className="body4 grayed-out mb-2">{planDescription.featuresTitle}</div>
                {createFeatureList(planDescription.name, planDescription.features)}
              </div>
            </div>
          </div>
          <div key={`${product.id}-billing`} className="tr-w-2/3 tr-ml-8 checkout">
            {isFreePlan ? (
              <PlanSettings
                selectedPackage={product}
                selectedPrice={selectedPrice}
                setSelectedPrice={setSelectedPrice}
              />
            ) : (
              <PlanDetailsForm plan={product} />
            )}
          </div>
          {contactOpen && (
            <ContactModal
              onHide={() => {
                setContactOpen(false);
              }}
              show={contactOpen}
            />
          )}
        </div>
      </Elements>
    </div>
  );
});

export default CurrentPlan;
