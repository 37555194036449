import React from 'react';
import {Button, Modal} from '@trustle/component-library';
import {User} from '../../../../../stores/domainObjects/User';

type Props = {onConfirm: () => void; onCancel: () => void; user: User};

export function ConfirmDeleteUserModal({onConfirm, onCancel, user}: Props) {
  return (
    <Modal onClose={() => onCancel()} title={'Confirm delete'}>
      <div className="tr-flex tr-flex-col tr-gap-2">
        <p>
          Are you sure? This will delete user <strong>{user.fullname}</strong> and all related data. This action is
          permanent and cannot be undone
        </p>
      </div>
      <div className="tr-flex tr-justify-end">
        <Button variant="secondary" colorVariant="danger" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button variant="primary" colorVariant="danger" onClick={() => onConfirm()}>
          Yes, Delete User
        </Button>
      </div>
    </Modal>
  );
}
