import axios from 'axios';
import React from 'react';
import {useEffect} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import Navigation from 'src/components/navigation/Navigation';
import {logger} from 'src/lib';
import {useToasts} from 'react-toast-notifications';

function OTLTask() {
  const history = useHistory();
  const {addToast} = useToasts();

  const params = useParams();
  const {taskId, action, oneTimeLink} = params as {taskId: string; action: string; oneTimeLink: string};

  useEffect(() => {
    const doAction = async () => {
      try {
        await axios.post(`/api/tasks/${taskId}/${action}`, {
          oneTimeLink,
        });

        addToast(`Successfully performed '${action}' action.`, {appearance: 'success', autoDismiss: true});
      } catch (err: any) {
        logger.error(err);
        addToast(`Error performing '${action}' action. ${err.response.data?.error?.message ?? ''}`, {
          appearance: 'error',
          autoDismiss: false,
        });
      }

      history.push('/tasks');
    };
    void doAction();
  }, [taskId, action, oneTimeLink]);

  return (
    <div>
      <Navigation />
    </div>
  );
}

export default OTLTask;
