import {Accordion, Table} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useEffect, useState} from 'react';
import {Resource} from 'src/stores/domainObjects/Resource';
import {ConnectionServiceE, ImportStateServiceE, ImportStateT, ImportStatus} from 'src/types';
import ImportProgress from './ImportProgress';
import './importStatesHistory.scss';
import {
  ActorCol,
  CreatedCol,
  DetailsCol,
  RetryInCol,
  ServiceCol,
  StatusCol,
} from 'src/components/design/tables/SharedColumnDefinitions/ImportStateColumns';

type ImportStatesHistoryPropsT = {
  resource: Resource;
  services: (ConnectionServiceE | ImportStateServiceE)[];
  limit?: number;
};
const ImportStatesHistory = observer(({resource, services, limit}: ImportStatesHistoryPropsT) => {
  const [importHistory, setImportHistory] = useState<ImportStateT[]>([]);

  const fetchImportHistory = async () => {
    const {importStates = [], jobs = {}} = (await resource.getLatestImportStates(services, limit)) ?? {};
    return importStates.map((is) => ({...is, jobs: jobs[is.id!] ? [jobs[is.id!]] : undefined}));
  };

  const calculateNextUpdateTime = (importHistory: ImportStateT[]): number => {
    const MIN_UPDATE_INTERVAL = 5000; // Minimum interval of 5 seconds

    if (importHistory.some(({status}) => status === ImportStatus.PENDING)) {
      return MIN_UPDATE_INTERVAL;
    } else if (importHistory.some(({status}) => status === ImportStatus.FAILED)) {
      const runAtTimes = _.compact(
        importHistory.filter((ih) => ih.status === ImportStatus.FAILED).map((ih) => ih.jobs?.[0]?.runAt)
      );
      const nextRetry = _.isEmpty(runAtTimes)
        ? MIN_UPDATE_INTERVAL
        : Math.min(...runAtTimes.map((runAt) => Date.parse(runAt)));
      return Math.max(nextRetry - Date.now(), MIN_UPDATE_INTERVAL);
    } else {
      const oldestUpdate = Math.min(...importHistory.map((ih) => Date.parse(ih.created)));
      if (oldestUpdate === undefined) {
        return MIN_UPDATE_INTERVAL;
      }
      if (_.isNil(resource.connector)) {
        return MIN_UPDATE_INTERVAL;
      }
      const {pollDurationUnit, pollDurationValue} = resource.connector;
      const hourInMs = 1000 * 60 * 60;
      const dayInMs = hourInMs * 24;
      const monthInMs = dayInMs * 30;
      const pollDurationInMs =
        (pollDurationUnit === 'month' ? monthInMs : pollDurationUnit === 'day' ? dayInMs : hourInMs) *
        pollDurationValue;

      return Math.max(oldestUpdate + pollDurationInMs - Date.now(), MIN_UPDATE_INTERVAL);
    }
  };

  useEffect(() => {
    let timeoutId: any;
    const scheduleUpdate = async (ms: number) => {
      const importHistory = await fetchImportHistory();
      if (importHistory.length === 0) {
        return;
      }
      const updateIn = calculateNextUpdateTime(importHistory);
      timeoutId = setTimeout(() => scheduleUpdate(updateIn), ms);
      setImportHistory(importHistory);
    };

    void scheduleUpdate(0);
    return () => void clearTimeout(timeoutId);
  }, []);

  return (
    <div className={`import-state-history opacity-${resource.shouldDisableInteractions ? '50' : '100'}`}>
      <div className="">{!_.isEmpty(importHistory) && <ImportProgress progressPerImport={importHistory} />}</div>
      {!_.isEmpty(importHistory) && (
        <Accordion className="tr-mt-[15px]" variant="ternary" content={<span className="body5">See Details</span>}>
          {() => {
            return (
              <Table
                data={importHistory}
                tableKey="importHistory"
                columns={[
                  StatusCol,
                  {...ServiceCol, hidden: _.size(services) === 1},
                  ActorCol,
                  CreatedCol,
                  {
                    ...RetryInCol,
                    hidden: _.size(services) === 1,
                    formatExtraData: {lastImportServiceUsageStatus: resource.connector?.lastImportServiceUsage?.status},
                  },
                  {
                    ...DetailsCol,
                    formatExtraData: {
                      lastImportServiceUsage: resource.connector?.lastImportServiceUsage,
                      lastImportStateError: resource.connector?.lastImport?.state,
                    },
                  },
                ]}
                noDataIndication={<div>No data</div>}
                striped={false}
                wrapperClasses="tr-mt-[20px] rounded-xl border border-black rounded"
                bordered={false}
              />
            );
          }}
        </Accordion>
      )}
    </div>
  );
});

export default ImportStatesHistory;
