import React from 'react';
import {observer} from 'mobx-react';
import './AwsPolicyRisk.scss';
import {useRootStore} from 'src/lib/hooks';
import {Loading} from '@trustle/component-library';
import {Card} from 'src/components/design';
import RiskBadge from 'src/views/Resource/Account/RiskBadge';
import NoData from 'src/components/NoData';
import PolicyRiskTable from './PolicyRiskTable';

type PropsT = {
  permissionId: string;
};

const AwsPolicyRisk = observer(function AwsPolicyRisk(props: PropsT) {
  const {permissionId} = props;
  const {permissionStore} = useRootStore();
  const riskReport = permissionStore.permissionMap[permissionId]?.riskReport;

  if (riskReport === undefined) {
    return <Loading />;
  }

  if (riskReport.isSystemUnavailable) {
    return <NoData>Hmm, we couldn’t seem to find any risk report data yet.</NoData>;
  }

  return (
    <div className="risk-report">
      <Card className="mt-2">
        <Card.Header>
          <span className="body4">Overall risk severity: </span> <RiskBadge value={riskReport.riskScore} />
        </Card.Header>
        <Card.Body className="mt-0 pt-1">
          <Card.Title className="body4">
            <span className="text text-muted body6 mt-0 pt-0">
              This is a real time score and could differ from the score on the resource table. The resource table score
              is generated every 2 hours.
            </span>
          </Card.Title>
          <Card.Text>
            <PolicyRiskTable riskReport={riskReport} />
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
});

export default AwsPolicyRisk;
