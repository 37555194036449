import React, {useState} from 'react';
import {Loading, Table} from '@trustle/component-library';
import {formatDateFromNow, formatFullDate, fuseSearch, useDataLoader} from 'src/lib';
import {ConnectionServiceE, ServiceUsageT} from 'src/types';
import _ from 'lodash';
import HeaderServiceUsage, {AccessTypeE} from './HeaderServiceUsage';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Search} from 'src/components/Search/Search';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {Account} from 'src/stores/domainObjects/Account';
import EmptySection from './EmptySection';
import {observer} from 'mobx-react-lite';
import SimplifiedHeaderServiceUsage from './SimplifiedHeaderServiceUsage';

type AccountServiceUsageProps = {
  userAccount: Account;
  system: Resource;
};

const AccountServiceUsage = observer(function AccountServiceUsage(props: AccountServiceUsageProps) {
  const {userAccount, system} = props;
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  const [query, setQuery] = useState('');
  const [filtersStatus, setFiltersStatus] = useState<string[]>([AccessTypeE.ACCESSED]);
  const [serviceUsage, serviceUsageStatus] = useDataLoader(`/api/accounts/${userAccount.id}/connector_service_usages`);

  if (!serviceUsageStatus.isReady) {
    return <Loading />;
  }
  const [accessed, notAccessed] = _.partition(serviceUsage, (su) => su.eventDate);

  if (accessed.length === 0 && notAccessed.length === 0) {
    return <EmptySection system={system} type="account" />;
  }

  if (
    !system.retrieveUsageDataEnabled ||
    (system.retrieveUsageDataEnabled &&
      system.isServiceUsageStatusImporting &&
      _.size(accessed) === 0 &&
      _.size(notAccessed) === 0)
  ) {
    return <EmptySection system={system} type="account" />;
  }

  const filteredServiceUsage = _.filter(
    serviceUsage,
    (su: ServiceUsageT) =>
      (_.isNil(filters.Service) || (su.csDescription && filters.Service === su.csDescription)) &&
      (_.size(filtersStatus) > 1
        ? true
        : _.includes(filtersStatus, AccessTypeE.ACCESSED)
        ? su.eventDate
        : !su.eventDate)
  );
  const searchedServiceUsages = fuseSearch(query, ['csDescription', 'csIdentifier'], filteredServiceUsage);

  const columns = [
    {
      dataField: 'csDescription',
      hidden: false,
      sort: true,
      text: 'Service',
      headerStyle: {width: '60%'},
      headerAlign: 'center',
      align: 'center',
      style: {width: '60%'},
      formatter: (cell: any) => <div>{cell}</div>,
    },
    {
      dataField: 'eventDate',
      sort: true,
      text: 'Last accessed',
      headerStyle: {width: '40%'},
      style: {width: '40%'},
      headerAlign: 'center',
      align: 'center',
      formatter: (cell: any) => {
        if (cell) {
          return <div>{`${formatDateFromNow(cell)} (${formatFullDate(cell)})`}</div>;
        }
        return <span className="text-muted">Not accessed in the tracking period</span>;
      },
    },
  ];

  return (
    <>
      {system.type === ConnectionServiceE.AWS ? (
        <HeaderServiceUsage
          accessedInTrackingPeriodNum={_.size(accessed)}
          notAccessedInTrackingPeriodNum={_.size(notAccessed)}
          system={system}
          overrideUsage={userAccount.usage}
          onClick={(e) => {
            setFiltersStatus(e);
          }}
        />
      ) : (
        <SimplifiedHeaderServiceUsage system={system} percentage={userAccount.usage} />
      )}
      {!_.isEmpty(searchedServiceUsages) && (
        <div className="tr-mt-2">
          <div className="tr-flex tr-flex-col body3">
            <span>{_.join(_.orderBy(filtersStatus), ' and ')} </span>
            {!showSearchBar && (
              <FontAwesomeIcon
                className="ml-2"
                size="xs"
                color="gray"
                icon={faSearch}
                onClick={() => setShowSearchBar(true)}
              />
            )}
            {showSearchBar && (
              <Search
                placeholder="Search for Service"
                filterOptions={{
                  Service: _.map(serviceUsage, 'csDescription'),
                }}
                editURL={false}
                onChange={(query, filters) => {
                  setQuery(query);
                  setFilters(filters);
                }}
                showCloseBtn={true}
                onClose={() => setShowSearchBar(false)}
              />
            )}
          </div>
        </div>
      )}
      {!_.isEmpty(searchedServiceUsages) && (
        <Table
          data={searchedServiceUsages}
          columns={columns}
          striped={false}
          tableKey={'searchedServiceUsages'}
          wrapperClasses="rounded-xl border border-black rounded"
          bordered={false}
        />
      )}
    </>
  );
});

export default AccountServiceUsage;
