import React from 'react';
import ComplexModal from 'src/components/modals/ComplexModal';
import {AuthenticationPolicyT} from './authenticationTypes';

export const DeleteModal: React.ElementType = (props: {
  policy: AuthenticationPolicyT;
  onConfirm: (policy: AuthenticationPolicyT) => void;
  onDeactivate: (policy: AuthenticationPolicyT) => void;
  onClose: () => void;
}) => {
  const {policy, onConfirm, onDeactivate, onClose} = props;

  return (
    <ComplexModal
      id={'auth-settings-delete-modal'}
      icon={'trashcanCircle'}
      confirmBtnClassName={'btn-danger'}
      confirmButtonTitle={'Delete'}
      cancelButtonTitle={'Cancel'}
      secondaryButtonTitle={policy.active ? 'De-Activate' : null}
      onConfirm={() => onConfirm(policy)}
      onClose={onClose}
      onSecondaryAction={() => onDeactivate(policy)}
      title={'Delete Authentication Method?'}
      content={
        <div className="text-center small">
          <div>
            <b>
              This will remove the authentication method
              <br />
              and cannot be undone.
            </b>
            {policy.active && (
              <>
                <br />
                <br />
                You can also <b>De-activate</b> this by <b>deselecting the active box</b>.<br />
                This will turn-off this method without deleting it from the system.
              </>
            )}
          </div>
        </div>
      }
    />
  );
};
