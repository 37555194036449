import React from 'react';
import './CircleStep.scss';

type PropsT = {
  text?: string;
  textUpperCase?: boolean;
  children?: any;
};

function CircleStatus(props: PropsT) {
  return (
    <span className="circle-step">
      <span className="circle complete body5">{props.children}</span>
    </span>
  );
}

export default CircleStatus;
