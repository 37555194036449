import {Icon, UserAvatar, UserInfo} from '@trustle/component-library';
import axios from 'axios';
import _ from 'lodash';
import {makeAutoObservable, observable, onBecomeObserved, runInAction} from 'mobx';
import React, {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {StatusE} from 'src/components/StatusIndicator';
import {formatUserName, logger} from 'src/lib';
import {
  AccessRecordT,
  AccountAccessType,
  OnboardingModeE,
  ProfileScreenContext,
  SourceOfTruthE,
  UserType,
  ViewerRole,
} from 'src/types';
import RootStore from '..';
import {TrustleAnalyticEventT, TrustleEventT, UserResponseT, UserStatus, UserTeamResponseT} from '../usersStore';
import {AccessRecord} from './AccessRecord';
import {Team} from './Team';
import {Permission} from './Permission';
import {BadgeVariantEnum} from '@trustle/component-library/dist/types';

export class User {
  id!: string;
  oid?: string;
  created!: string;
  firstname!: string;
  loginMethod?: string;
  lastname!: string;
  email!: string;
  isOrgOwner!: boolean;
  tombstone!: boolean | null;
  deleted!: boolean | null;
  managerEmail?: string;
  managerUid?: string;
  type!: UserType;
  hasPassword!: boolean;
  confirmed!: boolean;
  lastLogin!: string;
  terminated!: boolean;
  allowLogin!: boolean;
  expiresTs?: string;
  startDate?: string;
  createdByEmail?: string;
  title?: string | null;
  systems?: number;
  permissions?: number;
  onboardingMode?: OnboardingModeE;
  selected!: boolean;
  isHrAdmin?: boolean = undefined;
  isSecurityAdmin?: boolean = undefined;
  accessIds?: string[] = undefined;
  sourceOfTruth?: SourceOfTruthE;
  remoteRole?: string;
  department?: string;
  canSwitchSoT?: boolean;
  invitedAt?: string = undefined;
  accessCount?: number = 0;
  permissionCount?: number = 0;
  systemList: string[] = [];
  dismissedAlerts?: {keys: string[]};

  // ↓ lazily loaded properties ↓
  history: TrustleEventT[] = [];
  loginHistory: TrustleAnalyticEventT[] = [];
  errorLoadingHistoryData?: any;

  loadingTeams: boolean = false;
  errorLoadingTeams?: any = undefined;
  teamNames: string[] = [];
  loadingHistory: boolean = false;
  loadingLoginHistory: boolean = false;
  validity?: {
    valid: boolean;
    invalidAttributes: string[];
  };

  constructor(private rootStore: RootStore, attributes: Partial<UserResponseT>) {
    Object.assign(this, attributes);
    makeAutoObservable(this, {id: false});

    onBecomeObserved(this, 'history', async () => {
      void this.getHistory();
    });

    onBecomeObserved(this, 'loginHistory', async () => {
      void this.getLoginHistory();
    });

    const loadTeams = async () => {
      runInAction(() => (this.loadingTeams = true));
      try {
        const res = await axios.get<UserTeamResponseT[]>(`/api/users/${this.id}/teams`);
        const [managedTeams, memberTeams] = _.partition(res.data, 'managed');
        runInAction(() => {
          this.loadingTeams = false;
        });
        return {managedTeams, memberTeams};
      } catch (err) {
        runInAction(() => {
          this.errorLoadingTeams = err;
          this.loadingTeams = false;
        });
        return {managedTeams: [], memberTeams: []};
      }
    };

    onBecomeObserved(this, 'teamNames', async () => {
      const {managedTeams, memberTeams} = await loadTeams();
      runInAction(() => {
        this.teamNames = [...managedTeams.map((team) => team.name), ...memberTeams.map((team) => team.name)];
      });
    });

    onBecomeObserved(this, 'accessIds', async () => {
      await this.loadAccesses();
    });
  }

  async getHistory() {
    try {
      const res = await axios.get(`/api/users/${this.id}/history`);
      runInAction(() => (this.history = res.data));
    } catch (err) {
      runInAction(() => (this.errorLoadingHistoryData = err));
    }
  }

  async getLoginHistory() {
    let data: any;
    const PAGE_SIZE: number = 200;
    let loaded = 0;
    try {
      do {
        const res = await axios.get<any[]>(`/api/users/${this.id}/login_history?limit=${PAGE_SIZE}&skip=${loaded}`);
        data = res.data;
        loaded += _.size(res.data);

        runInAction(() => {
          this.loginHistory = _.concat(this.loginHistory || [], data);
        });
      } while (_.size(data) === PAGE_SIZE);
    } catch (e) {
      this.errorLoadingHistoryData = e;
    }

    return this.loginHistory;
  }

  async loadAccesses(includeDeleted: boolean = true) {
    await axios.get<AccessRecordT[]>(`/api/users/${this.id}/accesses?includeDeleted=${includeDeleted}`).then((res) => {
      runInAction(() => {
        res.data.forEach((access: AccessRecordT) => this.rootStore.accessStore.updateAccessFromServer(access));
        this.accessIds = res.data.map((a: AccessRecordT) => a.id);
      });
    });
  }

  async toggleDisabled() {
    try {
      await axios.post(`/api/users/admin/${this.id}/disable`, {disabled: !this.allowLogin});
      runInAction(() => (this.allowLogin = !this.allowLogin));
    } catch (err: any) {
      this.rootStore.usersStore.error = err;
    }
  }

  async toggleOwnerRole() {
    try {
      await axios.post(`/api/orgs/admin/${this.id}/owner`, {isOwner: !this.isOrgOwner});
      runInAction(() => (this.isOrgOwner = !this.isOrgOwner));
    } catch (err: any) {
      this.rootStore.usersStore.error = err;
    }
  }

  async verifyUser() {
    try {
      await axios.post(`/api/users/admin/${this.id}/verifyUser`);
      runInAction(() => {
        const isCustomerOrSystem = _.includes([UserType.customer, UserType.system], this.type);

        this.allowLogin = !isCustomerOrSystem;
        this.expiresTs = undefined;
        this.confirmed = isCustomerOrSystem || this.confirmed;
      });
    } catch (err: any) {
      this.rootStore.usersStore.error = err;
    }
  }

  async offboard() {
    try {
      await axios.post(`/api/users/admin/${this.id}/offboard`);
      runInAction(() => {
        this.allowLogin = false;
        this.tombstone = true;
      });
    } catch (err: any) {
      this.rootStore.usersStore.error = err;
    }
  }

  async resendInvite() {
    try {
      await axios.post(`/api/users/admin/${this.id}/resend-invite`);
      this.rootStore.toast.success('Notification successfully sent');
    } catch (err: any) {
      const message = _.get(err, 'response.data.error.message');
      this.rootStore.toast.error(`Notification couldn't be sent. ${message ?? ''}`);
      this.rootStore.usersStore.error = err;
    }
  }

  async mapAccount(aid: string, account: string, accountType: AccountAccessType = AccountAccessType.PERSONAL) {
    try {
      await axios.post(`/api/accounts/${aid}`, {account, accountType, uid: this.id});
      runInAction(() => {
        this.rootStore.accountsStore.accountsMap[aid].uid = this.id;
        this.rootStore.accountsStore.accountsMap[aid].accountType = accountType;
      });
    } catch (err) {
      const message = `Could not link account ${account}: ${_.get(
        err,
        'response.data.error.message',
        'Something went wrong'
      )}`;
      this.rootStore.toast.add(message, {appearance: 'error', autoDismiss: false});
    }
  }

  async setupProfile(values: {firstName: string; lastName: string; currentPassword: string; newPassword: string}) {
    if (this.id !== this.rootStore.currentUser?.id) {
      await axios.post(`/api/setup/profile`, values);
      runInAction(() => {
        this.firstname = values.firstName;
        this.lastname = values.lastName;
      });
    }
  }

  async switchAuthority() {
    try {
      const toggleSoT = this.sourceOfTruth === SourceOfTruthE.IDP ? SourceOfTruthE.TRUSTLE : SourceOfTruthE.IDP;
      await this.update({sourceOfTruth: toggleSoT});

      this.rootStore.toast.add('Authority successfully switched', {appearance: 'success', autoDismiss: true});
    } catch (err) {
      this.rootStore.toast.add('Authority switch failed', {appearance: 'error', autoDismiss: true});
    }
  }

  async update<
    T = Partial<
      Pick<
        User,
        'firstname' | 'lastname' | 'email' | 'allowLogin' | 'type' | 'managerUid' | 'sourceOfTruth' | 'isOrgOwner'
      >
    >
  >(values: T) {
    try {
      const {isOrgOwner, ...rest} = values as T & {isOrgOwner: boolean};

      if (rest || this.isOrgOwner !== isOrgOwner) {
        if (rest) {
          const res = await axios.patch<T>(`/api/users/${this.id}`, rest);
          runInAction(() => Object.assign(this, res.data));
        }
        if (this.isOrgOwner !== isOrgOwner) {
          void this.toggleOwnerRole();
        }
        this.rootStore.toast.success('User successfully updated');
      }
    } catch (err: any) {
      logger.error(`Error updating user. ${JSON.stringify(err?.response?.data)}`);
      const message = `Error updating user. ${_.get(err, 'response.data.error.message')}`;
      this.rootStore.toast.error(message);
    }
  }

  get manager(): User | undefined {
    return this.rootStore.usersStore.usersMap[this.managerUid!];
  }

  get createdByUser(): User | undefined {
    return this.rootStore.usersStore.users.find((user) => user.email === this.createdByEmail);
  }

  get status(): UserStatus {
    if (this.deleted) {
      return UserStatus.DELETED;
    }
    if (this.tombstone || this.terminated) {
      return UserStatus.OFFBOARDED;
    }

    //This is deprecated, but will leave.
    if (!_.isEmpty(this.expiresTs)) {
      return UserStatus.PENDING_APPROVAL;
    }

    if (!this.allowLogin && !_.includes([UserType.customer, UserType.system], this.type)) {
      return UserStatus.DISABLED;
    }

    if (this.lastLogin || this.confirmed) {
      return UserStatus.ACTIVE;
    } else {
      return UserStatus.PENDING_APPROVAL;
    }
  }

  get isValidForAuthorityMapping(): boolean {
    return !this.validity || this.validity.valid;
  }

  get statusIcon(): {icon: StatusE; text: string} {
    const status = this.status;

    return [UserStatus.OFFBOARDED, UserStatus.DELETED].includes(status)
      ? {icon: StatusE.ERROR, text: 'Deleted'}
      : status === UserStatus.PENDING_APPROVAL
      ? {icon: StatusE.WARNING, text: 'Pending'}
      : status === UserStatus.DISABLED
      ? {icon: StatusE.DISABLED, text: 'Disabled'}
      : {icon: StatusE.SUCCESS, text: 'Active'};
  }

  get statusVariant(): {variant: BadgeVariantEnum; text: string} {
    const status = this.status;

    return [UserStatus.OFFBOARDED, UserStatus.DELETED].includes(status)
      ? {variant: 'danger', text: 'Offboarded'}
      : status === UserStatus.PENDING_APPROVAL
      ? {variant: 'warning', text: 'Inactive'}
      : status === UserStatus.DISABLED
      ? {variant: 'dark', text: 'Disabled'}
      : {variant: 'success', text: 'Active'};
  }

  get label(): ReactNode {
    return this.email;
  }

  get value(): string {
    return this.id;
  }

  get authorityLabel() {
    if (_.defaultTo(this.sourceOfTruth, SourceOfTruthE.TRUSTLE) === SourceOfTruthE.TRUSTLE) {
      return 'Trustle';
    }
    return this.rootStore.org.orgUsersAuthorityLabel;
  }

  get TypeIcon() {
    switch (this.type) {
      case UserType.employee:
        return <Icon type="employeeUser" size="sm" />;
      case UserType.contractor:
        return <Icon type="contractorUser" size="sm" />;
      case UserType.system:
        return <Icon type="systemUser" size="sm" />;
      case UserType.customer:
        return <Icon type="customerUser" size="sm" />;
      default:
        return () => <></>;
    }
  }

  get typeLabel(): ReactNode {
    return (
      <div className="tr-flex align-items-center">
        {this.TypeIcon}
        {_.upperFirst(this.type)}
      </div>
    );
  }

  get avatar(): ReactNode {
    return observable(<UserAvatar text={formatUserName(this)} size="sm" />);
  }

  get nameLabel(): ReactNode {
    return observable(
      <UserInfo type={this.type} isOrgOwner={this.isOrgOwner} className="tr-text-trustle-link">
        <Link className="" to={`/users/${this.id}/current`}>
          {formatUserName(this)}
        </Link>
      </UserInfo>
    );
  }

  get managedTeams(): Team[] {
    return _.filter(this.rootStore.teamStore.teams, (team) => {
      return team.managerIds.includes(this.id);
    });
  }

  get memberTeams(): Team[] {
    return _.filter(this.rootStore.teamStore.teams, (team) => {
      return team.memberIds.includes(this.id);
    });
  }

  get viewerRole(): ViewerRole {
    return this.isOrgOwner
      ? ViewerRole.GLOBAL_ADMIN
      : _.isEmpty(this?.managerEmail)
      ? ViewerRole.PRIVILEGED_USER
      : ViewerRole.REGULAR_USER;
  }

  get accesses(): AccessRecord[] {
    return this.accessIds ? this.accessIds.map((id) => this.rootStore.accessStore.accessMap[id]) : [];
  }

  /**
   * return permissions for a user that prevent the offboarding because they have the deprovision mode in off
   * NR: Validations like this needs to happen in backend.
   */
  get permissionsPreventOffboarding(): Permission[] {
    if (_.size(this.accesses) <= 0) {
      return [];
    }
    const permissions = [];
    for (const access of this.accesses) {
      if (access.isProvisioned && access.forPermission?.deprovisionModeOff) {
        permissions.push(access.forPermission);
      }
    }
    return permissions;
  }

  get nonPlaceholderAccesses(): AccessRecord[] {
    return this.accesses?.filter((a) => !a.forAccount?.placeholder);
  }

  /**
   * return the list of resources names for a user
   */
  get resourcesNames(): string[] {
    return _.uniq(this.nonPlaceholderAccesses?.map((a) => a.forSystem?.name) ?? []);
  }
  get resourcesTypes(): any[] {
    return _.uniq(this.nonPlaceholderAccesses?.map((a) => a.forSystem?.type) ?? []);
  }

  get fullname(): string {
    return formatUserName(this);
  }

  canAccess(context: ProfileScreenContext, targetUid: string): boolean {
    switch (this.viewerRole) {
      case ViewerRole.GLOBAL_ADMIN:
      case ViewerRole.PRIVILEGED_USER:
        return true;

      case ViewerRole.REGULAR_USER:
        return targetUid === this.id || context !== ProfileScreenContext.PROFILE_INFO;

      default:
        return false;
    }
  }

  canEdit(context: ProfileScreenContext, targetUid: string | undefined): boolean {
    const user = this.rootStore.currentUser;

    if (_.isNil(user)) {
      return false;
    }

    switch (this.viewerRole) {
      case ViewerRole.GLOBAL_ADMIN:
      case ViewerRole.PRIVILEGED_USER:
        return context !== ProfileScreenContext.LOGIN_INFO || targetUid === user.id;

      case ViewerRole.REGULAR_USER:
        return (
          (targetUid === user.id && _.includes([ProfileScreenContext.PROFILE_INFO], context)) ||
          !_.includes([ProfileScreenContext.USER_PERMISSIONS, ProfileScreenContext.PROFILE_INFO], context)
        );

      default:
        return false;
    }
  }

  async disableAlert(key: string) {
    try {
      await axios.post(`/api/users/${this.id}/dismiss-alert`, {
        alertKey: key,
      });
      let keys: string[] = [];
      if (this.dismissedAlerts) {
        keys = [...this.dismissedAlerts!.keys, key];
        runInAction(() => {
          this.dismissedAlerts = {keys};
        });
      }
    } catch (error) {
      this.rootStore.toast.error('The message could not be discarded due to an error');
    }
  }
}
