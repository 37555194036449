import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {ConnectionInfoT, GithubAuthenticationMethodE, ImportStatus, ResourceT} from 'src/types';
import {Card} from 'src/components/design';
import '../common/editConnection/EditConnectionAWS.scss';
import GithubAppSetup from './GithubAppSetup';
import {Button, Loading} from '@trustle/component-library';
import ConnectorStatus from 'src/components/StatusIndicator';
import FontAwesomeIcon, {faCircleNotch} from 'src/components/FontAwesomeIcon';
import {useConnectionCredentials} from '../../lib/hooks';

export default function EditConnectionGitHub(props: {resource: ResourceT}) {
  const {resource} = props;
  const [connectionInfo, setConnectionInfo] = useState<ConnectionInfoT>();
  const lastImport = resource.connector?.lastImport;
  const [connectorStatus] = useState<string | undefined>(lastImport?.status);
  const [testConnectionResult, setTestConnectionResult] = useState<string | null>(null);
  const [showUpdateCredentials, setShowUpdateCredentials] = useState<boolean>(false);
  const [testConnectionInProgress, setTestConnectionInProgress] = useState<boolean>(false);
  const {testConnection: testConnectionAPI} = useConnectionCredentials();

  async function loadConnection() {
    const connectionResponse = await axios.get(`/api/connect/${resource.connectionId}`);
    setConnectionInfo(connectionResponse.data);
  }
  async function finishUpdateCredentials() {
    await loadConnection();
    setShowUpdateCredentials(false);
  }

  useEffect(() => {
    void loadConnection();
  }, [resource.connectionId]);

  if (!connectionInfo) {
    return <Loading />;
  }

  const {connection} = connectionInfo;
  const {serviceFriendlyName} = connection;

  async function testConnection() {
    setTestConnectionInProgress(true);
    const {data: responseData} = await testConnectionAPI(connection.id, {
      orgName: connection.serviceFriendlyName,
      authenticationMethod: connection.authenticationMethod,
    });
    setTestConnectionInProgress(false);

    if (responseData.error) {
      setTestConnectionResult(responseData.error.message);
      return;
    }

    setTestConnectionResult('');
  }

  function renderTestConnection() {
    const testConnectionText = (() => {
      if (testConnectionInProgress) {
        return (
          <div className="body6 text-right">
            <FontAwesomeIcon icon={faCircleNotch} spin size="sm" /> Connecting
          </div>
        );
      }
      if (testConnectionResult === '') {
        return <span className="text-success body6">Connected Successfully!</span>;
      } else if (!_.isEmpty(testConnectionResult)) {
        return <span className="text-danger body6">{testConnectionResult}</span>;
      }

      return null;
    })();

    return <div className="text-right body6">{testConnectionText}</div>;
  }

  const authenticationMethodDisplay = {
    [GithubAuthenticationMethodE.APP_READ]: 'Read-Only GitHub App',
    [GithubAuthenticationMethodE.APP_WRITE]: 'Automated GitHub App',
  }[(connection.authenticationMethod as GithubAuthenticationMethodE) || GithubAuthenticationMethodE.APP_READ];

  return (
    <div className="github-connector">
      <div className="tr-flex tr-flex-col tr-max-w-3xl">
        <div className="tr-my-2 no-padding tr-flex tr-flex-col">
          <h3>Authentication</h3>
          <div className="tr-flex tr-flex-col">
            <div>
              {connectorStatus === ImportStatus.PENDING && (
                <span className="body6 badge badge-warning">Import in progress...</span>
              )}
              {renderTestConnection()}
            </div>
            <Card body>
              <div className="tr-flex align-items-top tr-justify-between">
                <div className="tr-flex tr-items-center">
                  <ConnectorStatus className="tr-mr-3" status={connectorStatus}></ConnectorStatus>
                  <div>
                    <div data-testid="serviceFriendlyName" className="body4 ellipsis-wrap">
                      {serviceFriendlyName}
                    </div>
                    <div className="body5-normal">{authenticationMethodDisplay}</div>
                  </div>
                </div>
                <div>
                  <Button
                    variant="secondary"
                    label="Update"
                    selected={showUpdateCredentials}
                    onClick={() => {
                      setShowUpdateCredentials(!showUpdateCredentials);
                    }}
                  />
                  <Button variant="secondary" label="Test" onClick={() => testConnection()} />
                </div>
              </div>
            </Card>
            {showUpdateCredentials && (
              <GithubAppSetup
                orgName={serviceFriendlyName!}
                connectionInfo={connectionInfo}
                onComplete={finishUpdateCredentials}
                editMode={true}
                completeLabel={'Update'}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
