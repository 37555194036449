import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {ConnectionInfoT, GAppsAuthenticationMethodE, ImportStatus, ResourceT, TestConnectionResponseT} from 'src/types';
import {Card} from 'src/components/design';
import '../common/editConnection/EditConnectionAWS.scss';
import {Button, Loading} from '@trustle/component-library';
import ConnectorStatus from 'src/components/StatusIndicator';
import GAppsAppSetup from '../gapps/GAppsAppSetup';
import FontAwesomeIcon, {faCircleNotch} from 'src/components/FontAwesomeIcon';
import {useQueryParams} from 'src/lib';
import {useConnectionCredentials} from '../../lib/hooks';

export default function EditConnectionGApps(props: {resource: ResourceT}) {
  const {resource} = props;
  const [connectionInfo, setConnectionInfo] = useState<ConnectionInfoT>();
  const lastImport = resource.connector?.lastImport;
  const [showUpdateCredentials, setShowUpdateCredentials] = useState<boolean>(false);
  const [connectorStatus] = useState<string | undefined>(lastImport?.status);
  const [testConnectionResult, setTestConnectionResult] = useState<TestConnectionResponseT>();
  const [testConnectionInProgress, setTestConnectionInProgress] = useState<boolean>(false);
  const [actor, error] = useQueryParams(['actor', 'error']);
  const updated = !_.isEmpty(actor) && !error;
  const {testConnection: testConnectionAPI} = useConnectionCredentials();

  async function loadConnection() {
    const connectionResponse = await axios.get(`/api/connect/${resource.connectionId}`);
    setConnectionInfo(connectionResponse.data);
  }

  async function finishUpdateCredentials() {
    await loadConnection();
    setShowUpdateCredentials(false);
  }

  useEffect(() => {
    void loadConnection();
  }, [resource.connectionId]);

  if (!connectionInfo) {
    return <Loading />;
  }

  const {connection} = connectionInfo;
  const {serviceFriendlyName} = connection;

  async function testConnection() {
    setTestConnectionInProgress(true);
    const {data: responseData} = await testConnectionAPI(connection.id, {
      connectionId: connection.id,
      authenticationMethod: connection.authenticationMethod,
    });
    setTestConnectionInProgress(false);
    await axios.post(`/api/connect/${resource.connectionId}/start_import`);
    setTestConnectionResult(responseData);
  }

  function renderTestConnection() {
    const testConnectionText = (() => {
      if (testConnectionInProgress) {
        return (
          <div className="body6 text-right">
            <FontAwesomeIcon icon={faCircleNotch} spin size="sm" /> Connecting
          </div>
        );
      }

      if (testConnectionResult && testConnectionResult.ok) {
        return (
          <span className="text-success body6">
            {testConnectionResult.message} (Authorized by: {testConnectionResult.authorizedBy})
          </span>
        );
      }
      if (testConnectionResult && _.get(testConnectionResult, 'error.message')) {
        return <span className="text-danger body6">{testConnectionResult.error.message}</span>;
      }

      if (updated) {
        return <span className="text-success body6">Successfully updated (Authorized by: {actor})</span>;
      }

      if (error) {
        return <span className="text-danger body6">Update Failed ({_.startCase(error || '')})</span>;
      }

      return null;
    })();

    return <div className="text-right body6">{testConnectionText}</div>;
  }

  const authenticationMethodDisplay = {
    [GAppsAuthenticationMethodE.APP_READ]: 'Read-Only Google Workspace App',
    [GAppsAuthenticationMethodE.APP_WRITE]: 'Automated Google Workspace App',
  }[(connection.authenticationMethod as GAppsAuthenticationMethodE) || GAppsAuthenticationMethodE.APP_READ];

  return (
    <div className="github-connector">
      <div className="tr-flex tr-flex-col">
        <div className="tr-my-2 no-padding">
          <div className="tr-flex tr-flex-col tr-w-8/12">
            <div>
              {connectorStatus === ImportStatus.PENDING && (
                <span className="body6 badge badge-warning">Import in progress...</span>
              )}
              {renderTestConnection()}
            </div>
            <Card body>
              <div className="tr-flex align-items-top tr-justify-between">
                <div className="tr-flex tr-items-center">
                  <ConnectorStatus className="tr-mr-3" status={connectorStatus}></ConnectorStatus>
                  <div>
                    <div className="body4">{serviceFriendlyName}</div>
                    <div className="body5-normal">{authenticationMethodDisplay}</div>
                  </div>
                </div>
                <div>
                  <Button
                    variant="secondary"
                    label="Update"
                    selected={showUpdateCredentials}
                    onClick={() => {
                      setShowUpdateCredentials(!showUpdateCredentials);
                    }}
                  />
                  <Button variant="secondary" label="Test" onClick={() => testConnection()} />
                </div>
              </div>
            </Card>
            {showUpdateCredentials && (
              <GAppsAppSetup connectionInfo={connectionInfo} onComplete={finishUpdateCredentials} editMode={true} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
