import _ from 'lodash';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Permission} from 'src/stores/domainObjects/Permission';
import cloudflare from './schemas/cloudflare';
import gapps from './schemas/gapps';
import jira from './schemas/jira';
import gcp from './schemas/gcp';
import github from './schemas/github';
import aws from './schemas/aws';
import aws_identity_center from './schemas/aws_identity_center';

import {ConnectorSchema, templatesDefault} from './schemas/default';
import {
  PageTemplateName,
  ComponentTemplateName,
  ViewEntities,
  AccountTemplates,
  ResourceIntegrationEntityView,
  ResourceCategoryEnumExtended,
  PermissionIntegrationEntityView,
} from './common/types';

export type TemplateSchema = {
  [PageTemplateName.RESOURCES]: {
    // TOdo: This should be extended
    [ComponentTemplateName.TREE]: ViewEntities;
  };
  [PageTemplateName.ACCOUNTS]: {
    [ComponentTemplateName.TABLES]: AccountTemplates;
  };
};

const manifests: _.Dictionary<ConnectorSchema> = {aws, cloudflare, gcp, gapps, jira, github, aws_identity_center};

export function getResourcesTemplate(templateName: string): ViewEntities {
  const connectorSchema: ConnectorSchema | undefined = _.find(
    manifests,
    (manifest) => templateName === manifest.service
  );

  //Find all templates defined in manifest, if not, use defaults. We need to test this in the future
  const templates: TemplateSchema = connectorSchema
    ? _.merge(templatesDefault, connectorSchema?.templates)
    : templatesDefault;

  const resourcePage = templates[PageTemplateName.RESOURCES];
  return resourcePage[ComponentTemplateName.TREE] as ViewEntities;
}
export function getAccountsTemplate(templateName: string): AccountTemplates {
  const connectorSchema: ConnectorSchema | undefined = _.find(
    manifests,
    (manifest) => templateName === manifest.service
  );

  //Find all templates defined in manifest, if not, use defaults. We need to test this in the future
  const templates: TemplateSchema = connectorSchema
    ? _.merge(templatesDefault, connectorSchema?.templates)
    : templatesDefault;

  const accountsTemplates = templates[PageTemplateName.ACCOUNTS];

  return accountsTemplates[ComponentTemplateName.TABLES] as AccountTemplates;
}

export function getResourceTemplateComponent(resource: Resource): Required<ResourceIntegrationEntityView> {
  //Find all templates defined in manifest, if not, use defaults. We need to test this in the future

  const connectorSchema: ConnectorSchema | undefined = _.find(
    manifests,
    (manifest) => resource.type === manifest.service
  );

  const defaultResourceTemplates = templatesDefault.resources.tree.Resource;
  //If its not finding scheman then just use  the default
  if (!connectorSchema || !connectorSchema.templates) {
    return defaultResourceTemplates.default as Required<ResourceIntegrationEntityView>;
  }

  const resourcePage = connectorSchema.templates[PageTemplateName.RESOURCES];
  const connectorTemplate = resourcePage[ComponentTemplateName.TREE] as ViewEntities;

  const resourceTemplates = connectorTemplate!.Resource;

  const category = resource && resource.category ? resource.category : ResourceCategoryEnumExtended.DEFAULT;

  const availableTemplates = Object.assign(
    {...defaultResourceTemplates.default},
    {...resourceTemplates.default},
    {...resourceTemplates[category]}
  );

  return availableTemplates as Required<ResourceIntegrationEntityView>;
}

export function getPermissionTemplateComponent(
  template: ViewEntities,
  permission?: Permission
): Required<PermissionIntegrationEntityView> {
  const resourceTemplates = template!.Permission;
  const defaultResourceTemplates = templatesDefault.resources.tree.Permission;
  const category =
    permission && permission.rootResource.category
      ? permission.rootResource.category
      : ResourceCategoryEnumExtended.DEFAULT;

  const availableTemplates = _.merge(
    defaultResourceTemplates.default as Required<PermissionIntegrationEntityView>,
    resourceTemplates.default,
    resourceTemplates[category]
  );

  return availableTemplates;
}

export default manifests;
