import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {Icon} from '@trustle/component-library';
import _ from 'lodash';
import {Resource} from 'src/stores/domainObjects/Resource';
import LinkedResources from '../LinkedResources';
import LockedOrProvision from '../LockedOrProvision';
import {Permission} from 'src/stores/domainObjects/Permission';

/* We might rename this to principals*/
export const ReferencingAccounts = observer(({resource}: {resource: Resource}) => {
  const [relatedReposVisible, setRelatedReposVisible] = useState<boolean>(false);

  const referencingResources = resource.referencingResources;

  return (
    <div
      className="ml-2 mr-1 tr-flex tr-justify-center tr-items-center items-center"
      data-testid="linkedrepo"
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        setRelatedReposVisible(true);
      }}
    >
      <Icon type="repositories" title="Linked resources" data-testid="linkedrepoicon" />
      <div className="tr-text-trustle-primary body6 tr-m-1">{_.size(referencingResources)}</div>
      <LinkedResources
        resource={resource}
        referencingResources={referencingResources}
        visible={relatedReposVisible}
        onClose={() => {
          setRelatedReposVisible(false);
        }}
      />
    </div>
  );
});

export const HiddenOrLocked = observer(
  ({target, accessMode}: {accessMode?: boolean; target: Resource | Permission}) => {
    if (!target.userIsOwner || accessMode) {
      return <div className="ml-1 mr-1"></div>;
    }

    return (
      <>
        <div className="ml-1 mr-1 tr-flex tr-justify-center tr-items-center">
          {target.calculatedHidden.value ? (
            <Icon type="hidden" data-testid="hiddenicon" title="This resource is hidden" />
          ) : (
            <Icon type="visible" data-testid="visibleicon" title="This resource is visible" size="sm" />
          )}
        </div>
        <LockedOrProvision target={target} />
      </>
    );
  }
);
