import {Button, Tab, Table} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {fuseSearch} from 'src/lib';
import {Loading} from '@trustle/component-library';
import {Search} from '../Search/Search';
import {CSVContent, getColumns} from './helper';

export enum TabAction {
  ADDITIONS = 'new',
  UPDATES = 'updates',
  DELETIONS = 'deletes',
  UNCHANGED = 'unchanged',
}

type PropsT = {
  data: _.Dictionary<any>;
  onClose: any;
  onConfirm: any;
  syncInProgress: boolean;
  filename: string;
  csvContent: CSVContent;
};

export const CSVProcessedRows = observer((props: PropsT) => {
  const {onConfirm, onClose, syncInProgress, filename, data, csvContent} = props;
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [query, setQuery] = useState('');

  const {additions = [], updates = [], deletions = [], unchanged = []} = data || {};

  const filter = (data: any[]) => {
    return fuseSearch(query, ['email', 'firstName', 'lastName', 'name'], data);
  };

  const candidatesCount = _.size([...additions, ...updates, ...deletions]);
  const tabData =
    selectedIndex === 0 ? additions : selectedIndex === 1 ? updates : selectedIndex === 2 ? deletions : unchanged;
  const filteredData = filter(tabData);
  const sortedData = _.orderBy(filteredData, ['ignored', 'email'], ['asc', 'asc']);

  const tabCount = (data: any[]) => {
    return _.isEmpty(query) ? _.size(data) : `${_.size(filter(data))} of ${_.size(data)}`;
  };

  const disableDeletionFor =
    csvContent === CSVContent.ACCOUNT
      ? {accounts: false}
      : csvContent === CSVContent.RESOURCE_PERMISSION
      ? {resource: false, permissions: false}
      : csvContent === CSVContent.ACCESS_RECORD
      ? {accesses: false}
      : undefined;

  const resetState = () => {
    setSelectedIndex(0);
    setQuery('');
  };

  return (
    <div>
      <div>
        <div className="tr-flex tr-mb-4">
          <div className="tr-ml-1 tr-mt-3 body6">
            <strong>{`${candidatesCount} rows `}</strong>
            <span>{'detected on the uploaded file: '}</span>
            <strong>{filename}</strong>
            <span className="tr-mt-3 body6">
              <span className="tr-mt-3 body6">
                <span className="tr-mx-3">{' >> '}</span>
                <strong>{_.size(deletions)}</strong>
                <span>{' rows to be deleted'}</span>
              </span>
            </span>
          </div>
        </div>
      </div>

      <div className="tr-mt-4">
        <Search placeholder="Search" filterOptions={{}} onChange={(query) => setQuery(query)} />
      </div>

      <div className="tr-mt-4">
        <Tab.Group selectedIndex={selectedIndex} onChange={(i: number) => setSelectedIndex(i)}>
          <Tab.List className="border-bottom">
            <Tab key={TabAction.ADDITIONS}>{`${_.upperFirst(TabAction.ADDITIONS)} (${tabCount(additions)})`}</Tab>
            <Tab key={TabAction.UPDATES}>{`${_.upperFirst(TabAction.UPDATES)} (${tabCount(updates)})`}</Tab>
            <Tab key={TabAction.DELETIONS}>{`${
              csvContent === CSVContent.ACCOUNT ? 'Offboarded' : _.upperFirst(TabAction.DELETIONS)
            } (${tabCount(deletions)})`}</Tab>
            <Tab key={TabAction.UNCHANGED}>{`${_.upperFirst(TabAction.UNCHANGED)} (${tabCount(unchanged)})`}</Tab>
          </Tab.List>
          <Tab.Panels>
            <Table
              columns={getColumns(csvContent, selectedIndex)}
              data={sortedData}
              tableKey={`procesedrows${selectedIndex}`}
              id={`procesedrows${selectedIndex}`}
              key={`procesedrows${selectedIndex}`}
              striped={false}
              wrapperClasses="tr-rounded-xl tr-border border-black tr-rounded"
              bordered={false}
            />
          </Tab.Panels>
        </Tab.Group>
        <div>
          <div className="tr-flex tr-flex-row-reverse">
            <Button
              disabled={_.isEmpty([...additions, ...updates, ...deletions])}
              onClick={() => onConfirm(disableDeletionFor)}
            >
              {syncInProgress && <Loading>Processing...</Loading>}
              {!syncInProgress && <div>{`Confirm (${candidatesCount})`}</div>}
            </Button>
            <Button variant="secondary" onClick={() => {
              resetState();
              onClose();
            }}>
              Cancel
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
});
