import React from 'react';
import _ from 'lodash';
import {useRootStore} from 'src/lib/hooks';
import {Task} from 'src/stores/domainObjects/Task';
import {GenericAccessRequestDisplay} from '../displays/GenericAccessRequestDisplay';
import {AccessRequestType, TicketType} from 'src/types';
import {TaskDisplay} from '../displays/TaskDisplay';
import {observer} from 'mobx-react';

import {Alert} from '@trustle/component-library';

type TaskCardPropsT = {
  accessRequest: Task;
};

type PageContentPropsT = {type?: string; ticketType?: TicketType; accessRequest: Task; isAllowedToAct: boolean};
const PageContent = observer(({accessRequest, isAllowedToAct}: PageContentPropsT) => {
  if (accessRequest.calculatedType === AccessRequestType.GENERIC) {
    return <GenericAccessRequestDisplay isAllowedToAct={isAllowedToAct} accessRequest={accessRequest} />;
  }

  if (accessRequest.calculatedType) {
    return <TaskDisplay isAllowedToAct={isAllowedToAct} accessRequest={accessRequest} />;
  } else {
    throw new Error(`Could not render access_request=${accessRequest.id} ticketType=${accessRequest.calculatedType}`);
  }
});

const TaskDetailedView = observer((props: TaskCardPropsT) => {
  const {currentUser} = useRootStore();

  if (_.isNil(currentUser)) {
    return <></>;
  }

  const {accessRequest} = props;

  function isAssignedToMe(ar?: Task) {
    if (!ar) {
      return false;
    }
    return ar.assigneeIds?.indexOf(currentUser!.id) >= 0;
  }

  const isAllowedToAct = isAssignedToMe(accessRequest);
  const {ticketType, type} = accessRequest!;

  return (
    <div className="tt--task-view">
      {accessRequest.error?.message && (
        <Alert className="p-0!" colorVariant="danger">
          Error: {accessRequest.error.message}
        </Alert>
      )}
      <PageContent type={type} ticketType={ticketType} accessRequest={accessRequest} isAllowedToAct={isAllowedToAct} />
    </div>
  );
});

export default TaskDetailedView;
