import React from 'react';
import {ImportStateT} from '../../types';
import {createdDaysAgo, formatFullDate} from 'src/lib';
import {Loading} from '@trustle/component-library';
import _ from 'lodash';
import {Icon} from '@trustle/component-library';

const ImportStatusObject = (props: {importState?: ImportStateT}) => {
  const {importState} = props;
  const defaultNoImportStateConfiguration = {
    text: ' Finish configuration',
    extraDetailText: null,
    status: 'warning',
    icon: <Icon type="unlinkedUser" />,
  };

  if (_.isEmpty(importState)) {
    return defaultNoImportStateConfiguration;
  }

  switch (importState?.status) {
    case 'finished':
      return {
        text: `Last import: ${formatFullDate(importState.created)}`,
        status: 'success',
        icon: <Icon type={'linked'} />,
      };
    case 'pending':
      return {
        text: `Started ${createdDaysAgo(importState.created)} (${formatFullDate(importState.created)})`,
        status: 'pending',
        icon: <Loading size="xs" />,
      };
    case 'failed':
    case 'aborted':
      return {
        text: `Last import: ${formatFullDate(importState.created)}`,
        extraDetailText: `${!_.isNil(importState.state?.codeError) ? `[${importState.state?.codeError}]` : ''} ${
          importState.state?.message
        }`,
        status: 'error',
        icon: <Icon type="unlinkedUser" />,
      };
    default:
      return {
        text: 'Import failed',
        status: 'error',
        icon: <Icon type="unlinkedUser" />,
      };
  }
};
export default ImportStatusObject;
