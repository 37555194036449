import React, {useState} from 'react';
import {formatDateFromNow, formatFullDate, fuseSearch} from 'src/lib';
import {connectorsUsageServiceAccountDataT} from 'src/types';
import _ from 'lodash';
import {Search} from 'src/components/Search/Search';
import {Resource} from 'src/stores/domainObjects/Resource';
import {faSearch} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Icon, Table} from '@trustle/component-library';
import {useRootStore} from 'src/lib/hooks';
import {AccountTabTypeE} from '../Resource/Account/ResourceAccounts';
type ShowAccountsAccessProps = {
  accountData: connectorsUsageServiceAccountDataT[];
  selectedService: string | undefined;
  system: Resource;
  onClose: any;
};

function ShowAccountsAccess(props: ShowAccountsAccessProps) {
  const {accountData, selectedService, onClose, system} = props;
  const {accountsStore} = useRootStore();
  if (_.isEmpty(accountData)) {
    return <></>;
  }

  const [queryAccount, setQueryAccount] = useState('');
  const [filtersAccount, setFiltersAccount] = useState<Record<string, string>>({});
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  const sortedData = _.orderBy(accountData, ['eventDate', 'account'], ['desc', 'asc']);
  const filteredServiceUsageAccounts = _.filter(
    sortedData,
    (su) => _.isNil(filtersAccount.Account) || (su.account && filtersAccount.Account === su.account)
  );
  const searchedServiceUsagesAccount = fuseSearch(queryAccount, ['account'], filteredServiceUsageAccounts);
  if (_.isEmpty(accountData)) {
    return <></>;
  }
  return (
    <div className="mp-service-usage">
      <a
        className="tr-flex align-items-center mb-3"
        onClick={() => {
          onClose();
        }}
      >
        <span className="tr-rounded-full tr-shadow-md tr-bg-gray-200">
          <Icon type="back" />
        </span>
        <span className="back-nav-button-text"> Go back to service usage List </span>
      </a>

      <div className="tr-flex tr-flex-col tr-mt-2">
        <div className="body3">
          {selectedService}
          {!showSearchBar && (
            <FontAwesomeIcon
              className="ml-2"
              size="xs"
              color="gray"
              icon={faSearch}
              onClick={() => setShowSearchBar(true)}
            />
          )}
        </div>
      </div>
      {showSearchBar && (
        <Search
          placeholder="Search account"
          filterOptions={{
            Account: _.compact(_.uniq(_.map(accountData, 'account'))),
          }}
          editURL={false}
          onChange={(query, filters) => {
            setQueryAccount(query);
            setFiltersAccount(filters);
          }}
          showCloseBtn={true}
          onClose={() => setShowSearchBar(false)}
        />
      )}
      <Table
        tableKey={'showaccountsaccess'}
        data={searchedServiceUsagesAccount}
        columns={[
          {
            dataField: 'account',
            text: 'System Account',
            headerStyle: {width: '60%', textAlign: 'left'},
            style: {width: '60%', textAlign: 'left'},
            formatter: (cell: any, reg: any) => {
              let linkType;
              try {
                linkType = accountsStore.accountsMap[reg.uaId].categoryAccount;
              } catch (error) {
                linkType = AccountTabTypeE.ASSIGNED;
              }
              const link = `/resource/manage/${system.id}/accounts/${linkType}?Account+Name=${cell}&open=details`;
              return (
                <a href={link} className="text-underline" rel="noopener noreferrer">
                  {cell}
                </a>
              );
            },
          },
          {
            dataField: 'eventDate',
            text: 'Last accessed',
            headerStyle: {width: '40%', textAlign: 'left'},
            style: {width: '40%', textAlign: 'left'},
            formatter: (cell: any) => {
              return `${formatDateFromNow(cell)} (${formatFullDate(cell)})`;
            },
          },
        ]}
        emptyMessage={
          <div>
            This groups does not have any service permissions. No data can be shown until permissions are attached.
          </div>
        }
        striped={false}
        wrapperClasses="rounded-xl border border-black rounded bg-white"
        bordered={false}
      />
    </div>
  );
}

export default ShowAccountsAccess;
