import React from 'react';
import _ from 'lodash';
import {Alert} from '@trustle/component-library';

export default function SlackNotificationPostInstall(props: {slackPostInstallError: string | null}) {
  const renderOkNotification = () => {
    return (
      <Alert title="Slack Application Installed" colorVariant="success">
        Trustle is now connected to your Slack workspace. Slack will now be used to notify your team when workflow tasks
        are assigned to them.
      </Alert>
    );
  };

  const renderFailNotification = () => {
    return (
      <Alert title="Slack Application Install Failed" colorVariant="danger">
        The installation failed with the following error: <b>{slackPostInstallError}</b>
      </Alert>
    );
  };

  const {slackPostInstallError} = props;
  if (slackPostInstallError === 'false') {
    return renderOkNotification();
  } else if (!_.isEmpty(slackPostInstallError)) {
    return renderFailNotification();
  } else {
    return <></>;
  }
}
