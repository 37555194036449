import React from 'react';

type ErrorMessageProps = {
  error: string;
};

const ErrorMessage = ({error}: ErrorMessageProps) => {
  return (
    <div className="tr-my-3 tr-font-bold tr-text-red-600">
      {error === 'passwordAuthenticationRequired' && <>Login method is not allowed for this user.</>}
      {error === 'organizationNotFound' && <>Organization Not Found.</>}
      {error === 'authenticationFailed' && <>Auth Failed.</>}
      {error === 'noSAMLResponse' && <>No SAML response.</>}
      {error === 'userNotFound' && <>User not found.</>}
      {error === 'samlAuthenticationNotAllowed' && <>Saml Authentication not Allowed.</>}
    </div>
  );
};
export default ErrorMessage;
