import {
  Alert,
  Checkbox,
  Confirmation,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Icon,
  IconButton,
  Table,
  Tooltip,
} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {Link} from 'react-router-dom';

import {
  Authority,
  DepartmentRoleTypeCol,
  NameCol,
} from 'src/components/design/tables/SharedColumnDefinitions/UserColumns';
import {ExpandRowProps} from 'react-bootstrap-table-next';
import {formatDateFromNow, formatFullDate, formatUserName, useQueryParams} from 'src/lib';
import {User} from 'src/stores/domainObjects/User';
import {UserAction, UserStatus} from 'src/stores/usersStore';
import {MessageTypeE, SourceOfTruthE, UserType} from 'src/types';
import UserPermissions from 'src/views/User/Profile/UserPermissions';
import {useRootStore} from 'src/lib/hooks';
import Breadcrumb from 'src/views/Permissions/Breadcrumb';
import InviteStatusIndicator from 'src/components/InviteStatusIndicator';
import LinkedStatus from 'src/components/LinkedStatus';
import ExpandableObserver from '../ExpandableObserver';
import {UserProfileModal} from '../../user-profile-modal/UserProfileModal';
import {ConfirmDeleteUserModal} from './modals/ConfirmDeleteUserModal';

const UsersTable = observer((props: {tableKey: string; users: User[]}) => {
  const {usersStore, currentUser, org} = useRootStore();
  const {users, tableKey} = props;
  const {idpFeatureEnabled} = org;
  const [modalUser, setModalUser] = useState<User>();
  const [showAdminConfirmDialog, setShowAdminConfirmation] = useState(false);
  const [selectedAction, setSelectedAction] = useState<UserAction | null>(null);
  const [importing] = useQueryParams(['importing']);
  const [modal, setModal] = useState<string | null>(importing ? UserAction.UPLOAD_CSV : null);

  const [columnSort, setColumnSort] = useState<string | undefined>(undefined);

  const {error} = usersStore;
  const refresh = () => {
    void usersStore.refresh();
    setModal(null);

    if (error) {
      usersStore.rootStore.toast.add('Failed to load users.', {appearance: 'error', autoDismiss: true});
    }
  };

  async function handleUserOffBoard() {
    setModal(null);
    usersStore.selectAll(false);
    if (modalUser) {
      await modalUser?.offboard();
    }
  }

  async function handleResendInvite() {
    if (modalUser) {
      await modalUser?.resendInvite();
    }
  }

  const selectedRows = _.filter(users, {selected: true});

  const onOpenModal = (u: User, action: UserAction) => {
    setModalUser(u);
    setModal(action);
  };

  const getLinkStatus = (uid: string) => {
    return org.accountsDictionary[uid];
  };

  const getActions = (u: User) => {
    const actions = [UserAction.VIEW_USER];
    const items = [
      <IconButton
        key={`viewUser${u.id}`}
        title={UserAction.VIEW_USER}
        onClick={() => onOpenModal(u, UserAction.VIEW_USER)}
        variant="ternary"
        data-testid="details-icon"
        icon="details"
      />,
    ];

    if (u.status === UserStatus.OFFBOARDED) {
      actions.push(UserAction.DELETE);
      items.push(
        <IconButton
          key={`remove${u.id}`}
          onClick={() => onOpenModal(u, UserAction.DELETE)}
          variant="ternary"
          data-testid={`remove${u.id}`}
          title="Delete user"
          icon="remove"
        />
      );
    }

    if (u.status !== UserStatus.OFFBOARDED) {
      const isSwitchValid = !_.isEmpty(org.idpSettings) && u.canSwitchSoT;

      if (isSwitchValid) {
        actions.push(UserAction.SWITCH_AUTHORITY);
        items.push(
          <IconButton
            key={`switch${u.id}`}
            onClick={() => onOpenModal(u, UserAction.SWITCH_AUTHORITY)}
            variant="ternary"
            data-testid={`switch${u.id}`}
            title={UserAction.SWITCH_AUTHORITY}
            icon="refresh"
          />
        );
      }

      if (u.id !== currentUser.id) {
        const isCustomerOrSystem = _.includes([UserType.customer, UserType.system], u.type);

        if (u.allowLogin && !u.confirmed && !isCustomerOrSystem) {
          const action = u.invitedAt ? UserAction.RESEND_INVITE : UserAction.SEND_INVITE;

          items.push(
            <IconButton
              key={`resend${u.id}`}
              onClick={() => onOpenModal(u, action)}
              variant="ternary"
              title={action}
              data-testid={`resend${u.id}`}
              icon="email"
            />
          );
          actions.push(action);
        }

        const isEmployee = u.type === UserType.employee;

        if (isEmployee) {
          const action = u.isOrgOwner ? UserAction.REMOVE_OWNER_ROLE : UserAction.ADD_OWNER_ROLE;
          items.push(
            <IconButton
              key={`toggleowner${u.id}`}
              onClick={() => {
                setModalUser(u);
                setShowAdminConfirmation(true);
              }}
              variant="ternary"
              data-testid={`toggleowner${u.id}`}
              title={action}
              icon={u.isOrgOwner ? 'removeOwnerRole' : 'addOwnerRole'}
            />
          );
          actions.push(action);
        }

        const isTrustleSoT = _.defaultTo(u.sourceOfTruth, SourceOfTruthE.TRUSTLE) === SourceOfTruthE.TRUSTLE;

        if (isTrustleSoT) {
          if (u.status !== UserStatus.PENDING_APPROVAL) {
            if (!isCustomerOrSystem) {
              const action = !u.allowLogin ? UserAction.ENABLE_USER : UserAction.DISABLE_USER;
              items.push(
                <IconButton
                  onClick={() => void u.toggleDisabled()}
                  variant="ternary"
                  key={`disableUser${u.id}`}
                  data-testid={`disableUser${u.id}`}
                  title={action}
                  icon={!u.allowLogin ? 'enableUser' : 'disableUser'}
                />
              );
              actions.push(action);
            }

            actions.push(UserAction.START_OFFBOARD);
            items.push(
              <IconButton
                onClick={() => onOpenModal(u, UserAction.START_OFFBOARD)}
                variant="ternary"
                key={`offboarduser${u.id}`}
                data-testid={`offboarduser${u.id}`}
                title={UserAction.START_OFFBOARD}
                icon={'startOffboard'}
              />
            );
          }

          if (!u.confirmed && isCustomerOrSystem) {
            const action = UserAction.ACTIVATE_USER;

            items.push(
              <IconButton
                onClick={() => void u.verifyUser()}
                variant="ternary"
                data-testid={`${action}${u.id}`}
                key={`${action}${u.id}`}
                title={action}
                icon="task"
              />
            );
            actions.push(action);
          }
        }
      }
    }
    return {items, actions};
  };

  const generalDropdown = (() => {
    if (_.isEmpty(selectedRows)) {
      return <></>;
    }

    const allActions = [
      UserAction.ADD_OWNER_ROLE,
      UserAction.REMOVE_OWNER_ROLE,
      UserAction.ACTIVATE_USER,
      UserAction.ENABLE_USER,
      UserAction.DISABLE_USER,
      UserAction.START_OFFBOARD,
      UserAction.RESEND_INVITE,
      UserAction.SEND_INVITE,
      UserAction.DELETE,
    ];
    const availableActions = _.flatten(_.map(selectedRows, (s) => getActions(s).actions));
    const items = allActions.filter((action: UserAction) => {
      return _.includes(availableActions, action);
    });

    return (
      <Dropdown
        title="Actions"
        dropdownMenu={
          <DropdownMenu>
            {_.size(items) > 0
              ? _.map(items, (action) => {
                  return (
                    <DropdownItem
                      key={`multiple-${action}`}
                      data-testid={`multiple-selector-option-${action}`}
                      onClick={() => {
                        setSelectedAction(action);
                        setModal(UserAction.BULK_ACTION);
                      }}
                      className="tr-bg-white hover:tr-bg-trustle-bglighter tr-px-0"
                    >
                      <div className="tr-pl-4">{action}</div>
                    </DropdownItem>
                  );
                })
              : [
                  <DropdownItem key={`multiple`} data-testid={`multiple-selector-option-no-action`} onClick={() => {}}>
                    <div>No Actions Available</div>
                  </DropdownItem>,
                ]}
          </DropdownMenu>
        }
        variant="secondary"
      />
    );
  })();

  const columns: any[] = [
    {
      dataField: 'id',
      text: '',
      hidden: true,
    },
    {
      dataField: 'status',
      sort: true,
      sortFunc: (_a: any, _b: any, order: string, _dataField: string, rowA: User, rowB: User) => {
        if (order === 'asc') {
          return rowB.invitedAt ?? '' > (rowA.invitedAt ?? '') ? -1 : 1;
        } else {
          return rowB.invitedAt ?? '' > (rowA.invitedAt ?? '') ? 1 : -1;
        }
      },
      configTitle: 'Status',
      text: '',
      formatter: (cell: any, u: User) => {
        return (
          <div data-testid={`status${u.email}`} onClick={() => usersStore.toggleUserSelection(u)}>
            <InviteStatusIndicator user={u} />
          </div>
        );
      },
      headerStyle: {width: '2.2em', paddingLeft: '8px', paddingRight: '8px', textAlign: 'center'},
      style: {width: '2.2em', paddingLeft: '8px', paddingRight: '8px', textAlign: 'center'},
    },
    {
      dataField: 'linkstatus',
      sort: true,
      sortFunc: (_a: any, _b: any, order: string, _dataField: string, rowA: User, rowB: User) => {
        if (order === 'asc') {
          return _.size(getLinkStatus(rowA.id)) >= _.size(getLinkStatus(rowB.id)) ? 1 : -1;
        } else {
          return _.size(getLinkStatus(rowA.id)) >= _.size(getLinkStatus(rowB.id)) ? -1 : 1;
        }
      },
      configTitle: '',
      text: '',
      formatter: (cell: any, u: User) => {
        return <LinkedStatus user={u} />;
      },
      headerStyle: {width: '2.3em', paddingLeft: '8px', paddingRight: '8px', textAlign: 'center'},
      style: {width: '2.3em', paddingLeft: '8px', paddingRight: '8px', textAlign: 'center'},
    },
    NameCol({columnWidth: '13rem'}),
    {
      dataField: 'createdByEmail',
      sort: true,
      hidden: true,
      text: 'Added by',
      formatter: (cell: any, _u: User) => {
        if (_.isNil(cell)) {
          return <Icon title="Not Available" type="notAvailable" size="sm" />;
        }
        const {createdByUser} = _u;
        return <Link to={`/users/${createdByUser?.id}`}>{formatUserName(createdByUser)}</Link>;
      },
      headerStyle: {width: '10rem', textAlign: 'left'},
      style: {width: '10rem', textAlign: 'left'},
    },
    {
      dataField: 'managerEmail',
      sort: true,
      text: 'Manager',
      formatter: (cell: any, _u: User) => {
        if (_.isNil(cell)) {
          return <Icon type="emptyData" title="No Manager" size="sm" />;
        }
        const {manager} = _u;
        return <Link to={`/users/${manager?.id}`}>{formatUserName(manager)}</Link>;
      },
      headerStyle: {width: '10rem', textAlign: 'left'},
      style: {width: '10rem', textAlign: 'left'},
    },
    {
      dataField: 'lastLogin',
      sort: true,
      text: 'Last Login',
      formatter: (cell: any, _u: User) => {
        return _.isEmpty(cell) ? (
          <Icon type="emptyData" title="Never" size="sm" />
        ) : (
          <Tooltip position="top" content={formatFullDate(cell)}>
            {formatDateFromNow(cell)}
          </Tooltip>
        );
      },
      headerStyle: {width: '7rem', textAlign: 'left'},
      style: {width: '7rem', textAlign: 'left'},
    },
    DepartmentRoleTypeCol({idpFeatureEnabled, columnSort, setColumnSort}),
    {...Authority, hidden: !idpFeatureEnabled, skip: !idpFeatureEnabled},
    {
      dataField: 'actions',
      classes: 'show-on-hover',
      sort: false,
      text: '',
      headerStyle: {width: '7rem', paddingRight: '4px'},
      style: {width: '7rem', paddingRight: 0},
      formatter: (_cell: any, u: User) => {
        return <div className="action-buttons tr-flex tr-flex-row tr-items-center tr-gap-1">{getActions(u).items}</div>;
      },
    },
  ];

  const selectedCnt = _.size(selectedRows);

  function bulkActionContent({selectedAction, selectedCnt}: {selectedAction: UserAction | null; selectedCnt: number}) {
    const isPlural = selectedCnt > 1;
    const selectedActionPluralize = isPlural ? `${selectedAction}s` : selectedAction;
    const userPluralize = isPlural ? 'users' : 'user';
    const confirmMessage = `Please confirm you wish ${isPlural ? 'to bulk' : 'to '} ${selectedActionPluralize}${
      isPlural ? ' to' : '.'
    } `;

    return (
      <>
        <span>
          {confirmMessage}
          {isPlural && (
            <strong>
              {selectedCnt} {userPluralize}.
            </strong>
          )}
        </span>
      </>
    );
  }

  return (
    <div className="tr-pb-8">
      {_.isString(error?.response) && (
        <Alert title="Error" colorVariant={MessageTypeE.DANGER} icon={<Icon type="warning" />}>
          <div>{error.response}</div>
        </Alert>
      )}
      <Table
        tableKey={tableKey}
        selectedRows={selectedRows}
        bodyClasses="tr-max-h-[600px] tr-overflow-x-hidden tr-overflow-y-auto"
        selectRow={{
          mode: 'checkbox',
          clickToSelect: false,
          hideSelectAll: false,
          onSelect: (_row, _isSelect) => {
            const user = _.find(users, (user) => user.id === _row.id);
            if (!user) {
              return;
            }
            if (user.id !== currentUser.id) {
              usersStore.toggleUserSelection(user);
            }
          },
          selectionRenderer: ({rowKey}) => {
            const user = _.find(users, (user) => user.id === rowKey);
            if (!user) {
              return <></>;
            }
            return (
              <Checkbox
                id={`activate-${user.id}`}
                data-testid={`activate-${user.id}`}
                checked={user?.selected}
                disabled={user.id === currentUser.id}
              />
            );
          },
          selectionHeaderRenderer: ({indeterminate, checked}) => {
            return (
              <Checkbox
                id={`activate-users-all`}
                checked={checked}
                ref={(input) => {
                  if (input) {
                    input.indeterminate = indeterminate;
                  }
                }}
              />
            );
          },
          onSelectAll(isSelect, rows) {
            usersStore.selectMultiple(
              _.filter(rows as User[], (user) => {
                return user.id !== currentUser.id;
              }),
              isSelect
            );
          },
        }}
        data={users}
        columns={columns}
        multiActionComponent={generalDropdown}
        showClearSelectionBtn={true}
        onClearSelection={() => {
          usersStore.selectAll(false);
        }}
        expandRow={
          //@ts-ignore
          {
            nonExpandable: org.accountsDictionary
              ? users.filter((user) => !org.accountsDictionary[user.id]).map((r) => r.id)
              : undefined,
            showExpandColumn: [UserStatus.ACTIVE, UserStatus.PENDING_APPROVAL].includes(props.tableKey as UserStatus),
            expandColumnPosition: 'right',
            onlyOneExpanding: true,
            parentClassName: `expanded-row tr-bg-white hover:tr-bg-gray-light`,
            expandHeaderColumnRenderer: () => {
              return (
                <div className="cursor">
                  <Icon type="chevronDown" title="Expand" size="sm" />
                </div>
              );
            },
            expandColumnRenderer: ({expanded, rowKey}) => {
              return <ExpandableObserver expanded={expanded} rowKey={rowKey} />;
            },
            renderer: (row) => {
              return row.status === UserStatus.ACTIVE ? (
                <UserPermissions hideFilters={true} user={row} canEdit={true} disableFilters={true} />
              ) : (
                <></>
              );
            },
          } as ExpandRowProps<any>
        }
        striped={false}
        wrapperClasses="rounded-xl border border-black rounded"
        bordered={false}
        rowClasses={'tr-h-16'}
      />
      {modal === UserAction.BULK_ACTION && (
        <Confirmation
          onConfirm={async () => {
            usersStore.bulkAction(selectedAction!, selectedRows);
            setModal(null);
            setSelectedAction(null);
          }}
          onClose={() => {
            setModal(null);
            setSelectedAction(null);
          }}
          title={'Confirm action'}
        >
          {bulkActionContent({selectedAction, selectedCnt})}
        </Confirmation>
      )}
      {modal === UserAction.SWITCH_AUTHORITY && (
        <Confirmation
          onConfirm={async () => {
            void modalUser!.switchAuthority();
            refresh();
          }}
          onClose={() => refresh()}
          title={'Confirm action'}
        >
          <span>
            {`Please, confirm that you want to change the authority for user: `}
            <strong>{`${modalUser!.fullname}`}</strong>
            {` to be `}
            {modalUser!.sourceOfTruth === SourceOfTruthE.IDP ? (
              <strong>Trustle</strong>
            ) : (
              <>
                {'the configured '}
                <strong>IDP</strong>
              </>
            )}
          </span>
        </Confirmation>
      )}
      {modal === UserAction.VIEW_USER && <UserProfileModal user={modalUser!} handleCloseFn={() => setModal(null)} />}
      {modal === UserAction.DELETE && (
        <ConfirmDeleteUserModal
          onConfirm={async () => {
            setModal(null);
            usersStore.selectAll(false);
            if (modalUser) {
              usersStore.bulkAction(UserAction.DELETE, [modalUser]);
            }
          }}
          onCancel={() => {
            setModal(null);
          }}
          user={modalUser!}
        />
      )}
      {modal === UserAction.START_OFFBOARD && (
        <Confirmation
          onConfirm={handleUserOffBoard}
          size="lg"
          onClose={() => {
            refresh();
          }}
          title={'Confirm action'}
        >
          <div>
            Please, confirm that you want to offboard the user: <strong>{modalUser!.fullname}</strong>
            {_.size(modalUser?.permissionsPreventOffboarding) > 0 && (
              <Alert title="Overriden deprovision settings" colorVariant="warning">
                <div className="my-2">
                  We are unable to offboard this user because this user's permissions (
                  {_.size(modalUser?.permissionsPreventOffboarding)}):{' '}
                  <div className="tr-overflow-auto tr-my-2">
                    <ul className="tr-h-[70px]">
                      {modalUser &&
                        _.map(modalUser?.permissionsPreventOffboarding, (perm) => (
                          <li>
                            <Breadcrumb permission={perm} showLink={true} />
                          </li>
                        ))}
                    </ul>
                  </div>{' '}
                  are set to <strong>deprovisioning off</strong>. To <strong>override</strong> these settings,{' '}
                  <strong>click Confirm</strong> to continue offboarding this user. Otherwise, click Cancel, and contact
                  your systems administrator to manually remove the forementioned permissions from the user, and try
                  again.
                </div>
              </Alert>
            )}
            <div className="mt-3">
              <small>
                <strong>Keep in mind:</strong>
              </small>
              <ul>
                <li>
                  <small>
                    If a user that you offboard is the unique <strong>owner</strong> of a resource, then the
                    organizations owners are automatically assigned as new owners of the resource.
                  </small>
                </li>
                <li>
                  <small>
                    If a user that you offboard is the unique <strong>provisioner</strong> of a resource, then the
                    owners of the resource are automatically assigned as new provisioners of the resource.
                  </small>
                </li>
              </ul>
            </div>
          </div>
        </Confirmation>
      )}
      {showAdminConfirmDialog && (
        <Confirmation
          onConfirm={() => {
            void modalUser?.toggleOwnerRole();
            setShowAdminConfirmation(false);
            usersStore.selectAll(false);
          }}
          onClose={() => setShowAdminConfirmation(false)}
          title={'Confirm action'}
        >
          <div>
            {`Please, confirm that you want to ${
              modalUser?.isOrgOwner ? 'remove' : 'add'
            } the following user as an org owner: `}
            <strong>{modalUser!.fullname}</strong>
          </div>
        </Confirmation>
      )}
      {(modal === UserAction.RESEND_INVITE || modal === UserAction.SEND_INVITE) && (
        <Confirmation
          onConfirm={() => {
            void handleResendInvite();
            usersStore.selectAll(false);
            setModal(null);
          }}
          onClose={() => {
            setModal(null);
          }}
          title={'Confirm action'}
        >
          <div>
            {modal === UserAction.RESEND_INVITE
              ? `Please, confirm that you want to re-send an email invite to: `
              : `Please, confirm that you want to send an email invite to: `}
            <strong>{modalUser!.fullname}</strong>
          </div>
        </Confirmation>
      )}
    </div>
  );
});

export default UsersTable;
