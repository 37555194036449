import React from 'react';
import {ToggleSwitch} from 'src/components/design';
import {useField} from 'formik';

type PropsT = {
  name: string;
  disabled?: boolean;
  values?: {true: any; false: any};
};
export default function ProvisionMethodToggle(props: PropsT) {
  const [field, meta, helpers] = useField(props);

  const {values = {true: true, false: false}} = props;
  const isTrueValue = meta.value === values.true;
  return (
    <ToggleSwitch
      data-testid={props.name}
      checked={isTrueValue}
      onToggle={(e: any) => {
        helpers.setValue(e ? values.true : values.false);
        helpers.setTouched(true);
      }}
      name={field.name}
      disabled={props.disabled}
    />
  );
}
