import React, {useState} from 'react';
import _ from 'lodash';
import './PlansPricing.scss';
import {observer} from 'mobx-react';
import {useRootStore} from 'src/lib/hooks';
import {useHistory} from 'react-router-dom';
import ContactModal from './ContactModal';
import planDescriptions, {createFeatureList, pricingTitle} from './PlanDescriptions';
import {FacetIdEnum, PackageNameEnum} from 'src/types';
import {Button} from '@trustle/component-library';

const PlansAndPricing = observer(function PlansAndPricingCmp() {
  const {org} = useRootStore();
  const history = useHistory();
  const [contactOpen, setContactOpen] = useState<boolean>(false);

  if (_.isNil(org)) {
    return <></>;
  }

  const plan = org.subscriptionPlan;
  const isFreePlan = org.facetId === FacetIdEnum.FREE_AWS;
  const planActions: Record<string, any> = {
    Enterprise: () => {
      setContactOpen(true);
    },
    Team: (product: any) => {
      history.push(`/payments?plan=${product.id}`);
    },
    Basic: (product: any) => {
      history.push(`/payments?plan=${product.id}`);
    },
  };

  const buildActionButton = (altText: string, onClick: any) => {
    return (isActive: any, product: any) => {
      return (
        <Button onClick={_.partial(onClick, product)} disabled={isActive} data-testid={`actionButton-${altText}`}>
          {isActive ? 'Your plan' : altText}
        </Button>
      );
    };
  };

  return (
    <div className="plans-pricing mr-3 ml-3 mt-1">
      <div className="tr-flex tr-flex-col">
        {_.compact(
          _.map([PackageNameEnum.FREE, PackageNameEnum.TEAM, PackageNameEnum.ENTERPRISE], (planName) => {
            const product = org.getProductByName(planName);
            if (!product) {
              return null;
            }
            const isActive = (isFreePlan && planName === PackageNameEnum.FREE) || plan?.id === product.id;

            const planDescription = planDescriptions[planName]!;
            const emptyFunction = () => {};
            const action = planActions[planName] ?? emptyFunction;

            return (
              <div key={product.id} className={`tr-p-4 tr-bg-white tr-m-2 ${isActive ? 'active-shadow' : ''}`}>
                <div className="tr-flex flex-column">
                  <div className="">
                    <div>
                      <h1 className="grayed-out">{planDescription.name}</h1>
                    </div>
                    <div className="tr-mb-3 tr-flex justify-content-around align-content-center flex-column">
                      <div className={'tr-flex flex-column justify-content-center align-content-center'}>
                        <div className="tr-flex justify-content-start align-content-center">
                          {pricingTitle(planDescription.name, product)}
                        </div>
                        <div className="text-left body6 grayed-out">{planDescription.subtitle}</div>
                      </div>
                    </div>
                  </div>
                  <div className="text-center mb-4">
                    {buildActionButton(planDescription.actionButtonLabel, action)(isActive, product)}
                  </div>
                  <div>
                    <div className="body4 grayed-out mb-2">{planDescription.featuresTitle}</div>
                    {createFeatureList(planDescription.name, planDescription.features)}
                  </div>
                </div>
              </div>
            );
          })
        )}
        {contactOpen && (
          <ContactModal
            onHide={() => {
              setContactOpen(false);
            }}
            show={contactOpen}
          />
        )}
      </div>
    </div>
  );
});

export default PlansAndPricing;
