import React from 'react';
import {Button} from '@trustle/component-library';
import {useQueryStrings} from 'src/lib/hooks';
import _ from 'lodash';

type FilterToggleBtnPropsT = {filterKey: string; value: string} & React.ComponentProps<typeof Button>;

/**
 * @param filterKey the key of a filter to be defined in url params
 * @param value the value that should get toggled in url params when button is clicked
 * @example
 * ```
 * <FilterToggleBtn filterKey="type" value="AWS">
 *   Click to toggle "?type=AWS" in the url
 * </FilterToggleBtn>
 * ```
 */
const FilterToggleBtn = ({filterKey, value, ...rest}: FilterToggleBtnPropsT) => {
  const [filters, setFilters] = useQueryStrings();

  const values = filters[filterKey]?.split(',') ?? [];
  const isFilterOn = values.includes(value);
  const selected = values.includes(value);

  const toggleValue = () => {
    const newValue = isFilterOn ? _.without(values, value) : [...values, value];
    setFilters({...filters, 'Trustle User Type': newValue});
  };

  return <Button variant="ternary" selected={selected} onClick={toggleValue} {...rest} />;
};

export default FilterToggleBtn;
