import React, {ReactElement, useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';
import {AsyncTypeahead} from 'react-bootstrap-typeahead';
import _ from 'lodash';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import {Icon} from '@trustle/component-library';

type TypeaheadSelectT = {
  label?: string | ReactElement;
  id?: string;
  onChange: (selectedItem: any, ids?: string[], all?: any[]) => void;
  onSearch: (input: string) => Promise<{value: string; label: string}[]>;
  onBlur?: (e: any) => void | undefined;
  onInternalSelectedChange?: (e: any) => void;
  className?: string;
  allowNew?: boolean;
  multiple?: boolean;
  selected?: any;
  isClearable?: boolean;
  validated: boolean;
  required?: boolean;
  name?: string;
  defaultSelected?: {value: string; label: string}[];
  description?: string;
  subdescription?: string | ReactElement;
  placeholder?: string;
  limit?: number;
  horizontal?: boolean;
  validationFeedback?: string;
  newSelectionPrefix?: string;
  customItem?: (option: any | string, props: any) => ReactElement;
  prefilledSearch?: () => string;
  allowNewOutside?: boolean;
  emptyLabel?: string;
  disabled?: boolean;
};

export const TypeaheadSelect = React.forwardRef((props: TypeaheadSelectT, ref: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<{value: string; label: string}[]>([]);
  const [internalSelected, setInternalSelected] = useState<{value: string; label: string}[]>(
    props.defaultSelected && props.defaultSelected.length > 0 ? props.defaultSelected : []
  );
  const asyncTypeaheadRef = React.useRef<any>();
  useEffect(() => {
    if (_.isFunction(props.onInternalSelectedChange)) {
      props.onInternalSelectedChange(internalSelected);
    }
  }, [internalSelected]);

  useEffect(() => {
    if (!_.isNil(props.selected) && props.selected.length === 0 && internalSelected.length > 0) {
      setInternalSelected([]);
    }
  }, [props.selected]);

  React.useImperativeHandle(ref, () => ({
    clear: () => clear(),
  }));

  function clear() {
    if (asyncTypeaheadRef && asyncTypeaheadRef.current && asyncTypeaheadRef.current.inputNode) {
      asyncTypeaheadRef.current.inputNode.value = '';
      if (_.isFunction(asyncTypeaheadRef.current.clear)) {
        asyncTypeaheadRef.current.clear();
      }
    }
  }

  useEffect(() => {
    setPrefilled();
  }, [props.prefilledSearch]);

  function onChange(e: any) {
    if (props.multiple) {
      e.filter((item: any) => {
        return !item.disabled;
      });
      let selected = e;
      if (e && props.limit && e.length >= props.limit) {
        selected = e.slice(0, props.limit);
      }
      props.onChange(_.map(selected, 'value'), _.map(selected, 'id'), selected);
      setInternalSelected(selected);
    } else {
      const v = e[0] ? e[0] : e;
      props.onChange(v, []);
      setInternalSelected(e);
    }

    if (!props.prefilledSearch) {
      if (e.length === 0 && ref && ref.current && _.isFunction(ref.current.clear)) {
        ref.current.clear();
      }

      if (props.selected && props.selected.length === 0 && ref && _.isFunction(ref.current.clear)) {
        ref.current.clear();
      }
    }
  }

  function setPrefilled() {
    if (
      props.prefilledSearch &&
      props.prefilledSearch() &&
      asyncTypeaheadRef &&
      asyncTypeaheadRef.current &&
      asyncTypeaheadRef.current.inputNode &&
      !asyncTypeaheadRef.current.inputNode.value
    ) {
      asyncTypeaheadRef.current.inputNode.value = props.prefilledSearch();
    }
  }

  function defaultOnBlur() {
    if (
      internalSelected.length === 0 &&
      ref &&
      ref.current &&
      _.isFunction(ref.current.clear) &&
      !props.allowNewOutside
    ) {
      if (!props.allowNew) {
        ref.current.clear();
      }
    }
  }

  function defaultOnFocus() {
    setPrefilled();
  }

  async function onSearch(e: any) {
    setIsLoading(true);
    const options = await props.onSearch(e);
    setOptions(options);
    setIsLoading(false);
  }

  const reactSelectProps = {
    multiple: !!props.multiple,
    options: options,
    selected: internalSelected,
    className: props.className,
    onChange: onChange,
    onFocus: defaultOnFocus,
    onBlur: _.isFunction(props.onBlur) ? props.onBlur : defaultOnBlur,
    validated: props.validated,
    required: props.required,
    allowNew: props.allowNew,
    newSelectionPrefix: props.newSelectionPrefix,
    name: props.name,
    id: props.id,
    horizontal: props.horizontal,
    isLoading: isLoading,
    onSearch: onSearch,
    disabled: props.disabled,
    emptyLabel: props.emptyLabel || 'No matches found',
  };

  let isValid;

  if (internalSelected.length > 0) {
    const isMatch = internalSelected[0].value !== '';
    isValid = props.validated && isMatch;
  } else {
    isValid = props.validated && false;
  }

  const validationClass: string = props.validated ? (isValid ? 'is-valid' : 'is-invalid') : '';
  return (
    <>
      <Form.Group
        className={`trustle-typeahead form-control--text-input  ${
          props.horizontal ? 'horizontal tr-flex tr-flex-row' : ''
        }`}
      >
        <div className={props.horizontal ? 'tr-w-10/12' : ''}>
          <div className="tr-flex tr-justify-between tr-items-center">
            {props.label && (
              <Form.Label sm={props.horizontal ? 2 : undefined} {...(props.id ? {htmlFor: props.id} : {})}>
                {props.label}
              </Form.Label>
            )}
            {props.description && <small className="form-text text-muted">{props.description}</small>}
          </div>
          <AsyncTypeahead
            ref={asyncTypeaheadRef}
            {...reactSelectProps}
            defaultSelected={props.defaultSelected}
            minLength={1}
            disabled={props.disabled}
            emptyLabel={props.emptyLabel}
            renderMenuItemChildren={props.customItem}
            inputProps={{
              id: props.id,
              placeholder: props.placeholder ? props.placeholder : 'Select...',
              required: props.required,
              className: validationClass,
              autoComplete: 'off',
            }}
          />
          {props.subdescription && <small className="form-text text-muted">{props.subdescription}</small>}
          <Form.Control.Feedback type="valid"></Form.Control.Feedback>
          <Form.Control.Feedback type="invalid">
            {props.validationFeedback ? (
              props.validationFeedback
            ) : props.required ? (
              <div className="feedback">
                <Icon type="error" title="Error" />
                <span>{'This field is Required.'}</span>{' '}
              </div>
            ) : (
              <div className="feedback">
                <Icon type="error" title="Error" />
                <span>{'Incorrect value.'}</span>{' '}
              </div>
            )}
          </Form.Control.Feedback>
        </div>
      </Form.Group>
    </>
  );
});
