import React, {useState} from 'react';
import {useToasts} from 'react-toast-notifications';
import ConfirmDeleteResourceModal from '../Edit/ConfirmDeleteResourceModal';
import EditResourceModal from '../Edit/EditResourceModal';
import ResourceDetailsModal from './ResourceDetailsModal';
import {Resource} from 'src/stores/domainObjects/Resource';
import {IconButton} from '@trustle/component-library';

type PropsT = {
  resource: Resource;
  rootResource: Resource;
  refreshResourceList?: () => void;
};

enum ResourceModalE {
  VIEW_RESOURCE = 'view_resource',
  ADD_RESOURCE = 'add_resource',
  EDIT_RESOURCE = 'edit_resource',
  ADD_PERMISSION = 'add_permission',
  DELETE_RESOURCE = 'delete_resource',
}
function EditResourceIcon(props: PropsT) {
  const {addToast} = useToasts();
  const {rootResource, resource, refreshResourceList = () => {}} = props;

  const readOnly = !rootResource?.userIsOwner;
  const isConnectedSystem = !!rootResource.connectionId;
  const [modalAction, setModalAction] = useState<ResourceModalE>();

  const closeModal = (refreshData: boolean = false) => {
    setModalAction(undefined);
    if (refreshData) {
      refreshResourceList();
    }
  };

  function getActions() {
    const items = [];
    items.push(
      <IconButton
        icon="edit"
        variant="ternary"
        key={'detail'}
        title="Details"
        data-testid="details-icon"
        onClick={(e: any) => {
          e.stopPropagation();
          setModalAction(ResourceModalE.VIEW_RESOURCE);
        }}
      />
    );

    if (!readOnly) {
      items.push(
        <IconButton
          icon="edit"
          variant="ternary"
          title="Edit"
          data-testid="edit-icon"
          onClick={(e: any) => {
            e.stopPropagation();
            setModalAction(ResourceModalE.EDIT_RESOURCE);
          }}
        />
      );
    }

    if (!isConnectedSystem && !readOnly) {
      items.push(
        <IconButton
          icon="remove"
          variant="ternary"
          data-testid="delete-icon"
          disabled={readOnly}
          title="Delete"
          onClick={(e: any) => {
            e.stopPropagation();
            setModalAction(ResourceModalE.DELETE_RESOURCE);
          }}
        />
      );
    }

    return <div className="flex-align perm-actions-wrapper">{items}</div>;
  }

  const resourceModalActionContent = ((): React.ReactNode => {
    if (!modalAction) {
      return null;
    }

    switch (modalAction) {
      case ResourceModalE.DELETE_RESOURCE: {
        return (
          <ConfirmDeleteResourceModal
            resource={resource}
            closeModal={(resourceWasDeleted: boolean) => {
              if (!resourceWasDeleted) {
                closeModal();
                return;
              }
              addToast(`${resource.name} has been deleted`, {
                appearance: 'success',
                autoDismiss: true,
              });
              refreshResourceList();
            }}
          />
        );
      }
      case ResourceModalE.EDIT_RESOURCE: {
        return (
          <EditResourceModal
            resource={resource}
            isCreating={false}
            visible={ResourceModalE.EDIT_RESOURCE === modalAction}
            closeModal={closeModal}
            refresh={() => {
              closeModal();
              refreshResourceList();
            }}
          />
        );
      }
      case ResourceModalE.VIEW_RESOURCE: {
        return <ResourceDetailsModal resource={resource} closeModal={closeModal} />;
      }
    }
  })();

  return (
    <>
      {getActions()}
      {resourceModalActionContent}
    </>
  );
}

export default EditResourceIcon;
