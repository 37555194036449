import React, {useState} from 'react';
import {observer} from 'mobx-react';
import {Permission} from 'src/stores/domainObjects/Permission';
import PermissionDetailsModal, {PermTabModeE} from './PermissionDetailsModal';
import {Icon} from '@trustle/component-library';

type MetadataButtonProps = {
  permission: Permission;
  text?: React.ReactElement;
  defaultSelectedTab?: PermTabModeE;
};

/** @deprecated use PermissionDetailsButton */
const MetadataButton = observer(function MetadataButton(props: MetadataButtonProps) {
  const {permission, text = <Icon type="metadata" />, defaultSelectedTab = PermTabModeE.GENERAL} = props;
  const [openedModal, setOpenedModal] = useState(false);
  return (
    <div
      className="cursor tr-flex align-items-center"
      key={permission.id}
      onClick={() => {
        setOpenedModal(true);
      }}
    >
      {text}
      {openedModal && (
        <PermissionDetailsModal
          permission={permission}
          defaultSelectedTab={defaultSelectedTab}
          closeModal={() => {
            setOpenedModal(false);
          }}
        />
      )}
    </div>
  );
});

export default MetadataButton;
