import {SensitivityIcon} from '@trustle/component-library';
import React from 'react';
import ResourceDropdown from 'src/views/Resource/Setup/ResourceDropdown';
import AccessTable, {AccessBranchPropsT} from 'src/views/Resource/TreeView/AccessTable';
import {PermissionActions} from 'src/views/Resource/TreeView/BranchComponents/PermissionActions';
import {PermissionAttributes} from 'src/views/Resource/TreeView/BranchComponents/PermissionAttributes';
import {PermissionHeader} from 'src/views/Resource/TreeView/BranchComponents/PermissionHeader';

import ResourceHeader from 'src/views/Resource/TreeView/BranchComponents/ResourceHeader';
import PermissionBranch, {PermissionBranchPropsT} from 'src/views/Resource/TreeView/PermissionBranch';
import ResourceBranch, {ResourceBranchPropsT} from 'src/views/Resource/TreeView/ResourceBranch';
import SystemTreeWrapper, {SystemTreeTabPanelT} from 'src/views/Resource/TreeView/SystemWrapper';
import {FormField} from '../manifest/ManifestForm';
import {AccountTableParams} from '../common/types';
import {TemplateSchema} from '../manifests';
import {ConnectionServiceE} from 'src/types';
import ResourceChildren from 'src/views/Resource/TreeView/ResourceChildren';

export interface ConnectorSchema {
  name: string;
  description: string;
  tags?: string[];
  service: ConnectionServiceE;
  image?: string;
  serviceId: string;
  auth?: {
    title?: string;
    description?: string;
    docs?: {
      link: string;
      linkText: string;
    };
    type: string;
    fields?: FormField[];
    buttons?: any[];
  };
  templates?: TemplateSchema;
}

export const templatesDefault: TemplateSchema = {
  resources: {
    tree: {
      Wrapper: {
        default: {
          main: (props: SystemTreeTabPanelT) => {
            return <SystemTreeWrapper {...props} />;
          },
        },
      },
      Resource: {
        default: {
          main: (props: ResourceBranchPropsT) => {
            return <ResourceBranch {...props} />;
          },
          attributes: (props: ResourceBranchPropsT) => {
            return (
              <div className="tr-ml-1 tr-mr-1 tr-flex tr-justify-center tr-items-center p-1">
                <SensitivityIcon
                  size="sm"
                  level={props.resource.sensitivity?.level ?? ''}
                  title="Sensitivity set for this specific resource"
                />
              </div>
            );
          },
          actions: (props: ResourceBranchPropsT) => {
            return (
              <>
                {!props.accessMode && (
                  <ResourceDropdown
                    resource={props.resource}
                    refreshResourceList={() => props.resource.loadDetailed()}
                  />
                )}
              </>
            );
          },
          header: (props: ResourceBranchPropsT) => {
            return <ResourceHeader {...props} />;
          },
          children: (props: ResourceBranchPropsT) => {
            return <ResourceChildren {...props} />;
          },
        },
      },

      Permission: {
        default: {
          main: (props: PermissionBranchPropsT) => {
            return <PermissionBranch {...props} />;
          },
          attributes: (props: PermissionBranchPropsT) => {
            return <PermissionAttributes permission={props.permission} accessMode={props.accessMode ?? false} />;
          },
          actions: (props: PermissionBranchPropsT) => {
            return <PermissionActions {...props} />;
          },
          header: (props: PermissionBranchPropsT) => {
            return <PermissionHeader {...props} />;
          },
        },
      },
      AccessRecord: {
        default: {
          main: (props: AccessBranchPropsT) => {
            return <AccessTable {...props} />;
          },
        },
      },
    },
  },
  accounts: {
    tables: {
      //returns visible columns by datafield
      Linked: (_props: AccountTableParams) => {
        return [
          'flaggedForReview',
          'status',
          'user/account',
          'accesses.length',
          'numConnectedApps',
          'riskScore',
          'usage',
          'issue',
          'lastAccountActivity',
          'lastLogin',
          'actions',
        ];
      },
      Unlinked: (_props: AccountTableParams) => {
        return [
          'flaggedForReview',
          'status',
          'user/account',
          'accesses.length',
          'numConnectedApps',
          'usage',
          'riskScore',
          'accountType',
          'email',
          'lastAccountActivity',
          'lastLogin',
          'issue',
          'actions',
        ];
      },
      Flagged: (_props: AccountTableParams) => {
        return [
          'flaggedForReview',
          'status',
          'user/account',
          'accesses.length',
          'numConnectedApps',
          'usage',
          'riskScore',
          'lastAccountActivity',
          'lastLogin',
          'issue',
          'actions',
        ];
      },
      Offboarded: (_props: AccountTableParams) => {
        return ['flaggedForReview', 'status', 'user/account', 'editedByUid', 'deletedAt', 'actions'];
      },
    },
  },
};
