import _ from 'lodash';
import React, {useState} from 'react';
import {SubmitButton} from 'src/components/design';
import './GithubConnector.scss';
import {GitHubNextStepT} from './types';
import {AuthenticationProofT, ConnectionInfoT, GithubAuthenticationMethodE} from 'src/types';
import {formatEnglishList} from 'src/lib';
import {AuthenticationMethodSelection} from '../components/AuthenticationMethodSelection';
import {getConnectorOAuthRedirect} from 'src/lib/config';
import {Button} from '@trustle/component-library';
import {useConnectionCredentials} from '../../lib/hooks';

type GithubAppPropsT = {
  orgName: string;
  onComplete: GitHubNextStepT;
  connectionInfo: ConnectionInfoT;
  editMode?: boolean;
  completeLabel?: string;
};

export default function GithubAppSetup(props: GithubAppPropsT) {
  const {orgName, completeLabel = 'Continue', editMode = false} = props;
  const {connection, authenticationProofs} = props.connectionInfo;

  const [appVerification, setAppVerification] = useState({identity: !_.isEmpty(authenticationProofs), install: false});
  const [appInstallError, setAppInstallError] = useState('');
  const [selectedMethod, setSelectedMethod] = useState<GithubAuthenticationMethodE | null>(
    (connection.authenticationMethod as GithubAuthenticationMethodE) || null
  );
  const {credentials, testConnection: testConnectionAPI} = useConnectionCredentials();

  const authMethod = (() => {
    if (selectedMethod === GithubAuthenticationMethodE.APP_WRITE) {
      return 'automatic';
    }

    if (selectedMethod === GithubAuthenticationMethodE.APP_READ) {
      return 'manual';
    }

    return null;
  })();

  async function saveCredentials(): Promise<boolean> {
    const {data: responseData} = await testConnectionAPI(connection.id, {
      orgName,
      authenticationMethod: selectedMethod,
    });
    if (responseData.error) {
      setAppInstallError(responseData.error.message);
      return false;
    }
    await credentials(connection.id, {
      orgName,
      authenticationMethod: selectedMethod,
    });

    setAppInstallError('');
    setAppVerification({...appVerification, install: true});
    return true;
  }

  const identityContent = (() => {
    if (_.isEmpty(authenticationProofs)) {
      return <div>You have not authenticated as a GitHub user yet</div>;
    }
    const usernames = _.map(authenticationProofs, (ap: AuthenticationProofT) => {
      return ap.serviceFriendlyName || ap.serviceId;
    });

    return (
      <div>
        You have authenticated as <span className="font-weight-bold">{formatEnglishList(usernames)}</span>
      </div>
    );
  })();

  const [manualBtnText, automaticBtnText] = (() => {
    if (selectedMethod === GithubAuthenticationMethodE.APP_READ) {
      return ['Selected', 'Switch to Automatic'];
    }

    if (selectedMethod === GithubAuthenticationMethodE.APP_WRITE) {
      return ['Switch to Manual', 'Selected'];
    }
    return ['Select Manual', 'Select Automatic'];
  })();

  const installAppContent = (
    <AuthenticationMethodSelection
      manual={{
        description:
          'This application allows Trustle to import members, collaborators, and teams to track access. Your team will all access changes.',
        install: (
          <div className="body5 pt-2">
            <a
              href={`/api/connect/github/install?orgName=${orgName}&authenticationMethod=${GithubAuthenticationMethodE.APP_READ}`}
              rel="noreferrer"
              target="_blank"
            >
              Install App on GitHub.
            </a>
          </div>
        ),
        button: {
          label: manualBtnText,
          onClick: () => setSelectedMethod(GithubAuthenticationMethodE.APP_READ),
        },
      }}
      automatic={{
        description:
          'This application allows Trustle to import your GitHub Organization data and will automatically provision and deprovision GitHub Organization access.',
        install: (
          <div className="body5 pt-2">
            <a
              href={`/api/connect/github/install?orgName=${orgName}&authenticationMethod=${GithubAuthenticationMethodE.APP_WRITE}`}
              rel="noreferrer"
              target="_blank"
            >
              Install App on GitHub.
            </a>
          </div>
        ),
        button: {
          label: automaticBtnText,
          onClick: () => setSelectedMethod(GithubAuthenticationMethodE.APP_WRITE),
        },
      }}
      method={authMethod}
    />
  );

  return (
    <div className="github-app-setup connect-section">
      <p>
        We use GitHub Apps to connect your organization to Trustle. Some of the benefits of authenticating via GitHub
        Apps are:
      </p>
      <ul className="unindent body5">
        <li>Organization-level webhooks that will notify Trustle of changes.</li>
        <li>A smaller set of required permissions to function.</li>
      </ul>
      <div>
        <div className="pb-4">
          <div className="body3 pb-4">Verify Identity</div>
          <div className="body5-normal pb-2">{identityContent}</div>
          <div className="body6 pb-2">
            To enable Trustle for your account, we first need to verify that you are a member of the target
            organization.
          </div>
          <Button
            disabled={!orgName}
            onClick={() => {
              const params = {
                orgName,
                editConnection: `${editMode}`,
              };
              window.location.href = getConnectorOAuthRedirect(connection, params);
            }}
          >
            {_.size(authenticationProofs) > 0 ? 'Add New Account' : 'Verify Account'}
          </Button>
        </div>
        <div className="pb-4">
          <div className="body4 pb-3">Select one of the options below:</div>
          <div className="body5-normal pb-2">{installAppContent}</div>
        </div>
        <div className="text-right">
          {appInstallError && (
            <p className="text-danger">An error occured when verifying membership: {appInstallError}</p>
          )}
          <SubmitButton
            name="appComplete"
            disabled={!appVerification.identity || !orgName}
            onClick={async () => {
              const success = await saveCredentials();
              if (!success) {
                return;
              }
              props.onComplete({selectedAuthenticationMethod: selectedMethod});
            }}
            label={completeLabel}
          />
        </div>
      </div>
    </div>
  );
}
