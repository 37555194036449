import {Button, TextInput} from '@trustle/component-library';
import axios from 'axios';
import {Field, Form, Formik} from 'formik';
import React, {useState} from 'react';
import {object, string} from 'yup';

const FindOrgsForm = () => {
  const [emailSent, setEmailSent] = useState<string>();

  return (
    <>
      <h2 className="tr-text-3xl tr-border-solid tr-border-0 tr-border-b-2">
        {emailSent ? 'Check Your Email' : 'Recover Account'}
      </h2>
      {emailSent ? (
        <>
          <p className="tr-my-8">
            We've sent a special link to <strong>{emailSent}</strong>. Click the link to confirm your address and get
            started.
          </p>
          <p>
            Wrong email? Please <Button onClick={() => setEmailSent(undefined)}>re-enter your address</Button>
          </p>
        </>
      ) : (
        <Formik
          initialValues={{email: ''}}
          validationSchema={object().shape({email: string().email().required()})}
          onSubmit={({email}) => {
            void axios.post('/api/auth/forgot_pw', {email});
            setEmailSent(email);
          }}
        >
          {({isValid, dirty, isSubmitting}) => (
            <Form className="tr-my-8">
              <p>Please enter your email address and we'll send you instructions on how to recover your account.</p>
              <Field component={TextInput} label="Email Address" name="email" placeholder="user@example.com" />
              <Button type="submit" disabled={!isValid || !dirty || isSubmitting}>
                Reset Password
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default FindOrgsForm;
