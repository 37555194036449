import React from 'react';
import {DropdownItem} from '@trustle/component-library';

type Props = {
  onClick: () => void;
};

export function DeleteSystemAction({onClick}: Props) {
  return (
    <DropdownItem className="tr-text-red-500" key="delete-system" onClick={onClick}>
      Delete System
    </DropdownItem>
  );
}
