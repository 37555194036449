import React, {useState} from 'react';
import {observer} from 'mobx-react-lite';
import {useFeatureFlags, useMediaQuery, useRootStore} from 'src/lib/hooks';
import {NavLink, NavLinkProps} from 'react-router-dom';
import {ReactComponent as LogoText} from 'src/images/icons/logo-text.svg';
import {ReactComponent as LogoIcon} from 'src/images/icons/logo-icon.svg';
import {ReactComponent as CollapseIcon} from 'src/images/icons/collapse.svg';
import {ReactComponent as EllipsisVerticalIcon} from 'src/images/icons/ellipsis-vertical.svg';
import {Icon} from '@trustle/component-library';

type StyledNavLinkPropsT = {icon: React.ReactNode; text: React.ReactNode; isCollapsed?: boolean};
const StyledNavLink = ({icon, text, isCollapsed, ...rest}: NavLinkProps & StyledNavLinkPropsT) => (
  <NavLink
    className="tr-text-white/[0.6] tr-m-2 tr-p-2 tr-rounded-lg tr-flex tr-flex-nowrap tr-items-center tr-relative hover:tr-bg-white/[0.08] hover:tr-no-underline tr-group"
    activeClassName="tr-text-white/[1] tr-bg-white/[0.16]"
    {...rest}
  >
    {icon}
    <div
      className={
        isCollapsed
          ? 'tr-absolute tr-left-14 tr-rounded-lg tr-bg-white tr-text-black tr-p-2 tr-shadow-lg tr-hidden group-hover:tr-flex tr-z-50'
          : 'tr-flex tr-ml-2 tr-grow'
      }
    >
      {text}
    </div>
  </NavLink>
);

const Legal = ({isCollapsed}: {isCollapsed: boolean}) => {
  const [footerOptionsOpen, setFooterOptionsOpen] = useState(false);

  return (
    <div className="tr-text-white/[0.6] tr-relative">
      <div className={isCollapsed ? 'tr-rounded-lg tr-m-2 tr-p-2 hover:tr-bg-white/[0.16]' : 'hidden'}>
        <EllipsisVerticalIcon role="button" onClick={() => setFooterOptionsOpen(!footerOptionsOpen)} />
      </div>
      <div
        className={
          isCollapsed
            ? `tr-absolute tr-bottom-0 tr-left-16 tr-text-black tr-rounded-lg tr-shadow-xl tr-z-50 tr-bg-white ${
                footerOptionsOpen ? '' : 'hidden'
              }`
            : 'tr-text-white/[0.6] text-2xs'
        }
      >
        <a
          href="https://www.trustle.com/legal/privacy"
          target="_blank"
          rel="noreferrer"
          className={`tr-text-current tr-p-2 tr-flex tr-relative hover:tr-bg-white/[0.16] hover:tr-no-underline tr-whitespace-nowrap ${
            isCollapsed ? 'hover:tr-bg-trustle-selected tr-px-4 tr-rounded-t-lg' : 'tr-mx-2 tr-rounded-lg'
          }`}
        >
          Privacy Policy
        </a>
        <a
          href="https://www.trustle.com/legal/tos"
          target="_blank"
          rel="noreferrer"
          className={`tr-text-current tr-p-2 tr-flex tr-relative hover:tr-bg-white/[0.16] hover:tr-no-underline tr-whitespace-nowrap ${
            isCollapsed ? 'hover:tr-bg-trustle-selected tr-px-4 tr-rounded-b-lg' : 'tr-mx-2 tr-rounded-lg'
          }`}
        >
          Terms and Conditions
        </a>
      </div>
    </div>
  );
};

const TaskCount = observer(() => {
  const tasksStore = useRootStore().tasksStore;
  return (
    <span className="tr-rounded-full tr-border-solid tr-border tr-text-xs tr-w-6 tr-h-6 tr-flex tr-justify-center tr-items-center tr-ml-2">
      {tasksStore.assignedTotal > 99 ? '+99' : tasksStore.assignedTotal}
    </span>
  );
});

const TaskIndicator = observer(() => {
  const taskCount = useRootStore().tasksStore.selfTasksAndRequests.length;
  return <>{taskCount > 0 && <span className="tr-absolute tr-right-0 tr-bg-error tr-w-2 tr-h-2 tr-rounded-full" />}</>;
});

const AdminLink = observer((props: {isCollapsed: boolean}) => {
  const {currentUser} = useRootStore();

  return (
    <>
      {currentUser?.isOrgOwner && (
        <StyledNavLink
          to={`/admin/users`}
          icon={<Icon type="admin" className="tr-opacity-100" />}
          text="Admin"
          {...props}
          isActive={(_match, {pathname}) => pathname.startsWith('/admin')}
        />
      )}
    </>
  );
});

const Sidebar = observer(() => {
  const isSm = useMediaQuery('(max-width: 768px)');
  const featureFlagViewer = useFeatureFlags();
  const [isCollapsed, setIsCollapsed] = useState(isSm);
  const newTeamsApi = featureFlagViewer.isEnabled('enable_teams_api');
  const currentYear = new Date().getFullYear();
  return (
    <div
      className={`tr-flex-none tr-bg-trustle-navy tr-flex tr-flex-col tr-justify-between tr-box-content tr-text-white tr-transition-all ${
        isCollapsed ? 'tr-w-14' : 'tr-w-60'
      }`}
    >
      <nav className="tr-flex tr-flex-col">
        <div className="tr-border-0 tr-border-b-2 tr-border-solid tr-border-white/[.24] tr-px-4 tr-flex tr-items-center tr-relative tr-h-14">
          <CollapseIcon
            className={`tr-z-50 tr-absolute opacity-60 ${
              isCollapsed ? '-tr-right-8 tr-rotate-180 tr-text-black' : 'tr-right-0'
            }`}
            role="button"
            onClick={() => setIsCollapsed(!isCollapsed)}
            title={isCollapsed ? 'expand' : 'collapse'}
          />
          <div className="tr-flex tr-flex-nowrap">
            <LogoText title="trustle" className={`tr-mr-1 ${isCollapsed ? 'tr-hidden' : ''}`} />
            <LogoIcon />
          </div>
        </div>
        <StyledNavLink
          to="/access/active"
          icon={<Icon type="access" className="tr-opacity-100" />}
          text="Access"
          isCollapsed={isCollapsed}
          isActive={(_match, {pathname}) => pathname.startsWith('/access')}
          data-testid="sidebar-access"
        />
        <StyledNavLink
          to="/manage/active"
          icon={<Icon type="manage" className="tr-opacity-100" />}
          text="Systems"
          isCollapsed={isCollapsed}
          isActive={(_match, {pathname}) => pathname.startsWith('/manage') || pathname.startsWith('/resource/manage')}
          data-testid="sidebar-manage"
        />
        <StyledNavLink
          to="/tasks"
          icon={
            <div className="tr-relative">
              <Icon type="task" className="tr-opacity-100" />
              {isCollapsed && <TaskIndicator />}
            </div>
          }
          isCollapsed={isCollapsed}
          text={
            <div className="tr-flex tr-justify-between tr-grow">
              Tasks
              <TaskCount />
            </div>
          }
          data-testid="sidebar-tasks"
        />
        {newTeamsApi && (
          <StyledNavLink
            to="/teams"
            icon={<Icon type="team" className="tr-opacity-100" />}
            text="Teams"
            isCollapsed={isCollapsed}
            isActive={(_match, {pathname}) => pathname.startsWith('/teams')}
            data-testid="sidebar-teams"
          />
        )}
        <AdminLink isCollapsed={isCollapsed} data-testid="sidebar-admin" />
      </nav>
      <footer>
        <a
          href="mailto:support@trustle.com"
          className="tr-text-white/[0.6] tr-m-2 tr-p-2 tr-rounded-lg tr-flex tr-flex-nowrap tr-relative hover:tr-bg-white/[0.16] hover:tr-no-underline"
        >
          <Icon type="support" size="md" className="tr-opacity-100" />
          <span className={isCollapsed ? 'hidden' : 'ml-2'}>Support</span>
        </a>
        <Legal isCollapsed={isCollapsed} />
        <div
          className={`tr-text-2xs tr-text-white/[0.6] tr-flex tr-items-center tr-m-4 ${isCollapsed ? 'tr-ml-6' : ''}`}
        >
          <span className="tr-text-sm">&copy;</span>
          <span className={isCollapsed ? 'hidden' : 'tr-whitespace-nowrap tr-ml-1'}>
            {currentYear} Trustle Security all rights reserved
          </span>
        </div>
      </footer>
    </div>
  );
});

export default Sidebar;
