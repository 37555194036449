import React, {createContext, ReactNode, useContext, useEffect, useState} from 'react';
import {useLicenses} from './hooks/useLicenses';
import {useGetLicenseUsagePercentage} from './hooks/useGetLicenseUsagePercentage';
import {ViewType} from './types';

type M365LicenseUsageContextProps = {
  licenseUsagePercentageMap: Record<string, number>;
  view: ViewType;
  setView: (view: ViewType) => void;
};

const M365LicenseUsageContext = createContext<M365LicenseUsageContextProps | undefined>(undefined);

type M365LicenseUsageProviderProps = {
  resourceId: string;
  children: ReactNode;
};

export const M365LicenseUsageProvider: React.FC<M365LicenseUsageProviderProps> = ({resourceId, children}) => {
  const [licenseUsagePercentageMap, setLicenseUsagePercentageMap] = useState<Record<string, number>>({});
  const [view, setView] = useState<ViewType>('week');

  const {data: licensesResponse, fetchLicenses} = useLicenses({resourceId});
  const {getLicenseUsagePercentage} = useGetLicenseUsagePercentage({resourceId});

  useEffect(() => {
    void fetchLicenses();
  }, []);

  const handleSetLicenseUsagePercentage = async (licenseId: string) => {
    const percentage = (await getLicenseUsagePercentage(licenseId, view)) || 0;
    setLicenseUsagePercentageMap((prev) => ({...prev, [licenseId]: percentage}));
  };

  useEffect(() => {
    if (licensesResponse) {
      const licenseIds = licensesResponse.licenses.map((license) => license.id);
      void Promise.all(licenseIds.map((licenseId) => handleSetLicenseUsagePercentage(licenseId)));
    }
  }, [licensesResponse, view]);

  return (
    <M365LicenseUsageContext.Provider value={{licenseUsagePercentageMap, view, setView}}>
      {children}
    </M365LicenseUsageContext.Provider>
  );
};

export const useM365LicenseUsage = (): M365LicenseUsageContextProps => {
  const context = useContext(M365LicenseUsageContext);
  if (!context) {
    throw new Error('useM365LicenseUsage must be used within an M365LicenseUsageProvider');
  }
  return context;
};
