import React from 'react';
import {TextInput} from '@trustle/component-library';
import {User} from '../../../../stores/domainObjects/User';
import _ from 'lodash';
type Props = {
  user: User;
};
export function UserRoleAndAccess({user}: Props) {
  return (
    <div className="tr-flex tr-flex-col tr-gap-4">
      <h3>Role & Accesses</h3>
      <div className="tr-flex tr-flex-col">
        <div className="tr-flex tr-gap-4">
          <TextInput label="Manager" value={user.managerEmail} disabled />
          {/*TODO: Include link to manager*/}
          <TextInput label="Trustle Role" value={user.isOrgOwner ? 'Owner' : 'Regular User'} disabled />
        </div>
        <div className="tr-flex tr-gap-4">
          <TextInput label="Trustle User Type" value={_.startCase(user.type)} disabled />
          <TextInput label="Allowed to Login" value={user.allowLogin ? 'Yes' : 'No'} disabled />
        </div>
        <div className="tr-flex tr-w-1/2 tr-pr-2">
          <TextInput label="Status" value={_.startCase(user.status)} disabled />
        </div>
      </div>
    </div>
  );
}
