import React from 'react';
import {useHistory} from 'react-router-dom';
import {Icon} from '@trustle/component-library';

function PermissionDenied() {
  const history = useHistory();
  return (
    <div className="flex-align-column tr-justify-between tr-items-center centered-permission-msg">
      <div>You do not have permission to view the current page.</div>
      <div className="tr-mt-1 cursor tr-opacity-75 hover:tr-opacity-100">
        <a onClick={() => history.goBack()}>
          <Icon type="back" />
          <span className="tr-ml-1 tr-text-sm">Back</span>
        </a>
      </div>
    </div>
  );
}

export default PermissionDenied;
