import React, {useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHome} from '@fortawesome/free-solid-svg-icons';
import {faTimesCircle} from '@fortawesome/free-regular-svg-icons';
import './BillingAddress.scss';
import {useRootStore} from 'src/lib/hooks';
import {observer} from 'mobx-react';
import {Confirmation} from '@trustle/component-library';
import _ from 'lodash';

type PropsT = {
  address: any;
  onClick?: (e: any) => void;
  selected?: boolean;
  removeAllowed?: boolean;
};

const BillingAddress = observer(function PmCmp(props: PropsT) {
  const {address, onClick = () => {}, selected, removeAllowed} = props;
  const [showConfirmBillingMethod, setShowConfirmBillingMethod] = useState(false);
  const {org} = useRootStore();

  if (_.isNil(org)) {
    return <></>;
  }

  return (
    <div
      onClick={onClick}
      className={`billing-address ${props.onClick ? 'cursor' : ''} tr-flex tr-flex-row justify-content-start p-2 ${
        selected ? 'selected' : ''
      }`}
    >
      <div className="ml-3 mt-1 mr-3">
        <FontAwesomeIcon icon={faHome} />
      </div>
      <div className="capitalize-text text-navy font-weight-bold">
        {address.line1} {address.postalCode}
      </div>
      {removeAllowed && (
        <FontAwesomeIcon
          data-testid={`address-remove${address.id}`}
          icon={faTimesCircle}
          title="Remove Address"
          className="ml-2 mr-2 mt-1 remove"
          onClick={() => {
            setShowConfirmBillingMethod(true);
          }}
        />
      )}
      {showConfirmBillingMethod && (
        <Confirmation
          onConfirm={async () => org.removeBillingAddress(address.id)}
          onClose={() => setShowConfirmBillingMethod(false)}
          title={'Confirm Billing Address Removal'}
        >
          <div>
            Please confirm that you want to remove {address.line1} {address.postalCode}
          </div>
        </Confirmation>
      )}
    </div>
  );
});

export default BillingAddress;
