import React from 'react';
import {useState} from 'react';
import {SubmitButton} from 'src/components/design';
import './ResourceAccounts.scss';
import _ from 'lodash';
import axios from 'axios';
import {observer} from 'mobx-react';
import Import from 'src/components/Import';
import {Resource} from 'src/stores/domainObjects/Resource';
import {useToasts} from 'react-toast-notifications';
import {logger} from 'src/lib';
import {Badge, Button, Alert} from '@trustle/component-library';

import {useRootStore} from 'src/lib/hooks';

const columnDefs = [
  {key: 'lastName', name: 'last_name', isRequired: false},
  {key: 'firstName', name: 'first_name', isRequired: false},
  {key: 'accountType', name: 'account_type', isRequired: false},
  {key: 'account', name: 'account', isRequired: true},
  {key: 'trustleUser', name: 'trustle_user', isRequired: true},
];

const ResourceAccountsUpload = observer((props: {resource: Resource; onClose: () => void}) => {
  const {accountsStore} = useRootStore();
  const {resource, onClose} = props;
  const {accounts} = resource;
  const {addToast} = useToasts();
  const [rows, setRows] = useState<any[]>();
  const [invalidAccounts, setInvalidAccounts] = useState<string[]>([]);
  const [warning, setWarning] = useState<string | undefined>();
  const [error, setError] = useState<string | undefined>();
  const [filename, setFilename] = useState<string>('');

  const getRowNum = (message: string) => {
    try {
      const i = _.indexOf(message, '[');
      const j = _.indexOf(message, ']');

      if (i === -1 || j === -1) {
        return undefined;
      }
      return _.toNumber(_.join(_.slice(message, i + 1, j), ''));
    } catch {
      return undefined;
    }
  };

  const getError = (message: string) => {
    try {
      const splitted = _.split(message, '].') || [];
      return _.replace(splitted[1], '"', '');
    } catch {
      return undefined;
    }
  };

  const parseMessage = (message: any) => {
    const defaultMessage = 'An error ocurred while uploading';

    if (_.includes(message, '__parsed_extra')) {
      // message is: "entities[n].__parsed_extra" is not allowed
      const n = getRowNum(message);
      return !_.isNil(n) ? `Extra data columns provided ${n ? `on row #${n + 1}.` : '.'}` : defaultMessage;
    } else if (_.isMatch(message, /\[[0-9]\]/)) {
      // e.g. 1: "[0].accountType" must be one of [personal, service]
      // e.g. 2: "[11].trustleUser" is not allowed to be empty
      const n = getRowNum(message);
      const m = getError(message);

      return !_.isNil(n) || !_.isEmpty(m) ? `Error on row ${n ? `#${n + 1}` : ''}${m ? `: ${m}` : ''}` : defaultMessage;
    }
  };

  function validateAndSetRows(data: any) {
    const csvKeys = _.map(Object.keys(data[0]), (col) => {
      return _.replace(col, /_/g, '');
    });

    const missingRequiredColumns = _.filter(columnDefs, (c: any) => {
      return c.isRequired && !_.includes(csvKeys, c.name) && !_.includes(csvKeys, c.key);
    });

    if (_.size(missingRequiredColumns) > 0) {
      setError(`Missing required column(s): ${_.toArray(_.map(missingRequiredColumns, 'name'))}`);
      throw error;
    }

    const invalid: string[] = [];

    const validatedRows = _.compact(
      _.map(data, (row) => {
        const trimmedRow = _.mapValues(row, (v) => _.trim(_.toString(v)));
        const {account, firstName, lastName, trustleUser} = trimmedRow;

        const found = _.find(_.values(accounts), {account: account});

        if (_.isEmpty(account) || _.isEmpty(trustleUser) || _.isEmpty(firstName) || _.isEmpty(lastName)) {
          setWarning(
            'The following values are required: account, first_name, last_name, trustle_user. ' +
              'If not provided, the row will be ignored.'
          );

          if (!_.isEmpty(account)) {
            invalid.push(account);
          }
          return undefined;
        }

        if (_.isNil(found)) {
          invalid.push(account);
          return undefined;
        }

        return {
          ..._.pick(trimmedRow, ['firstName', 'lastName', 'account', 'trustleUser']),
          accountType: _.toLower(trimmedRow.accountType),
        };
      })
    );

    setInvalidAccounts(invalid);
    !_.isEmpty(validatedRows) ? setRows(validatedRows) : setError('No valid accounts uploaded.');
  }

  const onHandleData = async (data: any[], filename: string) => {
    setRows([]);
    setFilename(filename);
    setError(undefined);
    setWarning(undefined);
    setInvalidAccounts([]);

    try {
      if (_.isEmpty(data)) {
        setError('No data uploaded.');
        return;
      }

      validateAndSetRows(data);
    } catch (err: any) {
      addToast(`An error ocurred while parsing`, {appearance: 'error', autoDismiss: true});
      logger.error(err);
    }
  };

  const onCSVUpload = async () => {
    try {
      const {data} = await axios.post(`/api/accounts/csv_mapping/${resource.id}`, rows);
      const [unmapped, mapped] = _.partition(data, (row) => row.error);

      if (!_.isEmpty(mapped)) {
        addToast(`${_.size(mapped) === 1 ? '1 row' : `${_.size(mapped)} rows`} successfully uploaded`, {
          appearance: 'success',
          autoDismiss: true,
        });

        _.each(mapped, (item) => {
          const {uaid: id, updAccount} = item;
          accountsStore.updateAccountFromServer({id, ...updAccount});
        });
      }

      _.each(unmapped, (item: {account: any; error: boolean; message: string}) => {
        const warning = `${item.account}: ${item.message}`;
        addToast(warning, {appearance: 'warning', autoDismiss: false});
      });

      onClose();
    } catch (err: any) {
      const message = err?.response?.data?.error?.message;

      addToast(parseMessage(message), {appearance: 'error', autoDismiss: false});
      logger.error(err);
      onClose();
    }
  };

  return (
    <div className="resource-accounts-upload">
      {error && (
        <Alert
          colorVariant={'danger'}
          onClose={() => {
            setError(undefined);
          }}
        >
          {error}
        </Alert>
      )}
      {!_.isEmpty(warning) && (
        <Alert
          colorVariant={'warning'}
          onClose={() => {
            setWarning(undefined);
          }}
        >
          {warning}
        </Alert>
      )}
      {!_.isEmpty(invalidAccounts) && (
        <Alert
          colorVariant={'warning'}
          onClose={() => {
            setInvalidAccounts([]);
          }}
        >
          <div>
            {'The following accounts are not valid for this org so their corresponding row will be ignored: '}
            {invalidAccounts.map((name) => (
              <>
                <Badge className="mx-1" pill variant={'warning'}>
                  {name}
                </Badge>
              </>
            ))}
          </div>
        </Alert>
      )}

      {!_.isEmpty(rows) && (
        <div className="mb-3">
          <div>
            {'File uploaded: '}
            <span className="font-weight-bold">{`${filename}`}</span>
            {' | '}
            <Badge className="text-white" pill variant={'success'}>
              {`${_.size(rows) === 1 ? '1 row' : `${_.size(rows)} rows`}`}
            </Badge>
            {' ready to be saved'}
          </div>
        </div>
      )}

      <Import
        title={_.isEmpty(rows) ? 'Select a CSV file to upload' : 'Selecting a new file will update the last upload'}
        handleUpload={onHandleData}
        handleError={setError}
        headerTransform={'camelcase'}
      />
      {resource && (
        <div className="pt-2">
          <a
            href={`/api/accounts/csv_mapping/${resource.id}/example`}
            className="link"
            download
            data-testid="download-example"
          >
            Download example
          </a>
        </div>
      )}

      <div className="tr-flex tr-justify-end mt-5">
        <Button
          variant="secondary"
          onClick={() => {
            setRows([]);
            onClose();
          }}
        >
          Cancel
        </Button>
        <div className="tr-flex tr-justify-between">
          <SubmitButton
            label={'Save'}
            disabled={_.isEmpty(rows)}
            onClick={() => onCSVUpload()}
            data-testid="save-upload"
          />
        </div>
      </div>
    </div>
  );
});

export default ResourceAccountsUpload;
