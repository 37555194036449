import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {Link, useHistory, useParams} from 'react-router-dom';
import {
  Icon,
  Loading,
  Button,
  CircleGauge,
  Table,
  Tooltip,
  Tab,
  Accordion,
  TileButton,
} from '@trustle/component-library';
import {fuseSearch} from 'src/lib';
import {publishSessionEvent, useRootStore} from 'src/lib/hooks';
import {Resource} from 'src/stores/domainObjects/Resource';
import {observer} from 'mobx-react';
import {Search} from 'src/components/Search/Search';
import UnarchiveResourceModal from 'src/views/Resource/Edit/UnarchiveResourceModal';
import ConfirmDeleteResourceModal from 'src/views/Resource/Edit/ConfirmDeleteResourceModal';
import {Card} from 'react-bootstrap';
import {
  AccountsLinkedCol,
  ArchivedByCol,
  ArchivedOnCol,
  IconCol,
  IssueCol,
  LockedCol,
  NameCol,
  NumRecommendationsCol,
  ProvisionModeCol,
  ResourceCountCol,
  TrustleScoreCol,
} from 'src/components/design/tables/SharedColumnDefinitions/ResourceColumns';
import {groupBy} from 'src/utils';
import {useFeatureFlags} from 'src/lib/hooks';
import {ImportStatus} from 'src/types';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';

const EmptyTable = (addAvailable: boolean) => (
  <div className="tr-flex flex-column align-items-center">
    <img className="" src="/russel-magnifying-glass.svg" alt="" />
    <h2 className="mt-0 text-xl text-center font-weight-bold text-dark">No Systems Found</h2>
    {addAvailable && <div>Click the "Add System" button to add your first system.</div>}
  </div>
);

/* Empty column to keep similar alignment between tabs */
const EmptyColumn = {
  dataField: '',
  text: '',
  formatter: () => <></>,
  headerStyle: {width: '4%'},
  style: {width: '4%'},
};

const rowClasses = (row: Resource) => {
  return row?.connector?.lastImport?.status === ImportStatus.FAILED
    ? 'error-row'
    : row.isConfiguredAsIDP
    ? 'authz-row'
    : '';
};
const wrapperClasses = 'rounded-xl border border-black rounded bg-white';

const ActiveTable = observer(({systems}: {systems: Resource[]}) => {
  const activeColumns = [
    LockedCol,
    ProvisionModeCol,
    IssueCol,
    IconCol,
    NameCol,
    ResourceCountCol,
    AccountsLinkedCol,
    TrustleScoreCol,
    NumRecommendationsCol,
  ];

  return (
    <Table
      tableKey="managehomeactive"
      data={systems}
      columns={activeColumns}
      showEmptyElement={EmptyTable(true)}
      striped={false}
      rowClasses={rowClasses}
      wrapperClasses={wrapperClasses}
      bordered={false}
    />
  );
});

const ArchivedTable = observer(({resources}: {resources: Resource[]}) => {
  const {newResourceStore: resourceStore} = useRootStore();
  const [resourceToDelete, setResourceToDelete] = useState<Resource>();
  const [resourceToUnarchive, setResourceToUnarchive] = useState<Resource>();

  if (_.isEmpty(resources)) {
    return EmptyTable(false);
  }

  const archiveColumns = [
    EmptyColumn,
    IssueCol,
    IconCol,
    NameCol,
    ArchivedByCol,
    ArchivedOnCol,
    {
      dataField: 'actions',
      text: '',
      classes: 'show-on-hover',
      headerStyle: {width: '20%'},
      style: {width: '20%'},
      headerAlign: 'right',
      align: 'right',
      formatter: (_unused: any, system: any) => (
        <div className="action-buttons tr-flex tr-justify-end tr-items-align">
          <Tooltip
            size="sm"
            className="tr-mx-5 "
            content={<div className="tr-px-2">{`Unarchive ${system.name} resource`}</div>}
          >
            <Icon type="unArchive" className="cursor tr-mr-1" onClick={() => setResourceToUnarchive(system)} />
          </Tooltip>
          <Tooltip
            size="sm"
            className="tr-mx-5 "
            content={<div className="tr-px-2">{`Delete ${system.name} resource`}</div>}
          >
            <Icon type="remove" className="cursor" onClick={() => setResourceToDelete(system)} />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        data={resources}
        tableKey="managehomearchived"
        columns={archiveColumns}
        showEmptyElement={EmptyTable(false)}
        striped={false}
        rowClasses={rowClasses}
        wrapperClasses={wrapperClasses}
        bordered={false}
      />
      {resourceToDelete && (
        <ConfirmDeleteResourceModal
          resource={resourceToDelete}
          closeModal={(deleted: boolean) => {
            setResourceToDelete(undefined);
            if (deleted) {
              resourceStore.deleteResource(resourceToDelete);
            }
          }}
        />
      )}
      {resourceToUnarchive && (
        <UnarchiveResourceModal
          resource={resourceToUnarchive}
          closeModal={(unarchived: boolean) => {
            setResourceToUnarchive(undefined);
            if (unarchived) {
              void resourceToUnarchive.update({archived: !unarchived});
            }
          }}
        />
      )}
    </>
  );
});

const DisabledTable = observer(({resources}: {resources: Resource[]}) => {
  const {newResourceStore: resourceStore} = useRootStore();
  const [resourceToDelete, setResourceToDelete] = useState<Resource>();
  const [resourceToUnarchive, setResourceToUnarchive] = useState<Resource>();

  if (_.isEmpty(resources)) {
    return EmptyTable(false);
  }

  const columns = [
    EmptyColumn,
    IssueCol,
    {...IconCol, headerStyle: {width: '7%'}, style: {width: '7%'}},
    NameCol,
    {...EmptyColumn, headerStyle: {width: '40%'}, style: {width: '40%'}},
  ];

  return (
    <>
      <Table
        tableKey="managehomedisabled"
        data={resources}
        columns={columns}
        showEmptyElement={EmptyTable(false)}
        striped={false}
        rowClasses={rowClasses}
        wrapperClasses={wrapperClasses}
        bordered={false}
      />
      {resourceToDelete && (
        <ConfirmDeleteResourceModal
          resource={resourceToDelete}
          closeModal={(deleted: boolean) => {
            setResourceToDelete(undefined);
            if (deleted) {
              resourceStore.deleteResource(resourceToDelete);
            }
          }}
        />
      )}
      {resourceToUnarchive && (
        <UnarchiveResourceModal
          resource={resourceToUnarchive}
          closeModal={(unarchived: boolean) => {
            setResourceToUnarchive(undefined);
            if (unarchived) {
              void resourceToUnarchive.update({archived: !unarchived});
            }
          }}
        />
      )}
    </>
  );
});

const Filters = observer(({systems}: {systems: Resource[]}) => {
  const {tasksStore} = useRootStore();
  const history = useHistory();
  const numAccounts = _.sum(systems.map((s) => s.numAccounts));
  const groupedByType = groupBy(systems, 'type');
  return (
    <div className="mb-5">
      <Accordion
        variant="ternary"
        content={
          <div className="body3 tr-flex tr-justify-between">
            <span>
              <Icon type="chevronRight" />
              Accounts
            </span>
            {numAccounts}
          </div>
        }
      >
        {() => {
          return (
            <Card className="tr-border-0 border-bottom rounded-0">
              <Card.Body className="px-0">
                <Table
                  tableKey="managehomeaccounts"
                  data={_.keys(groupedByType).map((type) => {
                    const systems = groupedByType[type];
                    return {
                      icon: systems[0]?.iconImage,
                      systems: groupedByType[type].length,
                      accounts: _.sum(_.map(systems, 'numAccounts')),
                    };
                  })}
                  striped
                  columns={[
                    {
                      dataField: 'systems',
                      text: '# of Systems',
                    },
                    {
                      dataField: 'accounts',
                      text: '# of Accounts',
                    },
                  ]}
                />
              </Card.Body>
            </Card>
          );
        }}
      </Accordion>
      <Accordion
        variant="ternary"
        content={
          <div className="body3 tr-flex tr-justify-between">
            <span>
              <Icon type="chevronRight" />
              Systems
            </span>
            {systems.length}
          </div>
        }
      >
        {() => {
          return (
            <div className="mt-3 flex-wrap px-0 tr-flex justify-content-center">
              {_.keys(groupedByType).map((type) => {
                const systems = groupedByType[type];
                return (
                  <TileButton
                    size="sm"
                    className="tr-my-1 tr-mx-2"
                    name={type as SystemIconsTypesEnum}
                    badge={systems.length > 1 ? systems.length : undefined}
                    onClick={() => {
                      history.push({search: `?Type=${systems[0].typeDisplayName}`});
                    }}
                    data-testid={`manage-${type}`}
                    key={type}
                  />
                );
              })}
            </div>
          );
        }}
      </Accordion>
      <Accordion
        variant="ternary"
        content={
          <div className="body3 tr-flex tr-justify-between">
            <span>
              <Icon type="chevronRight" />
              Pending Tasks
            </span>
            {tasksStore.openTasksAndRequests.length}
          </div>
        }
      >
        {() => {
          return (
            <Card className="tr-border-0 border-bottom rounded-0">
              <Card.Body>
                <Button variant="secondary" onClick={() => history.push('/tasks')}>
                  {tasksStore.openTasksAndRequests.length} Pending Tasks
                </Button>
              </Card.Body>
            </Card>
          );
        }}
      </Accordion>
    </div>
  );
});

export enum ManageTab {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  ARCHIVED = 'archived',
}

const ManageHome = observer(() => {
  const {newResourceStore: resourceStore} = useRootStore();
  const history = useHistory();

  const [query, setQuery] = useState('');
  const [filters, setFilters] = useState<Record<string, string | undefined>>({});

  const params = useParams<{tab: string}>();
  const [selectedIndex, setSelectedIndex] = useState<number>(
    params.tab === ManageTab.ACTIVE ? 0 : params.tab === ManageTab.DISABLED ? 2 : 1
  );
  const featureFlags = useFeatureFlags();
  const toggleConnectionEnabled = featureFlags.isEnabled('toggle_connection_feature');

  useEffect(() => {
    void resourceStore.loadSystems({includeArchived: true});
  }, []);

  publishSessionEvent();

  if (resourceStore.loadingSystems) {
    return <Loading dataLoadStatus={{isError: resourceStore.error}} />;
  }

  const {activeSystems, archivedSystems, disabledSystems, percentOfRecsCompleted, percentOfAccountRecsCompleted} =
    resourceStore;

  const systems =
    params.tab === ManageTab.ACTIVE
      ? activeSystems
      : params.tab === ManageTab.DISABLED
      ? disabledSystems
      : archivedSystems;

  const filteredSystems = systems.filter(
    (resource) =>
      (_.isNil(filters.Type) || filters.Type === resource.typeDisplayName) &&
      (_.isNil(filters.System) || filters.System === resource.name)
  );

  const searchedResources = fuseSearch(query, ['name', 'description'], filteredSystems);

  const row = (
    <div className="tr-mb-4 tr-flex tr-flex-row tr-items-center">
      <div className="tr-grow">
        <Search
          placeholder={`Type to search ${params.tab} systems`}
          data-testid="manage-search"
          filterOptions={{
            Type: _.map(filteredSystems, 'typeDisplayName'),
            System: _.map(filteredSystems, 'name'),
            has: ['unlinked accounts', 'uncompleted recommendations'],
          }}
          onChange={(query, filters) => {
            setQuery(query);
            setFilters(filters);
          }}
          className={'tr-my-2'}
        />
      </div>
      {params.tab === ManageTab.ACTIVE && (
        <div className="tr-w-auto">
          <Button onClick={() => history.push('/resources/create')}>Add System</Button>
        </div>
      )}
    </div>
  );

  const hasSystems = _.size(activeSystems) > 0;

  return (
    <div className="bg-light tr-h-full manage-home">
      <div className="h-32 text-white tr-bg-trustle-royal tr-flex align-items-center">
        <div className="container tr-flex align-items-center">
          <Icon type="manage" size="xl" className="tr-opacity-100" />
          <div className="flex-col mb-0 ml-4 tr-flex">
            <h1 className="trustle-header-text">Your Managed Systems</h1>
            <span className="body6">Below are a list of resources that you are an owner of</span>
          </div>
        </div>
      </div>
      <div className="container tr-mt-2 tr-mb-5 compliance-page bg-light">
        <div className="tr-flex tr-flex-col md:tr-flex-row">
          <div className="compliance-wrapper tr-w-full lg:tr-w-1/4 tr-mr-8">
            <div className="mb-5 tr-flex flex-column align-items-center">
              <h1>Trustle Score</h1>
              <div className="tr-w-56">
                <CircleGauge
                  circles={[
                    {color: '#32C5FF', percent: hasSystems ? percentOfAccountRecsCompleted : 0},
                    {color: '#FF9500', percent: hasSystems ? percentOfRecsCompleted : 0},
                  ]}
                >
                  {hasSystems ? (
                    <span className="text-5xl font-weight-bold">
                      {Math.round(100 * _.mean([percentOfRecsCompleted, percentOfAccountRecsCompleted]))}%
                    </span>
                  ) : (
                    <h2 className="tr-mt-0 tr-text-xl tr-text-center tr-font-weight-bold tr-text-gray-600 ">
                      No systems
                    </h2>
                  )}
                </CircleGauge>
              </div>
            </div>
            <Filters systems={systems} />
          </div>
          <div className="tr-w-full lg:tr-w-3/4 tr-mt-2">
            <Tab.Group selectedIndex={selectedIndex} onChange={(i: number) => setSelectedIndex(i)}>
              <Tab.List variant="line">
                <Tab as={Link} to={ManageTab.ACTIVE} data-testid={ManageTab.ACTIVE} key={ManageTab.ACTIVE}>
                  {`Active (${activeSystems.length})`}
                </Tab>
                {toggleConnectionEnabled && (
                  <Tab as={Link} to={ManageTab.DISABLED} data-testid={ManageTab.DISABLED} key={ManageTab.DISABLED}>
                    {`Disabled (${disabledSystems.length})`}
                  </Tab>
                )}
                <Tab as={Link} to={ManageTab.ARCHIVED} data-testid={ManageTab.ARCHIVED} key={ManageTab.ARCHIVED}>
                  {`Archived (${archivedSystems.length})`}
                </Tab>
              </Tab.List>
              <Tab.Panels>
                <Tab.Panel data-testid={ManageTab.ACTIVE} key={ManageTab.ACTIVE}>
                  {row}
                  <ActiveTable systems={searchedResources} />
                </Tab.Panel>
                {toggleConnectionEnabled && (
                  <Tab.Panel data-testid={ManageTab.DISABLED} key={ManageTab.DISABLED}>
                    {row}
                    <DisabledTable resources={searchedResources} />
                  </Tab.Panel>
                )}
                <Tab.Panel data-testid={ManageTab.ARCHIVED} key={ManageTab.ARCHIVED}>
                  {row}
                  <ArchivedTable resources={searchedResources} />
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>
      </div>
    </div>
  );
});

export default ManageHome;
