import _ from 'lodash';
import axios from 'axios';
import React from 'react';
import {TypeaheadSelect} from '../form/TypeaheadSelect/TypeaheadSelect';
import {useField} from 'formik';
import './TrustleUserSelector.scss';
import {formatUserNameAndEmail} from 'src/lib';

type PropsT = {
  id?: string;
  label?: string | React.ReactElement;
  required?: boolean;
  name: string;
  description?: string;
  subdescription?: string | React.ReactElement;
  multiple?: boolean;
  limit?: number;
  horizontal?: boolean;
  placeholder?: string;
  emptyLabel?: string;
  disabled?: boolean;
  suggestUrl?: string;
};

/** @deprecated use Select from component library when possible */
export const TrustleUserSelector = React.forwardRef((props: PropsT, ref: any) => {
  const typeaheadSelectRef = React.useRef<any>();

  const [, meta, helpers] = useField(props);

  const url = props.suggestUrl ? props.suggestUrl : '/api/orgs/users/suggest';
  async function loadUserSuggestions(email: string) {
    const suggestionsResponse = await axios.post(url, {email});
    return _.map(suggestionsResponse.data, (suggestion: any) => {
      return {
        id: suggestion.id,
        value: `${suggestion.email}`,
        label: formatUserNameAndEmail(suggestion) ?? `${suggestion.email}`,
      };
    });
  }

  const extraProps = _.pick(props, [
    'id',
    'name',
    'label',
    'required',
    'description',
    'subdescription',
    'multiple',
    'placeholder',
    'onChangeNewUser',
    'horizontal',
    'allowNewOutside',
    'emptyLabel',
    'disabled',
  ]);

  React.useImperativeHandle(ref, () => ({
    loadUserSuggestions: (email: string) => loadUserSuggestions(email),
  }));

  return (
    <>
      <TypeaheadSelect
        ref={typeaheadSelectRef}
        id="trustleUserSelector"
        validated={false}
        onSearch={loadUserSuggestions}
        onChange={(values: any, _ids?: string[], elements?: any[]) => {
          if (props.multiple) {
            helpers.setValue(elements);
            return;
          }
          helpers.setValue([].concat(values));
        }}
        defaultSelected={meta.value}
        {...extraProps}
      />
      <div className="tr-w-full">{meta.touched && meta.error && <div className="text-danger">{meta.error}</div>}</div>
    </>
  );
});
