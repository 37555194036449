import React from 'react';
import {Card} from 'react-bootstrap';
import _ from 'lodash';
type ShowErrorPropsT = {
  children?: any;
  title: string | string[];
};

function ShowError(props: ShowErrorPropsT) {
  const {title, children} = props;
  let formattedTitle = title;
  let label = 'Error:';
  if (_.isArray(title)) {
    formattedTitle = _.join(title, ', ');
    if (_.size(title) > 1) {
      label = 'Errors:';
    }
  }

  return (
    <>
      <Card>
        <Card.Header>
          <span className="bolder text-danger">
            <span className="font-weight-bold">{label}</span> {formattedTitle}
          </span>
        </Card.Header>
        <Card.Body>
          <Card.Text>{children}</Card.Text>
        </Card.Body>
      </Card>
    </>
  );
}

export default ShowError;
