import {Icon, Tooltip} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {ReactNode} from 'react';
import {Link} from 'react-router-dom';
import {capitalizePhrase} from 'src/utils';
import {formatUserName} from 'src/lib';

import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import ShowAuthorizedAccount from './ShowAuthorizedAccount';

type PropsT = {access: AccessRecord};

const TeamAccountColumnContent = observer(({ access }: PropsT) => {
  const account = access.forAccount;

  if (!account) {return null;}

  const getResourceCategory = (): string => {
    let resource = access?.referencedResource;

    while (resource) {
      if (resource.category) {return resource.category;}
      const parentId = resource.parentId;
      if (!parentId) {break;}
      resource = account.rootStore.newResourceStore.resourceMap[parentId];
    }

    return 'Team';
  };

  const renderAccountTooltipContent = (): ReactNode => {
    const categoryLabel = capitalizePhrase(getResourceCategory().replace(/_/g, ' '));
    
    return (
      <div>
        <b>{`${categoryLabel}: `}</b>
        {account?.account}
        {account && (
          <span className="inline-flex-align ml-1">
            <ShowAuthorizedAccount account={account} />
          </span>
        )}
      </div>
    );
  };

  const numberOfSubteams = _.size(account.referencedEntity?.allNestedResources);
  const ids = _.map(account.referencedEntity?.allNestedResources, 'id');

  return (
    <Tooltip content={renderAccountTooltipContent()} position="bottom">
      <div className="my-1 tr-flex tr-flex-row justify-content-start align-items-center">
        <Icon type="team" size="sm" />
        <div className="tr-flex flex-column">
          {account.forUser && (
            <Link to={`/users/${account.forUser.id}`}>{formatUserName(account.forUser)}</Link>
          )}
          {account.referencedEntity && (
            <div className="tr-flex tr-flex-row inline-flex-align">
              <div className="grayed-out text-break">{account.account ?? ''}</div>
              {numberOfSubteams > 0 && (
                <div>
                  <Link
                    className="ml-3"
                    to={`/resource/manage/${account.referencedEntity.rootSid}/resources?Opened=${[
                      ...account.referencedEntity.ancestorIds,
                      ...ids,
                    ].join(',')}`}
                  >{`${_.size(account.referencedEntity.allNestedResources)} subteams`}</Link>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </Tooltip>
  );
});

export default TeamAccountColumnContent;
