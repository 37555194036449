import React from 'react';
import _ from 'lodash';
import {ConnectorsDataDataTypeE} from 'src/types';
import {RoleDetail} from './AwsTypes';
import Highlight from 'src/components/highlightjs/Highlight';
import ListTabPolicies from './ListTabPolicies';
import GroupServiceUsage from 'src/views/ConnectorServiceUsage/GroupServiceUsage';
import {Resource} from 'src/stores/domainObjects/Resource';
import {PermTabModeE} from 'src/views/Resource/Setup/PermissionDetailsModal';
import {Accordion, Card} from 'react-bootstrap';
import {Permission} from 'src/stores/domainObjects/Permission';
import NoData from 'src/components/NoData';
import {Table} from '@trustle/component-library';

type PropsT = {
  connectorsData: any;
  permission: Permission;
  system: Resource;
  defaultTabMode?: PermTabModeE;
};

export default function ViewRoleMetadata(props: PropsT) {
  const {connectorsData, permission, system} = props;
  if (connectorsData === undefined) {
    return null;
  }
  const permissionMetadata = _.find(connectorsData, (cd) => {
    return cd.dataType === ConnectorsDataDataTypeE.ENTITY_DATA;
  });
  if (_.isEmpty(permissionMetadata)) {
    return null;
  }
  const data: RoleDetail = permissionMetadata.data;

  const showRolePolicyList = () => {
    const listPolicies = _.map(data.RolePolicyList, (e) => {
      return e.PolicyName && e.PolicyDocument
        ? {id: e.PolicyName || '', title: e.PolicyName || '', document: e.PolicyDocument || ''}
        : null;
    });
    const filteredPolicies = _.compact(listPolicies);
    if (_.isEmpty(filteredPolicies)) {
      return null;
    }
    return (
      <div className="mt-2 card">
        <div className="body5 mt-2 mb-2 ml-2">Role Policy List</div>
        <div>
          <ListTabPolicies listPolicies={filteredPolicies} />
        </div>
      </div>
    );
  };
  const showGeneralData = () => {
    return _.map(
      {
        ..._.pick(data, ['Arn', 'RoleId', 'RoleName', 'Path', 'CreateDate']),
        LastUsedDate: data.RoleLastUsed?.LastUsedDate || '-',
        LastUsedRegion: data.RoleLastUsed?.Region || '-',
      },
      (v, k) => {
        return {key: k, value: v};
      }
    );
  };
  const showTags = () => {
    if (_.isEmpty(data.Tags)) {
      return <NoData>Hmm, we couldn’t seem to find any tags data yet.</NoData>;
    }

    return (
      <Table
        data={data.Tags ?? []}
        tableKey={'awsrolemetadata'}
        overrideKeyField="Key"
        columns={[
          {dataField: 'Key', text: 'Key'},
          {dataField: 'Value', text: 'Value'},
        ]}
        striped={true}
        caption="Tags"
        wrapperClasses="simple-table-bordered mt-2"
        bordered={false}
      ></Table>
    );
  };

  const showPermissionsPolicies = () => {
    const attachedManagedPolicies = data.AttachedManagedPolicies ?? [];

    if (_.isEmpty(attachedManagedPolicies)) {
      return <div className="text-center body5 mt-4">No Permissions Policies associated with the resource.</div>;
    }
    return (
      <>
        <Accordion className="mt-2" defaultActiveKey="0">
          {_.map(attachedManagedPolicies, (p, index) => {
            return (
              <Card key={`${p.PolicyName} ${index}`}>
                <Accordion.Toggle className="pointer" as={Card.Header} eventKey={_.toString(index)}>
                  <span className="body5">{p.PolicyName}</span>
                  <span className="body6 text-muted ml-3">{p.PolicyArn}</span>
                </Accordion.Toggle>

                <Accordion.Collapse eventKey={_.toString(index)}>
                  <Card.Body>
                    {p.PolicyDocument && <Highlight language="yaml">{decodeURIComponent(p.PolicyDocument)}</Highlight>}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            );
          })}
        </Accordion>
      </>
    );
  };

  const showTrustRelationships = () => {
    if (!data.AssumeRolePolicyDocument) {
      return <div className="text-center body5 mt-4">No Trust relationships associated with the resource.</div>;
    }
    return (
      <div className="mt-2 card">
        <div className="body5 mt-2 mb-0 ml-2">Trust relationships</div>
        <div className="ml-2 body6 text-muted">Entities that can assume this role under specified conditions.</div>
        <div>{<Highlight language="yaml">{decodeURIComponent(data.AssumeRolePolicyDocument)}</Highlight>}</div>
      </div>
    );
  };
  const showServiceUsages = () => {
    return (
      <GroupServiceUsage
        permission={permission}
        system={system}
        hideMemberColumn={true}
        emptyMessage="This role does not have any service permissions."
      />
    );
  };
  const rolePolicyList = showRolePolicyList();
  let options = {
    [PermTabModeE.GENERAL]: showGeneralData(),
  };

  if (!_.isNil(rolePolicyList)) {
    options = {...options, ...{[PermTabModeE.POLICY_LIST]: rolePolicyList}};
  }

  options = {
    ...options,
    ...{
      [PermTabModeE.PERMISSIONS_POLICIES]: showPermissionsPolicies(),
      [PermTabModeE.TRUST_RELATIONSHIPS]: showTrustRelationships(),
      [PermTabModeE.TAGS]: showTags(),
      [PermTabModeE.SERVICE_USAGE]: showServiceUsages(),
    },
  };
  return options;
}
