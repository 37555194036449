import React from 'react';
import {useAccountFilters} from '../../AccountsFiltersContext';
import {ACCOUNT_MATCH_STATUSES} from 'src/views/v2/admin/admin-users-panel/constants';
import _ from 'lodash';
import {AccountsSummaryResponse} from '../../hooks/useAccountsSummary';
import {AccountMatchStatus} from 'src/views/v2/admin/admin-users-panel/types';
import OverviewItem from 'src/views/v2/common/OverviewItem';

type Props = {
  accountsSummary?: AccountsSummaryResponse;
};

type AccountMatchStatusWUnselected = AccountMatchStatus | 'unselected';

export function UnlinkedAccountsView({accountsSummary}: Props) {
  const {filters, setFilter} = useAccountFilters();
  const {matchType} = filters;

  return (
    <div className="tr-flex align-items-center rounded-2xl tr-shadow-md tr-border-solid tr-border-[2px] tr-border-gray-100 tr-p-5 tr-gap-4">
      {Object.values(ACCOUNT_MATCH_STATUSES).map((aMatchStatus) => (
        <>
          <OverviewItem
            key={aMatchStatus}
            onClick={() => setFilter('matchType', aMatchStatus === matchType ? '' : aMatchStatus)}
            value={getAccountsSummaryValue(aMatchStatus, accountsSummary)}
            label={_.startCase(aMatchStatus)}
            selected={aMatchStatus === matchType}
            loading={false}
          />
        </>
      ))}
    </div>
  );
}

function getAccountsSummaryValue(status: AccountMatchStatusWUnselected, accountsSummary?: AccountsSummaryResponse) {
  if (!accountsSummary || !status) {
    return 0;
  }
  switch (status) {
    case 'perfect':
      return accountsSummary.perfectMatchAccountCount;
    case 'partial':
      return accountsSummary.partialMatchAccountCount;
    case 'none':
      return accountsSummary.noMatchAccountCount;
  }
}
