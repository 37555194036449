import _ from 'lodash';
import {useDataLoader, useFeatureFlags, useDebounce, useQueryParams, useDataFilter} from './hooks';
import {ConnectionServiceE, OwnerAccountAction, AccessRequestChangeType, ImportStateServiceE} from 'src/types';
import moment from 'moment';
import axios from 'axios';
import * as logger from './logger';
import React from 'react';
import * as EmailValidator from 'email-validator';
import {fuseSearch} from './filter/fuseSearch';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import {capitalizeFirstLetter} from '../utils';

const isSet = (o: any) => o !== null && o !== undefined;
const isString = (o: any) => (isSet(o) && typeof o === 'string') || o instanceof String;
const isEmail = (v: any) => isString(v) && /\S+@\S+\.\S+/.test(v);

function fixPlurality(v: number, u: string) {
  if (v > 1 && u[u.length - 1] !== 's') {
    return `${u}s`;
  }
  if (v === 1 && u[u.length - 1] === 's') {
    return u.slice(0, -1);
  }
  return u;
}

function formatDuration(durationValue: number, durationUnit: string) {
  return `${durationValue} ${fixPlurality(durationValue, durationUnit)}`;
}

function capFirst(n: string, onlyFirst = false) {
  if (n && n.length) {
    if (onlyFirst) {
      n = n[0];
    }
    return n[0].toUpperCase() + n.slice(1);
  }
  return '';
}

const getActions = (accessRecord: AccessRecord): OwnerAccountAction[] => {
  const locked = _.defaultTo(accessRecord?.forPermission?.isLocked, false);
  if (locked || !accessRecord || !accessRecord.forAccount?.uid) {
    return [];
  }

  const {statusObject} = accessRecord;
  const actionNames: OwnerAccountAction[] = [];
  if (statusObject.iP && !statusObject.iPD) {
    actionNames.push(OwnerAccountAction.START_DEPROVISION);
  }
  if (statusObject.iA && !statusObject.iPR) {
    actionNames.push(OwnerAccountAction.START_REVOKE);
  }
  if (statusObject.iP && !statusObject.iPR) {
    actionNames.push(OwnerAccountAction.START_REVOKE);
  }
  if (statusObject.iPP) {
    actionNames.push(OwnerAccountAction.START_REVOKE);
  }
  if (statusObject.iPP || statusObject.iPA || statusObject.iPD || statusObject.iPR) {
    actionNames.push(OwnerAccountAction.CANCEL_PENDING);
  }

  return _.uniq(actionNames);
};

function formatFullDate(timestamp?: string): string {
  if (!timestamp) {
    return '';
  }
  return moment(timestamp).format('M/D/Y hh:mm A');
}

function formatDateFromNow(timestamp: string | undefined): string {
  if (_.isEmpty(timestamp)) {
    return '';
  }
  return moment(timestamp).fromNow();
}

function formatUnixDaysFromNow(timestamp: number | undefined): number | string {
  if (!timestamp) {
    return '';
  }
  return moment.unix(timestamp).diff(moment(), 'days');
}
function createdDaysAgo(timestamp: string | undefined): string {
  const created = moment(timestamp);
  return created.fromNow();
}

function formatServiceName(service: ConnectionServiceE | ImportStateServiceE) {
  if (_.includes(ConnectionServiceE, service)) {
    return 'Accounts, Resources and Access Records';
  }

  if (_.includes([ImportStateServiceE.AWS_USAGE_SERVICE, ImportStateServiceE.OKTA_USAGE_SERVICE], service)) {
    return 'Service Usage Data';
  }

  return 'Account Security Data';
}

// TODO (jg): make output prettier `${largerNum}-${smallerNum} ${time units} ago`
function dateRange(timestamps: (string | undefined)[]): string {
  if (_.isEmpty(timestamps)) {
    return '';
  }
  if (timestamps.length === 1) {
    return moment(timestamps[0]).fromNow();
  }
  const sorted = _.compact(timestamps).sort();
  const newest = moment(sorted[0]).fromNow();
  const oldest = moment(sorted[sorted.length - 1]).fromNow();
  return `${newest} - ${oldest}`;
}

function formatUserName(user: {id?: string; firstname?: string; lastname?: string; email?: string} = {}): string {
  const {id, firstname, lastname, email} = user;
  if (id === 'system') {
    return 'System';
  }

  if (!firstname && !lastname && email) {
    return email;
  }

  const titleCasedStrings = _.compact([firstname, lastname]).map(capitalizeFirstLetter);

  return titleCasedStrings.join(' ');
}

const isValidEmailAccount = (email?: string) => {
  return _.isEmpty(email) || (!_.isEmpty(email) && EmailValidator.validate(email || ''));
};

function formatUserNameAndEmail(user: {firstname?: string; lastname?: string; email?: string} = {}): string {
  if (!user) {
    return '[Undefined User]';
  }

  const {firstname, lastname, email} = user;
  if (!firstname && !lastname) {
    return email ?? '';
  }

  return _.compact([firstname, lastname, `(${email})`]).join(' ');
}

function formatEnglishList(arrayElems: string[]): string {
  if (arrayElems.length === 0) {
    return '';
  }
  if (arrayElems.length <= 2) {
    return arrayElems.join(' and ');
  }
  return `${_.dropRight(arrayElems, 1).join(', ')}, and ${arrayElems[arrayElems.length - 1]}`;
}

async function retryUntilConditionMet(
  url: string,
  condition: (data: any) => boolean,
  timeout: number,
  maxTimes: number
): Promise<any> {
  //If condition does not return true will retry after a certain timeout defined by timeout param

  function waitFor(millSeconds: number) {
    return new Promise<void>((resolve) => {
      setTimeout(() => {
        resolve();
      }, millSeconds);
    });
  }

  async function attempRetry(nthTry: number): Promise<any> {
    if (nthTry >= maxTimes) {
      return {error: true};
    }

    try {
      const res = await axios.get(url);

      if (condition(res.data)) {
        return res.data;
      } else {
        await waitFor(nthTry * timeout);
        return attempRetry(nthTry + 1);
      }
    } catch (e) {
      await waitFor(timeout);
      return attempRetry(nthTry + 1);
    }
  }

  await attempRetry(1);
}

const connectorServiceDictionary: _.Dictionary<string> = {
  aws: 'AWS',
  github: 'GitHub',
  slack: 'Slack',
  gapps: 'Google Apps',
};
function humanizeConnectorName(connectorName: string): string {
  return connectorServiceDictionary[connectorName] || capFirst(connectorName);
}

function getTimeRange(value?: string): React.ReactElement {
  if (!value) {
    return <span>Not set</span>;
  }
  const expDate = moment(value);
  const daysTo = expDate.diff(moment(), 'days');
  const needAttention = daysTo < 7;
  return <span className={needAttention ? 'text-danger' : ''}>{`${createdDaysAgo(expDate.toString())}`}</span>;
}

function requestChangeLabel(changeType: string) {
  const changeLabelMap: _.Dictionary<string> = {
    [AccessRequestChangeType.DESIGNATED_APPROVED]: 'Designated User Approved',
    [AccessRequestChangeType.DESIGNATED_REJECTED]: 'Designated User Rejected',
  };

  return changeLabelMap[changeType] || _.startCase(changeType);
}

function formatAsDollarsAndCents(fractionalAmount: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  });
  return formatter.format(fractionalAmount);
}
function humanize(text: string) {
  return _.upperFirst(_.lowerCase(text));
}

/** Resolve multiple promises with limited concurrency. By default promises are processed in batches of 5. */
async function pLimit<T>(promises: Promise<T>[], concurrency = 5): Promise<T[]> {
  const results: T[] = [];
  for (const chunk of _.chunk(promises, concurrency)) {
    results.push(...(await Promise.all(chunk)));
  }

  return results;
}

export {
  isSet,
  isString,
  isEmail,
  fixPlurality,
  formatDuration,
  capFirst,
  useDataLoader,
  useFeatureFlags,
  useDebounce,
  useQueryParams,
  isValidEmailAccount,
  formatUserNameAndEmail,
  formatUserName,
  retryUntilConditionMet,
  getActions,
  logger,
  formatFullDate,
  humanizeConnectorName,
  getTimeRange,
  formatEnglishList,
  useDataFilter,
  formatDateFromNow,
  formatUnixDaysFromNow,
  createdDaysAgo,
  dateRange,
  fuseSearch,
  requestChangeLabel,
  formatAsDollarsAndCents,
  humanize,
  pLimit,
  formatServiceName,
};
