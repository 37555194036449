import React from 'react';
import {Button} from '@trustle/component-library';
import _ from 'lodash';
import {Modal} from 'react-bootstrap';
import axios from 'axios';
import {useCookies} from 'react-cookie';
import {useHistory, useLocation} from 'react-router-dom';
import './CookiesConsentPopup.scss';

export enum ConsentAction {
  ACCEPT = 'accept',
  DECLINE = 'decline',
}

export enum ConsentCategory {
  STRICTLY_REQUIRED = 'strictly-required',
  FUNCTIONAL = 'functional',
  ANALYTICAL = 'analytical',
  ADVERTISING = 'advertising',
}

function CookiesConsentPopup() {
  const history = useHistory();
  const location = useLocation();
  const [, setCookie] = useCookies();

  const setConsentCookie = async (categories: ConsentCategory[]) => {
    const oneYearFromNow = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
    setCookie('cookies-consent', {action: ConsentAction.ACCEPT, categories}, {expires: oneYearFromNow});
  };

  return (
    <Modal animation={false} id="cookies-consent-banner" centered={false} show={true} onHide={() => {}}>
      <Modal.Header closeButton={false}>
        <Modal.Title className={'capitalize-text'}>{'We Respect Your Privacy'}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container tr-mx-auto">
          <div className="system-metadata tr-flex tr-flex-col tr-max-w-3xl tr-mx-auto">
            <span>
              We use different types of cookies to optimize your experience on our website.{' '}
              <b>Essential cookies are required for this website to properly work. </b>
              You can learn more about how we use cookies by visiting our{' '}
              <a target="_blank" rel="noreferrer" href="https:/trustle.com/legal/privacy">
                Privacy Policy
              </a>
              .
            </span>

            <div className="mt-4 tr-flex tr-justify-end">
              <Button
                variant="ternary"
                disabled={location.pathname === '/login'}
                onClick={() => {
                  history.push('/logout');
                  void axios.post('/api/auth/user_cookies_consent', {categories: [], widthdrawConsent: true});
                }}
              >
                Decline
              </Button>
              <Button variant="primary" onClick={() => setConsentCookie(_.values(ConsentCategory))}>
                Allow All
              </Button>
              <Button variant="primary" onClick={() => setConsentCookie([ConsentCategory.STRICTLY_REQUIRED])}>
                Essential Cookies Only
              </Button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default CookiesConsentPopup;
