import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {ConnectionInfoT} from 'src/types';
import {Loading} from '@trustle/component-library';
import SetupTableau from './SetupTableau';
import {ReportType} from '../types';
import {Resource} from 'src/stores/domainObjects/Resource';
import TestConnection from '../common/editConnection/TestConnection';
import {useToasts} from 'react-toast-notifications';
import _ from 'lodash';
import {useConnectionCredentials} from '../../lib/hooks';

export default function EditConnectionTableau(props: {resource: Resource}) {
  const {resource} = props;
  const lastImport = resource.connector?.lastImport;

  const {addToast} = useToasts();

  const [connectionInfo, setConnectionInfo] = useState<ConnectionInfoT>();
  const [displayCredentialsForm, setDisplayCredentialsForm] = useState<boolean>(false);
  const [connectorStatus] = useState<string | undefined>(lastImport?.status);
  const [testConnectionInProgress, setTestConnectionInProgress] = useState<boolean>(false);
  const {testConnection: testConnectionAPI} = useConnectionCredentials();

  async function loadConnection() {
    const connectionResponse = await axios.get(`/api/connect/${resource.connectionId}`);
    setConnectionInfo(connectionResponse.data);
  }

  async function onCredentialsUpdated() {
    await loadConnection();
    setDisplayCredentialsForm(false);
  }

  useEffect(() => {
    void loadConnection();
  }, [resource.connectionId]);

  if (!connectionInfo) {
    return <Loading />;
  }

  const {connection} = connectionInfo;
  const {serviceFriendlyName} = connection;

  async function testConnection() {
    setTestConnectionInProgress(true);

    try {
      const {data} = await testConnectionAPI(connection.id, {});
      setTestConnectionInProgress(false);

      if (data.error) {
        addToast(_.join(['Connection failed', data.error.message], ': '), {appearance: 'error', autoDismiss: false});
        return;
      }

      await axios.post(`/api/connect/${resource.connectionId}/start_import`);
    } catch {
      setTestConnectionInProgress(false);
    }
  }

  return (
    <div>
      <div className="tr-my-2 no-padding">
        <div className="tr-w-full lg:tr-w-2/3">
          <div className="tr-border tr-rounded- tr-shadow-lg">
            <TestConnection
              name={serviceFriendlyName!}
              connectorStatus={connectorStatus!}
              onTest={testConnection}
              testing={testConnectionInProgress}
              onUpdate={() => setDisplayCredentialsForm(!displayCredentialsForm)}
              updating={displayCredentialsForm}
            />
          </div>
          {displayCredentialsForm && (
            <SetupTableau connectionInfo={connectionInfo} onComplete={onCredentialsUpdated} editMode={true} />
          )}
        </div>
      </div>

      <div className="tr-pt-2 tr-my-4 no-padding">
        <h3>Available Reports</h3>
        <div className="tr-w-full lg:tr-w-2/3">
          <div className="tr-flex">
            <a
              href={`/api/connect/${connection.id}/report/${ReportType.APP_USAGE_PER_ACCOUNT}`}
              className="link"
              download
            >
              App Usage Per Account
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
