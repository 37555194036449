import React from 'react';
import ComplexModal from 'src/components/modals/ComplexModal';
import {AuthenticationPolicyT} from './authenticationTypes';

export const AddedModal: React.ElementType = (props: {
  policy: AuthenticationPolicyT;
  onActivate: (policy: AuthenticationPolicyT) => void;
  onClose: () => void;
}) => {
  const {policy, onActivate, onClose} = props;

  return (
    <ComplexModal
      id={'auth-settings-added-modal'}
      icon={'thumbUpCircle'}
      confirmButtonTitle={'Activate'}
      cancelButtonTitle={'Close'}
      onClose={onClose}
      onConfirm={() => onActivate(policy)}
      title={'New Identity Provider Added!'}
      content={
        <div className="text-center small">
          Identity Provider added successfully. Click the button below to <b>Activate</b> and allow users to use this
          authentication method.
        </div>
      }
    />
  );
};
