import React, {useState} from 'react';
import {EULAContent} from './EULAContent';
import {Button, Checkbox, Modal} from '@trustle/component-library';
import IntegrationHubspot from 'src/components/hubspot/IntegrationHubspot';
import {Formik} from 'formik';
import {FormikForm} from 'src/components/design/formik';

export function LegalAgreementModal(props: {onResponse: any}) {
  const {onResponse} = props;

  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const [privacyPolicyChecked, setPrivacyPolicyChecked] = useState<boolean>(false);
  const [eulaChecked, setEULAChecked] = useState<boolean>(false);

  const submitLegalAgree = (form: any) => {
    form.agree === 'accept' ? onResponse(true) : onResponse(false);
  };

  return (
    <Modal visible={true} onClose={() => onResponse(false)} title="Legal Agreement">
      <div className="pb-1 dropdown-divider" />
      <div className="p-3 overflow-auto text-justify" style={{maxHeight: '600px'}}>
        <EULAContent />
      </div>
      <div className="dropdown-divider" />
      <div>
        <Formik
          initialValues={{
            plg: 'Activated',
            agree: '',
          }}
          onSubmit={submitLegalAgree}
        >
          {({isSubmitting, setFieldValue}) => (
            <FormikForm id="legalsApp">
              <div className="tr-my-3 tr-mx-4">
                <div className="tr-py-1 tr-flex tr-justify-content-end">
                  <small>
                    <Checkbox className="tr-mx-3" onChange={(e: any) => setEULAChecked(e.target.checked)} />
                    <b>I've read and agree to the End-user License Agreement</b>
                  </small>
                </div>
                <div className="tr-py-1 tr-flex tr-justify-content-end">
                  <small>
                    <Checkbox className="tr-mx-3" onChange={(e: any) => setPrivacyPolicyChecked(e.target.checked)} />
                    <b>
                      I've read and agree to the <a href="https://trustle.com/legal/privacy">Privacy Policy</a>
                    </b>
                  </small>
                </div>
                <div className="tr-py-1 tr-flex tr-justify-content-end">
                  <small>
                    <Checkbox className="tr-mx-3" onChange={(e: any) => setTermsChecked(e.target.checked)} />
                    <b>
                      I've read and agree to the <a href="https://trustle.com/legal/tos">Terms of Service</a>
                    </b>
                  </small>
                </div>
              </div>

              <IntegrationHubspot field="plg" value="Activated" sendEmail={true} />
              <div className="tr-pt-3 tr-mb-3 tr-mx-4 tr-flex tr-flex-row tr-justify-between">
                <Button
                  className="px-4 mb-1 btn btn-sm btn-outline-primary font-weight-bold"
                  name="agree"
                  onClick={() => setFieldValue('agree', 'decline')}
                  type="submit"
                >
                  Decline
                </Button>
                <Button
                  disabled={!termsChecked || !privacyPolicyChecked || !eulaChecked || isSubmitting}
                  name="agree"
                  onClick={() => setFieldValue('agree', 'accept')}
                  type="submit"
                >
                  Accept
                </Button>
              </div>
            </FormikForm>
          )}
        </Formik>
      </div>
    </Modal>
  );
}
