import React from 'react';
import {TextInput} from '@trustle/component-library';
import _ from 'lodash';
import {UserResponse} from '../../../../../hooks/useUsers';

type Props = {
  user: UserResponse;
};
export function UserRoleAndAccess({user}: Props) {
  const {manager, isOrgOwner, allowLogin, userStatus} = user;
  return (
    <div className="tr-flex tr-flex-col tr-gap-4">
      <h3>Role & Accesses</h3>
      <div className="tr-flex tr-flex-col">
        <div className="tr-flex tr-gap-4">
          <TextInput label="Manager" value={manager?.email} disabled />
          {/*TODO: Include link to manager*/}
          <TextInput label="Trustle Role" value={isOrgOwner ? 'Owner' : 'Regular User'} disabled />
        </div>
        <div className="tr-flex tr-gap-4">
          <TextInput label="Trustle User Type" value={_.startCase(user.type)} disabled />
          <TextInput label="Allowed to Login" value={allowLogin ? 'Yes' : 'No'} disabled />
        </div>
        <div className="tr-flex tr-w-1/2 tr-pr-2">
          <TextInput label="Status" value={_.startCase(userStatus)} disabled />
        </div>
      </div>
    </div>
  );
}
