import _ from 'lodash';
import {formatUserName, fuseSearch} from 'src/lib';
import {ACCESS_STATUSES} from 'src/lib/constants';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import {Account} from 'src/stores/domainObjects/Account';
import {Permission} from 'src/stores/domainObjects/Permission';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Task} from 'src/stores/domainObjects/Task';
import {User} from 'src/stores/domainObjects/User';

import UsersStore from 'src/stores/usersStore';

function buildPath(accesses: AccessRecord[], accessRequests: Task[], permissions: Permission[]) {
  const tasksPath = buildTasksPath(accessRequests);
  const accessPath = buildAccessPath(accesses);
  const permissionPath = buildPermissionPath(permissions, accesses);
  const resourcePath = buildResourcePath(permissions);

  return [...tasksPath, ...accessPath, ...permissionPath, ...resourcePath];
}
function buildResourcePath(permissions: Permission[]) {
  return _.chain(permissions)
    .map('parentResource')
    .uniqBy('id')
    .map((resource) => ({
      resource: resource.name,
      rid: resource.id,
    }))
    .value();
}

function buildPermissionPath(permissions: Permission[], accesses: AccessRecord[]) {
  return permissions
    .filter((permission) => !accesses.some((access) => access.pid === permission.id))
    .map((permission) => ({
      permission: permission.label,
      rid: permission.parentResource.id,
      pid: permission.id,
      resourceName: buildResourceChain(permission.parentResource),
    }));
}

function buildTasksPath(accessRequests: Task[]) {
  return accessRequests.map(({forResource, forPermission, forUser, forUserAccount, forAccess}) => ({
    resource: forResource?.name || '',
    permission: forPermission?.label || '',
    user: formatUser(forUser as User, forUserAccount as Account),
    rid: forResource?.id,
    pid: forPermission?.id,
    aid: forAccess?.id,
  }));
}

function buildAccessPath(accesses: AccessRecord[]) {
  return accesses.map((access) => {
    const resource = buildResourceChain(access.forPermission?.parentResource);
    return {
      resource,
      permission: access.forPermission?.label || '',
      account: access.forAccount?.account ?? '',
      user: formatUser(access.forUser, access.forAccount),
      rid: access.forResource?.id,
      pid: access.forPermission?.id,
      aid: access.id,
    };
  });
}

function formatUser(user?: User, account?: Account) {
  const {email = '', firstname = '', lastname = ''} = user || {};
  const accountLabel = account ? account.account : '';
  return `${firstname} ${lastname} ${email} ${accountLabel}`;
}

function buildResourceChain(resource: Resource | undefined) {
  let resourceName = '';
  while (resource) {
    resourceName = `${resourceName} ${resource.name}`;
    resource = resource.parentResource;
  }
  return resourceName.trim();
}

function filterItems(
  resources: Resource[],
  permissions: Permission[],
  accesses: AccessRecord[],
  accessRequests: Task[],
  filters: any,
  query: string = '',
  prefilteredAccesses: AccessRecord[],
  usersStore: UsersStore,
  canEdit: boolean = false,
  displayActiveAccesses = false // Flag used to skip filtering (locked, hidden, isRequestable) on active accesses
) {
  if (!canEdit && !displayActiveAccesses) {
    //Remove hidden and locked if is just in access mode.
    resources = _.filter(resources, (res) => {
      return !res.isLocked && !res.calculatedHidden.value;
    }); //Remove hidden and locked if is just in access mode.
    permissions = _.filter(permissions, (p) => {
      return p.isRequestable;
    });
  }

  //Calculate if should be
  if (_.isEmpty(_.omit(filters, 'Opened')) && _.isEmpty(query)) {
    return [_.map(resources, 'id'), _.map(permissions, 'id'), _.map(accesses, 'id')];
  }
  const permNoAccesses = _.filter(permissions, (perm) =>
    _.isEmpty(
      _.filter(accesses, (access) => {
        return access.pid === perm.id;
      })
    )
  );

  const paths = buildPath(accesses, accessRequests, permNoAccesses);
  const searchResults = fuseSearch(query, ['resource', 'permission', 'user', 'account'], paths);
  const searchResultByAccess = _.keyBy(searchResults, 'aid');
  const searchResultByPerm = _.keyBy(searchResults, 'pid');
  const searchResultByRes = _.keyBy(searchResults, 'rid');
  const accessFilter = accesses.filter((access: AccessRecord) => {
    const filterMatch =
      (_.isEmpty(query) || !!searchResultByAccess[access.id]) &&
      (_.isNil(filters.Status) || filters.Status.split(',').includes(access.status)) &&
      (_.isNil(filters.Account) || access.forAccount?.account === filters.Account) &&
      (_.isNil(filters.User) || formatUserName(access.forUser) === filters.User) &&
      (_.isNil(filters.Tasks) ||
        (filters.Tasks === 'Pending' &&
          _.find(accessRequests, (ar) => {
            return ar.forAccess?.id === access.id;
          })));

    return filterMatch;
  });

  const filteredAccesses = _.map(accessFilter, 'id');

  const permFilter = permissions.filter((permission) => {
    const permAccesses = accesses.filter((access) => access.pid === permission.id);

    const isQueryMatched =
      _.isEmpty(query) ||
      searchResultByPerm[permission.id] ||
      permAccesses.some((access) => searchResultByAccess[access.id]);

    const filterConditions = [
      _.isNil(filters.Permission) || permission.label === filters.Permission,
      isQueryMatched,
      _.isNil(filters.Lock) || (permission.isLocked ? 'Locked' : 'Unlocked') === filters.Lock,
    ];

    const exampleResource = _.first(resources);
    const account =
      filters.Account && exampleResource
        ? exampleResource.rootResource.accounts.find((acc) => acc.account === filters.Account)
        : undefined;
    const user = filters.User ? usersStore.users.find((u) => formatUserName(u) === filters.User) : undefined;

    if (_.isEmpty(prefilteredAccesses)) {
      filterConditions.push(
        _.isNil(filters.Status) ||
          permission.accessAggregated.some((accessAgg) =>
            filters.Status.split(',').includes(ACCESS_STATUSES[parseInt(accessAgg.value)])
          ),
        _.isNil(filters.User) || permission.accessAggregated.some((accessAgg) => accessAgg.uauid === user?.id),
        _.isNil(filters.Account) || permission.accessAggregated.some((accessAgg) => accessAgg.uaid === account?.id)
      );
    } else {
      filterConditions.push(
        _.isNil(filters.Status) || permAccesses.some((access) => filters.Status.split(',').includes(access.status))
      );
    }

    return filterConditions.every(Boolean);
  });

  const filteredPermissions = _.map(permFilter, 'id');
  const filteredResources = _.uniq(
    _.map(
      resources.flatMap((item) => {
        if (_.isNil(item)) {
          return [];
        }
        const names = _.map(item.allAncestors, (ancestor) => ancestor.name);
        const filterResource = item.name === filters.Resource || names.includes(filters.Resource);
        const queryResourceMatched = !_.isEmpty(query) && !!searchResultByRes[item.id];
        const isPermFilterMatched = _.some([...item.allNestedResources, item], (child) => {
          // TRUS-8338 - Allowed resources without associated permissions
          return child.permissions.length === 0 || _.some(child.permissions, (perm) => filteredPermissions.includes(perm.id));
        });

        const filterMatch = filterResource || queryResourceMatched || isPermFilterMatched;
        return filterMatch ? [item, ...item.allAncestors] : [];
      }),
      'id'
    )
  );
  return [filteredResources, filteredPermissions, filteredAccesses];
}

export {filterItems};
