import React from 'react';

type PropsT = {
  title?: string;
  imageUrl?: string | null;
  body?: React.ReactNode;
  children?: React.ReactNode;
};

function SetupScreenTopByBottom(props: PropsT) {
  const {title, imageUrl, body, children} = props;

  return (
    <div className="row m-auto" style={{padding: '60px 0', maxWidth: '528px'}}>
      <div className="col">
        <div className="card shadow bg-white rounded text-center">
          <div className="card-body">
            <h4 className="card-title">{title}</h4>
            <div className="card-text">{body || children}</div>
            {imageUrl && <img src={imageUrl} className="img-fluid pt-5" alt="man creating" />}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SetupScreenTopByBottom;
