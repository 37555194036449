import React from 'react';
import {TabsDocsT} from '../DocSection';
import DocSection from '../DocSection';
import CircleStep from 'src/components/CircleStep';
import {ConnectionServiceE} from 'src/types';
import {useRootStore} from 'src/lib/hooks';
import _ from 'lodash';
import {observer} from 'mobx-react';

const ConfigureAzureAD = observer(() => {
  const {org} = useRootStore();
  if (_.isNil(org)) {
    return <></>;
  }

  const tabs: TabsDocsT = [
    {id: 'azure-overview', label: 'Overview', description: overview(), active: true},
    {id: 'azure-supported-features', label: 'Supported Features', description: supportedFeatures(), active: false},
    {id: 'azure-configuration-steps', label: 'Configuration Steps', description: configurationSteps(), active: false},
  ];

  function Step(props: {stepNumber: number; title: string}): JSX.Element {
    const {stepNumber, title} = props;
    return (
      <p className="pt-3">
        <CircleStep>{stepNumber}</CircleStep>
        <span className="body5">{title}</span>
      </p>
    );
  }

  function Item(props: {attribute?: string; value: string}): JSX.Element {
    const {attribute, value} = props;
    return (
      <li>
        {attribute && `${attribute}:`} <code className="text-info">{value}</code>
      </li>
    );
  }

  function overview(): React.ReactNode {
    return (
      <div className="tr-w-75">
        <p>
          The following guide describes the required steps to configure a connection between Trustle and your tenant in
          Azure.
        </p>
        <p>
          This connector will allow Trustle to retrieve the data in your directory in Azure, as well as the information
          related to Azure <strong>Role-Based Access Control (RBAC)</strong>, an Authorization system built on Azure
          Resource Manager designed for fine-grained access management of Azure Resources.
        </p>
        <p>
          Through this connector, you will be able to easily manage the group memberships and know all the details of
          the roles and permissions associated to each group.
        </p>
        <p>
          If you have any questions, please contact us at <a href="mailto:support@trustle.com">support@trustle.com</a>.
        </p>
      </div>
    );
  }

  function supportedFeatures(): React.ReactNode {
    return (
      <>
        <p>Trustle currently supports the following features:</p>
        <div>
          <ul>
            <li>Importing Users and Groups from your Directory</li>
            <li>Importing Role Assignments for Groups in each scope level</li>
            <li>Importing Role definitions and their associated permissions</li>
            <li>Managing group memberships</li>
          </ul>
        </div>
      </>
    );
  }

  function configurationSteps(): React.ReactNode {
    return (
      <>
        <div className="tr-w-full tr-mb-5">
          <p>
            <u>IMPORTANT</u>: The following steps need to be performed by a <strong>Global Administrator</strong> with{' '}
            <strong>Read</strong> access to the subscriptions for the tenant.
          </p>
          <Step stepNumber={1} title="REGISTER A NEW APP IN AZURE PORTAL" />
          <div className="tr-mx-5">
            <span>
              Go to <a href="https://portal.azure.com">Azure Portal</a> and look for <u>App Registrations</u> service.
            </span>
            <p>Register a new application with the following parameters:</p>
            <p className="ml-5 mb-3">
              <Item attribute="Name" value="Trustle App" />
              <Item
                attribute="Redirect URI (of type Web)"
                value={`${org.getOrgRedirectUrl(`/api/connect/${ConnectionServiceE.AZURE_AD}/oauth`)}`}
              />
              <Item attribute="Supported account types" value="Single tenant" />
            </p>
          </div>

          <Step stepNumber={2} title="CONFIGURE YOUR APP CREDENTIALS AND SCOPED PERMISSIONS" />
          <div className="tr-mx-5">
            Inside your app, go to <u>Certificates &amp; secrets</u> section, add a New client secret and save its value
            for later.
            <div className="tr-ml-3">
              <cite>
                Keep in mind the secret value will only be displayed while you are in this page. Once you leave, it
                won't be visible again and you'll need to generate a new one.
              </cite>
            </div>
            <div className="tr-my-4 dropdown-divider"></div>
            <p>
              Next, go to <u>API permissions</u> and add the following permissions:
            </p>
            <div className="tr-ml-3">
              <li>
                <strong>Azure Service Management / Azure Resource Management</strong>
                <ul>
                  <li>
                    <code className="text-info">user_impersonation</code>
                    <cite className="mt-2 tr-w-75 blockquote-footer">
                      By granting this API Permission, the registered app will be able to perform Azure ARM API calls.
                      The level of access for this grant is controlled by Azure's{' '}
                      <strong>Role Based Access Control</strong> configured in Step 3. We will use Reader role to limit
                      token access to read-only access to resources within each Azure Subscription.
                    </cite>
                  </li>
                </ul>
              </li>
              <li>
                <strong>Microsoft.Graph / Delegated permissions</strong>
                <ul>
                  <Item value="AuditLog.Read.All"></Item>
                  <Item value="Directory.Read.All"></Item>
                  <Item value="Group.Read.All"></Item>
                  <Item value="GroupMember.ReadWrite.All"></Item>
                  <Item value="offline_access"></Item>
                  <Item value="User.Read.All"></Item>
                </ul>
              </li>
            </div>
            <div className="tr-my-4 dropdown-divider"></div>
            <p>
              Go to the <u>Overview</u> tab and copy the following values, they will be required on the next steps:
            </p>
            <div className="tr-ml-3">
              <li>Application (client) ID</li>
              <li>Directory (tenant) ID</li>
            </div>
          </div>

          <Step stepNumber={3} title="AUTHORIZE YOUR APP" />
          <div className="tr-mx-5">
            Go to <u>Subscriptions</u> service and then to <u>Access Control (IAM)</u> within each subscription.
            <p>Add the following role assignment:</p>
            <div className="tr-ml-3">
              <Item attribute="Role" value="Reader" />
              <Item attribute="Service Principal" value="Trustle App" />
            </div>
          </div>

          <Step stepNumber={4} title="CREATE A NEW CONNECTOR IN TRUSTLE" />
          <div className="tr-mx-5">
            <li>First, fill the general form providing a Name for your connector and fill all the required values.</li>
            <li>
              During the <u>Authentication setup</u> step, use the values provided in your new app registration to fill
              the form for Azure AD connector.
              <ul>
                <li>
                  After the client credentials are submitted, you will see a message indicating this operation was
                  succesful.
                </li>
              </ul>
            </li>
            <li>
              Then, grant access to both REST APIs resources in order to allow Trustle to retrieve this information from
              Azure.
            </li>
            <li>Finally, click Continue and finish setting up the connector</li>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <DocSection tabs={tabs} title="How to configure Azure Active Directory connector in Trustle" />
    </>
  );
});

export default ConfigureAzureAD;
