import React from 'react';
import {NextStepT} from 'src/connectors/types';
import SystemFlowNameStep from 'src/views/Resource/Create/SystemFlowNameStep';
import {ConnectionServiceE} from '../../types';

type PropsT = {
  nextStep: NextStepT;
  connectorType: ConnectionServiceE;
};

export default function InformationStep(props: PropsT) {
  function onComplete(response: any) {
    const {resource} = response.data;
    props.nextStep({resourceId: resource.id, newConnectionId: resource.connectionId});
  }

  return <SystemFlowNameStep onComplete={onComplete} connectorType={props.connectorType} />;
}
