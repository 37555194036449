import React from 'react';
import ManifestForm from './ManifestForm';
import {ComponentStateT, RouteStepT} from 'src/connectors/types';
import _ from 'lodash';
import {Loading} from '@trustle/component-library';
import manifests from '../manifests';
import ManifestOauth from './ManifestOauth';

function buildIntegrationMetaSteps() {
  return _.map(_.values(manifests), (manifest) => {
    const steps: RouteStepT[] = [
      {
        name: 'Authenticate',
        path: `/connect/${manifest.service}/:connectionId/authenticate`,
        component: (params: ComponentStateT) => {
          return (
            <>
              {params.connectionInfo ? (
                _.size(manifest.auth?.fields) > 0 ? (
                  <ManifestForm
                    connectionInfo={params.connectionInfo}
                    manifest={manifest}
                    {..._.pick(params, ['nextStep', 'connectionId'])}
                  />
                ) : (
                  <ManifestOauth
                    connectionInfo={params.connectionInfo}
                    manifest={manifest}
                    {..._.pick(params, ['nextStep', 'connectionId'])}
                  />
                )
              ) : (
                <Loading />
              )}
            </>
          );
        },
      },
    ];

    return {
      key: manifest.service,
      steps: steps,
      name: manifest.name,
      tags: manifest.tags,
      description: manifest.description,
      title: `${manifest.name} integration`,
    };
  });
}

export default buildIntegrationMetaSteps;
