import _ from 'lodash';
import React, {ReactNode} from 'react';
import axios from 'axios';
import {useState, useRef, useEffect} from 'react';
import {logger, formatFullDate, formatDateFromNow, formatUserName, useFeatureFlags} from 'src/lib';
import './AuthenticationSettings.scss';
import {EditModal} from './EditModal';
import {AuthMethodSelector, Settings} from './AuthMethodSelector';
import {AddedModal} from './AddedModal';
import {DeleteModal} from './DeleteModal';
import {SwitchAuthModal} from './SwitchAuthModal';
import {authDisplayName, SamlConfigurationT, AuthenticationTargetT, AuthenticationPolicyT} from './authenticationTypes';
import {JWTToken, JWTTokenStateE, UserType} from 'src/types';
import {useRootStore} from 'src/lib/hooks';
import {
  Button,
  Checkbox,
  DurationInput,
  Icon,
  IconButton,
  Modal,
  Table,
  TextAreaInput,
  Tooltip,
  Alert,
} from '@trustle/component-library';
import StatusIndicator, {translateTokenStatus} from 'src/components/StatusIndicator';
import {Link} from 'react-router-dom';
import copy from 'copy-to-clipboard';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClone} from '@fortawesome/free-regular-svg-icons';
import {Field, Formik} from 'formik';
import {FormikForm} from 'src/components/design/formik';
import {object, string} from 'yup';
import moment from 'moment';

type NewAuthPolicy = {
  name: string;
  type: string;
  targets: AuthenticationTargetT[];
  displayName: string;
};

enum AuthSettingsModalE {
  NEWLY_ADDED = 'ADD',
  CREATE = 'CREATE',
  UPDATE = 'UPD',
  DELETE = 'DEL',
  EDIT = 'EDIT',
  SWITCH = 'SWITCH',
}

enum APIKeyModalE {
  ADD,
  EDIT,
  UPD_STATUS,
  REVOKE,
}

export default function AuthenticationSettings() {
  const {currentUser, org, usersStore} = useRootStore();
  const {usersMap} = usersStore;

  const featureFlags = useFeatureFlags();
  const apiKeysFeatureEnabled = featureFlags.isEnabled('enable_api_keys');

  const [authPolicies, setAuthPolicies] = useState<AuthenticationPolicyT[]>([]);
  const [apiKeys, setAPIKeys] = useState<JWTToken[]>([]);
  const [modalState, setActiveModalState] = useState<AuthSettingsModalE | APIKeyModalE | null>(null);
  const [selected, setSelected] = useState<NewAuthPolicy | AuthenticationPolicyT | JWTToken | null>(null);
  const [generatedJWT, setGeneratedJWT] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [copied, setCopied] = useState<boolean>(false);
  const [tempAuthPolicies, setTempAuthPolicies] = useState<AuthenticationPolicyT[]>([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);

  useEffect(() => {
    const fetchPolicies = async () => {
      const result = await axios.get('/api/admin/authentication_policies');
      setAuthPolicies(_.sortBy(result.data.policies, ['createdAt']));
    };

    void fetchPolicies();
  }, []);

  useEffect(() => {
    setTempAuthPolicies(_.cloneDeep(authPolicies));
  }, [authPolicies]);

  const handleCheckboxChange = (policy: AuthenticationPolicyT, checked: boolean) => {
    const updatedPolicies = tempAuthPolicies.map((p) => {
        if (p.id === policy.id) {
            return { ...p, active: checked };
        }
        return { ...p, active: false };
    });

    setTempAuthPolicies(updatedPolicies);

    const anyActive = updatedPolicies.some(p => p.active);
    const previouslySelected = tempAuthPolicies.some(p => p.active);

    setIsSaveDisabled(!anyActive || previouslySelected && !checked);
  };

  const handleSave = async () => {
    try {
      for (const policy of authPolicies) {
        const isActive = tempAuthPolicies.some((tempPolicy) => tempPolicy.id === policy.id && tempPolicy.active);

        if (policy.active !== isActive) {
          await onSelectMethod(policy, isActive);
        }
      }

      setAuthPolicies(_.cloneDeep(tempAuthPolicies));
      setIsSaveDisabled(true);
    } catch (err) {
      onError(err);
    }
  };

  async function onSelectMethod(policy: AuthenticationPolicyT, checked: boolean) {
    try {
      await axios.put(`/api/admin/authentication_policies/${policy.id}/enable/${checked}`);
      updateListLocally(policy.name === 'saml' && checked ? 'SEL-SAML' : 'UNSEL-SAML', {...policy, active: checked});
      resetState();
    } catch (err) {
      onError(err);
    }
  }

  const handleSelectAuthMethod = (selectedMethod: Settings) => {
    const isAlreadyAdded = tempAuthPolicies.some((policy) => policy.name === selectedMethod.name);

    if (!isAlreadyAdded) {
      const newPolicy: AuthenticationPolicyT = {
        id: _.uniqueId('policy_'),
        name: selectedMethod.name,
        type: selectedMethod.type,
        active: false,
        targets: [{domain: '*', userType: UserType.employee}],
        oid: '',
        createdAt: new Date().toISOString(),
      };

      setTempAuthPolicies([...tempAuthPolicies, newPolicy]);
      setSelected(newPolicy);
      setActiveModalState(AuthSettingsModalE.CREATE);
    } else {
      setError('This authentication method is already added.');
      setShowAlert(true);
    }
  };

  const activeCount = _.size(_.filter(apiKeys, (k: JWTToken) => k.state === JWTTokenStateE.ACTIVE));

  const authMethodcolumns: any = [
    {
      dataField: 'active',
      text: 'Active',
      sort: true,
      formatter: (cell: any, policy: AuthenticationPolicyT) => {
        return (
          <Checkbox
            id={`activate-${policy.id}`}
            name={`activate-${policy.id}`}
            checked={cell}
            onChange={(e: any) => {
              void handleCheckboxChange(policy, e.target.checked);
            }}
          />
        );
      },
    },
    {
      dataField: 'name',
      text: 'Original Name',
      hidden: true,
    },
    {
      dataField: 'name',
      text: 'Name',
      sort: true,
      formatter: (policyName: string, policy: AuthenticationPolicyT) => {
        return <div className="tr-text-trustle-navy tr-font-bold">{authDisplayName(policy)}</div>;
      },
    },
    {
      dataField: 'type',
      text: 'Type',
      hidden: true,
    },
    {
      dataField: 'domain',
      text: 'Whitelisted Domain',
      formatter: (cell: any, policy: AuthenticationPolicyT) => {
        return policy.targets[0].domain;
      },
    },
    {
      dataField: 'createdAt',
      text: 'Created',
      sort: true,
      formatter: (cell: any) => {
        return <DateElem date={cell} />;
      },
    },
    {
      dataField: 'actions',
      classes: 'show-on-hover',
      text: 'Actions',
      headerStyle: {width: '90px'},
      style: {width: '90px'},
      formatter: (_cell: any, row: AuthenticationPolicyT) => {
        return <div>{getAuthPolicyActions(row)}</div>;
      },
    },
  ];

  const DateElem = (props: {date: string}) => {
    const {date} = props;

    return _.isEmpty(date) ? (
      <Icon type="emptyData" title="Never" size="sm" />
    ) : (
      <Tooltip content={formatFullDate(date)}>{formatDateFromNow(date)}</Tooltip>
    );
  };

  const apiKeysColumns: any = [
    {
      dataField: 'state',
      text: 'Status',
      sort: true,
      headerStyle: {width: '3.5rem', textAlign: 'center'},
      style: {width: '3.5rem', textAlign: 'center'},
      formatter: (field: JWTTokenStateE) => {
        return (
          <div>
            <StatusIndicator status={translateTokenStatus(field)} messages={[_.capitalize(field)]} />
          </div>
        );
      },
    },
    {
      dataField: 'description',
      text: 'Description',
      headerStyle: {width: '16rem'},
      style: {width: '16rem'},
    },
    {
      dataField: 'uid',
      text: 'Created By',
      headerStyle: {width: '12rem'},
      style: {width: '12rem'},
      formatter: (field: string) => {
        const user = usersMap[field];

        if (!user) {
          return <Icon type="notAvailable" title="Not available" size="sm" />;
        }

        return <Link to={`/users/${user.id}`}> {formatUserName(user)} </Link>;
      },
    },
    {
      dataField: 'issued',
      text: 'Created',
      sort: true,
      headerStyle: {width: '8rem'},
      style: {width: '8rem'},
      formatter: (field: string) => {
        return <DateElem date={field} />;
      },
    },
    {
      dataField: 'lastUsed',
      text: 'Last Used',
      sort: true,
      headerStyle: {width: '6rem'},
      style: {width: '6rem'},
      formatter: (field: string) => {
        return <DateElem date={field} />;
      },
    },
    {
      dataField: 'expires',
      text: 'Expires',
      sort: true,
      headerStyle: {width: '6rem'},
      style: {width: '6rem'},
      formatter: (field: string) => {
        return <DateElem date={field} />;
      },
    },
    {
      dataField: 'actions',
      classes: 'show-on-hover',
      text: 'Actions',
      headerStyle: {width: '6rem'},
      style: {width: '6rem'},
      formatter: (_field: any, row: JWTToken) => {
        return <div className="tr-flex tr-flex-row">{getApiKeyActions(row)}</div>;
      },
    },
  ];

  function getApiKeyActions(apiKey: JWTToken) {
    const items = [];
    items.push(
      <IconButton
        icon="edit"
        variant="ternary"
        title={'Edit'}
        key={`edit${apiKey.id}`}
        onClick={() => {
          setSelected(apiKey);
          setActiveModalState(APIKeyModalE.EDIT);
        }}
      />
    );

    if (apiKey.state === JWTTokenStateE.ACTIVE) {
      items.push(
        <IconButton
          icon="disabled"
          variant="ternary"
          title={'Disable'}
          key={`disable${apiKey.id}`}
          onClick={() => {
            setSelected(apiKey);
            setActiveModalState(APIKeyModalE.UPD_STATUS);
          }}
        />
      );
    }

    if (apiKey.state === JWTTokenStateE.DISABLED) {
      items.push(
        <IconButton
          icon="sync"
          variant="ternary"
          title={'Enable'}
          key={`enable${apiKey.id}`}
          onClick={() => {
            setSelected(apiKey);
            setActiveModalState(APIKeyModalE.UPD_STATUS);
          }}
        />
      );

      items.push(
        <IconButton
          icon="remove"
          variant="ternary"
          title="Revoke"
          key={`revoke${apiKey.id}`}
          onClick={() => {
            setSelected(apiKey);
            setActiveModalState(APIKeyModalE.REVOKE);
          }}
        />
      );
    }

    return <div className="tr-flex tr-flex-row">{items}</div>;
  }

  function getAuthPolicyActions(policy: AuthenticationPolicyT) {
    const items = [];
    items.push(
      <IconButton
        icon="edit"
        variant="ternary"
        title="Edit"
        key={`edit${policy.id}`}
        onClick={() => {
          setSelected(policy);
          setActiveModalState(AuthSettingsModalE.EDIT);
        }}
      />
    );
    items.push(
      <IconButton
        icon="remove"
        variant="ternary"
        title="Delete"
        key={`delete${policy.id}`}
        onClick={() => {
          setSelected(policy);
          setActiveModalState(AuthSettingsModalE.DELETE);
        }}
      />
    );
    return <div className="flex">{items}</div>;
  }

  const loadingRef = useRef(false);
  useEffect(() => {
    // load policies
    const fetchPolicies = async () => {
      loadingRef.current = true;
      const result = await axios.get('/api/admin/authentication_policies');

      setAuthPolicies(_.sortBy(result.data.policies, ['createdAt']));
      loadingRef.current = false;

      setAPIKeys(await org.getApiKeys());
    };

    if (_.isEmpty(authPolicies) && !loadingRef.current) {
      void fetchPolicies();
    }
  }, []);

  useEffect(() => {
    if (error) {
      setShowAlert(true);

      setTimeout(() => {
        setShowAlert(false);

        setTimeout(() => {
          // second timeout required to avoid clearing the message while the alert is still fading-out
          setError(undefined);
        }, 300);
      }, 2000);
    }
  }, [error]);

  /* Events */
  const onSaveNewRow = async (policy: NewAuthPolicy, config?: SamlConfigurationT) => {
    try {
      const createPolicyBody: _.Dictionary<any> = {
        name: policy.name,
        type: policy.type,
        targets: policy.targets,
        active: false,
      };

      if (policy.type === 'saml') {
        createPolicyBody.settings = config;
      }

      const createPolicyResponse = await axios.post(`/api/admin/authentication_policies`, createPolicyBody);

      resetState();
      const newPolicy = createPolicyResponse.data;
      updateListLocally('ADD', newPolicy);

      setSelected(newPolicy);
      setActiveModalState(AuthSettingsModalE.NEWLY_ADDED);
    } catch (err) {
      onError(err);
    }
  };

  const onSaveEditedRow = async (policy: AuthenticationPolicyT, config?: SamlConfigurationT) => {
    if (policy.type === 'saml') {
      await axios
        .put(`/api/admin/authentication_policies/${policy.id}/settings`, config)
        .then(() => {
          updateListLocally('UPD', {...policy, settings: config});
          resetState();
        })
        .catch(onError);
    } else {
      await axios
        .put(`/api/admin/authentication_policies/${policy.id}`, _.pick(policy, ['targets']))
        .then(() => {
          resetState();
          updateListLocally('UPD', policy);
        })
        .catch(onError);
    }
  };

  const onDelete = async (policy: AuthenticationPolicyT) => {
    if (!validateRowChange(policy)) {
      resetState();
      return;
    }
    await axios
      .delete(`/api/admin/authentication_policies/${policy.id}`)
      .then(() => {
        updateListLocally('DEL', policy);
        resetState();
      })
      .catch((err) => {
        resetState();
        onError(err);
      });
  };

  async function onStateChange(policy: AuthenticationPolicyT, checked: boolean) {
    if (!validateRowChange(policy)) {
      return;
    }

    if (checked) {
      const isSamlPolicy = policy.name === 'saml';
      const samlPolicyActive = _.some(authPolicies, (p) => {
        return p.name === 'saml' && p.active;
      });

      if (isSamlPolicy || (!isSamlPolicy && samlPolicyActive)) {
        setSelected(policy);
        setActiveModalState(AuthSettingsModalE.SWITCH);
        return;
      }
    }

    await onSelectMethod(policy, checked);
  }

  const onError = (err: any) => {
    setError(err.response.data.error.message);
    resetState();
  };

  const onGenerateAPIKey = async (values: {
    description: string;
    expiresIn: {durationUnit: string; durationValue: number};
  }) => {
    const {description, expiresIn} = values;
    const {durationUnit, durationValue} = expiresIn;

    const result = await org.generateAPIKey({description, unit: durationUnit, duration: durationValue});

    if (result) {
      setGeneratedJWT(result.apiKey);
      setAPIKeys([...apiKeys, _.omit(result, 'apiKey')]);
    }
  };

  const onUpdateAPIKey = async (id: string, params: {description?: string; state?: JWTTokenStateE}) => {
    await org.patchApiKey(id, params);

    if (params.state !== JWTTokenStateE.REVOKED) {
      const updKeys = _.map(apiKeys, (key) => (key.id === id ? {...key, ...params} : key));
      setAPIKeys(updKeys);
    } else {
      _.remove(apiKeys, (key) => key.id === id);
      setAPIKeys(apiKeys);
    }

    resetState();
  };

  /* Helpers */
  const updateListLocally = (action: string, policy: AuthenticationPolicyT) => {
    const copyOfAuthPolicies = _.cloneDeep(authPolicies);
    setAuthPolicies([]);

    switch (action) {
      case 'ADD':
        setAuthPolicies([...copyOfAuthPolicies, policy]);
        break;
      case 'UPD':
        setAuthPolicies(
          _.map(copyOfAuthPolicies, (p) => {
            return p.id === policy.id ? policy : p;
          })
        );
        break;
      case 'DEL':
        setAuthPolicies(
          _.filter(copyOfAuthPolicies, (p) => {
            return p.id !== policy.id;
          })
        );
        break;
      case 'SEL-SAML':
        setAuthPolicies(
          _.map(_.cloneDeep(copyOfAuthPolicies), (p) => {
            return {...p, active: p.type === 'saml'};
          })
        );
        break;
      case 'UNSEL-SAML':
        setAuthPolicies(
          _.map(copyOfAuthPolicies, (p) => {
            if (p.type === 'saml') {
              return _.extend({...p, active: false});
            } else if (p.id === policy.id) {
              return policy;
            } else {
              return p;
            }
          })
        );
        break;
      default:
        logger.warn(`Update authentication setting action not supported = ${action}`);
    }
  };

  const validateRowChange = (policy: AuthenticationPolicyT): boolean => {
    const remainingActive = _.filter(authPolicies, (p) => {
      return p.id !== policy?.id && p.active;
    });

    if (remainingActive.length < 1) {
      setError('At least one authentication method needs to be active');
      return false;
    }

    const currentDomain = currentUser?.email.split('@')[1] ?? '';

    if (
      !_.some(remainingActive, (p) => {
        const policyDomain = p.targets[0].domain;
        return policyDomain === currentDomain || policyDomain === '*';
      })
    ) {
      setError('If you perform this change, your user will lose access to Trustle');
      return false;
    }

    return true;
  };

  const resetState = () => {
    setActiveModalState(null);
    setSelected(null);
    setGeneratedJWT(null);
    setCopied(false);
  };

  const modalContent = (() => {
    switch (modalState) {
      case AuthSettingsModalE.CREATE:
        return (
          <EditModal
            policy={selected}
            onSave={async (policy: NewAuthPolicy, config?: SamlConfigurationT) => {
              await onSaveNewRow(policy, config);
            }}
            onClose={resetState}
          />
        );
      case AuthSettingsModalE.EDIT:
        return (
          <EditModal
            policy={selected}
            onSave={async (policy: AuthenticationPolicyT, config?: SamlConfigurationT) => {
              await onSaveEditedRow(policy, config);
            }}
            onClose={resetState}
          />
        );
      case AuthSettingsModalE.DELETE:
        return (
          <DeleteModal
            policy={selected}
            onDeactivate={async (policy: AuthenticationPolicyT) => {
              void onStateChange(policy, false);
              resetState();
            }}
            onConfirm={async (policy: AuthenticationPolicyT) => {
              void onDelete(policy);
            }}
            onClose={resetState}
          />
        );
      case AuthSettingsModalE.NEWLY_ADDED:
        return (
          <AddedModal
            policy={selected}
            onActivate={async (policy: AuthenticationPolicyT) => {
              void onStateChange(policy, true);
            }}
            onClose={resetState}
          />
        );
      case AuthSettingsModalE.SWITCH:
        return (
          <SwitchAuthModal
            policy={selected}
            onConfirm={(policy: AuthenticationPolicyT) => {
              void onSelectMethod(policy, true);
            }}
            onClose={resetState}
          />
        );

      case APIKeyModalE.ADD: {
        return (
          <Modal
            title={`${!generatedJWT ? 'Generate New Key' : 'New Key Generated'}`}
            width={'md'}
            onClose={resetState}
            visible={true}
          >
            <div>
              {!generatedJWT && (
                <Formik
                  key={`apiKeyForm`}
                  initialValues={{
                    description: '',
                    expiresIn: {durationUnit: 'M', durationValue: 3},
                  }}
                  validationSchema={object().shape({
                    description: string().required(),
                    expiresIn: object().test(
                      'expiresIn validations',
                      ({durationUnit, durationValue}, {createError}) => {
                        if (!durationUnit) {
                          return createError({message: 'required'});
                        }

                        if (!durationValue) {
                          return createError({message: 'required'});
                        }

                        const duration = moment.duration(durationValue, durationUnit);
                        if (duration.months() >= 12 || duration.years() >= 1) {
                          return createError({message: '1 year maximum'});
                        }

                        return true;
                      }
                    ),
                  })}
                  onSubmit={onGenerateAPIKey}
                >
                  {({isValid, isSubmitting}) => {
                    return (
                      <FormikForm>
                        <Field
                          component={DurationInput}
                          className="tr-mt-3"
                          label="Expires In"
                          id="expiresIn"
                          name="expiresIn"
                          default="m"
                          defaultValue="m"
                          min={1}
                          description="The amount of time this key will be valid (1 year maximum)"
                          includeNoneOption={false}
                          options={[
                            {value: 'm', label: 'Minutes'},
                            {value: 'h', label: 'Hours'},
                            {value: 'd', label: 'Days'},
                            {value: 'M', label: 'Months'},
                          ]}
                          maxLength={2}
                          required
                        />
                        <Field
                          component={TextAreaInput}
                          className="tr-mt-1"
                          label="Description"
                          description="Add a description for this key"
                          id="description"
                          name="description"
                          rows={3}
                          placeholder={'What is this key going to be used for?'}
                        />

                        <div className="tr-flex tr-justify-between mt-3">
                          <Button variant="secondary" onClick={resetState}>
                            Cancel
                          </Button>
                          <Button type="submit" disabled={!isValid || isSubmitting}>
                            Generate Key
                          </Button>
                        </div>
                      </FormikForm>
                    );
                  }}
                </Formik>
              )}
              {generatedJWT && (
                <div>
                  <div>
                    This API key is now <strong>Active</strong>. Copy this key and store it in a safe place. You will
                    not be able to see it again.
                  </div>
                  <div className="tr-mx-2">
                    <TextAreaInput
                      className="tr-border-gray-200 tr-border-[0px] tr-border-t-[1px] tr-border-solid tr-mt-3 tr-py-4"
                      label="JSON Web Token"
                      id="jwtToken"
                      name="jwtToken"
                      disabled={true}
                      rows={9}
                      value={generatedJWT}
                    />
                  </div>
                  <div className="tr-flex tr-justify-between mt-2">
                    <Button variant="secondary" onClick={resetState}>
                      Close
                    </Button>
                    <Button
                      colorVariant={copied ? 'success' : 'primary'}
                      onClick={async () => {
                        copy(generatedJWT);
                        setCopied(true);
                      }}
                    >
                      {copied ? (
                        <FontAwesomeIcon size={'lg'} icon={faClone} title={`Copied!`} />
                      ) : (
                        <Icon size={'md'} type={'checkCircle'} title={`Copy to clipboard`} />
                      )}
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Modal>
        );
      }

      case APIKeyModalE.EDIT: {
        const apiKey = selected as JWTToken;
        const {id, description} = apiKey;

        return (
          <Modal title={'Edit'} width={'md'} onClose={resetState} visible={true}>
            <>
              <div className="tr-flex tr-mx-1 tr-mt-2">
                <TextAreaInput
                  label="Description"
                  id="description"
                  name="description"
                  rows={4}
                  placeholder={'A description of this API Key'}
                  defaultValue={description}
                  onChange={async (e: any) => setSelected({...apiKey, description: e.target.value})}
                />
              </div>
              <div className="tr-flex tr-justify-between tr-mt-3">
                <Button variant="secondary" onClick={resetState}>
                  Cancel
                </Button>
                <Button onClick={async () => await onUpdateAPIKey(id, {description})}>Confirm</Button>
              </div>
            </>
          </Modal>
        );
      }
      case APIKeyModalE.UPD_STATUS: {
        const apiKey = selected as JWTToken;
        const {id, state, description} = apiKey;
        const isActive = state === JWTTokenStateE.ACTIVE;
        const toggleState = isActive ? JWTTokenStateE.DISABLED : JWTTokenStateE.ACTIVE;
        const maxReached = activeCount + (isActive ? -1 : 1) > 2;
        const message: ReactNode = isActive ? (
          <div>
            This will deactivate, and disable, any current usage of the API key: <strong>{description}</strong>. You
            will need to reactivate this key for it to be able to be used in the future.
          </div>
        ) : maxReached ? (
          <div>Max 2 API keys can be active at the same time. Please, disable at least one active key first.</div>
        ) : (
          <div>
            This will enable the usage of the API key: <strong>{description}</strong>.
          </div>
        );

        return (
          <Modal
            title={`${isActive ? 'Deactivate API Key' : 'Enable API Key'}`}
            width={'md'}
            onClose={resetState}
            visible={true}
          >
            <>
              <div>{message}</div>
              <div className="tr-flex tr-justify-between mt-5">
                <Button variant="secondary" onClick={resetState}>
                  Cancel
                </Button>
                <Button disabled={maxReached} onClick={async () => await onUpdateAPIKey(id, {state: toggleState})}>
                  Confirm
                </Button>
              </div>
            </>
          </Modal>
        );
      }
      case APIKeyModalE.REVOKE: {
        const apiKey = selected as JWTToken;
        const {id, description} = apiKey;

        return (
          <Modal title="Delete API Key" width={'md'} onClose={resetState} visible={true}>
            <>
              <div>
                This will permanently delete the API Key: <strong>{description}</strong>. Current usages of this key
                will become invalid so you will need to generate a new key if the access is still required.
              </div>
              <div className="tr-flex tr-justify-between mt-5">
                <Button variant="secondary" onClick={resetState}>
                  Cancel
                </Button>
                <Button onClick={async () => await onUpdateAPIKey(id, {state: JWTTokenStateE.REVOKED})}>Confirm</Button>
              </div>
            </>
          </Modal>
        );
      }
    }
  })();

  return (
    <div className="tr-mb-10">
      {modalContent}
      <div className="authentication-settings mt-3 tr-flex flex-column align-content-start row">
        <div className="tr-w-full tr-mb-5">
          {/* Main Screen */}
          <div className="tr-mb-3">
            <AuthMethodSelector
              policies={authPolicies}
              isSaveDisabled={isSaveDisabled}
              onSave={handleSave}
              onSelected={handleSelectAuthMethod}
            />
          </div>

          <div className="tr-mb-5">
            <Table data={tempAuthPolicies} columns={authMethodcolumns} tableKey={'accountData'} striped={false} />

            <Alert
              className={showAlert ? 'visible' : 'hidden'}
              colorVariant="danger"
              onClose={() => setShowAlert(false)}
            >
              {error}
            </Alert>
          </div>
        </div>
        {apiKeysFeatureEnabled && (
          <div className="tr-my-5 tr-border-gray-200 tr-border-[0px] tr-border-t-[1px] tr-border-solid">
            <div className="tr-flex tr-items-end">
              <div>
                <h3 className="tr-mt-6">API Keys</h3>
                <div className="tr-flex tr-px-3">
                  <div className="body5-normal">Generate an API key to use for API / CLI requests.</div>
                  <Tooltip content={<div>Max 2 API keys can be active at the same time</div>}>
                    <Icon type="moreInfo" size="sm" className="tr-text-trustle-link tr-mx-2" />
                  </Tooltip>
                </div>
              </div>
              <div className="tr-flex tr-justify-end">
                {activeCount === 2 && (
                  <Tooltip content={'Max amount of keys reached. Please, disable one or more keys to add a new one.'}>
                    <div className="tr-mx-2">
                      <Button className="rounded-lg" variant="primary" disabled={true}>
                        {'+ Add New Key'}
                      </Button>
                    </div>
                  </Tooltip>
                )}
                {activeCount < 2 && (
                  <Button
                    className="rounded-lg"
                    variant="primary"
                    onClick={async () => setActiveModalState(APIKeyModalE.ADD)}
                  >
                    {'+ Add New Key'}
                  </Button>
                )}
              </div>
            </div>
            <div className="tr-mt-10">
              <Table data={apiKeys} columns={apiKeysColumns} tableKey={'accountData'} striped={false} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
