import React, {createContext, PropsWithChildren, useContext} from 'react';
import _ from 'lodash';
import useUrlSyncPaginationAndFilters, {
  PaginationParams,
} from '../admin-users-panel/hooks/useUrlSyncPaginationAndFilters';

export type TeamsQueryFiltersAndPagination = {
  [key in any]?: any;
} & PaginationParams;

const DEFAULT_FILTERS: TeamsQueryFiltersAndPagination = {
  page: 1,
  size: 10,
};

type TeamFiltersContextType = {
  filters: TeamsQueryFiltersAndPagination;
  setFilter: (key: keyof TeamsQueryFiltersAndPagination, value: any) => void;
  setFilters: (filters: TeamsQueryFiltersAndPagination) => void;
  clearFilters: (filter?: {key: keyof TeamsQueryFiltersAndPagination; value: any}) => void;
};

const TeamFiltersContext = createContext<TeamFiltersContextType | undefined>(undefined);

type TeamsFilterProviderProps = PropsWithChildren<{}>;

export const TeamsFilterProvider: React.FC<TeamsFilterProviderProps> = ({children}) => {
  const [filters, setFilters] = useUrlSyncPaginationAndFilters(DEFAULT_FILTERS);

  const clearFilters = (filter?: {key: keyof TeamsQueryFiltersAndPagination; value: any}) => {
    let toSet = {..._.omit(DEFAULT_FILTERS, ['status'])};

    if (filter) {
      toSet = {...toSet, [filter.key]: filter.value};
    }

    setFilters(toSet);
  };

  return (
    <TeamFiltersContext.Provider
      value={{
        filters,
        setFilter: (key: keyof TeamsQueryFiltersAndPagination, value: any) => {
          if (!value) {
            const toSet = {...filters};
            delete toSet[key];
            setFilters(toSet);
          } else {
            setFilters({...filters, [key]: value});
          }
        },
        setFilters,
        clearFilters,
      }}
    >
      {children}
    </TeamFiltersContext.Provider>
  );
};

export const useTeamFilters = () => {
  const context = useContext(TeamFiltersContext);
  if (context === undefined) {
    throw new Error('useTeamFilters must be used within a TeamsFilterProvider');
  }
  return context;
};
