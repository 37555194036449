import React, {useState} from 'react';
import {Toggle} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react';

type PropsT = {
  name?: string;
  id?: string;
  'data-testid'?: string;
  onToggle: (e: any) => void;
  disabled?: boolean;
  checked: boolean;
  leftLabel?: React.ReactNode;
  rightLabel?: React.ReactNode;
  leftBackgroundColor?: string;
  rightBackgroundColor?: string;
};

const ToggleSwitch = observer(function ToggleSwitch(props: PropsT) {
  const {checked, onToggle} = props;
  const [enabled, setEnabled] = useState(checked);
  const handleChange = (e: any) => {
    setEnabled(!enabled);
    onToggle(e);
  };
  return (
    <div className="tr-flex tr-justify-center tr-items-center">
      <label className="body6 m-0" htmlFor={props.name}>
        {props.leftLabel}
      </label>
      <Toggle
        checked={enabled}
        onChange={handleChange}
        disabled={props?.disabled}
        name={props?.name}
        leftBackgroundColor={props?.leftBackgroundColor}
        rightBackgroundColor={props?.rightBackgroundColor}
        data-testid="toggle-switch"
        {..._.omit(props, ['checked'])}
      />

      <label className="body6 m-0" htmlFor={props.name}>
        {props.rightLabel}
      </label>
    </div>
  );
});
export default ToggleSwitch;
