import React from 'react';
import {observer} from 'mobx-react';
import './AwsPolicyRisk.scss';
import RiskBadge from 'src/views/Resource/Account/RiskBadge';
import {Table} from '@trustle/component-library';

type PropsT = {riskReport: any};

type RiskT = {
  name: string;
  description: string;
  severity: number;
  actions: string[];
};

type PolicyT = {
  name: string;
  policyRisk: number;
  risks: RiskT[];
};
const PolicyRiskTable = observer(function PolicyRiskTable(props: PropsT) {
  const columns = [
    {
      dataField: 'description',
      hidden: false,
      text: 'Description',
      headerStyle: {width: '50%'},
      style: {width: '50%'},
      formatter: (cell: any) => {
        return <div className="text-left">{cell}</div>;
      },
    },
    {
      dataField: 'actions',
      sort: true,
      text: 'Affected actions',
      headerStyle: {width: '30%'},
      style: {width: '30%'},
      formatter: (cell: any) => {
        return <div className="text-left">{cell}</div>;
      },
    },
    {
      dataField: 'severity',
      sort: true,
      text: 'Severity',
      headerStyle: {width: '20%'},
      style: {width: '20%'},
      formatter: (cell: any) => {
        return <RiskBadge value={cell} />;
      },
    },
  ];
  const {riskReport} = props;
  return riskReport.policies?.map((policy: PolicyT) => {
    return (
      <div className="risk-report" key={policy.name}>
        <div className="risk-report-row">
          <Table
            caption={<div>Policy: {policy.name}</div>}
            overrideKeyField={'description'}
            data={policy.risks}
            columns={columns}
            tableKey={'policyrisktable'}
          />
        </div>
      </div>
    );
  });
});

export default PolicyRiskTable;
