import React from 'react';
import _ from 'lodash';
import {Field, Formik} from 'formik';
import {FormikForm} from 'src/components/design/formik';
import {observer} from 'mobx-react';
import {useRootStore} from 'src/lib/hooks';
import {logger} from 'src/lib';
import {Button, TextInput} from '@trustle/component-library';

type CustomerFormT = {
  name: string;
  phone: string;
  email: string;
};

type PropsT = {
  close: () => void;
};
const BillingInfoForm = observer(function CheckoutFormCmp(props: PropsT) {
  const {org} = useRootStore();

  if (
    _.isNil(org) ||
    org?.customer === undefined ||
    org?.billing === undefined ||
    org?.billingAddresses === undefined ||
    org?.methods === undefined
  ) {
    return <></>;
  }

  async function handleSubmit(values: CustomerFormT) {
    try {
      await org!.updateCustomer(values);
      props.close();
    } catch (e: any) {
      logger.error(e);
    }
  }

  return (
    <div className="m-3">
      <Formik
        initialValues={{
          email: org.customer.email ?? '',
          name: org.customer.name ?? '',
          phone: org.customer.phone ?? '',
        }}
        validate={(values: any) => {
          const errors: _.Dictionary<string> = {};
          if (_.isEmpty(values.name)) {
            errors.name = 'This field is required.';
          }
          if (_.isEmpty(values.email)) {
            errors.email = 'This field is required.';
          }

          return errors;
        }}
        onSubmit={handleSubmit}
      >
        {({isValid}) => (
          <>
            <FormikForm>
              <>
                <div className="tr-flex tr-flex-col">
                  <Field
                    component={TextInput}
                    label="Email"
                    placeholder="customer@org.com"
                    name="email"
                    id="email"
                    data-cy="email"
                    required
                  />

                  <Field
                    component={TextInput}
                    label="Name"
                    name="name"
                    placeholder="Billing Name"
                    data-cy="name"
                    required
                  />

                  <Field component={TextInput} label="Phone" name="phone" type="phone" id="phone" data-cy="phone" />

                  <Button label="Update" disabled={!isValid} type="submit" />
                </div>
              </>
            </FormikForm>
          </>
        )}
      </Formik>
    </div>
  );
});

export default BillingInfoForm;
