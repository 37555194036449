import _ from 'lodash';
import React from 'react';
import {ConnectorContextWrapper} from 'src/connectors/ConnectorContext';
import {Switch, Route} from 'react-router-dom';
import SlackPostInstall from './SlackPostInstall';

export default function SlackConnector() {
  const steps = [
    {
      name: 'post_install',
      path: '/connect/slack/post_install',
      component: () => {
        return <SlackPostInstall />;
      },
    },
  ];

  const routes = _.map(steps, (step: any) => {
    return (
      <Route key={step.name} path={step.path} exact={true}>
        {step.component()}
      </Route>
    );
  });

  return (
    <ConnectorContextWrapper>
      <div className="container tr-w-full tr-mx-auto">
        <div className="tr-px-4 tr-py-2 bg-light tr-border tr-border-black tr-rounded">
          <Switch>{routes}</Switch>
        </div>
      </div>
    </ConnectorContextWrapper>
  );
}
