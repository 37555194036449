import {Button, TextInput} from '@trustle/component-library';
import axios from 'axios';
import {Field, Form, Formik} from 'formik';
import React, {useState} from 'react';
import {object, string} from 'yup';

const FindOrgsForm = () => {
  const [emailSent, setEmailSent] = useState<string>();

  return (
    <>
      <div className="tr-text-3xl ">
        <h2 className="tr-border-solid tr-border-0 tr-border-b-2 tr-border-gray-300 ">
          {emailSent ? 'Check Your Email' : 'Find Your Organization'}
        </h2>
      </div>

      {emailSent ? (
        <>
          <p className="tr-my-8">
            We've sent a special link to <strong>{emailSent}</strong>. Click the link to confirm your address and get
            started.
          </p>
          <p>
            Wrong email? Please{' '}
            <Button
              className="tr-border-0 tr-bg-inherit tr-text-[#007bff] hover:tr-underline tr-p-0"
              onClick={() => setEmailSent(undefined)}
            >
              re-enter your address
            </Button>
          </p>
        </>
      ) : (
        <Formik
          initialValues={{email: ''}}
          validationSchema={object().shape({email: string().email().required()})}
          onSubmit={async ({email}) => {
            await axios.post('/api/auth/find_orgs', {email});
            setEmailSent(email);
          }}
        >
          {({isValid, dirty, isSubmitting}) => (
            <Form className="tr-my-8">
              <Field component={TextInput} label="Email Address" name="email" placeholder="user@example.com" />
              <Button type="submit" disabled={!isValid || !dirty || isSubmitting}>
                Find Your Organization
              </Button>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default FindOrgsForm;
