import React, {useState} from 'react';
import {Tab, Table, Tooltip} from '@trustle/component-library';
import {formatDateFromNow, formatFullDate} from 'src/lib';
import _ from 'lodash';
import {Link} from 'react-router-dom';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Modal} from '@trustle/component-library';
import AutoFormatMetadataTable from '../../../components/design/tables/common/AutoFormatMetadataTable';
import {observer} from 'mobx-react-lite';
import {ResourceCategoryEnum} from 'src/types';
import PermissionBranch from '../TreeView/PermissionBranch';
import {getResourcesTemplate} from 'src/connectors/manifests';
import OrgEvents from 'src/views/Events/OrgEvents';

type RequestAccessModalProps = {
  closeModal: any;
  resource: Resource;
  defaultTab?: TabModeE;
};

export enum TabModeE {
  GENERAL = 'general',
  HISTORY = 'history',
  ROLES = 'roles',
  METADATA = 'metadata',
}

const ResourceDetailModal = observer((props: RequestAccessModalProps) => {
  const {closeModal, resource, defaultTab = TabModeE.GENERAL} = props;
  const template = getResourcesTemplate(resource.rootResource?.type as string);
  const [selectedIndex, setSelectedIndex] = useState<number>(
    _.findIndex(_.values(TabModeE), (tab) => tab === defaultTab)
  );

  const showDates = (date: string | undefined): React.ReactElement | string => {
    if (!date) {
      return '-';
    }
    return (
      <Tooltip content={formatFullDate(date)} position="bottom">
        {formatDateFromNow(date)}
      </Tooltip>
    );
  };

  const resourceData = () => {
    return [
      {
        key: 'Resource',
        value: (
          <Tooltip content={resource.name} position="bottom">
            <div>{resource.name}</div>
          </Tooltip>
        ),
      },
      {
        key: 'Description',
        value: (
          <Tooltip content={resource.name} position="bottom">
            <div>{resource.name}</div>
          </Tooltip>
        ),
      },
      {key: 'Updated', value: showDates(resource.lastChange?.created)},
      {key: 'Created', value: showDates(resource.createdAt)},
    ];
  };

  return (
    <Modal onClose={closeModal} width="xl" height={'xl'} title={`${resource.name} Details`}>
      <Tab.Group selectedIndex={selectedIndex} onChange={(i: number) => setSelectedIndex(i)}>
        <Tab.List variant="line">
          <Tab data-testid={TabModeE.GENERAL} key={TabModeE.GENERAL}>
            {_.startCase(TabModeE.GENERAL)}
          </Tab>
          {!_.isEmpty(resource?.resourcesConnectorData) && (
            <Tab data-testid={TabModeE.METADATA} key={TabModeE.METADATA}>
              {_.startCase(TabModeE.METADATA)}
            </Tab>
          )}
          <Tab data-testid={TabModeE.HISTORY} key={TabModeE.HISTORY}>
            {_.startCase(TabModeE.HISTORY)}
          </Tab>
          {resource.category === ResourceCategoryEnum.REPOSITORY && (
            <Tab data-testid={TabModeE.ROLES} key={TabModeE.ROLES}>
              {_.startCase(TabModeE.ROLES)}
            </Tab>
          )}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel key={TabModeE.GENERAL}>
            <Table
              data={resourceData()}
              tableKey="resourcedetailmodalsgeneral"
              overrideKeyField="key"
              columns={[
                {
                  dataField: 'key',
                  text: 'Account info',
                  headerClasses: 'text-left',
                  classes: 'text-left pl-5',
                  formatter: (_cell: any, row: any) => <strong className="text-left">{row.key}</strong>,
                },
                {dataField: 'value', text: '', classes: 'text-left', style: {width: '30%'}},
                {
                  dataField: 'by',
                  text: '',
                  classes: 'text-left',
                  formatter: (manager: {id: string; displayName: string}) =>
                    manager ? (
                      <div>
                        {'By: '}
                        <Link to={`/users/${manager.id}`} className="underline">
                          {manager.displayName}
                        </Link>
                      </div>
                    ) : (
                      <></>
                    ),
                },
                {dataField: 'extra', text: ''},
              ]}
              striped={true}
              wrapperClasses="simple-table-bordered mt-2"
              bordered={false}
            ></Table>
          </Tab.Panel>
          {!_.isEmpty(resource?.resourcesConnectorData) && (
            <Tab.Panel key={TabModeE.METADATA}>
              <AutoFormatMetadataTable data={resource?.resourcesConnectorData} />
            </Tab.Panel>
          )}
          <Tab.Panel key={TabModeE.HISTORY}>
            <OrgEvents entityType={'resources'} id={resource.id} />
          </Tab.Panel>
          {!_.isEmpty(resource?.resourcesConnectorData) && (
            <Tab.Panel key={TabModeE.ROLES}>
              <>
                {_.map(resource.permissions, (permission) => {
                  return (
                    <PermissionBranch
                      template={template}
                      permission={permission}
                      isTreeView={false}
                      accessMode={false}
                      visibleItems={{
                        resources: [resource.id],
                        accesses: [..._.map(resource.accessRecords, 'id')],
                        permissions: [..._.map(resource.permissions, 'id')],
                      }}
                    />
                  );
                })}
              </>
            </Tab.Panel>
          )}
        </Tab.Panels>
      </Tab.Group>
    </Modal>
  );
});

export default ResourceDetailModal;
