import React, {useMemo} from 'react';
import {observer} from 'mobx-react-lite';
import {Account} from 'src/stores/domainObjects/Account';
import {
  FlaggedForReviewColumn,
  MultipleActionsCol,
  OffboardedByCol,
  OffboardedDateCol,
  StatusColumn,
  UserAccountColumn,
} from 'src/components/design/tables/SharedColumnDefinitions/AccountColumns';
import {Resource} from 'src/stores/domainObjects/Resource';
import {AccountTabTypeE, EmptyAccountsTab} from './ResourceAccounts';
import {TableSetupEnum} from 'src/stores/userStore';
import {ColumnDescription, Table} from '@trustle/component-library';
import _ from 'lodash';
import {getAccountsTemplate} from 'src/connectors/manifests';

const OffboardedAccounts = observer(({accounts, system}: {accounts: Account[]; system: Resource}) => {
  const columns = useMemo(() => {
    const template = getAccountsTemplate(system?.type as string);
    const OffboardedAccountsTable = template!.Offboarded ? template!.Offboarded({accounts, system}) : [];

    const defaultCols: ColumnDescription<Account>[] = [
      FlaggedForReviewColumn,
      StatusColumn,
      UserAccountColumn({columnTitle: 'System Account'}),
      OffboardedByCol,
      OffboardedDateCol,
      {...MultipleActionsCol, hidden: !system.userIsOwner},
    ];

    const enabledColumns: ColumnDescription<Account>[] = _.map(
      defaultCols,
      (col: ColumnDescription<Account>): ColumnDescription<Account> => {
        return {...col, hidden: col.hidden || !OffboardedAccountsTable.includes(col.dataField)};
      }
    );
    return enabledColumns;
  }, [system?.type]);

  return accounts.length === 0 ? (
    <EmptyAccountsTab tab={AccountTabTypeE.OFFBOARDED} />
  ) : (
    <Table
      tableKey={TableSetupEnum.OFFBOARDED_ACCOUNTS}
      data={accounts}
      columns={columns}
      rowClasses={(row: Account) => (row.isAuthzOwner ? 'authz-row' : '')}
      striped={false}
      wrapperClasses="rounded-xl border border-black rounded"
      bordered={false}
    />
  );
});

export default OffboardedAccounts;
