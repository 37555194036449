import React from 'react';
import axios from 'axios';
import _ from 'lodash';
import {makeAutoObservable, onBecomeObserved, runInAction} from 'mobx';
import {logger} from 'src/lib';
import {
  AccountAccessType,
  AccountStatus,
  ConnectionServiceE,
  ConnectorsDataDataTypeE,
  ConnectorsDataT,
  ForeignDataT,
  MinUserT,
  NodeImportStateT,
  NoteT,
  OnboardingModeE,
  ThirdPartyDataOT,
} from 'src/types';
import RootStore from '..';
import {AccessRecord} from './AccessRecord';
import {Resource} from './Resource';
import {Link} from 'react-router-dom';
import moment from 'moment';
import {User} from './User';
import {AccountResponseT} from '../AccountStore';
import {AccountTabTypeE} from 'src/views/Resource/Account/ResourceAccounts';
import {UserStatus} from '../usersStore';

export type UnassignedAccountT = Omit<Account, 'forUser'>;
export type UnassignedAccountsMappingT = {[key: string]: {account: UnassignedAccountT; user?: MinUserT[]}};
export type AccountMappingsT = {
  assigned: Account[];
  unassigned: {
    noMatch: UnassignedAccountsMappingT;
    partialMatch: UnassignedAccountsMappingT;
    perfectMatch: UnassignedAccountsMappingT;
  };
  offboarded: Account[];
};

export class Account {
  id!: string;
  oid!: string;
  rid!: string;
  uid?: string;
  account!: string;
  createdByUid?: string;
  editedByUid?: string;
  editedByRole?: string;
  accountType?: AccountAccessType;
  createdAt?: string;
  editedAt?: string;
  placeholder?: boolean;
  foreignData?: ForeignDataT;
  notes?: NoteT[] = undefined;
  status?: AccountStatus;
  reviewedAt?: string;
  flaggedForReview?: boolean = false;
  deletedRemotely?: boolean;
  onboardingMode?: OnboardingModeE;
  riskScore?: number = undefined;
  errorLoadingNotes?: any;
  uniqueRemoteId?: string;
  tombstone?: boolean;
  refId?: string;
  serviceUsages?: any = undefined;

  accessIds: string[] = [];
  refType: any;

  constructor(
    public rootStore: RootStore,
    account: Partial<Omit<AccountResponseT, 'forUser' | 'editedByUser' | 'createdByUser'>>
  ) {
    makeAutoObservable(this, {id: false});
    Object.assign(this, account);

    onBecomeObserved(this, 'notes', async () => {
      await this.loadNotes();
    });

    onBecomeObserved(this, 'riskScore', async () => {
      const resource = this.rootStore.newResourceStore.resourceMap[this.rid];

      // risk scores only available for AWS right now
      if (resource.type !== ConnectionServiceE.AWS) {
        return;
      }

      if (resource.loadingAccountRiskScores) {
        return;
      }

      runInAction(() => (resource.loadingAccountRiskScores = true));

      try {
        const res = await axios.get<{accounts: {riskScore: number; uaid?: string}[]}>(
          `/api/resources/systems/${resource.rootResource.id}/account-oddity`
        );
        runInAction(() => {
          const riskyAccountUaids: any[] = [];
          res.data.accounts
            // ignore accounts that aren't loaded to avoid defining an account with just an id and riskScore
            .filter((a) => a.uaid && !_.isNil(this.rootStore.accountsStore.accountsMap[a.uaid]))
            .forEach(({riskScore, uaid}) => {
              this.rootStore.accountsStore.updateAccountFromServer({riskScore: _.round(10 * riskScore, 0), id: uaid!});
              riskyAccountUaids.push(uaid);
            });

          const accountsWithoutRisk = resource.accounts.filter((a) => !_.includes(_.compact(riskyAccountUaids), a.id));
          accountsWithoutRisk.forEach(({id}) => {
            if (!_.isNil(this.rootStore.accountsStore.accountsMap[id])) {
              this.rootStore.accountsStore.updateAccountFromServer({riskScore: undefined, id: id!});
            }
          });

          resource.loadingAccountRiskScores = false;
        });
      } catch (err) {
        logger.error(err);
        runInAction(() => {
          resource.loadingAccountRiskScores = false;
          resource.errorLoadingAccountRiskScores = err;
        });
      }
    });

    onBecomeObserved(this, 'serviceUsages', async () => {
      const res = await axios.get(`/api/accounts/${this.id}/connector_service_usages`);
      runInAction(() => (this.serviceUsages = res.data));
    });
  }

  async loadNotes(): Promise<void> {
    try {
      const res = await axios.get(`/api/accounts/${this.id}/notes`);
      runInAction(() => (this.notes = res.data));
    } catch (err) {
      runInAction(() => (this.errorLoadingNotes = err));
    }
  }

  async addNote(comment: string): Promise<void> {
    const {data: note} = await axios.post(`/api/accounts/${this.id}/notes`, {comment});
    const createdBy = this.rootStore.usersStore.usersMap[note.createdByUid];
    runInAction(() => this.notes!.push({...note, createdBy}));
  }

  async editNote(nid: string, comment: string): Promise<void> {
    const {data: note} = await axios.post(`/api/accounts/${this.id}/notes/${nid}`, {comment});

    runInAction(() => Object.assign(_.find(this.notes, {id: nid}) as any, note));
  }

  async deleteNote(nid: string): Promise<void> {
    await axios.delete(`/api/accounts/${this.id}/notes/${nid}`);
    runInAction(() => (this.notes = this.notes?.filter(({id}) => id !== nid)));
  }

  // TODO (jg): update endpoint to make attributes optional, and allow updates to additional fields
  async edit({
    accountType = this.accountType,
    account = this.account,
    uid,
  }: {
    accountType?: AccountAccessType;
    account?: string;
    uid: string;
  }): Promise<void> {
    try {
      if (account !== this.account || accountType !== this.accountType || uid !== this.uid) {
        await axios.post(`/api/accounts/${this.id}`, {accountType, account, uid});
        runInAction(() => Object.assign(this, {accountType, account, uid}));
      }
    } catch (err) {
      this.rootStore.toast.add(`Failed to edit Account.`, {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }

  get forUser(): User | undefined {
    return this.rootStore.usersStore.usersMap[this.uid!];
  }

  get forResource(): Resource {
    return this.rootStore.newResourceStore.resourceMap[this.rid];
  }

  get rootResource(): Resource {
    return this.forResource.rootResource;
  }

  /** @returns all access records associated with this account */
  get accesses(): AccessRecord[] | undefined {
    return this.rootResource.allNestedAccessRecords?.filter((a) => a.uaid === this.id);
  }

  get createdByUser(): User | undefined {
    return this.createdByUid ? this.rootStore.usersStore.usersMap[this.createdByUid] : undefined;
  }

  get editedByUser(): User | undefined {
    return this.editedByUid ? this.rootStore.usersStore.usersMap[this.editedByUid] : undefined;
  }

  /** @return true if this account is assigned to a user */
  get isLinked() {
    return !_.isNil(this.uid);
  }

  /** @return true if this account is offboarded */
  get isOffboarded() {
    return this.status === AccountStatus.OFFBOARDED;
  }

  get isUserOffboarded() {
    return this.forUser?.status === UserStatus.OFFBOARDED || this.forUser?.status === UserStatus.DELETED;
  }

  get categoryAccount(): AccountTabTypeE {
    if (this.uid && !this.isOffboarded && !this.placeholder) {
      return AccountTabTypeE.ASSIGNED;
    }
    if (!this.uid && !this.isOffboarded && !this.placeholder) {
      return AccountTabTypeE.UNASSIGNED;
    }
    if (this.flaggedForReview && !this.placeholder) {
      return AccountTabTypeE.FLAGGED;
    }
    if (this.isOffboarded && !this.placeholder) {
      return AccountTabTypeE.OFFBOARDED;
    }
    return AccountTabTypeE.ASSIGNED; // by default with no results
  }

  /** @returns all resources that this account has access to */
  get resources(): Resource[] {
    return this.rootResource.allNestedResources.filter((r) =>
      r.permissions.some((p) => p.accesses.some((a) => a.uaid === this.id))
    );
  }

  get lastAccountActivity(): string | undefined | null {
    if (this.rootResource.accountLastUsageEvents === undefined) {
      return undefined;
    }
    const lastUsageEvents = this.rootResource.accountLastUsageEvents?.[this.id];
    const dates = _.values(lastUsageEvents);
    if (_.isEmpty(dates)) {
      return null;
    }
    return moment.max(_.map(dates, (date) => moment(date))).toISOString();
  }

  get lastLogin(): string | undefined {
    return this.rootResource.accountLastUsageEvents?.[this.id]?.loginSuccess;
  }

  get lastProgrammaticAccess(): string | undefined {
    return this.rootResource.accountLastUsageEvents?.[this.id]?.lastProgrammaticAccess;
  }

  get additionalInformation(): ConnectorsDataT | undefined {
    switch (this.forResource.type) {
      case ConnectionServiceE.AWS:
        return _.find(this.connectorsData, {dataType: ConnectorsDataDataTypeE.CREDENTIAL_REPORT, refId: this.id});
      case ConnectionServiceE.GITHUB:
      case ConnectionServiceE.OKTA:
      case ConnectionServiceE.GAPPS:
        return _.find(this.connectorsData, {dataType: ConnectorsDataDataTypeE.USER_ADDITIONAL_DATA, refId: this.id});
      case ConnectionServiceE.M365:
        return _.find(this.connectorsData, {dataType: ConnectorsDataDataTypeE.ENTITY_DATA, refId: this.id});
      default:
        return undefined;
    }
  }

  get recommendations(): React.ReactElement {
    const escapedName = encodeURIComponent(this.account)
      .replace('#', '%23')
      .replace("'", '%27')
      .replace('(', '%28')
      .replace(')', '%29')
      .replace('*', '%2A')
      .replace('+', '%%2B');

    return (
      <>
        {_.isNil(this.uid) && (
          <Link to={`/resource/manage/${this.rootResource.id}/accounts/unlinked?Account+Name=${escapedName}`}>
            Link Account
          </Link>
        )}
      </>
    );
  }

  get connectorsData(): ConnectorsDataT[] | undefined | null {
    if (this.rootResource.accountsConnectorData === undefined) {
      // still loading
      return undefined;
    }

    return this.rootResource.accountsConnectorData[this.id] ?? null;
  }

  get isAuthzOwner(): boolean {
    let resource: Resource;
    if (this.rid && this.uniqueRemoteId) {
      resource = this.rootStore.newResourceStore.resourceMap[this.rid];
      return resource.connector?.authzOwner?.UserId === this.uniqueRemoteId;
    }
    return false;
  }

  get numConnectedApps(): number | undefined {
    const thirdPartyApps = this.rootResource.connectedApps?.find((e) => e.refId === this.id);
    return (thirdPartyApps?.data as ThirdPartyDataOT)?.data?.length;
  }

  get partialUserMatch(): User[] {
    const uids = this.rootResource.matches.partial[this.id] ?? [];

    const users = uids.map((uid) => this.rootStore.usersStore.usersMap[uid]);

    return users.filter((user) => !user.tombstone && !user.terminated);
  }

  get perfectUserMatch(): User[] {
    const uids = this.rootResource.matches.perfect[this.id] ?? [];

    const users = uids.map((uid) => this.rootStore.usersStore.usersMap[uid]);

    return users.filter((user) => !user.tombstone && !user.terminated);
  }

  /** returns undefined while loading, then null or NodeImportStateT after loading is complete */
  get nodeImportState(): undefined | NodeImportStateT | null {
    if (this.rootResource.accountNodeImportStates === undefined) {
      // still loading
      return undefined;
    }

    return this.rootResource.accountNodeImportStates[this.id] ?? null;
  }

  /** returns undefined while loading, then null or number after loading is complete */
  get usage(): undefined | number | null {
    return this.usageData?.usage;
  }

  /** returns undefined while loading, then null or value after loading is complete */
  get usageData(): {usage: number; lastEventDate: string} | undefined {
    const {accountUsages} = this.rootResource;
    if (accountUsages === undefined) {
      // still loading
      return undefined;
    }

    const accountUsage = accountUsages?.[this.id];

    // Okta percentage of usage for each permission is calculated differently
    if (this.rootResource.type !== ConnectionServiceE.OKTA) {
      return accountUsage ?? {usage: 0, lastEventDate: ''};
    }

    const appsCnt = _.size(_.find(this.rootResource.childResources, {name: 'Applications'})?.permissions);
    let servicesCnt = _.size(this.serviceUsages);

    if (appsCnt < servicesCnt) {
      servicesCnt = appsCnt;
    }
    const percentage = appsCnt === 0 ? 0 : _.round((servicesCnt / appsCnt) * 100);
    return {usage: percentage, lastEventDate: accountUsage?.lastEventDate ?? ''};
  }

  get referencedEntity(): Resource | undefined {
    if (!this.refId) {
      return;
    }
    switch (this.refType) {
      case 'resource': {
        return this.rootStore.newResourceStore.resourceMap[this.refId];
      }
      default: {
        return;
      }
    }
  }

  get accessesCount(): number {
    const accessesByAccount = this.forResource?.accessAggregatesByUaid[this.id] || [];
    return accessesByAccount.length;
  }
}
