import React from 'react';
import _ from 'lodash';
import {Table} from '@trustle/component-library';
import {observer} from 'mobx-react';
import {HISTORY_COLUMNS} from './columns';
import {useRootStore} from '../../../../../../../../../lib/hooks';

type Props = {
  userId: string;
};

export const UserHistory = observer(({userId}: Props) => {
  // TODO: This should load history from new endpoint
  const {usersStore, currentUser} = useRootStore();

  if (_.isNil(currentUser)) {
    return <></>;
  }

  const userEvents = usersStore.usersMap[userId].history;

  return (
    <div className="tr-flex tr-flex-col tr-gap-4">
      <h3>User history</h3>
      <div className="tr-max-h-[350px] tr-overflow-auto">
        <Table data={userEvents} columns={HISTORY_COLUMNS} striped={false} tableKey="historyUser" />
      </div>
    </div>
  );
});
