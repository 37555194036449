import React from 'react';
import {Switch, Route} from 'react-router-dom';
import OTLTask from 'src/views/OTLTask';

function OTL() {
  return (
    <Switch>
      <Route path="/otl/tasks/:taskId/:action/:oneTimeLink">
        <OTLTask />
      </Route>
    </Switch>
  );
}

export default OTL;
