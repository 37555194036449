import React, {useState} from 'react';
import './CheckoutPage.scss';
import {observer} from 'mobx-react';
import PlanSettings from './PlanSettings';
import {useQueryParams, useRootStore} from 'src/lib/hooks';
import planDescriptions, {createFeatureList} from '../admin/payments/PlanDescriptions';
import {Loading} from '@trustle/component-library';
import _ from 'lodash';

const StripeCheckout = observer(function CheckoutFormCmp() {
  const {org} = useRootStore();
  const [selectedPrice, setSelectedPrice] = useState<any>();
  const [plan] = useQueryParams(['plan']);

  if (org?.products === undefined) {
    return (
      <div className="mt-5 tr-flex justify-content-center">
        <Loading />;
      </div>
    );
  }

  const products = org.products;

  const selectedPackage = plan ? products[plan] : products[Object.keys(products)[0]];
  if (selectedPrice === undefined && selectedPackage && _.size(selectedPackage.prices)) {
    setSelectedPrice(selectedPackage.prices[0]);
  }

  const planDescription = planDescriptions[selectedPackage.name];

  return (
    <div className="checkout tr-flex tr-mx-auto tr-h-full">
      <div className="tr-flex tr-flex-col tr-h-full">
        <div className="tr-flex tr-w-1/2">
          <div className="tr-flex tr-flex-col tr-m-5">
            <h1 className={'ml-5 mt-3 title'}>{`What you get by upgrading to ${planDescription.name}`}</h1>
            <div className="ml-5 subtitle mb-2 ">{planDescription.featuresTitle}</div>
            <div className="ml-5 pl-5 mt-5">
              <div className="">{createFeatureList(planDescription.name, planDescription.features)}</div>
            </div>
          </div>
          <div className="tr-flex tr-flex-col tr-mt-5 tr-pt-5">
            <div className="tr-mt-5 tr-pt-5 tr-flex tr-justify-center">
              <a
                className={'tr-m-3 grayed-out'}
                target="_blank"
                rel="noreferrer"
                href="https:/trustle.com/legal/privacy"
              >
                Privacy Policy
              </a>
              <a className={'tr-m-3 grayed-out'} target="_blank" rel="noreferrer" href="https:/trustle.com/legal/tos">
                Terms and Conditions
              </a>
            </div>
          </div>
        </div>
        <div className="tr-h-full tr-flex tr-w-1/2 tr-flex-col tr-content-start ">
          <PlanSettings
            selectedPackage={selectedPackage}
            selectedPrice={selectedPrice}
            setSelectedPrice={setSelectedPrice}
          />
        </div>
      </div>
    </div>
  );
});

export default StripeCheckout;
