import React from 'react';
import {TextInput} from '@trustle/component-library';
import {formatDateFromNow} from '../../../../lib';
import {LoginMethodsE} from '../../../../types';
import _ from 'lodash';
import {User} from '../../../../stores/domainObjects/User';

type Props = {
  user: User;
};

export function UserLoginInfo({user}: Props) {
  const loginMethod =
    user.loginMethod === LoginMethodsE.GOOGLE
      ? 'Google OAuth'
      : user.loginMethod === LoginMethodsE.PASSWORD
      ? 'Email / Password'
      : _.startCase(user.loginMethod);

  const latLogin = user.lastLogin ? formatDateFromNow(user.lastLogin) : '';

  return (
    <div className="tr-flex tr-flex-col tr-gap-4">
      <h3>Login Info</h3>
      <div className="tr-flex tr-flex-col">
        <div className="tr-flex tr-gap-4">
          <TextInput label="Method" value={loginMethod} disabled />
          <TextInput label="Last Login" value={latLogin} disabled />
        </div>
      </div>
    </div>
  );
}
