import React from 'react';
import _ from 'lodash';
import {SensitivityLevelDefinitions} from './SensitivityConfiguration';
import {useRootStore} from 'src/lib/hooks';
import {Icon} from '@trustle/component-library';

const definitionsByLevel = _.keyBy(SensitivityLevelDefinitions, 'level');

export function SensitivityMonoIcon(props: {className?: string; sensitivityId?: string | null}) {
  const {org} = useRootStore();

  if (_.isNil(org)) {
    return <></>;
  }

  const sensitivitySettings = org.sensitivitySettingsSorted;

  const sensitivitySetting = props.sensitivityId ? _.find(sensitivitySettings, {id: props.sensitivityId} as any) : null;

  if (!sensitivitySetting) {
    return null;
  }

  return (
    <Icon
      type={definitionsByLevel[sensitivitySetting.level].icon ?? 'error'}
      className={`${props.className} tr-grayscale`}
    />
  );
}
