import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {ExpandRowProps} from 'react-bootstrap-table-next';
import {Link, useHistory, useParams} from 'react-router-dom';
import {
  ActionsCol,
  TypeCol,
  UserAccountCol,
  RiskScoreCol,
  GroupedRecsTypeCol,
  GroupedRecsStatusCol,
  GroupedRecsActionsCol,
  LastRotatedCol,
  GroupedRecs,
  ExpandIndicatorCol,
  GroupedRecsCompletedByCol,
  GroupedRecsCompletedOnCol,
  GROUPED_REC_TYPE,
  AssignedOnCol,
  AssignedByCol,
  AssignedToCol,
  CompletedOnCol,
  CompletedByCol,
} from 'src/components/design/tables/SharedColumnDefinitions/RecommendationColumns';
import {Recommendation, RECOMMENDATION_TYPE} from 'src/stores/domainObjects/Recommendation';
import {Resource} from 'src/stores/domainObjects/Resource';
import {ConnectionServiceE} from 'src/types';
import {recStats} from '../MainPage';
import AccountDetailModal, {TabModeE} from 'src/views/Resource/Account/AccountDetailsModal';
import {Account} from 'src/stores/domainObjects/Account';
import {CircleGauge, Table, Tab, Icon} from '@trustle/component-library';

const {
  ROTATE_ACCESS_KEY_1: RAK1,
  ROTATE_ACCESS_KEY_2: RAK2,
  ROTATE_CERT_1: RC1,
  ROTATE_CERT_2: RC2,
} = RECOMMENDATION_TYPE;

export enum SystemRecommendationsTab {
  SYSTEM_RECOMMENDATIONS = 'System Recommendations',
  TOKENS_AND_CERTIFICATES = 'Tokens and Certificates',
}

const RecTable = observer(
  ({category, recs}: {category: 'unreviewed' | 'inProcess' | 'completed'; recs: Recommendation[]}) => {
    const [selected, setSelected] = useState<_.Dictionary<string[]>>({
      [GROUPED_REC_TYPE.RAK]: [],
      [GROUPED_REC_TYPE.RC]: [],
    });
    const [showAccountDetails, setShowAccountDetails] = useState<Account>();
    const [accountDetailsTab, setAccountDetailsTab] = useState<TabModeE>();

    const data = _(recs)
      .groupBy(({type}) => {
        switch (type) {
          case RAK1:
          case RAK2:
            return GROUPED_REC_TYPE.RAK;
          case RC1:
          case RC2:
            return GROUPED_REC_TYPE.RC;
          default:
            return type;
        }
      })
      .toPairs()
      .map(([type, recs], id) => ({
        id,
        type,
        recs,
        expandable: type === GROUPED_REC_TYPE.RAK || type === GROUPED_REC_TYPE.RC || recs.length > 1,
      }))
      .value();

    return (
      <>
        <Table
          classes="font-weight-bold"
          tableKey={'recom1'}
          rowClasses="tr-h-16"
          columns={
            {
              unreviewed: [
                GroupedRecsTypeCol,
                ExpandIndicatorCol,
                GroupedRecsStatusCol,
                {
                  ...GroupedRecsActionsCol,
                  formatExtraData: {
                    selected,
                    onClick: (recs: Recommendation[]) => recs[0].system?.assignRecommendations(_.map(recs, 'id')),
                  },
                },
              ],
              // TODO (jg): update when there are recommendations in inProcess */}
              inProcess: [GroupedRecsTypeCol, ExpandIndicatorCol, GroupedRecsStatusCol],
              completed: [
                GroupedRecsTypeCol,
                ExpandIndicatorCol,
                GroupedRecsStatusCol,
                GroupedRecsCompletedOnCol,
                GroupedRecsCompletedByCol,
              ],
            }[category]
          }
          data={data}
          expandRow={
            {
              nonExpandable: data.filter((r) => !r.expandable).map((r) => r.id),
              parentClassName: `expanded-row`,
              renderer: ({type, recs}) => (
                <Table
                  tableKey={'recommendations2'}
                  columns={
                    {
                      unreviewed: [
                        UserAccountCol,
                        {...TypeCol, text: 'Access Key'},
                        LastRotatedCol,
                        // TODO (jg): RotatedByCol when data is returned by API
                        {
                          ...RiskScoreCol,
                          formatExtraData: {
                            onClick: (account: Account) => {
                              setAccountDetailsTab(TabModeE.RISK_REPORT);
                              setShowAccountDetails(account);
                            },
                          },
                        },
                        ActionsCol,
                      ],
                      // TODO (jg): update when there are expandable recommendations in inProcess/completed */}
                      inProcess: [
                        UserAccountCol,
                        {...TypeCol, text: 'Access Key'},
                        LastRotatedCol,
                        AssignedToCol,
                        AssignedOnCol,
                        AssignedByCol,
                      ],
                      completed: [UserAccountCol, CompletedOnCol, CompletedByCol],
                    }[category]
                  }
                  data={recs}
                  selectRow={{
                    hideSelectColumn: true,
                    mode: 'checkbox',
                    nonSelectable: recs
                      .filter((rec) => _.isNil(rec.associatedAccount?.uid))
                      .map((rec) => rec.id) as unknown as number[],
                    onSelect: ({id}, isSelect) => {
                      setSelected({
                        ...selected,
                        [type]: isSelect ? [...selected[type], id] : _.without(selected[type], id),
                      });
                    },
                  }}
                />
              ),
            } as ExpandRowProps<GroupedRecs> // casting because ExpandRow type is incorrect
          }
        />
        {!_.isNil(showAccountDetails) && (
          <AccountDetailModal
            closeModal={() => setShowAccountDetails(undefined)}
            userAccount={showAccountDetails}
            mode={accountDetailsTab}
          />
        )}
      </>
    );
  }
);

type TabsPropsT = {unreviewed: Recommendation[]; inProcess: Recommendation[]; completed: Recommendation[]};

const RecTabs = observer(({unreviewed, inProcess, completed}: TabsPropsT) => {
  return (
    <Tab.Group>
      <Tab.List>
        <Tab data-testid={'inProcess'} key={'inProcess'}>
          {`In Process (${inProcess.length})`}
        </Tab>
        <Tab data-testid={'unreviewed'} key={'unreviewed'}>
          {`Unreviewed (${unreviewed.length})`}
        </Tab>
        <Tab data-testid={'completed'} key={'completed'}>
          {`Completed (${completed.length})`}
        </Tab>
      </Tab.List>
      <Tab.Panels>
        <Tab.Panel data-testid={'inProcess'} key={'inProcess'}>
          <RecTable category="inProcess" recs={inProcess} />
        </Tab.Panel>
        <Tab.Panel data-testid={'unreviewed'} key={'unreviewed'}>
          <RecTable category="unreviewed" recs={unreviewed} />
        </Tab.Panel>
        <Tab.Panel data-testid={'completed'} key={'completed'}>
          <RecTable category="completed" recs={completed} />
        </Tab.Panel>
      </Tab.Panels>
    </Tab.Group>
  );
});

type SystemRecommendationsPropsT = {system: Resource};
const SystemRecommendations = observer(({system}: SystemRecommendationsPropsT) => {
  const {tab} = useParams<{tab: SystemRecommendationsTab}>();
  const history = useHistory();

  const sysRecs = recStats(system.sysRecs);
  const tokenAndCertRecs = recStats(system.tokenAndCertRecs);

  const tiles: {number: number; option: SystemRecommendationsTab; percentage: number}[] = [
    {
      number: sysRecs.uncompleted.length,
      option: SystemRecommendationsTab.SYSTEM_RECOMMENDATIONS,
      percentage: Math.round(100 * sysRecs.percentCompleted),
    },
  ];

  if (system.type === ConnectionServiceE.AWS && _.values(tokenAndCertRecs).some((recs) => !_.isEmpty(recs))) {
    tiles.push({
      number: tokenAndCertRecs.uncompleted.length,
      option: SystemRecommendationsTab.TOKENS_AND_CERTIFICATES,
      percentage: Math.round(100 * tokenAndCertRecs.percentCompleted),
    });
  }

  return (
    <div className="container tr-mx-auto">
      <Link className="tr-flex align-items-center my-3" to={`/resource/manage/${system.id}/recommendations`}>
        <Icon type="back" className="tr-mr-1" />
        Go to Recommendations Main Page
      </Link>
      <div className="tr-flex tr-justify-between align-items-center">
        <h1 className="mb-0">System</h1>
        <div className="tr-flex align-items-center">
          <h2 className="text-xl mr-3 mb-0">Trustle Score</h2>
          <CircleGauge
            className="tr-w-16 tr-h-16"
            circles={[{color: '#FF9500', percent: system.percentOfRecsCompleted}]}
          >
            <span className="text-xl font-weight-bold">{Math.round(100 * system.percentOfRecsCompleted)}%</span>
          </CircleGauge>
        </div>
      </div>
      <hr />
      <div className="tr-flex tr-flex-wrap tr-mb-4">
        {tiles.map(({number, option, percentage}) => (
          <div className="tr-flex tr-flex-col tr-mx-[15px] tr-mb-4" key={option}>
            <button
              className={`relative tr-flex flex-column tr-w-72 rounded-xl shadow-lg p-3 border-0 ${
                tab === option ? 'text-white bg-navy' : 'text-blue'
              }`}
              onClick={() => history.push(`/resource/manage/${system.id}/recommendations/system/${option}`)}
              disabled={tab === option}
            >
              <span className="absolute" style={{top: '0.5rem', right: '0.5rem'}}>
                {percentage}%
              </span>
              <h3 className="text-7.5xl font-weight-bold">{number}</h3>
              <p className="font-weight-bold">{option}</p>
            </button>
          </div>
        ))}
      </div>
      <RecTabs {...(tab === SystemRecommendationsTab.SYSTEM_RECOMMENDATIONS ? sysRecs : tokenAndCertRecs)} />
    </div>
  );
});

export default SystemRecommendations;
