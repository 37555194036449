import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {ConnectionInfoT, ImportStatus, ResourceT, TestConnectionResponseT} from 'src/types';
import {Card} from 'src/components/design';
import {Button, Loading} from '@trustle/component-library';
import ConnectorStatus from 'src/components/StatusIndicator';
import PagerDutySetup from './PagerDutySetup';
import {useConnectionCredentials} from '../../lib/hooks';
import {ReportType} from '../types';

export default function EditConnectionPagerDuty(props: {resource: ResourceT}) {
  const {resource} = props;
  const lastImport = resource.connector?.lastImport;
  const [connectionInfo, setConnectionInfo] = useState<ConnectionInfoT>();
  const [displayCredentialsForm, setDisplayCredentialsForm] = useState<boolean>(false);
  const [connectorStatus] = useState<string | undefined>(lastImport?.status);
  const [testConnectionResult, setTestConnectionResult] = useState<TestConnectionResponseT>();
  const [testConnectionInProgress, setTestConnectionInProgress] = useState<boolean>(false);
  const {testConnection: testConnectionAPI} = useConnectionCredentials();

  async function loadConnection() {
    const connectionResponse = await axios.get(`/api/connect/${resource.connectionId}`);
    setConnectionInfo(connectionResponse.data);
  }

  async function onCredentialsUpdated() {
    await loadConnection();
    setTestConnectionResult(undefined);
    setDisplayCredentialsForm(false);
  }

  useEffect(() => {
    void loadConnection();
  }, [resource.connectionId]);

  if (!connectionInfo) {
    return <Loading />;
  }

  const {connection} = connectionInfo;
  const {serviceFriendlyName} = connection;

  async function testConnection() {
    setTestConnectionInProgress(true);
    const {data} = await testConnectionAPI(connection.id, {});
    setTestConnectionResult(data);
    setTestConnectionInProgress(false);

    if (data.error) {
      return;
    }

    await axios.post(`/api/connect/${resource.connectionId}/start_import`);
  }

  function renderTestConnection() {
    const testConnectionText = (() => {
      if (testConnectionInProgress) {
        return (
          <div className="body6 text-right">
            <Loading size="sm" /> Connecting
          </div>
        );
      }
      return (
        <span className={`${testConnectionResult?.ok ? 'text-success' : 'text-danger'} body6`}>
          {testConnectionResult?.ok ? 'Connected Successfully!' : testConnectionResult?.error?.message}
        </span>
      );
    })();

    return <div className="text-right body6">{testConnectionText}</div>;
  }

  return (
    <div>
      <div className="tr-flex tr-flex-col">
        <div className="tr-my-2 no-padding">
          <h3>Authentication</h3>

          <div className="tr-w-full lg:tr-w-2/3">
            <div>
              {connectorStatus === ImportStatus.PENDING && (
                <span className="body6 badge badge-warning">Import in progress...</span>
              )}
              {renderTestConnection()}
            </div>
            <Card body>
              <div className="tr-flex align-items-top tr-justify-between">
                <div className="tr-flex  tr-items-center">
                  <ConnectorStatus className="tr-mr-3" status={connectorStatus}></ConnectorStatus>

                  <div className="body4 ellipsis-wrap">{serviceFriendlyName}</div>
                </div>
                <div>
                  <Button
                    variant="secondary"
                    label="Update"
                    selected={displayCredentialsForm}
                    onClick={() => {
                      setDisplayCredentialsForm(!displayCredentialsForm);
                    }}
                  />
                  <Button variant="secondary" label="Test" onClick={() => testConnection()} />
                </div>
              </div>
            </Card>
            {displayCredentialsForm && (
              <PagerDutySetup connectionInfo={connectionInfo} onComplete={onCredentialsUpdated} editMode={true} />
            )}
          </div>
        </div>
        <div className="tr-pt-2 tr-my-4 no-padding">
          <h3>Available Reports</h3>

          <div className="tr-flex tr-flex-col tr-w-full lg:tr-w-2/3">
            <div className="tr-flex">
              <a
                href={`/api/connect/${connection.id}/report/${ReportType.APP_USAGE_PER_ACCOUNT}`}
                className="link"
                download
              >
                App Usage Per Account
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
