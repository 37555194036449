import React, {useEffect} from 'react';
import axios from 'axios';
import {Switch, Route, useHistory, useParams} from 'react-router-dom';
import SlackConnector from 'src/connectors/slack/SlackConnector';

import ConnectorFlow from './ConnectorFlow';
import './ConnectorRender.scss';
import {Loading} from '@trustle/component-library';

function ReenterSetup() {
  const {id} = useParams() as {id: string};
  const history = useHistory();
  useEffect(() => {
    async function redirectPage() {
      const response = await axios.get(`/api/connect/${id}/reentry`);
      history.replace(response.data.path);
    }
    void redirectPage();
  }, [id]);
  return <Loading />;
}

function ConnectorsBase() {
  return (
    <Switch>
      <Route path="/connect/slack" exact={false}>
        <SlackConnector />
      </Route>
      <Route path="/connect/reentry/:id" exact={true}>
        <ReenterSetup />
      </Route>
      <Route path="/connect/:connectorType" exact={false}>
        <ConnectorFlow />
      </Route>
    </Switch>
  );
}

export default ConnectorsBase;
