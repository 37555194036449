import _ from 'lodash';
import React, {useEffect, useState} from 'react';
import {ConnectionServiceE, SourceOfTruthE, TeamT, TeamTypeEnum} from 'src/types';
import {formatUserName, fuseSearch} from 'src/lib';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Icon,
  IconButton,
  Loading,
  SystemsAccordion,
  TileButton,
  Tooltip,
} from '@trustle/component-library';
import TeamForm from './TeamForm';
import {Link, useHistory} from 'react-router-dom';
import {Confirmation, Modal} from '@trustle/component-library';
import {
  Authority,
  NameCol,
  DepartmentRoleTypeCol,
} from 'src/components/design/tables/SharedColumnDefinitions/UserColumns';
import {useRootStore} from 'src/lib/hooks';
import {Team} from 'src/stores/domainObjects/Team';
import {observer} from 'mobx-react-lite';
import './TeamsList.scss';
import {Search} from 'src/components/Search/Search';
import NoData from 'src/components/NoData';
import {User} from 'src/stores/domainObjects/User';
import {SystemCounts} from '@trustle/component-library/dist/molecules/SystemsModal/SystemsAccordion';
import {Table} from '@trustle/component-library';
import {ColumnDescription, UserAccordion} from '@trustle/component-library';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';
import OrgEvents from 'src/views/Events/OrgEvents';

type TeamListPropsT = {
  teams: Team[];
  showMemberCount?: boolean;
  selectedId?: string;
  onSelect: (teamId: string) => void;
  onEdit: (teamId: string) => void;
  onRemove: (teamId: string) => void;
};

interface TeamDetailsHeaderProps {
  team: Team;
  'data-testid'?: string;
}

const TeamList = observer(({teams, showMemberCount, selectedId, onSelect, onEdit, onRemove}: TeamListPropsT) => {
  return (
    <div className="tr-mb-3 tr-w-[294px]">
      {_.map(teams, (team: Team) => {
        if (_.isEmpty(team)) {
          return <></>;
        }
        const disabledRemove = team.type === 'organization';
        const teamActions = [
          {
            key: `viewUser${team.id}`,
            name: 'Edit',
            onClick: () => {
              onSelect(team.id);
              onEdit(team.id);
            },
          },
          {
            key: `removeUser${team.id}`,
            name: 'Remove',
            onClick: () => onRemove(team.id),
            disabled: disabledRemove,
          },
        ];
        return (
          <>
            <div
              key={team.id}
              data-testid={`select-${team.id}`}
              className={`tt--team-list-item1 tr-flex tr-justify-between tr-p-2 tr-rounded-lg tr-border-solid tr-border-[1px] tr-border-gray-300 tr-mb-2 tr-cursor-pointer hover:tr-bg-[#C2E5FF33] ${
                selectedId === team.id ? 'tr-bg-trustle-bglight hover:tr-bg-trustle-bglight' : ''
              }`}
              onClick={() => onSelect(team.id)}
            >
              <div className="tr-flex tr-flex-col tr-w-full tr-m-1">
                <div className="tr-flex tr-justify-between tr-items-center">
                  <div className={'tr-flex tr-items center'}>
                    <span className={`tt--team-color team-gradient-${team.color}`}></span>
                    <span className={'tt--team-name tr-truncate tr-max-w-[120px] tr-text-sm tr-ml-3'}>{team.name}</span>
                  </div>
                  <div className={'tr-flex tr-items-center'}>
                    {showMemberCount && (
                      <div className="tr-flex tr-items-end">
                        <Icon type="groupPeople" className="tr-ml-1" />
                        <div className={'tr-font-bold tr-text-xs tr-text-gray-semidark tr-ml-1'}>
                          {team.memberIds.length}
                        </div>
                      </div>
                    )}
                  </div>
                  <Dropdown
                    variant="ternary"
                    size="sm"
                    dropdownToggle={<Icon type="moreVertical" />}
                    dropdownMenu={
                      <DropdownMenu>
                        {teamActions.map((action) => {
                          return (
                            <DropdownItem
                              onClick={() => {
                                action.onClick();
                              }}
                              key={action.key}
                            >
                              {action.name}
                            </DropdownItem>
                          );
                        })}
                      </DropdownMenu>
                    }
                  />
                </div>
                <div className={`tr-text-sm ${selectedId === team.id ? '' : ' hidden '}`}>
                  <div className="tr-flex">
                    <Icon type="admin" size="sm" className="tr-mr-2" title="Manager" />
                    <UserAccordion users={team?.managers} title={team?.managers.length > 1 ? 'Managers' : 'Manager'} />
                  </div>
                </div>
              </div>
            </div>
          </>
        );
      })}
    </div>
  );
});

const TeamsList = observer(({forUser}: {forUser?: string}) => {
  const {teamStore, usersStore, newResourceStore, org, currentUser} = useRootStore();

  const {idpFeatureEnabled} = org;

  const history = useHistory();
  const [selectedTeamId, setSelectedTeamId] = useState<string>();
  const [editingTeam, setEditingTeam] = useState<string>();
  const [memberQuery, setMemberQuery] = useState('');
  const [teamQuery, setTeamMenu] = useState('');
  const [userToDelete, setUserToDelete] = useState<string>();
  const [teamToDelete, setTeamToDelete] = useState<string>();
  const [showHistory, setShowHistory] = useState<boolean>(false);

  const [columnSort, setColumnSort] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (newResourceStore.loadingSystems) {
      void newResourceStore.loadSystems();
    }
  }, []);
  if (_.isNil(currentUser)) {
    return <Loading />;
  }

  const canEdit = currentUser.isOrgOwner;
  const user = forUser ? usersStore.usersMap[forUser] : undefined;
  const memberTeams = _.sortBy(user?.memberTeams ?? [], ['name', 'desc']);
  const managedTeams = _.sortBy(user?.managedTeams ?? teamStore.teams, ['name', 'desc']);
  const teams = _.keyBy([...memberTeams, ...managedTeams], 'id');

  if (teamStore.loading || user?.loadingTeams || newResourceStore.loadingSystems) {
    return <Loading />;
  }

  const selectedTeam: Team | undefined = selectedTeamId ? teams[selectedTeamId] : undefined;

  const ObservableAccessesContent = observer(({u}: {u: User}) => {
    return (
      <Button variant="ternary" onClick={() => history.push(`/users/${u.id}`)} className="text-caption12">
        {u.accessCount}
      </Button>
    );
  });

  const ObservablePermissionsContent = observer(({u}: {u: User}) => {
    const countBySystem: _.Dictionary<number> = _.countBy(
      _.filter(newResourceStore.systems, (s) => {
        return u.systemList.includes(s.id);
      }),
      'type'
    );
    return (
      <div className="tr-flex">
        <div className="tr-mr-3 tr-w-16">
          {!_.isEmpty(countBySystem) && <SystemsAccordion {...(countBySystem as unknown as SystemCounts)} />}
        </div>
        <div>
          <Icon type="permission" />
          {u.permissionCount}
        </div>
      </div>
    );
  });

  const userColumns: ColumnDescription[] = [
    NameCol({columnWidth: '11rem'}),
    {
      dataField: 'accesses',
      text: 'Accesses',
      style: {width: '5rem'},
      headerStyle: {width: '5rem'},
      formatter: (_unused: any, u: User) => <ObservableAccessesContent u={u} />,
      sort: true,
    },
    {
      dataField: 'createdByEmail',
      sort: true,
      hidden: true,
      headerStyle: {textAlign: 'left', width: '8rem'},
      style: {textAlign: 'left', width: '8rem'},
      text: 'Added by',
      formatter: (cell: any, u: User) => {
        const usersByEmail = _.keyBy(usersStore.users, 'email');
        const createdByUser = usersByEmail[u.createdByEmail ?? ''];
        return _.isNil(createdByUser) ? (
          <div>Not available</div>
        ) : (
          <Link to={`/users/${createdByUser.id}`}>{formatUserName(createdByUser)}</Link>
        );
      },
    },
    {
      dataField: 'permissions',
      sort: true,
      headerStyle: {textAlign: 'left', width: '8rem'},
      style: {textAlign: 'justify', width: '8rem'},
      text: 'Systems / Permissions',
      formatter: (_cell: any, u: User) => {
        return <ObservablePermissionsContent u={u} />;
      },
    },
    {...Authority, hidden: !idpFeatureEnabled},
    DepartmentRoleTypeCol({idpFeatureEnabled, columnSort, setColumnSort}),
    {
      dataField: 'actions',
      headerStyle: {width: '4rem'},
      style: {width: '4rem'},
      classes: 'show-on-hover',
      text: '',
      hidden: !canEdit,
      formatter: (cell: any, u: User) => {
        const items = [];

        items.push(
          <IconButton
            icon="viewUser"
            variant="ternary"
            key={`viewmember${u.id}`}
            title="View member"
            data-testid="view-member"
            onClick={(e: any) => {
              e.stopPropagation();
              history.push(`/users/${u.id}`);
            }}
          />
        );

        const isTrustleSoT = _.defaultTo(u.sourceOfTruth, SourceOfTruthE.TRUSTLE) === SourceOfTruthE.TRUSTLE;

        if (isTrustleSoT && selectedTeam && selectedTeam.type !== TeamTypeEnum.OWNER) {
          items.push(
            <IconButton
              icon="removeUser"
              variant="ternary"
              key={`removemember${u.id}`}
              title="Remove member"
              data-testid={`removemember${u.id}`}
              onClick={(e: any) => {
                e.stopPropagation();
                setUserToDelete(u.id);
              }}
            />
          );
        }

        return <div className="flex-align tr-justify-center">{items}</div>;
      },
    },
  ];

  const filteredUsers = fuseSearch(memberQuery, ['email', 'fullname'], selectedTeam?.members ?? []);
  const filteredTeams = fuseSearch(teamQuery, ['name'], managedTeams);
  const managedCount = _.size(managedTeams);
  const memberCount = _.size(memberTeams);
  const service =
    selectedTeam?.sourceOfTruth === SourceOfTruthE.TRUSTLE
      ? 'trustle'
      : (org.idpSettings?.service as ConnectionServiceE);

  const TeamDetailsHeader: React.FC<TeamDetailsHeaderProps> = observer(({team, 'data-testid': testId}) => {
    const countByTeamSystems = _.countBy(
      _.flatMap(team?.members, (member) =>
        _.map(
          _.filter(newResourceStore.systems, (s) => member.systemList.includes(s.id)),
          'type'
        )
      )
    ) as unknown as SystemCounts;

    const statsTeam = [
      {total: team.members.length, name: 'members'},
      {total: team.departments.length, name: 'departments'},
      {total: team.roles.length, name: 'roles'},
      {total: team.titles.length, name: 'titles'},
    ];

    const DetailBox = ({team}: {team: Team}) => {
      return (
        <div className="tr-flex tr-flex-col tr-w-full">
          <div className="tr-flex tr-justify-between tr-items-center tr-pb-2">
            <div className="tr-flex tr-items-center">
              <div className="tr-w-[40px] tr-h-[40px] tr-flex tr-items-center tr-justify-center">
                <span className={`tt--team-color-lg team-gradient-${team.color}`}></span>
              </div>
              {idpFeatureEnabled && (
                <Tooltip content={`Team created through ${team.authorityLabel}`} position={'bottom'}>
                  <TileButton name={service as SystemIconsTypesEnum} />
                  <div className="tr-flex tr-justify-center ">
                    <a href="#" title={`Authority: ${team.authorityLabel}`}></a>
                  </div>
                </Tooltip>
              )}
              <div className="tr-flex tr-items-baseline">
                <div className="tr-text-sm tr-font-bold tr-pl-2 tr-max-w-[200px] tr-truncate">{team.name}</div>
              </div>
            </div>
            <Icon type="details" size="md" onClick={() => setEditingTeam(team.id)} className="cursor tr-text-navy" />
          </div>
          <div className="tr-flex">
            <Icon type="system" size="md" title="Systems" />
            {!_.isEmpty(countByTeamSystems) && (
              <SystemsAccordion {...countByTeamSystems} showLabel={true} title="Available Systems" />
            )}
            <div className="tr-flex tr-items-center tr-pl-2">
              <Icon type="admin" className="tr-mr-2 " title="Manager" />
              <UserAccordion users={team?.managers} title={team?.managers.length > 1 ? 'Managers' : 'Manager'} />
            </div>
          </div>
        </div>
      );
    };

    return (
      <div
        className={`tr-flex align-items-center rounded-2xl shadow-md tr-p-4 tr-justify-between`}
        data-testid={testId}
      >
        <div className="tr-flex tr-border-solid tr-border-[0px] tr-border-r-[1px] tr-border-r-gray-300 tr-pl-1 tr-pr-4 tr-w-full">
          <DetailBox team={team} />
        </div>
        <div className="tr-flex tr-justify-between tr-px-4 tr-w-full">
          {_.map(statsTeam, (stat) => (
            <div className="tr-flex tr-flex-col tr-text-5xl tr-font-bold tr-text-center tr-text-navy " key={stat.name}>
              <div className="tr-text-5xl tr-pb-2">{stat.total}</div>
              <div className="tr-text-sm">{_.upperFirst(stat.name)}</div>
            </div>
          ))}
        </div>
      </div>
    );
  });

  return (
    <div className="tr-pt-8">
      {managedCount > 0 && (
        <div className="tr-flex tr-w-full">
          <div className="tr-flex tr-justify-between tr-items-center ">
            <span className="body3"></span>
          </div>
          <Search
            placeholder="Search for Teams"
            editURL={false}
            onChange={(query) => {
              setTeamMenu(query);
              setSelectedTeamId(undefined);
            }}
            className="tr-w-full tr-rounded-lg"
          />
        </div>
      )}

      <div className="tt--team-list tr-py-8">
        <div className="tr-flex">
          <div className="tr-grow-0 tr-mr-8">
            {memberCount === 0 && managedCount === 0 && _.isNil(selectedTeam) && (
              <NoData>Hmm, we couldn't seem to find any teams data.</NoData>
            )}
            {memberCount > 0 && forUser && (
              <>
                <h2 className="text-lg mb-3">Member of</h2>
                <TeamList
                  teams={memberTeams}
                  selectedId={selectedTeamId}
                  onSelect={(id) => {
                    setSelectedTeamId(id);
                    void teamStore.loadTeam(id);
                  }}
                  onEdit={(id) => setEditingTeam(id)}
                  onRemove={(id) => setTeamToDelete(id)}
                />
              </>
            )}
            {managedCount > 0 && !_.isEmpty(filteredTeams) && (
              <>
                <TeamList
                  teams={filteredTeams}
                  showMemberCount={forUser === undefined}
                  selectedId={selectedTeamId}
                  onSelect={(id) => {
                    setSelectedTeamId(id);
                    void teamStore.loadTeam(id);
                  }}
                  onEdit={(id) => setEditingTeam(id)}
                  onRemove={(id) => setTeamToDelete(id)}
                />
              </>
            )}
          </div>

          {selectedTeam ? (
            <div className="tr-flex tr-flex-col ">
              <TeamDetailsHeader team={selectedTeam} data-testid="team-details-header" />
              <div className="tt--team-view-wrapper tt--team-details tr-px-0 tr-mt-12">
                <div className="tt-team-member-header tr-flex tr-justify-between tr-items-center mb-1 ">
                  <div className="tr-flex tr-justify-between tr-items-center">
                    <span className="body3">Members</span>
                  </div>
                  <Search
                    placeholder="Search for Member"
                    editURL={false}
                    onChange={(query) => {
                      setMemberQuery(query);
                    }}
                  />
                </div>
                <div className="tt-team-users-wrapper ">
                  <Table
                    data={filteredUsers ?? []}
                    columns={userColumns}
                    tableKey={'accountData'}
                    striped={false}
                    bordered={false}
                    rowClasses={'tr-h-16'}
                  />
                </div>
              </div>
              <div className="tr-flex tr-justify-end tr-pr-4">
                <span className="tr-font-bold tr-text-blue cursor" onClick={() => setShowHistory(true)}>
                  View history of this team
                </span>
              </div>
            </div>
          ) : !_.isEmpty(filteredTeams) ? (
            <div className="tr-flex tr-flex-col tr-m-auto text-center">
              <img className="tr-w-72" src="/russel-curious-left.svg" alt="" />
              <h2 className="mt-0 text-xl text-center font-weight-bold text-dark">Select a team to view it’s info!</h2>
            </div>
          ) : (
            <NoData>Hmm, we couldn't seem to find any teams data.</NoData>
          )}
        </div>
        <Modal visible={!!editingTeam} onClose={() => setEditingTeam(undefined)} width="md" title="Update Team">
          {editingTeam && (
            <TeamForm
              team={teams[editingTeam] as unknown as TeamT}
              afterSubmit={async () => {
                await teamStore.refresh();
                setEditingTeam(undefined);
              }}
            />
          )}
        </Modal>
        {userToDelete && (
          <Confirmation
            onConfirm={async () => {
              await selectedTeam?.deleteUser(userToDelete);
              setUserToDelete(undefined);
            }}
            onClose={() => setUserToDelete(undefined)}
            title="Confirm member removal"
          >
            {`Please confirm that you want to remove ${formatUserName(
              usersStore.usersMap[userToDelete]
            )} from this group`}
          </Confirmation>
        )}
        {teamToDelete && (
          <Confirmation
            onConfirm={async () => {
              const team = teams[teamToDelete];
              await teamStore.deleteTeam(team);
              setTeamToDelete(undefined);
            }}
            onClose={() => setTeamToDelete(undefined)}
            title="Confirm team deletion"
          >
            {`Please confirm that you want to delete '${teams[teamToDelete]?.name}' team.`}
          </Confirmation>
        )}
        {selectedTeam && (
          <Modal visible={!!showHistory} onClose={() => setShowHistory(false)} width="lg" title="History Team">
            <OrgEvents entityType={'teams'} id={selectedTeam.id} />
          </Modal>
        )}
      </div>
    </div>
  );
});

export default TeamsList;
