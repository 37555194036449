import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Formik, Form as FormikForm, Field} from 'formik';
import {SubmitButton} from 'src/components/design';
import {MinUserT, TeamT, UserT, TeamTypeEnum} from 'src/types';
import _ from 'lodash';
import {components, ControlProps, OptionProps} from 'react-select';
import {Loading} from '@trustle/component-library';
import {formatUserNameAndEmail} from 'src/lib';
import {Button, Select, TextAreaInput, TextInput} from '@trustle/component-library';
import {useRootStore} from 'src/lib/hooks';
import {array, object, string} from 'yup';

type PropsT = {
  team: Partial<TeamT>;
  afterSubmit: () => void;
};

type OptionType = {
  value: string;
  label: string;
};

export type TeamFormT = {
  id: string | undefined;
  name: string;
  description: string;
  color?: {value: string; label: string};
  type?: {value: string; label: string};
  members: string[];
  managers: string[];
};

export default function TeamForm(props: PropsT) {
  const {afterSubmit} = props;

  const {usersStore, teamStore} = useRootStore();
  const [isLoading, setLoading] = useState<boolean>(props.team.id ? true : false);
  const [users, setUsers] = useState<UserT[]>(
    usersStore.users.filter((u) => props.team.members?.map((x) => x.id).includes(u.id))
  );
  const [managers, setManagers] = useState<UserT[]>([]);

  useEffect(() => {
    async function teamLoader() {
      await loadTeam();
    }
    void teamLoader();
  }, []);

  async function loadTeam(): Promise<void> {
    if (props.team.id) {
      setLoading(true);
      const response = await axios.get(`/api/teams/${props.team.id}`);
      const {users, managers} = response.data;
      setUsers(users);
      setManagers(managers);
      setLoading(false);
    }
  }

  const teamTypes = _.filter(
    [
      {label: 'Organization', value: TeamTypeEnum.ORGANIZATION},
      {label: 'Ad Hoc', value: TeamTypeEnum.ADHOC},
      {label: 'Owner', value: TeamTypeEnum.OWNER},
    ],
    (teamOption) => {
      // Existing teams cannot change type.
      if (props.team.id) {
        return teamOption.value === props.team.type;
      }

      // 'OWNER' teams cannot be created.
      return teamOption.value !== TeamTypeEnum.OWNER;
    }
  );

  function convertTeamToForm(team: Partial<TeamT>): TeamFormT {
    // Defaulting to ORGANIZATION teams until we support AdHoc as an option.
    const teamType = _.find(teamTypes, {value: team.type || TeamTypeEnum.ORGANIZATION}) as {
      label: string;
      value: string;
    };
    return {
      id: team.id,
      name: team.name || '',
      description: team.description || '',
      color: _.find(teamColors, {value: team.color}),
      type: teamType,
      members:
        _.map(team.members || users, (o: MinUserT) => {
          return o.id;
        }) ?? [],
      managers:
        _.map(team.managers || managers, (o: MinUserT) => {
          return o.id;
        }) ?? [],
    };
  }

  const teamColors = [
    {label: 'Blue', value: 'blue'},
    {label: 'Dark Blue', value: 'dark-blue'},
    {label: 'Green', value: 'green'},
    {label: 'Violet', value: 'violet'},
    {label: 'Red', value: 'red'},
    {label: 'Yellow', value: 'yellow'},
    {label: 'Pink', value: 'pink'},
    {label: 'Orange', value: 'orange'},
    {label: 'Turquoise', value: 'turquoise'},
  ];

  async function onSubmit(values: TeamFormT) {
    const body = {
      name: values.name,
      description: values.description,
      color: values.color?.value,
      type: values.type?.value,
      members: values.members,
      managers: values.managers,
    };

    if (props.team.id) {
      delete body.type;
    }
    try {
      await teamStore.createTeam(body as TeamFormT, props.team.id);
    } catch (e: any) {
      const errorMessage = e.response.data?.error?.message || 'An error occurred while saving your changes';
      afterSubmit();
      throw errorMessage;
    }
    afterSubmit();
  }

  if (isLoading) {
    return <Loading />;
  }

  const CustomOption = ({innerProps, data}: OptionProps<OptionType, boolean>) => (
    <div {...innerProps} className={'flex-align'}>
      <div className={`ml-2 selection-option-color team-gradient-${data.value}`}></div>
      {data.label}
    </div>
  );

  const CustomControl = ({children, ...rest}: ControlProps<OptionType, boolean>) => {
    const selected = rest.getValue();
    const value = Array.isArray(selected) ? selected[0] : selected;
    return (
      <components.Control {...rest}>
        <div className={`ml-2 selection-option-color team-gradient-${value?.value}`}></div>
        {children}
      </components.Control>
    );
  };

  return (
    <Formik
      initialValues={convertTeamToForm(props.team)}
      onSubmit={onSubmit}
      initialTouched={{managers: true, members: true}}
      validationSchema={object().shape({
        managers: array()
          .of(string())
          .min(1)
          .required('There needs to be at least 1 valid manager')
          .test('managers', 'User cant be both member and manager of a team', (managers = [], {parent}) => {
            return !managers.some((manager) => _.includes(parent.members, manager));
          }),
        members: array()
          .of(string())
          .min(1)
          .required('There needs to be at least 1 valid member')
          .test('members', 'User cant be both member and manager of a team', (members = [], {parent}) => {
            return !parent.managers.some((manager: string) => _.includes(members, manager));
          }),
        name: string().required('Please select a name for the team'),
        description: string(),
      })}
    >
      {({values, isSubmitting, isValid}) => {
        const disableTypeSelection = !!values.id;
        return (
          <FormikForm>
            <div className="tr-grid tr-gap-4 tr-grid-cols-2">
              <Field component={TextInput} label="Name" name="name" id="name" disabled={isSubmitting} required />

              <Field
                component={Select}
                label="Color"
                name="color"
                options={teamColors}
                required
                components={{Option: CustomOption, Control: CustomControl}}
              />
            </div>
            <Field
              component={TextAreaInput}
              name="description"
              id="description"
              label={
                <div className="tr-flex tr-justify-between">
                  <div className="tr-font-bold tr-text-xs tr-text-trustle-dark">Description</div>
                  <div className="tr-text-xs text-gray tr-font-normal">This will be shown to end users</div>
                </div>
              }
              rows={3}
              required
              className="tr-pb-4"
              data-testid="description"
            />
            <Field
              component={Select}
              disabled={disableTypeSelection}
              label="Type"
              name="type"
              options={teamTypes}
              required
            />
            <Field
              component={Select}
              name={'managers'}
              id={'managers'}
              data-testid="typeahead-select"
              className="tr-my-2 tr-pb-2"
              isMulti={true}
              label="Team Managers"
              options={_.map(usersStore.users, (suggestion: any) => {
                return {
                  id: suggestion.id,
                  value: suggestion.id,
                  label: formatUserNameAndEmail(suggestion) ?? `${suggestion.email}`,
                };
              })}
            />
            <Field
              component={Select}
              name={'members'}
              id={'members'}
              isMulti={true}
              className="tr-my-2 pb-7"
              label="Team Members"
              required={true}
              onChange={(e: any) => {
                setUsers(e);
              }}
              options={_.map(usersStore.users, (suggestion: any) => {
                return {
                  id: suggestion.id,
                  value: suggestion.id,
                  label: formatUserNameAndEmail(suggestion) ?? `${suggestion.email}`,
                };
              })}
            />
            <div className="tr-flex tr-justify-end tr-items-center">
              <Button variant="secondary" onClick={afterSubmit}>
                Cancel
              </Button>
              <SubmitButton
                name="submit"
                label={`${props.team.id ? `Update` : `Create`} Team`}
                inSubmit={isSubmitting}
                disabled={!isValid}
                data-testId="submitTeam"
              />
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  );
}
