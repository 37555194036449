import React from 'react';
import {TabsDocsT} from '../DocSection';
import DocSection from '../DocSection';
import ShowError from './ShowError';
import _ from 'lodash';

function ConfigureGAPPS() {
  const errorList = [
    {
      title: ['Not Authorized to access this resource/api'],
      body: (
        <>
          <div>
            <span className="text bolder font-weight-bold">Problem description:&nbsp;</span>
            Selected Google Account does not have sufficient permissions to call the API's described in{' '}
            <i>Supported Features</i> requirements.
          </div>
          <div>
            <span className="text text-success bolder font-weight-bold">Solution:&nbsp;</span>
            <ul>
              <li>
                Contact the Google Workspace Account Administrator to verify that the Account has the correct level of
                privileges (Or switch to a different Account with the correct level of privileges).
              </li>
              <li>
                To connect Trustle to your Google Workspace organization, you must use a Google account with the
                required permissions.{' '}
                <strong>
                  Please be sure to grant the predefined administrator role{' '}
                  <a
                    href="https://support.google.com/a/answer/2405986?sjid=14217478134709019929-SA#super_admin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Super Admin
                  </a>{' '}
                  to the user that you use here.
                </strong>{' '}
                After you assign to the user this role, please be sure to go over the renewal process on{' '}
                <strong>Settings &gt; connections settings &gt; Update button.</strong>
              </li>
            </ul>
          </div>
        </>
      ),
    },
    {
      title: [
        'Token has been expired or revoked',
        'Bad Request',
        'The OAuth client was not found',
        'Invalid Credentials',
        'invalid_grant',
        'invalid_client',
      ],
      body: (
        <>
          <div>
            <span className="text bolder font-weight-bold">Problem description:&nbsp;</span>
            The Account used by Trustle to access the Google Workspace API has been revoked or has expired.
          </div>
          <div>
            <span className="text text-success bolder font-weight-bold">Solution:&nbsp;</span>
            You can verify Trustle's access to the Google Workspace account at:{' '}
            <a href="https://myaccount.google.com/permissions" target="_blank" rel="noreferrer">
              <span className="text-underline">Apps with access to your account section</span>
            </a>
            <div>
              If access for the Account used to connect to the Google Workspace API been revoked, Trustle can be
              re-connected by re-authenticating. Click on the <i>Update Button</i> in{' '}
              <i>Settings tab &gt; Connection Settings</i> for the Resource, as shown below:
              <div>
                <img
                  src="/docs/connectors/gapps/trustle_connect_gapps.png"
                  className="vertical-align shadow mb-4 mt-3"
                />
              </div>
            </div>
          </div>
        </>
      ),
    },
    {
      title: ['Access Denied'],
      body: (
        <>
          {' '}
          <div>
            <span className="text bolder font-weight-bold">Problem description:&nbsp;</span>
            The user canceled the authorization process before allowing Trustle to access data on Google Workspace.
          </div>
          <div>
            <span className="text text-success bolder font-weight-bold">Solution:&nbsp;</span>
            The System can connect by re-authenticating on Trustle; Click on the <i>Update button</i> in{' '}
            <i>Settings tab &gt; Connection Settings</i> on the Resource page.
            <div></div>
          </div>
        </>
      ),
    },
  ];

  const tabs: TabsDocsT = [
    {id: 'gapps-contents', label: 'Contents', description: contentDetail(), active: true},
    {id: 'gapps-supported-features', label: 'Supported Features', description: supportedFeatures(), active: false},
    {id: 'gapps-overview', label: 'Overview', description: overview(), active: false},
    {id: 'gapps-msg-errors', label: 'Troubleshooting', description: messageErrors(), active: false},
  ];

  function contentDetail(): React.ReactNode {
    return (
      <>
        <div className="mb-4 body3">Connect Trustle to Google Workspace</div>
        <div>
          Trustle connects to Google Workspace using OAuth2 Authentication. Follow the steps provide to connect Trustle
          to your Organizations Google Workspace with the correct level of access.
        </div>
      </>
    );
  }

  function supportedFeatures(): React.ReactNode {
    return (
      <>
        <div className="mb-4 body3">Google Workspace Supported Features</div>
        <div>Trustle Supports:</div>
        <div>
          <ul>
            <li>Importing Users</li>
            <li>Importing Groups</li>
            <li>Import Administrative system roles</li>
            <li>Managing group memberships</li>
          </ul>
        </div>
      </>
    );
  }

  function messageErrors(): React.ReactNode {
    return (
      <>
        <div className="mb-4 body3">Common errors</div>
        {_.map(errorList, (error) => {
          return (
            <>
              <ShowError title={error.title}>{error.body}</ShowError>
              <hr />
            </>
          );
        })}
      </>
    );
  }

  function overview(): React.ReactNode {
    return (
      <>
        <div className="mb-4 body3">Google Workspace Overview</div>
        <div>Coming soon...</div>
      </>
    );
  }

  return (
    <>
      <DocSection tabs={tabs} title="How to configure Google Workspace for Trustle" />
    </>
  );
}

export default ConfigureGAPPS;
