import React from 'react';
import {observer} from 'mobx-react-lite';
import {UserType} from 'src/types';
import {Account} from 'src/stores/domainObjects/Account';
import FilterToggleBtn from 'src/components/FilterToggleBtn';
import {Icon} from '@trustle/component-library';
import {Resource} from 'src/stores/domainObjects/Resource';
import {AccountTabTypeE, EmptyAccountsTab} from './ResourceAccounts';
import LinkedAccountsTable from './LinkedAccountsTable';

const LinkedAccounts = observer(({accounts, system}: {accounts: Account[]; system: Resource}) => {
  if (accounts.length === 0) {
    return <EmptyAccountsTab tab={AccountTabTypeE.ASSIGNED} />;
  }

  const countByType = (type: UserType) => accounts.filter((acc) => acc.forUser?.type === type).length;

  return (
    <>
      <div className="tr-flex mt-3">
        <FilterToggleBtn
          filterKey="Trustle User Type"
          value={UserType.employee}
          disabled={countByType(UserType.employee) === 0}
        >
          <Icon
            type="employeeUser"
            className={`${countByType(UserType.employee) === 0 ? 'tr-opacity-20' : ''} tr-pb-1 mr-1`}
            size="sm"
          />
          Employee
          <span className="ml-2">{countByType(UserType.employee)}</span>
        </FilterToggleBtn>
        <FilterToggleBtn
          filterKey="Trustle User Type"
          value={UserType.contractor}
          disabled={countByType(UserType.contractor) === 0}
        >
          <Icon
            type="contractorUser"
            className={`${countByType(UserType.contractor) === 0 ? 'tr-opacity-20' : ''} tr-pb-1 mr-1`}
            size="sm"
          />
          Contractor
          <span className="ml-2">{countByType(UserType.contractor)}</span>
        </FilterToggleBtn>
        <FilterToggleBtn
          filterKey="Trustle User Type"
          value={UserType.customer}
          disabled={countByType(UserType.customer) === 0}
        >
          <Icon
            type="customerUser"
            className={`${countByType(UserType.customer) === 0 ? 'tr-opacity-20' : ''} tr-pb-1 mr-1`}
            size="sm"
          />
          Customer
          <span className="ml-2">{countByType(UserType.customer)}</span>
        </FilterToggleBtn>
        <FilterToggleBtn
          filterKey="Trustle User Type"
          value={UserType.system}
          disabled={countByType(UserType.system) === 0}
        >
          <Icon
            type="systemUser"
            className={`${countByType(UserType.system) === 0 ? 'tr-opacity-20' : ''} tr-pb-1 mr-1`}
            size="sm"
          />
          System
          <span className="ml-2">{countByType(UserType.system)}</span>
        </FilterToggleBtn>
      </div>
      <LinkedAccountsTable accounts={accounts} system={system} />
    </>
  );
});

export default LinkedAccounts;
