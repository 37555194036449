import React from 'react';
import {TabsDocsT} from '../DocSection';
import DocSection from '../DocSection';
import {
  CSVContent,
  entityColumnsDef, exampleDataCSVAccess,
  exampleDataCSVAccounts,
  exampleDataCSVResourcesAndPermissions, optionalAccessColumns, optionalAccountColumns,
  optionalResPermColumns, requiredAccessColumns,
  requiredAccountColumns, requiredResPermColumns,
} from 'src/components/UniversalDataUpload/helper';
import _ from 'lodash';
import {DocumentationTable} from '@trustle/component-library';

function GenericContentGuide() {
  const allColumns = entityColumnsDef();
  const tabs: TabsDocsT = [
    {id: 'generic-overview', label: 'Overview', description: overview(), active: true},
    {id: 'generic-examples', label: 'Examples', description: examples(), active: true},
  ];

  function overview(): JSX.Element {
    return (
      <div>
        <div>
          <p className="tr-w-75">
            The data related to a connector in Trustle can be uploaded through a CSV file.
            <p>
              The following list of columns is a specification of the required format for the CSV. These definitions
              apply to all the available entity types:
            </p>
          </p>
          <div className="mt-1 ml-4 mb-4 tr-w-75">
            <li className="pb-2">
              <strong>ACCOUNT:</strong> represents a user in the system. Each user can be associated with several
              resources and permissions.
            </li>
            <li className="pb-2">
              <strong>RESOURCE:</strong> entity that allows to logically group permissions.
            </li>
            <li className="pb-2">
              <strong>PERMISSION:</strong> represents the definition of an entitlement or a set of entitlements and can
              be associated with a resource or a user. Examples of permissions are: policies, roles, group of users,
              etc. If the permission represents a group, all of its members will inherit the permissions associated with
              it.
            </li>
          </div>
        </div>
        <div className={'border-bottom pt-3 mb-2'}>
          <p className={'mb-1'}>
            <strong>Column Definitions</strong>
          </p>
        </div>
        <div className={'pb-4'}>
          {allColumns.map((column: any) => (
            <div className={'mb-1'} key={column.key}>
              <b className={'text-capitalize pr-2'}>{`${column.key}:`}</b> {column.description}
              <br />
              <div className={'text-muted'}>
                {column.conditions &&
                  `${
                    _.isArray(column.conditions) ? 'One of: '.concat(column.conditions.join(', ')) : column.conditions
                  }`}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  function examples(): JSX.Element {
    return (
      <div>
        <p className="pb-3">
          The following examples are based on an AWS account with 3 users, 3 resources, 4 permissions and 3 accesses.
          <p className="pt-2">
            The result of importing this scenario into Trustle will be:
            <div className="pl-4 tr-w-75">
              <li>
                Three accounts will be imported: two personal accounts, <strong>administrator</strong> and
                <strong> username@domain.com</strong>, and one service account, <strong>trustle_app</strong>.
              </li>
              <li>
                The <strong>AWS Groups</strong> resource will contain all the user groups defined, which are
                <strong> development</strong> and <strong>production</strong>.
              </li>
              <li>
                The <strong>AWS Managed Policies</strong> resource will contain 3 policies:
                <strong> AdministratorAccess</strong>, <strong> AmazonS3ReadOnlyAccess</strong> and
                <strong> AmazonS3FullAccess</strong>.
              </li>
              <li>
                The <strong>AWS Inline Policies</strong> resource will contain just 1 policy:
                <strong> list_access_reports</strong>.
              </li>
              <li>
                Since the <strong>admin</strong> role permission doesn't belong to any specific resource, it will be
                displayed in the top level as a System Permission.
              </li>
              <li>
                The <strong>administrator</strong> account will have the <strong>admin</strong> role associated, which
                is also related to the <strong>AdministratorAccess</strong> and
                <strong> AmazonS3FullAccess</strong> policies.
              </li>
              <li>
                The <strong>username@domain.com</strong> account will be a member of the<strong> development </strong>
                group and will have permission to <strong>list_access_reports</strong>. Also, the user will inherit the
                policy <strong>AmazonS3FullAccess</strong> (through the membership to the <strong>development </strong>
                group).
              </li>
              <li>
                The <strong>trustle_app</strong> service account will have permission to perform actions allowed by
                <strong> AmazonS3ReadOnlyAccess</strong> policy only.
              </li>
            </div>
          </p>
        </p>

        <ExampleTable type={CSVContent.ACCOUNT} />
        <ExampleTable type={CSVContent.RESOURCE_PERMISSION} />
        <ExampleTable type={CSVContent.ACCESS_RECORD} />
      </div>
    );
  }

  return (
    <>
      <DocSection tabs={tabs} title="Guide: How to create connector's data through CSV file upload" />
    </>
  );
}

function ExampleTable(props: {type: CSVContent}): JSX.Element {
  const {type} = props;
  const link = `/api/connect/generic/download_example?content=${type}`;

  return (
    <div className="mb-5">
      <div className={'border-bottom tr-flex tr-justify-between my-2'}>
        <p className={'mb-1'}>
          <strong className={'text-capitalize'}>{`Entity type: ${type}`}</strong>
        </p>
        <a href={link} className="link" download>
          {'Download example'}
        </a>
      </div>
      <div>
        {type === CSVContent.ACCOUNT && (
          <DocumentationTable
            requiredColumns={requiredAccountColumns}
            optionalColumns={optionalAccountColumns}
            data={exampleDataCSVAccounts}
          />
        )}
        {type === CSVContent.RESOURCE_PERMISSION && (
          <DocumentationTable
            requiredColumns={requiredResPermColumns}
            optionalColumns={optionalResPermColumns}
            data={exampleDataCSVResourcesAndPermissions}
          />
        )}
        {type === CSVContent.ACCESS_RECORD && (<DocumentationTable
          requiredColumns={requiredAccessColumns}
          optionalColumns={optionalAccessColumns}
          data={exampleDataCSVAccess}
        />)}
      </div>
    </div>
  );
}

export default GenericContentGuide;
