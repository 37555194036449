import _ from 'lodash';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {SubmitButton} from 'src/components/design';
import OnboardingWrapper from 'src/views/OnboardingWrapper';
import {Formik, FormikForm} from 'src/components/design/formik';
import {LoginMethodsE, passwordValidationRegex} from 'src/types';
import axios from 'axios';
import {useToasts} from 'react-toast-notifications';
import {useRootStore} from 'src/lib/hooks';
import {Field} from 'formik';
import {PasswordInput, TextInput} from '@trustle/component-library';

function SetupProfile() {
  const {currentUser} = useRootStore();
  const history = useHistory();
  const {addToast} = useToasts();

  if (_.isNil(currentUser)) {
    return <></>;
  }

  const requirePassword = currentUser.loginMethod === LoginMethodsE.PASSWORD;

  const initialValues = {
    firstName: currentUser.firstname || '',
    lastName: currentUser.lastname || '',
    email: currentUser.email || '',
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  };

  const validate = async (values: any) => {
    const errors: _.Dictionary<string | undefined> = {};
    if (_.isEmpty(values.firstName)) {
      errors.firstName = 'This field is required';
    }
    if (_.isEmpty(values.lastName)) {
      errors.lastName = 'This field is required';
    }
    if (requirePassword) {
      if (currentUser.hasPassword && _.isEmpty(values.currentPassword)) {
        errors.currentPassword = 'This field is required';
      }
      if (_.isEmpty(values.newPassword)) {
        errors.newPassword = 'This field is required';
      }
      if (!passwordValidationRegex.test(values.newPassword)) {
        errors.newPassword =
          'The new password does not accomplish our policy described below or was previously exposed in data breaches';
      }
      if (_.isEmpty(values.confirmNewPassword)) {
        errors.confirmNewPassword = 'This field is required';
      }
      if (values.newPassword !== values.confirmNewPassword) {
        errors.confirmNewPassword = 'This field does not match the new password';
      }
    }

    return errors;
  };

  const passwordSetups = requirePassword && (
    <>
      {currentUser.hasPassword && (
        <>
          <Field
            component={PasswordInput}
            label="Your current password"
            name="currentPassword"
            id="currentPassword"
            showreveal={true}
          />
          <hr />
        </>
      )}
      <Field
        component={PasswordInput}
        label="New password"
        name="newPassword"
        id="newPassword"
        showreveal={true}
        description="Password must be at least eight characters, one uppercase letter, one lowercase letter, one number and one special character between: #?!@$%^&*-()"
      />
      <Field
        component={PasswordInput}
        label="Confirm new password"
        name="confirmNewPassword"
        id="confirmNewPassword"
        showreveal={true}
      />
    </>
  );

  const renderPasswordFields = () => {
    if (currentUser.loginMethod === LoginMethodsE.GOOGLE) {
      return null;
    }
    return passwordSetups;
  };

  const isPwdSecure = async (pwd: string) => {
    try {
      const {data} = await axios.post('/api/auth/password_check', {password: pwd});
      if (data.potentiallyExposed) {
        return false;
      } else {
        return true;
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <>
      <OnboardingWrapper>
        <h4 className="card-title form-title">
          {currentUser.loginMethod === LoginMethodsE.PASSWORD ? 'Set your name and password' : 'Set your name'}
        </h4>
        <div className="login-form-container flex-column mr-5 ml-5">
          <Formik
            initialValues={initialValues}
            validate={async (values: any) => {
              return await validate(values);
            }}
            onSubmit={async (values: any, params: {setFieldError: any}) => {
              let condition = true;
              if (currentUser.loginMethod === LoginMethodsE.PASSWORD) {
                condition = await isPwdSecure(values.newPassword);
              }
              if (condition) {
                try {
                  await axios.post(
                    `/api/setup/profile`,
                    _.pick(values, ['firstName', 'lastName', 'currentPassword', 'newPassword'])
                  );
                  history.push('/setup/complete');
                } catch (err: any) {
                  const errorMessage = _.get(err, 'response.data.error.message');
                  switch (errorMessage) {
                    case 'Current password is not correct.':
                    case 'Passwords are not allowed for your login method':
                    case 'Current password required to change password.':
                      params.setFieldError(
                        'currentPassword',
                        'Current password is not correct. Please verify and try again.'
                      );
                      break;
                    case 'First name is required.':
                      params.setFieldError('firstName', 'First name is required.');
                      break;
                    case 'Last name is required.':
                      params.setFieldError('lastName', 'Last name is required.');
                      break;
                    default:
                      addToast('Error saving notification configuration. Try modifying the values', {
                        appearance: 'error',
                        autoDismiss: true,
                      });
                      break;
                  }
                }
              } else {
                params.setFieldError(
                  'newPassword',
                  'The new password does not accomplish with our policy described below or was previously exposed in data breaches'
                );
              }
            }}
          >
            {({isSubmitting, errors}) => (
              <FormikForm>
                <div className="tr-flex tr-flex-col">
                  <Field
                    component={TextInput}
                    label="Your first name"
                    placeholder="your first name"
                    id="firstName"
                    name="firstName"
                  />

                  <Field
                    component={TextInput}
                    label="Your last name"
                    placeholder="your last name"
                    id="lastName"
                    name="lastName"
                  />
                </div>
                {renderPasswordFields()}
                <div className="text-right">
                  <SubmitButton
                    label="Next"
                    inSubmit={isSubmitting}
                    className="tr-mr-3"
                    disabled={!_.isEmpty(errors) || isSubmitting}
                  />
                </div>
              </FormikForm>
            )}
          </Formik>
        </div>
      </OnboardingWrapper>
    </>
  );
}

export default SetupProfile;
