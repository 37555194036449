import {Icon} from '@trustle/component-library';
import React from 'react';

export const SAMLLoginButton: React.ElementType = (props: {url: string}) => {
  const {url} = props;
  return (
    <div className="tr-pt-8 saml-button">
      <a href={url} className="btn btn-light tr-px-4 font-weight-bold ml-0 tr-rounded-lg tr-border-gray-300">
        <Icon type="Saml" forcedSize={30} className="tr-pl-2 tr-text-sm tr-text-sensitivity-red" />
        <span className="tr-pl-2 tr-text-sm">Login with SSO</span>
      </a>
    </div>
  );
};
