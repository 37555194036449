import {Table} from '@trustle/component-library';
import React from 'react';
import {useUserFilters} from '../../UserFiltersContext';
import {TableStatus} from '../../../../common/TableStatus';
import {useUsersListed} from '../../UsersListedContext';
import {USER_COLUMNS} from './userColumns';

const TABLE_KEY = 'users-table';

export function UsersTable() {
  const {listUsersResponse, loading} = useUsersListed();

  const {filters, setFilter, setFilters} = useUserFilters();

  const handleTableChange = async (
    _type: string,
    {page, sortField, sortOrder}: {page: number; sortField: string; sortOrder: 'asc' | 'desc'}
  ) => {
    if (_type === 'pagination') {
      setFilter('page', page);
    }

    if (_type === 'sort') {
      const toSet = {orderBy: sortField, sort: sortOrder};
      setFilters({...filters, ...toSet});
    }
  };

  const handleSizePerPageChange = (size: number) => {
    setFilter('size', size);
  };

  if (loading && !listUsersResponse) {
    return <TableStatus text="Loading users..." status="loading" description="Please stand by" />;
  }

  const {users, total} = listUsersResponse;

  return (
    <Table
      data={users}
      columns={USER_COLUMNS}
      tableKey={TABLE_KEY}
      totalSize={total}
      sizePerPage={filters.size}
      page={filters.page}
      remote={{
        filter: true,
        pagination: true,
        sort: false,
        cellEdit: false,
      }}
      onSizePerPageChange={(size) => {
        handleSizePerPageChange(size);
        return {};
      }}
      onTableChange={handleTableChange}
      showEmptyElement={
        <TableStatus status="no-data" text="No users found" description="Try with different filter criteria" />
      }
    />
  );
}
