import _ from 'lodash';
import React, {useState} from 'react';
import axios from 'axios';
import {SubmitButton} from 'src/components/design';
import {Formik, FormikForm} from 'src/components/design/formik';
import './SetupTableau.scss';
import {NextStepT, TableauCredentialTypeE} from '../types';
import {ConnectionInfoT, ConnectionT} from 'src/types';
import {useConnectionCredentials} from '../../lib/hooks';
import {TextInput, Alert} from '@trustle/component-library';
import {Field} from 'formik';

type ConnectorPropsT = {
  onComplete: NextStepT;
  connectionInfo: ConnectionInfoT;
  editMode?: boolean;
};

export default function SetupTableau(props: ConnectorPropsT) {
  const {editMode = false, connectionInfo, onComplete} = props;
  const [connection, setConnection] = useState<ConnectionT>(connectionInfo.connection);
  const [saved, setSaved] = useState<boolean>(false);
  const {testConnection: testConnectionAPI} = useConnectionCredentials();

  async function reloadConnection() {
    const {data} = await axios.get(`/api/connect/${connection.id}`);
    setConnection(data.connection);
  }

  async function submitCredentials(
    values: {serverName: string; siteName: string; tokenName: string; secret: string},
    setSubmitting: (submitting: boolean) => void,
    setStatus: (status?: any) => void
  ) {
    try {
      const {data} = await testConnectionAPI(connection.id, {
        key: TableauCredentialTypeE.CLIENT_CREDENTIALS,
        value: values,
      });

      if (data.error) {
        setStatus({message: data.error.message});
      } else {
        setStatus(null);
        setSaved(true);

        void reloadConnection();

        if (editMode) {
          onComplete({});
        }
      }
    } catch (err: any) {
      setStatus({message: err.response.data?.error?.message || err.response.statusText});
    }

    setSubmitting(false);
  }

  return (
    <div className="py-4">
      <h3>Personal Access Token configuration</h3>
      <div className="mb-4">
        Please, fill the information related to your Personal Access Token in Tableau in order to connect to your site.
      </div>
      <small>
        <a href="/docs/connectors/tableau_setup" rel="noopener noreferrer" target="_blank">
          Guide: Setting up a Tableau Connector
        </a>
      </small>
      <Formik
        initialValues={{serverName: '', siteName: '', tokenName: '', secret: ''}}
        validate={(values: any) => {
          const errors: _.Dictionary<string> = {};
          if (_.isEmpty(_.trim(values.serverName))) {
            errors.serverName = 'required';
          }

          if (_.isEmpty(_.trim(values.siteName))) {
            errors.siteName = 'required';
          }

          if (_.isEmpty(_.trim(values.tokenName))) {
            errors.tokenName = 'required';
          }

          if (_.isEmpty(_.trim(values.secret))) {
            errors.secret = 'required';
          }

          return errors;
        }}
        onSubmit={(values: any, {setSubmitting, setStatus}: any) => {
          void submitCredentials(values, setSubmitting, setStatus);
        }}
      >
        {({isSubmitting, status}) => (
          <FormikForm className="tr-p-3 connector-setup">
            <div className="tr-flex">
              <div className="tr-w-1/2">
                <Field
                  component={TextInput}
                  label="Server Name"
                  placeholder="10ax.online.tableau.com"
                  name="serverName"
                  required
                />
              </div>
              <div className="tr-w-1/2">
                <Field component={TextInput} label="Site Name" placeholder="site-name" name="siteName" required />
              </div>
              <div className="tr-w-1/2">
                <Field component={TextInput} label="Token Name" placeholder="pat-name" name="tokenName" required />
              </div>
              <div className="tr-w-1/2">
                <Field
                  component={TextInput}
                  label="Secret"
                  placeholder="AB_cdEFGHIJ_KLM12opqRS|tUV3XYzaBCDe4fGhYJk5lmnOPQRST6Uv|7w8xy90z-1a23-4bcd-56ef-g78hi9j0k123"
                  name="secret"
                  required
                />
              </div>
            </div>

            <div className="tr-text-right tr-mt-5 tr-mb-3 tr-w-full tr-flex tr-flex-col">
              <div>
                <SubmitButton inSubmit={isSubmitting} label={editMode ? 'Submit' : 'Test Connection'} />
              </div>
              {!editMode && (
                <div>
                  <div className="text-right">
                    <SubmitButton disabled={!saved} onClick={() => onComplete({})} label={'Continue'} />
                  </div>
                </div>
              )}
            </div>
            {status && status.message && <Alert colorVariant={'danger'}>{status.message}</Alert>}
          </FormikForm>
        )}
      </Formik>

      {saved && <Alert colorVariant={'success'}>{'Connection successful'}</Alert>}

      <hr />
    </div>
  );
}
