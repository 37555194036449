import React from 'react';
import {TabsDocsT} from '../DocSection';
import DocSection from '../DocSection';
import CircleStep from '../../components/CircleStep';
import Highlight from 'src/components/highlightjs/Highlight';

function ConfigureGCP() {
  const tabs: TabsDocsT = [
    {id: 'gcp-overview', label: 'GCP Integration Overview', description: overview(), active: true},
    {id: 'gcp-configuration-steps', label: 'Configuration Steps', description: configurationSteps(), active: true},
  ];

  function overview(): React.ReactNode {
    return (
      <>
        <p>
          Our GCP integration provides deep visibility into accounts, permissions, usage patterns, and policies on your
          GCP account.{' '}
        </p>
        <ul>
          <li>
            With our <span className="strong">Free version</span>, you get a read-only view that will guide you through
            account cleanups, recommendations, and reporting. You can invite up to 5 total stakeholders to join and
            begin monitoring and planning your GCP permissions. Please note that the Free version is limited to one GCP
            account.
          </li>
          <li>
            With the <span className="strong">Teams</span> and <span className="strong">Enterprise versions</span>, you
            also are able to use Trustle’s request/approval workflows, just-in-time access, on-call access, and full
            auditing of permissions grants and removals. These features are available to any number of users and roles
            on your teams. Please note that the connector can operate in Read-only and Read-write mode, but the latter
            requires Write access to automate access grants.
          </li>
        </ul>
        <p>
          Getting started is easy. You just need an GCP service account with the configuration listed below. Trustle
          only imports IAM data, and our analysis of your account should only take a few minutes, depending on the
          number of user accounts, roles, groups, and policies in your GCP account.
        </p>
      </>
    );
  }

  function configurationSteps(): React.ReactNode {
    return (
      <>
        <div>
          <div className="tr-mb-4">
            Use the following steps to connect to <strong>GCP Identity and Access Management (IAM)</strong>. This setup
            requires an GCP user to create a new IAM service account with the included permissions. Key for this service
            account will be used by Trustle for all operations.
          </div>
          <div className="tr-mb-4 body3">GCP Setup Steps</div>

          <div className="body5 tr-mt-4 tr-pt-2">
            <CircleStep>1</CircleStep>Login to GCP console and find the IAM (Identify and Access Management) service.
          </div>

          <div className=" tr-mt-4 tr-pt-2">
            <div>
              <CircleStep>2</CircleStep>
              <span className="body5">Setup a user as admin, this user will be impersonated by a service account.</span>
            </div>
            <div>
              <div className="tr-ml-5 tr-mt-2">
                <div>
                  At organization level, set the follow roles to the admin user. (
                  <strong>
                    <i>Owner</i>
                  </strong>
                  ,{' '}
                  <strong>
                    <i>Folder Admin</i>
                  </strong>{' '}
                  and{' '}
                  <strong>
                    <i>Service Account Token Creator</i>
                  </strong>
                  )
                </div>
                <div className="image-wrap">
                  <img
                    src="/docs/connectors/gcp/connect/gpc_admin_user_roles.png"
                    className="vertical-align tr-mb-4 mt-3 shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className=" tr-mt-4 tr-pt-2">
            <div>
              <CircleStep>3</CircleStep>
              <span className="body5">
                Go to <a href="https://admin.google.com/">Google Workspace Admin Console</a>{' '}
                (https://admin.google.com/).
              </span>
              <div className="tr-ml-5 tr-mt-2">
                <div>
                  Log in in the console, and find the menu{' '}
                  <strong>
                    <i>@ Account</i>
                  </strong>{' '}
                  and select the sub menu{' '}
                  <strong>
                    <i>Admin roles</i>
                  </strong>
                  . Find the Role called{' '}
                  <strong>
                    <i>Super Admin</i>
                  </strong>{' '}
                  and click on{' '}
                  <strong>
                    <i>Assign admin</i>
                  </strong>{' '}
                  option in the right of the Super Admin row. Click on{' '}
                  <strong>
                    <i>Assign users</i>
                  </strong>{' '}
                  link and find your super admin user from GCP.
                </div>
              </div>

              <div className="image-wrap">
                <img
                  src="/docs/connectors/gcp/connect/gcp_workspace_super_admin.png"
                  className="vertical-align tr-mb-4 mt-3 shadow"
                />
              </div>
            </div>
          </div>

          <div className=" tr-mt-4 tr-pt-2">
            <div>
              <CircleStep>4</CircleStep>
              <span className="body5">Create a Service Account for Trustle integration at a project level.</span>
            </div>
            <div>
              <div className="tr-ml-5 tr-mt-2">
                <div>
                  Go back to GCP admin console and go to IAM service for a project (service accounts are not allowed at
                  organization level). Select{' '}
                  <strong>
                    <i>Servie Accounts</i>
                  </strong>{' '}
                  in the left pane and then click on{' '}
                  <strong>
                    <i> + Create Service Account</i>
                  </strong>
                </div>
                <div className="image-wrap">
                  <img
                    src="/docs/connectors/gcp/connect/gcp_create_service_account.png"
                    className="vertical-align tr-mb-4 mt-3 shadow"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="body5 tr-mt-4 tr-pt-3">
            <CircleStep>5</CircleStep> Fill the <i>Service Account</i> required information
          </div>
          <div>
            <div className="tr-ml-5 tr-mt-2">
              <div>
                Enter a Service Account name in the{' '}
                <strong>
                  <i>Service account name</i>
                </strong>{' '}
                field and enter a Service account ID in the{' '}
                <strong>
                  <i>Service account ID</i>
                </strong>{' '}
                field. For example, <i>sa-trustle-gcp-integration</i>. Click on{' '}
                <strong>
                  <i>CREATE AND CONTINUE</i>
                </strong>{' '}
                button.
              </div>
              <div className="image-wrap">
                <img
                  src="/docs/connectors/gcp/connect/gcp_add_service_account.png"
                  className="vertical-align tr-mb-4 mt-3 shadow"
                />
              </div>
            </div>
          </div>
          <div>
            <div className="tr-ml-5 tr-mt-2">
              <div>
                Skip the second step by clicking in{' '}
                <strong>
                  <i>CONTINUE</i>
                </strong>{' '}
                button. On thrid step ("Grant users access to this service account") select the super admin user that
                will be impersonated and click on{' '}
                <strong>
                  <i>DONE</i>
                </strong>{' '}
                button.
              </div>
              <div className="image-wrap">
                <img
                  src="/docs/connectors/gcp/connect/gcp-add_service_account_super_admin.png"
                  className="vertical-align tr-mb-4 mt-3 shadow"
                />
              </div>
            </div>
          </div>

          <div className="body5 tr-mt-4 tr-pt-3">
            <div>
              <CircleStep>6</CircleStep> Create a <i>KEY</i> for the <i>Service Account</i>
            </div>
          </div>
          <div>
            Click on the created{' '}
            <strong>
              <i>Service Account Email</i>
            </strong>{' '}
            or{' '}
            <strong>
              <i>Edit</i>
            </strong>{' '}
            the created{' '}
            <strong>
              <i>Service Account</i>
            </strong>{' '}
            and select{' '}
            <strong>
              <i>KEYS</i>
            </strong>{' '}
            tab, click on{' '}
            <strong>
              <i>ADD KEY</i>
            </strong>{' '}
            button and select the option{' '}
            <strong>
              <i>Create New</i>
            </strong>
            . In the next popup select the{' '}
            <strong>
              <i>JSON</i>
            </strong>{' '}
            option, it will download the{' '}
            <strong>
              <i>service account</i>
            </strong>{' '}
            credential file to be used on the integration setup (it will be used on{' '}
            <strong>
              Step <CircleStep>10</CircleStep>
            </strong>
            ).
          </div>
          <div>
            <div className="image-wrap">
              <img src="/docs/connectors/gcp/connect/gcp_add_key.png" className="vertical-align tr-mb-4 mt-3 shadow" />
            </div>
          </div>

          <div className="body5 tr-mt-4 tr-pt-3">
            <div>
              <CircleStep>7</CircleStep> Save <i>Client ID</i> and <i>Email</i> information
            </div>
          </div>
          <div>
            Go to{' '}
            <strong>
              <i>Details</i>
            </strong>{' '}
            tab, expand the{' '}
            <strong>
              <i>Advanced Settings</i>
            </strong>{' '}
            copy the{' '}
            <strong>
              <i>Client ID</i>
            </strong>{' '}
            (it will be used on{' '}
            <strong>
              Step <CircleStep>9</CircleStep>
            </strong>
            ), next click on{' '}
            <strong>
              <i>View Google Workspace Admin Console</i>
            </strong>{' '}
            button.
          </div>
          <div>
            <div className="image-wrap">
              <img
                src="/docs/connectors/gcp/connect/gcp_advanced_settings.png"
                className="vertical-align tr-mb-4 mt-3 shadow"
              />
            </div>
          </div>

          <div className="body5 tr-mt-4 tr-pt-3">
            <div>
              <CircleStep>8</CircleStep> Setup <i>API Controls</i> on <i>Google Workspace</i>
            </div>
          </div>
          <div>
            Log in on{' '}
            <strong>
              <i>Google Workspace Admin Console</i>
            </strong>
            , go to{' '}
            <strong>
              <i>Security</i>
            </strong>{' '}
            menu, expand{' '}
            <strong>
              <i>Access and data control</i>
            </strong>
            , expand{' '}
            <strong>
              <i>API controls</i>
            </strong>{' '}
            and click on{' '}
            <strong>
              <i>Manage domain wide delegation</i>
            </strong>{' '}
            button.
          </div>
          <div>
            <div className="image-wrap">
              <img
                src="/docs/connectors/gcp/connect/gcp_manage_domain_wide_delegation.png"
                className="vertical-align tr-mb-4 mt-3 shadow"
              />
            </div>
          </div>

          <div className="body5 tr-mt-4 tr-pt-3">
            <div>
              <CircleStep>9</CircleStep> Setup <i>Domain Wide Delegation</i>
            </div>
          </div>
          <div>
            On{' '}
            <strong>
              <i>domain wide delegation</i>
            </strong>{' '}
            click on{' '}
            <strong>
              <i>Add new</i>
            </strong>{' '}
            button and fill the{' '}
            <strong>
              <i>Client ID</i>
            </strong>{' '}
            field with the information copied from{' '}
            <strong>
              Step <CircleStep>7</CircleStep>
            </strong>{' '}
            and fill the{' '}
            <strong>
              <i>OAuth scopes</i>
            </strong>{' '}
            with the next list.
          </div>
          <div className="gcp-oauth-scopes">
            <div className="tr-ml-5 tr-mt-2">
              <div className="shadow tr-w-1/2">
                <div className="tr-p-2">
                  <strong>OAuth scopes:</strong>
                </div>
                <Highlight language="json" showCopy={true} className="shadow">
                  {JSON.stringify(
                    'https://www.googleapis.com/auth/admin.directory.user,https://www.googleapis.com/auth/admin.directory.customer.readonly,https://www.googleapis.com/auth/admin.directory.group.readonly,https://www.googleapis.com/auth/admin.directory.group.member,https://www.googleapis.com/auth/admin.directory.orgunit,https://www.googleapis.com/auth/admin.directory.rolemanagement,https://www.googleapis.com/auth/cloud-platform,https://www.googleapis.com/auth/admin.directory.user.readonly,https://www.googleapis.com/auth/admin.directory.orgunit.readonly,https://www.googleapis.com/auth/admin.directory.rolemanagement.readonly'
                  )}
                </Highlight>
              </div>
            </div>
          </div>
          <div>
            <div className="image-wrap">
              <img
                src="/docs/connectors/gcp/connect/gcp_domain_wide_delegation_info.png"
                className="vertical-align tr-mb-4 mt-3 shadow"
              />
            </div>
          </div>

          <div className="body5 tr-mt-4 tr-pt-3">
            <div>
              <CircleStep>10</CircleStep> Use the collected information
            </div>
          </div>
          <div>
            You are ready to use your super admin user email as super admin input and the information saved on{' '}
            <strong>
              Step <CircleStep>6</CircleStep>
            </strong>{' '}
            as service account credential.
          </div>
          <div className="image-wrap">
            <img
              src="/docs/connectors/gcp/connect/gcp_trustle_integration_credentials.png"
              className="vertical-align tr-mb-4 mt-3 shadow"
            />
          </div>
        </div>
      </>
    );
  }

  return <DocSection tabs={tabs} title="How to configure Google Cloud Platform (GCP) in Trustle" />;
}

export default ConfigureGCP;
