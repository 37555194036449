import React from 'react';
import './AuthenticationMethodSelection.scss';

type AuthenticationSelectButtonT = {
  href?: string;
  onClick?: () => void;
  label: string;
};

type AuthenticationSelectionT = {
  authenticationStatus?: React.ReactNode;
  description: string;
  install?: React.ReactNode;
  button: AuthenticationSelectButtonT;
};

type PropsT = {
  manual: AuthenticationSelectionT;
  automatic: AuthenticationSelectionT;
  method: 'automatic' | 'manual' | null;
  enableReadOnlyMode?: boolean;
};

export function AuthenticationMethodSelection(props: PropsT) {
  const {method, automatic, manual, enableReadOnlyMode = true} = props;
  const readOnlyMode = props.method === 'manual';
  const readWriteMode = props.method === 'automatic';

  function renderSelectButton(button: AuthenticationSelectButtonT) {
    return (
      <div className="body5 text-right">
        <a className="btn btn-primary" href={button.href} onClick={button.onClick} rel="noreferrer">
          {button.label}
        </a>
      </div>
    );
  }

  return (
    <div className="authentication-method-selection">
      <div className="app-selector">
        {enableReadOnlyMode && (
          <>
            <div className={method === 'manual' ? 'selector-item selected' : 'selector-item'}>
              <div className="tr-pb-4">
                <h5>Read-Only Mode</h5>
                <div>{readOnlyMode ? manual.authenticationStatus : ''}</div>
                <div className="tr-pb-4">{manual.description}</div>
                {manual.install}
              </div>
              {renderSelectButton(manual.button)}
            </div>
            <div className="body5 align-self-center">Or</div>
          </>
        )}
        <div className={method === 'automatic' ? 'selector-item selected' : 'selector-item'}>
          <div className="tr-pb-4">
            <h5>Read-Write Mode</h5>
            <div>{readWriteMode ? automatic.authenticationStatus : ''}</div>
            <div className="tr-pb-4">{automatic.description}</div>
            {automatic.install}
          </div>
          {renderSelectButton(automatic.button)}
        </div>
      </div>
    </div>
  );
}
