import React from 'react';
import {Loading, Tooltip, Icon, Table, Tab} from '@trustle/component-library';
import Notes from 'src/components/notes/Notes';
import {formatDateFromNow, formatFullDate} from 'src/lib';
import {AccountStatus, ConnectionServiceE, ConnectorsDataDataTypeE} from 'src/types';
import * as scopes from 'src/docs/gapps/gappsScopes';
import AdditionalInformationForAccount, {
  accountSecurityWarning,
  getAccountSecurityWarningMessages,
  GroupsE,
} from './AdditionalInformationForAccount';
import AccountServiceUsage from '../../ConnectorServiceUsage/AccountServiceUsage';
import _ from 'lodash';
import AccountRisk from './AccountRisk';
import AccountDetail from './AccountDetail';
import {NavePanelT, NavPanel} from 'src/components/navigation/NavPanel';
import {Account} from 'src/stores/domainObjects/Account';
import {observer} from 'mobx-react-lite';
import {Link} from 'react-router-dom';
import ShowAuthorizedAccount from 'src/components/design/tables/common/ShowAuthorizedAccount';
import {Modal} from '@trustle/component-library';
import moment from 'moment';
import AutoFormatMetadataTable from '../../../components/design/tables/common/AutoFormatMetadataTable';
import {WARNING_SERVICE_USAGE_THRESHOLD} from 'src/lib/constants';
import OrgEvents from 'src/views/Events/OrgEvents';

type RequestAccessModalProps = {
  closeModal: any;
  mode?: TabModeE;
  userAccount: Account;
  failTabs?: TabModeE[];
};
export enum TabModeE {
  GENERAL = 'general',
  HISTORY = 'history',
  NOTES = 'notes',
  CONNECTED_APPLICATIONS = 'connected_applications',
  METADATA = 'metadata',
  ACCOUNT_SECURITY = 'account_security',
  RISK_REPORT = 'risk_report',
  SERVICE_USAGE = 'service_usage',
}

const AccountDetailModal = observer((props: RequestAccessModalProps) => {
  const {closeModal, userAccount} = props;
  const resource = userAccount.forResource;
  const system = resource.rootResource;
  const service = system.connector?.service;
  const additionalInformation = userAccount.additionalInformation;
  const securityWarning = accountSecurityWarning(userAccount, additionalInformation);

  const failTabs =
    userAccount.usage && userAccount.usage < WARNING_SERVICE_USAGE_THRESHOLD ? [TabModeE.SERVICE_USAGE] : [];

  const accountSecurity = () => {
    const content: NavePanelT = [
      {
        displayName: (
          <>
            <span>Account Info</span>
          </>
        ),
        content: (
          <>
            <AdditionalInformationForAccount
              userAccount={userAccount}
              resource={resource}
              showGroup={GroupsE.ACCOUNT_INFO}
            />
          </>
        ),
        key: 'account_info',
      },
    ];

    if (resource.type === ConnectionServiceE.AWS) {
      if (_.isNil(additionalInformation)) {
        // not yet imported!
        return (
          <div className="tr-flex tr-text-center inline-flex tr-mt-5 tr-pt-3">
            <div className="tr-w-1/12 tr-mr-4">
              <img src="/russel-researching.svg" width="75px" />
            </div>
            <div className="tr-w-11/12 tr-mr-4">
              <div className="body3">
                Trustle is working on getting all of the data from your AWS. <div>This can take some time.</div>
              </div>
              <div className="text-muted body6 font-bold tr-mt-2">
                This process normally can take 5-30 minutes. If it has been over an hour, please{' '}
                <Link
                  className="text-underline"
                  to={`/resource/manage/${resource.id}/settings/edit_connection#retrieve_service_usage_data`}
                >
                  click here
                </Link>{' '}
                to check your connection settings. If the issue persists, please{' '}
                <a href="mailto:support@trustle.com" className="text-underline">
                  Contact our Support Team
                </a>
                .
              </div>
            </div>
          </div>
        );
      } else {
        <div className="tr-flex tr-mt-3">
          <div className="tr-w-1/2 body5 tr-text-left">This information is generated every 4 hours.</div>
          <div className="tr-w-1/2 tr-text-right">
            <Tooltip content={formatFullDate(moment(additionalInformation.updatedAt).toISOString())} position="bottom">
              Last update:
              <span className="body5">
                {formatDateFromNow(moment(additionalInformation.updatedAt).toISOString()).toString()}
              </span>
            </Tooltip>
          </div>
        </div>;
      }

      content.push(
        {
          displayName: (
            <div className="tr-flex tr-items-center">
              <span>MFA</span>
              {!securityWarning.mfa && (
                <div className="text-trustle-navy tr-font-normal tr-text-xs">
                  <Tooltip content={<div>Configuration is not secure enough</div>}>
                    <Icon className="tr-text-warning" type="warning" />
                  </Tooltip>
                </div>
              )}
            </div>
          ),
          content: (
            <AdditionalInformationForAccount userAccount={userAccount} resource={resource} showGroup={GroupsE.MFA} />
          ),
          key: 'mfa',
        },
        {
          displayName: (
            <>
              <span>Certificates</span>
            </>
          ),
          content: (
            <AdditionalInformationForAccount
              userAccount={userAccount}
              resource={resource}
              showGroup={GroupsE.CERTIFICATES}
            />
          ),
          key: 'certificates',
        },
        {
          displayName: (
            <div className="tr-flex tr-items-center">
              <span>Access Keys</span>
              {(securityWarning.accessKey1 || securityWarning.accessKey2) && (
                <div className="text-trustle-navy tr-font-normal tr-text-xs">
                  <Tooltip content={<div>{getAccountSecurityWarningMessages(userAccount)}</div>}>
                    <Icon className="tr-text-warning" type="warning" />
                  </Tooltip>
                </div>
              )}
            </div>
          ),
          content: (
            <AdditionalInformationForAccount
              userAccount={userAccount}
              resource={resource}
              showGroup={GroupsE.ACCESS_KEYS}
            />
          ),
          key: 'access_key',
        }
      );
    }

    return (
      <>
        <NavPanel content={content} />
      </>
    );
  };

  const showAccountSecurity = _.includes(
    [ConnectionServiceE.GAPPS, ConnectionServiceE.OKTA, ConnectionServiceE.AWS],
    service
  );

  const showRiskReportTab = _.includes([ConnectionServiceE.AWS], service);

  const warning = securityWarning.accessKey1 || securityWarning.accessKey2 || securityWarning.mfa;

  const showConnectedAppsTab =
    userAccount.status !== AccountStatus.OFFBOARDED && resource.type === ConnectionServiceE.GAPPS;

  const connectedApps = () => {
    const connectedAppsResponse = _.find(userAccount.connectorsData, {
      dataType: ConnectorsDataDataTypeE.THIRD_PARTY_CONNECTED_APPS,
    });

    const columns = [
      {
        dataField: 'userKey',
        hidden: true,
        id: true,
        text: 'Permission',
      },
      {dataField: 'displayText', text: 'Application'},
      {
        dataField: 'scopes',
        text: 'Access Scopes',
        headerStyle: {width: '70%'},
        style: {width: '70%'},
        formatter: (_cell: any, u: any) => {
          return <div>{showDescriptionScopes(u)}</div>;
        },
      },
      {
        dataField: 'updated_at',
        sort: true,
        text: 'Import Date',
        formatter: () => {
          return <p>{formatFullDate(connectedAppsResponse?.updatedAt)}</p>;
        },
      },
    ];

    if (connectedAppsResponse?.dataType === ConnectorsDataDataTypeE.THIRD_PARTY_CONNECTED_APPS) {
      return (
        <div className="py-1">
          <Table
            data={connectedAppsResponse.data.data ? _.values(connectedAppsResponse.data?.data) : []}
            columns={columns}
            tableKey={'connetedeapps'}
          />
        </div>
      );
    } else {
      return (
        <div className="tr-mt-3">
          <div className="tr-text-center">No connected apps to show</div>
        </div>
      );
    }
  };

  const showDescriptionScopes = (u: any): React.ReactElement => {
    const descriptions = _.map(u.scopes, (scope) => {
      return (
        <li>
          <Tooltip content={<code>{scope}</code>}>
            {_.get((scopes.googleScopesAsObject as any)[scope], '[0].description', scope)}
          </Tooltip>
        </li>
      );
    });
    return <ul>{descriptions}</ul>;
  };

  const warningUsage = _.includes(failTabs, TabModeE.SERVICE_USAGE);

  return (
    <Modal
      onClose={closeModal}
      height="xl"
      width={system.retrieveUsageDataEnabled ? 'xl' : 'lg'}
      title={
        <div className="flex tr-flex-row align-center">
          <div>{`${userAccount.account ?? 'Access'} Details`}</div>
          <div className="ml-2">
            <ShowAuthorizedAccount account={userAccount} iconSize="md" />
          </div>
        </div>
      }
    >
      {`${userAccount.account ? '' : 'Access has no assigned account at this point, this should be assigned.'}`}
      <Tab.Group className="tr-m-2">
        <Tab.List variant="line">
          <Tab data-testid={TabModeE.GENERAL} key={TabModeE.GENERAL}>
            {_.startCase(TabModeE.GENERAL)}
          </Tab>
          <Tab data-testid={TabModeE.HISTORY} key={TabModeE.HISTORY}>
            {_.startCase(TabModeE.HISTORY)}
          </Tab>
          <Tab data-testid={TabModeE.NOTES} key={TabModeE.NOTES}>
            {_.startCase(TabModeE.NOTES)}
          </Tab>
          {showAccountSecurity && (
            <Tab key={TabModeE.ACCOUNT_SECURITY}>
              <div className="tr-flex tr-items-center">
                {_.startCase(TabModeE.ACCOUNT_SECURITY)}
                {warning && (
                  <div className="text-trustle-navy tr-font-normal tr-text-xs">
                    <Tooltip content={<div>Configuration is not secure enough</div>}>
                      <Icon className="tr-text-warning" type="warning" forcedSize={20} />
                    </Tooltip>
                  </div>
                )}
              </div>
            </Tab>
          )}
          {showRiskReportTab && (
            <Tab data-testid={TabModeE.RISK_REPORT} key={TabModeE.RISK_REPORT}>
              {_.startCase(TabModeE.RISK_REPORT)}
            </Tab>
          )}
          {system.retrieveUsageDataEnabled && (
            <Tab data-testid={TabModeE.SERVICE_USAGE} key={TabModeE.SERVICE_USAGE}>
              <div className="tr-flex tr-items-center">
                {_.startCase(TabModeE.SERVICE_USAGE)}
                {warningUsage && (
                  <div className="text-trustle-navy tr-font-normal tr-text-xs">
                    <Tooltip content={<div>{`Usage is below ${WARNING_SERVICE_USAGE_THRESHOLD}%`}</div>}>
                      <Icon className="tr-text-warning" type="warning" forcedSize={20} />
                    </Tooltip>
                  </div>
                )}
              </div>
            </Tab>
          )}
          {_.includes([ConnectionServiceE.GITHUB, ConnectionServiceE.M365], service) && additionalInformation?.data && (
            <Tab data-testid={TabModeE.METADATA} key={TabModeE.METADATA}>
              {_.startCase(TabModeE.METADATA)}
            </Tab>
          )}
          {showConnectedAppsTab && (
            <Tab data-testid={TabModeE.CONNECTED_APPLICATIONS} key={TabModeE.CONNECTED_APPLICATIONS}>
              {_.startCase(TabModeE.CONNECTED_APPLICATIONS)}
            </Tab>
          )}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel key={TabModeE.GENERAL}>
            <AccountDetail resource={resource} userAccount={userAccount} />
          </Tab.Panel>
          <Tab.Panel key={TabModeE.HISTORY}>
            <OrgEvents entityType={'accounts'} id={userAccount.id} />
          </Tab.Panel>
          <Tab.Panel key={TabModeE.NOTES}>
            <Notes account={userAccount} />
          </Tab.Panel>
          {showAccountSecurity && <Tab.Panel key={TabModeE.ACCOUNT_SECURITY}>{accountSecurity()}</Tab.Panel>}
          {showRiskReportTab && (
            <Tab.Panel key={TabModeE.RISK_REPORT}>
              <AccountRisk resourceId={resource.id} accountId={userAccount.id} />
            </Tab.Panel>
          )}
          {system.retrieveUsageDataEnabled && (
            <Tab.Panel key={TabModeE.SERVICE_USAGE}>
              <AccountServiceUsage userAccount={userAccount} system={resource} />
            </Tab.Panel>
          )}
          {_.includes([ConnectionServiceE.GITHUB, ConnectionServiceE.M365], service) && additionalInformation?.data && (
            <Tab.Panel key={TabModeE.METADATA}>
              <AutoFormatMetadataTable data={additionalInformation?.data} />
            </Tab.Panel>
          )}
          {showConnectedAppsTab && (
            <Tab.Panel key={TabModeE.CONNECTED_APPLICATIONS}>
              {_.isNil(userAccount.connectorsData) ? <Loading /> : connectedApps()}
            </Tab.Panel>
          )}
        </Tab.Panels>
      </Tab.Group>
    </Modal>
  );
});

export default AccountDetailModal;
