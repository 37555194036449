import React from 'react';
import {DropdownItem} from '@trustle/component-library';
import {Resource} from '../../../../../../stores/domainObjects/Resource';

type Props = {
  resource: Resource;
};

export function ImportNowAction({resource}: Props) {
  const runImport = () => {
    void resource.startImport();
  };

  return (
    <DropdownItem
      key="import-system"
      onClick={runImport}
      title={
        resource.connector?.disabled
          ? `You couldn't start an import because the Connector was disabled`
          : 'Start Import'
      }
    >
      Import Now
    </DropdownItem>
  );
}
