import React, {useState, useEffect} from 'react';
import axios from 'axios';
import './GithubConnector.scss';
import {GitHubNextStepT} from './types';
import {ConnectionInfoT} from 'src/types';
import {Loading, TextInput} from '@trustle/component-library';
import GithubAppSetup from './GithubAppSetup';
import {Formik, FormikForm} from 'src/components/design/formik';
import {Field} from 'formik';

type PropsT = {
  orgName: string | null;
  systemId: string | null;
  nextStep: GitHubNextStepT;
  connectionId?: string;
};
export default function AuthenticateStep(props: PropsT) {
  const {nextStep, connectionId} = props;
  const [connectionInfo, setConnectionInfo] = useState<ConnectionInfoT>();

  async function loadConnection() {
    const connectionResponse = await axios.get(`/api/connect/${connectionId}`);
    setConnectionInfo(connectionResponse.data);
  }

  useEffect(() => {
    void loadConnection();
  }, [connectionId]);

  if (!connectionInfo) {
    return <Loading />;
  }

  function validateForm(values: {orgName: string}) {
    const errors: _.Dictionary<string> = {};
    if (!values.orgName) {
      errors.orgName = 'Organization Name is required';
    }

    return errors;
  }

  return (
    <div className="tr-flex tr-flex-col">
      <h2>Step 2 - Authenticate with GitHub</h2>

      <Formik
        key={`githubauth`}
        initialValues={{orgName: props.orgName || ''}}
        onSubmit={async () => void loadConnection()}
        validate={validateForm}
        initialErrors={validateForm({orgName: props.orgName ?? ''})}
        initialTouched={{orgName: true}}
        className="note-form"
      >
        {({values}) => {
          return (
            <FormikForm>
              <h3>Organization Name</h3>
              <Field component={TextInput} placeholder="GitHub Organization Name" name="orgName" />
              <h4>Next, choose 1 of the following authentication methods.</h4>
              <GithubAppSetup orgName={values.orgName} connectionInfo={connectionInfo} onComplete={nextStep} />
            </FormikForm>
          );
        }}
      </Formik>
    </div>
  );
}
