import React from 'react';
import ConnectorStatus from '../../../../components/StatusIndicator';
import {ImportStateT} from '../../../../types';
import {formatFullDate} from '../../../../lib';

type Props = {
  lastImport: ImportStateT;
};

export function ImportStatus({lastImport}: Props) {
  const importStatus = getImportStatus(lastImport);
  return (
    <div className="tr-flex  tr-items-center" data-testid="import-status">
      <ConnectorStatus status={importStatus.status}></ConnectorStatus>
      <span className=" body6 tooltip-text">{importStatus.text}</span>
    </div>
  );
}

function getImportStatus(lastImport: ImportStateT) {
  switch (lastImport.status) {
    case 'finished':
      return {
        text: `Last import: ${formatFullDate(lastImport.created)}`,
        status: 'success',
      };
    case 'pending':
      return {
        text: `Import in progress`,
        status: 'warning',
      };
    case 'failed':
      return {
        text: `Last import: ${formatFullDate(lastImport.created)}`,
        status: 'error',
      };
    default:
      return {
        text: `Import failed`,
        status: 'error',
      };
  }
}
