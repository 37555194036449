import _ from 'lodash';
import {observer} from 'mobx-react';
import React from 'react';
import {useRootStore} from 'src/lib/hooks';
import TaskCardItem from './TaskCardItem';
import {InfiniteScroll, Icon, NotificationDropdown} from '@trustle/component-library';
import {Task} from 'src/stores/domainObjects/Task';
import {Link} from 'react-router-dom';

const TasksFloater = observer(function GenericAccessRequestsTable() {
  const {tasksStore, currentUser} = useRootStore();

  if (_.isNil(currentUser)) {
    return <></>;
  }

  const accessRequests = tasksStore.selfTasksAndRequests;

  const tasks = _.map(accessRequests, (accessRequest: Task, index: number) => {
    return (
      <TaskCardItem
        key={index + accessRequest.createdAt + accessRequest.id}
        accessRequest={accessRequest}
        data-testid={`card-${accessRequest.id}`}
        onSelectedTaskChange={() => tasksStore.setSelectedTask(accessRequest)}
      />
    );
  });

  return (
    <>
      <NotificationDropdown
        orientation="right"
        newCount={tasksStore.assignedTotal > 99 ? 99 : tasksStore.assignedTotal}
      >
        <div className="tr-bg-white tr-text-black tr-min-w-[220px] tr-pt-1 tr-shadow-lg tr-rounded-md">
          <div className="tr-flex tr-justify-between tr-p-2">
            <Link to="/tasks">
              <div className="tr-text-blue-700 body5 tr-pr-1">See all</div>
            </Link>
            <Icon type="refresh" onClick={() => tasksStore.refresh()} />
          </div>
          <InfiniteScroll
            onEndReached={() => {
              void tasksStore.loadNextPage();
            }}
            className="!tr-max-h-[800px] tr-w-[300px] md:!tr-w-[400px] lg:!tr-w-[400px] blue-scrollbar tr-rounded-md"
          >
            {_.size(tasks) === 0
              ? [
                  <div className="tt--empty-task-view tr-my-3">
                    <div className="text-center text-navy">
                      <img src="/russel-celebrating.svg" alt="Checklist Icon" />
                      <div className="body2">Great job!</div>
                      <div className="body3">You've Completed Your Tasks.</div>
                    </div>
                  </div>,
                ]
              : tasks}
          </InfiniteScroll>
        </div>
      </NotificationDropdown>
    </>
  );
});

export default TasksFloater;
