import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faShapes,
  faCircle,
  faThLarge,
  faChevronRight,
  faChevronLeft,
  faPlus,
  faPlusSquare,
  faPlayCircle,
  faSearch,
  faAngleRight,
  faAngleLeft,
  faEllipsisV,
  faTasks,
  faUserCheck,
  faCaretRight,
  faInfoCircle,
  faBan,
  faUserShield,
  faUsersCog,
  faCross,
  faTimesCircle,
  faChevronUp,
  faCheckCircle,
  faStopwatch,
  faTimes,
  faCheck,
  faCogs,
  faChevronDown,
  faArrowUp,
  faArrowDown,
  faArchive,
  faTrash,
  faQuestionCircle,
  faCog,
  faLink,
  faEye,
  faEyeSlash,
  faDownload,
  faClipboard,
  faCircleNotch,
  faExclamationTriangle,
  faSpinner,
  faPen,
} from '@fortawesome/free-solid-svg-icons';
import {
  faPlayCircle as farPlayCircle,
  faCheckCircle as farCheckCircle,
  faPlusSquare as farPlusSquare,
  faQuestionCircle as farQuestionCircle,
} from '@fortawesome/free-regular-svg-icons';

library.add(
  faAngleLeft,
  faAngleRight,
  faBan,
  faCheck,
  faEllipsisV,
  faCheckCircle,
  faChevronRight,
  faChevronLeft,
  faInfoCircle,
  faChevronDown,
  faCircle,
  faDownload,
  faCogs,
  faCross,
  faCaretRight,
  faPlayCircle,
  faPlus,
  faPlusSquare,
  farCheckCircle,
  farPlayCircle,
  farPlusSquare,
  faSearch,
  faShapes,
  faPen,
  faStopwatch,
  faTasks,
  faThLarge,
  faTimes,
  faTimesCircle,
  faUserCheck,
  faUsersCog,
  faChevronUp,
  faTrash,
  faUserShield,
  faArrowUp,
  faArrowDown,
  faArchive,
  faClipboard,
  faExclamationTriangle,
  faSpinner,
  farQuestionCircle
);

export {
  faInfoCircle,
  faCheckCircle,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faCircle,
  faDownload,
  faTimesCircle,
  faQuestionCircle,
  faCog,
  faLink,
  faEye,
  faEyeSlash,
  faClipboard,
  faSpinner,
  faCircleNotch,
  faExclamationTriangle,
  farQuestionCircle,
};
export default FontAwesomeIcon;
