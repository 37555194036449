import React from 'react';
import './SetupProgressCircles.scss';
import {SetupStepT} from 'src/types';
import SetupCircle from './SetupCircle';

type PropsT = {
  finishedSteps: SetupStepT[];
  unfinishedSteps: SetupStepT[];
};

function SetupProgressCircles(props: PropsT) {
  const {finishedSteps, unfinishedSteps} = props;
  const totalSteps = [...finishedSteps, ...unfinishedSteps];
  return (
    <div className="setup-progress-circles">
      {[...finishedSteps, ...unfinishedSteps].map((step, i) => {
        return <SetupCircle step={step} index={i} key={`circle${step.id}${i}`} />;
      })}
      <div>
        {`${finishedSteps.length}/${totalSteps.length}`}
        <div className="body6">Completed</div>
      </div>
    </div>
  );
}

export default SetupProgressCircles;
