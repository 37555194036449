import axios from 'axios';
import {makeAutoObservable, onBecomeObserved, runInAction} from 'mobx';
import RootStore from '..';
import {TeamsResponseT} from '../TeamStore';
import {UserResponseT, TrustleEventT} from '../usersStore';
import {User} from './User';
import {SourceOfTruthE} from 'src/types';
import _ from 'lodash';

export class Team {
  id!: string;
  color!: string;
  created!: string;
  description!: string;
  memberCount!: string;
  name!: string;
  tombstone!: boolean;
  type?: string;
  memberIds: string[] = [];
  managerIds: string[] = [];
  errorDeletingUser?: any = undefined;

  users: {memberIds: string[]; managerIds: string[]} = {memberIds: [], managerIds: []};
  loadingMembers: boolean = false;
  membersLoaded: boolean = false;
  errorLoadingMembers?: any;

  // ↓ lazily loaded properties ↓
  history: TrustleEventT[] = [];
  errorLoadingHistoryData?: any;

  constructor(public rootStore: RootStore, team: TeamsResponseT) {
    const temp_team = {
      ..._.omit(team, ['members', 'managers']),
      memberIds: team.members?.split(',') ?? [],
      managerIds: team.managers?.split(',') ?? [],
    };
    Object.assign(this, temp_team);
    makeAutoObservable(this, {id: false});
    onBecomeObserved(this, 'users', async () => this.loadMembers());

    onBecomeObserved(this, 'history', async () => {
      try {
        const res = await axios.get(`/api/teams/${this.id}/history`);
        runInAction(() => (this.history = res.data));
      } catch (err) {
        runInAction(() => (this.errorLoadingHistoryData = err));
      }
    });
  }

  async loadMembers() {
    try {
      const response = await axios.get<{users: UserResponseT[]; managers: UserResponseT[]}>(`/api/teams/${this.id}`);
      const {users, managers} = response.data;
      runInAction(() => {
        /*
        TO DO: confirm if necessary:
        [...users, ...managers].forEach((user) => {
          this.rootStore.usersStore.updateUserFromServer(user);
        });
        */
        this.users.memberIds = users.map((u) => u.id);
        this.users.managerIds = managers.map((u) => u.id);
      });
    } catch (err) {
      this.errorLoadingMembers = err;
    }
  }

  async deleteUser(userId: string) {
    try {
      await axios.delete(`/api/teams/${this.id}/members/${userId}`);

      // TODO (jg): mutate store directly once teams logic is clearly defined
      await this.loadMembers();
    } catch (err) {
      this.errorDeletingUser = err;
    }
  }

  get members(): User[] {
    return this.memberIds.map((uid) => this.rootStore.usersStore.usersMap[uid]);
  }

  get managers(): User[] {
    return this.managerIds.map((uid) => this.rootStore.usersStore.usersMap[uid]).filter((user) => user);
  }

  //NR: USE WITH CARE: This methods should be used just in teams page.
  get departments(): string[] {
    return _.compact(_.uniq(_.map(this.members, 'department')));
  }

  get titles(): string[] {
    return _.compact(_.uniq(_.map(this.members, 'title')));
  }

  get roles(): string[] {
    return _.compact(_.uniq(_.map(this.members, 'remoteRole')));
  }

  get sourceOfTruth(): SourceOfTruthE {
    return _.some(this.managers, (m) => m.sourceOfTruth === SourceOfTruthE.IDP)
      ? SourceOfTruthE.IDP
      : SourceOfTruthE.TRUSTLE;
  }

  get authorityLabel() {
    if (_.defaultTo(this.sourceOfTruth, SourceOfTruthE.TRUSTLE) === SourceOfTruthE.TRUSTLE) {
      return 'Trustle';
    }
    return this.rootStore.org.orgUsersAuthorityLabel;
  }
}
