import {CustomLegend} from './CustomLegend';
import {Area, AreaChart, Label, ReferenceLine, ResponsiveContainer, Tooltip, XAxis} from 'recharts';
import React, {Fragment} from 'react';
import {CustomTooltip} from './CustomTooltip';
import {
  EXPOSURE_COLOR,
  formatDateForLegend,
  LicenseUsageChartProps,
  renderXAxisReferenceLabel,
  USAGE_COLOR,
} from '../utils';
import NoData from '../../../../../components/NoData';
import {CustomYAxis} from './CustomYAxis';

export function LicenseOverallUsageAreaChart({
  data,
  series,
  noDataMessage,
  onboardXAxisPos,
  licenseByKeyMap,
}: LicenseUsageChartProps & {onboardXAxisPos?: string}) {
  return (
    <div className="tr-flex tr-flex-col tr-gap-2">
      <CustomLegend series={series} licenseByKeyMap={licenseByKeyMap} />
      {noDataMessage ? (
        <NoData>{noDataMessage}</NoData>
      ) : (
        <ResponsiveContainer width="100%" height="100%" minWidth={500} minHeight={400}>
          <AreaChart
            data={data}
            margin={{
              top: 50,
            }}
          >
            <defs>
              <linearGradient id="colorBack" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={EXPOSURE_COLOR} stopOpacity={0.08} />
                <stop offset="95%" stopColor={EXPOSURE_COLOR} stopOpacity={0.02} />
              </linearGradient>
              <linearGradient id="colorFront" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={USAGE_COLOR} stopOpacity={0.08} />
                <stop offset="95%" stopColor={USAGE_COLOR} stopOpacity={0.02} />
              </linearGradient>
            </defs>
            {CustomYAxis()}
            <XAxis
              key="frontArea"
              dataKey="name"
              xAxisId="frontArea"
              tickFormatter={(tick) => {
                return formatDateForLegend(tick);
              }}
            />
            <XAxis key="backArea" dataKey="name" xAxisId="backArea" hide />
            <Tooltip content={<CustomTooltip licenseByKeyMap={licenseByKeyMap} />} />
            <ReferenceLine
              x={onboardXAxisPos}
              xAxisId="frontArea"
              isFront
              strokeWidth={3}
              stroke="#00519B"
              strokeDasharray="3 3"
              textAnchor={'left'}
              label={<Label position="top" content={renderXAxisReferenceLabel} />}
            />
            {renderAreas(series)}
          </AreaChart>
        </ResponsiveContainer>
      )}
    </div>
  );
}

function renderAreas(series: string[]) {
  return series.map((key) => {
    const baseDataKey = `data.${key}`;
    return (
      <Fragment key={key}>
        <Area
          dataKey={`${baseDataKey}.sum_of_granted_hours`}
          xAxisId="backArea"
          fillOpacity={1}
          fill="url(#colorBack)"
          stroke={EXPOSURE_COLOR}
        />
        <Area
          dataKey={`${baseDataKey}.sum_of_usage_hours`}
          xAxisId="frontArea"
          fillOpacity={1}
          fill="url(#colorFront)"
          stroke={USAGE_COLOR}
        />
      </Fragment>
    );
  });
}
