import React, {useState} from 'react';
import _ from 'lodash';
import {useHistory} from 'react-router-dom';
import {ConnectionServiceE, FacetIdEnum} from 'src/types';
import {useRootStore} from 'src/lib/hooks';
import {FileBasedSystemMetadata} from './GenericConnector';
import {Button, Badge, Confirmation, TileButton} from '@trustle/component-library';
import {fuseSearch, humanize, useFeatureFlags} from 'src/lib';
import buildIntegrationMetaSteps from 'src/connectors/manifest/ManifestMetadata';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';
import {Search} from 'src/components/Search/Search';

export type ResourceOptionT = {
  label: string;
  description: string;
  link?: string;
  redirect: string;
  tags?: string[];
  upperText?: string;
  bottomText?: string;
  key: ConnectionServiceE;
};

export default function ConnectResourceSelector() {
  const history = useHistory();
  const featureFlagViewer = useFeatureFlags();
  const {newResourceStore} = useRootStore();
  const metadatas = buildIntegrationMetaSteps();
  const [query, setQuery] = useState('');
  const [showUpgradeModal, setShowUpgradeModal] = useState<boolean>(false);
  const {org} = useRootStore();

  if (_.isNil(org)) {
    return null;
  }

  const manifestFlowEnabled = featureFlagViewer.isEnabled('manifest_flow');
  const m365ConnectorEnabled = featureFlagViewer.isEnabled('enable_m365_integration');
  const awsIdcConnectorEnabled = featureFlagViewer.isEnabled('enable_aws_identity_center_connector');
  const signInText = 'Sign in to';
  const connectors: _.Dictionary<ResourceOptionT> = {
    okta: {
      label: 'Okta',
      description: 'Automated Okta resource management',
      redirect: `/connect/${ConnectionServiceE.OKTA}`,
      upperText: signInText,
      bottomText: 'Okta',
      tags: ['authority'],
      key: ConnectionServiceE.OKTA,
    },
    github: {
      label: 'GitHub',
      description: 'Automated GitHub resource management',
      redirect: '/connect/github',
      upperText: signInText,
      bottomText: 'GitHub',
      key: ConnectionServiceE.GITHUB,
    },
    aws: {
      label: 'AWS',
      description: 'Automated AWS resource management',
      redirect: '/connect/aws',
      upperText: signInText,
      bottomText: 'AWS',
      key: ConnectionServiceE.AWS,
    },
    gapps: {
      label: 'Google',
      description: 'Automated Google Workspace resource management',
      redirect: '/connect/gapps',
      upperText: signInText,
      bottomText: 'Google',
      tags: ['authority'],
      key: ConnectionServiceE.GAPPS,
    },
    azure_ad: {
      label: 'Azure',
      description: 'Automated Azure AD resource management',
      redirect: `/connect/${ConnectionServiceE.AZURE_AD}`,
      upperText: signInText,
      bottomText: 'Azure',
      tags: ['authority'],
      key: ConnectionServiceE.AZURE_AD,
    },
    pagerduty: {
      label: 'PagerDuty',
      description: 'Automated PagerDuty management',
      redirect: `/connect/${ConnectionServiceE.PAGERDUTY}`,
      upperText: signInText,
      bottomText: 'PagerDuty',
      key: ConnectionServiceE.PAGERDUTY,
    },
    strongdm: {
      label: 'StrongDM',
      description: 'Automated StrongDM management',
      redirect: `/connect/${ConnectionServiceE.STRONGDM}`,
      upperText: signInText,
      bottomText: 'StrongDM',
      key: ConnectionServiceE.STRONGDM,
    },
    tableau: {
      label: 'Tableau',
      description: 'Automated Tableau management',
      redirect: `/connect/${ConnectionServiceE.TABLEAU}`,
      upperText: signInText,
      bottomText: 'Tableau',
      key: ConnectionServiceE.TABLEAU,
    },
  };
  if (m365ConnectorEnabled) {
    connectors['m365'] = {
      label: 'Microsoft 365',
      description: 'Automated Microsoft 365 resource management',
      redirect: `/connect/${ConnectionServiceE.M365}`,
      upperText: signInText,
      bottomText: 'M365',
      key: ConnectionServiceE.M365,
    };
  }

  if (manifestFlowEnabled) {
    for (const meta of metadatas) {
      if (featureFlagViewer.isEnabled('hide_manifest_unreleased')) {
        if (awsIdcConnectorEnabled && meta.key === ConnectionServiceE.AWS_IDENTITY_CENTER) {
          connectors[meta.key] = {
            label: meta.name,
            key: meta.key,
            tags: meta.tags,
            description: meta.description,
            redirect: `/connect/${meta.key}`,
            upperText: signInText,
            bottomText: humanize(meta.key),
          };
        }
      } else {
        connectors[meta.key] = {
          label: meta.name,
          key: meta.key,
          tags: meta.tags,
          description: meta.description,
          redirect: `/connect/${meta.key}`,
          upperText: signInText,
          bottomText: humanize(meta.key),
        };
      }
    }
  }

  const nonConnectedOptions = [FileBasedSystemMetadata];

  const isFreeTrial = org.facetId === FacetIdEnum.FREE_AWS;

  async function startFlow(option: ResourceOptionT) {
    history.push(option.redirect);
  }

  const renderSystemButton = (option: ResourceOptionT) => {
    return (
      <div
        className="tr-border-gray-200 tr-group tr-border-solid tr-border-2 tr-rounded-lg tr-w-[180px] tr-h-[200px] tr-m-4 tr-cursor-pointer hover:tr-shadow-md hover:tr-bg-gray-50"
        onClick={() => {
          if (
            (isFreeTrial && _.lowerCase(option.label) !== 'aws') ||
            (isFreeTrial && _.size(newResourceStore.systems) > 0)
          ) {
            setShowUpgradeModal(true);
            return;
          }

          void startFlow(option);
        }}
      >
        <TileButton
          data-testid={_.lowerCase(option.key)}
          className="tr-mx-2 tr-my-2"
          size="lg"
          name={option.key as SystemIconsTypesEnum}
        />
        <div className="tr-mx-2 tr-font-bold tr-text-lg">{option.label}</div>
        <div className=" group-hover:tr-hidden tr-m-2 ">
          {option.tags?.map((tag) => {
            return (
              <div className="tr-items-center tr-flex">
                <Badge variant="light" size="md" className="tr-text-wrap" pill={false}>
                  {_.capitalize(tag)}
                </Badge>
              </div>
            );
          })}
        </div>
        <div className=" tr-hidden group-hover:tr-block tr-m-2 ">
          <Button>Sign-In to System</Button>
        </div>
      </div>
    );
  };

  const renderResourceOptions = (optionsMap: ResourceOptionT[]) => {
    return _.map(optionsMap, (option: ResourceOptionT) => {
      return <div>{renderSystemButton(option)}</div>;
    });
  };

  const filteredConnectors = fuseSearch(
    query,
    ['product', 'description', 'number', 'total', 'status'],
    _.values(connectors)
  );

  return (
    <div className="connect-resource-selector">
      <div className="resource-section">
        <div className="tr-mb-4">
          <h1 className="tr-m-0">Select a system type and Sign-in to that system to get started.</h1>
          <Search
            className="tr-min-w-[200px] md:tr-min-w-[500px] sm:tr-min-[200px]"
            placeholder="Search by a specific system"
            onChange={(query) => {
              setQuery(query);
            }}
          />
        </div>

        <div className="tr-flex tr-flex-col">
          <div className="flex-wrap tr-flex  ">
            {renderResourceOptions(filteredConnectors)}
            {renderResourceOptions(nonConnectedOptions)}
          </div>
        </div>

        <div className="tr-flex-wrap tr-mt-5 tr-flex tr-justify-center tr-items-center">
          {isFreeTrial && (
            <div className="tr-flex tr-justify-center tr-items-center">
              <Button variant="ternary" onClick={() => history.push('/admin/billing/status')}>
                {'Upgrade here to add more systems'}
              </Button>
            </div>
          )}
        </div>
      </div>
      {showUpgradeModal && (
        <Confirmation
          onConfirm={() => {
            history.push('/admin/billing/plan');
          }}
          onClose={() => {
            setShowUpgradeModal(false);
          }}
          title="Upgrade to add another connector"
          confirmButtonLabel="Upgrade"
        >
          {'To add another system, please upgrade to Teams version. You will get a free 14-day trial'}
        </Confirmation>
      )}
    </div>
  );
}
