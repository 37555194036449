import {ConnectionServiceE} from 'src/types';
import {FormFieldTypes} from '../common/types';
import {ConnectorSchema} from './default';

const cloudflare: ConnectorSchema = {
  service: ConnectionServiceE.CLOUDFLARE,
  name: 'Cloudflare',
  description: 'Connect to Cloudflare',
  serviceId: 'cloudflare',
  auth: {
    type: 'accesKeys',
    fields: [
      {
        placeholder: 'Enter the account identifier',
        label: 'Account Identifier',
        type: FormFieldTypes.TEXT,
        name: 'accountIdentifier',
        id: 'accountIdentifier',
        required: true,
      },
      {
        label: 'Enter the API Token',
        type: FormFieldTypes.PASSWORD,
        name: 'token',
        id: 'token',
        placeholder: 'Enter API token here',
        required: true,
      },
      {
        label: 'Enter the API auth email',
        type: FormFieldTypes.TEXT,
        name: 'authEmail',
        id: 'authEmail',
        placeholder: 'Enter API auth email here',
        required: true,
      },
      {
        label: 'Enter the API auth key',
        type: FormFieldTypes.PASSWORD,
        name: 'authKey',
        id: 'authKey',
        placeholder: 'Enter API auth key here',
        required: true,
      },
    ],
  },
};

export default cloudflare;
