import React from 'react';
import {observer} from 'mobx-react';
import {formatUserName} from 'src/lib';
import {Link} from 'react-router-dom';

import _ from 'lodash';
import {OnboardingModeE, SourceOfTruthE} from 'src/types';

import {useRootStore} from 'src/lib/hooks';
import {UserEventType} from './UserHistory';

type PropsT = {
  event: string;
  dict: _.Dictionary<string>;
};

const HistoryEventDetails = observer(function StatusCmp(props: PropsT) {
  const {usersStore, accountsStore, newResourceStore: resourceStore, currentUser, org} = useRootStore();
  const {event, dict} = props;

  const renderAttributes = (dict: _.Dictionary<string>) => {
    const {
      email,
      firstname,
      lastname,
      type,
      allowLogin,
      status,
      managerUid,
      onboardingMode,
      isOrgOwner,
      sourceOfTruth,
      department,
      remoteRole,
      title,
    } = dict;
    const manager = usersStore.usersMap[managerUid];
    const {rid, auid} = dict;

    const account = accountsStore.accountsMap[auid];
    const system = account?.forResource ?? _.find(resourceStore.resources, {id: rid});

    const to = currentUser.isOrgOwner
      ? `/resource/manage/${system?.id}/overview/managed`
      : `/access/available/${system?.type}/${system?.id}`;

    return (
      <>
        <div>
          {onboardingMode === OnboardingModeE.MANUAL ? (
            renderKeyValue('Onboarding Mode:', 'Manual creation')
          ) : onboardingMode === OnboardingModeE.CSV ? (
            renderKeyValue('Onboarding Mode:', 'Uploaded through CSV')
          ) : onboardingMode === OnboardingModeE.CONNECTOR ? (
            renderKeyValue(
              'Onboarding Mode: Imported from',
              _.isNil(system) ? 'Remote System' : {to, title: system?.name}
            )
          ) : (
            <></>
          )}
        </div>
        {renderKeyValue('Email:', email)}
        {renderKeyValue('First Name:', _.startCase(firstname))}
        {renderKeyValue('Last Name:', _.startCase(lastname))}
        {renderKeyValue('Department:', _.startCase(department))}
        {renderKeyValue('Org Role:', _.startCase(remoteRole))}
        {renderKeyValue('Title:', _.startCase(title))}
        {renderKeyValue('Type:', _.startCase(type))}
        {renderKeyValue('Allow Login:', allowLogin)}
        {renderKeyValue('Status:', _.startCase(status))}
        {renderKeyValue('Trustle Role:', _.isNil(isOrgOwner) ? '' : isOrgOwner ? 'Owner' : 'User')}
        {renderKeyValue(
          'Authority:',
          !sourceOfTruth ? '' : sourceOfTruth === SourceOfTruthE.TRUSTLE ? 'Trustle' : org.orgUsersAuthorityLabel
        )}
        {!_.isUndefined(managerUid) &&
          renderKeyValue(
            'Manager:',
            _.defaultTo(managerUid, 'None') === 'None'
              ? 'None'
              : {to: `/users/${manager?.id}`, title: formatUserName(manager)}
          )}
      </>
    );
  };

  const renderKeyValue = (name: string, value: string | boolean | {to: string; title: string}) => {
    if (_.isEmpty(value) && !_.isBoolean(value)) {
      return <></>;
    }

    return _.isObject(value) ? (
      <div>
        {`${name} `}
        <Link to={value.to} target="_blank">
          {value.title}
        </Link>
      </div>
    ) : (
      <div>
        {`${name} `}
        <span className="font-weight-bold">{!_.isBoolean(value) ? value : value ? 'Yes' : 'No'}</span>
      </div>
    );
  };

  const {rid, auid} = dict;

  const userAcc = accountsStore.accountsMap[auid] ?? {id: dict['uaid'], account: dict['uaDisplayname']};
  const system = userAcc?.forResource ??
    _.find(resourceStore.resources, {id: rid}) ?? {id: dict['rid'], name: dict['rDisplayname']};

  const to = currentUser.isOrgOwner
    ? `/resource/manage/${system?.id}/accounts`
    : `/access/available/${system?.type}/${system?.id}`;

  switch (event) {
    case UserEventType.TRUSTLE_USER_ONBOARDED:
      return (
        <div className="">
          <div>{renderAttributes(dict)}</div>
        </div>
      );
    case UserEventType.USER_INVITED:
      return (
        <div className="">
          {!_.isNil(dict['uDisplayname']) ? (
            <>
              {`User: `}
              <Link to={`/users/${dict.uid}`}> {dict['uDisplayname']}</Link>
            </>
          ) : (
            ''
          )}
        </div>
      );

    case UserEventType.TRUSTLE_USER_OFFBOARDED:
      return <div className="">{renderAttributes(dict)}</div>;

    case UserEventType.TRUSTLE_USER_MAPPED:
    case UserEventType.TRUSTLE_USER_UNMAPPED: {
      return (
        <div className="tr-flex">
          <div>
            {renderKeyValue('System:', _.isNil(system?.name) ? 'Remote Resource' : {to, title: system.name})}
            <div>
              {'Account: '}
              <span className="strong">{_.isNil(userAcc) ? 'Unknown' : userAcc?.account ?? 'Unknown'}</span>
            </div>
          </div>
        </div>
      );
    }

    case UserEventType.TRUSTLE_USER_MANAGER_UPD:
    case UserEventType.TRUSTLE_PROFILE_USER_MANAGER_UPD:
      return (
        <div className="tr-flex ">
          <div>{renderAttributes({managerUid: dict.managerUid ?? 'None'})}</div>
        </div>
      );

    case UserEventType.TRUSTLE_USER_UPDATED:
      return (
        <div className="tr-flex ">
          <div>{renderAttributes(dict)}</div>
        </div>
      );
    default:
      return <></>;
  }
});

export default HistoryEventDetails;
