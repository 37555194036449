import React from 'react';
import {Link} from 'react-router-dom';
import {Resource} from 'src/stores/domainObjects/Resource';
import {observer} from 'mobx-react-lite';

type PropsT = {
  system: Resource;
  type: 'account' | 'resource';
};

const EmptySection = observer(function EmptySection(props: PropsT) {
  const {system, type} = props;
  const importing = system.retrieveUsageDataEnabled && system.isServiceUsageStatusImporting;

  const notEnabled = () => {
    return (
      <div className="text-center mt-4 pt-4 tr-w-full">
        <img src="/russel-thinking.svg" className="m-2" style={{width: '190px'}} alt="" />
        <div className="body5 mt-3">{`Hmm, we couldn’t seem to find any service usage data for this ${type}.`}</div>
        <div className="body5 text-muted mt-4" style={{width: '250px', display: 'inline-grid'}}>
          Looks like this is disabled.
        </div>
        <div className="body5">
          {' '}
          <Link
            className="text-underline strong"
            to={`/resource/manage/${system.id}/settings/edit_connection#retrieve_service_usage_data`}
          >
            go to settings
          </Link>{' '}
          to enable service usage for this {type}.
        </div>
      </div>
    );
  };

  const inProgress = function inProgress() {
    return (
      <div className="text-center mt-4 pt-4 tr-w-full">
        <img src="/russel-researching.svg" className="m-2" width="100px" />
        <div className="body5 mt-3">{`Trustle is working on getting all of the data from your AWS ${type}.`}</div>
        <div className="body5">This can take some time.</div>
        <div className="body5 text-muted mt-4">
          This process normally can take 10-60 minutes. If it has been over an hour, please{' '}
          <Link
            className="text-underline strong"
            to={`/resource/manage/${system.id}/settings/edit_connection#retrieve_service_usage_data`}
          >
            click here
          </Link>{' '}
          to check the progress in the connection settings page. If the issue persists, please{' '}
          <a href="mailto:support@trustle.com" className="text-underline">
            Contact our Support Team
          </a>
          .{' '}
        </div>
      </div>
    );
  };

  const noUsageData = () => {
    return (
      <div className="text-center mt-4 pt-4 tr-w-full">
        <img src="/russel-thinking.svg" className="m-2" style={{width: '190px'}} alt="" />
        <div className="body5 mt-3">{`Hmm, we couldn’t seem to find any usage data yet.`}</div>
      </div>
    );
  };

  if (!system.retrieveUsageDataEnabled) {
    return notEnabled();
  }

  if (importing) {
    return inProgress();
  }
  return noUsageData();
});
export default EmptySection;
