import React from 'react';
import {Link} from 'react-router-dom';

function SetupNav() {
  return (
    <Link style={{position: 'absolute', left: '30px', top: '0'}} to="/">
      <img src="/logo/trustle-blue.svg" className="align-top d-inline-block" alt="trustle" />
    </Link>
  );
}

export default SetupNav;
