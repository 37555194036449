import React from 'react';
import {Button, Icon, Modal} from '@trustle/component-library';
import {ItemIconTypesEnum} from '@trustle/component-library/dist/types';

// Set default props
ComplexModal.defaultProps = {
  confirmButtonTitle: 'Confirm',
};

export default function ComplexModal(props: {
  id?: string;
  icon: ItemIconTypesEnum;
  title: string;
  content: React.ReactNode;
  confirmBtnClassName?: string;
  confirmButtonTitle?: string | null;
  secondaryButtonTitle?: string | null;
  cancelButtonTitle?: string | null;
  onConfirm: (e: any) => any | Promise<void>;
  onSecondaryAction?: (e: any) => void;
  onClose: () => void;
}) {
  const {
    id,
    icon,
    title,
    content,
    onConfirm,
    onSecondaryAction,
    onClose,
    confirmBtnClassName,
    confirmButtonTitle,
    secondaryButtonTitle,
    cancelButtonTitle,
  } = props;

  return (
    <Modal
      id={id}
      className="complex-confimation-modal"
      visible={true}
      onClose={onClose}
      title={<div className="tr-flex tr-items-center tr-gap-2">{title}</div>}
    >
      <div className="tr-flex tr-flex-col">
        <div className="tr-flex tr-align-items-center tr-mt-4 tr-mx-auto tr-max-w-[80px]">
          <Icon type={icon} forcedSize={60} />
        </div>
        <div className="tr-m-3">{content}</div>
      </div>
      <div className={'tr-flex tr-justify-between tr-mb-0 tr-mx-2 tr-border-0'}>
        {cancelButtonTitle && (
          <Button variant="secondary" onClick={onClose}>
            {cancelButtonTitle}
          </Button>
        )}
        <div className="tr-flex tr-flex-row">
          {secondaryButtonTitle && (
            <Button variant="ternary" onClick={onSecondaryAction}>
              {secondaryButtonTitle}
            </Button>
          )}
          {confirmButtonTitle && (
            <Button variant="primary" className={confirmBtnClassName} onClick={onConfirm}>
              {confirmButtonTitle}
            </Button>
          )}
        </div>
      </div>
    </Modal>
  );
}
