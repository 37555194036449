import {ConnectorSchema} from './default';
import {FormFieldTypes} from '../common/types';
import {ConnectionServiceE} from 'src/types';

const aws_identity_center: ConnectorSchema = {
  service: ConnectionServiceE.AWS_IDENTITY_CENTER,
  name: 'AWS IAM Identity Center',
  description: 'Bring your AWS IAM IDC Users and Groups and sync the data with your Trustle organization.',
  serviceId: 'aws-identity-center-endpoint',
  auth: {
    type: 'accessKeys',
    title: 'API Key configuration',
    description: 'Trustle uses Access Keys to import and provision access to your AWS IAM Identity Center Account.',
    docs: {
      link: `/docs/connectors/aws_identity_center/configure_aws_identity_center`,
      linkText: `Guide: Setting up an AWS IAM Identity Center Connector`,
    },
    fields: [
      {
        label: 'Enter the access key ID for the Trustle Connector',
        type: FormFieldTypes.PASSWORD,
        name: 'accessKeyId',
        id: 'accessKeyId',
        placeholder: 'Enter access key here',
        required: true,
      },
      {
        placeholder: 'Enter the secret access key',
        label: 'Secret key',
        type: FormFieldTypes.PASSWORD,
        name: 'secretAccessKey',
        id: 'secretAccessKey',
        required: true,
      },
    ],
  },
  templates: {
    resources: {
      tree: {
        Resource: {},
        Permission: {},
        AccessRecord: {},
      },
    },
    accounts: {
      tables: {},
    },
  },
};

export default aws_identity_center;
