import React, {useEffect, useState} from 'react';
import FontAwesomeIcon from 'src/components/FontAwesomeIcon';
import {farQuestionCircle} from 'src/components/FontAwesomeIcon';
import './HeaderServiceUsage.scss';
import _ from 'lodash';
import moment from 'moment';
import {formatDateFromNow, formatFullDate} from 'src/lib';
import {Resource} from 'src/stores/domainObjects/Resource';
import {WARNING_SERVICE_USAGE_THRESHOLD} from '../../lib/constants';
import {Tooltip, Icon} from '@trustle/component-library';

type HeaderServiceUsageProps = {
  accessedInTrackingPeriodNum: number;
  notAccessedInTrackingPeriodNum: number;
  system: Resource;
  onClick: (selected: string[]) => void;
  overrideUsage?: number | null;
};
export enum AccessTypeE {
  NOT_ACCESSED = 'Not Accessed',
  ACCESSED = 'Accessed',
}

function HeaderServiceUsage(props: HeaderServiceUsageProps) {
  const {accessedInTrackingPeriodNum, notAccessedInTrackingPeriodNum, system, overrideUsage, onClick} = props;
  const allowedServices = accessedInTrackingPeriodNum + notAccessedInTrackingPeriodNum;
  const percentage = !_.isNil(overrideUsage)
    ? overrideUsage
    : allowedServices !== 0
    ? (accessedInTrackingPeriodNum * 100) / allowedServices
    : undefined;
  const lastImport = system.connector?.lastImportServiceUsage;
  const lastImportCreated = lastImport ? lastImport.created : '';
  const warningPercentage = !_.isNil(percentage) && percentage < WARNING_SERVICE_USAGE_THRESHOLD;
  const [selectedBox, setSelectedBox] = useState<string[]>([AccessTypeE.ACCESSED]);

  useEffect(() => {
    onClick(selectedBox);
  }, [selectedBox]);

  return (
    <>
      <div className="tr-flex tr-flex-col service-usage-header tr-pb-1 tr-ml-2 tr-mr-1">
        <div className="tr-flex tr-my-4">
          <div className="tr-w-2/3 body6 text-muted font-bold tr-px-0">
            This information is generated approx every 4 hours. For a period of 400 days.
            {/* Access Advisor reports activity for services and EC2, IAM, Lambda, and S3 management actions. Recent service activity usually appears within 4 hours. Service activity is reported for the past 400 days. */}
          </div>
          <div className="tr-w-1/3 body6 tr-mt-1 text-muted tr-text-right font-bold tr-my-4">
            {lastImportCreated && (
              <Tooltip
                content={formatFullDate(moment(lastImportCreated).toISOString())}
                position="bottom"
                data-testid="importStatusText"
              >
                Last updated: {formatDateFromNow(moment(lastImportCreated).toISOString()).toString()}
              </Tooltip>
            )}
          </div>
        </div>
        <div className="tr-flex ">
          <div className="tr-flex tr-flex-col tr-w-3/4 body3">
            <div className="tr-flex tr-mb-2 bb">
              Allowed Services
              <span data-testid="numAllowedServices" className="ml-1">
                ({allowedServices})
              </span>
            </div>

            <div className="tr-py-4 tr-flex">
              <div
                className="tr-flex tr-flex-col tr-items-center"
                onClick={() => {
                  setSelectedBox((prevSelectedBox) => {
                    const newStatus = _.xor(prevSelectedBox, [AccessTypeE.ACCESSED]);
                    return _.size(newStatus) > 0 ? newStatus : prevSelectedBox;
                  });
                }}
              >
                <div
                  className={`box tr-text-center tr-justify-center tr-items-center tr-flex tr-mr-4 ${
                    _.includes(selectedBox, AccessTypeE.ACCESSED) ? 'active' : 'bordered'
                  }`}
                  data-testid="accessedInTrackingPeriodNum"
                >
                  {accessedInTrackingPeriodNum}
                </div>
                <div
                  className={`body5 tr-ml-2 tr-mt-1 ${
                    _.includes(selectedBox, AccessTypeE.ACCESSED) ? '' : 'text-muted'
                  }`}
                >
                  Accessed
                </div>
              </div>
              <div
                className="tr-flex tr-flex-col tr-items-center"
                onClick={() => {
                  setSelectedBox((prevSelectedBox) => {
                    const newStatus = _.xor(prevSelectedBox, [AccessTypeE.NOT_ACCESSED]);
                    return _.size(newStatus) > 0 ? newStatus : prevSelectedBox;
                  });
                }}
              >
                <div
                  className={`box tr-text-center tr-justify-center tr-flex tr-items-center ${
                    _.includes(selectedBox, AccessTypeE.NOT_ACCESSED) ? 'active' : 'bordered'
                  }`}
                  data-testid="notAccessedInTrackingPeriodNum"
                >
                  {notAccessedInTrackingPeriodNum}
                </div>
                <div
                  className={`body5 ml-2 mt-1 ${_.includes(selectedBox, AccessTypeE.NOT_ACCESSED) ? '' : 'text-muted'}`}
                >
                  Not Accessed
                </div>
              </div>
            </div>
          </div>
          {!_.isNil(percentage) && (
            <div className="tr-flex tr-flex-col tr-w-2/4 tr-ml-2 body3 tr-text-left">
              <div className="tr-flex tr-mb-2  bb">
                <span className="mr-2">Service Usage %</span>{' '}
                {warningPercentage && (
                  <div className="text-trustle-navy tr-font-normal tr-text-xs">
                    <Tooltip content={<div>{`Usage is below ${WARNING_SERVICE_USAGE_THRESHOLD}%`}</div>}>
                      <Icon className="tr-text-warning" type="warning" />
                    </Tooltip>
                  </div>
                )}
              </div>
              <div className="tr-mt-4">
                <div
                  className={`box tr-pt-3 tr-mb-1 ${warningPercentage ? 'warning-text' : 'N/A'}`}
                  data-testid="percentage"
                >
                  {!_.isNil(percentage) ? `${_.round(percentage)}%` : 'N/A'}
                </div>
              </div>
              <div>
                <div className="body6 tr-mb-4 tr-px-0">
                  <Tooltip
                    content={
                      <div style={{width: '400px'}}>
                        <div className="body6 text-left strong">Here are some things you can try...</div>
                        <ul>
                          <li>Consolidate accessed policies in aws and remove unused ones</li>
                          <li>Remove member from unused polices and groups </li>
                        </ul>
                      </div>
                    }
                  >
                    <FontAwesomeIcon icon={farQuestionCircle} color="gray" /> How can I change this?
                  </Tooltip>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default HeaderServiceUsage;
