import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {SubmitButton} from 'src/components/design';
import {Formik, FormikForm, TrustleUserSelector} from 'src/components/design/formik';
import {useRootStore} from 'src/lib/hooks';
import {OnCallList} from 'src/stores/domainObjects/OnCallList';
import _ from 'lodash';
import {Button, Modal, Select, TextInput} from '@trustle/component-library';
import {Field} from 'formik';

type OnCallModalPropsT = {
  closeModal: () => void;
  defaultValues?: OnCallList;
  pageablePermissionOptions: {value: string; label: string}[];
};

export type OnCallFormT = {
  name: string;
  users: {value: string; label: string; id: string}[];
  permissionId?: {value: string; label: string};
};

const OnCallModal = observer(function OncallModal(props: OnCallModalPropsT) {
  const {closeModal, defaultValues, pageablePermissionOptions} = props;
  const [namingStep, setNamingStep] = useState<boolean>(false);
  const {org} = useRootStore();

  if (_.isNil(org)) {
    return <></>;
  }

  const id = defaultValues?.id;
  const isEditing = !!id;

  const title = (() => {
    if (isEditing) {
      return 'Edit On-Call User List';
    }

    if (namingStep) {
      return `What would you call this list?`;
    }

    return `Add On-Call Users List`;
  })();

  const permissionOption = _.find(pageablePermissionOptions, {value: defaultValues?.permissionId});

  function renderOncallGroupSetup() {
    return (
      <div className="tr-flex tr-flex-col">
        <div className="tr-flex align-items-center">
          <div>
            <img src="/logo/trustle-blue.svg" width="70px" alt="PagerDuty" />
          </div>
          <div className="flex-grow-1 tr-pb-4">
            <TrustleUserSelector name="users" multiple={true} label="Trustle User(s)" required={true} />
          </div>
        </div>
        <div className="tr-flex align-items-center">
          <div>
            <img src="/connectors/pagerduty-logo.svg" className="img-fluid" height="70px" alt="PagerDuty" />
          </div>
          <div className="flex-grow-1">
            <Field
              component={Select}
              name="permissionId"
              label="PagerDuty Entity"
              options={pageablePermissionOptions}
              multi={false}
              value={permissionOption}
              className="tr-px-4"
            />
          </div>
        </div>
      </div>
    );
  }

  const submit = (form: OnCallFormT) => {
    if (isEditing && defaultValues) {
      defaultValues.update(form);
    } else {
      void org.createOnCall({
        name: form.name,
        users: _.map(form.users, 'id'),
        permissionId: form.permissionId?.value,
      });
    }
    closeModal();
  };

  return (
    <Modal visible={true} width="md" onClose={closeModal} title={title}>
      {!namingStep && (
        <div className="tr-py-2 tr-pr-3 tr-mb-4">
          <div className="tr-w-full">{'Select targeted group of users for this On-Call list'}</div>
        </div>
      )}
      <Formik
        initialValues={{
          name: defaultValues?.name ?? '',
          users: defaultValues?.userData ?? [],
          permissionId: permissionOption,
        }}
        validate={(form: OnCallFormT) => {
          const errors: _.Dictionary<string> = {};
          if (form.name.length > 200) {
            errors.name = 'Names can be at most 200 characters.';
          }

          const foundOncall = _.find(org.onCallLists, {name: form.name});
          const hasNameConflict = !_.isNil(foundOncall) && id !== foundOncall.id;

          if (hasNameConflict) {
            errors.name = 'This name is already used by another on-call list.';
          }
          return errors;
        }}
        onSubmit={submit}
      >
        {({isSubmitting, values}) => (
          <FormikForm>
            <div className="tr-flex tr-flex-col">
              <div className="tr-flex tr-w-full tr-mb-8">
                {!namingStep && renderOncallGroupSetup()}
                {namingStep && (
                  <Field
                    component={TextInput}
                    label="Name"
                    placeholder="On-Call list name"
                    id="name"
                    name="name"
                    required={true}
                  />
                )}
              </div>
              <div className="tr-w-full tr-flex tr-flex-col tr-pr-5">
                {namingStep ? (
                  <div className="tr-flex tr-justify-between tr-items-center">
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setNamingStep(false);
                      }}
                    >
                      {'< Go back to Select Users'}
                    </Button>

                    <SubmitButton
                      name="resourceSave"
                      inSubmit={isSubmitting}
                      onClick={() => {
                        submit(values);
                      }}
                      label={isEditing ? 'Update List' : `Create List`}
                      disabled={values.name ? false : true}
                    />
                  </div>
                ) : (
                  <div className="tr-flex tr-justify-end tr-items-center">
                    <Button
                      variant="primary"
                      onClick={() => {
                        setNamingStep(true);
                      }}
                    >
                      {isEditing ? 'Change name >' : `Next, Name this List >`}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </FormikForm>
        )}
      </Formik>
    </Modal>
  );
});

export default OnCallModal;
