import React, {useState} from 'react';
import {ResourceT} from 'src/types';
import {AddPermissionModal} from 'src/views/Permissions/Modals/AddPermissionModal';
import ChildResource from '../Create/ChildResource';
import {useToasts} from 'react-toast-notifications';
import ConfirmDeleteResourceModal from '../Edit/ConfirmDeleteResourceModal';
import EditResourceModal from '../Edit/EditResourceModal';
import {Resource} from 'src/stores/domainObjects/Resource';
import ResourceDetailsModal, {TabModeE} from './ResourceDetailsModal';
import {Icon, Modal} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
type PropsT = {
  resource: Resource;
  refreshResourceList: () => void;
  showIconDetails?: boolean;
};

enum ResourceModalE {
  ADD_RESOURCE = 'add_resource',
  EDIT_RESOURCE = 'edit_resource',
  VIEW_RESOURCE = 'view_resource',
  ADD_PERMISSION = 'add_permission',
  DELETE_RESOURCE = 'delete_resource',
}

const ResourceDropdown = observer(function ResourceDropdown(props: PropsT) {
  const {addToast} = useToasts();
  const {resource, refreshResourceList, showIconDetails = true} = props;
  const isOwner = resource?.userIsOwner;

  const isConnectedSystem = !!resource.rootResource.connectionId;
  const [modalAction, setModalAction] = useState<ResourceModalE>();
  const [resourceDetailTab, setResourceDetailTab] = useState<TabModeE>(TabModeE.GENERAL);
  const closeModal = (refreshData: boolean = false) => {
    setModalAction(undefined);
    if (refreshData) {
      refreshResourceList();
    }
    setResourceDetailTab(TabModeE.GENERAL);
  };

  function getActions() {
    const isSystemPermissionTab = resource.name === resource.rootResource.name;

    if (isConnectedSystem) {
      return (
        <>
          {!_.isEmpty(resource?.resourcesConnectorData) && (
            <Icon
              key={'metadata'}
              title="Metadata"
              className="tr-px-1 tr-cursor-pointer"
              data-testid="metadata-icon"
              onClick={(e: any) => {
                e.stopPropagation();
                setResourceDetailTab(TabModeE.METADATA);
                setModalAction(ResourceModalE.VIEW_RESOURCE);
              }}
              type="metadata"
              size="sm"
              useDefaultColor={false}
            />
          )}
          {showIconDetails && (
            <Icon
              key={'detail'}
              title="Details"
              data-testid="details-icon"
              onClick={(e: any) => {
                e.stopPropagation();
                setModalAction(ResourceModalE.VIEW_RESOURCE);
              }}
              type="details"
              className="tr-px-1 tr-cursor-pointer"
              useDefaultColor={false}
            />
          )}
          {isOwner && !isSystemPermissionTab && (
            <Icon
              key={'edit'}
              title="Edit"
              data-testid={`edit-icon-${resource.name}`}
              onClick={(e: any) => {
                e.stopPropagation();
                setModalAction(ResourceModalE.EDIT_RESOURCE);
              }}
              type="edit"
              className="tr-px-1 tr-cursor-pointer"
              useDefaultColor={false}
            />
          )}
        </>
      );
    }

    return (
      <>
        {isOwner && (
          <>
            <Icon
              key={'addpermission'}
              size="sm"
              title="Add Permission"
              data-testid="addpermissions-icon"
              className="tr-px-1 tr-cursor-pointer"
              onClick={(e: any) => {
                e.stopPropagation();
                setModalAction(ResourceModalE.ADD_PERMISSION);
              }}
              type="addPermission"
              useDefaultColor={false}
            />
            <Icon
              key={'newresource'}
              size="sm"
              className="tr-px-1 tr-cursor-pointer"
              title="Add Resource"
              data-testid="addresorce-icon"
              onClick={(e: any) => {
                e.stopPropagation();
                setModalAction(ResourceModalE.ADD_RESOURCE);
              }}
              type="addResource"
              useDefaultColor={false}
            />
          </>
        )}
        {showIconDetails && (
          <Icon
            key={'detail'}
            size="sm"
            title="Details"
            data-testid="details-icon"
            onClick={(e: any) => {
              e.stopPropagation();
              setModalAction(ResourceModalE.VIEW_RESOURCE);
            }}
            type="details"
            className="tr-px-1 tr-cursor-pointer"
            useDefaultColor={false}
          />
        )}
        {isOwner && !isSystemPermissionTab && (
          <>
            <Icon
              key={'edit'}
              title="Edit"
              size="sm"
              data-testid={`edit-icon-${resource.name}`}
              onClick={(e: any) => {
                e.stopPropagation();
                setModalAction(ResourceModalE.EDIT_RESOURCE);
              }}
              type="edit"
              className="tr-px-1 tr-cursor-pointer"
              useDefaultColor={false}
            />
            <Icon
              key={'delete'}
              title="Delete"
              className="tr-px-1 tr-cursor-pointer"
              data-testid="delete-icon"
              onClick={(e: any) => {
                e.stopPropagation();
                setModalAction(ResourceModalE.DELETE_RESOURCE);
              }}
              type="remove"
              size="sm"
              useDefaultColor={false}
            />
          </>
        )}
      </>
    );
  }

  const resourceModalActionContent = ((): React.ReactNode => {
    if (!modalAction) {
      return null;
    }

    switch (modalAction) {
      case ResourceModalE.DELETE_RESOURCE: {
        return (
          <ConfirmDeleteResourceModal
            resource={resource}
            closeModal={(resourceWasDeleted: boolean) => {
              if (!resourceWasDeleted) {
                closeModal();
                return;
              }
              addToast(`${resource.name} has been deleted`, {
                appearance: 'success',
                autoDismiss: true,
              });
              refreshResourceList();
            }}
          />
        );
      }
      case ResourceModalE.ADD_RESOURCE: {
        return (
          <Modal
            onClose={() => {
              closeModal();
            }}
            visible={true}
            width="xl"
            title={'Create Resource'}
          >
            <ChildResource
              parent={resource}
              sensitivitySetting={resource.sensitivity}
              afterSubmit={() => {
                closeModal(true);
              }}
              close={closeModal}
            />
          </Modal>
        );
      }
      case ResourceModalE.EDIT_RESOURCE: {
        return (
          <EditResourceModal
            resource={resource}
            isCreating={false}
            visible={ResourceModalE.EDIT_RESOURCE === modalAction}
            closeModal={closeModal}
            refresh={() => {
              closeModal();
              refreshResourceList();
            }}
          />
        );
      }
      case ResourceModalE.VIEW_RESOURCE: {
        return <ResourceDetailsModal resource={resource} closeModal={closeModal} defaultTab={resourceDetailTab} />;
      }
      case ResourceModalE.ADD_PERMISSION: {
        return (
          <AddPermissionModal
            parent={resource as ResourceT}
            afterSubmit={() => {
              closeModal(true);
            }}
            closeModal={closeModal}
          />
        );
      }
    }
  })();

  const resourceActions = getActions();

  if (_.isEmpty(resourceActions)) {
    return <></>;
  }

  return (
    <div
      className="tr-flex tr-items-center tr-justify-center actions"
      onClick={(e: any) => {
        e.stopPropagation();
      }}
    >
      {resourceActions}
      {resourceModalActionContent}
    </div>
  );
});

export default ResourceDropdown;
