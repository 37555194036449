import {observer} from 'mobx-react';
import {ToastProvider, useToasts} from 'react-toast-notifications';
import {useRootStore} from 'src/lib/hooks';
import React, {useEffect} from 'react';

const MobxToastReceiver = observer(function ToastReceiver(props: {children: React.ReactElement}) {
  const {toast} = useRootStore();
  const {addToast} = useToasts();
  useEffect(() => {
    const nextToastMessage = toast.getNextMessage();
    if (nextToastMessage) {
      addToast(nextToastMessage.message, nextToastMessage.opts);
    }
  }, [toast.pendingMessages]);

  return <>{props.children}</>;
});

export default observer(function AppToastProvider(props: {children: React.ReactElement}) {
  return (
    <ToastProvider>
      <MobxToastReceiver>{props.children}</MobxToastReceiver>
    </ToastProvider>
  );
});
