import {Button, Icon, TextInput} from '@trustle/component-library';
import {ItemIconTypesEnum} from '@trustle/component-library/dist/types';
import React, {useMemo} from 'react';
import {useDebounce} from '../../../../../../lib';
import {UserQueryFiltersAndPagination, useUserFilters} from '../../UserFiltersContext';
import {FilterConfig, useFiltersConfigMap} from '../../hooks/useUserFiltersConfigMap';
import {UserFilterKey} from '../../types';

export function HeaderSearch() {
  const {filters, clearFilters, setFilter} = useUserFilters();
  const filtersConfigMap = useFiltersConfigMap();

  const handleDebouncedSearch = useDebounce(
    (value: string) => {
      setFilter('nameOrEmail', value);
    },
    1000,
    [filters]
  );

  const {title, icon} = getTitleAndIcon(filters, filtersConfigMap);

  const showBackToGroupBy = useMemo(() => {
    const {groupBy} = filters;
    return groupBy && groupBy !== 'user' && filters[groupBy];
  }, [filters]);

  return (
    <div className="tr-flex tr-items-center tr-gap-3 tr-bg-[#F8F9FC] tr-py-3 tr-px-2.5">
      {showBackToGroupBy && (
        <Button
          onClick={() => {
            clearFilters();
          }}
          variant="ternary"
        >
          <span className="tr-flex tr-items-center tr-gap-1">
            <Icon type="chevronLeft" size="sm" />
            Go back
          </span>
        </Button>
      )}
      <Icon type={icon} size="md" />
      <h2 className="tr-mb-0">{title}</h2>
      {(filters.groupBy === 'user' || showBackToGroupBy) && (
        <TextInput
          defaultValue={filters.nameOrEmail}
          placeholder="Search for users"
          className="tr-pb-0 tr-max-w-[600px] tr-h-[40px]"
          onInput={(e: any) => {
            handleDebouncedSearch(e.target.value);
          }}
        />
      )}
    </div>
  );
}

function getTitleAndIcon(
  filters: UserQueryFiltersAndPagination,
  filtersConfigMap: Record<UserFilterKey, FilterConfig>
): {title: string; icon: ItemIconTypesEnum} {
  const {groupBy, team, department, role, title} = filters;

  const getLabel = (key: UserFilterKey) => {
    return filtersConfigMap[key].options.find((o) => o.value === filters[key])?.label;
  };

  switch (groupBy) {
    case 'team':
      return {
        title: team ? `Team: ${getLabel('team')}` : 'Teams',
        icon: 'team',
      };
    case 'department':
      return {
        title: department ? `Department: ${getLabel('department')}` : 'Departments',
        icon: 'project',
      };
    case 'role':
      return {
        title: role ? `Role: ${getLabel('role')}` : 'Roles',
        icon: 'roles',
      };
    case 'title':
      return {
        title: title ? `Title: ${getLabel('title')}` : 'Titles',
        icon: 'project',
      };
    default:
      return {
        title: 'Users',
        icon: 'groupPeople',
      };
  }
}
