import axios from 'axios';
import React, {useEffect, useState} from 'react';

type OrgT = {name: string; hostname: string; url: string};

const ShowOrgsForm = () => {
  const [orgs, setOrgs] = useState<OrgT[]>();

  useEffect(() => {
    void axios.get<{orgs: OrgT[]}>('/api/orgs/show_user_orgs').then((res) => setOrgs(res.data?.orgs ?? []));
  }, []);

  return (
    <>
      <h2 className="tr-text-3xl tr-border-solid tr-border-0 tr-border-b-2">Your Organizations</h2>
      <div className="mt-4 tr-max-h-96 tr-overflow-auto tr-pr-2">
        {orgs?.length === 0 ? (
          <p>
            You do not have any organizations. <a href="/signup">Create an organization</a>, or contact your
            organization's administrator.
          </p>
        ) : (
          orgs?.map((org) => (
            <div key={org.hostname} className="my-4">
              <span className="tr-mr-4 tr-font-bold">{org.name}</span>
              <a href={org.url}>{org.url}</a>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default ShowOrgsForm;
