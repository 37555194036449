import {Button, Icon, Select} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import React, {useState} from 'react';
import {useRootStore} from 'src/lib/hooks';
import {Account} from 'src/stores/domainObjects/Account';
import {User} from 'src/stores/domainObjects/User';
import {AccountAccessType, UserType} from 'src/types';

type MapUserPropsT = {
  account: Account;
  accountType?: AccountAccessType;
  onSubmit: (user: User | null, input: string) => void;
};

const MapUser = observer((props: MapUserPropsT) => {
  const {account, onSubmit, accountType} = props;
  const {newResourceStore: resourceStore} = useRootStore();
  const {usersStore} = useRootStore();
  const [input, setInput] = useState<string>();
  const resource = resourceStore.resourceMap[account.rid];

  const opts =
    _.sortBy(
      _.filter(usersStore.activeAndPendingUsers, (user) => {
        return accountType === 'service' ? user.type === UserType.system : true;
      }),
      'email'
    ) ?? [];

  const [user, setUser] = useState<User | null>([...account.perfectUserMatch, ...account.partialUserMatch][0]);
  return (
    <div className="d-flex align-items-center">
      <Select
        className="my-2"
        defaultValue={user}
        inputValue={input}
        options={opts}
        id={`account-map-${account.account}`}
        placeholder="Trustle user's name"
        isDisabled={!resource.canEdit}
        onInputChange={(input, {action}) => {
          if (action === 'input-change') {
            setUser(null);
            setInput(input);
          } else if (action === 'set-value') {
            setInput(input);
          }
        }}
        onChange={(user) => setUser(user)}
        menuPortalTarget={document.querySelector('body')}
        styles={{
          control: (baseStyles) => ({
            ...baseStyles,
            fontSize: '12px',
            paddingLeft: '4px',
          }),
        }}
      />
      <Button
        style={{height: '2.4rem'}}
        variant="primary"
        id={`account-map-${account.account}-button`}
        key="complete"
        disabled={!resource.canEdit || (_.isEmpty(input) && _.isNil(user))}
        onClick={() => onSubmit(user, input ?? '')}
        className={'tr-p-2 tr-ml-1'}
      >
        {_.isEmpty(input) ? (
          <Icon type="linked" title="Link User" forcedSize={20} />
        ) : (
          <Icon type="linkNewUser" title="Link New User" forcedSize={20} />
        )}
      </Button>
    </div>
  );
});

export default MapUser;
