import _ from 'lodash';
import React from 'react';
import './Import.scss';
import CSVReader from 'react-csv-reader';

type HeaderTransform = 'lowercase' | 'uppercase' | 'camelcase' | 'snakecase' | 'none';

type PropsT = {
  title?: string;
  handleError: (err: any) => void;
  handleUpload: (data: any, filename: string) => void;
  headerTransform?: HeaderTransform;
};

function Import({title, handleUpload, handleError, headerTransform = 'snakecase'}: PropsT) {
  const papaparseOptions = {
    header: true,
    dynamicTyping: true,
    skipEmptyLines: true,
    transformHeader: (header: string) => {
      header = _.trim(header);

      switch (headerTransform) {
        case 'lowercase':
          return _.toLower(_.replace(header, / /g, ''));
        case 'uppercase':
          return _.toUpper(_.replace(header, / /g, ''));
        case 'camelcase':
          return _.camelCase(header);
        case 'snakecase':
          return _.snakeCase(header);
        case 'none':
        default:
          return header;
      }
    },
  };

  return (
    <div className="custom-import position-relative text-center">
      <span className="import-msg">{title ?? 'Select a file to upload'}</span>
      {/* TODO: we should implement our own Uploading File component inside our component-library */}
      <CSVReader
        cssClass="csv-input text-center tr-p-2 tr-bg-blue-light tr-font-bold"
        label="Browse"
        onFileLoaded={handleUpload}
        onError={handleError}
        parserOptions={papaparseOptions}
        inputStyle={{display: 'none'}}
        inputId="userMergeUpload"
      />
    </div>
  );
}

export default Import;
