import React from 'react';
type PropsT = {
  title: string;
  description?: string;
  actionElement?: React.ReactElement;
};

function ReportLine(props: PropsT) {
  const {title, description} = props;
  return (
    <div className="tr-bg-gray-background tr-rounded-xl tr-min-h-[9rem] tr-mt-4 mb-4 tr-flex tr-justify-between tr-items-center">
      <div className="tr-flex tr-items-center tr-ml-8">
        <img src="/reports/reports_symbol_large_grey.svg" width="39px" alt="file icon" />
        <div className="tr-ml-8">
          <div className="body3">{title}</div>
          <div>{description}</div>
        </div>
      </div>
      <div className="tr-mr-8">{props.actionElement}</div>
    </div>
  );
}

export default ReportLine;
