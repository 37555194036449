import React from 'react';
import {Formik, Field, Form} from 'formik';
import {Button, TextInput} from '@trustle/component-library';
import {object, string} from 'yup';
import {RouteComponentProps} from 'react-router-dom';

type LoginFormActionsProps = {
  handleSendEmail: (email: string) => void;
};

type LoginFormProps = LoginFormActionsProps & RouteComponentProps;

const LoginForm = ({handleSendEmail}: LoginFormProps) => (
  <Formik
    initialValues={{email: ''}}
    validationSchema={object().shape({
      email: string().email().required('Email is required'),
    })}
    onSubmit={async ({email}) => {
      handleSendEmail(email);
    }}
  >
    {({isValid, isSubmitting}) => (
      <Form className="tr-my-8">
        <Field component={TextInput} label="Email Address" name="email" placeholder="user@example.com" />
        <div className="tr-flex tr-justify-between tr-items-center">
          <Button type="submit" disabled={!isValid || isSubmitting}>
            Find Your Organization
          </Button>
        </div>
      </Form>
    )}
  </Formik>
);

export default LoginForm;
