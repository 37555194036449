import _ from 'lodash';
import React from 'react';
import {observer} from 'mobx-react';
import {Resource} from 'src/stores/domainObjects/Resource';
import {useRootStore} from 'src/lib/hooks';
import {Loading} from '@trustle/component-library';
import UsersCardSelector from './Edit/UsersCardSelector';
type PropsT = {
  resource: Resource;
};

const ResourceOwnersAndProvisioners = observer(function ResourceOwnersAndProvisioners(props: PropsT) {
  const rootStore = useRootStore();
  const {org} = rootStore;

  const {resource} = props;

  if (_.isNil(org) || org?.defaultSensitivityId === undefined) {
    return <Loading />;
  }
  return (
    <div className="tr-grid tr-grid-cols-2 tr-gap-x-8">
      <div className="owners-selection tr-border-solid tr-border-[0px] tr-border-r-[1px] tr-border-r-gray-300">
        <UsersCardSelector label={'owners'} name="owners" resource={resource} />
      </div>
      <div>
        <UsersCardSelector label={'provisioners'} name="provisioners" resource={resource} />
      </div>
    </div>
  );
});

export default ResourceOwnersAndProvisioners;
