import {Card} from '@trustle/component-library';
import React from 'react';
import {PropsWithChildren} from 'react';

type Props = PropsWithChildren<{
  loading?: boolean;
}>;
export function DashboardCard({children, loading = false}: Props) {
  return (
    <Card
      className={`tr-bg-white tr-drop-shadow-lg tr-p-0 tr-border-solid tr-border-[2px] tr-border-gray-100 ${
        loading ? 'tr-animate-pulse' : ''
      }`}
      variant="secondary"
    >
      {children}
    </Card>
  );
}
