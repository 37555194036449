import _ from 'lodash';
import {observer} from 'mobx-react';
import React from 'react';
import {formatFullDate, getTimeRange} from 'src/lib';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import {ProfileScreenContext} from 'src/types';
import {useRootStore} from 'src/lib/hooks';
import {Modal} from '@trustle/component-library';
import OrgEvents from 'src/views/Events/OrgEvents';

type HistoryModalPropsT = {
  closeModal: any;
  accessRecord: AccessRecord;
};

const HistoryModal = observer(function HistoryModal(props: HistoryModalPropsT) {
  const {closeModal, accessRecord} = props;

  const {usersStore} = useRootStore();

  const headerContent = _.map(
    [
      ['Resource', accessRecord.forResource?.name],
      ['Permission', accessRecord.forPermission.label],
      ['User', accessRecord.forUser?.email],
      ['Account', accessRecord.forAccount?.account],
      ['Type', accessRecord.forAccount?.accountType],
      ['Requested', accessRecord.created ? formatFullDate(accessRecord.created) : ''],
    ],
    (rowDef: [string, string]) => {
      return (
        <div className="tr-flex tr-w-full lg:tr-w-1/2" key={rowDef[0]}>
          <div className="tr-flex">
            <div className="tr-w-4/12 tr-font-bold">{rowDef[0]}:</div>
            <div className="tr-w-8/12">{rowDef[1]}</div>
          </div>
        </div>
      );
    }
  );

  const canSeeAccessHistory = usersStore.currentUser?.canAccess(
    ProfileScreenContext.ACCESS_HISTORY,
    accessRecord.forUser?.id
  );

  return (
    <Modal
      onClose={closeModal}
      width="lg"
      visible={true}
      title={canSeeAccessHistory ? 'Access History' : 'Access Details'}
    >
      <div className="container history-modal">
        <div className="tr-py-2">{headerContent}</div>
        <div>
          <div className="tr-w-full">
            <div className="tr-flex">
              <div className="tr-w-1/3 lg:tr-w-1/4 tr-font-bold">Access Expires:</div>
              <div className="tr-w-2/3">
                {accessRecord.accessExpiresTs && (
                  <span>
                    {getTimeRange(accessRecord.accessExpiresTs)} {`(${formatFullDate(accessRecord.accessExpiresTs)})`}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="tr-w-full">
            <div>
              <div className="tr-w-1/3 lg:tr-w-1/4 tr-font-bold">Approval Expires:</div>
              <div className="tr-w-2/3">
                {accessRecord.expiresTs && (
                  <span>
                    {getTimeRange(accessRecord.expiresTs)} {`(${formatFullDate(accessRecord.expiresTs)})`}
                  </span>
                )}
              </div>
            </div>
          </div>
        </div>
        {canSeeAccessHistory && <OrgEvents entityType={'access'} id={accessRecord.id} />}
      </div>
    </Modal>
  );
});

export {HistoryModal};
