import React from 'react';
import {observer} from 'mobx-react';
import {Accordion} from '@trustle/component-library';
import {Resource} from 'src/stores/domainObjects/Resource';
import {User} from 'src/stores/domainObjects/User';
import {getResourceTemplateComponent} from 'src/connectors/manifests';
import {ViewEntities} from 'src/connectors/common/types';
import ResourceChildren from './ResourceChildren';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import {SortState} from './shared';
import _ from 'lodash';

export type ResourceBranchPropsT = {
  template: ViewEntities;
  resource: Resource;
  hideRoot?: boolean;
  isRootNode?: boolean;
  isTreeView?: boolean;
  accessMode?: boolean;
  prefilteredAccesses?: AccessRecord[];
  opened?: string[];
  setFilters?: (value: any) => void;
  filters?: any;
  user?: User;
  hidePermissionsWithoutAccesses?: boolean;
  showTabs?: boolean;
  sort?: SortState;
  visibleItems?: {resources: string[]; permissions: string[]; accesses: string[]};
};

const ResourceBranch = observer((props: ResourceBranchPropsT) => {
  const {resource, isTreeView = false, isRootNode = false, hideRoot = false, opened = [], filters, setFilters} = props;

  const ResourceCmp = getResourceTemplateComponent(resource);

  const isBranchOpened = opened.includes(resource.id);

  const summary = (
    <div
      key={resource.id}
      className="tr-flex tr-flex-row grow resource-branch"
      data-testid={`test-resource-${resource.id}`}
      onClick={() => {
        if (setFilters) {
          const opened = [...(filters?.Opened ? filters.Opened.split(',') : [])];
          if (opened.includes(resource.id)) {
            _.remove(opened, (item) => item === resource.id);
          } else {
            opened.push(resource.id);
          }
          setFilters({...filters, Opened: opened});
        }
      }}
    >
      <div className="tr-basis-2/5 tr-flex tr-flex-row align-items-center justify-content-start heading-section">
        <ResourceCmp.header {...props} />
      </div>
      <div className="tr-basis-3/5 tr-flex tr-flex-row tr-items-center">
        <div className="tr-flex tr-flex-row tr-items-center tr-justify-content-start tr-grow badge-section">
          <ResourceCmp.attributes {...props} />
          <div className="grow"></div>
          <ResourceCmp.actions {...props} />
        </div>
      </div>
    </div>
  );

  return (
    <div key={resource.id}>
      {hideRoot ? (
        <ResourceChildren {...props} /> //If we dont do it like this then we need to rethink the tree.
      ) : (
        <Accordion
          variant="primary"
          className={resource.userIsOwner && resource.calculatedHidden.value ? 'tr-opacity-60' : ''}
          isRootNode={isRootNode}
          isTreeView={isTreeView}
          defaultState={isBranchOpened}
          content={summary}
          key={resource.id}
        >
          {() => {
            return <ResourceCmp.children {...props} />;
          }}
        </Accordion>
      )}
    </div>
  );
});

export default ResourceBranch;
