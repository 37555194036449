import React, {useState} from 'react';
import FocusedTaskTabs from '../FocusedTaskTabs';
import {TaskActionsEnum} from 'src/types';
import {observer} from 'mobx-react';
import _ from 'lodash';
import TakeOverTaskModal from '../TakeOverTaskModal';
import {Formik} from 'formik';
import {Alert, Button, Confirmation, Icon} from '@trustle/component-library';
import {Task} from 'src/stores/domainObjects/Task';
import {NoActionAlerts} from '../utils';
import {UserStatus} from 'src/stores/usersStore';
import StatusIndicator from 'src/components/StatusIndicator';
import moment from 'moment';
import {useFeatureFlags} from 'src/lib';
import {actionButtonType, taskTypeSchemaEnum} from '../components/helpers';

type TaskDisplayPropsT = {
  accessRequest: Task;
  isAllowedToAct: boolean;
};

export const TaskDisplay = observer(function TaskDisplayCmp(props: TaskDisplayPropsT) {
  const {accessRequest, isAllowedToAct} = props;
  const [showHistory, setShowHistory] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState<boolean>(false);
  const [showTakeoverConfirmDialog, setShowTakeoverConfirmDialog] = useState<boolean>(false);
  const [selectedAction, setSelectedAction] = useState<TaskActionsEnum | undefined>(undefined);
  const ticketConfiguration = taskTypeSchemaEnum[accessRequest.calculatedType];
  const featureFlagViewer = useFeatureFlags();
  const enabledTaskExpiration = featureFlagViewer.isEnabled('enable_expiration_days_for_tasks');

  const {isConnectionBroken, isDisabled} = accessRequest.system;

  const connectionEnabledForSystem = !isDisabled;

  if (!ticketConfiguration) {
    return <></>;
  }

  if (!ticketConfiguration.getForm) {
    return <></>;
  }

  const formConfig = ticketConfiguration.getForm({accessRequest, isAllowedToAct});
  return (
    <div>
      <div className="tr-flex tr-flex-col tr-gap-2 tr-mb-2">
        <ticketConfiguration.heading accessRequest={accessRequest} />
        {isConnectionBroken && (
          <Alert
            title="Broken Connection"
            icon={<Icon type="unlinkedUser" className="tr-text-error" title={'Broken connection'} />}
            colorVariant="danger"
          >
            <span>
              The connection to this system is broken. Please fix the connection before attempting to complete the task.
            </span>
          </Alert>
        )}

        {enabledTaskExpiration && moment().diff(accessRequest.expiresIn, 'days') < 2 && (
          <div>
            <Alert title="This task is about to expire" colorVariant="danger" className="tr-text-xs">
              This access request will be implicitly denied if no action is taken.
            </Alert>
          </div>
        )}
      </div>
      {ticketConfiguration.text(accessRequest, setShowHistory)}
      <>
        {accessRequest.targetUser?.status === UserStatus.OFFBOARDED && (
          <span className="tr-text-xs">
            <StatusIndicator
              className="mx-1"
              status={accessRequest.targetUser.statusIcon.icon}
              messages={[_.startCase(accessRequest.targetUser.statusIcon.text)]}
            />
            <span className="tr-mr-2">
              {accessRequest.targetUser.email} is {_.startCase(accessRequest.targetUser.statusIcon.text)}
            </span>
          </span>
        )}
      </>
      <ticketConfiguration.details accessRequest={accessRequest} />
      <Formik
        key={accessRequest.id}
        validateOnMount={true}
        initialValues={formConfig.initialValues}
        validationSchema={formConfig.validationSchema}
        onSubmit={async (values: any) => {
          if (!selectedAction) {
            return;
          }

          await accessRequest.submitAction(selectedAction, {...values});
          setSelectedAction(undefined);
        }}
      >
        {({isValid, values, submitForm}) => {
          return (
            <>
              {formConfig.fields}
              {showConfirmDialog && (
                <Confirmation
                  onConfirm={async () => {
                    const shouldApprove =
                      selectedAction === TaskActionsEnum.APPROVE ||
                      selectedAction === TaskActionsEnum.COMPLETE ||
                      selectedAction === TaskActionsEnum.EXTEND_ACCESS ||
                      selectedAction === TaskActionsEnum.REAPPROVE ||
                      selectedAction === TaskActionsEnum.PROVISION;
                    if (isValid && shouldApprove) {
                      void submitForm();
                      setShowConfirmDialog(false);
                    } else if (selectedAction) {
                      await accessRequest.submitAction(selectedAction, values);
                    }
                  }}
                  onClose={() => {
                    setShowConfirmDialog(false);
                    setSelectedAction(undefined);
                  }}
                  title={'Are you sure?'}
                >
                  <ticketConfiguration.confirmation
                    accessRequest={accessRequest}
                    selectedAction={selectedAction}
                    values={values}
                  />
                </Confirmation>
              )}
              {showTakeoverConfirmDialog && selectedAction && (
                <TakeOverTaskModal
                  title={_.capitalize(selectedAction.replace('_', ' '))}
                  comment={values.comment}
                  text={ticketConfiguration.text(accessRequest)}
                  accessRequest={accessRequest}
                  setVisibility={(value: boolean) => {
                    setShowTakeoverConfirmDialog(value);
                    setSelectedAction(undefined);
                  }}
                  actionFn={async () => {
                    if (isValid && selectedAction) {
                      void submitForm();
                      setShowTakeoverConfirmDialog(false);
                    } else if (selectedAction) {
                      await accessRequest.submitAction(selectedAction, {comment: values.comment});
                    }
                  }}
                />
              )}
              <NoActionAlerts connectionEnabledForSystem={connectionEnabledForSystem} isAllowedToAct={isAllowedToAct} />

              <div key="buttons" className="tr-flex tr-justify-between actions-bar">
                <Button variant="ternary" selected={showHistory} onClick={() => setShowHistory(!showHistory)}>
                  Show History
                </Button>
                <div>
                  {_.map(ticketConfiguration.getActions(accessRequest), (action: actionButtonType) => {
                    const disabled = action.type === 'submit' ? !isValid || action.disabled : false;
                    return (
                      <Button
                        variant={action.type === 'submit' ? 'secondary' : 'ternary'}
                        disabled={disabled}
                        key={`button-${action.action}`}
                        selected={selectedAction === action.action}
                        onClick={() => {
                          if (isAllowedToAct) {
                            setShowConfirmDialog(true);
                          } else {
                            setShowTakeoverConfirmDialog(true);
                          }
                          setSelectedAction(action.action);
                        }}
                      >
                        {action.label}
                      </Button>
                    );
                  })}
                </div>
              </div>
            </>
          );
        }}
      </Formik>
      {showHistory && <FocusedTaskTabs accessRequest={accessRequest} />}
    </div>
  );
});
