import React from 'react';
import {Tab, ListGroup} from 'src/components/design';
import './NavPanel.scss';
import _ from 'lodash';
export type NavePanelT = {
  displayName: string | React.ReactElement;
  key: string;
  content: string | React.ReactElement;
}[];

type PropsT = {
  content: NavePanelT;
  defaultActiveKey?: string;
  navColumns?: number;
  contentColumns?: number;
};

export function NavPanel(props: PropsT) {
  const {content, defaultActiveKey = _.head(content)?.key, navColumns = 3, contentColumns = 9} = props;
  return (
    <Tab.Container defaultActiveKey={defaultActiveKey}>
      <div className="tr-flex tr-mt-2 nav-panel">
        <div className={`tr-pl-3 tr-pr-0 tr-w-${navColumns}/12`}>
          <ListGroup>
            {_.map(content, (c) => {
              return (
                <ListGroup.Item className="pointer" key={c.key} eventKey={c.key}>
                  <div>{c.displayName}</div>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </div>
        <div className={`tr-pl-3 tr-w-${contentColumns}/12`}>
          <Tab.Content>
            {_.map(content, (c) => {
              return (
                <Tab.Pane eventKey={c.key} key={c.key}>
                  {c.content}
                </Tab.Pane>
              );
            })}
          </Tab.Content>
        </div>
      </div>
    </Tab.Container>
  );
}
