import {
  AutoProvisionDropdown,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  LockedDropdown,
  SortToggle,
  StatusFilterDropdown,
} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react';
import React, {useEffect, useState} from 'react';
import {Search} from 'src/components/Search/Search';
import {formatUserName} from 'src/lib';
import {TreeTabPanelT} from './TreeTabPanel';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Permission} from 'src/stores/domainObjects/Permission';
import {ACCESS_STATUSES} from '../../../lib/constants';
import {FILTER_OPTIONS, FilterOptions, SORT_OPTIONS, SortOption, SortState} from './shared';

type FiltersTreeTabPanelT = {
  filters: any;
  setFilters: (value: any) => void;
  setQuery: (value: string) => void;
  sortBy?: SortState;
  setSortBy: (value: any) => void;
  allNestedResources: Resource[];
  allPermissions: Permission[];
  showInactiveSystemFilter?: boolean;
  showTabs?: boolean;
} & TreeTabPanelT;

const DEFAULT_SORT_CONTROLS: {label: SortOption; value: string[]; order: 'asc' | 'desc'}[] = [
  {label: 'Name', value: ['label'], order: 'asc'},
  {label: 'Sensitivity', value: ['sensitivity.level'], order: 'asc'},
  {label: 'Risk', value: ['riskScore'], order: 'asc'},
  {label: 'Usage', value: ['usageData.usage'], order: 'asc'},
  {label: 'User', value: ['forUser.email'], order: 'asc'},
];

const SystemFilters = observer((props: FiltersTreeTabPanelT) => {
  const {
    system,
    canEditResource,
    hideFilters = false,
    sortBy,
    setSortBy,
    filters,
    setFilters,
    setQuery,
    allNestedResources,
    allPermissions,
    showInactiveSystemFilter = false,
    showTabs = false,
    allowExpand = true,
    availableFilters = FILTER_OPTIONS,
    availableSorts = SORT_OPTIONS,
  } = props;

  const DEFAULT_OPTIONS: FilterOptions = {
    Permission: _.map(allPermissions, 'label'),
    Account: _.map(system?.accounts, (acc) => {
      return acc.account;
    }),
    User: _.map(system?.accounts, (acc) => {
      return acc.forUser ? formatUserName(acc.forUser) : 'Unlinked';
    }),
    Status: ACCESS_STATUSES,
    Provisioning: ['Managed', 'Unmanaged'],
    Lock: ['Locked', 'Unlocked'],
    Tasks: ['Pending', 'None'],
  };

  const [filterOptions, setFilterOptions] = useState(DEFAULT_OPTIONS);

  useEffect(() => {
    const allowedFilterOptions: FilterOptions = {};
    availableFilters.forEach((key) => {
      if (DEFAULT_OPTIONS[key]) {
        allowedFilterOptions[key] = DEFAULT_OPTIONS[key];
      }
    });
    setFilterOptions(allowedFilterOptions);
  }, [allNestedResources, system?.accounts]);

  const sortOptions = DEFAULT_SORT_CONTROLS.filter((option) => availableSorts.includes(option.label)).map(
    (item: any) => {
      return (
        <DropdownItem key={item.value} onClick={() => setSortBy(item)}>
          {item.label}
        </DropdownItem>
      );
    }
  );

  return (
    <div data-testid={`filter-tree-view-${system?.id}`}>
      {!hideFilters && (
        <div>
          <div className="flex tr-flex-row align-items-center">
            <Search
              className="w-50"
              placeholder="Search for Resource, Permission, Access"
              filterOptions={filterOptions as Record<string, (string | undefined)[]>}
              initialFilters={{...props.fixedFilters}}
              onChange={(query, filters) => {
                setQuery(query);
                setFilters(filters);
              }}
            />

            <Dropdown
              title={!sortBy ? 'Sort' : `Sorting by ${sortBy?.label}`}
              dropdownMenu={
                <DropdownMenu>
                  <>{sortOptions}</>
                  <DropdownItem
                    onClick={() => {
                      setSortBy(undefined);
                    }}
                  >
                    Default Sorting
                  </DropdownItem>
                </DropdownMenu>
              }
            ></Dropdown>

            {sortBy && (
              <SortToggle
                onClick={() => {
                  const sortByLocal = {...sortBy};
                  sortByLocal.order = sortBy.order === 'desc' ? 'asc' : 'desc';
                  setSortBy({...sortByLocal});
                }}
                size="sm"
                direction={sortBy.order === 'desc' ? 'asc' : 'desc'}
                title={sortBy.order ? `Ascending` : `Descending`}
              />
            )}
            {canEditResource && (
              <>
                <AutoProvisionDropdown
                  size="sm"
                  isFilter={true}
                  selectedStatus={_.isUndefined(filters.Lock) ? undefined : filters.Provisioning === 'Managed'}
                  onSelected={(state?: boolean) => {
                    if (_.isUndefined(state)) {
                      const tempFilters = {...filters};
                      delete tempFilters.Provisioning;
                      setFilters(tempFilters);
                    } else {
                      setFilters({...filters, Provisioning: !state ? 'Unmanaged' : 'Managed'});
                    }
                  }}
                  className="tr-relative"
                />

                <LockedDropdown
                  size="sm"
                  selectedStatus={_.isUndefined(filters.Lock) ? undefined : filters.Lock === 'Locked'}
                  isFilter={true}
                  onSelected={(state?: boolean) => {
                    if (_.isUndefined(state)) {
                      const tempFilters = {...filters};
                      delete tempFilters.Lock;
                      setFilters(tempFilters);
                    } else {
                      setFilters({...filters, Lock: !state ? 'Unlocked' : 'Locked'});
                    }
                  }}
                />
              </>
            )}
            {!props.fixedFilters?.Status && (
              <StatusFilterDropdown
                key={`status-${filters.Status}`}
                size="sm"
                selectedStatus={filters.Status}
                isFilter={true}
                onStateSelected={(state?: string) => {
                  if (_.isUndefined(state)) {
                    const tempFilters = {...filters};
                    delete tempFilters.Status;
                    setFilters(tempFilters);
                  } else {
                    setFilters({...filters, Status: state !== filters.Status ? state : undefined});
                  }
                }}
                showInactiveStates={showInactiveSystemFilter}
              />
            )}
          </div>
        </div>
      )}
      {!showTabs && allowExpand && (
        <div className="tr-flex tr-justify-end tr-relative">
          <Button
            variant="ternary"
            onClick={() => {
              if (!_.isUndefined(filters.Expand)) {
                const tempFilters = {...filters};
                delete tempFilters.Expand;
                setFilters(tempFilters);
              } else {
                setFilters({...filters, Expand: 'All'});
              }
            }}
            className="tr-m-0 tr-absolute tr-top-0"
          >
            {!filters.Expand ? 'Expand All' : 'Close Expanded'}
          </Button>
        </div>
      )}
    </div>
  );
});

export default SystemFilters;
