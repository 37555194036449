import moment from 'moment';
import {Org} from 'src/stores/domainObjects/Org';
import {Resource} from 'src/stores/domainObjects/Resource';
import {ResourceFormT} from 'src/views/Resource/SimpleResourceEdit';

export function convertIntoHours(value: number, unit: string) {
  return moment.duration(value, `${unit}s` as moment.unitOfTime.DurationConstructor | undefined).asHours();
}
export function convertResourceToForm(org: Org, resource: Resource): ResourceFormT {
  return {
    name: resource.name || '',
    description: resource.description || '',
    owners: resource.owners ?? [],
    provisioners: resource.provisioners ?? [],
    hidden: resource.calculatedHidden?.value ?? resource.hidden,
    autoProvision: resource.autoProvisionValue?.value ?? false,
    sensitivityId: resource.sensitivity ? resource.sensitivity.id : org.defaultSensitivityId ?? null,
    initiateExpiredDeprovision: resource.calculatedInitiateExpiredDeprovision?.value,
    approvalDuration: resource.calcApprovalDuration,
    accessDuration: resource.calcAccessDuration,
    provisionMode: resource.calculatedProvisionMode?.value,
    deprovisionMode: resource.calculatedDeprovisionMode?.value,
  };
}
