import {Alert, Button, Icon} from '@trustle/component-library';
import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {Resource} from 'src/stores/domainObjects/Resource';
import {useAuthority} from '../hooks/useAuthority';
import {Link} from 'react-router-dom';
import {ConfirmDisassociateAuthorityModal} from '../modals/ConfirmDisassociateAuthorityModal';

export const AuthorityBanner = observer(({resource}: {resource: Resource}) => {
  const {isConfiguredAsIDP} = resource;
  const {settings, isDisabled: isAuthorityDisabled, updateAuthority} = useAuthority();
  const [showConfirmDisassociateModal, setShowConfirmDisassociateModal] = useState<boolean>(false);

  if (!isConfiguredAsIDP || !settings) {
    return null;
  }

  const message = isAuthorityDisabled ? (
    <p>
      Trustle Users is disabled, and will not resume until the System is re-enabled via the{' '}
      <Link to={`/resource/manage/${resource.id}/settings/edit_connection`}>Connection Settings page</Link>.
    </p>
  ) : (
    <p>
      Trustle Users are being sourced and synced directly from your <strong>{resource.name}</strong> IDP directory store
      via the Import Users button on the <Link to="/admin/users">User Management Page</Link>.
    </p>
  );

  return (
    <>
      <Alert
        title={`This System is currently set as your Trustle User Authority,${
          isAuthorityDisabled ? ' but is Disabled' : ' and Enabled'
        }`}
        colorVariant={isAuthorityDisabled ? 'danger' : 'primary'}
        className="tr-w-full"
        icon={isAuthorityDisabled ? <Icon type="disabled" title="disabled" /> : <Icon type="linked" title="linked" />}
      >
        <div className="tr-flex tr-items-baseline tr-justify-between">
          {message}
          <div className="tr-flex tr-gap-1">
            {isAuthorityDisabled ? (
              <Button
                className="tr-bg-white tr-text-error px-3 py-2 tr-m-1 text-body34"
                variant="secondary"
                colorVariant="danger"
                onClick={async () => {
                  await resource?.updateConnectionSettings({disabled: false});
                }}
              >
                Enable
              </Button>
            ) : (
              <Button
                className="tr-bg-white tr-text-trustle-link px-3 py-2 tr-m-1 text-body34"
                variant="secondary"
                colorVariant="primary"
                onClick={async () => {
                  await resource?.updateConnectionSettings({disabled: true});
                }}
              >
                Disable
              </Button>
            )}
            <Button
              variant="primary"
              colorVariant="primary"
              onClick={async () => {
                setShowConfirmDisassociateModal(true);
              }}
            >
              Disassociate
            </Button>
          </div>
        </div>
      </Alert>
      {showConfirmDisassociateModal && (
        <ConfirmDisassociateAuthorityModal
          onConfirm={async () => {
            await updateAuthority({associated: false});
            setShowConfirmDisassociateModal(false);
          }}
          onCancel={() => setShowConfirmDisassociateModal(false)}
          name={resource?.name}
        />
      )}
    </>
  );
});
