import React, {useState} from 'react';
import _ from 'lodash';
import {NoteT, ProfileScreenContext} from 'src/types';
import {Formik, FormikForm} from 'src/components/design/formik';
import Note from './Note';
import {formatUserName} from 'src/lib';
import './Notes.scss';
import {Account} from 'src/stores/domainObjects/Account';

import {observer} from 'mobx-react-lite';
import {useRootStore} from 'src/lib/hooks';
import {Button, Icon, Loading, TextAreaInput, UserAvatar} from '@trustle/component-library';
import {Field} from 'formik';

type RequestCommentsPropsT = {account: Account};

const Notes = observer(({account}: RequestCommentsPropsT) => {
  const [isDescending, setIsDescending] = useState<boolean>(true);
  const [creatingNote, setCreatingNote] = useState(false);
  const loggedUser = useRootStore().usersStore.currentUser;

  if (_.isNil(loggedUser) || _.isNil(account.notes)) {
    return <Loading />;
  }

  const canEdit = loggedUser.canEdit(ProfileScreenContext.ACCOUNT_NOTES, account.uid);

  const submitNote = async ({newComment}: {newComment: string}) => {
    await account.addNote(newComment);
    setCreatingNote(false);
  };

  const noteForm = (
    <Formik initialValues={{newComment: ''}} onSubmit={submitNote} className="note-form">
      {({isSubmitting}) => {
        return (
          <FormikForm>
            <Field
              component={TextAreaInput}
              id="newComment"
              data-testid="add-note"
              name="newComment"
              placeholder="Add your notes here"
              required
              rows={4}
            />
            <div className="tr-flex tr-justify-between">
              <Button
                variant="ternary"
                selected={false}
                onClick={() => {
                  setCreatingNote(false);
                }}
              >
                Close
              </Button>
              <Button type="submit" disabled={isSubmitting}>
                Save
              </Button>
            </div>
          </FormikForm>
        );
      }}
    </Formik>
  );

  const sortedNotes = _.orderBy(account.notes, ['created'], [isDescending ? 'desc' : 'asc']);

  return (
    <div className="tr-pt-3 notes-wrapper">
      {creatingNote && (
        <div className="tr-my-3 create-note-wrapper tr-flex">
          <div className="tr-w-full md:tr-w-1/12">
            <UserAvatar size={'sm'} text={`${formatUserName(loggedUser)}`} />
          </div>
          <div className="tr-w-full md:tr-w-11/12">{noteForm}</div>
        </div>
      )}
      <div className="tr-flex tr-justify-between tr-items-center body1">
        <div className="tr-flex tt--task-order" onClick={() => setIsDescending(!isDescending)}>
          {!_.isEmpty(sortedNotes) && (
            <>
              <span className="mx-1 font-weight-bold body5">{isDescending ? 'Newest First' : 'Oldest First'}</span>
              <Icon size="sm" className="mx-2" type={isDescending ? 'chevronUp' : 'chevronDown'} />
            </>
          )}
          {!creatingNote && _.isEmpty(sortedNotes) && (
            <small className="my-3 pl-1 text-secondary ">No notes added yet</small>
          )}
        </div>
        <div>
          {canEdit && !creatingNote && (
            <Button name="edit-connection-save" onClick={() => setCreatingNote(true)}>
              {'Add Note'}
            </Button>
          )}
        </div>
      </div>

      <ul className="list-unstyled">
        {_.map(sortedNotes, (note: NoteT) => {
          return <Note key={`note${note.id}`} note={note} loggedUser={loggedUser} account={account} />;
        })}
      </ul>
    </div>
  );
});

export default Notes;
