import {Alert, Button} from '@trustle/component-library';
import React from 'react';
import {Resource} from '../../../../stores/domainObjects/Resource';

type Props = {
  resource: Resource;
  handleAuthorityStatusChange: (enabledAsAuthority: boolean) => void;
};
export function AuthorityAssociateBanner(props: Props) {
  const {resource, handleAuthorityStatusChange} = props;

  return (
    <Alert title="Associate authority" colorVariant="primary" className="tr-w-full">
      <div className="tr-flex tr-items-baseline tr-justify-between">
        <p>
          Associating Authority enables you to source and sync your Trustle Users directly from your
          <strong> {resource.name}</strong> IDP directory store. Read more about it the{' '}
          <a href="https://learn.trustle.com/" target="_blank" rel="noopener noreferrer">
            Trustle Docs under User Authority.
          </a>
        </p>
        <Button
          className="tr-bg-white tr-text-trustle-link px-3 py-2 tr-m-1 text-body34 !tr-border-solid !tr-border-1 !tr-border-trustle-link"
          variant="secondary"
          onClick={() => {
            handleAuthorityStatusChange(true);
          }}
        >
          Associate
        </Button>
      </div>
    </Alert>
  );
}
