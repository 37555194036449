import {Button, Icon, Modal, Table, Tooltip} from '@trustle/component-library';
import React from 'react';
import {renderRowIconForMandatoryField} from '../authority-settings/helpers';

type Props = {
  users: any[];
  show: boolean;
  setShow: (show: boolean) => void;
  fieldMapping: any;
};

export function FieldMappingTestResultModal({users, show, setShow, fieldMapping}: Props) {
  return (
    <Modal
      visible={show}
      onClose={() => setShow(false)}
      width="xl"
      title={
        <div className="tr-text-trustle-primary tr-flex tr-items-center tr-gap-2">
          <h1 className="tr-mb-0">Field Mapping Validation Results</h1>
          <Tooltip
            size={'sm'}
            position="bottom"
            content={
              <div className="tr-py-1 tr-px-2">
                <p>At most, 10 users are fetched to verify the current field mapping configuration.</p>
              </div>
            }
          >
            <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
          </Tooltip>
        </div>
      }
    >
      <Table
        data={users}
        tableKey={'FieldMappingTestResultModal'}
        columns={resolveTableColumns(fieldMapping)}
        hideSizePerPage
      />
      <div className="tr-flex tr-justify-end">
        <Button variant="primary" label="Close" onClick={() => setShow(false)} />
      </div>
    </Modal>
  );
}

function resolveTableColumns(fieldMapping: any) {
  return [
    renderRowIconForMandatoryField(
      {
        dataField: 'email',
        text: 'Email',
        headerStyle: {width: '15%'},
        style: {width: '15%'},
      },
      fieldMapping
    ),
    renderRowIconForMandatoryField(
      {
        dataField: 'manager',
        text: 'Manager',
        headerStyle: {width: '15%'},
        style: {width: '15%'},
      },
      fieldMapping
    ),
    renderRowIconForMandatoryField(
      {
        dataField: 'remoteType',
        text: 'Type',
        formatter: (_cell: any) => {
          return <div className="tr-text-trustle-primary">{_cell}</div>;
        },
      },
      fieldMapping
    ),
    renderRowIconForMandatoryField({dataField: 'department', text: 'Department'}, fieldMapping),
    renderRowIconForMandatoryField({dataField: 'role', text: 'Role'}, fieldMapping),
    renderRowIconForMandatoryField({dataField: 'title', text: 'Title'}, fieldMapping),
    renderRowIconForMandatoryField(
      {
        dataField: 'type',
        text: 'Trustle User Type',
        formatter: (_cell: any, row: any) => {
          return <div className="tr-text-trustle-primary tr-capitalize">{row.type}</div>;
        },
      },
      fieldMapping
    ),
  ];
}
