export enum OrgEventEnum {
  USER_INVITED = 'userInvited',
  ACTIVITY_PASSWORD_RESET_REQUESTED = 'activityPasswordResetRequested',
  MODIFIED = 'modified',
  OWNER_ADDED = 'ownerAdded',
  OWNER_REMOVED = 'ownerRemoved',
  PROVISIONER_ADDED = 'provisionerAdded',
  PROVISIONER_REMOVED = 'provisionerRemoved',
  ACCESS_REQUEST = 'accessRequest',
  ACCESS_APPROVED = 'accessApproved',
  ACCESS_DEAPPROVED = 'accessDeapproved',
  ACCESS_PROVISIONED = 'accessProvisioned',
  ACCESS_DEPROVISIONED = 'accessDeprovisioned',
  ACCESS_PROVISIONED_REMOTE = 'accessProvisionedRemote',
  ACCESS_DEPROVISIONED_REMOTE = 'accessDeprovisionedRemote',
  ACCESS_PROVISIONED_REMOTE_FAILED = 'accessProvisionedRemoteFailed',
  ACCESS_DEPROVISIONED_REMOTE_FAILED = 'accessDeprovisionedRemoteFailed',
  ACCESS_INITIAL_APPROVAL = 'initialAccessApproval',
  ACCESS_ACCOUNT_CHANGED = 'accountChanged',
  ACCESS_EXTEND_PROVISION = 'accessExtendProvision',
  TICKET_ASSIGNMENT_UPDATED = 'ticketAssignmentUpdated',
  USER_ACCOUNT_CREATED = 'userAccountCreated',
  USER_ACCOUNT_DELETED = 'userAccountDeleted',
  USER_ACCOUNT_MODIFIED = 'userAccountModified',
  USER_ACCOUNT_STATUS_MODIFIED = 'userAccountStatusModified',
  TEAM_CREATED = 'teamCreated',
  TEAM_DELETED = 'teamDeleted',
  TEAM_UPDATED = 'teamModified',
  TEAM_MEMBER_ADDED = 'teamMemberAdded',
  TEAM_MEMBER_REMOVED = 'teamMemberRemoved',
  TEAM_MANAGER_ADDED = 'teamManagerAdded',
  TEAM_MANAGER_REMOVED = 'teamManagerRemoved',
  PERMISSION_CREATED = 'permissionCreated',
  PERMISSION_DELETED = 'permissionDeleted',
  PERMISSION_UPDATED = 'permissionUpdated',
  TRUSTLE_USER_ONBOARDED = 'userOnboarded',
  TRUSTLE_USER_UPDATED = 'userUpdated',
  TRUSTLE_USER_MANAGER_UPDATED = 'trustleUserManagerUpd',
  USER_LINKED = 'userLinked',
  USER_UNLINKED = 'userUninked',
  TRUSTLE_USER_UNMAPPED = 'userUnmapped',
  TRUSTLE_USER_MAPPED = 'userMapped',
  TRUSTLE_USER_OFFBOARDED = 'userOffboarded',
  CONNECTION_UPDATE = 'connectionUpdated',

  TRUSTLE_USER_DELETED = 'userDeleted',
  IMPORT_FINISHED = 'importFinished',
  CONNECTION_FAILED = 'connectionFailed',
  CONNECTION_REESTABLISHED = 'connectionReestablished',
  RESOURCE_MODIFIED = 'resourceModified',
  RESOURCE_ADDED_OWNER = 'resourceAddedOwner',
  RESOURCE_REMOVED_OWNER = 'resourceRemovedOwner',
  RESOURCE_ADDED_PROVISIONER = 'resourceAddedProvisioner',
  RESOURCE_REMOVED_PROVISIONER = 'resourceRemovedProvisioner',
  RESOURCE_ARCHIVED = 'resourceArchived',
  RESOURCE_UNARCHIVED = 'resourceUnarchived',
  RESOURCE_AUTO_PROVISION_CHANGED = 'resourceAutoProvisionChanged',
  RESOURCE_DELETED = 'resourceDeleted',
  AUTHORITY_STATUS_CHANGE = 'authorityStatusChange',
  AUTHORITY_SETTINGS_UPDATED = 'authoritySettingsUpdated',

  //Added after comparing with doc

  ACCESS_REVOKED = 'accessRevoked',
  RESOURCE_HIDDEN = 'resourceHidden',
  RESOURCE_UNHIDDEN = 'resourceUnhidden',
  ACTIVITY_LOGIN = 'activityLogin',
  ACTIVITY_LOGIN_CONFIRM_CODE = 'activityLoginConfirmCode',
  ACTIVITY_SESSION = 'activitySession',
  ACTIVITY_LOGIN_FAILED = 'activityLoginFailed',
  ACCESS_REQUEST_REQUESTED = 'accessRequestRequested',
  ACCESS_REQUEST_APPROVED = 'accessRequestApproved',
  ACCESS_REQUEST_CANCELED = 'accessRequestCanceled',
  ACCESS_REQUEST_CLOSED = 'accessRequestClosed',
  ACCESS_REQUEST_COMPLETED = 'accessRequestCompleted',
  ACCESS_REQUEST_CREATED = 'accessRequestCreated',
  ACCESS_REQUEST_DENIED = 'accessRequestDenied',
  ACCESS_REQUEST_DEPROVISIONED = 'accessRequestDeprovisioned',
  ACCESS_REQUEST_PERMISSION_GRANTED = 'accessRequestPermissionGranted',
  ACCESS_REQUEST_PROVISIONED = 'accessRequestProvisioned',
  WORKFLOW_STARTED = 'workflowStarted',
  WORKFLOW_PREPARED = 'workflowPrepared',
  WORKFLOW_CANCELED = 'workflowCanceled',
  WORKFLOW_RUN = 'workflowRun',
  WORKFLOW_STATUS = 'workflowStatus',
  TICKET_STARTED = 'ticketStarted',
  TICKET_FINISHED = 'ticketFinished',
  //COnnector events nr:not sure if suitable here.

  LOGIN_SUCCESS = 'loginSuccess',
  LAST_PROGRAMMATIC_ACCESS = 'lastProgrammaticAccess',
  LAST_ACTIVITY = 'lastActivity',
}

export const ActivityReadableMapping = {
  [OrgEventEnum.USER_INVITED]: 'User Invited',
  [OrgEventEnum.ACTIVITY_PASSWORD_RESET_REQUESTED]: 'User Password Reset Requested',
  [OrgEventEnum.MODIFIED]: 'Modified',
  [OrgEventEnum.CONNECTION_UPDATE]: 'Connection Update',
  [OrgEventEnum.OWNER_ADDED]: 'Owner Added',
  [OrgEventEnum.OWNER_REMOVED]: 'Owner Removed',
  [OrgEventEnum.PROVISIONER_ADDED]: 'Provisioner Added',
  [OrgEventEnum.PROVISIONER_REMOVED]: 'Provisioner Removed',
  [OrgEventEnum.ACCESS_REQUEST]: 'Access Request',
  [OrgEventEnum.ACCESS_APPROVED]: 'Access Approved',
  [OrgEventEnum.ACCESS_DEAPPROVED]: 'Access Deapproved',
  [OrgEventEnum.ACCESS_PROVISIONED]: 'Access Provisioned',
  [OrgEventEnum.ACCESS_DEPROVISIONED]: 'Access Deprovisioned',
  [OrgEventEnum.ACCESS_PROVISIONED_REMOTE]: 'Access Provisioned Remote',
  [OrgEventEnum.ACCESS_DEPROVISIONED_REMOTE]: 'Access Deprovisioned Remote',
  [OrgEventEnum.ACCESS_PROVISIONED_REMOTE_FAILED]: 'Access Provisioned Remote Failed',
  [OrgEventEnum.ACCESS_DEPROVISIONED_REMOTE_FAILED]: 'Access Deprovisioned Remote Failed',
  [OrgEventEnum.ACCESS_INITIAL_APPROVAL]: 'Initial Access Approval',
  [OrgEventEnum.ACCESS_ACCOUNT_CHANGED]: 'Access Account Changed',
  [OrgEventEnum.ACCESS_EXTEND_PROVISION]: 'Access Extend Provision',
  [OrgEventEnum.TICKET_ASSIGNMENT_UPDATED]: 'Ticket Assignment Updated',
  [OrgEventEnum.USER_ACCOUNT_CREATED]: 'User Account Created',
  [OrgEventEnum.USER_ACCOUNT_DELETED]: 'User Account Deleted',
  [OrgEventEnum.USER_ACCOUNT_MODIFIED]: 'User Account Modified',
  [OrgEventEnum.USER_ACCOUNT_STATUS_MODIFIED]: 'User Account Status Modified',
  [OrgEventEnum.TEAM_CREATED]: 'Team Created',
  [OrgEventEnum.TEAM_DELETED]: 'Team Deleted',
  [OrgEventEnum.TEAM_UPDATED]: 'Team Updated',
  [OrgEventEnum.TEAM_MEMBER_ADDED]: 'Team Member Added',
  [OrgEventEnum.TEAM_MEMBER_REMOVED]: 'Team Member Removed',
  [OrgEventEnum.TEAM_MANAGER_ADDED]: 'Team Manager Added',
  [OrgEventEnum.TEAM_MANAGER_REMOVED]: 'Team Manager Removed',
  [OrgEventEnum.PERMISSION_CREATED]: 'Permission Created',
  [OrgEventEnum.PERMISSION_DELETED]: 'Permission Deleted',
  [OrgEventEnum.PERMISSION_UPDATED]: 'Permission Updated',
  [OrgEventEnum.TRUSTLE_USER_ONBOARDED]: 'Trustle User Onboarded',
  [OrgEventEnum.TRUSTLE_USER_UPDATED]: 'Trustle User Updated',
  [OrgEventEnum.TRUSTLE_USER_MANAGER_UPDATED]: 'Trustle User Manager Updated',
  [OrgEventEnum.USER_LINKED]: 'User Linked',
  [OrgEventEnum.USER_UNLINKED]: 'User Unlinked',
  [OrgEventEnum.TRUSTLE_USER_OFFBOARDED]: 'Trustle User Offboarded',
  [OrgEventEnum.TRUSTLE_USER_DELETED]: 'User Deleted',
  [OrgEventEnum.TRUSTLE_USER_UNMAPPED]: 'User Unlinked',
  [OrgEventEnum.TRUSTLE_USER_MAPPED]: 'User Linked',
  [OrgEventEnum.IMPORT_FINISHED]: 'Import Finished',
  [OrgEventEnum.CONNECTION_FAILED]: 'Connection Failed',
  [OrgEventEnum.CONNECTION_REESTABLISHED]: 'Connection Reestablished',
  [OrgEventEnum.RESOURCE_MODIFIED]: 'Resource Modified',
  [OrgEventEnum.RESOURCE_ADDED_OWNER]: 'Resource Added Owner',
  [OrgEventEnum.RESOURCE_REMOVED_OWNER]: 'Resource Removed Owner',
  [OrgEventEnum.RESOURCE_ADDED_PROVISIONER]: 'Resource Added Provisioner',
  [OrgEventEnum.RESOURCE_REMOVED_PROVISIONER]: 'Resource Removed Provisioner',
  [OrgEventEnum.RESOURCE_ARCHIVED]: 'Resource Archived',
  [OrgEventEnum.RESOURCE_UNARCHIVED]: 'Resource Unarchived',
  [OrgEventEnum.RESOURCE_HIDDEN]: 'Resource Hidden',
  [OrgEventEnum.RESOURCE_UNHIDDEN]: 'Resource Unhidden',
  [OrgEventEnum.RESOURCE_DELETED]: 'Resource Deleted',
  [OrgEventEnum.AUTHORITY_STATUS_CHANGE]: 'Authority Status Change',
  [OrgEventEnum.AUTHORITY_SETTINGS_UPDATED]: 'Authority Settings Updated',
  [OrgEventEnum.ACCESS_REVOKED]: 'Access Revoked',
  [OrgEventEnum.ACTIVITY_LOGIN]: 'Activity Login ',
  [OrgEventEnum.ACTIVITY_LOGIN_CONFIRM_CODE]: 'Activity Login Confirm Code',
  [OrgEventEnum.ACTIVITY_LOGIN_FAILED]: 'Activity Login Failed',
  [OrgEventEnum.ACTIVITY_SESSION]: 'Activity Session',
  [OrgEventEnum.LOGIN_SUCCESS]: 'Login Success',
  [OrgEventEnum.LAST_PROGRAMMATIC_ACCESS]: 'Last Programmatic Access',
  [OrgEventEnum.LAST_ACTIVITY]: 'Last Activity',
  [OrgEventEnum.RESOURCE_AUTO_PROVISION_CHANGED]: 'Auto Provision had changed',
  [OrgEventEnum.ACCESS_REQUEST_REQUESTED]: 'Access Request Requested',
  [OrgEventEnum.ACCESS_REQUEST_APPROVED]: 'Access Request Approved',
  [OrgEventEnum.ACCESS_REQUEST_CANCELED]: 'Access Request Canceled',
  [OrgEventEnum.ACCESS_REQUEST_CLOSED]: 'Access Request Closed',
  [OrgEventEnum.ACCESS_REQUEST_COMPLETED]: 'Access Request Completed',
  [OrgEventEnum.ACCESS_REQUEST_CREATED]: 'Access Request Created',
  [OrgEventEnum.ACCESS_REQUEST_DENIED]: 'Access Request Denied',
  [OrgEventEnum.ACCESS_REQUEST_DEPROVISIONED]: 'Access Request Deprovisioned',
  [OrgEventEnum.ACCESS_REQUEST_PERMISSION_GRANTED]: 'Permission Granted ',
  [OrgEventEnum.ACCESS_REQUEST_PROVISIONED]: 'Access Request Provisioned',
  [OrgEventEnum.WORKFLOW_STARTED]: 'Workflow Started',
  [OrgEventEnum.WORKFLOW_PREPARED]: 'Workflow Prepared',
  [OrgEventEnum.WORKFLOW_CANCELED]: 'Workflow Canceled',
  [OrgEventEnum.WORKFLOW_RUN]: 'Workflow Run',
  [OrgEventEnum.WORKFLOW_STATUS]: 'Workflow Status',
  [OrgEventEnum.TICKET_STARTED]: 'Ticket Started',
  [OrgEventEnum.TICKET_FINISHED]: 'Ticket Finished',
};
