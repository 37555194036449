import React from 'react';
import {TabsDocsT} from '../DocSection';
import DocSection from '../DocSection';

function STSTokenAWS() {
  const tabs: TabsDocsT = [
    {id: 'sts-contents', label: 'Contents', description: 'Content detail', active: true},
    {id: 'sts-supported', label: 'Supported Features', description: 'Supported features detail', active: false},
    {id: 'sts-overview', label: 'Overview', description: 'Overview detail', active: false},
    {
      id: 'sts-configuration-steps',
      label: 'Configuration Steps',
      description: 'Configuration steps detail',
      active: false,
    },
  ];

  return (
    <>
      <DocSection
        tabs={tabs}
        title="How to configure Security Token Service (STS) of Amazon Web Services (AWS) for Trustle"
      />
    </>
  );
}

export default STSTokenAWS;
