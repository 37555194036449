/* This scopes are extracted from https://developers.google.com/identity/protocols/oauth2/scopes
Are copied and formatted. After that we use the follow algorithm to convert to an useful object: googleScopesAsObject

import _ from 'lodash';
const result: any = {};
_.each(googleScopes, (family) => {
  _.each(family.scopes, (scope) => {
    result[scope.scope] = (result[scope.scope] || []).concat({description: scope.description, group: family.title});
  });
});

const googleScopes = [
  {
    title: 'AI Platform Training & Prediction API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform.read-only',
        description: 'View your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Access Approval API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Access Context Manager API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Ad Exchange Buyer API, v1.4',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/adexchange.buyer',
        description: 'Manage your Ad Exchange buyer account configuration',
      },
    ],
  },
  {
    title: 'Ad Exchange Buyer API II, v2beta1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/adexchange.buyer',
        description: 'Manage your Ad Exchange buyer account configuration',
      },
    ],
  },
  {
    title: 'AdMob API, v1',
    scopes: [
      {scope: 'https://www.googleapis.com/auth/admob.readonly', description: 'See your AdMob data'},
      {scope: 'https://www.googleapis.com/auth/admob.report', description: 'See your AdMob data'},
    ],
  },
  {
    title: 'AdSense Host API, v4.1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/adsensehost',
        description: 'View and manage your AdSense host data and associated accounts',
      },
    ],
  },
  {
    title: 'AdSense Management API, v1.4',
    scopes: [
      {scope: 'https://www.googleapis.com/auth/adsense', description: 'View and manage your AdSense data'},
      {scope: 'https://www.googleapis.com/auth/adsense.readonly', description: 'View your AdSense data'},
    ],
  },
  {
    title: 'Admin Data Transfer API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/admin.datatransfer',
        description: 'View and manage data transfers between users in your organization',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.datatransfer.readonly',
        description: 'View data transfers between users in your organization',
      },
    ],
  },
  {
    title: 'Admin Directory API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.customer',
        description: 'View and manage customer related information',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.customer.readonly',
        description: 'View customer related information',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.device.chromeos',
        description: "View and manage your Chrome OS devices' metadata",
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.device.chromeos.readonly',
        description: "View your Chrome OS devices' metadata",
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.device.mobile',
        description: "View and manage your mobile devices' metadata",
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.device.mobile.action',
        description: 'Manage your mobile devices by performing administrative tasks',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.device.mobile.readonly',
        description: "View your mobile devices' metadata",
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.domain',
        description: 'View and manage the provisioning of domains for your customers',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.domain.readonly',
        description: 'View domains related to your customers',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.group',
        description: 'View and manage the provisioning of groups on your domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.group.member',
        description: 'View and manage group subscriptions on your domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.group.member.readonly',
        description: 'View group subscriptions on your domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.group.readonly',
        description: 'View groups on your domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.orgunit',
        description: 'View and manage organization units on your domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.orgunit.readonly',
        description: 'View organization units on your domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.resource.calendar',
        description: 'View and manage the provisioning of calendar resources on your domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.resource.calendar.readonly',
        description: 'View calendar resources on your domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.rolemanagement',
        description: 'Manage delegated admin roles for your domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.rolemanagement.readonly',
        description: 'View delegated admin roles for your domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.user',
        description: 'View and manage the provisioning of users on your domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.user.alias',
        description: 'View and manage user aliases on your domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.user.alias.readonly',
        description: 'View user aliases on your domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.user.readonly',
        description: 'View users on your domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.user.security',
        description: 'Manage data access permissions for users on your domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.userschema',
        description: 'View and manage the provisioning of user schemas on your domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.userschema.readonly',
        description: 'View user schemas on your domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Admin Reports API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/admin.reports.audit.readonly',
        description: 'View audit reports for your G Suite domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.reports.usage.readonly',
        description: 'View usage reports for your G Suite domain',
      },
    ],
  },

  {
    title: 'Analytics Reporting API, v4',
    scopes: [
      {scope: 'https://www.googleapis.com/auth/analytics', description: 'View and manage your Google Analytics data'},
      {
        scope: 'https://www.googleapis.com/auth/analytics.readonly',
        description: 'See and download your Google Analytics data',
      },
    ],
  },
  {
    title: 'Android Management API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/androidmanagement',
        description: 'Manage Android devices and apps for your customers',
      },
    ],
  },

  {
    title: 'Apigee API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },

  {
    title: 'App Engine Admin API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/appengine.admin',
        description: 'View and manage your applications deployed on Google App Engine',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform.read-only',
        description: 'View your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Apps Script API, v1',
    scopes: [
      {
        scope: 'https://mail.google.com/',
        description: 'Read, compose, send, and permanently delete all your email from Gmail',
      },
      {
        scope: 'https://www.google.com/calendar/feeds',
        description: 'See, edit, share, and permanently delete all the calendars you can access using Google Calendar',
      },
      {
        scope: 'https://www.google.com/m8/feeds',
        description: 'See, edit, download, and permanently delete your contacts',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.group',
        description: 'View and manage the provisioning of groups on your domain',
      },
      {
        scope: 'https://www.googleapis.com/auth/admin.directory.user',
        description: 'View and manage the provisioning of users on your domain',
      },
      {scope: 'https://www.googleapis.com/auth/documents', description: 'View and manage your Google Docs documents'},
      {
        scope: 'https://www.googleapis.com/auth/drive',
        description: 'See, edit, create, and delete all of your Google Drive files',
      },
      {scope: 'https://www.googleapis.com/auth/forms', description: 'View and manage your forms in Google Drive'},
      {
        scope: 'https://www.googleapis.com/auth/forms.currentonly',
        description: 'View and manage forms that this application has been installed in',
      },
      {scope: 'https://www.googleapis.com/auth/groups', description: 'View and manage your Google Groups'},
      {
        scope: 'https://www.googleapis.com/auth/script.deployments',
        description: 'Create and update Google Apps Script deployments',
      },
      {
        scope: 'https://www.googleapis.com/auth/script.deployments.readonly',
        description: 'View Google Apps Script deployments',
      },
      {
        scope: 'https://www.googleapis.com/auth/script.metrics',
        description: "View Google Apps Script project's metrics",
      },
      {scope: 'https://www.googleapis.com/auth/script.processes', description: 'View Google Apps Script processes'},
      {
        scope: 'https://www.googleapis.com/auth/script.projects',
        description: 'Create and update Google Apps Script projects',
      },
      {
        scope: 'https://www.googleapis.com/auth/script.projects.readonly',
        description: 'View Google Apps Script projects',
      },
      {
        scope: 'https://www.googleapis.com/auth/spreadsheets',
        description: 'See, edit, create, and delete your spreadsheets in Google Drive',
      },
      {scope: 'https://www.googleapis.com/auth/userinfo.email', description: 'View your email address'},
    ],
  },
  {
    title: 'BigQuery API, v2',
    scopes: [
      {scope: 'https://www.googleapis.com/auth/bigquery', description: 'View and manage your data in Google BigQuery'},
      {scope: 'https://www.googleapis.com/auth/bigquery.insertdata', description: 'Insert data into Google BigQuery'},
      {scope: 'https://www.googleapis.com/auth/bigquery.readonly', description: 'View your data in Google BigQuery'},
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform.read-only',
        description: 'View your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/devstorage.full_control',
        description: 'Manage your data and permissions in Google Cloud Storage',
      },
      {
        scope: 'https://www.googleapis.com/auth/devstorage.read_only',
        description: 'View your data in Google Cloud Storage',
      },
      {
        scope: 'https://www.googleapis.com/auth/devstorage.read_write',
        description: 'Manage your data in Google Cloud Storage',
      },
    ],
  },
  {
    title: 'BigQuery Connection API, v1beta1',
    scopes: [
      {scope: 'https://www.googleapis.com/auth/bigquery', description: 'View and manage your data in Google BigQuery'},
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'BigQuery Data Transfer API, v1',
    scopes: [
      {scope: 'https://www.googleapis.com/auth/bigquery', description: 'View and manage your data in Google BigQuery'},
      {scope: 'https://www.googleapis.com/auth/bigquery.readonly', description: 'View your data in Google BigQuery'},
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform.read-only',
        description: 'View your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'BigQuery Reservation API, v1',
    scopes: [
      {scope: 'https://www.googleapis.com/auth/bigquery', description: 'View and manage your data in Google BigQuery'},
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Binary Authorization API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Blogger API, v3',
    scopes: [
      {scope: 'https://www.googleapis.com/auth/blogger', description: 'Manage your Blogger account'},
      {scope: 'https://www.googleapis.com/auth/blogger.readonly', description: 'View your Blogger account'},
    ],
  },
  {
    title: 'Books API, v1',
    scopes: [{scope: 'https://www.googleapis.com/auth/books', description: 'Manage your books'}],
  },
  {
    title: 'Calendar API, v3',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/calendar',
        description: 'See, edit, share, and permanently delete all the calendars you can access using Google Calendar',
      },
      {
        scope: 'https://www.googleapis.com/auth/calendar.events',
        description: 'View and edit events on all your calendars',
      },
      {
        scope: 'https://www.googleapis.com/auth/calendar.events.readonly',
        description: 'View events on all your calendars',
      },
      {
        scope: 'https://www.googleapis.com/auth/calendar.readonly',
        description: 'See and download any calendar you can access using your Google Calendar',
      },
      {scope: 'https://www.googleapis.com/auth/calendar.settings.readonly', description: 'View your Calendar settings'},
    ],
  },
  {
    title: 'Campaign Manager 360 API, v3.4',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/ddmconversions',
        description: 'Manage DoubleClick Digital Marketing conversions',
      },
      {
        scope: 'https://www.googleapis.com/auth/dfareporting',
        description: 'View and manage DoubleClick for Advertisers reports',
      },
      {
        scope: 'https://www.googleapis.com/auth/dfatrafficking',
        description: "View and manage your DoubleClick Campaign Manager's (DCM) display ad campaigns",
      },
    ],
  },
  {
    title: 'Chrome Verified Access API, v1',
    scopes: [
      {scope: 'https://www.googleapis.com/auth/verifiedaccess', description: 'Verify your enterprise credentials'},
    ],
  },
  {
    title: 'Cloud Asset API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Bigtable Admin API, v2',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/bigtable.admin',
        description: 'Administer your Cloud Bigtable tables and clusters',
      },
      {
        scope: 'https://www.googleapis.com/auth/bigtable.admin.cluster',
        description: 'Administer your Cloud Bigtable clusters',
      },
      {
        scope: 'https://www.googleapis.com/auth/bigtable.admin.instance',
        description: 'Administer your Cloud Bigtable clusters',
      },
      {
        scope: 'https://www.googleapis.com/auth/bigtable.admin.table',
        description: 'Administer your Cloud Bigtable tables',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-bigtable.admin',
        description: 'Administer your Cloud Bigtable tables and clusters',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-bigtable.admin.cluster',
        description: 'Administer your Cloud Bigtable clusters',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-bigtable.admin.table',
        description: 'Administer your Cloud Bigtable tables',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform.read-only',
        description: 'View your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Billing API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-billing',
        description: 'View and manage your Google Cloud Platform billing accounts',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-billing.readonly',
        description: 'View your Google Cloud Platform billing accounts',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Build API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Composer API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud DNS API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform.read-only',
        description: 'View your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/ndev.clouddns.readonly',
        description: 'View your DNS records hosted by Google Cloud DNS',
      },
      {
        scope: 'https://www.googleapis.com/auth/ndev.clouddns.readwrite',
        description: 'View and manage your DNS records hosted by Google Cloud DNS',
      },
    ],
  },
  {
    title: 'Cloud Data Loss Prevention (DLP) API, v2',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Dataproc API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Datastore API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/datastore',
        description: 'View and manage your Google Cloud Datastore data',
      },
    ],
  },
  {
    title: 'Cloud Debugger API, v2',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {scope: 'https://www.googleapis.com/auth/cloud_debugger', description: 'Use Stackdriver Debugger'},
    ],
  },
  {
    title: 'Cloud Deployment Manager V2 API, v2',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform.read-only',
        description: 'View your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/ndev.cloudman',
        description:
          'View and manage your Google Cloud Platform management resources and deployment status information',
      },
      {
        scope: 'https://www.googleapis.com/auth/ndev.cloudman.readonly',
        description: 'View your Google Cloud Platform management resources and deployment status information',
      },
    ],
  },
  {
    title: 'Cloud Filestore API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Firestore API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/datastore',
        description: 'View and manage your Google Cloud Datastore data',
      },
    ],
  },
  {
    title: 'Cloud Functions API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Healthcare API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Identity API, v1',
    scopes: [
      {scope: 'https://www.googleapis.com/auth/cloud-identity.devices.lookup', description: 'See your device details'},
      {
        scope: 'https://www.googleapis.com/auth/cloud-identity.groups',
        description:
          'See, change, create, and delete any of the Cloud Identity Groups that you can access, including the members of each group',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-identity.groups.readonly',
        description: 'See any Cloud Identity Groups that you can access, including group members and their emails',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Identity-Aware Proxy API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud IoT API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloudiot',
        description: 'Register and manage devices in the Google Cloud IoT service',
      },
    ],
  },
  {
    title: 'Cloud Key Management Service (KMS) API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloudkms',
        description: 'View and manage your keys and secrets stored in Cloud Key Management Service',
      },
    ],
  },
  {
    title: 'Cloud Life Sciences API, v2beta',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Logging API, v2',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform.read-only',
        description: 'View your data across Google Cloud Platform services',
      },
      {scope: 'https://www.googleapis.com/auth/logging.admin', description: 'Administrate log data for your projects'},
      {scope: 'https://www.googleapis.com/auth/logging.read', description: 'View log data for your projects'},
      {scope: 'https://www.googleapis.com/auth/logging.write', description: 'Submit log data for your projects'},
    ],
  },
  {
    title: 'Cloud Memorystore for Memcached API, v1beta2',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Monitoring API, v3',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/monitoring',
        description: 'View and write monitoring data for all of your Google and third-party Cloud and API projects',
      },
      {
        scope: 'https://www.googleapis.com/auth/monitoring.read',
        description: 'View monitoring data for all of your Google Cloud and third-party projects',
      },
      {
        scope: 'https://www.googleapis.com/auth/monitoring.write',
        description: 'Publish metric data to your Google Cloud projects',
      },
    ],
  },
  {
    title: 'Cloud Natural Language API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-language',
        description: 'Apply machine learning models to reveal the structure and meaning of text',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud OS Login API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/compute',
        description: 'View and manage your Google Compute Engine resources',
      },
    ],
  },
  {
    title: 'Cloud Pub/Sub API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/pubsub',
        description: 'View and manage Pub/Sub topics and subscriptions',
      },
    ],
  },
  {
    title: 'Cloud Resource Manager API, v2',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform.read-only',
        description: 'View your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Run Admin API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Runtime Configuration API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloudruntimeconfig',
        description: "Manage your Google Cloud Platform services' runtime configuration",
      },
    ],
  },
  {
    title: 'Cloud SQL Admin API, v1beta4',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/sqlservice.admin',
        description: 'Manage your Google SQL Service instances',
      },
    ],
  },
  {
    title: 'Cloud Scheduler API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Search API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud_search',
        description: "Index and serve your organization's data with Cloud Search",
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud_search.debug',
        description: "Index and serve your organization's data with Cloud Search",
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud_search.indexing',
        description: "Index and serve your organization's data with Cloud Search",
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud_search.query',
        description: "Search your organization's data in the Cloud Search index",
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud_search.settings',
        description: "Index and serve your organization's data with Cloud Search",
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud_search.settings.indexing',
        description: "Index and serve your organization's data with Cloud Search",
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud_search.settings.query',
        description: "Index and serve your organization's data with Cloud Search",
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud_search.stats',
        description: "Index and serve your organization's data with Cloud Search",
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud_search.stats.indexing',
        description: "Index and serve your organization's data with Cloud Search",
      },
    ],
  },
  {
    title: 'Cloud Shell API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Source Repositories API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/source.full_control',
        description: 'Manage your source code repositories',
      },
      {
        scope: 'https://www.googleapis.com/auth/source.read_only',
        description: 'View the contents of your source code repositories',
      },
      {
        scope: 'https://www.googleapis.com/auth/source.read_write',
        description: 'Manage the contents of your source code repositories',
      },
    ],
  },
  {
    title: 'Cloud Spanner API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {scope: 'https://www.googleapis.com/auth/spanner.admin', description: 'Administer your Spanner databases'},
      {
        scope: 'https://www.googleapis.com/auth/spanner.data',
        description: 'View and manage the contents of your Spanner databases',
      },
    ],
  },
  {
    title: 'Cloud Speech-to-Text API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Storage JSON API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform.read-only',
        description: 'View your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/devstorage.full_control',
        description: 'Manage your data and permissions in Google Cloud Storage',
      },
      {
        scope: 'https://www.googleapis.com/auth/devstorage.read_only',
        description: 'View your data in Google Cloud Storage',
      },
      {
        scope: 'https://www.googleapis.com/auth/devstorage.read_write',
        description: 'Manage your data in Google Cloud Storage',
      },
    ],
  },
  {
    title: 'Cloud TPU API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Tasks API, v2',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Testing API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform.read-only',
        description: 'View your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Text-to-Speech API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Tool Results API, v1beta3',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Trace API, v2',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/trace.append',
        description: 'Write Trace data for a project or application',
      },
    ],
  },
  {
    title: 'Cloud Translation API, v3',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-translation',
        description: 'Translate text from one language to another using Google Translate',
      },
    ],
  },
  {
    title: 'Cloud Video Intelligence API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Cloud Vision API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-vision',
        description: 'Apply machine learning models to understand and label images',
      },
    ],
  },
  {
    title: 'Compute Engine API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/compute',
        description: 'View and manage your Google Compute Engine resources',
      },
      {
        scope: 'https://www.googleapis.com/auth/compute.readonly',
        description: 'View your Google Compute Engine resources',
      },
      {
        scope: 'https://www.googleapis.com/auth/devstorage.full_control',
        description: 'Manage your data and permissions in Google Cloud Storage',
      },
      {
        scope: 'https://www.googleapis.com/auth/devstorage.read_only',
        description: 'View your data in Google Cloud Storage',
      },
      {
        scope: 'https://www.googleapis.com/auth/devstorage.read_write',
        description: 'Manage your data in Google Cloud Storage',
      },
    ],
  },
  {
    title: 'Container Analysis API, v1beta1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Content API for Shopping, v2.1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/content',
        description: 'Manage your product listings and accounts for Google Shopping',
      },
    ],
  },
  {
    title: 'Dataflow API, v1b3',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/compute',
        description: 'View and manage your Google Compute Engine resources',
      },
      {
        scope: 'https://www.googleapis.com/auth/compute.readonly',
        description: 'View your Google Compute Engine resources',
      },
      {scope: 'https://www.googleapis.com/auth/userinfo.email', description: 'View your email address'},
    ],
  },
  {
    title: 'Display & Video 360 API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/display-video',
        description: 'Create, see, edit, and permanently delete your Display & Video 360 entities and reports',
      },
      {
        scope: 'https://www.googleapis.com/auth/display-video-mediaplanning',
        description: 'Create, see, and edit Display & Video 360 Campaign entities and see billing invoices',
      },
      {scope: 'https://www.googleapis.com/auth/display-video-user-management', description: ''},
      {
        scope: 'https://www.googleapis.com/auth/doubleclickbidmanager',
        description: 'View and manage your reports in DoubleClick Bid Manager',
      },
    ],
  },
  {
    title: 'DoubleClick Bid Manager API, v1.1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/doubleclickbidmanager',
        description: 'View and manage your reports in DoubleClick Bid Manager',
      },
    ],
  },
  {
    title: 'Drive API, v3',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/drive',
        description: 'See, edit, create, and delete all of your Google Drive files',
      },
      {
        scope: 'https://www.googleapis.com/auth/drive.appdata',
        description: 'View and manage its own configuration data in your Google Drive',
      },
      {
        scope: 'https://www.googleapis.com/auth/drive.file',
        description: 'View and manage Google Drive files and folders that you have opened or created with this app',
      },
      {
        scope: 'https://www.googleapis.com/auth/drive.install',
        description: 'Special scope used to let users approve installation of an app, and scope needs to be requested.',
      },
      {
        scope: 'https://www.googleapis.com/auth/drive.apps.readonly',
        description: 'Allows read-only access to installed apps.',
      },
      {
        scope: 'https://www.googleapis.com/auth/drive.metadata',
        description: 'View and manage metadata of files in your Google Drive',
      },
      {
        scope: 'https://www.googleapis.com/auth/drive.metadata.readonly',
        description: 'View metadata for files in your Google Drive',
      },
      {
        scope: 'https://www.googleapis.com/auth/drive.photos.readonly',
        description: 'View the photos, videos and albums in your Google Photos',
      },
      {
        scope: 'https://www.googleapis.com/auth/drive.readonly',
        description: 'See and download all your Google Drive files',
      },
      {
        scope: 'https://www.googleapis.com/auth/drive.scripts',
        description: "Modify your Google Apps Script scripts' behavior",
      },
    ],
  },
  {
    title: 'Drive Activity API, v2',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/drive.activity',
        description: 'View and add to the activity record of files in your Google Drive',
      },
      {
        scope: 'https://www.googleapis.com/auth/drive.activity.readonly',
        description: 'View the activity record of files in your Google Drive',
      },
    ],
  },

  {
    title: 'Enterprise License Manager API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/apps.licensing',
        description: 'View and manage G Suite licenses for your domain',
      },
    ],
  },

  {
    title: 'Error Reporting API, v1beta1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },

  {
    title: 'Fact Check Tools API, v1alpha1',
    scopes: [{scope: 'https://www.googleapis.com/auth/userinfo.email', description: 'View your email address'}],
  },

  {
    title: 'Firebase Cloud Messaging API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'See, edit, configure, and delete your Google Cloud Platform data',
      },
    ],
  },
  {
    title: 'Firebase Dynamic Links API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/firebase',
        description: 'View and administer all your Firebase data and settings',
      },
    ],
  },
  {
    title: 'Firebase Management API, v1beta1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform.read-only',
        description: 'View your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/firebase',
        description: 'View and administer all your Firebase data and settings',
      },
      {
        scope: 'https://www.googleapis.com/auth/firebase.readonly',
        description: 'View all your Firebase data and settings',
      },
    ],
  },
  {
    title: 'Firebase Rules API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/firebase',
        description: 'View and administer all your Firebase data and settings',
      },
      {
        scope: 'https://www.googleapis.com/auth/firebase.readonly',
        description: 'View all your Firebase data and settings',
      },
    ],
  },
  {
    title: 'Fitness API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/fitness.activity.read',
        description: 'Use Google Fit to see and store your physical activity data',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.activity.write',
        description: 'See and add to your Google Fit physical activity data',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.blood_glucose.read',
        description:
          'See info about your blood glucose in Google Fit. I consent to Google sharing my blood glucose information with this app.',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.blood_glucose.write',
        description:
          'See and add info about your blood glucose to Google Fit. I consent to Google sharing my blood glucose information with this app.',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.blood_pressure.read',
        description:
          'See info about your blood pressure in Google Fit. I consent to Google sharing my blood pressure information with this app.',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.blood_pressure.write',
        description:
          'See and add info about your blood pressure in Google Fit. I consent to Google sharing my blood pressure information with this app.',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.body.read',
        description: 'See info about your body measurements and heart rate in Google Fit',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.body.write',
        description: 'See and add info about your body measurements and heart rate to Google Fit',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.body_temperature.read',
        description:
          'See info about your body temperature in Google Fit. I consent to Google sharing my body temperature information with this app.',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.body_temperature.write',
        description:
          'See and add to info about your body temperature in Google Fit. I consent to Google sharing my body temperature information with this app.',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.heart_rate.read',
        description:
          'See your heart rate data in Google Fit. I consent to Google sharing my heart rate information with this app.',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.heart_rate.write',
        description:
          'See and add to your heart rate data in Google Fit. I consent to Google sharing my heart rate information with this app.',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.location.read',
        description: 'See your Google Fit speed and distance data',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.location.write',
        description: 'See and add to your Google Fit location data',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.nutrition.read',
        description: 'See info about your nutrition in Google Fit',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.nutrition.write',
        description: 'See and add to info about your nutrition in Google Fit',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.oxygen_saturation.read',
        description:
          'See info about your oxygen saturation in Google Fit. I consent to Google sharing my oxygen saturation information with this app.',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.oxygen_saturation.write',
        description:
          'See and add info about your oxygen saturation in Google Fit. I consent to Google sharing my oxygen saturation information with this app.',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.reproductive_health.read',
        description:
          'See info about your reproductive health in Google Fit. I consent to Google sharing my reproductive health information with this app.',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.reproductive_health.write',
        description:
          'See and add info about your reproductive health in Google Fit. I consent to Google sharing my reproductive health information with this app.',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.sleep.read',
        description:
          'See your sleep data in Google Fit. I consent to Google sharing my sleep information with this app.',
      },
      {
        scope: 'https://www.googleapis.com/auth/fitness.sleep.write',
        description:
          'See and add to your sleep data in Google Fit. I consent to Google sharing my sleep information with this app.',
      },
    ],
  },
  {
    title: 'G Suite Vault API, v1',
    scopes: [
      {scope: 'https://www.googleapis.com/auth/ediscovery', description: 'Manage your eDiscovery data'},
      {scope: 'https://www.googleapis.com/auth/ediscovery.readonly', description: 'View your eDiscovery data'},
    ],
  },
  {
    title: 'Genomics API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {scope: 'https://www.googleapis.com/auth/genomics', description: 'View and manage Genomics data'},
    ],
  },
  {
    title: 'Gmail API, v1',
    scopes: [
      {
        scope: 'https://mail.google.com/',
        description: 'Read, compose, send, and permanently delete all your email from Gmail',
      },
      {
        scope: 'https://www.googleapis.com/auth/gmail.addons.current.action.compose',
        description: 'Manage drafts and send emails when you interact with the add-on',
      },
      {
        scope: 'https://www.googleapis.com/auth/gmail.addons.current.message.action',
        description: 'View your email messages when you interact with the add-on',
      },
      {
        scope: 'https://www.googleapis.com/auth/gmail.addons.current.message.metadata',
        description: 'View your email message metadata when the add-on is running',
      },
      {
        scope: 'https://www.googleapis.com/auth/gmail.addons.current.message.readonly',
        description: 'View your email messages when the add-on is running',
      },
      {scope: 'https://www.googleapis.com/auth/gmail.compose', description: 'Manage drafts and send emails'},
      {scope: 'https://www.googleapis.com/auth/gmail.insert', description: 'Insert mail into your mailbox'},
      {scope: 'https://www.googleapis.com/auth/gmail.labels', description: 'Manage mailbox labels'},
      {
        scope: 'https://www.googleapis.com/auth/gmail.metadata',
        description: 'View your email message metadata such as labels and headers, but not the email body',
      },
      {scope: 'https://www.googleapis.com/auth/gmail.modify', description: 'View and modify but not delete your email'},
      {scope: 'https://www.googleapis.com/auth/gmail.readonly', description: 'View your email messages and settings'},
      {scope: 'https://www.googleapis.com/auth/gmail.send', description: 'Send email on your behalf'},
      {scope: 'https://www.googleapis.com/auth/gmail.settings.basic', description: 'Manage your basic mail settings'},
      {
        scope: 'https://www.googleapis.com/auth/gmail.settings.sharing',
        description: 'Manage your sensitive mail settings, including who can manage your mail',
      },
    ],
  },
  {
    title: 'Google Analytics API, v3',
    scopes: [
      {scope: 'https://www.googleapis.com/auth/analytics', description: 'View and manage your Google Analytics data'},
      {
        scope: 'https://www.googleapis.com/auth/analytics.edit',
        description: 'Edit Google Analytics management entities',
      },
      {
        scope: 'https://www.googleapis.com/auth/analytics.manage.users',
        description: 'Manage Google Analytics Account users by email address',
      },
      {
        scope: 'https://www.googleapis.com/auth/analytics.manage.users.readonly',
        description: 'View Google Analytics user permissions',
      },
      {
        scope: 'https://www.googleapis.com/auth/analytics.provision',
        description: 'Create a new Google Analytics account along with its default property and view',
      },
      {scope: 'https://www.googleapis.com/auth/analytics.readonly', description: 'View your Google Analytics data'},
      {
        scope: 'https://www.googleapis.com/auth/analytics.user.deletion',
        description: 'Manage Google Analytics user deletion requests',
      },
    ],
  },
  {
    title: 'Google Classroom API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/classroom.announcements',
        description: 'View and manage announcements in Google Classroom',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.announcements.readonly',
        description: 'View announcements in Google Classroom',
      },
      {scope: 'https://www.googleapis.com/auth/classroom.courses', description: 'Manage your Google Classroom classes'},
      {
        scope: 'https://www.googleapis.com/auth/classroom.courses.readonly',
        description: 'View your Google Classroom classes',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.coursework.me',
        description: 'Manage your course work and view your grades in Google Classroom',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.coursework.me.readonly',
        description: 'View your course work and grades in Google Classroom',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.coursework.students',
        description:
          'Manage course work and grades for students in the Google Classroom classes you teach and view the course work and grades for classes you administer',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.coursework.students.readonly',
        description: 'View course work and grades for students in the Google Classroom classes you teach or administer',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.courseworkmaterials',
        description: 'See, edit, and create classwork materials in Google Classroom',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.courseworkmaterials.readonly',
        description: 'See all classwork materials for your Google Classroom classes',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.guardianlinks.me.readonly',
        description: 'View your Google Classroom guardians',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.guardianlinks.students',
        description: 'View and manage guardians for students in your Google Classroom classes',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.guardianlinks.students.readonly',
        description: 'View guardians for students in your Google Classroom classes',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.profile.emails',
        description: 'View the email addresses of people in your classes',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.profile.photos',
        description: 'View the profile photos of people in your classes',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.push-notifications',
        description: 'Receive notifications about your Google Classroom data',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.rosters',
        description: 'Manage your Google Classroom class rosters',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.rosters.readonly',
        description: 'View your Google Classroom class rosters',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.student-submissions.me.readonly',
        description: 'View your course work and grades in Google Classroom',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.student-submissions.students.readonly',
        description: 'View course work and grades for students in the Google Classroom classes you teach or administer',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.topics',
        description: 'See, create, and edit topics in Google Classroom',
      },
      {
        scope: 'https://www.googleapis.com/auth/classroom.topics.readonly',
        description: 'View topics in Google Classroom',
      },
    ],
  },
  {
    title: 'Google Cloud Data Catalog API, v1beta1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Google Cloud Memorystore for Redis API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },

  {
    title: 'Google Docs API, v1',
    scopes: [
      {scope: 'https://www.googleapis.com/auth/documents', description: 'View and manage your Google Docs documents'},
      {scope: 'https://www.googleapis.com/auth/documents.readonly', description: 'View your Google Docs documents'},
      {
        scope: 'https://www.googleapis.com/auth/drive',
        description: 'See, edit, create, and delete all of your Google Drive files',
      },
      {
        scope: 'https://www.googleapis.com/auth/drive.file',
        description: 'View and manage Google Drive files and folders that you have opened or created with this app',
      },
      {
        scope: 'https://www.googleapis.com/auth/drive.readonly',
        description: 'See and download all your Google Drive files',
      },
    ],
  },
  {
    title: 'Google Identity Toolkit API, v3',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/firebase',
        description: 'View and administer all your Firebase data and settings',
      },
    ],
  },
  {
    title: 'Google OAuth2 API, v2',
    scopes: [
      {scope: 'https://www.googleapis.com/auth/userinfo.email', description: 'View your email address'},
      {
        scope: 'https://www.googleapis.com/auth/userinfo.profile',
        description: "See your personal info, including any personal info you've made publicly available",
      },
      {scope: 'openid', description: 'Associate you with your personal info on Google'},
    ],
  },
  {
    title: 'Google Play Android Developer API, v3',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/androidpublisher',
        description: 'View and manage your Google Play Developer account',
      },
    ],
  },
  {
    title: 'Google Play Custom App Publishing API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/androidpublisher',
        description: 'View and manage your Google Play Developer account',
      },
    ],
  },
  {
    title: 'Google Play EMM API, v1',
    scopes: [
      {scope: 'https://www.googleapis.com/auth/androidenterprise', description: 'Manage corporate Android devices'},
    ],
  },
  {
    title: 'Google Play Game Management, v1management',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/games',
        description: 'Create, edit, and delete your Google Play Games activity',
      },
    ],
  },
  {
    title: 'Google Play Game Services, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/drive.appdata',
        description: 'View and manage its own configuration data in your Google Drive',
      },
      {
        scope: 'https://www.googleapis.com/auth/games',
        description: 'Create, edit, and delete your Google Play Games activity',
      },
    ],
  },
  {
    title: 'Google Play Game Services Publishing API, v1configuration',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/androidpublisher',
        description: 'View and manage your Google Play Developer account',
      },
    ],
  },
  {
    title: 'Google Search Console API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/webmasters',
        description: 'View and manage Search Console data for your verified sites',
      },
      {
        scope: 'https://www.googleapis.com/auth/webmasters.readonly',
        description: 'View Search Console data for your verified sites',
      },
    ],
  },
  {
    title: 'Google Sheets API, v4',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/drive',
        description: 'See, edit, create, and delete all of your Google Drive files',
      },
      {
        scope: 'https://www.googleapis.com/auth/drive.file',
        description: 'View and manage Google Drive files and folders that you have opened or created with this app',
      },
      {
        scope: 'https://www.googleapis.com/auth/drive.readonly',
        description: 'See and download all your Google Drive files',
      },
      {
        scope: 'https://www.googleapis.com/auth/spreadsheets',
        description: 'See, edit, create, and delete your spreadsheets in Google Drive',
      },
      {scope: 'https://www.googleapis.com/auth/spreadsheets.readonly', description: 'View your Google Spreadsheets'},
    ],
  },
  {
    title: 'Google Sign-In',
    scopes: [
      {scope: 'profile', description: 'View your basic profile info'},
      {scope: 'email', description: 'View your email address'},
      {scope: 'openid', description: 'Authenticate using OpenID Connect'},
    ],
  },
  {
    title: 'Google Site Verification API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/siteverification',
        description: 'Manage the list of sites and domains you control',
      },
      {
        scope: 'https://www.googleapis.com/auth/siteverification.verify_only',
        description: 'Manage your new site verifications with Google',
      },
    ],
  },
  {
    title: 'Google Slides API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/drive',
        description: 'See, edit, create, and delete all of your Google Drive files',
      },
      {
        scope: 'https://www.googleapis.com/auth/drive.file',
        description: 'View and manage Google Drive files and folders that you have opened or created with this app',
      },
      {
        scope: 'https://www.googleapis.com/auth/drive.readonly',
        description: 'See and download all your Google Drive files',
      },
      {
        scope: 'https://www.googleapis.com/auth/presentations',
        description: 'View and manage your Google Slides presentations',
      },
      {
        scope: 'https://www.googleapis.com/auth/presentations.readonly',
        description: 'View your Google Slides presentations',
      },
      {
        scope: 'https://www.googleapis.com/auth/spreadsheets',
        description: 'See, edit, create, and delete your spreadsheets in Google Drive',
      },
      {scope: 'https://www.googleapis.com/auth/spreadsheets.readonly', description: 'View your Google Spreadsheets'},
    ],
  },
  {
    title: 'Google Workspace Alert Center API, v1beta1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/apps.alerts',
        description: "See and delete your domain's G Suite alerts, and send alert feedback",
      },
    ],
  },
  {
    title: 'Google Workspace Reseller API, v1',
    scopes: [
      {scope: 'https://www.googleapis.com/auth/apps.order', description: 'Manage users on your domain'},
      {scope: 'https://www.googleapis.com/auth/apps.order.readonly', description: 'Manage users on your domain'},
    ],
  },
  {
    title: 'Groups Migration API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/apps.groups.migration',
        description: 'Manage messages in groups on your domain',
      },
    ],
  },
  {
    title: 'Groups Settings API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/apps.groups.settings',
        description: 'View and manage the settings of a G Suite group',
      },
    ],
  },
  {title: 'HomeGraph API, v1', scopes: [{scope: 'https://www.googleapis.com/auth/homegraph', description: ''}]},
  {
    title: 'IAM Service Account Credentials API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Identity and Access Management (IAM) API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Indexing API, v3',
    scopes: [{scope: 'https://www.googleapis.com/auth/indexing', description: 'Submit data to Google for indexing'}],
  },
  {
    title: 'Kubernetes Engine API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Library Agent API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Managed Service for Microsoft Active Directory API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Manufacturer Center API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/manufacturercenter',
        description: 'Manage your product listings for Google Manufacturer Center',
      },
    ],
  },
  {
    title: 'Network Management API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'OS Config API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'OpenID Connect, 1.0',
    scopes: [
      {scope: 'openid', description: 'Authenticate using OpenID Connect'},
      {scope: 'profile', description: 'View your basic profile info'},
      {scope: 'email', description: 'View your email address'},
    ],
  },
  {
    title: 'PageSpeed Insights API, v5',
    scopes: [{scope: 'openid', description: 'Associate you with your personal info on Google'}],
  },
  {
    title: 'People API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/contacts',
        description: 'See, edit, download, and permanently delete your contacts',
      },
      {
        scope: 'https://www.googleapis.com/auth/contacts.other.readonly',
        description: 'See and download contact info automatically saved in your Other contacts',
      },
      {scope: 'https://www.googleapis.com/auth/contacts.readonly', description: 'See and download your contacts'},
      {
        scope: 'https://www.googleapis.com/auth/directory.readonly',
        description: "See and download your organization's GSuite directory",
      },
      {scope: 'https://www.googleapis.com/auth/user.addresses.read', description: 'View your street addresses'},
      {
        scope: 'https://www.googleapis.com/auth/user.birthday.read',
        description: 'See and download your exact date of birth',
      },
      {scope: 'https://www.googleapis.com/auth/user.emails.read', description: 'View your email addresses'},
      {scope: 'https://www.googleapis.com/auth/user.gender.read', description: 'See your gender'},
      {
        scope: 'https://www.googleapis.com/auth/user.organization.read',
        description: 'See your education, work history and org info',
      },
      {
        scope: 'https://www.googleapis.com/auth/user.phonenumbers.read',
        description: 'See and download your personal phone numbers',
      },
      {scope: 'https://www.googleapis.com/auth/userinfo.email', description: 'View your email address'},
      {
        scope: 'https://www.googleapis.com/auth/userinfo.profile',
        description: "See your personal info, including any personal info you've made publicly available",
      },
    ],
  },
  {
    title: 'Photos Library API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/photoslibrary',
        description: 'View and manage your Google Photos library',
      },
      {
        scope: 'https://www.googleapis.com/auth/photoslibrary.appendonly',
        description: 'Add to your Google Photos library',
      },
      {
        scope: 'https://www.googleapis.com/auth/photoslibrary.edit.appcreateddata',
        description:
          'Edit the info in your photos, videos, and albums created within this app, including titles, descriptions, and covers',
      },
      {scope: 'https://www.googleapis.com/auth/photoslibrary.readonly', description: 'View your Google Photos library'},
      {
        scope: 'https://www.googleapis.com/auth/photoslibrary.readonly.appcreateddata',
        description: 'Manage photos added by this app',
      },
      {
        scope: 'https://www.googleapis.com/auth/photoslibrary.sharing',
        description: 'Manage and add to shared albums on your behalf',
      },
    ],
  },

  {
    title: 'Policy Troubleshooter API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },

  {
    title: 'Recommender API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },

  {
    title: 'Remote Build Execution API, v2',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },

  {
    title: 'SAS Portal API, v1alpha1',
    scopes: [{scope: 'https://www.googleapis.com/auth/userinfo.email', description: 'View your email address'}],
  },

  {
    title: 'SAS Portal API (Testing), v1alpha1',
    scopes: [{scope: 'https://www.googleapis.com/auth/userinfo.email', description: 'View your email address'}],
  },

  {
    title: 'Search Ads 360 API, v2',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/doubleclicksearch',
        description: 'View and manage your advertising data in DoubleClick Search',
      },
    ],
  },

  {
    title: 'Search Console API, v3',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/webmasters',
        description: 'View and manage Search Console data for your verified sites',
      },
      {
        scope: 'https://www.googleapis.com/auth/webmasters.readonly',
        description: 'View Search Console data for your verified sites',
      },
    ],
  },

  {
    title: 'Secret Manager API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },

  {
    title: 'Security Command Center API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },

  {
    title: 'Service Consumer Management API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Service Management API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform.read-only',
        description: 'View your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/service.management',
        description: 'Manage your Google API service configuration',
      },
      {
        scope: 'https://www.googleapis.com/auth/service.management.readonly',
        description: 'View your Google API service configuration',
      },
    ],
  },
  {
    title: 'Service Networking API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/service.management',
        description: 'Manage your Google API service configuration',
      },
    ],
  },
  {
    title: 'Service Usage API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform.read-only',
        description: 'View your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/service.management',
        description: 'Manage your Google API service configuration',
      },
    ],
  },
  {
    title: 'Stackdriver Profiler API, v2',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
      {
        scope: 'https://www.googleapis.com/auth/monitoring',
        description: 'View and write monitoring data for all of your Google and third-party Cloud and API projects',
      },
      {
        scope: 'https://www.googleapis.com/auth/monitoring.write',
        description: 'Publish metric data to your Google Cloud projects',
      },
    ],
  },
  {
    title: 'Storage Transfer API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'Street View Publish API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/streetviewpublish',
        description: 'Publish and manage your 360 photos on Google Street View',
      },
    ],
  },
  {
    title: 'Tag Manager API, v2',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/tagmanager.delete.containers',
        description: 'Delete your Google Tag Manager containers',
      },
      {
        scope: 'https://www.googleapis.com/auth/tagmanager.edit.containers',
        description:
          'Manage your Google Tag Manager container and its subcomponents, excluding versioning and publishing',
      },
      {
        scope: 'https://www.googleapis.com/auth/tagmanager.edit.containerversions',
        description: 'Manage your Google Tag Manager container versions',
      },
      {
        scope: 'https://www.googleapis.com/auth/tagmanager.manage.accounts',
        description: 'View and manage your Google Tag Manager accounts',
      },
      {
        scope: 'https://www.googleapis.com/auth/tagmanager.manage.users',
        description: 'Manage user permissions of your Google Tag Manager account and container',
      },
      {
        scope: 'https://www.googleapis.com/auth/tagmanager.publish',
        description: 'Publish your Google Tag Manager container versions',
      },
      {
        scope: 'https://www.googleapis.com/auth/tagmanager.readonly',
        description: 'View your Google Tag Manager container and its subcomponents',
      },
    ],
  },

  {
    title: 'Tasks API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/tasks',
        description: 'Create, edit, organize, and delete all your tasks',
      },
      {scope: 'https://www.googleapis.com/auth/tasks.readonly', description: 'View your tasks'},
    ],
  },

  {
    title: 'Web Security Scanner API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/cloud-platform',
        description: 'View and manage your data across Google Cloud Platform services',
      },
    ],
  },
  {
    title: 'YouTube Analytics API, v2',
    scopes: [
      {scope: 'https://www.googleapis.com/auth/youtube', description: 'Manage your YouTube account'},
      {scope: 'https://www.googleapis.com/auth/youtube.readonly', description: 'View your YouTube account'},
      {
        scope: 'https://www.googleapis.com/auth/youtubepartner',
        description: 'View and manage your assets and associated content on YouTube',
      },
      {
        scope: 'https://www.googleapis.com/auth/yt-analytics-monetary.readonly',
        description: 'View monetary and non-monetary YouTube Analytics reports for your YouTube content',
      },
      {
        scope: 'https://www.googleapis.com/auth/yt-analytics.readonly',
        description: 'View YouTube Analytics reports for your YouTube content',
      },
    ],
  },
  {
    title: 'YouTube Data API, v3',
    scopes: [
      {scope: 'https://www.googleapis.com/auth/youtube', description: 'Manage your YouTube account'},
      {
        scope: 'https://www.googleapis.com/auth/youtube.channel-memberships.creator',
        description:
          'See a list of your current active channel members, their current level, and when they became a member',
      },
      {
        scope: 'https://www.googleapis.com/auth/youtube.force-ssl',
        description: 'See, edit, and permanently delete your YouTube videos, ratings, comments and captions',
      },
      {scope: 'https://www.googleapis.com/auth/youtube.readonly', description: 'View your YouTube account'},
      {scope: 'https://www.googleapis.com/auth/youtube.upload', description: 'Manage your YouTube videos'},
      {
        scope: 'https://www.googleapis.com/auth/youtubepartner',
        description: 'View and manage your assets and associated content on YouTube',
      },
      {
        scope: 'https://www.googleapis.com/auth/youtubepartner-channel-audit',
        description:
          'View private information of your YouTube channel relevant during the audit process with a YouTube partner',
      },
    ],
  },

  {
    title: 'YouTube Reporting API, v1',
    scopes: [
      {
        scope: 'https://www.googleapis.com/auth/yt-analytics-monetary.readonly',
        description: 'View monetary and non-monetary YouTube Analytics reports for your YouTube content',
      },
      {
        scope: 'https://www.googleapis.com/auth/yt-analytics.readonly',
        description: 'View YouTube Analytics reports for your YouTube content',
      },
    ],
  },
];
*/

export const googleScopesAsObject = {
  'https://www.googleapis.com/auth/cloud-platform': [
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'AI Platform Training & Prediction API, v1',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Access Approval API, v1'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Access Context Manager API, v1',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Admin Directory API, v1'},
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Apigee API, v1'},
    {description: 'View and manage your data across Google Cloud Platform services', group: 'App Engine Admin API, v1'},
    {description: 'View and manage your data across Google Cloud Platform services', group: 'BigQuery API, v2'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'BigQuery Connection API, v1beta1',
    },
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'BigQuery Data Transfer API, v1',
    },
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'BigQuery Reservation API, v1',
    },
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Binary Authorization API, v1',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Asset API, v1'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Cloud Bigtable Admin API, v2',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Billing API, v1'},
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Build API, v1'},
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Composer API, v1'},
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud DNS API, v1'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Cloud Data Loss Prevention (DLP) API, v2',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Dataproc API, v1'},
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Datastore API, v1'},
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Debugger API, v2'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Cloud Deployment Manager V2 API, v2',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Filestore API, v1'},
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Firestore API, v1'},
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Functions API, v1'},
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Healthcare API, v1'},
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Identity API, v1'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Cloud Identity-Aware Proxy API, v1',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud IoT API, v1'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Cloud Key Management Service (KMS) API, v1',
    },
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Cloud Life Sciences API, v2beta',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Logging API, v2'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Cloud Memorystore for Memcached API, v1beta2',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Monitoring API, v3'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Cloud Natural Language API, v1',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud OS Login API, v1'},
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Pub/Sub API, v1'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Cloud Resource Manager API, v2',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Run Admin API, v1'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Cloud Runtime Configuration API, v1',
    },
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Cloud SQL Admin API, v1beta4',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Scheduler API, v1'},
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Shell API, v1'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Cloud Source Repositories API, v1',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Spanner API, v1'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Cloud Speech-to-Text API, v1',
    },
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Cloud Storage JSON API, v1',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud TPU API, v1'},
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Tasks API, v2'},
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Testing API, v1'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Cloud Text-to-Speech API, v1',
    },
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Cloud Tool Results API, v1beta3',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Trace API, v2'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Cloud Translation API, v3',
    },
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Cloud Video Intelligence API, v1',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Cloud Vision API, v1'},
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Compute Engine API, v1'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Container Analysis API, v1beta1',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Dataflow API, v1b3'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Error Reporting API, v1beta1',
    },
    {
      description: 'See, edit, configure, and delete your Google Cloud Platform data',
      group: 'Firebase Cloud Messaging API, v1',
    },
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Firebase Management API, v1beta1',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Firebase Rules API, v1'},
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Genomics API, v1'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Google Cloud Data Catalog API, v1beta1',
    },
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Google Cloud Memorystore for Redis API, v1',
    },
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Google Identity Toolkit API, v3',
    },
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'IAM Service Account Credentials API, v1',
    },
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Identity and Access Management (IAM) API, v1',
    },
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Kubernetes Engine API, v1',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Library Agent API, v1'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Managed Service for Microsoft Active Directory API, v1',
    },
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Network Management API, v1',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'OS Config API, v1'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Policy Troubleshooter API, v1',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Recommender API, v1'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Remote Build Execution API, v2',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Secret Manager API, v1'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Security Command Center API, v1',
    },
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Service Consumer Management API, v1',
    },
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Service Management API, v1',
    },
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Service Networking API, v1',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Service Usage API, v1'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Stackdriver Profiler API, v2',
    },
    {description: 'View and manage your data across Google Cloud Platform services', group: 'Storage Transfer API, v1'},
    {
      description: 'View and manage your data across Google Cloud Platform services',
      group: 'Web Security Scanner API, v1',
    },
  ],
  'https://www.googleapis.com/auth/cloud-platform.read-only': [
    {
      description: 'View your data across Google Cloud Platform services',
      group: 'AI Platform Training & Prediction API, v1',
    },
    {description: 'View your data across Google Cloud Platform services', group: 'App Engine Admin API, v1'},
    {description: 'View your data across Google Cloud Platform services', group: 'BigQuery API, v2'},
    {description: 'View your data across Google Cloud Platform services', group: 'BigQuery Data Transfer API, v1'},
    {description: 'View your data across Google Cloud Platform services', group: 'Cloud Bigtable Admin API, v2'},
    {description: 'View your data across Google Cloud Platform services', group: 'Cloud DNS API, v1'},
    {description: 'View your data across Google Cloud Platform services', group: 'Cloud Deployment Manager V2 API, v2'},
    {description: 'View your data across Google Cloud Platform services', group: 'Cloud Logging API, v2'},
    {description: 'View your data across Google Cloud Platform services', group: 'Cloud Resource Manager API, v2'},
    {description: 'View your data across Google Cloud Platform services', group: 'Cloud Storage JSON API, v1'},
    {description: 'View your data across Google Cloud Platform services', group: 'Cloud Testing API, v1'},
    {description: 'View your data across Google Cloud Platform services', group: 'Firebase Management API, v1beta1'},
    {description: 'View your data across Google Cloud Platform services', group: 'Service Management API, v1'},
    {description: 'View your data across Google Cloud Platform services', group: 'Service Usage API, v1'},
  ],
  'https://www.googleapis.com/auth/adexchange.buyer': [
    {description: 'Manage your Ad Exchange buyer account configuration', group: 'Ad Exchange Buyer API, v1.4'},
    {description: 'Manage your Ad Exchange buyer account configuration', group: 'Ad Exchange Buyer API II, v2beta1'},
  ],
  'https://www.googleapis.com/auth/admob.readonly': [{description: 'See your AdMob data', group: 'AdMob API, v1'}],
  'https://www.googleapis.com/auth/admob.report': [{description: 'See your AdMob data', group: 'AdMob API, v1'}],
  'https://www.googleapis.com/auth/adsensehost': [
    {description: 'View and manage your AdSense host data and associated accounts', group: 'AdSense Host API, v4.1'},
  ],
  'https://www.googleapis.com/auth/adsense': [
    {description: 'View and manage your AdSense data', group: 'AdSense Management API, v1.4'},
  ],
  'https://www.googleapis.com/auth/adsense.readonly': [
    {description: 'View your AdSense data', group: 'AdSense Management API, v1.4'},
  ],
  'https://www.googleapis.com/auth/admin.datatransfer': [
    {
      description: 'View and manage data transfers between users in your organization',
      group: 'Admin Data Transfer API, v1',
    },
  ],
  'https://www.googleapis.com/auth/admin.datatransfer.readonly': [
    {description: 'View data transfers between users in your organization', group: 'Admin Data Transfer API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.customer': [
    {description: 'View and manage customer related information', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.customer.readonly': [
    {description: 'View customer related information', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.device.chromeos': [
    {description: "View and manage your Chrome OS devices' metadata", group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.device.chromeos.readonly': [
    {description: "View your Chrome OS devices' metadata", group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.device.mobile': [
    {description: "View and manage your mobile devices' metadata", group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.device.mobile.action': [
    {description: 'Manage your mobile devices by performing administrative tasks', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.device.mobile.readonly': [
    {description: "View your mobile devices' metadata", group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.domain': [
    {description: 'View and manage the provisioning of domains for your customers', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.domain.readonly': [
    {description: 'View domains related to your customers', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.group': [
    {description: 'View and manage the provisioning of groups on your domain', group: 'Admin Directory API, v1'},
    {description: 'View and manage the provisioning of groups on your domain', group: 'Apps Script API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.group.member': [
    {description: 'View and manage group subscriptions on your domain', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.group.member.readonly': [
    {description: 'View group subscriptions on your domain', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.group.readonly': [
    {description: 'View groups on your domain', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.orgunit': [
    {description: 'View and manage organization units on your domain', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.orgunit.readonly': [
    {description: 'View organization units on your domain', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.resource.calendar': [
    {
      description: 'View and manage the provisioning of calendar resources on your domain',
      group: 'Admin Directory API, v1',
    },
  ],
  'https://www.googleapis.com/auth/admin.directory.resource.calendar.readonly': [
    {description: 'View calendar resources on your domain', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.rolemanagement': [
    {description: 'Manage delegated admin roles for your domain', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.rolemanagement.readonly': [
    {description: 'View delegated admin roles for your domain', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.user': [
    {description: 'View and manage the provisioning of users on your domain', group: 'Admin Directory API, v1'},
    {description: 'View and manage the provisioning of users on your domain', group: 'Apps Script API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.user.alias': [
    {description: 'View and manage user aliases on your domain', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.user.alias.readonly': [
    {description: 'View user aliases on your domain', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.user.readonly': [
    {description: 'View users on your domain', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.user.security': [
    {description: 'Manage data access permissions for users on your domain', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.userschema': [
    {description: 'View and manage the provisioning of user schemas on your domain', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.directory.userschema.readonly': [
    {description: 'View user schemas on your domain', group: 'Admin Directory API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.reports.audit.readonly': [
    {description: 'View audit reports for your G Suite domain', group: 'Admin Reports API, v1'},
  ],
  'https://www.googleapis.com/auth/admin.reports.usage.readonly': [
    {description: 'View usage reports for your G Suite domain', group: 'Admin Reports API, v1'},
  ],
  'https://www.googleapis.com/auth/analytics': [
    {description: 'View and manage your Google Analytics data', group: 'Analytics Reporting API, v4'},
    {description: 'View and manage your Google Analytics data', group: 'Google Analytics API, v3'},
  ],
  'https://www.googleapis.com/auth/analytics.readonly': [
    {description: 'See and download your Google Analytics data', group: 'Analytics Reporting API, v4'},
    {description: 'View your Google Analytics data', group: 'Google Analytics API, v3'},
  ],
  'https://www.googleapis.com/auth/androidmanagement': [
    {description: 'Manage Android devices and apps for your customers', group: 'Android Management API, v1'},
  ],
  'https://www.googleapis.com/auth/appengine.admin': [
    {description: 'View and manage your applications deployed on Google App Engine', group: 'App Engine Admin API, v1'},
  ],
  'https://mail.google.com/': [
    {
      description: 'Read, compose, send, and permanently delete all your email from Gmail',
      group: 'Apps Script API, v1',
    },
    {description: 'Read, compose, send, and permanently delete all your email from Gmail', group: 'Gmail API, v1'},
  ],
  'https://www.google.com/calendar/feeds': [
    {
      description: 'See, edit, share, and permanently delete all the calendars you can access using Google Calendar',
      group: 'Apps Script API, v1',
    },
  ],
  'https://www.google.com/m8/feeds': [
    {description: 'See, edit, download, and permanently delete your contacts', group: 'Apps Script API, v1'},
  ],
  'https://www.googleapis.com/auth/documents': [
    {description: 'View and manage your Google Docs documents', group: 'Apps Script API, v1'},
    {description: 'View and manage your Google Docs documents', group: 'Google Docs API, v1'},
  ],
  'https://www.googleapis.com/auth/drive': [
    {description: 'See, edit, create, and delete all of your Google Drive files', group: 'Apps Script API, v1'},
    {description: 'See, edit, create, and delete all of your Google Drive files', group: 'Drive API, v3'},
    {description: 'See, edit, create, and delete all of your Google Drive files', group: 'Google Docs API, v1'},
    {description: 'See, edit, create, and delete all of your Google Drive files', group: 'Google Sheets API, v4'},
    {description: 'See, edit, create, and delete all of your Google Drive files', group: 'Google Slides API, v1'},
  ],
  'https://www.googleapis.com/auth/forms': [
    {description: 'View and manage your forms in Google Drive', group: 'Apps Script API, v1'},
  ],
  'https://www.googleapis.com/auth/forms.currentonly': [
    {description: 'View and manage forms that this application has been installed in', group: 'Apps Script API, v1'},
  ],
  'https://www.googleapis.com/auth/groups': [
    {description: 'View and manage your Google Groups', group: 'Apps Script API, v1'},
  ],
  'https://www.googleapis.com/auth/script.deployments': [
    {description: 'Create and update Google Apps Script deployments', group: 'Apps Script API, v1'},
  ],
  'https://www.googleapis.com/auth/script.deployments.readonly': [
    {description: 'View Google Apps Script deployments', group: 'Apps Script API, v1'},
  ],
  'https://www.googleapis.com/auth/script.metrics': [
    {description: "View Google Apps Script project's metrics", group: 'Apps Script API, v1'},
  ],
  'https://www.googleapis.com/auth/script.processes': [
    {description: 'View Google Apps Script processes', group: 'Apps Script API, v1'},
  ],
  'https://www.googleapis.com/auth/script.projects': [
    {description: 'Create and update Google Apps Script projects', group: 'Apps Script API, v1'},
  ],
  'https://www.googleapis.com/auth/script.projects.readonly': [
    {description: 'View Google Apps Script projects', group: 'Apps Script API, v1'},
  ],
  'https://www.googleapis.com/auth/spreadsheets': [
    {description: 'See, edit, create, and delete your spreadsheets in Google Drive', group: 'Apps Script API, v1'},
    {description: 'See, edit, create, and delete your spreadsheets in Google Drive', group: 'Google Sheets API, v4'},
    {description: 'See, edit, create, and delete your spreadsheets in Google Drive', group: 'Google Slides API, v1'},
  ],
  'https://www.googleapis.com/auth/userinfo.email': [
    {description: 'View your email address', group: 'Apps Script API, v1'},
    {description: 'View your email address', group: 'Dataflow API, v1b3'},
    {description: 'View your email address', group: 'Fact Check Tools API, v1alpha1'},
    {description: 'View your email address', group: 'Google OAuth2 API, v2'},
    {description: 'View your email address', group: 'People API, v1'},
    {description: 'View your email address', group: 'SAS Portal API, v1alpha1'},
    {description: 'View your email address', group: 'SAS Portal API (Testing), v1alpha1'},
  ],
  'https://www.googleapis.com/auth/bigquery': [
    {description: 'View and manage your data in Google BigQuery', group: 'BigQuery API, v2'},
    {description: 'View and manage your data in Google BigQuery', group: 'BigQuery Connection API, v1beta1'},
    {description: 'View and manage your data in Google BigQuery', group: 'BigQuery Data Transfer API, v1'},
    {description: 'View and manage your data in Google BigQuery', group: 'BigQuery Reservation API, v1'},
  ],
  'https://www.googleapis.com/auth/bigquery.insertdata': [
    {description: 'Insert data into Google BigQuery', group: 'BigQuery API, v2'},
  ],
  'https://www.googleapis.com/auth/bigquery.readonly': [
    {description: 'View your data in Google BigQuery', group: 'BigQuery API, v2'},
    {description: 'View your data in Google BigQuery', group: 'BigQuery Data Transfer API, v1'},
  ],
  'https://www.googleapis.com/auth/devstorage.full_control': [
    {description: 'Manage your data and permissions in Google Cloud Storage', group: 'BigQuery API, v2'},
    {description: 'Manage your data and permissions in Google Cloud Storage', group: 'Cloud Storage JSON API, v1'},
    {description: 'Manage your data and permissions in Google Cloud Storage', group: 'Compute Engine API, v1'},
  ],
  'https://www.googleapis.com/auth/devstorage.read_only': [
    {description: 'View your data in Google Cloud Storage', group: 'BigQuery API, v2'},
    {description: 'View your data in Google Cloud Storage', group: 'Cloud Storage JSON API, v1'},
    {description: 'View your data in Google Cloud Storage', group: 'Compute Engine API, v1'},
  ],
  'https://www.googleapis.com/auth/devstorage.read_write': [
    {description: 'Manage your data in Google Cloud Storage', group: 'BigQuery API, v2'},
    {description: 'Manage your data in Google Cloud Storage', group: 'Cloud Storage JSON API, v1'},
    {description: 'Manage your data in Google Cloud Storage', group: 'Compute Engine API, v1'},
  ],
  'https://www.googleapis.com/auth/blogger': [{description: 'Manage your Blogger account', group: 'Blogger API, v3'}],
  'https://www.googleapis.com/auth/blogger.readonly': [
    {description: 'View your Blogger account', group: 'Blogger API, v3'},
  ],
  'https://www.googleapis.com/auth/books': [{description: 'Manage your books', group: 'Books API, v1'}],
  'https://www.googleapis.com/auth/calendar': [
    {
      description: 'See, edit, share, and permanently delete all the calendars you can access using Google Calendar',
      group: 'Calendar API, v3',
    },
  ],
  'https://www.googleapis.com/auth/calendar.events': [
    {description: 'View and edit events on all your calendars', group: 'Calendar API, v3'},
  ],
  'https://www.googleapis.com/auth/calendar.events.readonly': [
    {description: 'View events on all your calendars', group: 'Calendar API, v3'},
  ],
  'https://www.googleapis.com/auth/calendar.readonly': [
    {description: 'See and download any calendar you can access using your Google Calendar', group: 'Calendar API, v3'},
  ],
  'https://www.googleapis.com/auth/calendar.settings.readonly': [
    {description: 'View your Calendar settings', group: 'Calendar API, v3'},
  ],
  'https://www.googleapis.com/auth/ddmconversions': [
    {description: 'Manage DoubleClick Digital Marketing conversions', group: 'Campaign Manager 360 API, v3.4'},
  ],
  'https://www.googleapis.com/auth/dfareporting': [
    {description: 'View and manage DoubleClick for Advertisers reports', group: 'Campaign Manager 360 API, v3.4'},
  ],
  'https://www.googleapis.com/auth/dfatrafficking': [
    {
      description: "View and manage your DoubleClick Campaign Manager's (DCM) display ad campaigns",
      group: 'Campaign Manager 360 API, v3.4',
    },
  ],
  'https://www.googleapis.com/auth/verifiedaccess': [
    {description: 'Verify your enterprise credentials', group: 'Chrome Verified Access API, v1'},
  ],
  'https://www.googleapis.com/auth/bigtable.admin': [
    {description: 'Administer your Cloud Bigtable tables and clusters', group: 'Cloud Bigtable Admin API, v2'},
  ],
  'https://www.googleapis.com/auth/bigtable.admin.cluster': [
    {description: 'Administer your Cloud Bigtable clusters', group: 'Cloud Bigtable Admin API, v2'},
  ],
  'https://www.googleapis.com/auth/bigtable.admin.instance': [
    {description: 'Administer your Cloud Bigtable clusters', group: 'Cloud Bigtable Admin API, v2'},
  ],
  'https://www.googleapis.com/auth/bigtable.admin.table': [
    {description: 'Administer your Cloud Bigtable tables', group: 'Cloud Bigtable Admin API, v2'},
  ],
  'https://www.googleapis.com/auth/cloud-bigtable.admin': [
    {description: 'Administer your Cloud Bigtable tables and clusters', group: 'Cloud Bigtable Admin API, v2'},
  ],
  'https://www.googleapis.com/auth/cloud-bigtable.admin.cluster': [
    {description: 'Administer your Cloud Bigtable clusters', group: 'Cloud Bigtable Admin API, v2'},
  ],
  'https://www.googleapis.com/auth/cloud-bigtable.admin.table': [
    {description: 'Administer your Cloud Bigtable tables', group: 'Cloud Bigtable Admin API, v2'},
  ],
  'https://www.googleapis.com/auth/cloud-billing': [
    {description: 'View and manage your Google Cloud Platform billing accounts', group: 'Cloud Billing API, v1'},
  ],
  'https://www.googleapis.com/auth/cloud-billing.readonly': [
    {description: 'View your Google Cloud Platform billing accounts', group: 'Cloud Billing API, v1'},
  ],
  'https://www.googleapis.com/auth/ndev.clouddns.readonly': [
    {description: 'View your DNS records hosted by Google Cloud DNS', group: 'Cloud DNS API, v1'},
  ],
  'https://www.googleapis.com/auth/ndev.clouddns.readwrite': [
    {description: 'View and manage your DNS records hosted by Google Cloud DNS', group: 'Cloud DNS API, v1'},
  ],
  'https://www.googleapis.com/auth/datastore': [
    {description: 'View and manage your Google Cloud Datastore data', group: 'Cloud Datastore API, v1'},
    {description: 'View and manage your Google Cloud Datastore data', group: 'Cloud Firestore API, v1'},
  ],
  'https://www.googleapis.com/auth/cloud_debugger': [
    {description: 'Use Stackdriver Debugger', group: 'Cloud Debugger API, v2'},
  ],
  'https://www.googleapis.com/auth/ndev.cloudman': [
    {
      description: 'View and manage your Google Cloud Platform management resources and deployment status information',
      group: 'Cloud Deployment Manager V2 API, v2',
    },
  ],
  'https://www.googleapis.com/auth/ndev.cloudman.readonly': [
    {
      description: 'View your Google Cloud Platform management resources and deployment status information',
      group: 'Cloud Deployment Manager V2 API, v2',
    },
  ],
  'https://www.googleapis.com/auth/cloud-identity.devices.lookup': [
    {description: 'See your device details', group: 'Cloud Identity API, v1'},
  ],
  'https://www.googleapis.com/auth/cloud-identity.groups': [
    {
      description:
        'See, change, create, and delete any of the Cloud Identity Groups that you can access, including the members of each group',
      group: 'Cloud Identity API, v1',
    },
  ],
  'https://www.googleapis.com/auth/cloud-identity.groups.readonly': [
    {
      description: 'See any Cloud Identity Groups that you can access, including group members and their emails',
      group: 'Cloud Identity API, v1',
    },
  ],
  'https://www.googleapis.com/auth/cloudiot': [
    {description: 'Register and manage devices in the Google Cloud IoT service', group: 'Cloud IoT API, v1'},
  ],
  'https://www.googleapis.com/auth/cloudkms': [
    {
      description: 'View and manage your keys and secrets stored in Cloud Key Management Service',
      group: 'Cloud Key Management Service (KMS) API, v1',
    },
  ],
  'https://www.googleapis.com/auth/logging.admin': [
    {description: 'Administrate log data for your projects', group: 'Cloud Logging API, v2'},
  ],
  'https://www.googleapis.com/auth/logging.read': [
    {description: 'View log data for your projects', group: 'Cloud Logging API, v2'},
  ],
  'https://www.googleapis.com/auth/logging.write': [
    {description: 'Submit log data for your projects', group: 'Cloud Logging API, v2'},
  ],
  'https://www.googleapis.com/auth/monitoring': [
    {
      description: 'View and write monitoring data for all of your Google and third-party Cloud and API projects',
      group: 'Cloud Monitoring API, v3',
    },
    {
      description: 'View and write monitoring data for all of your Google and third-party Cloud and API projects',
      group: 'Stackdriver Profiler API, v2',
    },
  ],
  'https://www.googleapis.com/auth/monitoring.read': [
    {
      description: 'View monitoring data for all of your Google Cloud and third-party projects',
      group: 'Cloud Monitoring API, v3',
    },
  ],
  'https://www.googleapis.com/auth/monitoring.write': [
    {description: 'Publish metric data to your Google Cloud projects', group: 'Cloud Monitoring API, v3'},
    {description: 'Publish metric data to your Google Cloud projects', group: 'Stackdriver Profiler API, v2'},
  ],
  'https://www.googleapis.com/auth/cloud-language': [
    {
      description: 'Apply machine learning models to reveal the structure and meaning of text',
      group: 'Cloud Natural Language API, v1',
    },
  ],
  'https://www.googleapis.com/auth/compute': [
    {description: 'View and manage your Google Compute Engine resources', group: 'Cloud OS Login API, v1'},
    {description: 'View and manage your Google Compute Engine resources', group: 'Compute Engine API, v1'},
    {description: 'View and manage your Google Compute Engine resources', group: 'Dataflow API, v1b3'},
  ],
  'https://www.googleapis.com/auth/pubsub': [
    {description: 'View and manage Pub/Sub topics and subscriptions', group: 'Cloud Pub/Sub API, v1'},
  ],
  'https://www.googleapis.com/auth/cloudruntimeconfig': [
    {
      description: "Manage your Google Cloud Platform services' runtime configuration",
      group: 'Cloud Runtime Configuration API, v1',
    },
  ],
  'https://www.googleapis.com/auth/sqlservice.admin': [
    {description: 'Manage your Google SQL Service instances', group: 'Cloud SQL Admin API, v1beta4'},
  ],
  'https://www.googleapis.com/auth/cloud_search': [
    {description: "Index and serve your organization's data with Cloud Search", group: 'Cloud Search API, v1'},
  ],
  'https://www.googleapis.com/auth/cloud_search.debug': [
    {description: "Index and serve your organization's data with Cloud Search", group: 'Cloud Search API, v1'},
  ],
  'https://www.googleapis.com/auth/cloud_search.indexing': [
    {description: "Index and serve your organization's data with Cloud Search", group: 'Cloud Search API, v1'},
  ],
  'https://www.googleapis.com/auth/cloud_search.query': [
    {description: "Search your organization's data in the Cloud Search index", group: 'Cloud Search API, v1'},
  ],
  'https://www.googleapis.com/auth/cloud_search.settings': [
    {description: "Index and serve your organization's data with Cloud Search", group: 'Cloud Search API, v1'},
  ],
  'https://www.googleapis.com/auth/cloud_search.settings.indexing': [
    {description: "Index and serve your organization's data with Cloud Search", group: 'Cloud Search API, v1'},
  ],
  'https://www.googleapis.com/auth/cloud_search.settings.query': [
    {description: "Index and serve your organization's data with Cloud Search", group: 'Cloud Search API, v1'},
  ],
  'https://www.googleapis.com/auth/cloud_search.stats': [
    {description: "Index and serve your organization's data with Cloud Search", group: 'Cloud Search API, v1'},
  ],
  'https://www.googleapis.com/auth/cloud_search.stats.indexing': [
    {description: "Index and serve your organization's data with Cloud Search", group: 'Cloud Search API, v1'},
  ],
  'https://www.googleapis.com/auth/source.full_control': [
    {description: 'Manage your source code repositories', group: 'Cloud Source Repositories API, v1'},
  ],
  'https://www.googleapis.com/auth/source.read_only': [
    {description: 'View the contents of your source code repositories', group: 'Cloud Source Repositories API, v1'},
  ],
  'https://www.googleapis.com/auth/source.read_write': [
    {description: 'Manage the contents of your source code repositories', group: 'Cloud Source Repositories API, v1'},
  ],
  'https://www.googleapis.com/auth/spanner.admin': [
    {description: 'Administer your Spanner databases', group: 'Cloud Spanner API, v1'},
  ],
  'https://www.googleapis.com/auth/spanner.data': [
    {description: 'View and manage the contents of your Spanner databases', group: 'Cloud Spanner API, v1'},
  ],
  'https://www.googleapis.com/auth/trace.append': [
    {description: 'Write Trace data for a project or application', group: 'Cloud Trace API, v2'},
  ],
  'https://www.googleapis.com/auth/cloud-translation': [
    {
      description: 'Translate text from one language to another using Google Translate',
      group: 'Cloud Translation API, v3',
    },
  ],
  'https://www.googleapis.com/auth/cloud-vision': [
    {description: 'Apply machine learning models to understand and label images', group: 'Cloud Vision API, v1'},
  ],
  'https://www.googleapis.com/auth/compute.readonly': [
    {description: 'View your Google Compute Engine resources', group: 'Compute Engine API, v1'},
    {description: 'View your Google Compute Engine resources', group: 'Dataflow API, v1b3'},
  ],
  'https://www.googleapis.com/auth/content': [
    {
      description: 'Manage your product listings and accounts for Google Shopping',
      group: 'Content API for Shopping, v2.1',
    },
  ],
  'https://www.googleapis.com/auth/display-video': [
    {
      description: 'Create, see, edit, and permanently delete your Display & Video 360 entities and reports',
      group: 'Display & Video 360 API, v1',
    },
  ],
  'https://www.googleapis.com/auth/display-video-mediaplanning': [
    {
      description: 'Create, see, and edit Display & Video 360 Campaign entities and see billing invoices',
      group: 'Display & Video 360 API, v1',
    },
  ],
  'https://www.googleapis.com/auth/display-video-user-management': [
    {description: '', group: 'Display & Video 360 API, v1'},
  ],
  'https://www.googleapis.com/auth/doubleclickbidmanager': [
    {description: 'View and manage your reports in DoubleClick Bid Manager', group: 'Display & Video 360 API, v1'},
    {
      description: 'View and manage your reports in DoubleClick Bid Manager',
      group: 'DoubleClick Bid Manager API, v1.1',
    },
  ],
  'https://www.googleapis.com/auth/drive.appdata': [
    {description: 'View and manage its own configuration data in your Google Drive', group: 'Drive API, v3'},
    {
      description: 'View and manage its own configuration data in your Google Drive',
      group: 'Google Play Game Services, v1',
    },
  ],
  'https://www.googleapis.com/auth/drive.file': [
    {
      description: 'View and manage Google Drive files and folders that you have opened or created with this app',
      group: 'Drive API, v3',
    },
    {
      description: 'View and manage Google Drive files and folders that you have opened or created with this app',
      group: 'Google Docs API, v1',
    },
    {
      description: 'View and manage Google Drive files and folders that you have opened or created with this app',
      group: 'Google Sheets API, v4',
    },
    {
      description: 'View and manage Google Drive files and folders that you have opened or created with this app',
      group: 'Google Slides API, v1',
    },
  ],
  'https://www.googleapis.com/auth/drive.install': [
    {
      group: 'Drive API,',
      description: 'Special scope used to let users approve installation of an app, and scope needs to be requested.',
    },
  ],
  'https://www.googleapis.com/auth/drive.apps.readonly': [
    {
      group: 'Drive API,',
      description: 'Allows read-only access to installed apps.',
    },
  ],
  'https://www.googleapis.com/auth/drive.metadata': [
    {description: 'View and manage metadata of files in your Google Drive', group: 'Drive API, v3'},
  ],
  'https://www.googleapis.com/auth/drive.metadata.readonly': [
    {description: 'View metadata for files in your Google Drive', group: 'Drive API, v3'},
  ],
  'https://www.googleapis.com/auth/drive.photos.readonly': [
    {description: 'View the photos, videos and albums in your Google Photos', group: 'Drive API, v3'},
  ],
  'https://www.googleapis.com/auth/drive.readonly': [
    {description: 'See and download all your Google Drive files', group: 'Drive API, v3'},
    {description: 'See and download all your Google Drive files', group: 'Google Docs API, v1'},
    {description: 'See and download all your Google Drive files', group: 'Google Sheets API, v4'},
    {description: 'See and download all your Google Drive files', group: 'Google Slides API, v1'},
  ],
  'https://www.googleapis.com/auth/drive.scripts': [
    {description: "Modify your Google Apps Script scripts' behavior", group: 'Drive API, v3'},
  ],
  'https://www.googleapis.com/auth/drive.activity': [
    {description: 'View and add to the activity record of files in your Google Drive', group: 'Drive Activity API, v2'},
  ],
  'https://www.googleapis.com/auth/drive.activity.readonly': [
    {description: 'View the activity record of files in your Google Drive', group: 'Drive Activity API, v2'},
  ],
  'https://www.googleapis.com/auth/apps.licensing': [
    {description: 'View and manage G Suite licenses for your domain', group: 'Enterprise License Manager API, v1'},
  ],
  'https://www.googleapis.com/auth/firebase': [
    {description: 'View and administer all your Firebase data and settings', group: 'Firebase Dynamic Links API, v1'},
    {description: 'View and administer all your Firebase data and settings', group: 'Firebase Management API, v1beta1'},
    {description: 'View and administer all your Firebase data and settings', group: 'Firebase Rules API, v1'},
    {description: 'View and administer all your Firebase data and settings', group: 'Google Identity Toolkit API, v3'},
  ],
  'https://www.googleapis.com/auth/firebase.readonly': [
    {description: 'View all your Firebase data and settings', group: 'Firebase Management API, v1beta1'},
    {description: 'View all your Firebase data and settings', group: 'Firebase Rules API, v1'},
  ],
  'https://www.googleapis.com/auth/fitness.activity.read': [
    {description: 'Use Google Fit to see and store your physical activity data', group: 'Fitness API, v1'},
  ],
  'https://www.googleapis.com/auth/fitness.activity.write': [
    {description: 'See and add to your Google Fit physical activity data', group: 'Fitness API, v1'},
  ],
  'https://www.googleapis.com/auth/fitness.blood_glucose.read': [
    {
      description:
        'See info about your blood glucose in Google Fit. I consent to Google sharing my blood glucose information with this app.',
      group: 'Fitness API, v1',
    },
  ],
  'https://www.googleapis.com/auth/fitness.blood_glucose.write': [
    {
      description:
        'See and add info about your blood glucose to Google Fit. I consent to Google sharing my blood glucose information with this app.',
      group: 'Fitness API, v1',
    },
  ],
  'https://www.googleapis.com/auth/fitness.blood_pressure.read': [
    {
      description:
        'See info about your blood pressure in Google Fit. I consent to Google sharing my blood pressure information with this app.',
      group: 'Fitness API, v1',
    },
  ],
  'https://www.googleapis.com/auth/fitness.blood_pressure.write': [
    {
      description:
        'See and add info about your blood pressure in Google Fit. I consent to Google sharing my blood pressure information with this app.',
      group: 'Fitness API, v1',
    },
  ],
  'https://www.googleapis.com/auth/fitness.body.read': [
    {description: 'See info about your body measurements and heart rate in Google Fit', group: 'Fitness API, v1'},
  ],
  'https://www.googleapis.com/auth/fitness.body.write': [
    {
      description: 'See and add info about your body measurements and heart rate to Google Fit',
      group: 'Fitness API, v1',
    },
  ],
  'https://www.googleapis.com/auth/fitness.body_temperature.read': [
    {
      description:
        'See info about your body temperature in Google Fit. I consent to Google sharing my body temperature information with this app.',
      group: 'Fitness API, v1',
    },
  ],
  'https://www.googleapis.com/auth/fitness.body_temperature.write': [
    {
      description:
        'See and add to info about your body temperature in Google Fit. I consent to Google sharing my body temperature information with this app.',
      group: 'Fitness API, v1',
    },
  ],
  'https://www.googleapis.com/auth/fitness.heart_rate.read': [
    {
      description:
        'See your heart rate data in Google Fit. I consent to Google sharing my heart rate information with this app.',
      group: 'Fitness API, v1',
    },
  ],
  'https://www.googleapis.com/auth/fitness.heart_rate.write': [
    {
      description:
        'See and add to your heart rate data in Google Fit. I consent to Google sharing my heart rate information with this app.',
      group: 'Fitness API, v1',
    },
  ],
  'https://www.googleapis.com/auth/fitness.location.read': [
    {description: 'See your Google Fit speed and distance data', group: 'Fitness API, v1'},
  ],
  'https://www.googleapis.com/auth/fitness.location.write': [
    {description: 'See and add to your Google Fit location data', group: 'Fitness API, v1'},
  ],
  'https://www.googleapis.com/auth/fitness.nutrition.read': [
    {description: 'See info about your nutrition in Google Fit', group: 'Fitness API, v1'},
  ],
  'https://www.googleapis.com/auth/fitness.nutrition.write': [
    {description: 'See and add to info about your nutrition in Google Fit', group: 'Fitness API, v1'},
  ],
  'https://www.googleapis.com/auth/fitness.oxygen_saturation.read': [
    {
      description:
        'See info about your oxygen saturation in Google Fit. I consent to Google sharing my oxygen saturation information with this app.',
      group: 'Fitness API, v1',
    },
  ],
  'https://www.googleapis.com/auth/fitness.oxygen_saturation.write': [
    {
      description:
        'See and add info about your oxygen saturation in Google Fit. I consent to Google sharing my oxygen saturation information with this app.',
      group: 'Fitness API, v1',
    },
  ],
  'https://www.googleapis.com/auth/fitness.reproductive_health.read': [
    {
      description:
        'See info about your reproductive health in Google Fit. I consent to Google sharing my reproductive health information with this app.',
      group: 'Fitness API, v1',
    },
  ],
  'https://www.googleapis.com/auth/fitness.reproductive_health.write': [
    {
      description:
        'See and add info about your reproductive health in Google Fit. I consent to Google sharing my reproductive health information with this app.',
      group: 'Fitness API, v1',
    },
  ],
  'https://www.googleapis.com/auth/fitness.sleep.read': [
    {
      description: 'See your sleep data in Google Fit. I consent to Google sharing my sleep information with this app.',
      group: 'Fitness API, v1',
    },
  ],
  'https://www.googleapis.com/auth/fitness.sleep.write': [
    {
      description:
        'See and add to your sleep data in Google Fit. I consent to Google sharing my sleep information with this app.',
      group: 'Fitness API, v1',
    },
  ],
  'https://www.googleapis.com/auth/ediscovery': [
    {description: 'Manage your eDiscovery data', group: 'G Suite Vault API, v1'},
  ],
  'https://www.googleapis.com/auth/ediscovery.readonly': [
    {description: 'View your eDiscovery data', group: 'G Suite Vault API, v1'},
  ],
  'https://www.googleapis.com/auth/genomics': [
    {description: 'View and manage Genomics data', group: 'Genomics API, v1'},
  ],
  'https://www.googleapis.com/auth/gmail.addons.current.action.compose': [
    {description: 'Manage drafts and send emails when you interact with the add-on', group: 'Gmail API, v1'},
  ],
  'https://www.googleapis.com/auth/gmail.addons.current.message.action': [
    {description: 'View your email messages when you interact with the add-on', group: 'Gmail API, v1'},
  ],
  'https://www.googleapis.com/auth/gmail.addons.current.message.metadata': [
    {description: 'View your email message metadata when the add-on is running', group: 'Gmail API, v1'},
  ],
  'https://www.googleapis.com/auth/gmail.addons.current.message.readonly': [
    {description: 'View your email messages when the add-on is running', group: 'Gmail API, v1'},
  ],
  'https://www.googleapis.com/auth/gmail.compose': [
    {description: 'Manage drafts and send emails', group: 'Gmail API, v1'},
  ],
  'https://www.googleapis.com/auth/gmail.insert': [
    {description: 'Insert mail into your mailbox', group: 'Gmail API, v1'},
  ],
  'https://www.googleapis.com/auth/gmail.labels': [{description: 'Manage mailbox labels', group: 'Gmail API, v1'}],
  'https://www.googleapis.com/auth/gmail.metadata': [
    {
      description: 'View your email message metadata such as labels and headers, but not the email body',
      group: 'Gmail API, v1',
    },
  ],
  'https://www.googleapis.com/auth/gmail.modify': [
    {description: 'View and modify but not delete your email', group: 'Gmail API, v1'},
  ],
  'https://www.googleapis.com/auth/gmail.readonly': [
    {description: 'View your email messages and settings', group: 'Gmail API, v1'},
  ],
  'https://www.googleapis.com/auth/gmail.send': [{description: 'Send email on your behalf', group: 'Gmail API, v1'}],
  'https://www.googleapis.com/auth/gmail.settings.basic': [
    {description: 'Manage your basic mail settings', group: 'Gmail API, v1'},
  ],
  'https://www.googleapis.com/auth/gmail.settings.sharing': [
    {description: 'Manage your sensitive mail settings, including who can manage your mail', group: 'Gmail API, v1'},
  ],
  'https://www.googleapis.com/auth/analytics.edit': [
    {description: 'Edit Google Analytics management entities', group: 'Google Analytics API, v3'},
  ],
  'https://www.googleapis.com/auth/analytics.manage.users': [
    {description: 'Manage Google Analytics Account users by email address', group: 'Google Analytics API, v3'},
  ],
  'https://www.googleapis.com/auth/analytics.manage.users.readonly': [
    {description: 'View Google Analytics user permissions', group: 'Google Analytics API, v3'},
  ],
  'https://www.googleapis.com/auth/analytics.provision': [
    {
      description: 'Create a new Google Analytics account along with its default property and view',
      group: 'Google Analytics API, v3',
    },
  ],
  'https://www.googleapis.com/auth/analytics.user.deletion': [
    {description: 'Manage Google Analytics user deletion requests', group: 'Google Analytics API, v3'},
  ],
  'https://www.googleapis.com/auth/classroom.announcements': [
    {description: 'View and manage announcements in Google Classroom', group: 'Google Classroom API, v1'},
  ],
  'https://www.googleapis.com/auth/classroom.announcements.readonly': [
    {description: 'View announcements in Google Classroom', group: 'Google Classroom API, v1'},
  ],
  'https://www.googleapis.com/auth/classroom.courses': [
    {description: 'Manage your Google Classroom classes', group: 'Google Classroom API, v1'},
  ],
  'https://www.googleapis.com/auth/classroom.courses.readonly': [
    {description: 'View your Google Classroom classes', group: 'Google Classroom API, v1'},
  ],
  'https://www.googleapis.com/auth/classroom.coursework.me': [
    {
      description: 'Manage your course work and view your grades in Google Classroom',
      group: 'Google Classroom API, v1',
    },
  ],
  'https://www.googleapis.com/auth/classroom.coursework.me.readonly': [
    {description: 'View your course work and grades in Google Classroom', group: 'Google Classroom API, v1'},
  ],
  'https://www.googleapis.com/auth/classroom.coursework.students': [
    {
      description:
        'Manage course work and grades for students in the Google Classroom classes you teach and view the course work and grades for classes you administer',
      group: 'Google Classroom API, v1',
    },
  ],
  'https://www.googleapis.com/auth/classroom.coursework.students.readonly': [
    {
      description: 'View course work and grades for students in the Google Classroom classes you teach or administer',
      group: 'Google Classroom API, v1',
    },
  ],
  'https://www.googleapis.com/auth/classroom.courseworkmaterials': [
    {description: 'See, edit, and create classwork materials in Google Classroom', group: 'Google Classroom API, v1'},
  ],
  'https://www.googleapis.com/auth/classroom.courseworkmaterials.readonly': [
    {description: 'See all classwork materials for your Google Classroom classes', group: 'Google Classroom API, v1'},
  ],
  'https://www.googleapis.com/auth/classroom.guardianlinks.me.readonly': [
    {description: 'View your Google Classroom guardians', group: 'Google Classroom API, v1'},
  ],
  'https://www.googleapis.com/auth/classroom.guardianlinks.students': [
    {
      description: 'View and manage guardians for students in your Google Classroom classes',
      group: 'Google Classroom API, v1',
    },
  ],
  'https://www.googleapis.com/auth/classroom.guardianlinks.students.readonly': [
    {description: 'View guardians for students in your Google Classroom classes', group: 'Google Classroom API, v1'},
  ],
  'https://www.googleapis.com/auth/classroom.profile.emails': [
    {description: 'View the email addresses of people in your classes', group: 'Google Classroom API, v1'},
  ],
  'https://www.googleapis.com/auth/classroom.profile.photos': [
    {description: 'View the profile photos of people in your classes', group: 'Google Classroom API, v1'},
  ],
  'https://www.googleapis.com/auth/classroom.push-notifications': [
    {description: 'Receive notifications about your Google Classroom data', group: 'Google Classroom API, v1'},
  ],
  'https://www.googleapis.com/auth/classroom.rosters': [
    {description: 'Manage your Google Classroom class rosters', group: 'Google Classroom API, v1'},
  ],
  'https://www.googleapis.com/auth/classroom.rosters.readonly': [
    {description: 'View your Google Classroom class rosters', group: 'Google Classroom API, v1'},
  ],
  'https://www.googleapis.com/auth/classroom.student-submissions.me.readonly': [
    {description: 'View your course work and grades in Google Classroom', group: 'Google Classroom API, v1'},
  ],
  'https://www.googleapis.com/auth/classroom.student-submissions.students.readonly': [
    {
      description: 'View course work and grades for students in the Google Classroom classes you teach or administer',
      group: 'Google Classroom API, v1',
    },
  ],
  'https://www.googleapis.com/auth/classroom.topics': [
    {description: 'See, create, and edit topics in Google Classroom', group: 'Google Classroom API, v1'},
  ],
  'https://www.googleapis.com/auth/classroom.topics.readonly': [
    {description: 'View topics in Google Classroom', group: 'Google Classroom API, v1'},
  ],
  'https://www.googleapis.com/auth/documents.readonly': [
    {description: 'View your Google Docs documents', group: 'Google Docs API, v1'},
  ],
  'https://www.googleapis.com/auth/userinfo.profile': [
    {
      description: "See your personal info, including any personal info you've made publicly available",
      group: 'Google OAuth2 API, v2',
    },
    {
      description: "See your personal info, including any personal info you've made publicly available",
      group: 'People API, v1',
    },
  ],
  openid: [
    {description: 'Associate you with your personal info on Google', group: 'Google OAuth2 API, v2'},
    {description: 'Authenticate using OpenID Connect', group: 'Google Sign-In'},
    {description: 'Authenticate using OpenID Connect', group: 'OpenID Connect, 1.0'},
    {description: 'Associate you with your personal info on Google', group: 'PageSpeed Insights API, v5'},
  ],
  'https://www.googleapis.com/auth/androidpublisher': [
    {description: 'View and manage your Google Play Developer account', group: 'Google Play Android Developer API, v3'},
    {
      description: 'View and manage your Google Play Developer account',
      group: 'Google Play Custom App Publishing API, v1',
    },
    {
      description: 'View and manage your Google Play Developer account',
      group: 'Google Play Game Services Publishing API, v1configuration',
    },
  ],
  'https://www.googleapis.com/auth/androidenterprise': [
    {description: 'Manage corporate Android devices', group: 'Google Play EMM API, v1'},
  ],
  'https://www.googleapis.com/auth/games': [
    {
      description: 'Create, edit, and delete your Google Play Games activity',
      group: 'Google Play Game Management, v1management',
    },
    {description: 'Create, edit, and delete your Google Play Games activity', group: 'Google Play Game Services, v1'},
  ],
  'https://www.googleapis.com/auth/webmasters': [
    {
      description: 'View and manage Search Console data for your verified sites',
      group: 'Google Search Console API, v1',
    },
    {description: 'View and manage Search Console data for your verified sites', group: 'Search Console API, v3'},
  ],
  'https://www.googleapis.com/auth/webmasters.readonly': [
    {description: 'View Search Console data for your verified sites', group: 'Google Search Console API, v1'},
    {description: 'View Search Console data for your verified sites', group: 'Search Console API, v3'},
  ],
  'https://www.googleapis.com/auth/spreadsheets.readonly': [
    {description: 'View your Google Spreadsheets', group: 'Google Sheets API, v4'},
    {description: 'View your Google Spreadsheets', group: 'Google Slides API, v1'},
  ],
  profile: [
    {description: 'View your basic profile info', group: 'Google Sign-In'},
    {description: 'View your basic profile info', group: 'OpenID Connect, 1.0'},
  ],
  email: [
    {description: 'View your email address', group: 'Google Sign-In'},
    {description: 'View your email address', group: 'OpenID Connect, 1.0'},
  ],
  'https://www.googleapis.com/auth/siteverification': [
    {description: 'Manage the list of sites and domains you control', group: 'Google Site Verification API, v1'},
  ],
  'https://www.googleapis.com/auth/siteverification.verify_only': [
    {description: 'Manage your new site verifications with Google', group: 'Google Site Verification API, v1'},
  ],
  'https://www.googleapis.com/auth/presentations': [
    {description: 'View and manage your Google Slides presentations', group: 'Google Slides API, v1'},
  ],
  'https://www.googleapis.com/auth/presentations.readonly': [
    {description: 'View your Google Slides presentations', group: 'Google Slides API, v1'},
  ],
  'https://www.googleapis.com/auth/apps.alerts': [
    {
      description: "See and delete your domain's G Suite alerts, and send alert feedback",
      group: 'Google Workspace Alert Center API, v1beta1',
    },
  ],
  'https://www.googleapis.com/auth/apps.order': [
    {description: 'Manage users on your domain', group: 'Google Workspace Reseller API, v1'},
  ],
  'https://www.googleapis.com/auth/apps.order.readonly': [
    {description: 'Manage users on your domain', group: 'Google Workspace Reseller API, v1'},
  ],
  'https://www.googleapis.com/auth/apps.groups.migration': [
    {description: 'Manage messages in groups on your domain', group: 'Groups Migration API, v1'},
  ],
  'https://www.googleapis.com/auth/apps.groups.settings': [
    {description: 'View and manage the settings of a G Suite group', group: 'Groups Settings API, v1'},
  ],
  'https://www.googleapis.com/auth/homegraph': [{description: '', group: 'HomeGraph API, v1'}],
  'https://www.googleapis.com/auth/indexing': [
    {description: 'Submit data to Google for indexing', group: 'Indexing API, v3'},
  ],
  'https://www.googleapis.com/auth/manufacturercenter': [
    {description: 'Manage your product listings for Google Manufacturer Center', group: 'Manufacturer Center API, v1'},
  ],
  'https://www.googleapis.com/auth/contacts': [
    {description: 'See, edit, download, and permanently delete your contacts', group: 'People API, v1'},
  ],
  'https://www.googleapis.com/auth/contacts.other.readonly': [
    {description: 'See and download contact info automatically saved in your Other contacts', group: 'People API, v1'},
  ],
  'https://www.googleapis.com/auth/contacts.readonly': [
    {description: 'See and download your contacts', group: 'People API, v1'},
  ],
  'https://www.googleapis.com/auth/directory.readonly': [
    {description: "See and download your organization's GSuite directory", group: 'People API, v1'},
  ],
  'https://www.googleapis.com/auth/user.addresses.read': [
    {description: 'View your street addresses', group: 'People API, v1'},
  ],
  'https://www.googleapis.com/auth/user.birthday.read': [
    {description: 'See and download your exact date of birth', group: 'People API, v1'},
  ],
  'https://www.googleapis.com/auth/user.emails.read': [
    {description: 'View your email addresses', group: 'People API, v1'},
  ],
  'https://www.googleapis.com/auth/user.gender.read': [{description: 'See your gender', group: 'People API, v1'}],
  'https://www.googleapis.com/auth/user.organization.read': [
    {description: 'See your education, work history and org info', group: 'People API, v1'},
  ],
  'https://www.googleapis.com/auth/user.phonenumbers.read': [
    {description: 'See and download your personal phone numbers', group: 'People API, v1'},
  ],
  'https://www.googleapis.com/auth/photoslibrary': [
    {description: 'View and manage your Google Photos library', group: 'Photos Library API, v1'},
  ],
  'https://www.googleapis.com/auth/photoslibrary.appendonly': [
    {description: 'Add to your Google Photos library', group: 'Photos Library API, v1'},
  ],
  'https://www.googleapis.com/auth/photoslibrary.edit.appcreateddata': [
    {
      description:
        'Edit the info in your photos, videos, and albums created within this app, including titles, descriptions, and covers',
      group: 'Photos Library API, v1',
    },
  ],
  'https://www.googleapis.com/auth/photoslibrary.readonly': [
    {description: 'View your Google Photos library', group: 'Photos Library API, v1'},
  ],
  'https://www.googleapis.com/auth/photoslibrary.readonly.appcreateddata': [
    {description: 'Manage photos added by this app', group: 'Photos Library API, v1'},
  ],
  'https://www.googleapis.com/auth/photoslibrary.sharing': [
    {description: 'Manage and add to shared albums on your behalf', group: 'Photos Library API, v1'},
  ],
  'https://www.googleapis.com/auth/doubleclicksearch': [
    {description: 'View and manage your advertising data in DoubleClick Search', group: 'Search Ads 360 API, v2'},
  ],
  'https://www.googleapis.com/auth/service.management': [
    {description: 'Manage your Google API service configuration', group: 'Service Management API, v1'},
    {description: 'Manage your Google API service configuration', group: 'Service Networking API, v1'},
    {description: 'Manage your Google API service configuration', group: 'Service Usage API, v1'},
  ],
  'https://www.googleapis.com/auth/service.management.readonly': [
    {description: 'View your Google API service configuration', group: 'Service Management API, v1'},
  ],
  'https://www.googleapis.com/auth/streetviewpublish': [
    {description: 'Publish and manage your 360 photos on Google Street View', group: 'Street View Publish API, v1'},
  ],
  'https://www.googleapis.com/auth/tagmanager.delete.containers': [
    {description: 'Delete your Google Tag Manager containers', group: 'Tag Manager API, v2'},
  ],
  'https://www.googleapis.com/auth/tagmanager.edit.containers': [
    {
      description:
        'Manage your Google Tag Manager container and its subcomponents, excluding versioning and publishing',
      group: 'Tag Manager API, v2',
    },
  ],
  'https://www.googleapis.com/auth/tagmanager.edit.containerversions': [
    {description: 'Manage your Google Tag Manager container versions', group: 'Tag Manager API, v2'},
  ],
  'https://www.googleapis.com/auth/tagmanager.manage.accounts': [
    {description: 'View and manage your Google Tag Manager accounts', group: 'Tag Manager API, v2'},
  ],
  'https://www.googleapis.com/auth/tagmanager.manage.users': [
    {
      description: 'Manage user permissions of your Google Tag Manager account and container',
      group: 'Tag Manager API, v2',
    },
  ],
  'https://www.googleapis.com/auth/tagmanager.publish': [
    {description: 'Publish your Google Tag Manager container versions', group: 'Tag Manager API, v2'},
  ],
  'https://www.googleapis.com/auth/tagmanager.readonly': [
    {description: 'View your Google Tag Manager container and its subcomponents', group: 'Tag Manager API, v2'},
  ],
  'https://www.googleapis.com/auth/tasks': [
    {description: 'Create, edit, organize, and delete all your tasks', group: 'Tasks API, v1'},
  ],
  'https://www.googleapis.com/auth/tasks.readonly': [{description: 'View your tasks', group: 'Tasks API, v1'}],
  'https://www.googleapis.com/auth/youtube': [
    {description: 'Manage your YouTube account', group: 'YouTube Analytics API, v2'},
    {description: 'Manage your YouTube account', group: 'YouTube Data API, v3'},
  ],
  'https://www.googleapis.com/auth/youtube.readonly': [
    {description: 'View your YouTube account', group: 'YouTube Analytics API, v2'},
    {description: 'View your YouTube account', group: 'YouTube Data API, v3'},
  ],
  'https://www.googleapis.com/auth/youtubepartner': [
    {description: 'View and manage your assets and associated content on YouTube', group: 'YouTube Analytics API, v2'},
    {description: 'View and manage your assets and associated content on YouTube', group: 'YouTube Data API, v3'},
  ],
  'https://www.googleapis.com/auth/yt-analytics-monetary.readonly': [
    {
      description: 'View monetary and non-monetary YouTube Analytics reports for your YouTube content',
      group: 'YouTube Analytics API, v2',
    },
    {
      description: 'View monetary and non-monetary YouTube Analytics reports for your YouTube content',
      group: 'YouTube Reporting API, v1',
    },
  ],
  'https://www.googleapis.com/auth/yt-analytics.readonly': [
    {description: 'View YouTube Analytics reports for your YouTube content', group: 'YouTube Analytics API, v2'},
    {description: 'View YouTube Analytics reports for your YouTube content', group: 'YouTube Reporting API, v1'},
  ],
  'https://www.googleapis.com/auth/youtube.channel-memberships.creator': [
    {
      description:
        'See a list of your current active channel members, their current level, and when they became a member',
      group: 'YouTube Data API, v3',
    },
  ],
  'https://www.googleapis.com/auth/youtube.force-ssl': [
    {
      description: 'See, edit, and permanently delete your YouTube videos, ratings, comments and captions',
      group: 'YouTube Data API, v3',
    },
  ],
  'https://www.googleapis.com/auth/youtube.upload': [
    {description: 'Manage your YouTube videos', group: 'YouTube Data API, v3'},
  ],
  'https://www.googleapis.com/auth/youtubepartner-channel-audit': [
    {
      description:
        'View private information of your YouTube channel relevant during the audit process with a YouTube partner',
      group: 'YouTube Data API, v3',
    },
  ],
};
