import _ from 'lodash';
import React, {useState} from 'react';
import {SubmitButton} from 'src/components/design';
import {ConnectionInfoT} from '../../types';
import './PagerDutyConnector.scss';
import {NextStepT} from '../types';
import {getConnectorOAuthRedirect} from 'src/lib/config';
import {Button} from '@trustle/component-library';
import {useConnectionCredentials} from '../../lib/hooks';

type ConnectorPropsT = {
  onComplete: NextStepT;
  connectionInfo: ConnectionInfoT;
  editMode?: boolean;
};

export default function PagerDutySetup(props: ConnectorPropsT) {
  const {editMode = false, connectionInfo} = props;
  const {connection} = connectionInfo;

  const isReadSelected = connection.authenticationMethod === 'read';
  const isAuthenticated = _.includes(['read', 'write'], connection.authenticationMethod);
  const [testConnectionError, setTestConnectionError] = useState('');
  const {testConnection: testConnectionAPI} = useConnectionCredentials();

  async function testConnection(): Promise<boolean> {
    const {data: responseData} = await testConnectionAPI(connection.id, {});
    if (responseData.error) {
      setTestConnectionError(responseData.error.message);
      return false;
    }
    return true;
  }

  return (
    <div className="py-4 pagerduty-setup">
      <h3>OAuth authentication</h3>
      <div>
        Trustle authenticates with PagerDuty via OAuth authentication to import and provision access to your PagerDuty
        groups and users.
      </div>
      <div className="tr-flex tr-flex-col tr-mt-5 tr-m-3">
        <div className="tr-flex">
          <div className="app-selector">
            <div className={`selector-item ${isReadSelected ? 'selected' : ''}`}>
              <div className="pb-2">
                <div className="mb-2 row">
                  <div className="col-md-4">
                    <h5>Manual</h5>
                  </div>
                </div>
                <div className="body5-normal">{'Read-only access to PagerDuty API endpoints'}</div>
                <div className="text-right">
                  <Button
                    onClick={() => {
                      window.location.href = getConnectorOAuthRedirect(connection, {method: 'read'});
                    }}
                  >
                    {isReadSelected ? 'Reauthenticate' : 'Authenticate'}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tr-text-right tr-mt-5 tr-m-3">
        <div className="tr-flex">
          {!editMode && (
            <div>
              <div className="text-right">
                <SubmitButton
                  disabled={!isAuthenticated}
                  onClick={async () => {
                    const success = await testConnection();
                    if (!success) {
                      return;
                    }

                    props.onComplete({});
                  }}
                  label={'Continue'}
                />
              </div>
              {testConnectionError && (
                <p className="text-danger">An error occured when confirming access: {testConnectionError}</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
