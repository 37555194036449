import React from 'react';
import {Button, Checkbox, Icon, Table} from '@trustle/component-library';

type Props = {
  setShowLicensesPanel: React.Dispatch<React.SetStateAction<boolean>>;
};

const sharePointSites = [
  {name: 'HR Portal', usage: 85},
  {name: 'Finance Dashboard', usage: 72},
  {name: 'Marketing Hub', usage: 64},
  {name: 'IT Support Center', usage: 93},
  {name: 'Operations Team', usage: 58},
  {name: 'Sales Insights', usage: 76},
  {name: 'Project Management', usage: 49},
  {name: 'Training Resources', usage: 31},
  {name: 'Compliance Center', usage: 67},
  {name: 'R&D Collaboration', usage: 88},
];

const COLUMNS = [
  {
    dataField: 'name',
    text: 'Sites',
    formatter: (name: string) => (
      <div className="tr-flex tr-items-baseline" title={name}>
        <span className="tr-text-trustle-link">{name}</span>
      </div>
    ),
  },
  {
    dataField: 'usage',
    text: 'Usage',
    headerStyle: {width: '25%'},
    style: {width: '25%'},
    formatter: (usage: number) => (
      <div className="tr-flex tr-items-baseline">
        <span className="tr-font-bold tr-text-trustle-navy">{usage}%</span>
      </div>
    ),
  },
];

export function SitesTable({setShowLicensesPanel}: Props) {
  return (
    <div className="tr-flex tr-flex-col">
      <Button
        variant="secondary"
        className="tr-m-0 tr-px-3 tr-py-2.5 tr-whitespace-nowrap tr-ml-auto tr-bg-trustle-bglight tr-text-black"
        onClick={() => setShowLicensesPanel(false)}
      >
        Hide Sites
      </Button>
      <h2 className="tr-flex tr-gap-1 tr-items-center">
        <Icon size="sm" type="licenses" color="white" />
        Sites
      </h2>
      <div className="tr-flex tr-gap-2 tr-items-center tr-mb-2">
        <div>
          <Checkbox id="overall-license-usage" name="overall-license-usage" />
        </div>
        <span className="tr-flex tr-gap-2 tr-whitespace-nowrap">
          <strong>Overall Utilization</strong>
          <p className="tr-font-light tr-mb-0">(Or select up to 4 to compare)</p>
        </span>
      </div>
      <Table
        overrideKeyField="id"
        tableKey="licenses"
        data={sharePointSites}
        columns={COLUMNS}
        hideSizePerPage
        selectRow={{
          mode: 'checkbox',
          clickToSelect: false,
          hideSelectAll: true,
          selected: [],
          onSelect: (_row, _isSelect) => {},
          nonSelectable: [],
        }}
      />
    </div>
  );
}
