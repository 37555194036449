import React from 'react';

type StepTabsProps = {
  pages: Array<number>;
  changePage: (pageNumber: number) => void;
  activePage: number;
};

const StepTabs = ({activePage, changePage, pages}: StepTabsProps) => {
  const handleClick = (page: number) => {
    if (page < activePage) {
      changePage(page);
    }
  };

  return (
    <div className="tr-text-base tr-text-blue">
      {pages.map((page) => (
        <span
          key={page}
          className={`tr-border-solid tr-border-0 tr-px-1 ${
            activePage === page ? 'tr-border-b-4' : 'tr-opacity-40'
          } tr-mr-2`}
          onClick={() => handleClick(page)}
          style={{cursor: page < activePage ? 'pointer' : 'default'}}
        >
          {page}
        </span>
      ))}
    </div>
  );
};

export default StepTabs;
