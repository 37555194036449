import React from 'react';
import {observer} from 'mobx-react';
import {AuthzOwnerAWS} from '../../../types';
import _ from 'lodash';
import {Icon} from '@trustle/component-library';

export default observer(function ShowUserAccount(props: {
  authzOwner?: AuthzOwnerAWS;
  label?: string | React.ReactElement;
  className?: string;
}) {
  const {authzOwner, label, className} = props;
  if (_.isNil(authzOwner) || _.isEmpty(authzOwner)) {
    return <></>;
  }
  return (
    <>
      <div className={className}>
        {label}
        <span>
          <div>
            User: <strong>{authzOwner.UserName}</strong>
            <span className="ml-1">
              <Icon type={'connection'} className="cursor tr-text-[#F08C00]" size={'sm'} />
            </span>
          </div>
          <div>
            {'Organization ID: '}
            <span>
              <strong>{authzOwner.AccountId}</strong>
            </span>
          </div>
        </span>
      </div>
    </>
  );
});
