export const USER_STATUSES = ['active', 'inactive', 'disabled', 'offboarded'] as const;
export const USER_TYPES = ['employee', 'contractor', 'customer', 'system'] as const;
export const INVITATION_STATUSES = ['pending', 'sent', 'expired'] as const;
export const LINK_STATUSES = ['linked', 'unlinked'] as const;
export const ACCOUNT_MATCH_STATUSES = ['perfect', 'partial', 'none'] as const;

export const USER_FILTERS_KEYS = [
  'team',
  'department',
  'role',
  'title',
  'manager',
  // User information
  'status', // User status
  'type',
  'invitationStatus',
  'linkStatus',
  'nameOrEmail',
] as const;

export const ACCOUNT_FILTERS_KEYS = ['matchStatus', 'linkStatus', 'userType'];

export const USER_GROUP_BY_OPTIONS = ['user', 'team', 'department', 'role', 'title'] as const;
export const USER_ACTIONS = [
  'VIEW_USER',
  'START_OFFBOARD',
  'UPLOAD_CSV',
  'ACTIVATE_USER',
  'ENABLE_USER',
  'DISABLE_USER',
  'ADD_OWNER_ROLE',
  'REMOVE_OWNER_ROLE',
  'BULK_ACTION',
  'RESEND_INVITE',
  'SEND_INVITE',
  'SWITCH_AUTHORITY',
  'SYNC_USERS',
  'IDP_SYNC',
  'IDP_SETUP',
  'DELETE',
] as const;
