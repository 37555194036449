import {useState} from 'react';
import axios from 'axios';
import {OrganizationSummaryParams} from '../types';

export type UsersSummaryResponse = {
  activeUserCount: number;
  inactiveUserCount: number;
  disabledUserCount: number;
  offboardedUserCount: number;
  employeeCount: number;
  contractorCount: number;
  customerCount: number;
  employeeUserCount?: number;
  contractorUserCount?: number;
  customerUserCount?: number;
  systemUserCount: number;
  unlinkedUserCount: number;
};

export function useUsersSummary() {
  const [data, setData] = useState<UsersSummaryResponse>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const BASE_URL = '/api/v2/users_summary';

  const getUsersSummary = async (params: OrganizationSummaryParams) => {
    const parsedParams: Record<string, string> = {};

    Object.keys(params).forEach((key) => {
      const typedKey = key as keyof typeof params; // Ensure TypeScript understands the key type
      const value = params[typedKey] as string | number;
      if (params[typedKey] !== undefined) {
        parsedParams[typedKey] = value.toString();
      }
    });

    const queryString = new URLSearchParams(parsedParams).toString();

    setLoading(true);
    try {
      const response = await axios.get<UsersSummaryResponse>(`${BASE_URL}?${queryString}`);
      const responseData = response.data;
      setData(responseData);
    } catch (err: any) {
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return {data, loading, error, getUsersSummary};
}
