import {ConnectionInfoT} from 'src/types';

export type NextStepT = ({
  resourceId,
  newConnectionId,
  path,
}: {
  resourceId?: string;
  newConnectionId?: string;
  path?: string;
}) => void;

export enum ReportType {
  APP_USAGE_PER_ACCOUNT = 'app_usage_per_account',
}

export enum OktaCredentialTypeE {
  CLIENT_CREDENTIALS = 'client_credentials',
  OAUTH_TOKEN = 'oauth_token',
}

export enum TableauCredentialTypeE {
  CLIENT_CREDENTIALS = 'client_credentials',
  TABLEAU_TOKEN = 'tableau_token',
}

export type ComponentStateT = {
  connectionId?: string;
  connectionInfo?: ConnectionInfoT;
  systemId: string | null;
  orgName: string | null;
  nextStep: NextStepT;
};

export type RouteStepT = {
  name: string;
  path: string;
  component: (params: ComponentStateT) => React.ReactElement;
};

export type ConnectorMeta = {
  steps?: RouteStepT[];
  description?: string;
  image?: JSX.Element;
  title?: string;
  key?: string;
};
