import _ from 'lodash';
import React, {useState} from 'react';
import {ConnectionServiceE, UpdateConnectorSettingsT, UsageServiceByConnectionType} from 'src/types';
import {Field, Form as FormikForm, Formik} from 'formik';
import AutomatedProvisionToggle from '../../components/AutomatedProvisionToggle';
import './EditConnectionParams.scss';
import {Resource} from 'src/stores/domainObjects/Resource';
import {RECOMMENDATION_TYPE} from 'src/stores/domainObjects/Recommendation';
import {observer} from 'mobx-react-lite';
import {Alert, Button, DurationInput, Icon, Select, Tooltip} from '@trustle/component-library';
import {useToasts} from 'react-toast-notifications';
import {useRootStore} from 'src/lib/hooks';

const EditConnectionParams = observer((props: {resource: Resource}) => {
  const rootStore = useRootStore();

  const {currentUser} = rootStore;
  const {resource} = props;

  const userEmail = currentUser!.email.toLowerCase();
  const canSeeResource = currentUser.isOrgOwner && !_.includes(resource.owners, userEmail);

  const {connector} = resource;

  const [, setFormValuesChange] = useState<_.Dictionary<any>>({});
  const [isTouched, setTouched] = useState<boolean>(false);

  const {addToast} = useToasts();

  async function submitConnection(values: UpdateConnectorSettingsT, formikArgs: any) {
    await resource.updateConnectionSettings(values);

    if (!resource.connectionSettingsConfirmed) {
      await resource.completeRecommendation(RECOMMENDATION_TYPE.REVIEW_CONNECTION_SETTINGS);
      addToast('Default Import Settings changed successfully', {appearance: 'success', autoDismiss: true});
    } else {
      addToast(`Connection Import settings saved successfully`, {appearance: 'success', autoDismiss: true});
    }

    // Reset dirty status.
    formikArgs.resetForm({values});
    setTouched(false);
  }

  const initialFormValues = {
    pollFrequency: {
      durationUnit: connector?.pollDurationUnit ?? 'hour',
      durationValue: connector?.pollDurationValue ?? 24,
    },
    accessKeyRotation: {
      durationValue: connector?.accessKeyRotationValue ?? 3,
      durationUnit: connector?.accessKeyRotationUnit ?? 'month',
    },
    retrieveUsageData: connector?.retrieveUsageData ?? false,
    thresholdPct: connector?.thresholdPct ?? 55,
    isTouched,
  };

  function onChangeFields(event: any) {
    const target = event.target;
    const name = target.name;
    const value = target.type === 'checkbox' ? target.checked : target.value;

    setFormValuesChange((prevFormValueChange) => {
      return {
        ...prevFormValueChange,
        ...{[target.name]: _.toString(_.get(initialFormValues, name)) !== _.toString(value)},
      };
    });
  }

  const metadata = connector?.service && UsageServiceByConnectionType[connector.service];

  return (
    <>
      <div className="edit-connection-params pb-7 tr-t-2 tr-w-[90%]">
        <div className="tr-my-6">
          {!resource.connectionSettingsConfirmed && (
            <Alert title="Confirm Default Import Settings?" colorVariant="primary">
              Please confirm the default import settings by clicking <u>Save</u>. This can be changed later.
            </Alert>
          )}
        </div>
        <Formik initialValues={initialFormValues} onSubmit={submitConnection}>
          {({dirty, submitForm, values}) => {
            const {idpEnabled, idpFieldMapping} = values;
            const {email, manager, title, department, role, type} = idpFieldMapping || {};

            const invalidForm =
              idpEnabled &&
              (_.isEmpty(email) ||
                _.isEmpty(manager) ||
                _.isEmpty(title) ||
                _.isEmpty(department) ||
                _.isEmpty(role) ||
                _.isEmpty(type));

            const changedAndValid = !invalidForm && (dirty || isTouched);

            return (
              <FormikForm onChange={onChangeFields}>
                <div className="tr-grid tr-grid-cols-2 tr-gap-8 tr-gap-x-28 tr-gap-y-8">
                  <h3>Import Settings</h3>
                </div>
                {changedAndValid && !canSeeResource && (
                  <Alert
                    title='You have changed your Import Settings. Click "Save" to confirm.'
                    colorVariant="primary"
                  />
                )}
                {changedAndValid && canSeeResource && (
                  <Alert colorVariant="danger" hideCloseButton={false} title="Read Only System">
                    <span>You are not an owner of this system, you can't save any configuration changes. </span>
                  </Alert>
                )}
                <div className="tr-grid tr-grid-cols-2 tr-gap-8 tr-gap-x-28 tr-gap-y-8 tr-mt-4">
                  <div>
                    <Field
                      component={DurationInput}
                      name="pollFrequency"
                      includeNoneOption={false}
                      label={
                        <>
                          Poll Frequency
                          <Tooltip
                            className="tr-font-normal"
                            content={<div>How often should Trustle import new data?</div>}
                          >
                            <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                          </Tooltip>
                        </>
                      }
                      required
                      handleOnChange={onChangeFields}
                    />
                  </div>
                  <div>
                    {_.includes(
                      [ConnectionServiceE.AWS, ConnectionServiceE.AZURE_AD, ConnectionServiceE.OKTA],
                      resource.type
                    ) && (
                      <Field
                        component={DurationInput}
                        name="accessKeyRotation"
                        includeNoneOption={false}
                        description=""
                        label={
                          <>
                            Trustle Connector key rotation
                            <Tooltip content="Prompt me to rotate the Trustle access key in" className="tr-font-normal">
                              <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                            </Tooltip>
                          </>
                        }
                        required
                        handleOnChange={onChangeFields}
                      />
                    )}
                  </div>
                  <div>
                    {metadata && (
                      <div className="tr-flex tr-my-4">
                        <div className="tr-flex-none tr-w-14 tr-mr-2 tr-mt-2">
                          <AutomatedProvisionToggle name="retrieveUsageData" />
                        </div>
                        <div className="tr-flex-initial tr-grow tr-mt-2">
                          <div className="tr-flex-auto">
                            <span className="body4 tr-leading-none">Retrieve service usage data</span>
                            {metadata.info && (
                              <Tooltip content={<div>{metadata.info}</div>}>
                                <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                              </Tooltip>
                            )}
                          </div>

                          {metadata.moreInfoLink && (
                            <div>
                              <div className="body5-normal grayed-out tr-mt-3">
                                Do you want to track usage data on this system?
                              </div>
                              <div className="body5-normal">
                                <a
                                  href={metadata.moreInfoLink}
                                  target="_blank"
                                  className="tr-text-decoration-underline tr-text-underline"
                                  rel="noreferrer"
                                >
                                  <u>More information about this feature</u>
                                </a>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    {_.includes([ConnectionServiceE.OKTA], resource.type) && (
                      <Field
                        component={Select}
                        name="thresholdPct"
                        includeNoneOption={false}
                        options={_.map(
                          [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 90, 95, 100],
                          (val) => {
                            return {value: val, label: `${val}%`};
                          }
                        )}
                        description=""
                        className="tr-w-[272px]"
                        label={
                          <>
                            Rate Limit Threshold
                            <Tooltip
                              content="Percentage of the rate limit Trustle will use as boundery to limit ingestion process."
                              className="tr-font-normal"
                            >
                              <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
                            </Tooltip>
                          </>
                        }
                        handleOnChange={onChangeFields}
                      />
                    )}
                  </div>
                </div>
                <div className="">
                  <Button
                    name="edit-connection-save"
                    onClick={submitForm}
                    disabled={(!changedAndValid && resource.connectionSettingsConfirmed) || resource.archived}
                  >
                    Save
                  </Button>
                </div>
              </FormikForm>
            );
          }}
        </Formik>
      </div>
      <hr />
    </>
  );
});

export default EditConnectionParams;
