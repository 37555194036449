import React, {useState} from 'react';
import {Button, Icon, Modal} from '@trustle/component-library';
import {useRootStore} from '../../../lib/hooks';
import {UserAction, UserStatus} from '../../../stores/usersStore';

export function OffboardInactiveUsersButton() {
  const {usersStore} = useRootStore();
  const [showModal, setShowModal] = useState(false);

  const toDelete = usersStore.users.filter((u) => u.status === UserStatus.PENDING_APPROVAL);

  const onConfirmDelete = async () => {
    usersStore.bulkAction(UserAction.START_OFFBOARD, toDelete);
    setShowModal(false);
  };
  return (
    <>
      <Button
        variant="secondary"
        colorVariant="danger"
        className="tr-flex tr-items-center tr-gap-2 tr-p-2 tr-mb-2"
        onClick={() => setShowModal(true)}
      >
        <Icon type="startOffboard" />
        Offboard Inactive Users
      </Button>
      {showModal && (
        <Modal onClose={() => setShowModal(false)} title="Offboard Inactive Users?">
          <div className="tr-flex tr-flex-col tr-gap-4">
            <strong>This will offboard and unlink {toDelete.length} inactive users.</strong>
            <p>This action is permanent and cannot be undone.</p>
          </div>
          <div className="tr-flex tr-justify-end">
            <Button variant="secondary" colorVariant="danger" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" colorVariant="danger" onClick={() => onConfirmDelete()}>
              Yes, Offboard Users
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
}
