import {object, string} from 'yup';
import {Button, TextInput} from '@trustle/component-library';
import {ToggleSwitch} from 'src/components/design';
import {UserFilter} from 'src/stores/domainObjects/Org';
import {useLocation} from 'react-router-dom';
import {useRootStore} from 'src/lib/hooks';
import {Field, Form, Formik} from 'formik';
import React from 'react';
import _ from 'lodash';

const SaveFilterModal = ({
  onClose,
  isEditing = false,
  loadedFilter,
  afterSubmit,
  loadedFilters,
}: {
  onClose: () => void;
  filters: any;
  isEditing?: boolean;
  loadedFilter: UserFilter | null;
  afterSubmit: () => void;
  loadedFilters: UserFilter[] | undefined;
}) => {
  const validationSchema = object().shape({
    name: string().when('loadedFilters', {
      is: (loadedFilters: UserFilter[]) => loadedFilters !== null,
      then: string()
        .min(1, 'Name must be at least 1 character long')
        .required('Please select a name for the Filter')
        .notOneOf(_.map(loadedFilters, 'name'), 'Name already used'),
      otherwise: string()
        .min(1, 'Name must be at least 1 character long')
        .required('Please select a name for the Filter'),
    }),
  });

  const paramsUrl = new URLSearchParams(useLocation().search);
  const queryFilters = paramsUrl.toString().length === 0 ? '' : `?${paramsUrl.toString()}`;
  const {currentUser, usersStore, org} = useRootStore();
  const initialValues =
    isEditing && loadedFilter ? loadedFilter : {name: '', owner: currentUser.id, query: queryFilters, private: false};

  const handleSubmit = async (values: UserFilter) => {
    const filterParams = {
      name: values.name,
      owner: currentUser.id,
      query: queryFilters,
      private: values.private,
    };

    try {
      if (isEditing && loadedFilter) {
        await org.updateFilter(currentUser.id, loadedFilter.name, {
          name: values.name,
          query: queryFilters,
          private: values.private,
        });
      } else {
        await org.addFilter(filterParams);
      }

      afterSubmit();
      onClose();
    } catch (err) {
      usersStore.rootStore.toast.add('Failed to load filters.', {appearance: 'error', autoDismiss: true});
    }
  };

  return (
    <>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={handleSubmit}>
        {({isSubmitting, values, setFieldValue, isValid}) => (
          <Form>
            <div className="tr-flex tr-flex-col tr-pb-4">
              <Field
                className="tr-pb-4"
                component={TextInput}
                label="Name"
                type="text"
                id="name"
                name="name"
                placeholder="What would you like to call this filter?"
                disabled={isSubmitting}
              />
              <div className="tr-flex tr-items-left tr-pb-2">
                <ToggleSwitch
                  leftBackgroundColor="tr-bg-[#0067C4]"
                  rightBackgroundColor="tr-bg-gray"
                  rightLabel={<span className="tr-font-bold tr-ml-2">Private</span>}
                  checked={values.private}
                  onToggle={() => {
                    setFieldValue('private', !values.private);
                  }}
                  name={'private'}
                  disabled={isSubmitting}
                />
              </div>
            </div>
            <div className="tr-flex tr-justify-end">
              <Button variant="primary" onClick={() => onClose()}>
                Cancel
              </Button>
              <Button variant="secondary" type="submit" disabled={isSubmitting || !isValid}>
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SaveFilterModal;
