import {
  Confirmation,
  Button,
  Modal,
  Icon,
  Select,
  IconButton,
  UserAvatarAndName,
  Tooltip,
} from '@trustle/component-library';
import axios from 'axios';
import {Field, Formik} from 'formik';
import _ from 'lodash';
import {observer} from 'mobx-react';
import React, {useState} from 'react';
import {useToasts} from 'react-toast-notifications';
import {FormikForm} from 'src/components/design/formik';
import {formatUserName, formatUserNameAndEmail, logger} from 'src/lib';
import {useRootStore} from 'src/lib/hooks';
import {Resource} from 'src/stores/domainObjects/Resource';
import {UserT} from 'src/types';
import {array, object, string} from 'yup';
import './UsersCardSelector.scss';

type UserCardSelector = {
  label: string;
  name: 'owners' | 'provisioners';
  fixedOptions?: string[];
  resource: Resource;
};

const UsersCardSelector = observer((props: UserCardSelector) => {
  const {name, label, resource, fixedOptions} = props;
  const {usersStore, currentUser} = useRootStore();
  const {addToast} = useToasts();
  const [showDeletingConfirm, setShowDeletingConfirm] = useState<Partial<UserT>>();
  const [open, setOpen] = useState<boolean>(false);
  const list = resource[name] ?? [];
  const [initialList, setInitialList] = useState<string[]>([...list]);

  if (usersStore.loading) {
    return <>Loading...</>;
  }
  const userEmail = currentUser!.email.toLowerCase();
  const canEdit = currentUser.isOrgOwner || _.includes(resource.owners, userEmail);

  async function save(values: any) {
    const url = `/api/resources/${resource.id}`;

    const cleanResource: any = {};
    cleanResource[name] = values[name];
    try {
      await axios.post(url, {resource: cleanResource});
      resource[name] = cleanResource[name];
      setInitialList(values[name]);
      addToast(`Resource updated successfully`, {
        appearance: 'success',
        autoDismiss: true,
      });
      return {error: false, msg: 'Saved the resource.'};
    } catch (err) {
      addToast(`An error occurred while updating `, {
        appearance: 'error',
        autoDismiss: true,
      });
      logger.error(err);
      return {error: true, msg: 'Failed to save the resource.'};
    }
  }

  const cleanResource: any = {};
  const cleanSchema: any = {};
  cleanSchema[name] = array().of(string().email()).min(1, 'Please specify at least one owner');

  cleanResource[name] = initialList;

  return (
    <>
      <Formik
        initialValues={cleanResource}
        validationSchema={object().shape(cleanSchema)}
        onSubmit={async (values, {resetForm}) => {
          await save(values);
          resetForm();
        }}
      >
        {({values, errors, setFieldValue, resetForm}) => {
          const error = errors[name];
          return (
            <FormikForm>
              <div className="tr-flex tr-justify-between tr-items-center">
                <h2 className={'capitalize-text'}>
                  {label}
                  <Tooltip
                    size="xl"
                    content={
                      <span className="tr-grid tr-grid-cols-1 tr-gap-y-4 tl-w-90">
                        <span className="tr-font-bold">{_.capitalize(name)}</span> Only Trustle Users who are employees
                        can be added as {name}
                      </span>
                    }
                  >
                    <Icon type="moreInfo" size="sm" className="tr-text-trustle-link hover:tr-text-trustle-link" />
                  </Tooltip>
                </h2>
                <IconButton
                  variant="ternary"
                  data-testid={`modify ${label}`}
                  onClick={() => {
                    setFieldValue(name, initialList);
                    setOpen(true);
                  }}
                  className="tr-text-trustle-link hover:tr-text-trustle-link"
                  ignoreDefaultColor={true}
                  disabled={!canEdit}
                  icon="edit"
                />
              </div>
              <div className="tr-flex tr-justify-between tr-items-center flex-wrap ">
                {fixedOptions &&
                  fixedOptions.map((user: string) => {
                    const storeUser = usersStore.getByEmail(user);
                    const role = resource.getResourceRole(storeUser);
                    return (
                      storeUser && (
                        <UserAvatarAndName
                          displayName={formatUserName(storeUser)}
                          isOwner={storeUser.isOrgOwner}
                          subtitle={role}
                          size="sm"
                          wrapper={true}
                        />
                      )
                    );
                  })}
                {initialList.map((email: string) => {
                  const storeUser = usersStore.getByEmail(email);
                  const role = resource.getResourceRole(storeUser);

                  return (
                    storeUser && (
                      <div key={storeUser.id} className="user-avatar-wrapper p-2 m-1 shadow-sm">
                        <UserAvatarAndName
                          displayName={formatUserName(storeUser)}
                          isOwner={storeUser.isOrgOwner}
                          subtitle={role}
                          deleteAction={() => {
                            setShowDeletingConfirm(storeUser);
                          }}
                          size="sm"
                        />
                      </div>
                    )
                  );
                })}
              </div>

              {showDeletingConfirm && (
                <Confirmation
                  title={
                    <div className="tr-flex">
                      <Icon type="remove" />
                      <span className="ml-1">Remove {label}?</span>
                    </div>
                  }
                  onClose={() => {
                    setShowDeletingConfirm(undefined);
                  }}
                  onConfirm={async () => {
                    const values: any = {};
                    values[name] = _.filter(list, (val) => {
                      return val !== showDeletingConfirm.email;
                    });
                    await save(values);
                    resetForm(values);
                    setShowDeletingConfirm(undefined);
                  }}
                >
                  Are you sure you want to remove {showDeletingConfirm.email} from {label} list?
                </Confirmation>
              )}
              <Modal
                visible={open}
                width="md"
                onClose={() => {
                  setOpen(false);
                  setFieldValue(name, initialList);
                }}
                title={_.upperFirst(label)}
                footer={
                  <Button
                    type="submit"
                    onClick={async () => {
                      await save(values);
                      setOpen(false);
                    }}
                    data-testid={`${label}-modal-confirm`}
                  >
                    Save
                  </Button>
                }
              >
                <Field
                  component={Select}
                  name={name}
                  isMulti={true}
                  description={`Add and remove users below. Only Trustle Users who are Employees can be added as ${_.capitalize(
                    name
                  )} for Systems`}
                  data-testid={`modify ${name}`}
                  label={`Select`}
                  required={true}
                  options={_.map(usersStore.activeAndPendingEmployees, (suggestion: any) => {
                    return {
                      id: suggestion.id,
                      value: `${suggestion.email}`,
                      label: formatUserNameAndEmail(suggestion) ?? `${suggestion.email}`,
                    };
                  })}
                />
                {error && (
                  <div className="feedback tr-flex tr-items-center tr-justify-center">
                    <Icon type="error" title="Error" />
                    <span className="grayed-out">{error}</span>
                  </div>
                )}
              </Modal>
            </FormikForm>
          );
        }}
      </Formik>
    </>
  );
});

export default UsersCardSelector;
