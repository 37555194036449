import _ from 'lodash';
import React, {useState} from 'react';
import {UserT} from 'src/types';
import './MergeDisplay.scss';
import MergeDisplayTable from './MergeDisplayTable';
import {Button, Icon, Tab, Tooltip, Alert} from '@trustle/component-library';

type PropsT = {
  records: UserChangeRecordT[];
  setRecords: (arg: any) => void;
  next: (err?: any) => JSX.Element;
  prev: () => JSX.Element;
  filename: string;
  rowsCount: number;
  onClose: () => void;
  error?: string;
};

export enum RowAction {
  MISSING = 'missing',
  NEW = 'new',
  CHANGED = 'changed',
  NONE = 'none',
}

export type UserChangeRecordT = {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  type?: string;
  managerEmail: string;
  allowLogin: boolean;
  status: RowAction;
  title?: string;
  diffs: string[];
  curr?: UserT;
  action: string;
  doAction: boolean;
};

function MergeDisplay(props: PropsT) {
  const {records, next, setRecords, filename, rowsCount, onClose, error} = props;
  const [alert, setAlert] = useState<string | undefined>();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const onSave = () => {
    setRecords((old: any) =>
      old.map((record: any) => {
        if (_.includes([RowAction.NEW, RowAction.CHANGED], record.status)) {
          return {...record, doAction: true};
        }
        return record;
      })
    );

    next();
  };

  const count = (status: RowAction, activeOnly: boolean) => {
    return _.size(
      _.filter(records, (r) => {
        return r.status === status && (!activeOnly || r.doAction);
      })
    );
  };

  const countAdded = count(RowAction.NEW, false);
  const countUpdated = count(RowAction.CHANGED, false);
  const countDeleted = count(RowAction.MISSING, true);
  const total = countAdded + countUpdated + countDeleted;

  return (
    <div className="merge-display">
      {(alert || error) && <Alert colorVariant={'danger'}>{alert ?? error}</Alert>}

      <div className="tr-flex tr-mb-4">
        <div className="tr-ml-1 tr-mt-3 body6">
          <strong>{`${rowsCount} rows `}</strong>
          <span>{'detected on the uploaded file: '}</span>
          <strong>{filename}</strong>
          <span className="tr-mt-3 body6">
            <span className="tr-mx-3">{' >> '}</span>
            <strong>{`${countAdded + countUpdated + countDeleted}`}</strong>
            <span>{' users to be processed'}</span>
          </span>
        </div>
      </div>

      <div className="tr-flex">
        <Tab.Group selectedIndex={selectedIndex} onChange={(i: number) => setSelectedIndex(i)}>
          <Tab.List className="border-bottom">
            <Tab key={RowAction.NEW}>{`New (${countAdded})`}</Tab>
            <Tab key={RowAction.CHANGED}>{`Updates (${countUpdated})`}</Tab>
            <Tab key={RowAction.MISSING}>
              <div className="tr-flex">
                {`Offboarded (${countDeleted})`}
                <Tooltip
                  content={
                    <div className="text-secondary">
                      {countDeleted > 0 && (
                        <p>
                          {'This upload will remove '}
                          <strong>{`${countDeleted} user${countDeleted > 1 ? 's' : ''}`}</strong>
                          {' from your Trustle organization.'}
                        </p>
                      )}
                      {countDeleted === 0 && (
                        <>
                          {"This upload won't remove any user from your Trustle organization, unless you select them."}
                        </>
                      )}
                    </div>
                  }
                  className="tr-font-normal"
                >
                  <div className={'text-danger tr-ml-2'}>
                    <Icon type={'warning'} className={`cursor danger`} />
                  </div>
                </Tooltip>
              </div>
            </Tab>
          </Tab.List>
        </Tab.Group>
      </div>

      <MergeDisplayTable
        status={selectedIndex === 0 ? RowAction.NEW : selectedIndex === 1 ? RowAction.CHANGED : RowAction.MISSING}
        records={records}
        setRecords={setRecords}
        onError={setAlert}
      />

      <div className="tr-flex tr-flex-row-reverse">
        <Button onClick={() => onSave()} disabled={total === 0 || !!error || !!alert}>
          <div>{`Confirm (${total})`}</div>
        </Button>
        <Button variant="secondary" onClick={() => onClose()}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

export default MergeDisplay;
