import {Dropdown, DropdownItem, DropdownMenu, Icon} from '@trustle/component-library';
import React, {useMemo} from 'react';
import {useSaveFilterView} from './useSaveFilterView';
import {FilterToApply} from '../../../types';

type Props = {
  setFilters: (filters: FilterToApply[]) => void;
  filters: FilterToApply[];
};

export function SelectSavedFilterView({setFilters, filters}: Props) {
  const {getAllFilterViews, matchedFilterView} = useSaveFilterView();

  const selectedFilterView = useMemo(() => {
    return matchedFilterView(filters);
  }, [filters]);

  const renderMenuItems = () => {
    const allFilterViews = getAllFilterViews();
    if (allFilterViews.length === 0) {
      return [];
    }

    return allFilterViews.map(({name, filters}) => {
      return (
        <DropdownItem
          key={name}
          onClick={() => {
            setFilters(filters);
          }}
        >
          {name}
        </DropdownItem>
      );
    });
  };

  return (
    <div className="tr-flex tr-items-center tr-flex-col tr-gap-2 tr-mt-4">
      <div className="tr-flex tr-items-center tr-w-full">
        <Dropdown
          dropdownMenu={<DropdownMenu>{renderMenuItems()}</DropdownMenu>}
          variant={'secondary'}
          size={'md'}
          dropdownToggle={
            <div className="tr-flex tr-gap-1 tr-items-center">
              <Icon type="save" size="md" />
              <span className="tr-mb-1">{selectedFilterView ? selectedFilterView.name : 'Saved Filters'}</span>
            </div>
          }
        />
      </div>
    </div>
  );
}
