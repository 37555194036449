import React from 'react';
import {observer} from 'mobx-react-lite';
import {Icon, Tooltip} from '@trustle/component-library';
import {Resource} from 'src/stores/domainObjects/Resource';
import _ from 'lodash';

const AutomatedProvisioningModeTooltip = observer((props: {resource?: Resource; title?: string}) => {
  const isSystem = _.isNil(props.resource) ? true : props.resource.isSystem;
  const title = isSystem ? 'Automated Provisioning Mode' : 'Provisioning Mode';
  return (
    <>
      <span className="body4">{title}</span>
      <Tooltip
        size="xl"
        content={
          <div className="tr-grid tr-grid-cols-1 tr-gap-y-4 tl-w-90">
            <div>This will set the level of human interaction required.</div>
            <div>
              <strong>Off</strong> - Trustle will operate in a “read-only” capacity for this{' '}
              {isSystem ? 'system' : 'resource'}. Trustle will Import data, but will not change anything on the remote
              System nor will Tasks generate tasks access requests.
            </div>

            <div>
              <strong>Manual</strong> - will allow Trustle to create provisioning/deprovisioning tasks, However The
              action itself will need to be completed outside of Trustle
            </div>

            <div>
              <strong>Interactive</strong>, will create tasks in Trustle and will complete the action for you on the
              remote system upon approval in Trustle.
            </div>

            <div>
              <strong>Automatic</strong>, will automatically provision and deprovision users on the remote system and
              will notify you as it happens.
            </div>
          </div>
        }
      >
        <Icon type="moreInfo" size="sm" className="tr-text-trustle-link" />
      </Tooltip>
    </>
  );
});

export default AutomatedProvisioningModeTooltip;
