import _ from 'lodash';
import {UserType} from 'src/types';

export type AuthenticationTargetT = {
  userType: UserType;
  domain: string;
};

export type SamlConfigurationT = {
  id?: string;
  oid?: string;
  audience?: string;
  requireEncryption?: boolean;
  signRequest?: boolean;
  orgCert?: string;
  idpIssuer?: string;
  idpCert?: string;
  idpLoginUrl?: string;
  idpLogoutUrl?: string;
  authnContextClass?: string;
  nameidFormat?: string;
};

export type AuthenticationPolicyT = {
  id: string;
  oid: string;
  name: string;
  type: string;
  createdAt: string;
  active: boolean;
  targets: AuthenticationTargetT[];
  deletedAt?: string;
  settings?: SamlConfigurationT;
};

export const AuthPolicyDisplayNameMap = {
  saml: 'SAML 2.0',
  google: 'Google OAuth',
  password: 'Email/Password',
};

export function authDisplayName(authPolicy: AuthenticationPolicyT): string {
  return _.get(AuthPolicyDisplayNameMap, authPolicy.name, authPolicy.name);
}
