import {Button, Modal} from '@trustle/component-library';
import React from 'react';
import {formatUserName} from '../../../../../../../../lib';
import axios from 'axios';
import {useRootStore} from '../../../../../../../../lib/hooks';
import {UserActionModalProps} from '../types';

export function UserToggleOwnerRoleModal({user, handleCloseFn, onConfirm}: UserActionModalProps) {
  const {toast} = useRootStore();
  const {id, isOrgOwner} = user;

  const handleConfirm = async () => {
    try {
      await axios.post(`/api/orgs/admin/${id}/owner`, {isOwner: !isOrgOwner});
      toast.success("User's role changed successfully");
    } catch (error) {
      toast.error("User's role couldn't be changed");
    } finally {
      onConfirm?.();
      handleCloseFn();
    }
  };

  return (
    <Modal onClose={handleCloseFn} title={'Confirm action'}>
      <div>
        {`Please, confirm that you want to ${isOrgOwner ? 'remove' : 'add'} the following user as an org owner: `}
        <strong>{formatUserName(user)}</strong>
        <div className="tr-flex tr-justify-end tr-mt-4">
          <Button onClick={() => handleCloseFn()} variant="secondary">
            Cancel
          </Button>
          <Button onClick={() => handleConfirm()}>Confirm</Button>
        </div>
      </div>
    </Modal>
  );
}
