import React from 'react';
import _ from 'lodash';
import {AuthzOwnerAWS} from 'src/types';
import ShowUserAccount from '../common/editConnection/ShowUserAccount';
type TestConnectionT = {ok: boolean; message?: string; authorizedBy?: string} | {error: {message: string}};
export type ParsedTestConnectionT = {
  ok: boolean;
  message: string;
  authzOwner?: AuthzOwnerAWS;
};

export function showTestConnection(resultTestConnection: ParsedTestConnectionT | undefined) {
  if (_.isNil(resultTestConnection)) {
    return <></>;
  }
  if (resultTestConnection.ok) {
    return (
      <span className="text-success">
        {resultTestConnection.message}
        {resultTestConnection.authzOwner ? (
          <div className="body5 mb-1">
            <ShowUserAccount authzOwner={resultTestConnection.authzOwner} label={'Authorized by '} />
          </div>
        ) : (
          ''
        )}
      </span>
    );
  } else {
    return <span className="text-danger">{resultTestConnection.message}</span>;
  }
}

export async function parseResponse(
  response: TestConnectionT
): Promise<{ok: boolean; message: string; authzOwner?: AuthzOwnerAWS}> {
  if (_.has(response, 'error')) {
    return {ok: false, message: _.get(response, 'error.message', 'An error ocurred')};
  }
  if (_.get(response, 'ok', undefined) === false) {
    return {ok: false, message: _.get(response, 'message', 'An error ocurred')};
  }
  const authorized = _.get(response, 'authzOwner', undefined);
  return {
    ok: true,
    message: _.get(response, 'message', 'Connected Successfully'),
    authzOwner: authorized,
  };
}
