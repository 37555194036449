import axios from 'axios';
import _ from 'lodash';
import {makeAutoObservable, onBecomeObserved, runInAction} from 'mobx';
import {logger} from 'src/lib';
import {OnboardingModeE, UserT, UserType} from 'src/types';
import RootStore from '.';
import AccountsStore from './AccountStore';
import {User} from './domainObjects/User';
import moment from 'moment';

export enum UserStatus {
  ACTIVE = 'active',
  DISABLED = 'disabled',
  PENDING_APPROVAL = 'pending',
  OFFBOARDED = 'offboarded',
  INVALID = 'invalid',
  DELETED = 'deleted',
}

export enum UserAction {
  VIEW_USER = 'View User',
  START_OFFBOARD = 'Offboard User',
  UPLOAD_CSV = 'Upload CSV',
  ACTIVATE_USER = 'Activate User',
  ENABLE_USER = 'Enable User',
  DISABLE_USER = 'Disable User',
  ADD_OWNER_ROLE = 'Add Owner Role',
  REMOVE_OWNER_ROLE = 'Remove Owner Role',
  BULK_ACTION = 'Bulk Action',
  RESEND_INVITE = 'Resend Invite',
  SEND_INVITE = 'Send Invite',
  SWITCH_AUTHORITY = 'Switch Authority',
  SYNC_USERS = 'Sync Users',
  IDP_SYNC = 'IDP Sync',
  IDP_SETUP = 'IDP Setup',
  DELETE = 'Delete',
}

export type TrustleEventT = {
  created: string;
  event: string;
  details: _.Dictionary<string>;
  by: UserT;
};

export type TrustleAnalyticEventT = {
  created: string;
  id: string;
  data: {
    name: string;
    mode: string;
  };
  uid: string;
};

// TODO (jg): import types directly from backend once path mapping is supported
export type UserResponseT = UserT;
export type CreateUserRequestT = {
  firstname: string;
  lastname: string;
  department?: string;
  remoteRole?: string;
  title?: string;
  email: string;
  managerEmail?: string;
  createdByEmail?: string;
  onboardingMode?: OnboardingModeE;
  startDate: any;
  type: UserType;
  allowLogin: boolean;
  isOrgOwner?: boolean;
  activateUser?: boolean;
};

export type UserTeamResponseT = {
  color: string;
  created: string;
  description: string;
  id: string;
  managed: boolean;
  name: string;
  oid: string;
  tombstone: boolean;
  type: string;
};

class UsersStore {
  usersMap: Record<string, User> = {};
  loading: boolean = false;
  usersLoadedAtLeastOnce = false;
  error?: any = undefined;
  accountsStore?: AccountsStore = undefined;
  newlyImported: any[] = [];
  systemList: any[] = [];

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this);

    onBecomeObserved(this, 'usersMap', () => {
      if (!this.usersLoadedAtLeastOnce && !this.loading) {
        this.loadUsers();
      }
    });

    this.accountsStore = rootStore.accountsStore;
  }

  async createUser(user: CreateUserRequestT, id?: string) {
    try {
      const {data} = await axios.post<User>('/api/orgs/onboard', {user, id});
      this.rootStore.toast.success('New user created. It will appear inactive until activated');
      this.updateUserFromServer({...data, createdByEmail: this.rootStore.currentUser?.email});

      if (user.isOrgOwner) {
        void this.usersMap[data.id].toggleOwnerRole();
      }

      return this.usersMap[data.id];
    } catch (err) {
      const message = `Error onboarding user. ${_.get(err, 'response.data.error.message')}`;
      this.rootStore.toast.error(message);

      return id ? this.usersMap[id] : undefined;
    }
  }

  updateUserFromServer(attributes: Partial<UserResponseT> & {id: string}) {
    // default null type to employee
    // TODO (jg): possibly remove this when org owner is initialized with a type
    attributes.type = attributes.type ?? UserType.employee;
    const existingUser = this.usersMap[attributes.id];
    if (existingUser) {
      Object.assign(existingUser, attributes);
    } else {
      this.usersMap[attributes.id] = new User(this.rootStore, attributes);
    }
  }

  async refresh() {
    this.loadUsers();
  }

  getByEmail(email: string): User | undefined {
    const user = this.users.find((user) => {
      return user.email === email;
    });
    return user;
  }

  /**
   * @deprecated observing usersMap or its derived computeds should automatically load users
   * and triggering this action shouldn't be necessary.
   */
  loadUsers() {
    if (!this.usersLoadedAtLeastOnce) {
      this.loading = true;
    }
    axios
      .get('/api/users/all?allowDeleted=true')
      .then((result) => {
        runInAction(() => {
          const users: UserResponseT[] = result.data.users ?? [];
          users.forEach((user) => {
            this.usersMap[user.id] = new User(this.rootStore, user);
          });
          this.loading = false;
          this.usersLoadedAtLeastOnce = true;
        });
      })
      .catch((err) => {
        logger.error(err);
        runInAction(() => {
          this.error = err;
          this.loading = false;
        });
      });
  }

  async resetUserPwd(uid: string) {
    try {
      await axios.post(`/api/auth/admin/${uid}/force_password_reset`);

      this.rootStore.toast.add('Process to reset password successfully triggered', {
        appearance: 'success',
        autoDismiss: true,
      });
    } catch (err: any) {
      logger.error(err);
      this.rootStore.toast.add('Error restarting password', {appearance: 'error', autoDismiss: true});
      this.error = true;
      return false;
    }
  }

  bulkAction(action: UserAction, users: User[]) {
    void runInAction(async () => {
      try {
        this.loading = true;

        if (!_.isEmpty(users)) {
          const sampleUser = _.first(users)!;
          const value = _.includes([UserAction.DISABLE_USER, UserAction.ENABLE_USER], action)
            ? !sampleUser.allowLogin
            : _.includes([UserAction.REMOVE_OWNER_ROLE, UserAction.ADD_OWNER_ROLE], action)
            ? !sampleUser.isOrgOwner
            : null;

          await axios.post(`/api/users/admin/bulk_action`, {action, uids: _.map(users, 'id'), value});

          if (UserAction.RESEND_INVITE === action) {
            for (const u of users) {
              u.invitedAt = moment().toISOString();
            }
          } else {
            this.loadUsers();
          }
        }
      } catch (err) {
        logger.error(err);
        this.rootStore.toast.add('Failed to perform bulk action.', {appearance: 'error', autoDismiss: true});
        this.error = true;
      }

      this.loading = false;
    });
  }

  toggleUserSelection(user: User) {
    runInAction(() => (user.selected = !user.selected));
  }
  selectMultiple(users: User[], checked: boolean) {
    runInAction(() => users.forEach((user) => (user.selected = checked)));
  }

  selectAll(checked: boolean) {
    runInAction(() => this.users.forEach((user) => (user.selected = checked)));
  }

  get users() {
    return _.values(this.usersMap);
  }

  /** @returns users that can login, aren't offboarded and are employees/contractors */
  get activeUsers() {
    return this.users.filter(
      (user) => user.allowLogin && !user.tombstone && [UserType.employee, UserType.contractor, null].includes(user.type)
    );
  }

  /** @returns users that are linked to some account in the context of the current connected system */
  get connectorUsers() {
    const linkedAccounts = this.accountsStore?.linkedAccounts;
    const linkedUsers = _.map(linkedAccounts, 'forUser');

    return this.activeUsers.filter((user) => _.includes(_.map(linkedUsers, 'id'), user.id));
  }

  /** @returns users that can login, aren't offboarded and are employees/contractors */
  get activeAndPendingUsers() {
    return this.users.filter((user) => !user.tombstone && !user.terminated);
  }

  get activeAndPendingEmployees() {
    return this.users.filter((user) => !user.tombstone && !user.terminated && user.type === UserType.employee);
  }

  get offBoardedUsers() {
    return this.users.filter((user) => user.tombstone || user.terminated);
  }

  /** @deprecated use rootStore.currentUser */
  get currentUser() {
    return this.rootStore.currentUser;
  }

  /** @returns the users that match with the parameter. */
  get suggestedUsers(): User[] {
    const linkedAccounts = this.accountsStore?.linkedAccounts;
    const linkedUsers = _.map(linkedAccounts, 'forUser');

    if (!this.accountsStore?.hasUnlinkedAccounts) {
      return this.activeAndPendingUsers.filter((user) => _.includes(_.map(linkedUsers, 'id'), user.id));
    } else {
      return this.activeAndPendingUsers;
    }
  }
  /** @returns the last user imported using import users  */
  get lastImportedUser() {
    return _.chain(this.users)
      .filter((user) => _.includes([OnboardingModeE.CSV, OnboardingModeE.IDP], user.onboardingMode))
      .maxBy('created')
      .value();
  }
  setNewlyImported(records: any[]) {
    this.newlyImported = records;
  }
}

export default UsersStore;
