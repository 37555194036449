import React, {useEffect, useState} from 'react';
import _ from 'lodash';
import {SensitivityLevelDefinitions, LevelDefinitionT} from './SensitivityConfiguration';
import {MinSensitivitySettingT} from 'src/types';
import {SensitivityCard} from '@trustle/component-library';

type PropsT = {
  sensitivityId?: string;
  sensitivitySettings: MinSensitivitySettingT[];
  onSelect: ({level, sensitivityId}: {level: string; sensitivityId: string}) => void;
  descriptionInTitle?: boolean;
};

export function SensitivityDefaultSelector(props: PropsT) {
  const {sensitivitySettings, sensitivityId} = props;
  const [selectedSensitivity, setSelectedSensitivity] = useState<LevelDefinitionT>();
  useEffect(() => {
    const defaultSensitivity = _.find(sensitivitySettings, {id: sensitivityId});

    if (defaultSensitivity) {
      const levelDefinition = _.find(SensitivityLevelDefinitions, {level: defaultSensitivity.level});
      setSelectedSensitivity(levelDefinition);
    }
  }, [sensitivityId]);

  const sensitivityCards = _.map(SensitivityLevelDefinitions, (levelDefinition: LevelDefinitionT) => {
    const {name, level} = levelDefinition;

    const handleOnClick = () => {
      const setting = _.find(sensitivitySettings, {level});
      if (setting) {
        setSelectedSensitivity(levelDefinition);
        props.onSelect({level, sensitivityId: setting.id});
      }
    };

    return (
      <SensitivityCard
        key={name}
        sensitivityLevelDefinition={levelDefinition}
        selectedLevel={selectedSensitivity?.level}
        onClick={handleOnClick}
      />
    );
  });

  return (
    <>
      <div className="tr-flex tr-justify-between tr-max-w-[1250px]">{sensitivityCards}</div>
    </>
  );
}
