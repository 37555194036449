import React, {useState} from 'react';
import axios from 'axios';
import PermissionActionModal from 'src/components/PermissionActionModal';
import {ResourceT} from 'src/types';
import {SubmitButton} from 'src/components/design';
import {Formik, FormikForm} from 'src/components/design/formik';
import {logger} from 'src/lib';
import {TextInput} from '@trustle/component-library';
import {Field} from 'formik';

type PropsT = {
  parent: ResourceT;
  closeModal: any;
  afterSubmit: any;
};

type CreatePermissionFormT = {
  label: string;
  description: string;
  durationValue: number | '';
  durationUnit: string;
};

function AddPermissionModal(props: PropsT) {
  const {closeModal, afterSubmit, parent} = props;
  const [selectedResource] = useState<any>({label: parent.name, value: parent.id});

  async function saveModal(formData: CreatePermissionFormT) {
    try {
      const data: any = {
        permission: formData,
      };
      await axios.post(`/api/resources/${selectedResource.value}/permission/create`, data);
      closeModal();
      afterSubmit();
      return {};
    } catch (err) {
      logger.error(err);
      return {msg: 'Failed to save.', error: true};
    }
  }

  return (
    <PermissionActionModal title="Create a permission" closeModal={closeModal}>
      <Formik
        initialValues={{
          label: '',
          description: '',
          durationUnit: parent?.durationUnit || '',
          durationValue: parent?.durationValue || '',
        }}
        onSubmit={saveModal}
      >
        {({isSubmitting}) => (
          <FormikForm noValidate>
            <Field component={TextInput} label="Name" name="label" required />
            <Field component={TextInput} label="Description" name="description" required />
            <div className="text-right">
              <SubmitButton inSubmit={isSubmitting} name="savePermission" label="Save" />
            </div>
          </FormikForm>
        )}
      </Formik>
    </PermissionActionModal>
  );
}

export {AddPermissionModal};
