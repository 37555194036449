import {useLicenseUsage} from './useLicenseUsage';
import {LicenseUsageResponse, ViewType} from '../types';
import _ from 'lodash';

type Props = {
  resourceId: string;
};

export function useGetLicenseUsagePercentage({resourceId}: Props) {
  const {fetchLicenseUsage, loading} = useLicenseUsage({resourceId});

  const calculateUsagePercentage = (data?: LicenseUsageResponse) => {
    if (!data) {
      return 0;
    }
    const {summary_statistics} = data;
    const {total_usage_hours, total_access_hours} = summary_statistics;
    return total_usage_hours ? Math.round((total_usage_hours / total_access_hours) * 100) : 0;
  };

  const getLicenseUsagePercentage = async (licenseId: string, view: ViewType) => {
    const data = await fetchLicenseUsage({licenses: [licenseId], view});
    if (_.isEmpty(data) || loading) {
      return null;
    }
    return calculateUsagePercentage(data);
  };

  return {getLicenseUsagePercentage};
}
