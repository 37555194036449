import _ from 'lodash';
import React from 'react';
import {useContext, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {GlobalInfoContext} from 'src/context';
import axios from 'axios';
import PwnedPasswordModal from '../../../components/modals/PwnedPasswordModal';
import {useToasts} from 'react-toast-notifications';
import {useRootStore} from 'src/lib/hooks';
import {PasswordInput} from '@trustle/component-library';
import {Field, Form, Formik} from 'formik';
import {object, string} from 'yup';
import {SubmitButton} from 'src/components/design';

export const passwordPolicyHint =
  'Password must be at least eight characters long, including one uppercase letter, one lowercase letter, one number, and one special character from the following set: #?!@$%^&*-()';

type ResetPwdPropsT = {
  closeModal?: () => void;
};
function ResetPasswordForm(props: ResetPwdPropsT) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {addToast} = useToasts();

  const {currentUser} = useRootStore();
  const globalInfo = useContext(GlobalInfoContext);
  const history = useHistory();

  if (_.isNil(currentUser)) {
    return <></>;
  }

  const showOldPassword = !(globalInfo.state.magicData && globalInfo.state.magicData.userCanResetPassword);

  return (
    <>
      <Formik
        initialValues={{oldPassword: '', newPassword: ''}}
        validationSchema={object().shape({
          newPassword: string().required('Please fill out this field'),
        })}
        onSubmit={async (values) => {
          try {
            const {data} = await axios.post('/api/auth/password_check', {password: values.newPassword});
            if (data.potentiallyExposed) {
              setModalIsOpen(true);
              return;
            }
            await axios.post('/api/auth/reset_pw', values);
            addToast('Password reset successful', {
              appearance: 'success',
              autoDismiss: true,
            });
            if (props.closeModal) {
              props.closeModal();
            } else {
              history.push('/home');
            }
          } catch (err) {
            addToast('Password reset errored', {
              appearance: 'success',
              autoDismiss: true,
            });
          }
        }}
        enableReinitialize
      >
        {({isValid}) => {
          return (
            <Form className="text-dark-gray">
              {showOldPassword && (
                <Field
                  component={PasswordInput}
                  label="Your Current Password"
                  placeholder="--------"
                  showreveal={true}
                  id="oldPassword"
                  name="oldPassword"
                  key="oldPassword"
                  required
                />
              )}
              <Field
                component={PasswordInput}
                label="Your New Password"
                placeholder="--------"
                showreveal={true}
                name="newPassword"
                id="newPassword"
                key="newPassword"
                required
                description={passwordPolicyHint}
              />
              <SubmitButton name="submit" label={`Reset Password`} disabled={!isValid} />
            </Form>
          );
        }}
      </Formik>
      <div className="small">Profile setup for {currentUser.email}.</div>
      {modalIsOpen ? <PwnedPasswordModal closeModal={() => setModalIsOpen(false)} /> : null}
    </>
  );
}

export default ResetPasswordForm;
