import React from 'react';
import {OrgT, MinSensitivitySettingT, currentUserT, LegalDocName, SensitivitySettingT, IDPIntegration} from './types';
import {UserFilter} from './stores/domainObjects/Org';

export type GlobalInfoT = {
  get: (key: string) => any;
  set: (key: string, value: any) => void;
  clear: (key: string) => void;

  state: {
    checkEmailEmail?: string;
    magicData?: {
      userCanResetPassword: boolean;
    };
  };
  version: string;
};
const GlobalInfoContext = React.createContext({} as GlobalInfoT);

export type AuthInfoT = {
  loginMethod: string;
  user: currentUserT;
  defaultSensitivityId?: string;
  org: OrgT;
  sensitivitySettings: SensitivitySettingT[];
  magicInfo?: {
    error: {
      message: string;
      payload: any;
    };
  };
  env: 'development' | 'production' | 'staging' | '';
  publishableKeys: {
    stripe: string;
    authPubKey: string;
  };
  loadAuth: (args?: any) => void;
  reloadAuth: () => void;
  logout: () => void;
  isLoggedIn: boolean;
  featureFlags?: string[];
  defaultSensitivitySetting?: MinSensitivitySettingT;
  idpSettings: IDPIntegration;
  userAgreements?: LegalAgreementT[];
  filters?: UserFilter[];
};

export type LegalAgreementT = {
  uid: string;
  oid: string;
  ipAddress: string;
  email: string;
  docName: LegalDocName;
  docVersion: string;
  agreedOn?: string;
};

export {GlobalInfoContext};
