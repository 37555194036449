import {Button, Modal, TextInput} from '@trustle/component-library';
import React, {useState} from 'react';
import {useRootStore} from '../../../../../../../lib/hooks';
import {FilterToApply} from '../../../types';
import {useSaveFilterView} from './useSaveFilterView';

type Props = {
  filters: FilterToApply[];
  setFilters: (filters: FilterToApply[]) => void;
};

export function SaveFilterButtonModal({filters, setFilters}: Props) {
  const [showModal, setShowModal] = useState(false);
  const [filterName, setFilterName] = useState('');
  const {saveFilterView} = useSaveFilterView();
  const {toast} = useRootStore();

  const handleConfirm = () => {
    toast.success('Filter View saved successfully');
    saveFilterView(filterName, filters);
    setFilters([...filters]);
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <Button variant="secondary" onClick={() => setShowModal(true)}>
        Save Filters View
      </Button>
      {showModal && (
        <Modal onClose={handleClose} title={'Save Filter View'}>
          <div>
            <TextInput
              label="First Name"
              value={filterName}
              onInput={(e: any) => {
                setFilterName(e.target.value);
              }}
            />
            <div className="tr-flex tr-justify-end tr-mt-4">
              <Button onClick={handleClose} variant="secondary">
                Cancel
              </Button>
              <Button onClick={handleConfirm} disabled={!filterName}>
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
