import _ from 'lodash';
import {ConnectionT} from 'src/types';

function isProd(): boolean {
  return process.env.NODE_ENV === 'production';
}

function isDevelopment(): boolean {
  return _.includes(['development', 'test'], process.env.NODE_ENV);
}

function getConnectorOAuthRedirect(connection: ConnectionT, params: _.Dictionary<any>) {
  const urlParams = new URLSearchParams({
    connectionId: connection.id,
    ...params,
  });
  return `/api/connect/${connection.service}/oauth/start?${urlParams.toString()}`;
}
const maxAgeSessionMinutes = 30;
export {isProd, isDevelopment, getConnectorOAuthRedirect, maxAgeSessionMinutes};
