import React from 'react';
import {useQueryParams} from 'src/lib';

export default function SlackPostInstall() {
  const [errorMessage] = useQueryParams(['error']);
  if (errorMessage) {
    return (
      <div className="container">
        <div className="tr-flex tr-flex-col">
          <h2>Slack Application Install Failed</h2>
          <p>
            The installation failed with the following error: <b>{errorMessage}</b>
          </p>
        </div>
      </div>
    );
  }
  return (
    <div className="container">
      <div className="tr-flex tr-flex-col">
        <h2>Slack Application Installed</h2>
        <p>Trustle is now connected to your Slack workspace.</p>
        <p>Slack will now be used to notify your team when workflow tasks are assigned to them.</p>
      </div>
    </div>
  );
}
