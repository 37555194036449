import React, {useEffect} from 'react';
import {Icon, Table, TileButton, Tooltip} from '@trustle/component-library';
import {useTeams} from '../admin-users-panel/hooks/useTeams';
import {formatFullDate, formatDateFromNow, formatUserName} from 'src/lib';
import {TableStatus} from '../../common/TableStatus';
import {useHistory} from 'react-router-dom';
import {TeamT} from 'src/types';
import {useTeamFilters} from './TeamsFiltersContext';
import {SystemIconsTypesEnum} from '@trustle/component-library/dist/types';

export function TeamsTable() {
  const {data: listTeamsResponse, loading, fetchTeams} = useTeams();
  const {filters, setFilter, setFilters} = useTeamFilters();
  const history = useHistory();

  const handleSizePerPageChange = (size: number) => {
    setFilter('size', size);
  };

  useEffect(() => {
    void fetchTeams({...filters});
  }, [filters]);

  if (loading || !listTeamsResponse) {
    return <TableStatus text="Loading teams..." status="loading" description="Please stand by" />;
  }
  const {teams, total, page} = listTeamsResponse;

  return (
    <Table
      data={teams}
      columns={
        [
          {
            dataField: 'color',
            formatter: (color: string) => {
              return <div className={`ml-2 selection-option-color team-gradient-${color}`}></div>;
            },
            headerStyle: {width: '4em'},
            style: {width: '4em', paddingTop: '20px', paddingBottom: '20px'},
          },
          {
            text: 'Name',
            dataField: 'name',
            sort: true,
            formatter: (name: string, team: TeamT) => {
              return (
                <div
                  className="tr-cursor-pointer"
                  onClick={() => {
                    history.push({
                      pathname: `/teams/${team.id}`,
                      state: {team},
                    });
                  }}
                >
                  {name}
                </div>
              );
            },
          },
          {
            text: 'Members',
            sort: true,
            dataField: 'userCount',
            formatter: (userCount: number, team: TeamT) => {
              return (
                <div
                  className="tr-cursor-pointer"
                  onClick={() => {
                    history.push({
                      pathname: `/teams/${team.id}`,
                      state: {team},
                    });
                  }}
                >
                  {userCount}
                </div>
              );
            },
          },
          {
            text: 'Managers',
            sort: true,
            dataField: 'managers',
            formatter: (managers: any[], team: TeamT) => {
              return (
                <div
                  className="tr-cursor-pointer"
                  onClick={() => {
                    history.push({
                      pathname: `/teams/${team.id}`,
                      state: {team},
                    });
                  }}
                >
                  {managers.map((manager) => (
                    <div key={manager.id}>{formatUserName(manager)}</div>
                  ))}
                </div>
              );
            },
          },
          {
            text: 'Last Activity',
            dataField: 'lastActivity',
            sort: true,
            formatter: (lastActivity: string, team: TeamT) => {
              return (
                <div
                  className="tr-cursor-pointer"
                  onClick={() => {
                    history.push({
                      pathname: `/teams/${team.id}`,
                      state: {team},
                    });
                  }}
                >
                  {!lastActivity ? (
                    <Icon type="emptyData" title="Never" size="sm" />
                  ) : (
                    <Tooltip position="top" content={formatFullDate(lastActivity)}>
                      {formatDateFromNow(lastActivity)}
                    </Tooltip>
                  )}
                </div>
              );
            },
          },
          {
            text: 'Created by',
            dataField: 'service',
            sort: true,
            formatter: (service: string) => {
              return (
                <TileButton
                  size="md"
                  className="tr-my-1 tr-mx-1"
                  name={(service ?? 'trustle') as SystemIconsTypesEnum}
                  data-testid={`manage-${service}`}
                  key={service}
                />
              );
            },
          },
          {
            text: '',
            classes: 'show-on-hover',
            headerStyle: {width: '4rem'},
            style: {width: '4rem'},
            formatter: (_actions: string, team: TeamT) => {
              return (
                <div className="action-buttons tr-pr-8">
                  <Icon
                    className="tr-cursor-pointer"
                    type="chevronRight"
                    size="sm"
                    onClick={() => {
                      history.push({
                        pathname: `/teams/${team.id}`,
                        state: {team},
                      });
                    }}
                  />
                </div>
              );
            },
          },
        ] as any
      }
      tableKey="teams-summary-table"
      totalSize={total}
      sizePerPage={filters.size}
      page={page}
      rowClasses={() => {
        return 'tr-cursor-pointer';
      }}
      rowEvents={{
        onClick: (_e, row) => {
          history.push({
            pathname: `/teams/${row.id}`,
            state: {team: row},
          });
        },
      }}
      remote={{
        filter: true,
        pagination: true,
        sort: true,
        cellEdit: false,
      }}
      onSizePerPageChange={(size) => {
        handleSizePerPageChange(size);
        return {};
      }}
      onTableChange={(type: string, props: any) => {
        if (type === 'sort') {
          setFilters({...filters, sort: props.sortOrder, orderBy: props.sortField});
        } else {
          setFilter('page', props.page);
        }
      }}
      showEmptyElement={<TableStatus status="no-data" text="No teams found" />}
    />
  );
}
