import React from 'react';
import {Button, Modal} from '@trustle/component-library';
import {formatUserName} from '../../../../../../../../lib';
import axios from 'axios';
import _ from 'lodash';
import {useRootStore} from '../../../../../../../../lib/hooks';
import {UserActionModalProps} from '../types';

export function UserDeleteModal({user, handleCloseFn, onConfirm}: UserActionModalProps) {
  const {toast} = useRootStore();

  const handleConfirm = async () => {
    try {
      await axios.post(`/api/users/admin/bulk_action`, {action: 'Delete', uids: _.map([user], 'id')});
    } catch (error) {
      toast.add('Failed to delete user.', {appearance: 'error', autoDismiss: true});
    } finally {
      onConfirm?.();
      handleCloseFn();
    }
  };

  return (
    <Modal onClose={handleCloseFn} title={'Confirm delete'}>
      <div className="tr-flex tr-flex-col tr-gap-2">
        <p>
          Are you sure? This will delete user <strong>{formatUserName(user)}</strong> and all related data. This action
          is permanent and cannot be undone
        </p>
      </div>
      <div className="tr-flex tr-justify-end tr-mt-4">
        <Button variant="secondary" colorVariant="danger" onClick={handleCloseFn}>
          Cancel
        </Button>
        <Button variant="primary" colorVariant="danger" onClick={handleConfirm}>
          Yes, Delete User
        </Button>
      </div>
    </Modal>
  );
}
