import React from 'react';
import {formatFullDate} from 'src/lib';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Modal} from '@trustle/component-library';
import ResourceEdit from '../ResourceEdit';
import {observer} from 'mobx-react';

type PropsT = {
  resource: Resource;
  visible: boolean;
  isCreating: boolean;
  refresh: () => void;
  closeModal: () => void;
};

const EditResourceModal = observer(function EditResourceModal(props: PropsT) {
  const {resource, closeModal, visible} = props;
  return (
    <>
      <Modal onClose={closeModal} width="lg" visible={visible} title={<span>{resource.name}</span>}>
        <div className="tr-flex tr-pt-2 tr-pb-2 tr-m-1 permission-dates-wrapper description-row tr-text-left">
          <div className="tr-flex tr-w-2/12 tr-p-0 body6 tr-font-bold tr-text-center">Date Added:</div>
          <div className="tr-flex tr-w-1/3 tr-p-0 body6">
            {resource.createdAt && formatFullDate(resource.createdAt)}
          </div>
          <div className="tr-flex tr-w-2/12 tr-p-0 body6 tr-font-bold tr-text-center">Edited:</div>
          <div className="tr-flex tr-w-1/3 tr-p-0 body6">
            {resource.lastChange ? (
              <>{resource.lastChange && resource.lastChange.created && formatFullDate(resource.lastChange.created)}</>
            ) : (
              'Never edited'
            )}
          </div>
        </div>
        <ResourceEdit resource={resource} cancel={closeModal} />
      </Modal>
    </>
  );
});

export default EditResourceModal;
