import React from 'react';
import _ from 'lodash';
import {Icon} from '@trustle/component-library';
import {User} from 'src/stores/domainObjects/User';
import moment from 'moment';
import {observer} from 'mobx-react';
import {UserStatus} from 'src/stores/usersStore';
import {StatusE} from './StatusIndicator';

const InviteStatusIndicator = observer(function fn({user}: {user: User}) {
  const invitationExpired = moment().diff(moment(user.invitedAt), 'days') > 1;
  const invitationString = `Invited on ${moment(user.invitedAt).format('M/D/Y hh:mma')}`;
  const statusClass =
    user.status === UserStatus.ACTIVE
      ? StatusE.SUCCESS
      : user.status === UserStatus.DISABLED
      ? StatusE.DISABLED
      : user.status === UserStatus.PENDING_APPROVAL
      ? StatusE.PENDING
      : StatusE.FAILED;

  const statusVariant = {
    success: 'tr-bg-status-success',
    pending: 'tr-bg-status-pending',
    failed: 'tr-bg-status-failed',
    disabled: 'tr-bg-status-disabled',
  };

  return (
    <div className="tr-flex tr-items-center tr-justify-center ">
      {user.status !== UserStatus.DISABLED &&
      user.status !== UserStatus.OFFBOARDED &&
      user.invitedAt &&
      user.status !== UserStatus.ACTIVE ? (
        invitationExpired ? (
          <span data-testid="emailExpired">
            <Icon type="emailExpired" title={`${invitationString}, it is expired now`} size="sm" />
          </span>
        ) : (
          <span data-testid="emailPending">
            <Icon type="emailPending" title={`${invitationString}`} size="sm" />
          </span>
        )
      ) : (
        <div
          data-testid={`status${user.status}`}
          className="tr-flex tr-items-center tr-space-x-2 tr-w-6 tr-justify-center"
          title={`User Status: ${_.capitalize(user.status)}`}
        >
          <div className={`tr-w-[9px] tr-h-[9px] ${statusVariant[statusClass]} tr-rounded-full`}></div>
        </div>
      )}
    </div>
  );
});

export default InviteStatusIndicator;
