import _ from 'lodash';
import {observer} from 'mobx-react-lite';
import React from 'react';
import {Button, ColumnDescription, Select, Loading} from '@trustle/component-library';
import {Link, useHistory} from 'react-router-dom';
import {formatDateFromNow, formatFullDate} from 'src/lib';
import {Account} from 'src/stores/domainObjects/Account';
import {Resource} from 'src/stores/domainObjects/Resource';
import {AccountAccessType, ConnectionServiceE, UserType} from 'src/types';
import RiskBadge from 'src/views/Resource/Account/RiskBadge';
import UsageBadge from 'src/views/Resource/Account/UsageBadge';
import UserAccountColumnContent from '../common/UserAccount';
import MapUser from 'src/views/Resource/Account/MapUser';
import AccountActionsDropdown from 'src/views/Resource/Account/AccountActionsDropdown';
import moment from 'moment';
import {getAccountSecurityWarningMessages} from 'src/views/Resource/Account/AdditionalInformationForAccount';
import {UserInfo, Icon, Tooltip} from '@trustle/component-library';
import StatusIndicator, {translateAccountStatus} from 'src/components/StatusIndicator';
import ShowAuthorizedAccount from '../common/ShowAuthorizedAccount';
import {OptionProps} from 'react-select';

export type OptionType = {
  value: string;
  label: string;
  meta?: string;
};
export enum AccountColumnsE {
  FLAG = 'Flag',
  STATUS = 'Status',
  ISSUE = 'Issue',
  ACCESSES = 'Accesses',
  USER_ACCOUNT = 'Account/User',
  ACCOUNT_NAME = 'System Account',
  ACCOUNT_WITH_STATUS = 'Account',
  ACCOUNT_TYPE = 'Type',
  TRUSTLE_USER = 'Trustle User',
  CONNECTED_APPS = 'Connected Apps',
  OFFBOARDED_BY = 'Offboarded By',
  OFFBOARDED_DATE = 'Offboarded Date',
  LAST_LOGIN = 'Last Login',
  LAST_ACCESS = 'Last Access',
  CONSOLE_LAST_SIGN_IN = 'Console Last sign-in',
  LAST_ACTIVITY = 'Last Activity',
  MULTIPLE_ACTIONS = 'Actions',
  RISK_SCORE = 'Risk Score',
  USAGE = 'Usage',
  RECOMMENDATION = 'Recommendation',
  USER_TYPE = 'Trustle User Type',
  ACCOUNT_USER = 'System Account / Trustle User',
}

/** Shared Columns for use in Account Tables */

export const FlaggedForReviewColumn = {
  dataField: 'flaggedForReview',
  configTitle: AccountColumnsE.FLAG,
  text: '',
  headerStyle: {width: '2rem'},
  style: {width: '2rem'},
  sort: true,
  formatter: (flaggedForReview: boolean) =>
    flaggedForReview ? <Icon type="flagOff" size="sm" title="flagged for review" /> : <></>,
};

export const StatusColumn = {
  dataField: 'status',
  sort: true,
  configTitle: AccountColumnsE.STATUS,
  text: '',
  headerStyle: {width: '1em', paddingLeft: '0', paddingRight: '0'},
  style: {width: '1em', paddingLeft: '0', paddingRight: '0'},
  formatter: (_accountName: string, account: Account) => (
    <div>
      <StatusIndicator status={translateAccountStatus(account.status!)} messages={[_.capitalize(account.status!)]} />
    </div>
  ),
};

const ObservableIssue = observer(({account, onClick}: {account: Account; onClick?: (account: Account) => void}) => {
  const warnings = getAccountSecurityWarningMessages(account);
  const messages = (
    <div>
      {warnings?.map((message) => {
        return (
          <div key="err" className="tr-min-w-[120px] tr-flex tr-flex-col tr-items-center">
            {message}
          </div>
        );
      })}
    </div>
  );
  return _.size(warnings) ? (
    <div onClick={() => onClick?.(account)}>
      <div className="tr-text-warning">
        <Tooltip position="bottom" content={messages}>
          <Icon type="warning" />
        </Tooltip>
      </div>
    </div>
  ) : (
    <></>
  );
});

export const IssueCol = {
  dataField: 'issue',
  sort: true,
  configTitle: AccountColumnsE.ISSUE,
  text: '',
  headerStyle: {width: '1em', paddingLeft: '0', paddingRight: '0'},
  style: {width: '1em', paddingLeft: '0', paddingRight: '0'},
  formatter: (_issue: any, account: Account, _index: any, extraData: any) => (
    <ObservableIssue account={account} onClick={extraData.onClick} />
  ),
};

export const UserAccountColumn = ({
  columnTitle = AccountColumnsE.ACCOUNT_USER,
  columnWidth = '22em',
}: {
  columnTitle?: string;
  columnWidth?: string;
}): ColumnDescription<Account> => {
  const column: ColumnDescription<Account> = {
    dataField: 'user/account',
    sort: true,
    classes: 'show-on-hover',
    text: columnTitle,
    headerStyle: {width: columnWidth, textAlign: 'left', paddingLeft: '1.5em'},
    style: {width: columnWidth, textAlign: 'left', paddingLeft: '1.5em'},
    formatter: (_accountName: string, account: Account) => (
      <>
        <UserInfo
          type={account.forUser?.type ?? 'unlinked'}
          accountType={account?.accountType}
          account={account.account}
          isOrgOwner={account?.forUser?.isOrgOwner}
          authorizedAccount={<ShowAuthorizedAccount account={account} iconSize={'md'} />}
          className="tr-text-trustle-link"
        >
          <UserAccountColumnContent account={account} shortVersion={true} />
        </UserInfo>
      </>
    ),
    sortValue: (_cell: any, account: Account) => {
      return account.forUser?.email ?? '';
    },
  };
  return column;
};

export const lastLoginIcon = <Icon type="emptyData" title="Never" size="sm" />;
export const unavailableIcon = <Icon type="notAvailable" title="Unavailable" size="sm" />;

const ObservableActivity = observer(({account}: {account: Account}) => {
  const system = account.rootResource;
  const date = account.lastAccountActivity === null ? 'never' : formatDateFromNow(account.lastAccountActivity);

  if (system.type === ConnectionServiceE.AWS) {
    const showDate = (date?: string) => {
      if (!date) {
        return <span className="text-muted">None</span>;
      }
      return `${date} (${formatFullDate(date)})`;
    };
    return (
      <Tooltip
        size="lg"
        content={
          <div className="text-left">
            <div>
              <div className="strong">Console Access</div> <div>{showDate(account.lastLogin)}</div>
            </div>
            <div className="mt-2">
              <div className="strong">Programmatic access with active access key</div>
              <div>{showDate(account.lastProgrammaticAccess)}</div>
            </div>
          </div>
        }
      >
        {date}
      </Tooltip>
    );
  }

  if (!_.isNil(account.lastAccountActivity)) {
    return <Tooltip content={formatFullDate(account.lastAccountActivity)}>{date}</Tooltip>;
  }

  return <span className="text-muted">{system?.isUsageLastLoginAvailable ? lastLoginIcon : unavailableIcon}</span>;
});

export const LastActivityColumn: ColumnDescription<Account> = {
  dataField: 'lastAccountActivity',
  text: AccountColumnsE.LAST_ACTIVITY,
  align: 'left',
  headerAlign: 'left',
  style: {width: '7rem', textAlign: 'left', paddingLeft: 0},
  headerStyle: {width: '7rem', textAlign: 'left', paddingLeft: 0},
  formatter: (_unused, account: Account) => <ObservableActivity account={account} />,
  sort: true,
};

export const LastLoginColumn: ColumnDescription<Account> = {
  dataField: 'lastLogin',
  text: AccountColumnsE.LAST_LOGIN,
  align: 'left',
  headerAlign: 'left',
  style: {width: '8rem', textAlign: 'left'},
  headerStyle: {width: '8rem', textAlign: 'left'},
  formatter: (lastLogin: string | undefined, account: Account) => {
    if (lastLogin) {
      const date = moment(lastLogin).toISOString();
      return <Tooltip content={formatFullDate(date)}>{formatDateFromNow(date)}</Tooltip>;
    } else {
      const system = account.rootResource;
      return <span className="text-muted">{system?.isUsageLastLoginAvailable ? lastLoginIcon : unavailableIcon}</span>;
    }
  },
  sort: true,
};

export const ObservableRisk = observer(({account, onClick}: {account?: Account; onClick?: () => void}) => (
  <RiskBadge value={account?.riskScore} onClick={onClick} />
));

export const RiskScoreCol: ColumnDescription<Account> = {
  dataField: 'riskScore',
  text: AccountColumnsE.RISK_SCORE,
  headerStyle: {width: '7rem'},
  style: {width: '7rem'},
  align: 'left',
  headerAlign: 'left',
  formatter: (_riskScore: number, account: Account, _index, {onClick}) => (
    <ObservableRisk account={account} onClick={() => onClick?.(account)} />
  ),
  sort: true,
  sortValue: (_cell: any, account: Account) => (_.isNumber(account.riskScore) ? account.riskScore : -1),
};

const ObservableUsage = observer(
  ({account, onClick, resource}: {account: Account; onClick?: () => void; resource: Resource}) => (
    <UsageBadge
      value={account.usage}
      isLoading={account.usage === undefined}
      onClick={onClick}
      resource={resource.rootResource}
    />
  )
);

export const UsageCol: ColumnDescription<Account> = {
  dataField: 'usage',
  text: AccountColumnsE.USAGE,
  headerStyle: {width: '4rem'},
  style: {width: '4rem'},
  formatter: (_usage, account, _index, extraData) => (
    <ObservableUsage account={account} onClick={() => extraData.onClick?.(account)} resource={account.rootResource} />
  ),
  sort: true,
};

const AccountRecommendations = observer(({account}: {account: Account}) => account.recommendations);
export const RecommendationsColumn: ColumnDescription<Account> = {
  dataField: '_recommendations',
  text: AccountColumnsE.RECOMMENDATION,
  align: 'left',
  headerAlign: 'left',
  formatter: (_recommendations, account) => <AccountRecommendations account={account} />,
  headerStyle: {width: '12rem'},
  style: {width: '12rem'},
};

export const ActionsColumn = ({
  showDetails,
}: {
  showDetails?: (account: Account) => void;
}): ColumnDescription<Account> => ({
  dataField: '_actions',
  text: '',
  headerStyle: {width: '3rem'},
  style: {width: '3rem'},
  classes: 'show-on-hover',
  formatter: (_unused, account: Account) => (
    <div className="tr-w-full">
      {!_.isNil(showDetails) && (
        <>
          <Icon
            type="details"
            onClick={() => showDetails(account)}
            title="show details"
            className="tr-mx-1 hideable-button"
            role="button"
          />
        </>
      )}
    </div>
  ),
});

const ObservableNumResources = observer(({account}: {account: Account}) => {
  const history = useHistory();
  const encodedAccount = encodeURIComponent(account.account);
  return (
    <Button
      variant="ternary"
      onClick={() =>
        history.push(`/resource/manage/${account.rootResource.id}/resources?Account=${encodedAccount}&Expand=All`)
      }
      className="text-caption12"
    >
      {account.accessesCount}
    </Button>
  );
});

export const sortByAccessCount = (
  _a: any,
  _b: any,
  order: 'asc' | 'desc',
  _dataField: string,
  rowA: Account,
  rowB: Account
) => {
  const countA = rowA.accessesCount;
  const countB = rowB.accessesCount;

  if (countA === countB) {
    return 0;
  }

  if (order === 'asc') {
    return countA > countB ? 1 : -1;
  } else {
    return countA > countB ? -1 : 1;
  }
};

/**
 * Adds account.id to end of current url when clicked. May need refactoring to a onClick callback
 * for better re-use in the future.
 */
export const NumAccessesColumn: ColumnDescription<Account> = {
  dataField: 'accesses',
  text: 'Accesses',
  style: {width: '4rem'},
  headerStyle: {width: '4rem'},
  formatter: (_unused: any, account: Account) => <ObservableNumResources account={account} />,
  sort: true,
  sortFunc: sortByAccessCount,
};

const ObservableNumAccessesLink = observer(({account}: {account: Account}) => {
  const encodedAccount = encodeURIComponent(account?.account);
  return (
    <Link
      className="underline"
      data-testid="accesses"
      to={`/resource/manage/${account.rootResource.id}/resources?Account=${encodedAccount}&Expand=All`}
    >
      {account.accessesCount}
    </Link>
  );
});

export const NumAccessesLinkCol: ColumnDescription<Account> = {
  dataField: 'accesses.length',
  sort: true,
  text: AccountColumnsE.ACCESSES,
  headerStyle: {width: '8em'},
  style: {width: '8em'},
  formatter: (_numAccesses, account) => <ObservableNumAccessesLink account={account} />,
  sortFunc: sortByAccessCount,
};

const ObservableType = observer(({account}: {account: Account}) => <>{_.startCase(account.accountType)} Account</>);
export const TypeCol: ColumnDescription<Account> = {
  dataField: 'accountType',
  sort: true,
  text: AccountColumnsE.ACCOUNT_TYPE,
  headerStyle: {width: '14em', textAlign: 'left'},
  style: {width: '14em', textAlign: 'left'},
  formatter: (_accountType, account: Account) => <ObservableType account={account} />,
};

export const EditableTypeCol: ColumnDescription<Account> = {
  dataField: 'accountType',
  sort: true,
  text: 'Account Type',
  headerStyle: {width: '6em', textAlign: 'left', overflow: 'visible', paddingRight: '0', paddingLeft: '0'},
  style: {width: '6em', textAlign: 'left', paddingRight: '0', paddingLeft: '0'},
  formatter: (
    _accountType,
    account: Account,
    _index,
    extraData: {onChange?: (id: string, type: AccountAccessType) => void; disabled?: boolean}
  ) => {
    const options = [
      {
        label: (
          <Tooltip content="Personal Account" position="bottom">
            <Icon type="user" forcedSize={20} />
          </Tooltip>
        ),
        value: AccountAccessType.PERSONAL,
      },
      {
        label: (
          <Tooltip content="Service Account" position="bottom">
            <Icon type="service" forcedSize={20} />
          </Tooltip>
        ),
        value: AccountAccessType.SERVICE,
      },
    ];

    const CustomOption = ({innerProps, data}: OptionProps<OptionType, boolean>) => (
      <div {...innerProps} className={'mx-2 my-1 account-select-item'}>
        {data.label}
      </div>
    );
    return (
      // style hack to counteract padding from Col defined in StaticSelect, TODO: use component-lib Select
      <div className="tr-py-1 account-selector">
        <Select
          aria-label={'select-account-type'}
          options={options}
          name="select-account-type"
          data-testid={`testselect-account-type}`}
          onChange={
            ((e: {value: AccountAccessType}) => {
              extraData.onChange?.(account.id, e.value);
            }) as (selectedItem: any) => void
          }
          components={_.pickBy({Option: CustomOption}, _.identity)}
          menuPortalTarget={document.querySelector('body')}
          defaultValue={_.find(options, {value: account.accountType ?? AccountAccessType.PERSONAL})}
        />
      </div>
    );
  },
};

export const EditableUserNameCol: ColumnDescription<Account> = {
  dataField: 'email',
  sort: true,
  text: 'Trustle User',
  headerStyle: {width: '18em', textAlign: 'left', paddingLeft: '4px'},
  style: {width: '18em', textAlign: 'left', paddingLeft: '4px'},
  formatter: (_email: string, account: Account, _index, extraData) => {
    return (
      <MapUser
        account={account}
        accountType={
          extraData.newMappings[account.id]
            ? extraData.newMappings[account.id].account.accountType
            : account.accountType
        }
        onSubmit={(user, search) => extraData.onMapUser?.(account, user, search)}
      />
    );
  },
  sortValue: (_cell: any, account: Account) => {
    return account.forUser?.email ?? '';
  },
};

const ConnectedApps = observer(({account, onClick}: {account: Account; onClick?: (a: Account) => void}) => {
  const system = account.rootResource;
  if (system.connectedApps === undefined) {
    return <Loading />;
  }
  if (_.isNil(account.numConnectedApps)) {
    return <Icon title="Not Available" type="notAvailable" className="tr-mx-auto" size="sm" />;
  }
  return (
    <span onClick={() => onClick?.(account)}>
      <a href="#" className={`underline`}>
        {account.numConnectedApps}
      </a>
    </span>
  );
});

export const ConnectedAppsCol: ColumnDescription<Account> = {
  dataField: 'numConnectedApps',
  text: AccountColumnsE.CONNECTED_APPS,
  sort: true,
  headerStyle: {width: '10em'},
  style: {width: '10em'},
  formatter: (_cell: any, account: Account, _index, extraData) => (
    <ConnectedApps account={account} onClick={extraData.onClick} />
  ),
};

export const OffboardedByCol: ColumnDescription<Account> = {
  dataField: 'editedByUid',
  sort: true,
  headerStyle: {width: '15em', textAlign: 'left'},
  style: {width: '15em', textAlign: 'left'},
  text: AccountColumnsE.OFFBOARDED_BY,
};

export const OffboardedDateCol: ColumnDescription<Account> = {
  dataField: 'deletedAt',
  sort: true,
  headerStyle: {width: '10em'},
  style: {width: '10em'},
  text: AccountColumnsE.OFFBOARDED_DATE,
  formatter: (deletedAt: string, _account: Account) => {
    if (deletedAt) {
      return <Tooltip content={formatFullDate(deletedAt)}>{formatDateFromNow(deletedAt)}</Tooltip>;
    }
    return <span className="text-muted">{'Unavailable'}</span>;
  },
};

export const MultipleActionsCol = {
  dataField: 'actions',
  text: '',
  classes: 'show-on-hover',
  headerStyle: {width: '7em'},
  style: {width: '7em'},
  formatter: (_cell: any, account: Account) => (
    <AccountActionsDropdown account={account} resource={account.rootResource} refresh={() => {}} />
  ),
};

export const DepartmentRoleTypeCol = {
  dataField: 'department',
  sort: false,
  text: 'Department / Role / Title',
  headerStyle: {width: '12em', 'text-align': 'left'},
  style: {width: '12em', 'text-align': 'left'},
  formatter: (_field: string, record: any) => {
    const {department, role, title} = record;

    if (_.isEmpty(department) && _.isEmpty(role) && _.isEmpty(title)) {
      return <Icon type="emptyData" title="No data" size="sm" />;
    }

    return (
      <div className="tr-my-1 tr-flex-col">
        <div className="font-weight-bold">{department}</div>
        <div>{role}</div>
        <div className="tr-text-gray-600">{title}</div>
      </div>
    );
  },
};

export const DepartmentCol = {
  dataField: 'department',
  sort: false,
  text: 'Department',
  formatter: (field: string) => {
    if (_.isEmpty(field)) {
      return <Icon type="emptyData" title="No data" size="sm" />;
    }
    return (
      <div className="tr-my-1 tr-flex-col">
        <div className="font-weight-bold">{field}</div>
      </div>
    );
  },
};

export const RoleCol = {
  dataField: 'role',
  sort: false,
  text: 'Role',
  formatter: (field: string) => {
    if (_.isEmpty(field)) {
      return <Icon type="emptyData" title="No data" size="sm" />;
    }
    return (
      <div className="tr-my-1 tr-flex-col">
        <div className="font-weight-bold">{field}</div>
      </div>
    );
  },
};

export const TitleCol = {
  dataField: 'title',
  sort: false,
  text: 'Title',
  formatter: (field: string) => {
    if (_.isEmpty(field)) {
      return <Icon type="emptyData" title="No data" size="sm" />;
    }
    return (
      <div className="tr-my-1 tr-flex-col">
        <div className="font-weight-bold">{field}</div>
      </div>
    );
  },
};

export const getTypeIcon = (type: string) => {
  switch (type) {
    case UserType.employee:
    case UserType.contractor:
    case UserType.system:
    case UserType.customer:
      return (
        <span className="tr-flex tr-gap-2 tr-items-center">
          <Icon type={`${type}User`} size="sm" title={_.capitalize(type)} /> <strong>{_.capitalize(type)}</strong>
        </span>
      );
    default:
      return () => <></>;
  }
};

export const UserTypeCol = {
  dataField: 'type',
  sort: false,
  text: 'Trustle User Type',
  formatter: (field: string, _record: any) => {
    return <div className="tr-flex align-items-center">{getTypeIcon(field)}</div>;
  },
};
