import React from 'react';
import {useDataLoader} from 'src/lib/hooks';
import {Loading} from '@trustle/component-library';
import _ from 'lodash';
import './AccountRisk.scss';
import {humanize} from 'src/lib';
import RiskBadge from './RiskBadge';
import NoData from 'src/components/NoData';
import AwsPolicyRiskPerPermission from 'src/views/Permissions/Row/AwsPolicyRiskPerPermission';
import {RiskScorePermissionsT} from 'src/types';
import {Icon} from '@trustle/component-library';
type PropsT = {
  resourceId: string;
  accountId: string;
};

function AccountRisk(props: PropsT) {
  const {resourceId, accountId} = props;
  const [systemRisk, systemRiskStatus] = useDataLoader(`/api/resources/systems/${resourceId}/account-oddity`);

  if (!systemRiskStatus.isReady) {
    return <Loading />;
  }

  if (systemRisk.isSystemUnavailable) {
    return <NoData>Hmm, we couldn’t seem to find any risk report data yet.</NoData>;
  }

  const account = _.find(systemRisk.accounts, {uaid: accountId});

  if (!account) {
    return (
      <>
        <NoData>No risk data was found for this account.</NoData>
      </>
    );
  }
  const calculateAccountRiskScore = (num: number) => {
    return _.round(10 * num);
  };

  const header = () => {
    return (
      <>
        <div>
          <span className="body4">Account Risk Score: </span>{' '}
          <RiskBadge value={calculateAccountRiskScore(account.riskScore)} />
        </div>

        {_.size(account.foundRisks) > 0 && (
          <div className="tr-mt-2">
            <div className="body5 tr-flex tr-items-center">
              <span className="tr-text-error tr-pr-1">
                <Icon type="warning" />
              </span>
              Risks Found:
            </div>
            <div className="tr-ml-6 tr-pl-4">
              <ul className="tr-mb-0">
                {_.map(account.foundRisks, (risk) => {
                  return <li key={risk}>{humanize(risk)}</li>;
                })}
              </ul>
            </div>
          </div>
        )}
      </>
    );
  };

  return (
    <div className="tr-mt-5">
      <AwsPolicyRiskPerPermission
        riskScoresPermissions={account.permissions as RiskScorePermissionsT[]}
        header={header()}
      />
    </div>
  );
}

export default AccountRisk;
