import _ from 'lodash';
import React from 'react';
import {Loading, Tab, Table} from '@trustle/component-library';
import {formatDateFromNow, formatFullDate} from 'src/lib';
import {Permission} from 'src/stores/domainObjects/Permission';
import {observer} from 'mobx-react';
import {ConnectionServiceE} from 'src/types';
import {WARNING_SERVICE_USAGE_THRESHOLD} from 'src/lib/constants';
import {Modal, Icon, Tooltip} from '@trustle/component-library';
import GenericPermissionMetadata from 'src/views/Permissions/Row/GenericPermissionMetadata';
import StrongDMPermissionMetadata from 'src/views/Permissions/Row/StrongDMPermissionMetadata';
import GoogleWorkspacePermissionMetadata from 'src/views/Permissions/Row/GoogleWorkspacePermissionsMetadata';
import AwsPermissionMetadata from 'src/views/Permissions/Row/AwsPermissionMetadata';
import PermissionMetadataViewer from 'src/views/Permissions/Row/PermissionMetadataViewer';
import NoData from 'src/components/NoData';
import PermissionEvents from 'src/views/ConnectorServiceUsage/PermissionEvents';
import AutoFormatMetadataTable from 'src/components/design/tables/common/AutoFormatMetadataTable';
import OrgEvents from 'src/views/Events/OrgEvents';

type RequestAccessModalProps = {
  closeModal: any;
  permission: Permission;
  defaultSelectedTab?: PermTabModeE;
};

export enum PermTabModeE {
  GENERAL = 'general',
  HISTORY = 'history',
  METADATA = 'metadata',
  POLICY = 'policy',
  POLICY_LIST = 'policy_list',
  PERMISSIONS_POLICIES = 'permissions_policies',
  TRUST_RELATIONSHIPS = 'trust_relationships',
  TAGS = 'tags',
  RISK_REPORT = 'risk_report',
  SERVICE_USAGE = 'services_usage',
}

const PermissionDetailModal = observer(function PermissionDetailModal(props: RequestAccessModalProps) {
  const {closeModal, permission, defaultSelectedTab = PermTabModeE.GENERAL} = props;

  const {retrieveUsageDataEnabled} = permission.rootResource;
  const resource = permission.parentResource;
  const connectorData = permission.connectorData;

  if (connectorData === undefined) {
    return <Loading />;
  }

  let viewConnectorData: any;
  //NR: We should stop doing this all over frontend, we should rely on if its accessible depending on the attributes of the system or if set, not on type.
  if (_.isEmpty(connectorData)) {
    viewConnectorData = {};
  } else {
    switch (permission.rootResource.connector?.service) {
      case ConnectionServiceE.AZURE_AD:
      case ConnectionServiceE.TABLEAU:
        viewConnectorData = {
          [PermTabModeE.METADATA]: <PermissionMetadataViewer connectorsData={connectorData} />,
        };
        break;
      case ConnectionServiceE.M365:
        viewConnectorData = {
          [PermTabModeE.METADATA]: <AutoFormatMetadataTable data={connectorData[0]?.data} />,
        };
        break;
      case ConnectionServiceE.AWS:
        viewConnectorData = AwsPermissionMetadata({
          connectorsData: connectorData,
          permission: permission,
          resource: resource,
          system: permission.rootResource,
        });
        break;
      case ConnectionServiceE.GAPPS:
        viewConnectorData = {
          [PermTabModeE.METADATA]: <GoogleWorkspacePermissionMetadata connectorsData={connectorData} />,
        };
        break;
      case ConnectionServiceE.STRONGDM:
        viewConnectorData = {
          [PermTabModeE.METADATA]: <StrongDMPermissionMetadata connectorsData={connectorData} />,
        };
        break;
      default:
        viewConnectorData = {
          [PermTabModeE.METADATA]: <GenericPermissionMetadata permission={permission} />,
        };

        if (retrieveUsageDataEnabled) {
          viewConnectorData[PermTabModeE.SERVICE_USAGE] = <PermissionEvents permission={permission} />;
        }
        break;
    }
  }

  const percentage = _.round(permission.usageData?.usage ?? 0);
  const warningPercentage = !_.isNil(percentage) && percentage < WARNING_SERVICE_USAGE_THRESHOLD;

  const showDates = (date: string | undefined): React.ReactElement | string => {
    if (!date) {
      return '-';
    }
    return (
      <Tooltip content={formatFullDate(date)} position="bottom">
        {formatDateFromNow(date)}
      </Tooltip>
    );
  };

  let accessData: {key: string; value: any}[] = [
    {
      key: 'Permission',
      value: (
        <Tooltip content={permission.label} position="bottom">
          <div>{permission.label}</div>
        </Tooltip>
      ),
    },
    {
      key: 'Description',
      value: permission.description,
    },
    {
      key: 'Resource',
      value: (
        <Tooltip content={permission.parentResource.name} position="bottom">
          <div>{permission.parentResource.name}</div>
        </Tooltip>
      ),
    },
    {key: 'Updated', value: showDates(permission.updated)},
    {key: 'Created', value: showDates(permission.created)},
  ];

  const tabs: JSX.Element[] = [];
  const tabContent: JSX.Element[] = [];
  const tabHeaders: string[] = [PermTabModeE.GENERAL, PermTabModeE.HISTORY];
  if (!_.isEmpty(viewConnectorData)) {
    _.each(viewConnectorData, (component, key) => {
      if (key === PermTabModeE.GENERAL) {
        if (_.isArray(component)) {
          accessData = accessData.concat(component);
        }
      } else if (
        _.includes(
          [
            PermTabModeE.HISTORY,
            PermTabModeE.METADATA,
            PermTabModeE.PERMISSIONS_POLICIES,
            PermTabModeE.POLICY,
            PermTabModeE.POLICY_LIST,
            PermTabModeE.RISK_REPORT,
            PermTabModeE.SERVICE_USAGE,
            PermTabModeE.TAGS,
            PermTabModeE.TRUST_RELATIONSHIPS,
          ],
          key
        )
      ) {
        tabHeaders.push(key);
        tabContent.push(<Tab.Panel key={key}>{viewConnectorData && (viewConnectorData as any)[key]}</Tab.Panel>);
        tabs.push(
          <Tab data-testid={key} key={key}>
            {key === PermTabModeE.PERMISSIONS_POLICIES ? 'Policy' : _.startCase(key)}
            {key === PermTabModeE.SERVICE_USAGE && warningPercentage && (
              <span className="m-1 text-trustle-navy tr-font-normal tr-text-xs">
                <Tooltip content={<div>{`Usage is below ${WARNING_SERVICE_USAGE_THRESHOLD}%`}</div>}>
                  <Icon className="tr-text-warning" type="warning" />
                </Tooltip>
              </span>
            )}
          </Tab>
        );
      }
    });
  }

  return (
    <Modal
      onClose={closeModal}
      width="lg"
      height="xl"
      title={
        <>
          <Icon type="permission" size="md" className="tr-opacity-100" />
          {permission.label} Details
        </>
      }
    >
      <Tab.Group defaultIndex={_.findIndex(tabHeaders, (tab) => tab === defaultSelectedTab)}>
        <Tab.List variant="line">
          <Tab data-testid={PermTabModeE.GENERAL} key={PermTabModeE.GENERAL}>
            {_.startCase(PermTabModeE.GENERAL)}
          </Tab>
          <Tab data-testid={PermTabModeE.HISTORY} key={PermTabModeE.HISTORY}>
            {_.startCase(PermTabModeE.HISTORY)}
          </Tab>
          {tabs}
        </Tab.List>
        <Tab.Panels>
          <Tab.Panel key={PermTabModeE.GENERAL}>
            {_.isEmpty(accessData) ? (
              <NoData>Hmm, we couldn’t seem to find any general data yet.</NoData>
            ) : (
              <Table
                tableKey="permissionMetadata"
                data={accessData}
                overrideKeyField="key"
                columns={[
                  {
                    dataField: 'key',
                    text: 'Permission info',
                    headerClasses: 'text-left',
                    classes: 'text-left pl-5',
                    formatter: (_cell: any, row: any) => <strong className="text-left">{row.key}</strong>,
                  },
                  {dataField: 'value', text: '', classes: 'text-left', style: {width: '75%'}},
                ]}
                striped={true}
                wrapperClasses="simple-table-bordered mt-2"
                bordered={false}
              ></Table>
            )}
          </Tab.Panel>
          <Tab.Panel key={PermTabModeE.HISTORY}>
            <OrgEvents entityType={'permissions'} id={permission.id} />
          </Tab.Panel>
          {tabContent}
        </Tab.Panels>
      </Tab.Group>
    </Modal>
  );
});

export default PermissionDetailModal;
