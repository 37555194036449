import {observer} from 'mobx-react';
import React from 'react';
import PermissionDropdown from '../../Setup/PermissionDropdown';

import {PermissionBranchPropsT} from '../PermissionBranch';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import {useRootStore} from 'src/lib/hooks';
import _ from 'lodash';
import AccessActions from '../AccessActions';

export const PermissionActions = observer((props: PermissionBranchPropsT) => {
  const {permission, accessMode, user} = props;
  const accessRecords = props.visibleItems?.accesses ?? [];
  const {accessStore} = useRootStore();
  const filteredAccess: AccessRecord[] = _.compact(
    _.map(accessRecords, (accessRecord) => {
      const accessRecordItem = accessStore.accessMap[accessRecord];

      if (user && accessRecordItem.forAccount?.uid === user.id && accessRecordItem.pid === permission.id) {
        return accessRecordItem;
      }
    })
  );
  return (
    <div className="tr-flex-col tr-relative actions">
      {_.size(filteredAccess) > 0 && permission.rootResource?.userIsOwner ? (
        _.map(filteredAccess, (accessRecord) => <AccessActions accessRecord={accessRecord} />)
      ) : (
        <PermissionDropdown
          key={`${permission.id} PermissionDropdown`}
          permission={permission}
          accessMode={accessMode}
        />
      )}
    </div>
  );
});
