import axios from 'axios';
import _ from 'lodash';
import {makeAutoObservable, runInAction} from 'mobx';
import {logger} from 'src/lib';
import {ColumnDescription} from '@trustle/component-library';
import RootStore from '.';

export enum TableSetupEnum {
  RESOURCE_SETUP = `resource_setup`,
  OVERVIEW = `overview`,
  INVOICES = `invoice`,
  LINKED_ACCOUNTS = `assigned_accounts`,
  OFFBOARDED_ACCOUNTS = `offboarded_accounts`,
  UNASSIGNED_ACCOUNTS = `unassigned_accounts`,
  ACCESS = `user_access`,
  USER_PERMISSIONS = 'user_permissions',
  RECOMENDATIONS = 'recomendations',
  RECOMENDATIONS_ACCESS = 'recomendations_access',
  ADMIN_USERS = 'admin_users',
  USERS_SYNC = 'users_sync',
}

//Store that saves and handles user configurations to remove dependency on authInfo
export class UserStore {
  loading: boolean = false;
  tableSetups: Record<string, any> = {};
  error: boolean = false;

  constructor(public rootStore: RootStore) {
    makeAutoObservable(this);
  }

  buildTableKeyIdentity = (tableKey?: string, rid?: string, uid?: string) =>
    this.isUserTableSetup(tableKey) ? _.join(_.compact([uid, tableKey]), '_') : _.join(_.compact([rid, tableKey]), '_');

  // if it is a non resource table, the table key identity should be built with the uid as parameter
  isUserTableSetup = (tableKey?: string) => (tableKey ? ['admin_users'].includes(tableKey) : false);

  loadUserDefaults() {
    axios
      .get(`/api/table_setups/user/${this.rootStore.currentUser?.id}`)
      .then((result) => {
        runInAction(() => {
          for (const tableSetup of result.data) {
            this.tableSetups[this.buildTableKeyIdentity(tableSetup.tableKey, tableSetup.rid, tableSetup.uid)] =
              tableSetup;
          }
        });
      })
      .catch((err) => {
        logger.error(err);
        runInAction(() => {
          this.error = true;
        });
      });
  }

  async isPwdSecure(password: string, onInvalidPwd: () => void): Promise<boolean> {
    const {data} = await axios.post('/api/auth/password_check', {password});

    if (data.potentiallyExposed) {
      onInvalidPwd();
      return false;
    }

    return true;
  }

  async updatePwd(values: {oldPassword: string; newPassword: string; confirmNewPwd: string}, onInvalidPwd: () => void) {
    const {oldPassword, newPassword, confirmNewPwd} = values;
    if (_.isEmpty(oldPassword) && _.isEmpty(newPassword) && _.isEmpty(confirmNewPwd)) {
      return;
    }

    try {
      const isSecure = await this.isPwdSecure(newPassword, onInvalidPwd);
      if (isSecure) {
        await axios.post('/api/auth/reset_pw', {oldPassword, newPassword, confirmNewPwd});
      } else {
        this.rootStore.toast.add('Error updating password: new password is not secure', {
          appearance: 'error',
          autoDismiss: false,
        });
      }
    } catch (err) {
      const message = _.get(err, 'response.data.error.message', 'Something went wrong');
      this.rootStore.toast.add(`Error updating password: ${message}`, {appearance: 'error', autoDismiss: false});
      this.error = true;
      logger.error(err);
      onInvalidPwd();
      return false;
    }
  }

  async setTableSetup(columns: ColumnDescription<any, any>[], params: {tableKey: string; rid?: string; uid?: string}) {
    const {tableKey, rid, uid = undefined} = params;
    const columnsData = columns.map((col) => {
      return {dataField: col.dataField, hidden: col.hidden};
    });
    await axios.post(`/api/table_setups`, {tableKey, rid, uid, config: {columns: columnsData}});
    runInAction(() => {
      this.tableSetups[this.buildTableKeyIdentity(tableKey, rid, uid)] = {config: {columns: columnsData}};
    });
  }
}
