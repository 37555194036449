import React from 'react';
import {Resource} from 'src/stores/domainObjects/Resource';
import _ from 'lodash';
import {Permission} from 'src/stores/domainObjects/Permission';
import {SensitivityLevelDefinitions} from 'src/components/sensitivity';
import {ColumnDescription} from '@trustle/component-library';
import {Link} from 'react-router-dom';
import MetadataButton from 'src/views/Resource/Setup/MetadataButton';
import VisibilityToggle from 'src/components/design/visibilityToggle/VisibilityToggle';
import {createdDaysAgo} from 'src/lib';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import RiskBadge from 'src/views/Resource/Account/RiskBadge';
import {PermTabModeE} from 'src/views/Resource/Setup/PermissionDetailsModal';
import {AwsResourceType, ConnectionServiceE} from 'src/types';
import {observer} from 'mobx-react';
import {Tooltip, Icon} from '@trustle/component-library';
import {AccessStatusIcon} from '@trustle/component-library';
import EditResourceIcon from 'src/views/Resource/Setup/EditResourceIcon';
import PermissionDropdown from 'src/views/Resource/Setup/PermissionDropdown';
/** Shared Columns for use in Resource|Permission Tables */

const resourceNameComparator = (item1: Permission | Resource, item2: Permission | Resource, order: string): number => {
  const firstItemName = item1 instanceof Resource ? item1.name : item1.parentResource.name;
  const secondItemName = item2 instanceof Resource ? item2.name : item2.parentResource.name;
  return order === 'asc' ? firstItemName.localeCompare(secondItemName) : secondItemName.localeCompare(firstItemName);
};

export const StatusColumn: ColumnDescription<Resource | Permission> = {
  dataField: 'currentUsersAccess',
  text: 'Status',
  headerStyle: {width: '100px'},
  style: {width: '100px'},
  formatter: (access: AccessRecord | undefined) =>
    access && <AccessStatusIcon size="sm" title={access.status} status={access.statusValue} className="m-auto" />,
  sort: true,
  sortFunc: (access1, access2, order, _dataField, item1, item2) => {
    if (resourceNameComparator(item1, item2, order) === 0) {
      // group/resource name always goes first
      if (item2 instanceof Resource) {
        return 1;
      } else if (order === 'asc') {
        return (access1?.statusValue ?? 0) < (access2?.statusValue ?? 0) ? -1 : 1;
      } else {
        return (access1?.statusValue ?? 0) > (access2?.statusValue ?? 0) ? -1 : 1;
      }
    } else {
      return 0;
    }
  },
};

const ObservableResourceName = observer(({item}: {item: Resource | Permission}) => {
  if (!(item instanceof Resource)) {
    return <></>;
  }
  return (
    <>
      <span className="pr-1">{item.name}</span>
      {item.description && (
        <Tooltip content={item.description}>
          <Icon type="moreInfo" size="sm" className="tr-inline-block" />
        </Tooltip>
      )}
    </>
  );
});

export const ResourceNameColumn: ColumnDescription<Resource | Permission> = {
  dataField: 'label',
  text: 'Resource',
  headerAlign: 'left',
  align: 'left',
  formatter: (_name, item) => <ObservableResourceName item={item} />,
  sort: true,
  sortFunc: (...[, , order, , item1, item2]) => resourceNameComparator(item1, item2, order),
};

const ObservableLabel = observer(({item}: {item: Resource | Permission}) => {
  if (!(item instanceof Permission)) {
    return <></>;
  }

  return (
    <>
      <span className="pr-1 tr-flex ">
        <span className="tr-truncate font-bold" title={item.label}>
          {item.label}
        </span>

        {item.nodeImportState?.warning && (
          <div className="ml-2 text-trustle-navy tr-font-normal tr-text-xs">
            <Tooltip content={<div>Configuration is not secure enough</div>}>
              <Icon className="tr-text-warning" size="sm" type="warning" />
            </Tooltip>
          </div>
        )}
        {item.description && (
          <Tooltip content={item.description}>
            <Icon type="moreInfo" size="sm" className="tr-inline-block" />
          </Tooltip>
        )}
      </span>
    </>
  );
});

export const PermissionLabelColumn: ColumnDescription<Resource | Permission> = {
  dataField: 'label',
  text: 'Permission',
  align: 'left',
  headerAlign: 'left',
  headerStyle: {width: '320px'},
  style: {width: '320px'},
  formatter: (_label, item: Resource | Permission) => <ObservableLabel item={item} />,
  sort: true,
  sortFunc: (...[label1, label2, order, , item1, item2]) => {
    if (resourceNameComparator(item1, item2, order) === 0) {
      // group/resource name always goes first
      if (item2 instanceof Resource) {
        return 1;
      } else if (order === 'asc') {
        return label1.localeCompare(label2);
      } else {
        return label2.localeCompare(label1);
      }
    } else {
      return 0;
    }
  },
};

const ObservableSensitivity = observer(({item}: {item: Resource | Permission}) => {
  if (!(item instanceof Permission)) {
    return <></>;
  }
  return (
    <>
      {item?.sensitivityIcon} {item.sensitivity?.level}
      {` (${_.findIndex(SensitivityLevelDefinitions, {level: item.sensitivity?.level})})`}
    </>
  );
});
export const SensitivityColumn: ColumnDescription<Resource | Permission> = {
  dataField: 'sensitivity',
  text: 'Sensitivity',
  formatter: (_sensitivity, item: Resource | Permission) => <ObservableSensitivity item={item} />,
  sort: true,
  sortFunc: (...[sensitivity1, sensitivity2, order, , item1, item2]) => {
    if (resourceNameComparator(item1, item2, order) === 0) {
      // group/resource name always goes first
      if (item2 instanceof Resource) {
        return 1;
      } else if (order === 'asc') {
        return sensitivity1.level < sensitivity2.level ? -1 : 1;
      } else {
        return sensitivity1.level > sensitivity2.level ? -1 : 1;
      }
    } else {
      return 0;
    }
  },
};

const ObservableMembers = observer(({item}: {item: Resource | Permission}) =>
  item instanceof Resource ? (
    <></>
  ) : (
    <Link
      to={`/resource/manage/${item.rootResource.id}/overview/managed?Permission=${item.label}`}
      className="underline"
      data-testid="permissionsMembers"
    >
      {item.memberCount}
    </Link>
  )
);
export const MembersColumn: ColumnDescription<Resource | Permission> = {
  dataField: 'numAccounts',
  text: 'Members',
  headerStyle: {width: '78px'},
  style: {width: '78px'},
  formatter: (_cell, item) => <ObservableMembers item={item} />,
};

export const MetaColumn: ColumnDescription<Resource | Permission> = {
  dataField: 'metadata',
  text: 'Meta',
  headerStyle: {width: '60px'},
  style: {width: '60px'},
  formatter: (_cell, item: Resource | Permission) => {
    if (item instanceof Permission && item.hasMetadata) {
      return (
        <MetadataButton
          permission={item}
          defaultSelectedTab={
            item.parentResource.type === ConnectionServiceE.AWS
              ? item.parentResource.foreignId === AwsResourceType.ROLES
                ? PermTabModeE.PERMISSIONS_POLICIES
                : PermTabModeE.POLICY
              : PermTabModeE.METADATA
          }
        />
      );
    }
    return <></>;
  },
};

export const VisibilityColumn: ColumnDescription<Resource | Permission> = {
  dataField: 'visibility',
  text: '',
  classes: 'show-on-hover',
  headerStyle: {width: '35px'},
  style: {width: '35px'},
  formatter: (_cell, item: Resource | Permission) =>
    item instanceof Resource ? (
      <VisibilityToggle resource={item} />
    ) : (
      <VisibilityToggle resource={item.parentResource} permission={item} />
    ),
};

const ObservableLastEdited = observer(({item}: {item: Resource | Permission}) => {
  return <>{createdDaysAgo(item instanceof Resource ? item.lastChange?.created : item.updated)}</>;
});
export const LastEditedColumn: ColumnDescription<Resource | Permission> = {
  dataField: 'lastChange.created',
  text: 'Last Edited',
  headerStyle: {width: '100px'},
  style: {width: '100px'},
  formatter: (_cell, item: Resource | Permission) => <ObservableLastEdited item={item} />,
};

export const ObservableRiskColumn = observer(({item}: {item: Resource | Permission}) => {
  if (item instanceof Resource) {
    return <></>;
  }

  return (
    <MetadataButton
      permission={item}
      defaultSelectedTab={PermTabModeE.RISK_REPORT}
      text={<RiskBadge value={item.riskReport?.riskScore} />}
    />
  );
});
export const RiskColumn: ColumnDescription<Resource | Permission> = {
  dataField: 'riskScore',
  text: 'Risk score',
  headerStyle: {width: '110px'},
  style: {width: '110px'},
  formatter: (_cell, item: Resource | Permission) => <ObservableRiskColumn item={item} />,
  sort: true,
  // TODO (jg): account for risk score sorting in tables with resources
  sortValue: (riskScore: number | undefined) => (_.isNumber(riskScore) ? riskScore : -1),
};

const ObservableActions = observer(({item}: {item: Resource | Permission}) =>
  item instanceof Resource ? (
    <EditResourceIcon resource={item} rootResource={item.rootResource} />
  ) : (
    <PermissionDropdown permission={item} />
  )
);

export const ActionsCol: ColumnDescription<Resource | Permission> = {
  dataField: 'actions',
  classes: 'show-on-hover',
  text: 'Actions',
  headerStyle: {width: '105px'},
  style: {width: '105px'},
  sort: true,
  formatter: (_cell, item) => <ObservableActions item={item} />,
};
