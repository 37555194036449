import React from 'react';
import {TextInput} from '@trustle/component-library';
import {User} from '../../../../stores/domainObjects/User';
import {formatFullDate} from '../../../../lib';

type Props = {
  user: User;
};
export function UserBasicInfo({user}: Props) {
  return (
    <div className="tr-flex tr-flex-col tr-gap-4">
      <h3>Basic Information</h3>
      <div className="tr-flex tr-flex-col">
        <div className="tr-flex tr-gap-4">
          <TextInput label="First Name" value={user.firstname} disabled />
          <TextInput label="Last Name" value={user.lastname} disabled />
        </div>
        <div className="tr-flex tr-gap-4">
          <TextInput label="Username" value={user.email} disabled />
          <TextInput label="Department" value={user.department} disabled />
        </div>
        <div className="tr-flex tr-gap-4">
          <TextInput label="Organization Role" value={user.remoteRole} disabled />
          <TextInput label="Title" value={user.title ?? ''} disabled />
        </div>
        <div className="mb-4 dropdown-divider"></div>
        <div className="tr-flex tr-w-1/2 tr-pr-2">
          <TextInput label="Created on" value={formatFullDate(user.created)} disabled />
        </div>
      </div>
    </div>
  );
}
