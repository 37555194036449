import React, {useState} from 'react';
import {Card} from 'src/components/design';
import {formatAsDollarsAndCents} from 'src/lib';
import './Billing.scss';
import {observer} from 'mobx-react';
import {useRootStore} from 'src/lib/hooks';
import FontAwesomeIcon from 'src/components/FontAwesomeIcon';
import {faCalendar, faMoneyBill} from '@fortawesome/free-solid-svg-icons';
import {TableSetupEnum} from 'src/stores/userStore';
import {formatFullDate, fuseSearch} from 'src/lib';
import moment from 'moment';
import PaymentMethod from '../../signup/PaymentMethod';
import {Search} from 'src/components/Search/Search';
import _ from 'lodash';
import {faFilePdf} from '@fortawesome/free-regular-svg-icons';
import BillingInfoForm from './BillingInfo';
import BillingAddress from '../../signup/BillingAddress';
import {Button, Confirmation, Modal, Table} from '@trustle/component-library';
import {Loading} from '@trustle/component-library';

const BillingPage = observer(function PlansAndPricingCmp() {
  const [query, setQuery] = useState('');
  const [cancelingSubscription, setCancelingSubscription] = useState(false);
  const [editingBillingInfo, setEditingBillingInfo] = useState(false);
  const {org} = useRootStore();
  if (
    _.isNil(org) ||
    org?.customer === undefined ||
    org?.billing === undefined ||
    org?.billingAddresses === undefined ||
    org?.products === undefined
  ) {
    return <Loading />;
  }

  const invoices = org.billing.invoices;
  const paymentMethods = org.methods;
  const upcoming = org.billing.upcoming;

  const invoicesData = invoices ?? [];
  const invoinceWithData = invoicesData.map((inv) => {
    const productId = inv.lines.data[0].plan.product;
    const description = inv.lines.data[0].description;
    const product = org.products[productId]?.name;
    return {...inv, description, product};
  });

  const columns: any = [
    {
      dataField: 'id',
      hidden: true,
      text: '',
    },
    {
      dataField: 'description',
      text: 'Description',
      sortValue: (cell: any, row: any) => {
        return row.id;
      },
      width: '30%',
      formatter: (cell: any, row: any) => {
        return (
          <div className="tr-flex tr-flex-row justify-content-start p-2">
            <div className="tr-flex flex-column justify-content-center">
              <FontAwesomeIcon icon={faFilePdf} size="lg" />
            </div>
            <div className="tr-flex flex-column">
              <div className="ml-3 mr-3 capitalize-text body5 font-weight-bold">{cell}</div>
              <div className="ml-3 mr-3 capitalize-text body6 grayed-out">{row.number}</div>
            </div>
          </div>
        );
      },
    },
    {
      dataField: 'total',
      text: 'Total',
      formatter: (cell: any) => {
        return <div>{formatAsDollarsAndCents(cell / 100)}</div>;
      },
    },
    {
      dataField: 'product',
      text: 'Plan',
      formatter: (cell: any) => {
        return <div>{cell}</div>;
      },
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell: any) => {
        return <div>{cell}</div>;
      },
    },
    {
      dataField: 'created',
      text: 'Date',
      formatter: (cell: number) => {
        return <div>{formatFullDate(moment.unix(cell).toISOString())}</div>;
      },
    },
    {
      dataField: 'download',
      text: 'Download',
      formatter: (cell: any, row: any) => {
        return (
          <Button
            variant="primary"
            size="sm"
            content={
              <a className="text-white" target="_blank" rel="noreferrer" href={`${row.invoice_pdf}`}>
                Download
              </a>
            }
          />
        );
      },
    },
  ];

  const searchedInvoices = fuseSearch(query, ['product', 'description', 'number', 'total', 'status'], invoinceWithData);

  const paymentMethod = _.first(paymentMethods);

  return (
    <div className="billing tr-mt-2">
      <h1 className="text-navy">Payments & Billing</h1>
      <span className="grayed-out ">Billing details and Invoice information</span>
      <div className="tr-my-4 tr-flex">
        <div className="tr-flex-grow">
          <div>
            <div className="tr-mb-4">
              {upcoming && (
                <Card>
                  <Card.Header className="font-weight-bold tr-flex tr-flex-row align-items-center tr-justify-between">
                    <h2 className="m-0 text-navy">Next Payment</h2>
                    {org.subscription && (
                      <Button
                        label="Cancel"
                        size="sm"
                        onClick={() => {
                          setCancelingSubscription(true);
                        }}
                      />
                    )}
                  </Card.Header>
                  <Card.Body>
                    {cancelingSubscription && (
                      <Confirmation
                        onConfirm={() => {
                          void org.cancelSubscription();
                          setCancelingSubscription(false);
                        }}
                        onClose={() => setCancelingSubscription(false)}
                        title="Confirm Action"
                      >
                        <div>Do you confirm that you want to cancel Trustle's subscription?</div>
                      </Confirmation>
                    )}
                    <div className="tr-flex tr-flex-row tr-justify-between  p-2">
                      <div className="tr-flex tr-flex-row align-items-center">
                        <div className="tr-flex tr-flex-row flex-column justify-content-start p-2">
                          <FontAwesomeIcon icon={faCalendar} size="lg" />
                        </div>
                        <div className="tr-flex tr-flex-row flex-column justify-content-start p-2">
                          <div className="grayed-out">{'Next Payment'}</div>
                          <div className="">
                            {formatFullDate(moment.unix(upcoming.next_payment_attempt).toISOString())}
                          </div>
                        </div>
                      </div>
                      <div className="tr-flex tr-flex-row align-items-center">
                        <div className="tr-flex tr-flex-row flex-column justify-content-start p-2">
                          <FontAwesomeIcon icon={faMoneyBill} size="lg" />
                        </div>
                        <div className="tr-flex tr-flex-row flex-column justify-content-start p-2">
                          <div className="grayed-out">{'Amount'}</div>
                          <div className="">{formatAsDollarsAndCents(upcoming.total / 100)}</div>
                        </div>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              )}
            </div>
            <div className="tr-mb-4">
              <Card>
                <Card.Header className="font-weight-bold tr-flex tr-flex-row align-items-center tr-justify-between">
                  <h2 className="m-0 text-navy">Billing Contact Information</h2>

                  <Button
                    label="Edit"
                    size="sm"
                    onClick={() => {
                      setEditingBillingInfo(true);
                    }}
                  />
                  <Modal
                    visible={editingBillingInfo}
                    onClose={() => {
                      setEditingBillingInfo(false);
                    }}
                    title="Billing Details"
                  >
                    <BillingInfoForm
                      close={() => {
                        setEditingBillingInfo(false);
                      }}
                    />
                  </Modal>
                </Card.Header>
                <Card.Body>
                  <div className="tr-flex tr-flex-row justify-content-start p-2">
                    <div className="ml-3 mr-3">{org.customer?.email}</div>
                  </div>
                </Card.Body>
              </Card>
            </div>
            {org.subscriptionPrice && paymentMethod && (
              <Card>
                <Card.Header className="font-weight-bold tr-flex tr-flex-row align-items-center tr-justify-between">
                  <h2 className="m-0 text-navy">Plan Details</h2>
                </Card.Header>
                <Card.Body>
                  <div className="tr-flex tr-flex-row tr-justify-between mt-1">
                    <div className="ml-3 mr-3 grayed-out body3">Start Date</div>
                    <div className="ml-3 mr-3 body3 text-right">
                      {moment(org.subscription?.start_date * 1000).format('M/D/Y')}
                    </div>
                  </div>
                  <div className="tr-flex tr-flex-row tr-justify-between mt-1">
                    <div className="ml-3 mr-3 grayed-out body3">Current Plan</div>
                    <div className="ml-3 mr-3 body3 text-right">{org.subscriptionPlan?.name}</div>
                  </div>
                  <div className="tr-flex tr-flex-row tr-justify-between mt-1">
                    <div className="ml-3 mr-3 grayed-out body3">Number of Users</div>
                    <div className="ml-3 mr-3 body3 text-right">
                      {org.billableUsersCount} of {org.subscription?.quantity}
                    </div>
                  </div>
                  <div className="tr-flex tr-flex-row tr-justify-between mt-1">
                    <div className="ml-3 mr-3 grayed-out body3">Card Used</div>
                    <div className="ml-3 mr-3 body3 text-right">
                      <span className="capitalize-all ">{paymentMethod?.card.brand} </span>...{' '}
                      {paymentMethod?.card.last4}
                    </div>
                  </div>
                  <div className="tr-flex tr-flex-row tr-justify-between mt-1">
                    <div className="ml-3 mr-3 grayed-out body3">Billing Cycle</div>
                    <div className="ml-3 mr-3 body3 text-right capitalize-all">
                      {org.subscriptionPrice?.recurring.interval}
                    </div>
                  </div>
                  <div className="tr-flex tr-flex-row tr-justify-between mt-1">
                    <div className="ml-3 mr-3 grayed-out body3">Plan Price (per Seat)</div>
                    <div className="ml-3 mr-3 body3 text-right capitalize-all">
                      {formatAsDollarsAndCents(org.subscriptionPrice?.unit_amount / 100)}
                    </div>
                  </div>
                </Card.Body>
              </Card>
            )}
          </div>
        </div>
        <div className="tr-flex-grow tr-ml-4">
          <div className="tr-mb-4">
            <Card>
              <Card.Header className="font-weight-bold">
                <h2 className="m-0 text-navy">Card Information</h2>
              </Card.Header>
              <Card.Body>
                {!_.isEmpty(paymentMethods) ? (
                  paymentMethods!.map((pm) => {
                    return (
                      <div key={pm.id}>
                        <PaymentMethod pm={pm} removeAllowed={paymentMethods!.length > 1} />
                      </div>
                    );
                  })
                ) : (
                  <>No payments methods attached</>
                )}
              </Card.Body>
            </Card>
          </div>
          <div>
            <Card>
              <Card.Header className="font-weight-bold">
                <h2 className="m-0 text-navy">Billing Address</h2>
              </Card.Header>
              <Card.Body>
                {!_.isEmpty(org.billingAddresses) ? (
                  org.billingAddresses.map((billingAddress) => {
                    return (
                      <div key={billingAddress.id}>
                        <BillingAddress address={billingAddress} removeAllowed={org.billingAddresses!.length > 1} />
                      </div>
                    );
                  })
                ) : (
                  <>No address attached</>
                )}
              </Card.Body>
            </Card>
          </div>
        </div>
      </div>

      <Card>
        <Card.Header className="font-weight-bold tr-flex justify-content-start">
          <h2 className="m-0 text-navy mr-5">Billing</h2>
          <Search placeholder="Search" onChange={(query) => setQuery(query)} />
        </Card.Header>
        <Card.Body>
          <Table
            id="setup-resources-table"
            tableKey={TableSetupEnum.INVOICES}
            data={searchedInvoices}
            defaultSorted={[
              {
                dataField: 'resource.name',
                order: 'desc',
              },
            ]}
            columns={columns}
            striped={true}
          />
        </Card.Body>
      </Card>
    </div>
  );
});

export default BillingPage;
