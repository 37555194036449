import {AutoProvisionIcon, Icon, Tooltip} from '@trustle/component-library';
import _ from 'lodash';
import {observer} from 'mobx-react';
import React from 'react';
import {Permission} from 'src/stores/domainObjects/Permission';
import {Resource} from 'src/stores/domainObjects/Resource';
import {ProvisionOptions} from 'src/types';

const LockedOrProvision = observer(
  (props: {target: Resource | Permission; ignoreLocked?: boolean; addLabel?: boolean}) => {
    const {target, ignoreLocked = false, addLabel = false} = props;

    if (!ignoreLocked && target.isLocked) {
      return (
        <div className="tr-flex tr-justify-center tr-items-center tr-pr-1">
          <Icon type="locked" title="This resource/permission is not editable" size="sm" />
        </div>
      );
    }

    const provisionMode = target.calculatedProvisionMode.value;
    const deprovisionMode = target.calculatedDeprovisionMode.value;

    const thunderboltOn = !(provisionMode === ProvisionOptions.off && deprovisionMode === ProvisionOptions.off);

    const title = `Provision: ${_.startCase(provisionMode)} / Deprovision: ${_.startCase(deprovisionMode)}`;
    return (
      <Tooltip size="md" className="tr-mx-5" content={<div className="tr-px-2">{title}</div>}>
        <div className="tr-flex tr-justify-center tr-items-center " data-testid={`provisionDeprovisionModes`}>
          <AutoProvisionIcon size="sm" value={thunderboltOn} className="tr-pr-1" />
          {addLabel && (
            <>
              {provisionMode === deprovisionMode ? (
                <div>{_.startCase(provisionMode)}</div>
              ) : (
                <div>{`${_.startCase(provisionMode)} / ${_.startCase(deprovisionMode)}`}</div>
              )}
            </>
          )}
        </div>
      </Tooltip>
    );
  }
);

export default LockedOrProvision;
