import {Modal, Alert} from '@trustle/component-library';
import React from 'react';
import {passwordPolicyHint} from 'src/views/User/ResetPassword/ResetPasswordForm';

type PwnedPasswordModalT = {
  closeModal: () => void;
};

export default function PwnedPasswordModal(props: PwnedPasswordModalT) {
  const {closeModal} = props;
  return (
    <Modal
      title="Unsafe Password"
      visible={true}
      data-testid="unsafePasswordModal"
      onClose={() => {
        closeModal();
      }}
    >
      <div>
        <p>The password entered is not safe and cannot be used for Trustle. Please try another password. </p>
        <p>
          The password was previously exposed in a data breach or does not accomplish our password policy. This doesn’t
          mean you’ve been hacked, but that your password is well known to attackers and therefore less secure.
          <Alert colorVariant="danger">{passwordPolicyHint}</Alert>
        </p>
        <p>More information: </p>
        <p>
          Need help making a password? Create an{' '}
          <a href={'https://blog.knowbe4.com/5-easy-steps-to-create-strong-passwords'}>
            easy to remember, and hard to guess password
          </a>
          .
        </p>
        <p>
          Learn more about{' '}
          <a href={'https://security.googleblog.com/2014/09/cleaning-up-after-password-dumps.html'}>data breaches</a>
        </p>
        <p>
          <a
            href="#0"
            onClick={() => {
              closeModal();
            }}
          >
            Enter another password
          </a>
        </p>
      </div>
    </Modal>
  );
}
