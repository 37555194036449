import React from 'react';
import {AWSData} from '../../../types';
import {Loading} from '@trustle/component-library';
import {Badge} from '@trustle/component-library';
import Highlight from 'src/components/highlightjs/Highlight';
import {observer} from 'mobx-react';
import _ from 'lodash';

type PropsT = {
  connectorsData: any;
  permissionId: string;
};

const ViewAwsPolicy = observer(function ViewAwsPolicy(props: PropsT) {
  const {connectorsData} = props;
  if (connectorsData === undefined) {
    return <Loading />;
  }
  const policy = connectorsData[0];
  const policyARN = (policy.data as AWSData).PolicyArn;

  if (_.isEmpty(connectorsData) || _.isEmpty(policy)) {
    return <span>There isn't any data yet</span>;
  }

  const showPolicyArn = () => {
    return !_.isEmpty(policyARN) && <Badge variant="secondary">{policyARN}</Badge>;
  };
  return (
    <>
      {showPolicyArn()}
      {policy && policy.data && (
        <Highlight language="yaml">{decodeURIComponent((policy.data as AWSData).PolicyDocument)}</Highlight>
      )}
    </>
  );
});

export default ViewAwsPolicy;
