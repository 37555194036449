import React from 'react';
import {useQueryParams} from 'src/lib';
import {publishSessionEvent, useRootStore} from 'src/lib/hooks';
import GenericAccessRequestsTable from './GenericAccessRequestsTable';
import {Icon, Tab} from '@trustle/component-library';
import _ from 'lodash';
import {Task} from 'src/stores/domainObjects/Task';
import {observer} from 'mobx-react';
import {Link, useParams} from 'react-router-dom';

const TasksHome = observer(function TasksHomeBase() {
  const [accessRequestId] = useQueryParams(['accessRequestId']);

  enum TasksHomeTabs {
    MINE = 'mine',
    OTHER = 'other',
  }

  const tabs = [TasksHomeTabs.MINE, TasksHomeTabs.OTHER];
  const {tab} = useParams<{tab?: TasksHomeTabs}>();
  const {tasksStore} = useRootStore();
  const selectedIndex = Math.max(tabs.indexOf(tab!), 0);

  const tasks = tasksStore.selfTasksAndRequests;
  const otherTasks = tasksStore.otherTasks;

  publishSessionEvent();

  const getTasks = (tasks: Task[]) => {
    return (
      <>
        {tasksStore.loading && !tasksStore.loadedAtLeastOnce ? (
          <div className="tt--empty-task-view">
            <div className="text-center text-navy">
              <img src="/russel-researching.svg" alt="Checklist Icon" />
              <div className="body2">Loading in progress...</div>
              <div className="body3">Please wait a moment.</div>
            </div>
          </div>
        ) : _.isEmpty(tasks) ? (
          <div className="tt--empty-task-view">
            <div className="text-center text-navy">
              <img src="/russel-celebrating.svg" alt="Checklist Icon" />
              <div className="body2">Great job!</div>
              <div className="body3">You've Completed Your Tasks.</div>
            </div>
          </div>
        ) : (
          <GenericAccessRequestsTable tasks={tasks} selectedAccessRequestId={accessRequestId ?? undefined} />
        )}
      </>
    );
  };

  return (
    <Tab.Group selectedIndex={selectedIndex}>
      <div className="pt-4 text-white tr-bg-trustle-royal">
        <div className="container">
          <div className="tr-flex tr-pb-4">
            <Icon type="task" size="xl" className="tr-opacity-100" />
            <h1 className="tr-ml-2 tr-mb-0">Tasks</h1>
          </div>
          <Tab.List variant="folder">
            {tabs.map((key) => (
              <Tab
                key={key}
                as={Link}
                to={`/tasks/${key}`}
                data-testid={`task-tab-${key}`}
                className="text-white aria-selected:bg-white aria-selected:text-blue opacity-100 truncate tr-px-2 md:tr-px-4"
              >
                {key === TasksHomeTabs.MINE
                  ? `My Tasks ${tasks ? `(${_.size(tasks)})` : ''}`
                  : key === TasksHomeTabs.OTHER
                  ? `Other Tasks ${otherTasks ? `(${_.size(otherTasks)})` : ''}`
                  : _.upperFirst(key)}
              </Tab>
            ))}
          </Tab.List>
        </div>
      </div>

      <div className="container tr-flex tr-items-center tr-w-full">
        <Tab.Panels>
          <Tab.Panel key={TasksHomeTabs.MINE}>{getTasks(tasks)}</Tab.Panel>
          <Tab.Panel key={TasksHomeTabs.OTHER}>{getTasks(otherTasks)}</Tab.Panel>
        </Tab.Panels>
      </div>
    </Tab.Group>
  );
});

export default TasksHome;
