import React, {useEffect} from 'react';
import {AccountAccessType, UserType} from 'src/types';
import {useState} from 'react';
import {SubmitButton} from 'src/components/design';
import _ from 'lodash';
import {Resource} from 'src/stores/domainObjects/Resource';
import {Button, Modal, Select, TextInput} from '@trustle/component-library';
import {useRootStore} from 'src/lib/hooks';
import {observer} from 'mobx-react-lite';
import {User} from 'src/stores/domainObjects/User';
import {Field, Formik, useFormikContext} from 'formik';
import {FormikForm} from 'src/components/design/formik';
import {object, string} from 'yup';
import {useToasts} from 'react-toast-notifications';

type FormData = Partial<{
  email: string;
  firstName: string;
  lastName: string;
  userType: UserType;
  managerUid: string;
  id: string;
  account: string;
  accountType: AccountAccessType;
}>;

type NewResourceAccountPropsT = {
  closeModal: () => void;
  resource: Resource;
  defaultData: FormData;
};

const NewResourceAccountModal = observer((props: NewResourceAccountPropsT) => {
  const {resource, closeModal, defaultData} = props;
  const {account, accountType, email, firstName, lastName} = defaultData;
  const {addToast} = useToasts();
  const {usersStore, accountsStore} = useRootStore();

  const [existingUser, setExistingUser] = useState<User>();

  async function submitNewUser(values: FormData) {
    try {
      const {email = '', firstName = '', lastName = '', userType, managerUid} = values;
      let user: User | undefined = usersStore.getByEmail(email);
      const manager = managerUid && usersStore.usersMap[managerUid]?.email;

      if (!user) {
        user = await usersStore.createUser({
          email,
          firstname: firstName,
          lastname: lastName,
          type: userType || UserType.employee,
          managerEmail: _.isEmpty(manager) ? undefined : manager,
          allowLogin: true,
          startDate: new Date().toISOString(),
        });
      }

      if (user) {
        const currentAccount = accountsStore.accountsMap[defaultData.id!];

        if (_.isNil(currentAccount)) {
          // manual resource add account
          await accountsStore.createAccount({
            account: values.account,
            accountType: values.accountType,
            rid: resource.id,
            uid: user.id,
          });
        } else {
          const {id, account, accountType} = currentAccount;

          await user.mapAccount(id, account, accountType);
          await currentAccount.edit({uid: user.id, account: values.account, accountType: values.accountType});
        }
        closeModal();
        addToast('New account added Successfully', {appearance: 'success', autoDismiss: true});
      }
    } catch (err: any) {
      if (err && err.response && err.response.data && err.response.data.error) {
        addToast(err.response.data.error.message, {appearance: 'error', autoDismiss: false});
      }

      return {error: true, msg: 'Error saving account.'};
    }
  }

  const accountTypeOptions = [
    {label: 'Personal Account', value: AccountAccessType.PERSONAL},
    {label: 'Service Account', value: AccountAccessType.SERVICE},
  ];

  const userTypeOptions = Object.values(UserType).map((value) => ({label: _.upperFirst(value), value}));

  function ExistingUserTrigger(props: {setUser: any}) {
    const {setUser} = props;
    const {values, setFieldValue}: any = useFormikContext();

    useEffect(() => {
      const user: User | undefined = usersStore.getByEmail(values.email);
      setUser(user);

      if (user) {
        setFieldValue('firstName', user?.firstname);
        setFieldValue('lastName', user?.lastname);
        setFieldValue('userType', user.type);
        setFieldValue('managerUid', user.managerUid);
      }
    }, [values, setFieldValue]);

    return null;
  }

  return (
    <Modal title="Link Account" width="md" visible={true} onClose={closeModal}>
      <div>
        <Formik
          key={`newResourceaccount`}
          initialValues={{
            email,
            firstName,
            lastName,
            managerUid: existingUser?.managerUid,
            account,
            accountType: accountType ?? AccountAccessType.PERSONAL,
            userType: existingUser?.type ?? UserType.employee,
          }}
          onSubmit={submitNewUser}
          validationSchema={object().shape({
            email: string().email('invalid email').required('required'),
            firstName: string().required('required'),
            lastName: string().required('required'),
            account: string().required('required'),
            accountType: string().required('required'),
          })}
        >
          {({isValid, isSubmitting}) => {
            return (
              <FormikForm>
                <Field component={TextInput} label="Trustle Username (email)" className="tr-mx-3" name="email" />
                <div className="tr-flex tr-row">
                  <Field
                    data-testid={'new-link-email'}
                    component={TextInput}
                    label="First Name"
                    className="tr-mx-3"
                    name="firstName"
                    disabled={!_.isNil(existingUser)}
                  />
                  <Field
                    component={TextInput}
                    label="Last Name"
                    className="tr-mx-3"
                    name="lastName"
                    disabled={!_.isNil(existingUser)}
                  />
                </div>
                <Field
                  id="managerUid"
                  data-testid="managerUid"
                  component={Select}
                  name={'managerUid'}
                  isMulti={false}
                  className="tr-m-2"
                  label="Manager"
                  required={false}
                  options={_.map(usersStore.activeUsers, (u: any) => {
                    return {id: u.id, value: u.id, label: u.email};
                  })}
                  isDisabled={!_.isNil(existingUser)}
                />
                <Field
                  component={TextInput}
                  label="Account Name"
                  className="tr-mx-3 tr-mt-4"
                  name="account"
                  disabled={!!account}
                />
                <Field
                  component={Select}
                  label="Account Type"
                  className="tr-mx-3"
                  name="accountType"
                  options={accountTypeOptions}
                  onChange={(_type: any) => {}}
                  defaultValue={_.find(accountTypeOptions, {value: accountType || AccountAccessType.PERSONAL})}
                  required
                />
                <Field
                  component={Select}
                  label="Trustle User Type"
                  className="tr-mx-3 tr-mt-4"
                  name="userType"
                  options={userTypeOptions}
                  onChange={(_type: any) => {}}
                  isDisabled={!_.isNil(existingUser)}
                  required
                />
                <ExistingUserTrigger setUser={setExistingUser} />
                <div className="clear tr-flex tr-justify-end tr-mt-8">
                  <Button variant="secondary" name="cancel-resource" onClick={closeModal}>
                    Cancel
                  </Button>
                  <SubmitButton name="submit-resource" disabled={!isValid || isSubmitting} label="Save" />
                </div>
              </FormikForm>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
});

export default NewResourceAccountModal;
