import React from 'react';
import {formatUserName, logger} from 'src/lib';
import {AccessRecord} from 'src/stores/domainObjects/AccessRecord';
import {OwnerAccountAction} from 'src/types';
import {observer} from 'mobx-react-lite';
import {Confirmation} from '@trustle/component-library';
import {getErrorMessage} from 'src/utils';
import {useRootStore} from 'src/lib/hooks';

type ConfirmActionPropsT = {
  // an Accept action would throw invalid parameter exception from backend
  action: Omit<OwnerAccountAction, 'ACCEPT'>;
  accessRecords: AccessRecord[];
  closeModal?: () => void;
  refresh?: () => void;
};

const ConfirmAccountActionModal = observer((props: ConfirmActionPropsT) => {
  const {action, accessRecords, closeModal, refresh} = props;
  const {toast} = useRootStore();

  async function runAction(e: any) {
    e.preventDefault();
    await Promise.all(
      accessRecords.map(async (access) => {
        try {
          switch (action) {
            case OwnerAccountAction.START_DEPROVISION:
              return await access.deprovision();
            case OwnerAccountAction.START_REVOKE:
              return await access.revoke();
            case OwnerAccountAction.CANCEL_PENDING:
              return await access.cancelPending();
            case OwnerAccountAction.START_PROVISION:
              return await access.provision();
          }
        } catch (err) {
          logger.error(err);
          toast.error(
            `Error while performing ${action} ${access.forUser ? `for ${formatUserName(access.forUser)}` : ''}: ${
              getErrorMessage(err) ?? 'Something went wrong'
            }`
          );
        }
      })
    );
    refresh?.();
    closeModal?.();
  }

  return (
    <Confirmation onConfirm={runAction} onClose={() => closeModal?.()} title={'Confirm Action'}>
      <div>
        Please confirm that you would like to {action} {accessRecords.length > 0 && ` the selected accesses`}
      </div>
    </Confirmation>
  );
});

export default ConfirmAccountActionModal;
