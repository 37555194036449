import React from 'react';
import {OrgEventsDataParamsT} from './OrgEvents';
import _ from 'lodash';
import {formatUserName} from 'src/lib';

interface ReplaceBracesProps {
  text: string;
  data: OrgEventsDataParamsT;
}

const EventTemplate: React.FC<ReplaceBracesProps> = ({text, data}) => {
  const regex = /{([^{}]+)}/g;

  const parts = text.split(regex);

  const getObjectDifferences = (obj1: any, obj2: any, onlyWhatChanged?: boolean): Record<string, string> => {
    const differences: any = {};

    for (const key of Object.keys(obj1)) {
      if (
        [
          'parentId',
          'editedByUid',
          'uid',
          'id',
          'oid',
          'refId',
          'refType',
          'uniqueRemoteId',
          'foreignId',
          'foreignData',
          'updated',
          'updatedAt',
        ].includes(key)
      ) {
        continue;
      }
      if (!_.isEqual(obj1[key], obj2[key])) {
        if (_.isBoolean(obj1[key]) && _.isBoolean(obj2[key])) {
          differences[key] = `${obj2[key]}`;
        } else if (!_.isNil(obj1[key]) && !_.isNil(obj2[key])) {
          differences[key] = `${obj1[key]} to ${obj2[key]}`;
        }
      } else if (!onlyWhatChanged) {
        differences[key] = `${obj2[key]}`;
      }
    }

    return differences;
  };

  const resourceDifferences =
    data.resource && data.resource.oldValue ? getObjectDifferences(data.resource.oldValue, data.resource) : {};
  const systemDifferences =
    data.system && data.system.oldValue ? getObjectDifferences(data.system.oldValue, data.system) : {};
  const permissionDifferences =
    data.permission && data.permission.oldValue ? getObjectDifferences(data.permission.oldValue, data.permission) : {};
  const connectionDifferences =
    data.connection && data.connection.oldValue
      ? getObjectDifferences(data.connection.oldValue, data.connection, true)
      : {};
  const userDifferences = data.user && data.user.oldValue ? getObjectDifferences(data.user.oldValue, data.user) : {};
  const userAccountDifferences =
    data.userAccount && data.userAccount.oldValue
      ? getObjectDifferences(data.userAccount.oldValue, data.userAccount)
      : {};

  const differences = {
    ...connectionDifferences,
    ...resourceDifferences,
    ...systemDifferences,
    ...permissionDifferences,
    ...userDifferences,
    ...userAccountDifferences,
  };

  function replacePart(part: string) {
    switch (part) {
      case 'data.system.name':
        return <span className="tr-font-bold">{data.system && <b>{data.system.name}</b>}</span>;
      case 'data.resource.name':
        return <span className="tr-font-bold">{data.resource && <b>{data.resource.name}</b>}</span>;
      case 'data.permission.label':
        return <span className="tr-font-bold">{data.permission && <b>{data.permission.label}</b>}</span>;
      case 'data.team.name':
        return <span className="tr-font-bold">{data.team && <b>{data.team.name}</b>}</span>;
      case 'data.user.name':
        return <span className="tr-font-bold">{data.user && <b>{formatUserName(data.user)}</b>}</span>;
      case 'data.user.method':
        return <span className="tr-font-bold">{data.user && <b>{data.user.method}</b>}</span>;
      case 'data.import.message':
        return <span className="tr-font-bold">{data.import && <b>{data.import.message}</b>}</span>;
      default:
        return <span className="tr-font-bold">{_.get(data, part) ? _.get(data, part) : part}</span>;
    }
  }

  return (
    <div className="tr-my-2">
      {parts.map((part, index) => {
        return <React.Fragment key={index}>{replacePart(part)}</React.Fragment>;
      })}

      <div>
        {data.permission && (
          <span>
            Permission: <b>{data.permission.label}</b>
          </span>
        )}
      </div>
      <div>
        {data.userAccount && (
          <span>
            Account: <b>{data.userAccount.account}</b>
          </span>
        )}
      </div>
      <div>
        <ul>
          {Object.keys(differences).map((key) => (
            <li key={key}>
              {_.startCase(key)}: {differences[key]}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default EventTemplate;
