import React, {useEffect} from 'react';
import {NavLink} from 'react-router-dom';
import {observer} from 'mobx-react-lite';
import {useRootStore} from 'src/lib/hooks';
import _ from 'lodash';
import TrustleWhiteLogo from 'src/images/trustle-white-logo.svg';
import {FacetIdEnum} from 'src/types';
import TasksFloater from 'src/views/Tasks/components/TasksFloater';
import GettingStarted from './GettingStarted';
import PackageBadge from './PackageBadge';
import ProfileDropdown from './ProfileDropdown';
import {Button, Modal} from '@trustle/component-library';
import TaskDetailedView from 'src/views/Tasks/components/TaskDetailedView';

const Navigation = observer(function NavigationComponent() {
  const {tasksStore, userStore, org, currentUser} = useRootStore();
  const [menuOpen, setMenuOpen] = React.useState(false);

  if (_.isNil(org) || _.isNil(currentUser)) {
    return <></>;
  }

  useEffect(() => {
    void userStore.loadUserDefaults();
  }, []);

  const isFreePlan = org.facetId === FacetIdEnum.FREE_AWS;

  function taskCount() {
    return tasksStore.assignedTotal > 99 ? '+99' : tasksStore.assignedTotal;
  }

  return (
    <nav className="tr-px-2 sm:tr-px-4 tr-bg-navy tr-flex tr-flex-col md:tr-flex-row tr-justify-between md:tr-items-center">
      <div className="tr-flex tr-items-center tr-justify-between">
        <div className="tr-flex tr-items-center">
          <NavLink to="/home" className="tr-h-fit tr-my-3.5">
            <img src={TrustleWhiteLogo} height={24} width={98.39} alt="trustle" />
          </NavLink>
          <PackageBadge />
        </div>
        <div className="tr-flex tr-items-center">
          <Button
            className="tr-w-8 tr-h-8 tr-bg-transparent tr-border tr-border-solid tr-border-white tr-rounded-md md:tr-hidden tr-text-white"
            onClick={() => setMenuOpen(!menuOpen)}
          >
            {menuOpen ? (
              'x'
            ) : (
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="#fff">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            )}
          </Button>
          <ProfileDropdown className="md:tr-hidden" />
        </div>
      </div>
      <div
        className={`md:tr-flex tr-flex-col md:tr-flex-row md:tr-items-center tr-font-bold tr-mb-4 md:tr-mb-0 ${
          menuOpen ? 'tr-flex' : 'tr-hidden'
        }`}
      >
        {currentUser.isOrgOwner && <GettingStarted />}
        {!isFreePlan && (
          <NavLink
            to="/access/available"
            className="tr-text-white tr-py-2 tr-px-1 tr-mr-5 tr-border-0 tr-border-b-2 tr-no-underline hover:tr-border-solid hover:tr-pb-1.5 tr-w-fit"
            activeClassName="tr-border-solid tr-pb-1.5"
          >
            Access
          </NavLink>
        )}
        <NavLink
          to="/tasks"
          className="tr-text-white tr-py-2 tr-px-1 tr-mr-5 tr-border-0 tr-border-b-2 tr-no-underline hover:tr-border-solid hover:tr-pb-1.5 tr-w-fit tr-flex tr-items-center"
          activeClassName="tr-border-solid tr-pb-1.5"
        >
          Tasks
          <span className="tr-rounded-full tr-border tr-border-solid tr-w-5 tr-h-5 tr-flex tr-justify-center tr-items-center tr-text-xs tr-ml-1">
            {taskCount()}
          </span>
        </NavLink>
        <NavLink
          to="/manage"
          className="tr-text-white tr-py-2 tr-px-1 tr-mr-5 tr-border-0 tr-border-b-2 tr-no-underline hover:tr-border-solid hover:tr-pb-1.5 tr-w-fit"
          activeClassName="tr-border-solid tr-pb-1.5"
          isActive={(_match, {pathname}) => pathname.startsWith('/manage') || pathname.startsWith('/resource/manage')}
        >
          Manage
        </NavLink>
        {currentUser.isOrgOwner && (
          <NavLink
            to="/admin"
            className="tr-text-white tr-py-2 tr-px-1 tr-border-0 tr-border-b-2 tr-no-underline hover:tr-border-solid hover:tr-pb-1.5 tr-w-fit"
            activeClassName="tr-border-solid tr-pb-1.5"
          >
            Admin
          </NavLink>
        )}
        <div className="tr-mx-2">
          <TasksFloater />
        </div>
        <ProfileDropdown className="tr-hidden md:tr-block" />
        <Modal
          visible={!!tasksStore.selected}
          onClose={() => {
            tasksStore.setSelectedTask(undefined);
          }}
        >
          {tasksStore.selected && <TaskDetailedView accessRequest={tasksStore.selected} />}
        </Modal>
      </div>
    </nav>
  );
});

export default Navigation;
