import React, {useState} from 'react';
import axios from 'axios';
import {SubmitButton} from 'src/components/design';
import {ConnectionInfoT, ConnectionT} from '../../types';
import {Formik, FormikForm} from 'src/components/design/formik';
import {NextStepT} from '../types';
import {Field} from 'formik';
import {PasswordInput, TextInput, Alert} from '@trustle/component-library';
import {useConnectionCredentials} from '../../lib/hooks';

type ConnectorPropsT = {
  onComplete: NextStepT;
  connectionInfo: ConnectionInfoT;
  editMode?: boolean;
};

type StrongDMAuthForm = {
  accessKey: string;
  accessSecret: string;
};

export default function StrongDMSetup(props: ConnectorPropsT) {
  const {editMode = false, connectionInfo, onComplete} = props;
  const [connection, setConnection] = useState<ConnectionT>(connectionInfo.connection);
  const {credentials} = useConnectionCredentials();

  async function reloadConnection() {
    const {data} = await axios.get(`/api/connect/${connection.id}`);
    setConnection(data.connection);
  }

  async function submitCredentials(
    form: StrongDMAuthForm,
    setSubmitting: (submitting: boolean) => void,
    setStatus: (status?: any) => void
  ) {
    try {
      await credentials(connection.id, form);
      setStatus(null);
      void reloadConnection();

      onComplete({});
    } catch (err: any) {
      setStatus({message: err.response.data?.error?.message || err.response.statusText});
    }

    setSubmitting(false);
  }

  return (
    <div className="py-4">
      <h3>API Key configuration</h3>
      <div>Trustle authenticates with StrongDM via Access Token and Secret credentials.</div>
      <Formik
        initialValues={{accessKey: '', accessSecret: ''}}
        onSubmit={(values: StrongDMAuthForm, {setSubmitting, setStatus}: any) => {
          void submitCredentials(values, setSubmitting, setStatus);
        }}
      >
        {({isSubmitting, status}) => (
          <FormikForm className="p-3">
            <div className="tr-grid tr-gap-4 tr-grid-cols-2">
              <Field
                component={TextInput}
                label="Access Key"
                placeholder=""
                name="accessKey"
                required
                autoComplete="off"
              />

              <Field
                component={PasswordInput}
                label="Access Secret"
                showreveal={true}
                placeholder=""
                name="accessSecret"
                required
              />
            </div>
            <div className="tr-text-right tr-mt-5 tr-mb-3">
              <SubmitButton inSubmit={isSubmitting} label={editMode ? 'Submit' : 'Authenticate'} />
            </div>
            {status && status.message && <Alert colorVariant={'danger'}>{status.message}</Alert>}
          </FormikForm>
        )}
      </Formik>

      <hr />
    </div>
  );
}
