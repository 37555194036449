import React from 'react';
import AccessTable, {AccessBranchPropsT} from 'src/views/Resource/TreeView/AccessTable';
import {ResourceBranchPropsT} from 'src/views/Resource/TreeView/ResourceBranch';

import {ConnectorSchema} from './default';
import {ConnectionServiceE} from 'src/types';

import {SensitivityIcon} from '@trustle/component-library';
import {ReferencingAccounts} from 'src/views/Resource/TreeView/BranchComponents/ResourceBranchComponents';

const github: ConnectorSchema = {
  service: ConnectionServiceE.GITHUB,
  name: 'Github',
  description: 'Github templating definition.',
  serviceId: 'github',
  templates: {
    resources: {
      tree: {
        Resource: {
          team: {
            attributes: (props: ResourceBranchPropsT) => {
              return (
                <div className="tr-ml-1 tr-mr-1 tr-flex tr-justify-center tr-items-center">
                  <SensitivityIcon
                    size="sm"
                    level={props.resource.sensitivity?.level ?? ''}
                    title="Sensitivity set for this specific resource"
                  />
                  <ReferencingAccounts resource={props.resource} />
                </div>
              );
            },
          },
        },
        Permission: {},
        AccessRecord: {
          default: {
            main: (props: AccessBranchPropsT) => {
              return <AccessTable {...props} />;
            },
          },
        },
      },
    },
    accounts: {
      tables: {},
    },
  },
};

export default github;
