import React from 'react';
import _ from 'lodash';
import {Button, Icon} from '@trustle/component-library';
import {observer} from 'mobx-react';
import {User} from 'src/stores/domainObjects/User';
import {useRootStore} from 'src/lib/hooks';
import FilterToggleBtn from 'src/components/FilterToggleBtn';
import {UserType} from 'src/types';

type UsersTypeFiltersPropsT = {
  filters: any;
  filteredUsers: User[];
  setFilters: any;
};

const UsersTypeFilters = observer(function AdminUsersCmp(props: UsersTypeFiltersPropsT) {
  const {filters, setFilters, filteredUsers = []} = props;
  const {org} = useRootStore();
  const countByType = (type: UserType) => filteredUsers.filter((user) => user.type === type).length;

  const unlinkedUsers = _.size(
    _.filter(filteredUsers, (item) => {
      return _.isNil(org.accountsDictionary[item.id]);
    })
  );
  const wrapperClasses = 'tr-pl-2 tr-py-2 tr-pr-3 tr-text-sm tr-flex tr-items-center mr-2';
  return (
    <>
      <div className="tr-flex tr-mb-4">
        <FilterToggleBtn
          filterKey="Trustle User Type"
          value={UserType.employee}
          disabled={countByType(UserType.employee) === 0}
          className={`tr-flex tr-items-center ${wrapperClasses}`}
        >
          <Icon
            type="employeeUser"
            className={`${countByType(UserType.employee) === 0 ? 'tr-opacity-20' : ''} tr-pr-2`}
          />
          Employee
          <span className="ml-3">{countByType(UserType.employee)}</span>
        </FilterToggleBtn>
        <FilterToggleBtn
          filterKey="Trustle User Type"
          value={UserType.contractor}
          disabled={countByType(UserType.contractor) === 0}
          className={`tr-flex tr-items-center ${wrapperClasses}`}
        >
          <Icon
            type="contractorUser"
            className={`${countByType(UserType.contractor) === 0 ? 'tr-opacity-20' : ''} tr-pr-1`}
          />
          Contractor
          <span className="ml-3">{countByType(UserType.contractor)}</span>
        </FilterToggleBtn>
        <FilterToggleBtn
          filterKey="Trustle User Type"
          value={UserType.customer}
          disabled={countByType(UserType.customer) === 0}
          className={`tr-flex tr-items-center ${wrapperClasses}`}
        >
          <Icon
            type="customerUser"
            className={`${countByType(UserType.customer) === 0 ? 'tr-opacity-20' : ''} tr-pr-1`}
          />
          Customer
          <span className="ml-3">{countByType(UserType.customer)}</span>
        </FilterToggleBtn>
        <FilterToggleBtn
          filterKey="Trustle User Type"
          value={UserType.system}
          disabled={countByType(UserType.system) === 0}
          className={`tr-flex tr-items-center ${wrapperClasses}`}
        >
          <Icon type="systemUser" className={`${countByType(UserType.system) === 0 ? 'tr-opacity-20' : ''} tr-pr-1`} />
          System
          <span className="ml-3">{countByType(UserType.system)}</span>
        </FilterToggleBtn>
        <Button
          variant="ternary"
          selected={filters.LinkStatus === 'Unlinked'}
          onClick={() => {
            if (filters.LinkStatus === 'Unlinked') {
              setFilters({...filters, LinkStatus: ''});
            } else {
              setFilters({...filters, LinkStatus: 'Unlinked'});
            }
          }}
          disabled={unlinkedUsers === 0}
          className={`tr-flex tr-items-center ${wrapperClasses}`}
        >
          <Icon type="unlinkedUser" className={`${unlinkedUsers === 0 ? 'tr-opacity-20' : ''} tr-pr-1`} />
          Unlinked
          <span className="ml-3">{unlinkedUsers}</span>
        </Button>
      </div>
    </>
  );
});

export default UsersTypeFilters;
